import {Injectable, Inject} from '@angular/core';

@Injectable()
export class rpsDurationResolver implements rps.services.IDurationResolver {
    private cache: { [id: string]: rps.data.DurationFactor }
    configure = () => {
        this.cache = {};
        rps.app.durationResolver = this;
    }
    resolve(codCompany: string, timeUnit: number): Promise<rps.data.DurationFactor> {
        var cacheKey = `${codCompany};${timeUnit}`;
        if (this.cache[cacheKey])
            return Promise.resolve<rps.data.DurationFactor>(this.cache[cacheKey]);
        return rps.app.api.query({
            queryName: "Security/DurationFactor",
            params: {
                CodCompany: codCompany,
                TimeUnit: timeUnit
            }
        }).then((result: rps.data.DurationFactor) => {
            this.cache[cacheKey] = result;
            return result;
        });
    }
}