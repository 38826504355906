import {Component, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { baseServerColumn } from './baseServerColumn';

//TODO: Hacer el tooltip, que se elimino al pasar a Angular 2
@Component({
    template: `
        <div>
            <div class="rps-address-c-container">
                <div class="rps-img-2 rps-address-sc-image" *ngIf="image">
                    <img class="rps-img"
                         src="{{image}}"/>
                </div>
                <div class="rps-address-sc-text-container">
                    <div class="rps-editor-editor rps-address-sc-text">
                        <div>
                            {{text}}
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class rpsAddressServerColumn extends baseServerColumn<rps.data.AddressValue>  {
    private modelProperty: rps.data.AddressValue;
    public text: string;
    public image: string;
    public countryText: string;
    public countryFlag: string;
    public stateText: string;
    public countyText: string;
    public zipCodeText: string;
    public cityText: string;
    public addressText: string;  

    constructor(_cdr: ChangeDetectorRef) {
        super(_cdr);
    }

    setValue(newValue: rps.data.AddressValue) {
        if (this.modelProperty !== newValue) {
            this.modelProperty = newValue;
            this.refresh();
            this.refreshTooltip();
            this._cdr.markForCheck();
        }
    }

    private refresh() {
        if (this.modelProperty) {
            if (!rps.string.isNullOrEmpty(this.modelProperty.CountryName)) {
                if (!rps.string.isNullOrEmpty(this.modelProperty.City))
                    this.text = rps.string.format("{0}, {1}", this.modelProperty.City, this.modelProperty.CountryName);
                else
                    this.text = rps.string.format("{0}", this.modelProperty.CountryName);
            }
            else if (!rps.string.isNullOrEmpty(this.modelProperty.City)) {
                this.text = rps.string.format("{0}", this.modelProperty.City);
            }
            else {
                this.text = "";
            }
            this.image = this.modelProperty.CountryFlag;
        }
        else {
            this.text = "";
            this.image = null;
        }
    }

    private refreshTooltip() {
        if (this.modelProperty) {
            this.countryText = this.modelProperty.CountryName;
            this.countryFlag = this.modelProperty.CountryFlag;
            this.stateText = this.modelProperty.StateName;
            this.countyText = this.modelProperty.CountyName;
            this.zipCodeText = this.modelProperty.ZipCode;
            this.cityText = this.modelProperty.City;
            this.addressText = this.modelProperty.Address;
        }
        else {
            this.countryText = "";
            this.countryFlag = "";
            this.stateText = "";
            this.countyText = "";
            this.zipCodeText = "";
            this.cityText = "";
            this.addressText = "";
        }
    }
} 