import {Injectable, Inject} from '@angular/core';

@Injectable()
export class rpsCurrencyResolver implements rps.services.ICurrencyResolver {
    private cache: { [id: string]: rps.data.CurrencySymbol }
    configure = () => {
        this.cache = {};
        rps.app.currencyResolver = this;
    }
    resolve(codCompany: string, entityType: string, entityId: string): Promise<rps.data.CurrencySymbol> {
        var cacheKey = `${codCompany};${entityType};${entityId}`;
        if (this.cache[cacheKey])            
            return Promise.resolve<rps.data.CurrencySymbol>(this.cache[cacheKey]);
        return rps.app.api.query({
            queryName: "General/CurrencySymbol",
            params: {
                CodCompany: codCompany,
                EntityType: entityType,
                EntityId: entityId
            }
        }).then((result:rps.data.CurrencySymbol) => {
            this.cache[cacheKey] = result;
            return result;
        });
    }
}