import { Component,
    AfterViewInit,
    ElementRef,
    OnChanges,
    SimpleChange,
    OnDestroy} from '@angular/core';
import { rpsButton } from '../editors/button';
import { rpsToggleButton } from '../editors/toggleButton';
import {rpsColumns} from '../layouts/columns';
import {rpsRow} from '../layouts/row';
import {rpsRowsGroup} from '../layouts/rowsGroup';
import {rpsComboBox} from '../editors/comboBox';
import {rpsTextBox} from '../editors/textBox';
import {rpsDatePicker} from '../editors/datePicker';
import {rpsMultiEnum} from '../editors/multiEnum';
import {rpsCheckBox} from '../editors/checkBox';
import {rpsIntegerEditor} from '../editors/integerEditor';
import {rpsDecimalEditor} from '../editors/decimalEditor';
import {rpsMultiLookup} from '../editors/multiLookup';

@Component({
    selector: 'rps-filters',
    template: `
        <div>
            <div class="rps-filters">         
                <div class="rps-stack-panel rps-layout-margin rps-layout-padding-top">
                    <div class="rps-stack-panel-item rps-layout-padding-horizontal rps-editor-fit-container">
                        <rps-toggle-button class="rps-stack-panel-item"
                                            rpsTemplate="TEXT_WITH_ICON"
                                            rpsLabel="{{resources.directives.ADD_FILTER}}"
                                            rpsIcon="fa fa-plus"
                                            [rpsModel]="rpsFiltersManager.addNewFilterBoolean">
                        </rps-toggle-button>                        
                        <rps-toggle-button [hidden]="!rpsFiltersManager.allowLoadFilters"
                                            id="saveFiltersContainer"
                                            class="rps-stack-panel-item"
                                            rpsTemplate="ICON"
                                            rpsIcon="fa fa-angle-down"
                                            [rpsModel]="loadFiltersBoolean">
                        </rps-toggle-button>
                        <ul id="kendoContextMenu" class="rps-filter-context-menu">
                        </ul>  
                    </div>
                    <div *ngIf="rpsFiltersManager.entityName && rpsFiltersManager.hasFilters()"
                         class="rps-stack-panel-item rps-layout-padding-horizontal  rps-editor-fit-container">
                        <rps-button class="rps-stack-panel-item"
                                    rpsTemplate="TEXT_WITH_ICON"
                                    rpsLabel="{{resources.directives.SAVE_FILTERS}}"
                                    rpsIcon="fa fa-floppy-o"
                                    [rpsModel]="rpsFiltersManager.saveFiltersCommand">
                        </rps-button>
                    </div>
                    <div *ngIf="rpsFiltersManager.hasFilters()"
                         class="rps-stack-panel-item rps-layout-padding-horizontal  rps-editor-fit-container">
                        <rps-button class="rps-stack-panel-item"
                                    rpsTemplate="TEXT_WITH_ICON"
                                    rpsLabel="{{resources.directives.CLEAR_FILTERS}}"
                                    rpsIcon="fa fa-times"
                                    [rpsModel]="rpsFiltersManager.clearFiltersCommand">
                        </rps-button>
                    </div>
                </div>
                <rps-rows-group *ngIf="rpsFiltersManager?.editingFilter">
                    <rps-row>
                        <rps-combo-box rpsColumns="4"
                                       rpsLabel="{{resources.directives.FILTER_HEADER}}"
                                       [rpsModel]="rpsFiltersManager.editingFilter.idFilterDefinition"></rps-combo-box>
                        <rps-combo-box rpsColumns="3"
                                       *ngIf="rpsFiltersManager.editingFilter.comparisonOperator && rpsFiltersManager.editingFilter.comparisonOperator.getItems().length > 1"
                                       [rpsModel]="rpsFiltersManager.editingFilter.comparisonOperator">
                        </rps-combo-box>
                        <div rpsColumns="3"
                             *ngIf="rpsFiltersManager.editingFilter.value?.isRequired">
                            <rps-text-box *ngIf="rpsFiltersManager.editingFilter.value.valueString"
                                            rpsLabel="{{resources.directives.VALUE}}"
                                            [rpsModel]="rpsFiltersManager.editingFilter.value.valueString">
                            </rps-text-box>
                            <rps-multi-combo-box *ngIf="rpsFiltersManager.editingFilter.value.valueMultiComboBox"
                                            rpsLabel="{{resources.directives.VALUE}}"
                                            [rpsModel]="rpsFiltersManager.editingFilter.value.valueMultiComboBox">
                            </rps-multi-combo-box>
                            <rps-decimal-editor *ngIf="rpsFiltersManager.editingFilter.value.valueDecimal"
                                                rpsLabel="{{resources.directives.VALUE}}"
                                                [rpsModel]="rpsFiltersManager.editingFilter.value.valueDecimal">
                            </rps-decimal-editor>
                            <rps-integer-editor *ngIf="rpsFiltersManager.editingFilter.value.valueInteger"
                                                rpsLabel="{{resources.directives.VALUE}}"
                                                [rpsModel]="rpsFiltersManager.editingFilter.value.valueInteger">
                            </rps-integer-editor>
                            <rps-check-box *ngIf="rpsFiltersManager.editingFilter.value.valueBoolean"
                                            rpsLabel="{{resources.directives.VALUE}}"
                                            [rpsModel]="rpsFiltersManager.editingFilter.value.valueBoolean">
                            </rps-check-box>
                            <rps-multi-enum *ngIf="rpsFiltersManager.editingFilter.value.valueMultiEnum"
                                            rpsLabel="{{resources.directives.VALUE}}"
                                            [rpsModel]="rpsFiltersManager.editingFilter.value.valueMultiEnum">
                            </rps-multi-enum>
                            <rps-date-picker *ngIf="rpsFiltersManager.editingFilter.value.valueDatetime"
                                                rpsLabel="{{resources.directives.VALUE}}"
                                                [rpsModel]="rpsFiltersManager.editingFilter.value.valueDatetime">
                            </rps-date-picker>
                            <rps-multi-lookup *ngIf="rpsFiltersManager.editingFilter.value.valueMultiLookup"
                                              rpsLabel="{{resources.directives.VALUE}}"
                                              [rpsDataSource]="rpsFiltersManager.editingFilter.value.valueMultiLookupSource"
                                              [rpsModel]="rpsFiltersManager.editingFilter.value.valueMultiLookup">
                            </rps-multi-lookup>
                        </div>
                        <div rpsColumns="2" class="rps-stack-panel">
                            <rps-button class="rps-stack-panel-item"
                                        rpsTemplate="ICON"
                                        rpsLabel="{{resources.directives.APPLY}}"
                                        rpsIcon="fa fa-check"
                                        [rpsModel]="rpsFiltersManager.validateEditingFilterCommand"
                                        rpsType="PRIMARY">
                            </rps-button>
                            <rps-button class="rps-stack-panel-item rps-editor-left-fit-container"
                                        rpsTemplate="ICON"
                                        rpsLabel="{{resources.directives.CANCEL}}"
                                        rpsIcon="fa fa-minus"
                                        [rpsModel]="rpsFiltersManager.cancelEditingFilterCommand">
                            </rps-button>
                        </div>
                    </rps-row>
                </rps-rows-group>
                <div class="rps-stack-panel rps-layout-margin">                                
                    <div *ngFor="let filter of rpsFiltersManager?.filters?.value?.toArray()" 
                         class="rps-stack-panel-item rps-layout-padding-horizontal  rps-editor-fit-container">
                        <rps-toggle-button class="rps-stack-panel-item"
                                            rpsTemplate="TEXT"
                                            [rpsLabel]="filter.description()"
                                            [rpsModel]="filter.editFilterBoolean">
                        </rps-toggle-button>
                        <rps-button class="rps-stack-panel-item"
                                    rpsTemplate="ICON"
                                    rpsLabel="{{resources.directives.DELETE}}"
                                    [rpsModel]="filter.deleteFilter"
                                    rpsIcon="fa fa-times">
                        </rps-button>
                    </div>
                </div>                
            </div>
        </div>
    `,
    inputs: ['rpsFiltersManager']    
})
export class rpsFilters implements AfterViewInit, OnChanges, OnDestroy {
    private kendoContextMenu: kendo.ui.ContextMenu;
    private static kendoContextMenuItemTemplate: string = `
        <div class="filter-item-container">
            <div>{0}</div>             
            <div class="remove-filter-item">
                    <div class="fa fa-trash-o"></div>                                                                                                              
            </div>
        </div>
    `

    public loadFiltersBoolean:rps.viewmodels.properties.VMProperty<boolean>;

    public get resources() {
        return rps.app.resources;
    }

    public rpsFiltersManager: rps.data.filters.FiltersManager;

    constructor(private elementRef: ElementRef) {
        this.loadFiltersBoolean = new rps.viewmodels.properties.VMProperty<boolean>({
            target: this,
            initialValue: false
        });
        this.loadFiltersBoolean.propertyChanged.subscribe((value: rps.viewmodels.properties.VMPropertyChange) => {
            if (value.propertyName == "value" && value.newValue) {
                this.kendoContextMenu.open($(this.elementRef.nativeElement).find("#saveFiltersContainer"));
            }
        });
    }

    ngAfterViewInit() {
        //Crear el menú contextual para cargar los filtros guardados
        this.kendoContextMenu = $(this.elementRef.nativeElement).find("#kendoContextMenu")
            .kendoContextMenu(this.getOptions())
            .data("kendoContextMenu");
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        //Al cambiar el filterManager o la lista de grupos de filtros guardados, hay que refrescar los ítems del menú contextual
        if (changes["rpsFiltersManager"]) {
            this.refreshDataSource();         
            if (this.rpsFiltersManager) {
                this.rpsFiltersManager.savedFiltersChanged.subscribe(() => {
                    this.refreshDataSource();
                });
            }
        }
    };

    ngOnDestroy() {
        if (this.kendoContextMenu) {
            this.kendoContextMenu.destroy();
            this.kendoContextMenu = null;

            this.loadFiltersBoolean.onDestroy();
        }
    }

    /**
     * Refrescar los ítems del menú contextual de la carga de los filtros
     */
    refreshDataSource() {
        if (this.kendoContextMenu) {
            this.kendoContextMenu.setOptions(this.getOptions());
        }
    }

    /**
     * Método que crea las opciones del contextMenu de carga de filtros
     */
    getOptions(): kendo.ui.ContextMenuOptions {
        return {            
            select: (e) => {
                if ($(e.item).find(".k-link .remove-filter-item").is(":hover")) {
                    this.rpsFiltersManager.removeSavedFilter($(e.item).children(".k-link").children().text().trim());
                } else {
                    this.rpsFiltersManager.loadSavedFilter($(e.item).children(".k-link").children().text().trim());
                }
            },
            dataSource: this.getDataSource(),
            orientation: "vertical",
            close: () => {
                this.loadFiltersBoolean.value = false;
            },
            target:"#none"
        };
    }

    /**
     * Método que crea el origen de datos para las opciones del contextMenu de carga de filtros
     */
    getDataSource() {
        if (this.rpsFiltersManager && this.rpsFiltersManager.savedFilters.length > 0) {
            var newDataSource: Array<{ text: string; encoded: boolean; }> = new Array<{ text: string; encoded: boolean; }>();
            this.rpsFiltersManager.savedFilters.forEach((sfi) => {
                newDataSource.push({
                    text: rps.string.format(rpsFilters.kendoContextMenuItemTemplate,sfi.Name),
                    encoded: false
                });
            });
            return newDataSource;
        }
        else {
            return null;
        }
    }
}