import {Component, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { baseServerColumn } from './baseServerColumn';

@Component({
    template: `
        <div align="center">
            <div class="rps-img-2">
                <img class="rps-img" 
                     src="{{value}}">
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class rpsImageEditorServerColumn extends baseServerColumn<string>{
    value: string;

    constructor(_cdr: ChangeDetectorRef) {
        super(_cdr);
    }

    setValue(newValue: string) {
        if (this.value !== newValue) {
            this.value = newValue;
            this._cdr.markForCheck();
        }
    }
} 