import {Component,
    ElementRef,
    SimpleChange,
    OnChanges,
    OnDestroy,
    ViewChild,
    ViewContainerRef,
    AfterContentInit} from '@angular/core';

@Component({
    template: `
        <div>
            <div *ngIf="descriptor">
                <div class="rps-tree-left" 
                        *ngIf="descriptor.Image">
                    <div class="rps-img-2">
                        <img class="rps-img"
                                src="{{descriptor.Image}}"
                                [attr.alt]="descriptor.MainDescriptor"/>
                    </div>
                </div>
                <div *ngIf="descriptor.SecondaryDescriptor == undefined"
                        class="rps-tree-right"
                        [class.rps-tree-right-prevent-left]="descriptor.Image">
                    {{descriptor.MainDescriptor}}
                </div>           
                <div *ngIf="descriptor.SecondaryDescriptor != undefined"
                        class="rps-tree-right"
                        [class.rps-tree-right-prevent-left]="descriptor.Image">
                    {{descriptor.MainDescriptor}} ({{descriptor.SecondaryDescriptor}})
                </div>
            </div>
        </div>
    `
})
export class rpsTreeViewItem {
    public descriptor: rps.data.FullDescriptor;

    constructor() {
    }

    setDescriptor(newValue: rps.data.FullDescriptor) {
        this.descriptor = newValue;
    }
}

@Component({
    selector: 'rps-tree-view',
    template: `
        <div>
            <div [hidden]="!loaded">
                <div id="kendoTreeView">
                </div>
            </div>
            <div *ngIf="!loaded" 
                    class="rps-unloaded-query">
                    {{resources.directives.UNLOADED_QUERY}}
            </div>
            <div #dynamicContainer>
            </div>
        </div>
  `,
    inputs: ['rpsSource', 'rpsLinkPropertyPath', 'rpsDescriptorPropertyPath']
})
export class rpsTreeView implements AfterContentInit, OnDestroy,OnChanges {
    @ViewChild('dynamicContainer', { read: ViewContainerRef })
    private dynamicContainer: ViewContainerRef;

    private kendoTreeView: kendo.ui.TreeView;

    protected myId: string;

    private get resources() {
        return rps.app.resources;
    }

    public rpsSource: rps.data.sources.HierarchicalQuerySource;
    private rpsSourcePropertyChangedSub: rps.services.ISubscription;

    private rpsLinkPropertyPath: string;
    private rpsDescriptorPropertyPath: string;

    public loaded: boolean = false;

    constructor(private elementRef: ElementRef) {
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes["rpsSource"])
            this.rpsSourceChanged();
    }

    ngAfterContentInit() {
        this.createKendoWidgets();
    }

    ngOnDestroy() {
        if (this.kendoTreeView)
            this.kendoTreeView.destroy();

        $(this.elementRef.nativeElement).find("#kendoTreeView").first().empty();

        if (this.rpsSourcePropertyChangedSub)
            this.rpsSourcePropertyChangedSub.unsubscribe();
    }

    createKendoWidgets() {
        //Crear el treeview
        this.kendoTreeView = $(this.elementRef.nativeElement).find("#kendoTreeView").kendoTreeView({
            autoBind: false,
            dataBound: this.kendoTreeViewDataBoundEvent,
            template: "<div class='rps-tree-view-item-temp'></div>",
            select:this.select
        }).data("kendoTreeView");
        //Se establece el origen de datos
        this.rpsSourceChanged();   
    }

    kendoTreeViewDataBoundEvent = (e: kendo.ui.TreeViewDataBoundEvent) => {
        (<JQuery>(<any>e.sender).items()).find(".rps-tree-view-item-temp").each((index: number, elem: Element) => {
            var element = jQuery(elem);
            var dataItem = e.sender.dataItem(element);
            this.createItem(dataItem, element);
        });
        this.loadedChanged();
    }

    createItem(dataItem: any, element: JQuery) {
        element.removeClass("rps-tree-view-item-temp");
        rps.app.uiFactory.createComponent<rpsTreeViewItem>(rpsTreeViewItem, this.dynamicContainer)
            .then((result) => {
                (<rpsTreeViewItem>result.instance).setDescriptor(dataItem[this.rpsDescriptorPropertyPath].value);
                element.append(result.location.nativeElement);
            });
    }

    rpsSourceChanged() {
        if (this.rpsSourcePropertyChangedSub)
            this.rpsSourcePropertyChangedSub.unsubscribe();

        if (this.rpsSource && this.kendoTreeView) {
            //Se establece el origen de datos del widget
            this.kendoTreeView.setDataSource(this.rpsSource.hierarchicalDataSource);

            //HACK:Se refresca a mano, ya que en el caso de que la lectura ya esté hecha, no se dibujaba…
            if (this.rpsSource.hierarchicalDataSource.data().length) {
                (<any>this.kendoTreeView).refresh({
                    items: this.rpsSource.hierarchicalDataSource.data()
                })
            }

            this.rpsSourcePropertyChangedSub = this.rpsSource.propertyChanged
                .subscribe((se: rps.viewmodels.properties.VMPropertyChange) => this.querySourcePropertyChanged(se));
            this.loadedChanged();
        }
    }

    select = (e: kendo.ui.TreeViewSelectEvent) => {
        if (this.rpsLinkPropertyPath) {
            var selectedVM: kendo.data.Node = this.kendoTreeView.dataItem(e.node);
            var linkProperty: rps.viewmodels.properties.LinkProperty = selectedVM[this.rpsLinkPropertyPath];
            linkProperty.go();
        }
        else {
            var selectedVM: kendo.data.Node = this.kendoTreeView.dataItem(e.node);
            var newSelection: Array<any> = new Array<any>();
            newSelection.push(selectedVM)
            this.rpsSource.setSelectedItems(newSelection);
        }
    }

    private querySourcePropertyChanged(e: rps.viewmodels.properties.VMPropertyChange) {
        if (e.propertyName == "loaded")
            this.loadedChanged();
    }

    loadedChanged() {  
        if (this.rpsSource && this.kendoTreeView)
            this.loaded = this.rpsSource.loaded;
        else
            this.loaded = false;
    }
}
