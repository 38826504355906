import { Component, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsReadOnlyEditor} from './readOnlyEditorBase'
import {rpsSemanticState} from '../utils/semanticState'

@Component({
    selector: 'rps-read-only-radio-button',
    template: `
        <div class="rps-editor" 
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label"></label>
            <div class="rps-read-only-radio-button-container"  
                [rpsSemanticState]="rpsModel?.semanticState">
                <input class="rps-read-only-radiobutton"
                        type="radio"
                        [attr.id]="myId"
                        [attr.name]="rpsName"
                        [attr.value]="rpsValue"
                        tabindex="-1"
                        disabled>
                <label class="rps-read-only-radiobutton-label"
                        [attr.for]="myId">
                    {{rpsLabel}}
                </label>
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsReadOnlyEditor.getComponentInputs(['rpsName', 'rpsValue'])
})
export class rpsReadOnlyRadioButton extends rpsReadOnlyEditor<string, rps.viewmodels.properties.VMProperty<string>> {

    rpsName: string;
    rpsValue: string;

    get inputCheckValue(): boolean {
        return this.element.find("input").prop("checked");
    }
    set inputCheckValue(newValue: boolean) {
        this.element.find("input").prop("checked", newValue);
    }

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef) {
        super(elementRef, changeDetectorRef);
    }

    updateTargetValue() {
        if (this.rpsModel && this.rpsModel.value == this.rpsValue)
            this.inputCheckValue = true;
        else
            this.inputCheckValue = false;
    }
}
