import {Component, ElementRef, SimpleChange, Optional, OnChanges, OnDestroy, AfterViewChecked, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsReadOnlyEditor} from './readOnlyEditorBase'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'

@Component({
    selector: 'rps-documents',
    template: `
        <div class="rps-editor" 
             [rpsSemanticState]="rpsModel?.semanticState">
            <label class="rps-editor-label">
                {{rpsLabel}}
            </label>
            <div class="rps-editor-editor rps-read-only-editor-viewer rps-read-only-semantic-state">
                 <template ngFor let-document [ngForOf]="rpsModel?.value?.Data" let-i="index">
                    <div class="rps-img-2 rps-document-img" *ngIf="i<nDocumentos">
                        <img class="rps-img" src="{{document?.descriptor?.Image}}" />
                    </div> 
                    <i *ngIf="i==maxDocumentos-1 && rpsModel?.value?.Count>=nDocumentos"> 
                        +{{rpsModel?.value?.Count-nDocumentos}}
                    </i>  
                </template>              
            </div>
        </div>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsReadOnlyEditor.getComponentInputs()
})
export class rpsDocuments extends rpsReadOnlyEditor<rps.data.DocumentsResult, rps.viewmodels.properties.DocumentsProperty> implements OnChanges, AfterViewChecked ,OnDestroy {
    nDocumentos: number;
    maxDocumentos: number = 5;
    componentWidth: number = 0;
    private kendoTooltip: kendo.ui.Tooltip;
    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);                
    }
    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes['rpsModel']) {
            if (rps.object.hasValue(this.rpsModel)) {
                if (this.kendoTooltip)
                    this.kendoTooltip.destroy();
                this.kendoTooltip = $(this.elementRef.nativeElement).kendoTooltip({
                    filter: ".rps-document-img",
                    position: "bottom",
                    showAfter: rps.utils.getTooltipDelay(),
                    content: (e) => {
                        return this.rpsModel.value.Data[$(e.target).index()].descriptor.MainDescriptor;
                    }
                }).data("kendoTooltip");
            }
        }
    }

    ngOnDestroy() {
        if (this.kendoTooltip)
            this.kendoTooltip.destroy();
    }

    ngAfterViewChecked() {
        //Comprobamos que tenga tamaño y que no sea el tamaño que tenia anteriormente para no estar constantemente realizando la operacion
        if ($(this.elementRef.nativeElement).find(".rps-editor-editor").width() > 0 && this.componentWidth != $(this.elementRef.nativeElement).find(".rps-editor-editor").width()) {           
            this.nDocumentos = Math.floor($(this.elementRef.nativeElement).find(".rps-editor-editor").width() / 35);
            if (this.nDocumentos > this.maxDocumentos)
                this.nDocumentos = this.maxDocumentos;
            this.componentWidth = $(this.elementRef.nativeElement).find(".rps-editor-editor").width();
        }        
    }
   
}