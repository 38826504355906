module rps {
    export interface IParams {
        [key: string]: any;
    }

    export interface IActivable {
        /** @internal */
        isIActivable: boolean;
        /** @internal */
        onActivate(isFirstActivation:boolean) : void;
    }

    /** @internal */
    export function isIActivable(value: any): value is IActivable {
        return value && value.isIActivable;
    }

    export interface IDestroyable {
        /** @internal */
        isIDestroyable: boolean;
        /** @internal */
        onDestroy: () => void;
    }

    /** @internal */
    export function isIDestroyable(value: any): value is IDestroyable {
        return value && value.isIDestroyable;
    }

    export interface ISelectable {
        /** @internal */
        ISelectable: boolean;

        selectedItems: Array<any>;
        hasSelectedItems(): boolean;
        getSelectedItems(): Array<any>;
        setSelectedItems(newSelection: Array<any>);
    }

    /** @internal */
    export function isISelectable(value: any): value is ISelectable {
        return value && value.ISelectable;
    }

    export interface IItemContainer {
        /** @internal */
        IItemContainer: boolean;

        hasItems(): boolean;
    }

    export interface ICanAddEntity {
        disableAddEntity(reason: string): void;
    }

    /** @internal */
    /**
     * Devuelve si el elemento pasado es un contenedor o lista de elementos
     * @param value
     */
    export function isItemContainer(value: any): value is IItemContainer {
        return value && value.IItemContainer;
    }

    export enum queryExecutionPolicy {
        Manual = 0,
        WhenActive = 1,
        Automatic = 2
    }

    export interface IExecutableQuery {
        execute(commandParameters?: any): Promise<any>;
        executionPolicy: queryExecutionPolicy;
    }

    export function isExecutableQuery(value: any): value is IExecutableQuery {
        return value && "executionPolicy" in value;
    }

    /** @internal */
    export interface IComponentFactory {
        (obj: {
            selector?: string;
            inputs?: string[];
            outputs?: string[];
            properties?: string[];
            events?: string[];
            host?: {
                [key: string]: string;
            };
            bindings?: any[];
            providers?: any[];
            exportAs?: string;
            moduleId?: string;
            queries?: {
                [key: string]: any;
            };
            viewBindings?: any[];
            viewProviders?: any[];
            changeDetection?: any;
            templateUrl?: string;
            template?: string;
            styleUrls?: string[];
            styles?: string[];
            directives?: Array<Function | any[]>;
            pipes?: Array<Function | any[]>;
            encapsulation?: any;
        }): any;
        new (obj: {
            selector?: string;
            inputs?: string[];
            outputs?: string[];
            properties?: string[];
            events?: string[];
            host?: {
                [key: string]: string;
            };
            bindings?: any[];
            providers?: any[];
            exportAs?: string;
            moduleId?: string;
            queries?: {
                [key: string]: any;
            };
            viewBindings?: any[];
            viewProviders?: any[];
            changeDetection?: any;
            templateUrl?: string;
            template?: string;
            styleUrls?: string[];
            styles?: string[];
            directives?: Array<Function | any[]>;
            pipes?: Array<Function | any[]>;
            encapsulation?: any;
        }): any;
    }

    export interface IOnBeforeUnload {
        /** @internal */
        isIOnBeforeUnload: boolean;
        /** @internal */
        onBeforeUnload: () => Promise<any>;
    }

    /** @internal */
    export function isIOnBeforeUnload(value: any): value is IOnBeforeUnload {
        return value && value.isIOnBeforeUnload;
    }
}
