import { NgModule,  ErrorHandler,Component }       from '@angular/core';
import { UrlSerializer}       from '@angular/router';
import { BrowserModule  } from '@angular/platform-browser';
import { rpsApp }   from './rpsApp';
import { routing }   from './app.routing';
import { COMPILER_PROVIDERS  } from '@angular/compiler';

import {HttpModule, BaseRequestOptions, RequestOptions, Headers} from '@angular/http';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import { FormsModule }   from '@angular/forms';

import { rpsModule,rpsDesktopModule } from 'rps/src/directives/modules';
import {rpsMenuModule} from 'rps/src/app/menu';

import {rpsSession} from 'rps/src/services/session';
import {rpsErrorManager} from 'rps/src/services/errorManager';
import {rpsEntityFactory} from 'rps/src/services/entityFactory';
import {rpsProcessResultManager} from 'rps/src/services/processResultManager';
import {rpsBusyIndicatorManager} from 'rps/src/services/busyIndicatorManager';
import {rpsNotificationManager} from 'rps/src/services/notificationManager';
import {rpsAPI, UploadService} from 'rps/src/services/api';
import {rpsClientAPI} from 'rps/src/services/clientAPI';
import {rpsAPIRef} from 'rps/src/services/apiRef';
import {rpsResourceManager} from 'rps/src/services/resourceManager';
import {rpsAppSettings} from 'rps/src/services/appSettings';
import {rpsMessageManager} from 'rps/src/services/messageManager';
import {rpsUIFactory} from 'rps/src/services/uiFactory';
import {rpsViewFactory} from 'rps/src/services/viewFactory';
import {rpsViewModelFactory} from 'rps/src/services/viewModelFactory';
import {rpsStateManager} from 'rps/src/services/stateManager';
import {rpsEventManager} from 'rps/src/services/eventManager';
import {rpsSessionStorage, rpsLocalStorage} from 'rps/src/services/localStorage';
import {rpsEntityManager} from 'rps/src/services/entityManager';
import { rpsHubsService} from 'rps/src/services/hubsService';
import {rpsCurrencyResolver} from 'rps/src/services/currencyResolver';
import {rpsQuantityUnitResolver, rpsPriceUnitResolver } from 'rps/src/services/unitResolver';
import {rpsDurationResolver} from 'rps/src/services/durationResolver';
import { rpsUserInputManager } from 'rps/src/services/userInputManager';

import {rpsExceptionHandler} from 'rps/src/utils/exceptionHandler';
import {rpsUrlSerializer} from 'rps/src/utils/customUrlSerializer';

import {reportViewerComponent} from './tsComponents/reports/reportViewer';
import { CurrentCompanyComponent, LoadingComponent } from './app.routing';
import { notFoundComponent } from './tsComponents/notFound/notFound';

@NgModule({
    declarations: [rpsApp, reportViewerComponent, CurrentCompanyComponent, LoadingComponent, notFoundComponent],
    imports: [BrowserModule, FormsModule, rpsModule,rpsDesktopModule, rpsMenuModule, HttpModule, routing ],
    bootstrap: [rpsApp],
    providers: [
        { provide: ErrorHandler, useClass: rpsExceptionHandler },
        { provide: UrlSerializer, useClass: rpsUrlSerializer },
        COMPILER_PROVIDERS,
        rpsSession, rpsErrorManager, rpsEntityFactory, rpsProcessResultManager, rpsNotificationManager, UploadService,
        rpsAPI, rpsClientAPI, rpsAPIRef, rpsResourceManager, rpsAppSettings, rpsMessageManager, rpsUIFactory, rpsLocalStorage, rpsSessionStorage,
        rpsViewFactory, rpsViewModelFactory, rpsStateManager, rpsEventManager, rpsEntityManager, rpsBusyIndicatorManager,
        rpsCurrencyResolver, rpsQuantityUnitResolver, rpsPriceUnitResolver, rpsDurationResolver, rpsHubsService, rpsUserInputManager
    ]
})
export class rpsAppModule { }
