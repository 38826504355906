import { Component, OnChanges, SimpleChange, ViewChild, ContentChild, ViewContainerRef, TemplateRef, ElementRef } from '@angular/core';
import {rpsItemsControlToolbarService} from './itemsControlToolbarItem';

@Component({
    selector: 'rps-hierarchical-query-source-toolbar',
    template: `
    <div class="rps-sources-toolbar">
        <div class="rps-layout-padding">
            <div class="k-pager-wrap k-item" *ngIf="allowExcelExport()">
                <div id="buttons" class="rps-stack-panel rps-editor-fit-container">
                    <rps-button class="rps-stack-panel-item-right rps-layout-padding-horizontal"
                                rpsTemplate="ICON"
                                rpsIcon="fa fa-file-excel-o"
                                rpsLabel="{{resources.directives.EXCEL_EXPORT}}"
                                [rpsModel]="excelExport">
                    </rps-button>
                </div>
            </div>
            <ng-content></ng-content>
        </div>
    </div>
    `,    
    inputs: ['rpsItemTemplate', 'rpsItemTitle', 'rpsSource', 'rpsExportFormats'],
    providers: [rpsItemsControlToolbarService]
})
export class rpsHierarchicalQuerySourceToolbar implements OnChanges {

    public rpsItemTemplate: string;
    public rpsItemTitle: string;
    public rpsSource: rps.data.sources.HierarchicalQuerySource;
    public get resources() {
        return rps.app.resources;
    }
    constructor(private queryToolbarService: rpsItemsControlToolbarService) {       
    }

    ngOnChanges(changes: { [key: string]: SimpleChange }): any {
        if (changes['rpsItemTemplate'])
            this.queryToolbarService.itemTemplate = this.rpsItemTemplate;
        if (changes['rpsItemTitle'])
            this.queryToolbarService.itemTitle = this.rpsItemTitle;
    }

    private _exportFormats: string[] = [];
    public set rpsExportFormats(formats: string) {
        this._exportFormats = formats ? formats.split(' ') : [];
    }

    public allowExcelExport(): boolean {
        return this._exportFormats.indexOf('xlsx') != -1;
    }

    public excelExport: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        canExecute: (): rps.viewmodels.commands.CanExecuteResult => {
            if (this.allowExcelExport())
                return this.rpsSource.export.canExecute();
            else
                return rps.viewmodels.commands.CanExecuteResult.deny(null);
        },
        command: (): Promise<any> => {
            return this.rpsSource.export.execute({ format: 'xlsx' });
        }
    });
}

