﻿module rps.HTML5.viewmodels.Desktop {
    export class DesktopViewModelExtension extends DesktopViewModel {
        configure(configParams?: IParams): Promise<rps.viewmodels.BaseVM> {
            return super.configure().then(() => {
                this.Desktop.configure({
                    templateUrl: "./app/services/html5/components/desktop/Desktop.extension.html",
                    viewModelInstance: this
                });

                return this;
            });
        }
    }
}

rps.extensions.register(
    new rps.extensions.Extension(rps.HTML5.viewmodels.Desktop.DesktopViewModelExtension, rps.HTML5.viewmodels.Desktop.DesktopViewModel)
);