import {Injectable, Inject} from '@angular/core';
import {Http, Response, Headers, URLSearchParams} from '@angular/http';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import {rpsAppSettings} from './appSettings';

class ServicesClass implements rps.services.entityFactory.services {
    public BPM: rps.services.entities.BPMEntities = <any>{};
    public Workflow: rps.services.entities.WorkflowEntities = <any>{};
    public General: rps.services.entities.GeneralEntities = <any>{};
    public Maintenance: rps.services.entities.MaintenanceEntities = <any>{};
    public Manufacturing: rps.services.entities.ManufacturingEntities = <any>{};
    public Project: rps.services.entities.ProjectEntities = <any>{};
    public Purchase: rps.services.entities.PurchaseEntities = <any>{};
    public Sales: rps.services.entities.SalesEntities = <any>{};
    public Warehouse: rps.services.entities.WarehouseEntities = <any>{};
}

class typeExtension {
    extensions: Array<string>;
    resolvedType: string;

    constructor() {
        this.extensions = new Array<string>();
    }
}

export class EntityDefinition implements rps.services.entityFactory.IEntityDefinition<any> {

    constructor(public url: string, public parentEntityDefinition: EntityDefinition, public isNavigable: boolean, private entityFactory: rps.services.IEntityFactory) {            
    }

    public get typeName(): string {
        return this.url.split('/')[1];
    }

    public entityType: Function;
    public entityTypePromise: Promise<EntityDefinition>;

    public ensureLoaded(): Promise<rps.services.entityFactory.IEntityDefinition<any>> {
        return (<rpsEntityFactory>this.entityFactory).getFunction(this);
    }

    public createNew(saveOriginalData?: boolean): Promise<rps.entities.IBaseEntity> {
        return this.entityFactory.createNew(this,saveOriginalData);
    }

    public "get"(id: string | {}, saveOriginalData?: boolean, customQuery?: rps.data.sources.GetEntitySource): Promise<rps.entities.IBaseEntity>{
        return this.entityFactory.get(id, this, saveOriginalData,customQuery);
    }

    public getCompanySingleEntity(): Promise<rps.entities.IBaseEntity> {
        return this.entityFactory.getCompanySingleEntity(this);
    }

    public relatedEntityDefinitions: Array<EntityDefinition> = [];
}

@Injectable()
export class rpsEntityFactory implements rps.services.IEntityFactory {

    public entities: rps.services.entityFactory.services = new ServicesClass();
    /** @internal **/
    public isWorking: boolean = false;
    /** @rpsInternal **/
    public isApplyingPatch: boolean = false;

    /** @internal **/
    constructor( private http: Http) {
        rps.app.entityFactory = this;
    }

    public configure() {
        this.http.get(rps.app.appSettings.rpsAPIAddress + "clientapi/entity/configuration").
            map((res: Response, ix?: number) => {
                return res.json();
            }).subscribe((configuration: any) => {
                //Devuelve un objeto que tiene un array con servicios, y una clase por servicio donde indica la URL del tipo principal
                //colgando de este tipo, puede haber sub-tipos (de hijos, nietos,...), que se cargan a la vez que el principal,accediendo 
                //por la misma URL para facilitar el cacheo de peticiones
                for (var prop in configuration) {
                    configuration[prop].forEach((entity) => {
                        var newEntityDefinition: EntityDefinition = new EntityDefinition(entity.Url, null, !!entity.IsNavigable, this);
                        if (this.entities[prop]) {
                            this.entities[prop][entity.Name] = newEntityDefinition;
                            //Si tiene entidades relacionadas, se crean como modelos y se añaden como relacionadas
                            entity.RelatedEntities.forEach((relatedEntity) => {
                                this.entities[prop][relatedEntity.Name] = new EntityDefinition(relatedEntity.Url, newEntityDefinition, !!relatedEntity.IsNavigable, this);
                                newEntityDefinition.relatedEntityDefinitions.push(this.entities[prop][relatedEntity.Name]);
                            });
                        }
                    });
                }
            }, (error) => {
                debugger;
                throw new Error("Could not get entity configuration for app");
            });        
    }

    /** @internal **/
    private getEntityFile(url: string, queryParams?: any): Promise<any> {
        return new Promise((resolve, reject) => {
            var searchParams = new URLSearchParams();
            if (queryParams) {
                for (var param in queryParams)
                    searchParams.set(param, queryParams[param]);
            }

            this.http.get(
                rps.app.appSettings.rpsAPIAddress + "clientapi/" + url + "/entity",
                {
                    search: searchParams,
                    body: '',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                    })
                }).map((res: Response, ix?: number) => {
                    return res.json();
                }).subscribe((data: any) => {
                    resolve(data);
                }, (error: any) => {
                    reject(error);
                });
        });
    }

    /** @internal **/
    public getFunction(entityDef: EntityDefinition): Promise<EntityDefinition> {
        if (!entityDef.entityTypePromise) {
            entityDef.entityTypePromise = new Promise((resolve, reject) => {
                if (entityDef.entityType)
                    resolve(entityDef);
                else {
                    this.createFunction(entityDef).then(() => {
                        resolve(entityDef);
                    }, (error) => {
                        console.log("Error en el get " + error);
                        reject(error);
                    });
                }
            });
        }
        return entityDef.entityTypePromise;
    }

    /** @internal **/
    private createFunction(entityDef: EntityDefinition): Promise<EntityDefinition> {
        var funcPromise = new Promise<EntityDefinition>((resolve, reject) => {
            //Pedir los ficheros implicados en la creación del modelo (del principal)
            this.getEntityFile(entityDef.parentEntityDefinition ? entityDef.parentEntityDefinition.url : entityDef.url).then((result: string[]) => {
                //Por cada resultado, evaluarlo, y distinguir si es tipo principal o extension (que se guardan para aplicar más tarde)
                //var extensions: { [extendedClass: string]  : Array<Function>} = {};
                var mainEntityDef = entityDef.parentEntityDefinition || entityDef;
                var finalType = null;
                var finalTypeName = "";
                var mainTypes = [];
                var typeExtensions = new rps.collections.Dictionary<string, typeExtension>([]);
                for (var i = 0; i < result.length; i++) {
                    let jsCode = result[i];
                    //Si no es el primero, se supone que es una extensión; hay que reemplazar la clase de la que hereda
                    if (i > 0) {
                        //Extraer todas las cadenas de extensión del fichero
                        var match = null;
                        var extPattern = /new\s*rps.extensions.Extension\(\s*([\w\.]*)\s*\,*\s*([\w\.]*)\s*\)/g;
                        while (match = extPattern.exec(jsCode)) {
                            var extension: string = match[1];
                            let base: string = match[2];
                            //Primero, mira si está extendiendo la base o una personalizada
                            let baseName = Enumerable.From(base.split('.')).Last();
                            var mainBaseType = Enumerable.From(mainTypes).FirstOrDefault(null, mt => rps.extensions.functionName(mt) == baseName);
                            if (mainBaseType != null) {
                                //Extiende clase estándar; mirar si ya está extendida
                                if (!typeExtensions.containsKey(baseName)) {
                                    //Si no está extendida, se registra y se deja como está (heredando directamente)
                                    let ext = new typeExtension();
                                    ext.resolvedType = extension;
                                    ext.extensions.push(baseName);
                                    ext.extensions.push(Enumerable.From(extension.split('.')).Last());
                                    typeExtensions.add(baseName, ext);
                                }
                                else {
                                    //Si está extendida, reemplazar la cadena de herencia por la clase resuelta; lo mismo para todas las extensions
                                    let ext: typeExtension = typeExtensions[baseName];
                                    //} (BankAccountCompany.BankAccountCompanyVM));
                                    //    /^\s*\}\(((?:[\w\.\d]*\.||\()BankAccountCompanyVM)\)\);$/gm;
                                    ext.extensions.forEach((eBaseName) => {
                                        var inhPattern = new RegExp("^(\\s*\\}\\()((?:[\\w\\.\\d]*\.||\\()" + eBaseName + ")(\\)\\);)$", "gm");
                                        jsCode = jsCode.replace(inhPattern, "$1" + ext.resolvedType + "$3");
                                    });
                                    ext.resolvedType = extension;
                                    ext.extensions.push(Enumerable.From(extension.split('.')).Last());
                                }
                            }
                        }
                    }
                    var types = eval.call(this, jsCode);
                    for (var obj in types) {
                        // Meterlo en el modeldefinition correspondiente
                        if (!(types[obj] instanceof rps.extensions.Extension)) {
                            if (mainEntityDef.typeName === obj)
                                mainEntityDef.entityType = types[obj];
                            else {
                                mainEntityDef.relatedEntityDefinitions.forEach((rmd) => {
                                    if (rmd.typeName === obj) {
                                        rmd.entityType = types[obj];
                                    }
                                });
                            }
                            mainTypes.push(types[obj]);
                        }
                    }
                }

                //Recorrer el diccionario para poner los tipos resueltos
                typeExtensions.keys().forEach((key) => {
                    let ext: typeExtension = typeExtensions[key];

                    //Sustituir el tipo correspondiente
                    var typeToExtend = Enumerable.From(mainTypes).FirstOrDefault(undefined, mt => rps.extensions.functionName(mt) === key);
                    if (typeToExtend) {
                        //Buscar el tipo final
                        var finalNameParts: Array<string> = ext.resolvedType.split('.');
                        var finalTarget = window;
                        for (var i = 0; i < finalNameParts.length - 1; i++) {
                            if (finalTarget[finalNameParts[i]])
                                finalTarget = (finalTarget[finalNameParts[i]]);
                        }

                        if (finalTarget && finalTarget[finalNameParts[finalNameParts.length - 1]]) {
                            let resolvedType = finalTarget[finalNameParts[finalNameParts.length - 1]];
                            if (mainEntityDef.entityType == typeToExtend)
                                mainEntityDef.entityType = resolvedType;
                            else {
                                for (var i = 0; i < mainEntityDef.relatedEntityDefinitions.length; i++) {
                                    if (mainEntityDef.relatedEntityDefinitions[i].entityType == typeToExtend) {
                                        mainEntityDef.relatedEntityDefinitions[i].entityType = resolvedType;
                                        break;
                                    }
                                }
                                
                            }
                        }
                    }
                });

                resolve(entityDef);
            }).catch((error) => {
                //Hay un error en el get de los modelos; es un error bastante gordo qeu habría que tratar
                alert("TODO: error al hacer get de entidades");
                console.error("Error: " + error.toString());
            });
        });
        return funcPromise;
    }

    /** @internal **/
    private cachedTypes = new rps.collections.Dictionary<string, Function>([]);
    /** @rpsInternal **/
    public resolveEntityType(entityType: Function): Function {
        //Mirar si está cacheado
        var entityUrl = entityType["entityUrl"];
        if (this.cachedTypes.containsKey(entityUrl))
            return this.cachedTypes[entityUrl];

        for (var service in this.entities) {
            for (var md in this.entities[service]) {
                if (this.entities[service][md].entityType && entityUrl == this.entities[service][md].url) {
                    //Cachear y devolver
                    this.cachedTypes.add(entityUrl, this.entities[service][md].entityType);
                    return this.cachedTypes[entityUrl];
                }
            }
        }

        return entityType;
    }

    /** @internal **/
    public findEntityDefinition = (url: string): EntityDefinition => {
        for (var service in this.entities) {
            for (var md in this.entities[service]) {
                if (url == this.entities[service][md].url) {
                    return this.entities[service][md];
                }
            }
        }
        return null;
    }

    /** @internal **/
    private applyMixins(derivedCtor: any, baseCtors: any[]) {
        baseCtors.forEach(baseCtor => {
            Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
                if (name !== 'constructor') {
                    //Tratar diferente las propiedades get/set
                    var propDescriptor = Object.getOwnPropertyDescriptor(baseCtor.prototype, name);
                    if (propDescriptor.get || propDescriptor.set) {
                        Object.defineProperty(derivedCtor.prototype,name, {
                            enumerable:propDescriptor.enumerable,
                            configurable: propDescriptor.configurable,
                            get: propDescriptor.get,
                            set: propDescriptor.set
                        });
                    }
                    else
                        derivedCtor.prototype[name] = baseCtor.prototype[name];
                }
            });
        });
    }

    /** @internal **/
    public createNew(url: string, saveOriginalData?: boolean): Promise<any>;
    /** @internal **/
    public createNew(entityDef: rps.services.entityFactory.IEntityDefinition<any>, saveOriginalData?: boolean): Promise<any>;
    /** @internal **/
    public createNew(param?: any, saveOriginalData?:boolean): Promise<any> {
        var entityDef: rps.services.entityFactory.IEntityDefinition<any>;
        if (rps.object.isString(param))
            entityDef = this.findEntityDefinition(param);
        else
            entityDef = param;

        return Promise.all([
            this.getFunction(<EntityDefinition>entityDef),
            rps.app.api.get({ url: entityDef.url + "/new", urlType: rps.services.UrlType.Relative})
        ]).then((retValues) => {
            ///Crear un nuevo modelo del tipo buscado y copiar las propiedades que ha traido el get/new
            var typeToCreate: any = (<any>entityDef).entityType;
            var newEntity: rps.entities.BaseEntity = rps.object.instantiate(typeToCreate);
            try {
                this.isWorking = true;

                newEntity.merge(retValues[1], false,"or");

                //Si hay que guardar los datos para aplicar un patch, crear una copia
                if (saveOriginalData) {
                    newEntity.__originalEntity = rps.object.instantiate(typeToCreate);
                    newEntity.__originalEntity.merge(retValues[1], false,"cp");
                }

                newEntity.__entityUrl = entityDef.url;
                newEntity.__isNew = true;
                newEntity.__isModified = false;
            }
            finally{
                this.isWorking = false;
            }
            return newEntity;
        });
    }

    /** @internal **/
    public get(pkValue: string | {}, entityUrl: string, saveOriginalData?: boolean, customQuery?: rps.data.sources.GetEntitySource): Promise<any>
    /** @internal **/
    public get(pkValue: string | {}, entityDefinion: EntityDefinition, saveOriginalData?: boolean, customQuery?: rps.data.sources.GetEntitySource): Promise<any>
    /** @internal **/
    public get(pkValue: string | {}, entityDefinionOrUrl?: EntityDefinition | string, saveOriginalData?: boolean, customQuery?: rps.data.sources.GetEntitySource): Promise<any> {
        var promiseArray: Array<Promise<any>> = [];
        var entityDef: EntityDefinition;
        if (rps.object.isString(entityDefinionOrUrl))
            entityDef = this.findEntityDefinition(<string>entityDefinionOrUrl);
        else
            entityDef = <EntityDefinition>entityDefinionOrUrl;
        promiseArray.push(this.getFunction(entityDef));
        if (rps.object.isString(pkValue)) {
            //Mirar si usa query estándar o personalizada
            if (customQuery)
                promiseArray.push(customQuery.get(<string>pkValue));
            else
                promiseArray.push(rps.app.api.get({
                    url: entityDef.url,
                    queryParams: { id: pkValue },
                    urlType: rps.services.UrlType.Relative
                }));
        }
        else
            promiseArray.push(Promise.resolve(pkValue));

        return Promise.all(promiseArray).then((retValues) => {
            ///Crear un nuevo modelo del tipo buscado y copiar las propiedades que ha traido el get/new
            var typeToCreate: any = (<any>entityDef).entityType;
            var newEntity: rps.entities.BaseEntity = rps.object.instantiate(typeToCreate);
            try {
                this.isWorking = true;

                newEntity.merge(retValues[1], false,"or");
                    
                //Si hay que guardar los datos para aplicar un patch, crear una copia
                if (saveOriginalData) {
                    newEntity.__originalEntity = rps.object.instantiate(typeToCreate);
                    newEntity.__originalEntity.merge(retValues[1], false,"cp");
                }

                newEntity.__entityUrl = entityDef.url;
                newEntity.__isNew = false;
                newEntity.__isModified = false;
            }
            finally {
                this.isWorking = false;
            }
            return newEntity;
        });
    }

    /** @internal **/
    public getCompanySingleEntity(entityDef: EntityDefinition): Promise<any> {
        return Promise.all([
            this.getFunction(entityDef),
            rps.app.api.get({
                url: entityDef.url,
                queryParams: { id: rps.app.session.company },
                urlType:rps.services.UrlType.Relative
            })
        ]).then((retValues) => {
            ///Crear un nuevo modelo del tipo buscado y copiar las propiedades que ha traido el get/new
            var typeToCreate: any = (<any>entityDef).entityType;
            var newEntity: rps.entities.BaseEntity = rps.object.instantiate(typeToCreate);
            try {
                this.isWorking = true;

                newEntity.merge(retValues[1],false);
                newEntity.__entityUrl = entityDef.url;
                newEntity.__isNew = false;
                newEntity.__isModified = false;
            }
            finally {
                this.isWorking = false;
            }
            return newEntity;
            }).catch(() => {
                return this.createNew(entityDef);
        });            
    }
}

