module rps.HTML5.viewmodels.Logon {

    export class LogonVMExtension extends LogonVM{
        protected configure(configParams?: rps.IParams): Promise<rps.viewmodels.BaseVM> {
            return super.configure(configParams).then(() => {
                if (rps.app.session.logged) {
                    rps.app.stateManager.goToDesktop(true);
                }
                return this;
            });
        }

        public logOnExecute(): Promise<any> {
            return new Promise<LogonVM>((resolve) => {

                if (this.codUser.value) {
                    rps.app.session.logOn(this.codUser.value, this.password.value).
                        then((result) => {
                            //Mirar si tengo un 500 en la respuesta
                            if (result.status != 500) {
                                rps.app.stateManager.goToDesktop(true);
                                resolve(this);
                            }
                            else {
                                let text = result.text();
                                let errorMatch = /\{\"Code\":\"([^\"]+)\"\,\"Description\"\:\"([^\"]+)\"\}/.exec(text);
                                if (errorMatch) {
                                    //Sacar mensaje de error
                                    rps.app.messageManager.show({
                                        message: errorMatch[2],
                                        messageButton: services.MessageButton.Ok,
                                        messageType: services.MessageType.Error
                                    }).then(() => {
                                        //Si contraseña expirada, redirigir a ventana
                                        if (errorMatch[1] == "PASSWORD_EXPIRED_ERROR")
                                            this.changePasswordNavigationCommand.execute();
                                        resolve(this);
                                    });
                                }
                                else
                                    resolve(this);
                            }
                        }, (reason) => {
                            rps.app.messageManager.show({
                                message: Logon.resources.Logon.MSG_UserPassword_Invalid,
                                messageButton: services.MessageButton.Ok,
                                messageType: services.MessageType.Error
                            }).then(() => {
                                resolve(this);
                            });
                        });
                }
                else {
                    rps.app.messageManager.show({
                        message: Logon.resources.Logon.MSG_UserPassword_Invalid,
                        messageButton: services.MessageButton.Ok,
                        messageType: services.MessageType.Error
                    }).then(() => {
                        resolve(this);
                    });
                }
            });
        }

        public logOnCanExecute(): rps.viewmodels.commands.CanExecuteResult {
            var reasons: Array<string> = new Array<string>();
            if (this.codUser.hasValue())
                return rps.viewmodels.commands.CanExecuteResult.allow();
            else {
                var reasons: Array<string> = new Array<string>();
                if (!this.codUser.hasValue())
                    reasons.push("Username is required");
                return rps.viewmodels.commands.CanExecuteResult.deny(reasons);
            }
        }

        public windowsLogOnExecute(): Promise<any> {
            return new Promise<LogonVM>((resolve) => {

                rps.app.session.windowsLogOn().
                    then((result) => {
                        //Mirar si tengo un 500 en la respuesta
                        if (result.status != 500) {
                            rps.app.stateManager.goToDesktop(true);
                            resolve(this);
                        }
                        else {
                            let text = result.text();
                            let errorMatch = /\{\"Code\":\"([^\"]+)\"\,\"Description\"\:\"([^\"]+)\"\}/.exec(text);
                            if (errorMatch) {
                                //Sacar mensaje de error
                                rps.app.messageManager.show({
                                    message: errorMatch[2],
                                    messageButton: services.MessageButton.Ok,
                                    messageType: services.MessageType.Error
                                }).then(() => {
                                    //Si contraseña expirada, redirigir a ventana
                                    if (errorMatch[1] == "PASSWORD_EXPIRED_ERROR")
                                        this.changePasswordNavigationCommand.execute();
                                    resolve(this);
                                });
                            }
                            else
                                resolve(this);
                        }
                    }, (reason) => {
                        alert(Logon.resources.Logon.MSG_UserPassword_Invalid);
                        resolve(this);
                    });
            });
        }

        public changePasswordNavigationCommand_ok(childVM: ChangePasswordVM): Promise<any> {
            //La llamada se hace a través de método normal, no de acción, para que funcione el tema de cambio de variables de sesión
            return rps.app.api.invokeMethod("/Security/Session", "ChangePassword", {
                "codUser": childVM.codUser.value,
                "oldPassword": childVM.oldPassword.value,
                "newPassword": childVM.newPassword.value
            }).then((data) => {
                if (data && data.Result) {
                    rps.app.notificationManager.show({
                        message: rps.app.resources.components.settings.PASSWORD_CHANGED,
                        notificationType: rps.services.NotificationType.Success
                    });
                }
                else {
                    rps.app.notificationManager.show({
                        message: rps.app.resources.components.settings.ERROR_CHANGING_PASSWORD,
                        notificationType: rps.services.NotificationType.Error
                    });
                }
                return data;
            }).catch((error) => {
                rps.app.notificationManager.show({
                    message: rps.app.resources.components.settings.ERROR_CHANGING_PASSWORD,
                    notificationType: rps.services.NotificationType.Error
                });
                throw error;
            }).finally(() => {
                
            });
        }

        public changePasswordNavigationCommand_allowOk(childVM: ChangePasswordVM): rps.viewmodels.commands.CanExecuteResult {
            if (!childVM.codUser.hasValue())
                return rps.viewmodels.commands.CanExecuteResult.deny(["User must be specified"]);
            if (!childVM.newPassword.hasValue())
                return rps.viewmodels.commands.CanExecuteResult.deny(["Password cannot be empty"]);
            else if (childVM.newPassword.value != childVM.newPasswordConfirmation.value)
                return rps.viewmodels.commands.CanExecuteResult.deny(["Password confirmation is different from new password"]);

            return rps.viewmodels.commands.CanExecuteResult.allow();
        }

        public onSubmit() {
            this.logOn.execute();
        }
    }

    export class ChangePasswordVMExtension extends ChangePasswordVM {


    }
    

}

rps.extensions.register(
    new rps.extensions.Extension(rps.HTML5.viewmodels.Logon.LogonVMExtension, rps.HTML5.viewmodels.Logon.LogonVM),
    new rps.extensions.Extension(rps.HTML5.viewmodels.Logon.ChangePasswordVMExtension, rps.HTML5.viewmodels.Logon.ChangePasswordVM)
);
