import { Component } from '@angular/core';
import {rpsRowsGroup} from '../layouts/rowsGroup';
import {rpsRow} from '../layouts/row';
import {rpsColumns} from '../layouts/columns';
import {rpsButton} from '../editors/button';
import {rpsSearchBox} from '../editors/searchBox';
import {rpsFilters} from './filters';

@Component({
    selector: 'rps-entity-source-tools',
    template: `
        <div>
            <rps-rows-group>
                <rps-row>
                    <rps-button rpsColumns="2"
                                rpsTemplate="TEXT_WITH_ICON"
                                rpsLabel="{{resources.directives.QUERY}}"
                                rpsIcon="fa fa-refresh"
                                [rpsModel]="rpsSource?.loadItems"
                                rpsType="PRIMARY">
                    </rps-button>
                    <div rpsColumns="9">
                    </div>
                </rps-row>
                <rps-filters [rpsFiltersManager]="rpsSource?.filtersManager"
                             *ngIf="isFiltrable">
                </rps-filters>
            </rps-rows-group>                
        </div>
    `,
    inputs: ['rpsSource', 'rpsIsFiltrable'],
})
export class rpsEntitySourceTools{
    public get resources() {
        return rps.app.resources;
    }

    public rpsSource: rps.data.sources.EntitySource;   
    public isFiltrable: boolean = false;
    public get rpsIsFiltrable(): string {
        if (this.isFiltrable)
            return "true";
        else
            return "false";
    }
    public set rpsIsFiltrable(newValue: string) {
        if (newValue == "true")
            this.isFiltrable = true;
        else
            this.isFiltrable = false;
    }

    constructor() {
    }
}