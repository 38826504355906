import { Component } from '@angular/core';

@Component({
    template: `
        <div class="rps-div rps-not-found">
            <h3 class="rps-layout-padding">{{resources.errors.ERR_NOT_FOUND}}</h3>
            <h5 class="rps-layout-padding">{{resources.errors.ERR_NOT_FOUND_DESCRIPTION}}</h5>
        </div>`,
})
export class notFoundComponent {
    public get resources() {
        return rps.app.resources;
    }    
}