import {Injectable } from '@angular/core';

@Injectable()
export class rpsBusyIndicatorManager implements rps.services.IBusyIndicatorManager {
    public isBusy: boolean = false;
    public isBusyChanged: rps.services.IEventEmitter<boolean> = rps.app.eventManager.createEmitter<boolean>();

    constructor() {
    }

    setIsBusy(newValue: boolean): void {        
        if (this.isBusy != newValue) {
            this.isBusy = newValue;
        }
    }

    configure() {
        rps.app.busyIndicatorManager = this;
    }
}
