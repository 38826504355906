import {
    Component, OnChanges, SimpleChange, Output, EventEmitter, ContentChild,
    TemplateRef, ViewChild, ViewContainerRef, AfterContentInit,OnDestroy
} from '@angular/core';
import {rpsItemsControlToolbarService, rpsItemsControlToolbarItem} from './itemsControlToolbarItem';

@Component({
    selector: 'rps-array-toolbar',
    template: `
        <div class="rps-array-toolbar">
            <div>
                <div class="rps-stack-panel">
                    <div class="rps-stack-panel-item">
				        <rps-button rpsTemplate="TEXT_WITH_ICON"
							        rpsLabel="{{resources.directives.NEW}}"
							        rpsIcon="fa fa-plus"
							        [rpsModel]="addNew"
                                    *ngIf="allowAddNew">
				        </rps-button>
                    </div>
                    <div #toolbarContainer>
                    </div>
                    <div class="rps-stack-panel-item"
                         *ngIf="allowChangeView">
                        <div class="rps-stack-panel">
                            <div class="rps-stack-panel-item rps-editor-right-fit-container"
                                 *ngIf="gridViewValue">
                                <rps-toggle-button rpsTemplate="ICON"
                                                   rpsIcon="fa fa-align-justify"
                                                   [rpsModel]="gridViewSet">
                                </rps-toggle-button>
                            </div>
                            <div class="rps-stack-panel-item rps-editor-left-fit-container"
                                 *ngIf="cardViewValue">
                                <rps-toggle-button rpsTemplate="ICON"
                                                   rpsIcon="fa fa-th-large"
                                                   [rpsModel]="cardViewSet">
                                </rps-toggle-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rps-layout-padding">
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    `,
    inputs: ['rpsSource', 'rpsViews', 'rpsSelectedView', 'rpsSelectionMode', 'rpsAddModel', 'rpsItemTemplate','rpsItemTitle'],
    providers: [rpsItemsControlToolbarService]
})
export class rpsArrayToolbar implements OnChanges, AfterContentInit,OnDestroy {
    @Output() rpsSelectedViewChanged = new EventEmitter();

    @ViewChild('toolbarContainer', { read: ViewContainerRef })
    private toolbarContainer: ViewContainerRef;
    @ContentChild("toolbarTemplate")    
    public toolbarTemplate: TemplateRef<any>;

    private queryToolbarItems: Array<rpsItemsControlToolbarItem> = new Array<rpsItemsControlToolbarItem>();
    private items: kendo.data.DataSource;
    //Variable creada para evitar que se queden todos los tipos de vistas seleccionadas
    private refreshingViewsValues: boolean = false;
    private allowAddSubscription:rps.services.ISubscription;

    public rpsSource: rps.viewmodels.properties.BaseVMCollectionProperty<rps.viewmodels.BaseVM, rps.viewmodels.ObservableArray<rps.viewmodels.BaseVM>>;
    public rpsViews: string;    
    set rpsSelectedView(newValue: string) {
        this.queryToolbarService.selectedView = newValue;
        this.rpsSelectedViewChanged.emit(newValue);
    }
    get rpsSelectedView(): string {
        return this.queryToolbarService.selectedView;
    }

    public rpsSelectionMode: string = "Single";
    public rpsAddModel: rps.viewmodels.properties.LinkProperty;
    public rpsItemTemplate: string;
    public rpsItemTitle: string;

    public allowChangeView: boolean = false;
    //En el caso de que haya una vista de tipo grid view, se guarda su valor y se mantiene un boleano indicando si es la seleccionada o no, esto se hace para cambiar de vista con toggleButtons (lo mismo para el cardView)
    public gridViewValue: string = null;
    public cardViewValue: string = null;
    public viewsProperty: rps.viewmodels.properties.ComboProperty;
    public gridViewSet: rps.viewmodels.properties.VMProperty<boolean>;
    public cardViewSet: rps.viewmodels.properties.VMProperty<boolean>;
    public selectable: number = -1;
    public allowAddNew: boolean = false;

    public get resources() {
        return rps.app.resources;
    }

    constructor(private queryToolbarService: rpsItemsControlToolbarService) {
        this.queryToolbarService.itemAdded$.subscribe(this.addQueryToolbarItem);
        this.gridViewSet = new rps.viewmodels.properties.VMProperty<boolean>({
            target: this,
            initialValue: false
        });
        this.gridViewSet.propertyChanged.subscribe((e) => {
            //Si el usuario a deschequedo el botón, no se hace caso, ya que no se sabe que vista seleccionar
            if (this.refreshingViewsValues || e.newValue)
                this.gridViewSetPropertyChanged(e);
            else {
                setTimeout(() => {
                    this.gridViewSet.value = true;
                });
            }

        });
        this.cardViewSet = new rps.viewmodels.properties.VMProperty<boolean>({
            target: this,
            initialValue: false
        });
        this.cardViewSet.propertyChanged.subscribe((e) => {
            //Si el usuario a deschequedo el botón, no se hace caso, ya que no se sabe que vista seleccionar
            if (this.refreshingViewsValues || e.newValue)
                this.cardViewSetPropertyChanged(e);
            else {
                setTimeout(() => {
                    this.cardViewSet.value = true;
                });
            }
        });        
    }

    ngAfterContentInit() {
        if (this.toolbarTemplate) {
            this.toolbarContainer.createEmbeddedView<any>(this.toolbarTemplate);            
        }
    }

    addQueryToolbarItem = (queryToolbarItem: rpsItemsControlToolbarItem) => {
        this.queryToolbarItems.push(queryToolbarItem);
    }

    ngOnChanges(changes: { [key: string]: SimpleChange }): any {
        if (changes['rpsSelectedView'])
            this.selectedViewChanged();
        if (changes['rpsSelectionMode'])
            this.rpsSelectionModeChanged();
        if (changes['rpsViews'])
            this.viewsChanged();
        if (changes['rpsSource'])
            this.sourceChanged();
        if (changes['rpsItemTemplate'])
            this.queryToolbarService.itemTemplate = this.rpsItemTemplate;
        if (changes['rpsItemTitle'])
            this.queryToolbarService.itemTitle = this.rpsItemTitle;
    }

    sourceChanged() {
        if (this.allowAddSubscription)
            this.allowAddSubscription.unsubscribe();
        if (this.rpsSource) {
            this.allowAddNew = this.rpsSource.allowAdd;            
            this.allowAddSubscription = this.rpsSource.propertyChanged.subscribe((value) => {
                if (value.propertyName == "allowAdd")
                    this.allowAddNew = this.rpsSource.allowAdd;
            });
        }
        else
            this.allowAddNew = false;
    }

    viewsChanged() {
        //rpsViews, es de tipo string, para poder establecer valores estaticos en el HTML y de esta manera, evitar un binding
        var views: Array<rps.data.sources.IView>;
        if (rps.string.isNullOrEmpty(this.rpsViews))
            views = new Array<rps.data.sources.IView>();
        else
            views = JSON.parse(this.rpsViews);
                
        if (Enumerable.From<rps.data.sources.IView>(views).Any()) {
            if (rps.string.isNullOrEmpty(this.rpsSelectedView))
                this.rpsSelectedView = Enumerable.From<rps.data.sources.IView>(views).First().value;
        }
        else
            this.rpsSelectedView = null;
        this.selectedViewChanged();

        //Hay una propiedad como para manejar la vista con un combo, pero se mantiene con toggleButtons, de todas maneras, se deja la propiedad, ya que se usa para manejar el elemento seleccionado
        this.viewsProperty = new rps.viewmodels.properties.ComboProperty({
            target:this,
            initialValue: this.rpsSelectedView,
            displayPath: "name",
            valuePath:"value",
            relatedItems: views
        });
        this.viewsProperty.propertyChanged.subscribe((e) => {
            this.viewsPropertyPropertyChanged(e);
        });

        var viewsEnumerable: linq.Enumerable<rps.data.sources.IView> = Enumerable.From<rps.data.sources.IView>(views);
        if (viewsEnumerable.Count() > 1) {
            this.allowChangeView = true;
            if (viewsEnumerable.Any(v => v.type == rps.data.sources.ViewType.Grid))
                this.gridViewValue = viewsEnumerable.First(v => v.type == rps.data.sources.ViewType.Grid).value;
            else
                this.gridViewValue = null;          
            if (viewsEnumerable.Any(v => v.type == rps.data.sources.ViewType.Card))
                this.cardViewValue = viewsEnumerable.First(v => v.type == rps.data.sources.ViewType.Card).value;
            else
                this.cardViewValue = null;
        }
        else {
            this.allowChangeView = false;
            this.gridViewValue = null;            
            this.cardViewValue = null;
            
        }
        this.refreshViewsValues();
    }

    refreshViewsValues() {
        this.refreshingViewsValues = true;
        if (!this.rpsSelectedView) {
            this.gridViewSet.value = false;
            this.cardViewSet.value = false;
        }
        else if (this.gridViewValue == this.rpsSelectedView) {
            this.gridViewSet.value = true;
            this.cardViewSet.value = false;
        }
        else if (this.cardViewValue == this.rpsSelectedView){
            this.gridViewSet.value = false;
            this.cardViewSet.value = true;
        }
        this.refreshingViewsValues = false;
    }

    viewsPropertyPropertyChanged(e: rps.viewmodels.properties.VMPropertyChange) {        
        if (e.propertyName == "value") {
            this.rpsSelectedView = this.viewsProperty.value;
            this.selectedViewChanged();
            this.refreshViewsValues();
        }
    }

    gridViewSetPropertyChanged(e: rps.viewmodels.properties.VMPropertyChange) {
        if (e.propertyName == "value" && e.newValue)
            this.viewsProperty.value = this.gridViewValue;
    }

    cardViewSetPropertyChanged(e: rps.viewmodels.properties.VMPropertyChange) {
        if (e.propertyName == "value" && e.newValue)
            this.viewsProperty.value = this.cardViewValue;
    }

    rpsSelectionModeChanged() {
        if (this.rpsSelectionMode == "Single")
            this.selectable = 0;
        else if (this.rpsSelectionMode == "Multiple")
            this.selectable = 1;
    }

    selectedViewChanged() {
        Enumerable.From<rpsItemsControlToolbarItem>(this.queryToolbarItems).Where(ti => ti.isSelected && ti.rpsItemValue != this.rpsSelectedView).forEach((ti: rpsItemsControlToolbarItem) => {
            ti.isSelected = false;
        });
        Enumerable.From<rpsItemsControlToolbarItem>(this.queryToolbarItems).Where(ti => !ti.isSelected && ti.rpsItemValue == this.rpsSelectedView).forEach((ti: rpsItemsControlToolbarItem) => {
            ti.isSelected = true;
        });
    }

    public addNew: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        canExecute: (): rps.viewmodels.commands.CanExecuteResult => {
            if (this.rpsSource)  
                return this.rpsSource.canAdd();
            return rps.viewmodels.commands.CanExecuteResult.deny(null);
        },
        command: (): Promise<any> => {            
            if (!rps.object.hasValue(this.rpsAddModel))
                return this.rpsSource.addCommand.execute();
            else
                return this.rpsAddModel.go();
        }
    });

    ngOnDestroy() {
        if (this.allowAddSubscription)
            this.allowAddSubscription.unsubscribe();
    }
}

 