import {Component, ElementRef, Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsReadOnlyEditor} from './readOnlyEditorBase'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'

@Component({
    selector: 'rps-read-only-entity-link',
    template: `
        <div class="rps-editor" 
             [rpsSemanticState]="rpsModel?.semanticState" 
             [class.rps-disabled-editor]="disabled"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label" 
                    [attr.for]="myId">
                {{rpsLabel}}<font *ngIf="isRequired" color="red"> *</font>
            </label>
            <div class="rps-entity-link-container rps-navigable-read-only-lookup">
                <div>
                    <div class="rps-editor-editor rps-read-only-editor-viewer rps-read-only-semantic-state">
                        <div class="rps-img-2 rps-entity-link-image" *ngIf="image">
                            <img class="rps-img" src="{{image}}" />
                        </div>
                        <div *ngIf="!link" >
                            {{completeDescriptor}}
                        </div>
                        <div *ngIf="link">
                            <a tabindex="-1" [routerLink]="link" [queryParams]="linkQueryParams">{{completeDescriptor}}</a>
                        </div>
                        <div class="rps-lookup-navigate-to-button rps-editor-fit-container" 
                                 *ngIf="isNavigable">
                            <rps-button rpsTemplate="ICON"
                                        rpsIcon="fa fa-lg fa-share-square-o" 
                                        [rpsModel]="navigateToCommand"
                                        rpsPreventTabindex="true">
                            </rps-button>
                        </div>
                        <div class="contextContainer" *ngIf="!isEditing">
                            <ul id="kendoContextMenu" class="rps-navigable-lookup-context-menu"></ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsReadOnlyEditor.getComponentInputs()
})
export class rpsReadOnlyEntityLink extends rpsReadOnlyEditor<rps.data.FullDescriptor, rps.viewmodels.properties.VMProperty<rps.data.FullDescriptor>>  {    
    private mainDescriptor: string;
    private secondaryDescriptor: string;
    private completeDescriptor: string;
    private link: Array<any>;
    public linkQueryParams: rps.IParams;
    private image: string;
    private isNavigable = false;
    private static kendoContextMenuItemTemplate: string = `
        <div class="navigable-states-container">
            <div class=" rps-img-2 navigable-state-image"><img class="rps-img" src="{0}"/></div>       
            <div class="navigable-state-description">{1}</div>                        
        </div>
    `;
    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    setValue(newValue: rps.data.FullDescriptor) {
        super.setValue(newValue);
        if (newValue) {
            this.mainDescriptor = newValue.MainDescriptor;
            this.secondaryDescriptor = newValue.SecondaryDescriptor;
            if (newValue.SecondaryDescriptor)
                this.completeDescriptor = newValue.MainDescriptor + " (" + newValue.SecondaryDescriptor + ")";
            else
                this.completeDescriptor = newValue.MainDescriptor;
            this.image = newValue.Image;
            if (rps.object.hasValue(newValue.UILink)) {
                var link = rps.app.stateManager.createRouterLink(newValue.UILink);
                this.link = link.routerLink;
                this.linkQueryParams = link.queryParams;
            }
            this.isNavigable = newValue.EntityType && rps.app.entityFactory.findEntityDefinition(newValue.EntityType).isNavigable;
        }
        else {
            this.mainDescriptor = "";
            this.secondaryDescriptor = "";
            this.completeDescriptor = "";
            this.image = "";
            this.isNavigable = false;
            this.link = rps.app.stateManager.notFoundLink;
        }
    }

    navigateToCommand: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: this.openContextMenu,
        //canExecute: this.canNavigateTo
    });
    openContextMenu(): Promise<any> {

        return rps.app.entityManager.getNavigableStates(this.value.EntityType,
            this.value.EntityID,
            rps.app.stateManager.getCurrentCompany())
            .then((states: Array<rps.data.NavigableState>) => {
                //Obtenemos los estados de la navegacion y los metemos al dataSource del ContextMenu
                //Se guarda el TargetState en el urlSelect para despues poder acceder al dato y hacer la navegacion                
                var contextMenuDataSource: Array<{ text: string; url: string; urlSelect: rps.data.IUILinkDefinition; encoded: boolean; }> = new Array<{ text: string; url: string; urlSelect: rps.data.IUILinkDefinition; encoded: boolean; }>();
                Enumerable.From(states).OrderBy(s => s.Description).forEach((state) => {
                    contextMenuDataSource.push({
                        text: rps.string.format(rpsReadOnlyEntityLink.kendoContextMenuItemTemplate, state.Image, state.Description),
                        url: rps.app.stateManager.href(state.TargetState),
                        urlSelect: state.TargetState,
                        encoded: false
                    });
                });

                //Máximo de 10 items por columna
                let navigationListColumns = Math.trunc(contextMenuDataSource.length / 10) + 1;

                var kendoContextMenu: kendo.ui.ContextMenu;
                var container = $(this.elementRef.nativeElement).find("#kendoContextMenu");
                if (container.length > 0) {

                    container.css('columns', navigationListColumns + " auto");

                    //Filter "#none" para que solo se abra el contextMenu donde ejecutemos el "Open". El elemento "#none" no existe.
                    kendoContextMenu = $(this.elementRef.nativeElement)
                        .find("#kendoContextMenu")
                        .kendoContextMenu({
                            dataSource: contextMenuDataSource,
                            orientation: "vertical",
                            filter: "#none",
                            close: (e) => {
                                kendoContextMenu.destroy();
                                $(this.elementRef.nativeElement).find(".contextContainer").html("<ul id='kendoContextMenu' class='rps-navigable-lookup-context-menu' style='columns:" + navigationListColumns + ";' ></ul>");

                            },
                            select: (e) => {
                                //Evitamos que vaya directamente para no romper la navegacion y obtenemos la ruta del DataSource para crear un RouterLink
                                e.preventDefault();
                                var index = $(e.item).index();
                                rps.app.stateManager.goTo(e.sender.options.dataSource[index].urlSelect);
                            }
                        })
                        .data("kendoContextMenu");
                    //Ejecutamos el open para forzar la apertura del contextMenu indicando donde tiene que abrirlo.
                    kendoContextMenu.open($(this.elementRef.nativeElement).find(".rps-lookup-navigate-to-button"));
                }
            });
    }
}