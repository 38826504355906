import { Component, Input, AfterViewInit, ElementRef, ChangeDetectionStrategy, OnChanges, SimpleChange } from '@angular/core';

@Component({
    selector: 'rps-progressbar',
    template: `
    <div class="rps-progress-bar-container">
        <div class="rps-progress-bar-label">{{progressLabel}}</div>
        <div [hidden]="value < 0">
            <div class="progressbar"></div>
        </div>
    </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class rpsProgressBar implements AfterViewInit, OnChanges {
    private kendoProgressBar: kendo.ui.ProgressBar;   
    @Input()
    public value: number = -1;
    @Input()
    public progressLabel: string;
    @Input()
    public min: number = 0;
    @Input()
    public max: number = 100;

    constructor(private _elementRef: ElementRef) {

    }

    ngAfterViewInit(): any {
        this.kendoProgressBar = this.createProgressBar($(this._elementRef.nativeElement).find(".progressbar"));

    }

    private createProgressBar(element: JQuery): kendo.ui.ProgressBar {
        return element.kendoProgressBar({
            showStatus: false,
            max: this.max,
            min: this.min
        }).data("kendoProgressBar");
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes["value"] && this.kendoProgressBar) {
            this.kendoProgressBar.value(this.value);
        }
    }


}