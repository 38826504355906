import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

/**
 * Esta directiva se usa para hacer que un elemento coja el foco nada más inicializarse (caso de un elemento dentro de un template con ngIf...)
 */
@Component({
    selector: 'rps-pdf',
    template: `
        <div *ngIf=rpsData>
            <object class="pdf-viewer-object" type="application/pdf" [attr.data]="safeResourceUrl"></object>
        </div>
        <div *ngIf=!rpsData class="rps-unloaded-query pdf-viewer-object">
            {{resources.directives.UNLOADED_QUERY}}
        </div>
  `,
    inputs: ['rpsData']
})
export class rpsPdf {
    private _rpsData: string;
    get rpsData(): string {
        return this._rpsData;
    }
    set rpsData(value: string) {
        this._rpsData = value;
        this.safeResourceUrl = this.getPdfUrl(this._rpsData);
    }

    private get resources() {
        return rps.app.resources;
    }

    safeResourceUrl: SafeUrl;

    constructor(private sanitizer: DomSanitizer) { }

    getPdfUrl(url: string) : SafeUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}

