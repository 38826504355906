import { Component, ElementRef, AfterViewInit, OnChanges, SimpleChange, OnDestroy, ChangeDetectionStrategy} from '@angular/core';
import { rpsControl } from '../controlBase'

@Component({
    selector: 'rps-file-uploader',
    template: `
        <div class="rps-layout-padding">
            <input type="file" id="kendoUpload" />
        </div>
    `,
    inputs: ['rpsAdapter'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class rpsFileUploader implements AfterViewInit, OnChanges, OnDestroy {
    private kendoUploader: kendo.ui.Upload;
    private rpsAdapter: rps.viewmodels.adapters.FileUploaderAdapterBase;
    private enabledChangedSubscription: rps.services.ISubscription;
    private multipleChangedSubscription: rps.services.ISubscription;
    private allowedExtensionsChangedSubscription: rps.services.ISubscription;

    constructor(private elementRef: ElementRef) {
    }

    ngAfterViewInit() {
        this.createTargetControl();
    }

    createTargetControl() {
        var options: kendo.ui.UploadOptions = {
            async: { saveUrl: 'api/General/File', autoUpload: true },
            success: this.onSuccess
        };
        if (this.rpsAdapter) {
            options.enabled = this.rpsAdapter.enabled;
            options.multiple = this.rpsAdapter.multiple;            
            if (this.rpsAdapter.allowedExtensions)
                options.validation = { allowedExtensions: this.rpsAdapter.allowedExtensions };
        }

        this.kendoUploader = $(this.elementRef.nativeElement)
            .find("input")
            .first()
            .kendoUpload(options).data("kendoUpload");
    }

    onSuccess = (kendoEvent: kendo.ui.UploadSuccessEvent) => {
        var uploadResponse: { UploadTicket: string, FileName: string } = kendoEvent.response;
        if (this.rpsAdapter) {
            this.rpsAdapter.onFileUploaded(uploadResponse.UploadTicket, uploadResponse.FileName);
        }
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes["rpsAdapter"] && this.rpsAdapter) {
            this.setAdapter();
        }
    }

    private setAdapter(): void {
        if (this.kendoUploader) {
            this.kendoUploader.enable(this.rpsAdapter.enabled);
            this.kendoUploader.options.multiple = this.rpsAdapter.multiple;
            if (this.rpsAdapter.allowedExtensions)
                this.kendoUploader.options.validation = { allowedExtensions: this.rpsAdapter.allowedExtensions };
            else if (this.kendoUploader.options.validation)
                delete this.kendoUploader.options.validation
        }
        this.enabledChangedSubscription = this.rpsAdapter.enabledChanged.subscribe(() => {
            this.kendoUploader.enable(this.rpsAdapter.enabled);
        });
        this.multipleChangedSubscription = this.rpsAdapter.multipleChanged.subscribe(()=>{
            this.kendoUploader.options.multiple = this.rpsAdapter.multiple;
        });
        this.allowedExtensionsChangedSubscription = this.rpsAdapter.allowedExtensionsChanged.subscribe(() => {
            if (this.rpsAdapter.allowedExtensions)
                this.kendoUploader.options.validation = { allowedExtensions: this.rpsAdapter.allowedExtensions };
            else if (this.kendoUploader.options.validation)
                delete this.kendoUploader.options.validation
        });
    }

    ngOnDestroy() {
        if (this.kendoUploader) {
            this.kendoUploader.destroy();
            this.kendoUploader = null;
        }
        if (this.enabledChangedSubscription)
            this.enabledChangedSubscription.unsubscribe();
        if (this.multipleChangedSubscription)
            this.multipleChangedSubscription.unsubscribe();
        if (this.allowedExtensionsChangedSubscription)
            this.allowedExtensionsChangedSubscription.unsubscribe();
    }
}