import {Component, ElementRef, AfterViewInit, OnChanges, SimpleChange,OnDestroy} from '@angular/core';
import { rpsControl } from '../controlBase'

export class baseButton implements AfterViewInit, OnDestroy {
    protected kendoButton: kendo.ui.Button;   
    public rpsButton: rpsButton;
   
    constructor(protected elementRef: ElementRef) {    
    }

    ngAfterViewInit(): any {
        this.createKendoButton($(this.elementRef.nativeElement).find(".rps-button-button"));

        //En el caso de que se quiera evitar que obtenga el foco tabulando, se modifica el elemento, para que ningún elemento lo haga
        if (this.rpsButton && this.rpsButton.rpsPreventTabindex == "true")
            $(this.elementRef.nativeElement).find("[tabindex]").attr("tabindex", "-1");

        //HACK: Se añade la clase a mano a pesar de estar con un binding, ya que al crear el control, kendo la elimina
        if (!this.rpsButton.evalCanExecute())
            $(this.elementRef.nativeElement)
                .find(".rps-button-button")
                .addClass("k-state-disabled");

        if (this.rpsButton) {
            this.rpsButton.kendoTooltip = $(this.elementRef.nativeElement).kendoTooltip({
                filter: ".rps-button-button",
                position: "bottom",
                show: (e) => {
                    //Comprobamos que entre el texto en el boton para mostrar o no el tooltip
                    if (($(e.sender.target())[0].scrollWidth > $(e.sender.target()).innerWidth() || this.rpsButton.rpsTemplate == "ICON") && this.rpsButton.rpsLabel)
                        $(e.sender["content"]).parent().css("visibility", "visible");
                    else
                        this.rpsButton.kendoTooltip.hide();
                },
                content: (e) => {
                    $(e.sender.content).parent().css("visibility", "hidden");                 
                    return this.rpsButton.rpsLabel;                                     
                },
                showAfter: rps.utils.getTooltipDelay()
            }).data("kendoTooltip");
        }
    }

    ngOnDestroy() {
        if (this.kendoButton){
            this.kendoButton.destroy();
        }
        if (this.rpsButton.kendoTooltip) {
            this.rpsButton.kendoTooltip.destroy();
        }
    }

    protected createKendoButton(element: JQuery) {        
        this.kendoButton = element.kendoButton().data("kendoButton");       
    }

    mouseLeave() {
        if (this.rpsButton.kendoTooltip)
            this.rpsButton.kendoTooltip.hide();
    }
}

@Component({
    selector: 'rps-text-button',
    template: `
        <div class="rps-button-button rps-editor-editor"
             [class.k-state-disabled] = "!rpsButton?.evalCanExecute()"
             [class.rps-primary] = "rpsButton?.evalIsPrimary()"
             [class.rps-danger] = "rpsButton?.evalIsDanger()"
             type="button" 
             [attr.id]="rpsButton?.myId"
             (click)="rpsButton.click()"
             (keyup.space)="rpsButton.click()"
             (mouseleave)="mouseLeave()">{{rpsButton?.rpsLabel}}</div>
    `,
    inputs: ['rpsButton']
})
export class textButton extends baseButton {    
    constructor(protected elementRef: ElementRef) {
        super(elementRef);
    }
}

@Component({
    selector: 'rps-icon-button',
    template: `
        <div class="rps-button-button rps-editor-editor"
             [class.k-state-disabled] = "!rpsButton?.evalCanExecute()"
             [class.rps-primary] = "rpsButton?.evalIsPrimary()"
             [class.rps-danger] = "rpsButton?.evalIsDanger()"
             type="button" 
             [attr.id]="rpsButton?.myId"
             (click)="rpsButton.click()"
             (keyup.space)="rpsButton.click()"
             (mouseleave)="mouseLeave()">
            <span class="k-icon k-sprite">                
            </span>            
        </div>
    `,
    inputs: ['rpsButton']
})
export class iconButton extends baseButton {

    constructor(protected elementRef: ElementRef) {
        super(elementRef);
    }

    ngOnDestroy() {
        super.ngOnDestroy();      
    }

    protected createKendoButton(element: JQuery) {
        this.kendoButton = element.kendoButton({
            spriteCssClass: this.rpsButton.rpsIcon
        }).data("kendoButton");
    }
}

@Component({
    selector: 'rps-telerik-icon-button',
    template: `
        <div class="rps-button-button rps-editor-editor"
             [class.k-state-disabled] = "!rpsButton?.evalCanExecute()"
             [class.rps-primary] = "rpsButton?.evalIsPrimary()"
             [class.rps-danger] = "rpsButton?.evalIsDanger()"
             type="button" 
             [attr.id]="rpsButton?.myId"
             (click)="rpsButton.click()"
             (keyup.space)="rpsButton.click()"
             (mouseleave)="mouseLeave()">
            <span class="k-icon">                
            </span>            
        </div>
    `,
    inputs: ['rpsButton']
})
export class telerikIconButton extends baseButton {

    constructor(protected elementRef: ElementRef) {
        super(elementRef);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    protected createKendoButton(element: JQuery) {
        this.kendoButton = element.kendoButton({
            icon: this.rpsButton.rpsIcon
        }).data("kendoButton");
    }
}

@Component({
    selector: 'rps-text-with-icon-button',
    template: `
        <div class="rps-button-button rps-editor-editor"
            [class.k-state-disabled] = "!rpsButton?.evalCanExecute()"
            [class.rps-primary] = "rpsButton?.evalIsPrimary()"
            [class.rps-danger] = "rpsButton?.evalIsDanger()"
            type="button" 
            [attr.id]="rpsButton?.myId"
            (click)="rpsButton.click()" 
            (keyup.space)="rpsButton.click()"
            (mouseleave)="mouseLeave()">
            <span class="k-icon k-sprite"></span>{{rpsButton?.rpsLabel}}</div>
    `,
    inputs: ['rpsButton']
})
export class textWithIconButton extends baseButton {

    constructor(protected elementRef: ElementRef) {
        super(elementRef);
    }

    protected createKendoButton(element: JQuery) {
        this.kendoButton = element.kendoButton({
            spriteCssClass: this.rpsButton.rpsIcon
        }).data("kendoButton");
    }
}

@Component({
    selector: 'rps-button',
    template: `
        <div class="rps-editor">
            <label class="rps-editor-label"></label>
            <div class="editor-is-busy" *ngIf="rpsCommandProperty?.isBusy"></div>
            <rps-text-button *ngIf="rpsTemplate == 'TEXT'" 
                             [rpsButton]="rpsButton">
            </rps-text-button>
            <rps-icon-button *ngIf="rpsTemplate == 'ICON'" 
                             [rpsButton]="rpsButton">
            </rps-icon-button>
            <rps-telerik-icon-button *ngIf="rpsTemplate == 'K-ICON'" 
                             [rpsButton]="rpsButton">
            </rps-telerik-icon-button>
            <rps-text-with-icon-button *ngIf="rpsTemplate == 'TEXT_WITH_ICON'" 
                                        [rpsButton]="rpsButton">
            </rps-text-with-icon-button>
        </div>
  `,
    inputs: ['rpsLabel', 'rpsModel', 'rpsTemplate', 'rpsType', 'rpsIcon','rpsPreventTabindex']
})
export class rpsButton implements OnChanges,AfterViewInit  {
    //Propiedad que contiene su propia instancia, para bindear los diferentes componentes
    rpsButton: rpsButton;
    rpsLabel: string;
    public rpsTemplate: string = "TEXT";
    public rpsIcon: string;
    rpsModel: rps.viewmodels.commands.CommandProperty | rps.viewmodels.properties.ILinkProperty;
    rpsType: string;
    rpsPreventTabindex: string = "false";

    rpsCommandProperty: rps.viewmodels.commands.CommandProperty;
    rpsLinkProperty: rps.viewmodels.properties.ILinkProperty;
    private myId: string;
    //Propiedad que indica que está pendiente de forzar el foco después de iniciarse
    private focusAfterInit: boolean = false;

    public kendoTooltip: kendo.ui.Tooltip; 

    constructor(private elementRef: ElementRef) {
        this.rpsButton = this;
        if (!this.myId)
            this.myId = rps.guid.newGuid();        
    }

    ngAfterViewInit() {
        //Si estamos en modo edición de la grid, hay que introducirle el foco al editor, ya que significa que hemos entrado en modo edición de la celda
        if (this.focusAfterInit) {
            $(this.elementRef.nativeElement).find(".k-button").focus();
            this.focusAfterInit = false;
            rps.ui.grid.isInEditMode = false;
        }
    }

    public click() {
        if (this.rpsCommandProperty)
            this.rpsCommandProperty.tryExecute();
        else if (this.rpsLinkProperty)
            this.rpsLinkProperty.go();        
    }

    evalCanExecute(): boolean {
        if (this.rpsLinkProperty)
            return true;
        else if (this.rpsCommandProperty) {
            if (this.rpsCommandProperty.isBusy)
                return false;

            return this.rpsCommandProperty.canExecute().result;
        }
        return false;
    }

    evalIsPrimary(): boolean {
        return (this.rpsType == "PRIMARY");            
    }

    evalIsDanger(): boolean {
        return (this.rpsType == "DANGER");
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }): any {
        //En el ngModel, se puede establecer un comando o un link, por lo que en función del tipo, se establece una propiedad u otra, ya que el control está preparado para funcionar con cualquiera de las dos
        if (changes["rpsModel"]) {
            if (rps.viewmodels.properties.isILinkProperty(this.rpsModel)) {
                this.rpsCommandProperty = undefined;
                this.rpsLinkProperty = this.rpsModel;
            }
            else if (rps.viewmodels.commands.isCommandProperty(this.rpsModel)) {
                this.rpsCommandProperty = this.rpsModel;
                this.rpsLinkProperty = undefined;
            }
            else {
                this.rpsCommandProperty = undefined;
                this.rpsLinkProperty = undefined;
            }
        }
        if (changes["rpsLabel"]) {
            if(this.kendoTooltip)
                this.kendoTooltip.refresh();
        }       
    }

    //Método que invoca la grid, para forzar que el editor recoja el foco
    setFocusAfterInit() {
        this.focusAfterInit = true;
        rps.ui.grid.isInEditMode = true;
    }
}