import {Component, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsReadOnlyEditor} from './readOnlyEditorBase'
import {rpsSemanticState} from '../utils/semanticState'

@Component({
    selector: 'rps-read-only-check-box',
    template: `
        <div class="rps-editor" 
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label"></label>
            <div class="rps-read-only-checkbox-container"  
                [rpsSemanticState]="rpsModel?.semanticState">
                <input class="rps-read-only-checkbox"
                       type="checkbox" 
                       [attr.id]="myId"
                       tabindex="-1"
                       disabled>
                <label class="rps-read-only-checkbox-label"
                       [attr.for]="myId">
                    {{rpsLabel}}
                </label>
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors" >
        </error-detail-validator>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsReadOnlyEditor.getComponentInputs()
})
export class rpsReadOnlyCheckBox extends rpsReadOnlyEditor<boolean, rps.viewmodels.properties.VMProperty<boolean>> {

    get inputCheckValue(): boolean {
        return this.element.find("input").prop("checked");
    }
    set inputCheckValue(newValue: boolean) {
        this.element.find("input").prop("checked", newValue);
    }

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef) {
        super(elementRef, changeDetectorRef);
    }

    updateTargetValue() {
        this.inputCheckValue = this.value;        
    }
}
