import {Directive, ElementRef, AfterContentInit } from '@angular/core';

@Directive({
    selector: '[rpsOffset]',
    inputs: [
        'rpsOffset'
    ],
})
export class rpsOffset implements AfterContentInit {
    rpsOffset: string = "0";

    constructor(private elementRef: ElementRef) {
    }

    ngAfterContentInit(): any {
        this.setColClass();
    }

    private setColClass() {
        var element: JQuery = $(this.elementRef.nativeElement);
        element.addClass("col-xs-offset-0");
        element.addClass(rps.string.format("col-md-offset-{0}", this.rpsOffset));
        element.addClass(rps.string.format("col-sm-offset-{0}", this.rpsOffset));
    }
}