import {Component, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef} from '@angular/core';
import { baseServerColumn } from './baseServerColumn';

@Component({
    template: `
        <div class="rps-navigable-read-only-lookup">
            <div class="rps-img-2 rps-lookup-tc-image" *ngIf="image">
                <img class="rps-img"
                     src="{{image}}"/>
            </div>
            <div class="rps-lookup-tc-text-container">
                <div class="rps-lookup-tc-text">
                    <div *ngIf="!link">
                        {{text}}
                    </div>
                    <div *ngIf="link">
                        <a tabindex="-1" [routerLink]="link" [queryParams]="linkQueryParams">{{text}}</a>
                    </div>
                </div>
            </div>
            <div class="rps-lookup-navigate-to-button rps-editor-fit-container" 
                     *ngIf="isNavigable">
                <rps-button rpsTemplate="ICON"
                            rpsIcon="fa fa-lg fa-share-square-o" 
                            [rpsModel]="navigateToCommand"
                            rpsPreventTabindex="true">
                </rps-button>
            </div>
            <div class="contextContainer" *ngIf="!isEditing">
                <ul id="kendoContextMenu" class="rps-navigable-lookup-context-menu"></ul>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class rpsLookupServerColumn extends baseServerColumn<rps.data.Descriptor>{
    private modelProperty: rps.data.Descriptor;
    public text: string;
    public link: Array<any>;
    public linkQueryParams: rps.IParams;
    public image: string;
    isNavigable = false;
    private static kendoContextMenuItemTemplate: string = `
        <div class="navigable-states-container">
            <div class=" rps-img-2 navigable-state-image"><img class="rps-img" src="{0}"/></div>       
            <div class="navigable-state-description">{1}</div>                        
        </div>
    `;
    constructor(_cdr: ChangeDetectorRef, private _elementRef: ElementRef) {
        super(_cdr);
    }

    setValue(newValue: rps.data.Descriptor) {
        if (this.modelProperty !== newValue) {
            this.modelProperty = newValue;
            this.refreshText();
            this.refreshLink();
            this.refreshImage();
            this.isNavigable = this.modelProperty ? rps.app.entityFactory.findEntityDefinition(this.modelProperty.EntityType).isNavigable : false;
            this._cdr.markForCheck();
        }
    }

    private refreshText() {
        if (this.modelProperty) {
            if (this.modelProperty.SecondaryDescriptor)
                this.text = rps.string.format("{0} - {1}", this.modelProperty.MainDescriptor, this.modelProperty.SecondaryDescriptor);
            else
                this.text = this.modelProperty.MainDescriptor;
        }
        else
            this.text = "";
    }

    private refreshLink() {
        //Si no tiene UILink desde el servidor, ni siquiera se pinta el link
        if (this.modelProperty) {
            if (!this.modelProperty.UILink)
                this.link = null;
            else {
                var link = rps.app.stateManager.createRouterLink(this.modelProperty.UILink);
                this.link = link.routerLink;
                this.linkQueryParams = link.queryParams;
            }
        }
        else
            this.link = rps.app.stateManager.notFoundLink;
    }

    private refreshImage() {
        if (this.modelProperty)
            this.image = this.modelProperty.Image;
        else
            this.image = null;
    }

    navigateToCommand: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: this.openContextMenu,
        //canExecute: this.canNavigateTo
    });
    openContextMenu(): Promise<any> {
        
        return rps.app.entityManager.getNavigableStates(this.modelProperty.EntityType,
            this.modelProperty.EntityID,
            rps.app.stateManager.getCurrentCompany())
            .then((states: Array<rps.data.NavigableState>) => {
                //Obtenemos los estados de la navegacion y los metemos al dataSource del ContextMenu
                //Se guarda el TargetState en el urlSelect para despues poder acceder al dato y hacer la navegacion                
                var contextMenuDataSource: Array<{ text: string; url: string; urlSelect: rps.data.IUILinkDefinition; encoded: boolean; }> = new Array<{ text: string; url: string; urlSelect: rps.data.IUILinkDefinition; encoded: boolean; }>();
                Enumerable.From(states).OrderBy(s => s.Description).forEach((state) => {
                    contextMenuDataSource.push({
                        text: rps.string.format(rpsLookupServerColumn.kendoContextMenuItemTemplate, state.Image, state.Description),
                        url: rps.app.stateManager.href(state.TargetState),
                        urlSelect: state.TargetState,
                        encoded: false
                    });
                });

                //Máximo de 10 items por columna
                let navigationListColumns = Math.trunc(contextMenuDataSource.length / 10) + 1;

                var kendoContextMenu: kendo.ui.ContextMenu;
                var container = $(this._elementRef.nativeElement).find("#kendoContextMenu");
                if (container.length > 0) {

                    container.css('columns', navigationListColumns + " auto");

                    //Filter "#none" para que solo se abra el contextMenu donde ejecutemos el "Open". El elemento "#none" no existe.
                    kendoContextMenu = container
                        .kendoContextMenu({
                            dataSource: contextMenuDataSource,
                            orientation: "vertical",
                            filter: "#none",
                            close: (e) => {
                                kendoContextMenu.destroy();
                                $(this._elementRef.nativeElement).find(".contextContainer").html("<ul id='kendoContextMenu' class='rps-navigable-lookup-context-menu' style='columns:" + navigationListColumns + ";' ></ul>");

                            },
                            select: (e) => {
                                //Evitamos que vaya directamente para no romper la navegacion y obtenemos la ruta del DataSource para crear un RouterLink
                                e.preventDefault();
                                var index = $(e.item).index();
                                rps.app.stateManager.goTo(e.sender.options.dataSource[index].urlSelect);
                            }
                        })
                        .data("kendoContextMenu");
                    //Ejecutamos el open para forzar la apertura del contextMenu indicando donde tiene que abrirlo.
                    kendoContextMenu.open($(this._elementRef.nativeElement).find(".rps-lookup-navigate-to-button"));
                }
            });
    }
} 