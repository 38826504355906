import { Component, NgModule, enableProdMode } from '@angular/core';
import { HttpModule,Http, Response, Headers } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'rxjs/add/operator/map';
import 'rxjs/Rx';

interface StatusResponse {
    IsRunning: boolean;
    IsSafeMode: boolean;
    IsUnicodeDB: boolean;
    AvailableServices: Array<string>;
    InitializationError: string;
    ServiceVersion: string;
}

interface LogStatusResponse {
    IsLogging: boolean;
    LogLevel: number;
}

@Component({
    selector: "rps-status-app",
    template: `
        <div class="rps-status-app">
            <h3>RPS Web API Status</h3>
            <div *ngIf="isRunning">
                <div class="ok-panel">
                    <div>Running</div>
                </div>
                <div class="panel">                    
                    <div><b>Service Version:</b> {{serviceVersion}}</div>
                    <div><b>IsSafeMode:</b> {{isSafeMode}}</div>
                    <div><b>IsUnicodeDB:</b> {{isUnicodeDB}}</div>
                    <div><b>Available Services:</b> {{availableServicesString}}</div>
                </div>
                <div class="panel">                    
                    <div><b>Is Logging:</b> {{isLoggingText}}</div>
                    <button *ngIf="isLoggingLoaded" (click)="changeLogStatus()">{{changeLogStatusText}}</button>
                    <button *ngIf="isLoggingLoaded" (click)="deleteLog()">Delete Log</button>                    
                    <div *ngIf="isLoggingLoaded && isLogging">Download Log</div>
                    <div *ngIf="isLoggingLoaded && !isLogging">
                        <div *ngIf="!isLogging">
                            <a [href]="logLink">Download Log</a>
                        </div>
                        <div *ngIf="isLogging">Download Log</div>
                    </div>
                </div>
            </div>
            <div *ngIf="initializationError" class="error-panel">
                <div>{{initializationError}}</div>
            </div>
        </div>
    `,
    styles: [`
        .rps-status-app{
            padding: 0.5em;
        }
        button{
            margin-top: 0.5em;
        }
        h3 
        {
            background-color: darkgray;
            color: white;
            padding: 0.5em;
        }
        .panel
        {
            border:2px solid black;
            padding:0.5em;
            margin-top:10px;
            margin-bottom:10px;
        }
        .ok-panel
        {
            background-color: azure;
            border:2px solid green;
            padding:0.5em;
            margin-top:10px;
            margin-bottom:10px;
        }
        .error-panel
        {
            background-color: #FFCCCC;
            border:2px solid #CC0000;
            padding:0.5em;
            font-family:Consolas;
            margin-top:10px;
            margin-bottom:10px;
        }`]
})
export class rpsSesionApp {
    private rpsAPIAddress: string;

    isRunning: boolean = false;
    isSafeMode: boolean = false;
    serviceVersion: string;
    isUnicodeDB: boolean = false;
    initializationError: string;
    availableServicesString: string;
    isLoggingLoaded: boolean = false;
    isLogging: boolean = false;
    isLoggingText: string = "Loading";
    changeLogStatusText: string;
    logLink: string;

    constructor(private _http: Http) {
        this.fillRPSAPIAddress();
        this.checkStatus();
    }

    private fillRPSAPIAddress() {
        //Calcular la ruta del servidor
        var origin: string;
        if (window.location.origin)
            origin = window.location.origin;
        else {
            //Hay veces que en IE el origin está vacío; hay que montarlo con otras propiedades
            origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
        }
        //Se coge el API Address como el punto de acceso del index.html
        this.rpsAPIAddress = origin + window.location.pathname.substr(0, window.location.pathname.lastIndexOf('/') + 1);
    }

    private checkStatus() {
        //Comprobar el estado del servicio
        this._http.get(
            this.rpsAPIAddress + "status",
            {
                body: '',
                headers: new Headers({
                    'Content-Type': 'application/json'
                })
            }).map((res: Response, ix?: number) => {
                return JSON.parse(res.text());
            }).subscribe((data: StatusResponse) => {
                this.isRunning = data.IsRunning;
                this.initializationError = data.InitializationError;
                this.serviceVersion = data.ServiceVersion;
                if (data.IsSafeMode)
                    this.isSafeMode = true;
                if (data.IsUnicodeDB)
                    this.isUnicodeDB = true;
                if (data.AvailableServices) {
                    data.AvailableServices.forEach((serviceName) => {
                        if (this.availableServicesString) {
                            if (data.AvailableServices.indexOf(serviceName) != (data.AvailableServices.length - 1))
                                this.availableServicesString += ", " + serviceName;
                            else
                                this.availableServicesString += " and " + serviceName;
                        }
                        else
                            this.availableServicesString = serviceName;
                    })
                }

                if (this.isRunning)
                    this.checkLogStatus();
            }, (res: Response) => {
                this.initializationError = "Unexpected Error";
            });
    }

    private checkLogStatus() {
        //Comprobar el estado de la traza
        this._http.get(
            this.rpsAPIAddress + "log/status",
            {
                body: '',
                headers: new Headers({
                    'Content-Type': 'application/json'
                })
            }).map((res: Response, ix?: number) => {
                return JSON.parse(res.text());
            }).subscribe((data: LogStatusResponse) => {
                this.setIsLogging(data.IsLogging);
                this.isLoggingText = this.isLogging.toString();
                this.isLoggingLoaded = true;
                this.logLink = this.rpsAPIAddress + "log/current";
            }, (res: Response) => {
                this.setIsLogging(false);
                this.isLoggingLoaded = false;
                this.logLink = "";
                this.isLoggingText = "Unexpected Error";
            });
    }

    private setIsLogging(newValue: boolean) {
        if (newValue) {
            this.isLogging = true;
            this.changeLogStatusText = "Stop Log";
        }
        else {
            this.isLogging = false;
            this.changeLogStatusText = "Start Log";
        }
    }

    public changeLogStatus = () => {
        if (this.isLogging)
            this.setLogStatus(0);
        else
            this.setLogStatus(2);
    }

    private setLogStatus(newStatus:number) {
        this._http.put(
            this.rpsAPIAddress + "log/status",
            newStatus,
            {                
                headers: new Headers({
                    'Content-Type': 'application/json'
                })
            }).subscribe(() => {
                this.setIsLogging(!this.isLogging);
                this.isLoggingText = this.isLogging.toString();
            }, (res: Response) => {
                this.setIsLogging(false);
                this.isLoggingLoaded = false;
                this.logLink = "";
                this.isLoggingText = "Unexpected Error";
            });
    }

    public deleteLog = () => {
        this._http.delete(
            this.rpsAPIAddress + "log/current",
            {
                headers: new Headers({
                    'Content-Type': 'application/json'
                })
            }).subscribe(() => {
                alert("The log has been deleted");
            }, (res: Response) => {
                this.setIsLogging(false);
                this.isLoggingLoaded = false;
                this.logLink = "";
                this.isLoggingText = "Unexpected Error";
            });
    }
}

@NgModule({
    imports: [BrowserModule, HttpModule],
    declarations: [rpsSesionApp],
    bootstrap: [rpsSesionApp]
})
export class AppModule { }

enableProdMode();
platformBrowserDynamic().bootstrapModule(AppModule);
