//** Name: Logon
//** State: html5.Logon - /Logon?_state - LogonView.html
//** State: html5.Logon.changePassword - /changePassword?_state - changePasswordDialogView.html

module rps.HTML5.viewmodels.Logon {

export class LogonVM extends rps.viewmodels.BaseVM{
	
    static fullTypeName: string = "rps.HTML5.viewmodels.Logon.LogonVM";

	relatedState: string = "html5.Logon";
	static relatedState: string = "html5.Logon";


	



	configureVMProperties(){	
		super.configureVMProperties();	
		this.codUser = new rps.viewmodels.properties.VMProperty<string>({ target: this,initialValue: null, isRequired : true});
		this.password = new rps.viewmodels.properties.VMProperty<string>({ target: this,initialValue: null});
		this.logOn = new rps.viewmodels.commands.CommandProperty({target: this, command: this.logOnExecute, canExecute: this.logOnCanExecute});
		this.changePasswordLink = new rps.viewmodels.properties.LinkProperty({state:"[]"});
		this.changePasswordNavigationCommandOk = new rps.viewmodels.commands.CommandProperty({target: this, command: this.changePasswordNavigationCommand_ok, canExecute: this.changePasswordNavigationCommand_allowOk});
	this.changePasswordNavigationCommand = new rps.viewmodels.commands.ChildNavigationCommandProperty({target: this,link: this.changePasswordLink, vm: this, resolve: this.changePasswordNavigationCommandExecute, canExecute: this.changePasswordNavigationCommandCanExecute, okCommand: this.changePasswordNavigationCommandOk});
		this.windowsLogOn = new rps.viewmodels.commands.CommandProperty({target: this, command: this.windowsLogOnExecute, canExecute: this.windowsLogOnCanExecute});

		//Creación de las condiciones de filtros de las listas de mi tipo
		
		//Configuración de querysources con custom card view
	}

	
 
 	//Declaración de las propiedades
	public codUser: rps.viewmodels.properties.VMProperty<string>;
	public password: rps.viewmodels.properties.VMProperty<string>;
	public changePasswordLink: rps.viewmodels.properties.LinkProperty;

	//Declaración de los comandos
	public logOn: rps.viewmodels.commands.CommandProperty;
	public logOnExecute(): Promise<any>{ return Promise.resolve<any>(this) }
	public logOnCanExecute(): rps.viewmodels.commands.CanExecuteResult { return rps.viewmodels.commands.CanExecuteResult.allow();}
	public changePasswordNavigationCommandOk: rps.viewmodels.commands.CommandProperty;
	public changePasswordNavigationCommand: rps.viewmodels.commands.ChildNavigationCommandProperty;
	public changePasswordNavigationCommandExecute(result: { result: rps.services.NavigationResult; data: any }): void{}

	public changePasswordNavigationCommandCanExecute(): rps.viewmodels.commands.CanExecuteResult { return rps.viewmodels.commands.CanExecuteResult.allow();}
	public changePasswordNavigationCommand_allowOk(childVM:ChangePasswordVM): rps.viewmodels.commands.CanExecuteResult { return rps.viewmodels.commands.CanExecuteResult.allow();}
	public changePasswordNavigationCommand_ok(childVM:ChangePasswordVM): Promise<any>{ return Promise.resolve<any>(this) }
	public windowsLogOn: rps.viewmodels.commands.CommandProperty;
	public windowsLogOnExecute(): Promise<any>{ return Promise.resolve<any>(this) }
	public windowsLogOnCanExecute(): rps.viewmodels.commands.CanExecuteResult { return rps.viewmodels.commands.CanExecuteResult.allow();}


	//Declaración de las condiciones de filtros de las listas de mi tipo
	}






export class ChangePasswordVM extends rps.viewmodels.BaseVM{
	
    static fullTypeName: string = "rps.HTML5.viewmodels.Logon.ChangePasswordVM";

	relatedState: string = "html5.Logon.changePassword";
	static relatedState: string = "html5.Logon.changePassword";


	

	//Redefinir el parentVM para especificar el tipo concreto
    parentVM: LogonVM;


	configureVMProperties(){	
		super.configureVMProperties();	
		this.codUser = new rps.viewmodels.properties.VMProperty<string>({ target: this,valuePropertyPath:  "parentVM.codUser.value", isRequired : true});
		this.oldPassword = new rps.viewmodels.properties.VMProperty<string>({ target: this,initialValue: null, isRequired : true});
		this.newPassword = new rps.viewmodels.properties.VMProperty<string>({ target: this,initialValue: null, isRequired : true});
		this.newPasswordConfirmation = new rps.viewmodels.properties.VMProperty<string>({ target: this,initialValue: null, isRequired : true});

		//Creación de las condiciones de filtros de las listas de mi tipo
		
		//Configuración de querysources con custom card view
	}

	
 
 	//Declaración de las propiedades
	public codUser: rps.viewmodels.properties.VMProperty<string>;
	public oldPassword: rps.viewmodels.properties.VMProperty<string>;
	public newPassword: rps.viewmodels.properties.VMProperty<string>;
	public newPasswordConfirmation: rps.viewmodels.properties.VMProperty<string>;



	//Declaración de las condiciones de filtros de las listas de mi tipo
	}





}

module rps.HTML5.viewmodels.Logon.resources{
	export class Logon{
 		public static TITLE: string = '|^SR(TITLE)^|';
 		public static TXT_User: string = '|^SR(TXT_User)^|';
 		public static TXT_Password: string = '|^SR(TXT_Password)^|';
 		public static BTN_Logon: string = '|^SR(BTN_Logon)^|';
 		public static TITLE_ChangePassword: string = '|^SR(TITLE_ChangePassword)^|';
 		public static TXT_OldPassword: string = '|^SR(TXT_OldPassword)^|';
 		public static TXT_NewPassword: string = '|^SR(TXT_NewPassword)^|';
 		public static TXT_NewPasswordConfirmation: string = '|^SR(TXT_NewPasswordConfirmation)^|';
 		public static MSG_UserPassword_Invalid: string = '|^SR(MSG_UserPassword_Invalid)^|';
 		public static BTN_ChangePassword: string = '|^SR(BTN_ChangePassword)^|';
 		public static BTN_WindowsLogon: string = '|^SR(BTN_WindowsLogon)^|';
	}
}

rps.extensions.register(
	  rps.HTML5.viewmodels.Logon.LogonVM,rps.HTML5.viewmodels.Logon.ChangePasswordVM
);