import { Injectable, Component, OnInit, OnDestroy, AfterContentInit,
    AfterViewInit, AfterViewChecked, ContentChildren, ViewChildren, QueryList, forwardRef, ElementRef} from '@angular/core';
import { ActivatedRoute, Resolve, ActivatedRouteSnapshot,Params, Router, CanActivate, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {rpsPanel} from '../directives/layouts/panel';
import {rpsTogglePanel} from '../directives/layouts/togglePanel';
import {rpsTabControl} from '../directives/layouts/tabControl';
import { rpsControl } from '../directives/controlBase';
import { rpsView } from '../directives/layouts/view';
import { Observable }  from 'rxjs/Observable';
import 'rxjs/add/operator/debounceTime';

export class ComponentProvider {
    public viewModels: rps.collections.Dictionary<string, rps.viewmodels.BaseVM>;
    public viewModelCreated: rps.services.IEventEmitter<rps.viewmodels.BaseVM>;

    constructor() {
        this.viewModels = new rps.collections.Dictionary<string, rps.viewmodels.BaseVM>([]);
        this.viewModelCreated = rps.app.eventManager.createEmitter<rps.viewmodels.BaseVM>(false);
    }

}

@Injectable()
export class ComponentGuards implements Resolve<rps.viewmodels.BaseVM>, CanActivate, CanDeactivate<BaseComponent> {
    constructor(private _componentProvider: ComponentProvider) {
    }

    resolve(activatedRoute: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
        var configOptions: rps.viewmodels.IConfigOptions = <rps.viewmodels.IConfigOptions>activatedRoute.data["configOptions"];

        //Mirar si está cacheado
        let vm = rps.app.stateManager.getCachedVM(configOptions.stateAbsoluteName);
        if (vm) {
            var key: string = BaseComponent.getVMKey(activatedRoute);
            if (!this._componentProvider.viewModels.containsKey(key))
                this._componentProvider.viewModels.add(key, vm);

            return {
                vm: vm,
                componentProvider: this._componentProvider
            }
        }

        //Cubrir el caso de redirección de /new a /ID; estaría navegando al mismo estado, se devuelve el mismo vm
        if (rps.app.stateManager.stateStack.current && rps.app.stateManager.stateStack.current.configOptions == configOptions && 
            rps.app.stateManager.stateStack.current.routeParams[(<rps.viewmodels.EntityVM<any>>rps.app.stateManager.stateStack.current.vm).idPropertyName] == rps.viewmodels.EntityVM.NEW_ID_VALUE) {

            //Sustituir la clave en la lista de viewmodels
            var keys = this._componentProvider.viewModels.keys();
            for (var i = 0; i < keys.length; i++) {
                var key = keys[i];
                if (this._componentProvider.viewModels[key] == rps.app.stateManager.stateStack.current.vm) {
                    this._componentProvider.viewModels.remove(key);
                    this._componentProvider.viewModels.add(BaseComponent.getVMKey(activatedRoute), rps.app.stateManager.stateStack.current.vm);
                    break;
                }
            }
            //Y los parámetros grabados
            rps.app.stateManager.stateStack.current.routeParams = BaseComponent.getRouteParams(activatedRoute);

            //Llamara al onReplaceNew
            rps.app.stateManager.stateStack.current.vm.onReplaceNew(rps.app.stateManager.stateStack.current.routeParams);

            return rps.app.stateManager.stateStack.current.vm;
        }

        rps.app.busyIndicatorManager.setIsBusy(true);

           //Juntar parámetros y argumentos
        var params: rps.IParams = BaseComponent.getRouteParams(activatedRoute);

        var promise: Promise<rps.viewmodels.BaseVM>;
        if (rps.extensions.functionName(activatedRoute.routeConfig.component) == "$Main")
            promise = this.configureViewModel(activatedRoute, configOptions, { stateParams: params });
        else if (configOptions.isDialogComponent)
            promise = this.configureDialogViewModel(activatedRoute, configOptions, { stateParams: params });
        else
            promise = this.resolveViewModel(activatedRoute, configOptions, { stateParams: params });
        return promise.then((vm) => {
            return {
                vm: vm,
                componentProvider: this._componentProvider,
            }
        }).catch((error) => {
            if (error instanceof rps.errors.ErrorDetail &&
                (error.code == "404" || error.code == "NOT_FOUND"))
                return rps.utils.showIdNotFound().then(() => {
                    rps.app.stateManager.goToRoute(activatedRoute.parent);
                });
                //rps.app.stateManager.goToNotFound();
            else
                throw error;
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        //Mirar si está cacheado y es modal; en ese caso, no lo debería abrir, y navega al padre (por hacer algo...)
        var configOptions: rps.viewmodels.IConfigOptions = <rps.viewmodels.IConfigOptions>route.data["configOptions"];
        if (configOptions.hasDialogLayout) {
            let vm = rps.app.stateManager.getCachedVM(configOptions.stateAbsoluteName);
            if (vm) {
                rps.app.stateManager.goToRoute(route.parent);
                return false;
            }
        }
        return true;
    }

    canDeactivate(component: BaseComponent, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        //Comprobar a dónde quiere navegar para controlar ciertas cosas...
        let nextUrl = rps.app.stateManager.nextUrl;
        if (nextUrl.startsWith('/'))
            nextUrl = nextUrl.substr(1);

        //Al navegar a otra empresa, cancelar y sacar un mensaje
        if ((rps.app.stateManager.getCurrentCompany() && nextUrl.split('/')[1] && rps.app.stateManager.getCurrentCompany() != nextUrl.split('/')[1])) {
            rps.app.messageManager.show({
                message: rps.app.resources.errors.ERR_OTHER_COMPANY_DATA,
                messageButton: rps.services.MessageButton.Ok,
                messageType: rps.services.MessageType.Warning
            });
            return false;
        }

        // También Cancelar navegación a otro componente desde una ventana modal (abre otra modal)
        // "/app/001/general.test/testchild/testchildchild/childchilddialog"
        let currentUrl = rps.app.stateManager.currentPath();
        if (currentUrl.startsWith('/'))
            currentUrl = currentUrl.substr(1);
        if (component.isModalComponent && rps.app.stateManager.forceNewWindowInModal && currentUrl.split('/')[2] != nextUrl.split('/')[2]) {

            setTimeout(() => {
                window.open(rps.app.appSettings.rpsAPIAddress + nextUrl,
                    "_blank", "location=0,status=1,scrollbars=1, resizable=1, directories=0, toolbar=0, titlebar=0, menubar=0,width=1000, height=600");

            });
            return false;
        }

        if (component.vm && component.vm["idPropertyName"] && 
            route.params[component.vm["idPropertyName"]] == rps.viewmodels.EntityVM.NEW_ID_VALUE
            && !component.vm["hasManualID"])
            return true;

        if (component.canDeactivate) {
            return component.canDeactivate().then((result: boolean) => {                
                //Borrar lista de errores (por lo menos cuando sale de una pantalla principal o de un MainEntityVM
                if (result &&
                    (component instanceof MainComponent ||
                    component.vm instanceof rps.viewmodels.MainEntityVM))
                    rps.app.errorManager.clear();
                return result;
            });
        }
        else {
            this._componentProvider.viewModels.remove(BaseComponent.getVMKey(component.activatedRoute));
            return true;
        }
    }

    
    protected configureDialogViewModel(activatedRoute: ActivatedRouteSnapshot, configOptions: rps.viewmodels.IConfigOptions, params: rps.IParams): Promise<rps.viewmodels.BaseVM> {

        var key: string = BaseComponent.getVMKey(activatedRoute.parent);
        var parentVM: rps.viewmodels.BaseVM = this._componentProvider.viewModels[key];

        return Promise.resolve(parentVM);
    }

    protected configureViewModel(activatedRoute: ActivatedRouteSnapshot, configOptions: rps.viewmodels.IConfigOptions, params: rps.IParams): Promise<rps.viewmodels.BaseVM> {
        //Mirar si ya está creado, y si es así, se devuelve
        var key: string = BaseComponent.getVMKey(activatedRoute);
        if (this._componentProvider.viewModels.containsKey(key))
            return Promise.resolve(this._componentProvider.viewModels[key]);

        //Si no se ha creado todavía, se instancia y devuelve
        return rps.app.viewModelFactory.createComponent(configOptions.stateName, params).then((vm) => {
            //Guardar el vm en un diccionario
            if (this._componentProvider.viewModels.containsKey(key))
                this._componentProvider.viewModels[key] = vm;
            else 
                this._componentProvider.viewModels.add(key, vm);

            this._componentProvider.viewModelCreated.emit(vm);

            //vm.processRules.call(vm, rps.viewmodels.rules.Triggers.OnLoad);
            rps.app.stateManager.resetStateHierarchy();

            return vm;
        });
    }

    protected resolveViewModel(activatedRoute: ActivatedRouteSnapshot, configOptions: rps.viewmodels.IConfigOptions, params: rps.IParams): Promise<rps.viewmodels.BaseVM> {
        var key: string = BaseComponent.getVMKey(activatedRoute.parent);

        //Puede ser que el parentVM no esté resuelto; en este caso, hay que esperar...
        if (!this._componentProvider.viewModels.containsKey(key)) {
            //Se devuelve una promesa que se resolverá cuando el vm padre esté creado y el vm hijo se encuentre
            var parentPromise = new Promise((resolve) => {
                var sub = this._componentProvider.viewModelCreated.subscribe(() => {
                    var parentVM: rps.viewmodels.BaseVM = this._componentProvider.viewModels[key];
                    if (parentVM) {
                        sub.unsubscribe();
                        this.resolveViewModelFromParent(parentVM, activatedRoute, configOptions, params ).then((vm) => {
                            resolve(vm);
                        });
                    }
                });
            });
            return parentPromise;
        }
        else
            return this.resolveViewModelFromParent(
                this._componentProvider.viewModels[key], activatedRoute, configOptions, params);
    }

    private resolveViewModelFromParent(parentVM: rps.viewmodels.BaseVM, activatedRoute: ActivatedRouteSnapshot, configOptions: rps.viewmodels.IConfigOptions, params: rps.IParams): Promise<rps.viewmodels.BaseVM> {
        var stateParams = ((params && params["stateParams"]) ? params["stateParams"] : params);
        return parentVM.resolveStateViewModel({ stateName: configOptions.stateAbsoluteName, stateParams: stateParams }).then((m: rps.viewmodels.BaseVM) => {
            if (!m) {
                var vmNameParts = configOptions.viewModelName.split(".");
                try {
                    var functionName: any = rps[vmNameParts[0]]["viewmodels"][vmNameParts[1]][vmNameParts[2]];
                    var initParams = { stateParams: stateParams , parentVM: null };
                    if (parentVM)
                        initParams.parentVM = parentVM;
                    return (rps.app.viewModelFactory.createViewModel(functionName, initParams)).then((m: rps.viewmodels.BaseVM) => {
                        //Guardar el vm en un diccionario
                        var key: string = BaseComponent.getVMKey(activatedRoute);
                        if (this._componentProvider.viewModels.containsKey(key))
                            this._componentProvider.viewModels[key] = m;
                        else
                            this._componentProvider.viewModels.add(key, m);

                        m.parentVM = parentVM;
                        //m.processRules.call(m, rps.viewmodels.rules.Triggers.OnLoad);

                        this._componentProvider.viewModelCreated.emit(m);

                        return m;
                    });

                }
                catch (e) {
                }
            }
            //Guardar el vm en un diccionario
            var key: string = BaseComponent.getVMKey(activatedRoute);
            if (this._componentProvider.viewModels.containsKey(key))
                this._componentProvider.viewModels[key] = m;
            else
                this._componentProvider.viewModels.add(key, m);

            this._componentProvider.viewModelCreated.emit(m);
            //m.processRules.call(m, rps.viewmodels.rules.Triggers.OnLoad);

            return m;
        });
    }
}

@Component({
    selector: 'Empty',
    template: '',
})
export class Empty {

}

export class BaseComponent implements OnInit , AfterViewInit{

    public view: rpsView;
	public rpsControls: QueryList<rpsControl>;

    private sub; private qSub;
    private _vm: rps.viewmodels.BaseVM;
    public get vm(): rps.viewmodels.BaseVM {
        return this._vm;
    }
    public set vm(newValue: rps.viewmodels.BaseVM) {
        this._vm = newValue;
        this.vmChanged.emit(newValue);
    }

    public configOptions: rps.viewmodels.IConfigOptions;
    public routeParams: { [key: string]: string; };
    public isModalComponent: boolean = false;
    public vmChanged: rps.services.IEventEmitter<any>;
    protected _componentProvider: ComponentProvider;


    constructor(protected _elementRef: ElementRef,
        protected _router: Router,
        public activatedRoute: ActivatedRoute,
        configOptions: rps.viewmodels.IConfigOptions) {

        rps.app.busyIndicatorManager.setIsBusy(true);

        this.isModalComponent = configOptions.hasDialogLayout;

        this.vmChanged = rps.app.eventManager.createEmitter<any>();

        this.configOptions = configOptions;
    }

    /**
     * Navega de forma relativa a la ruta del componente al estado pasado por parámetro
     * @param params
     */
    public navigate(relativeRoute: Array<any>): Promise<boolean> {
        return new Promise((resolve, reject) => {
            return this._router.navigate(relativeRoute, { relativeTo: this.activatedRoute, preserveQueryParams: true }).then((result) => {
                resolve(result);
            }).catch((err) => {
                reject(err);
            });
        });
    }

    public canDeactivate(): Promise<boolean> {
        if (this.vm) {
            if (this.vm.isUnloaded)
                return Promise.resolve(true);

            //En el caso de que contenga este comando, significa que es una navegación modal y se está desnavegando
            if (this.vm["__CancelCommand" + this.configOptions.stateName.toLowerCase()]) {
                //Se llama al comando de cancel para que se ejecute la lógica de cerrado; entraría por aquí en caso de 
                //estar en una ventana modal y pulsar el botón de back. Se pasa un parámetro de avoidNavigation para que
                //no navegue dos veces
                return (<rps.viewmodels.commands.CommandProperty>this.vm["__CancelCommand" + this.configOptions.stateName.toLowerCase()]).execute({ fromBackButton: true }).
                    then(() => {
                        return true;
                    }).catch(() => {
                        return true;
                    });
            }
            else {
                return this.vm.onBeforeUnload().then((resolve) => {
                    if (resolve) {
                        this.vm.isUnloaded = true;
                        return true;
                    }
                    else {
                        this.vm.isUnloaded = false;
                        //history.forward(); Parece que ahora no hace falta hacer el forward para cancelar decentemente
                        return false;
                    }
                });
            }
        }

        return Promise.resolve(true);
    }

    protected onDeactivate(activateParentComponent: boolean): Promise<BaseComponent> {
        //Si una vez de quitar el último, el que quedase fuese el mismo (caso de cerrar ventana modal), no habría que hacer nada,
        //ni lanzar el onExit ni nada
        let nextLastVM: rps.viewmodels.BaseVM;
        if (rps.app.stateManager.stateStack.current == this) //Se quitará
            nextLastVM = rps.app.stateManager.stateStack.length > 1 ?
                rps.app.stateManager.stateStack.at(rps.app.stateManager.stateStack.length - 2).vm : null;
        else //No lo quita
            nextLastVM = rps.app.stateManager.stateStack.length > 0 ? rps.app.stateManager.stateStack.current.vm : null;
        if (this.vm === nextLastVM)
            return Promise.resolve(this);

        //Caso normal; hay que lanzar el onExit antes de seguir con la destrucción del vm actual
        //Destruir el vm actual
        if (this.vm) {
            //Se espera a terminar onExit para salir
            return this.vm.processRules(rps.viewmodels.rules.Triggers.OnExit).then(() => {
                //var _vm = this.vm;
                this.vm = null;

                //Al estar cacheado, no se destruye en este momento, sino cuando se elimina de la cache
                //if (rps.extensions.functionName(this) == "$Main")
                //    _vm.onDestroy();
                //else if (_vm.parentVM instanceof rps.viewmodels.MaintenanceComponentVM)
                //    _vm.onDestroy();
                //else if (_vm.parentVM instanceof rps.viewmodels.RecursiveHierarchicalMaintenanceComponentVM)
                //    _vm.onDestroy();

                //Me quito si soy el último
                if (rps.app.stateManager.stateStack.current == this)
                    rps.app.stateManager.stateStack.removeLast();
                else {
                    //Puede haber casos en los que se desactive el componente porque desde una ventan modal se está navegando
                    //en el OK a otra ventana. En estos casos, al ser el el procesado de reglas onExit un proceso asíncrono,
                    //el siguiente elemento se añade al stack antes de quitar el último; se intenta eliminarsi está en la lista,
                    //aunque no sea el último. En ese caso, no se debe activar el último componente de la lista
                    //Caso concreto: Ventas -> Albaranes -> Nuevo desde pedido -> OK En la ventana -> navega al detalle de albarán
                    if (rps.app.stateManager.stateStack.tryRemove(this))
                        activateParentComponent = false;
                }
                rps.app.stateManager.resetStateHierarchy();

                this._componentProvider.viewModels.remove(BaseComponent.getVMKey(this.activatedRoute));

                //Además, lanzo el onActivate del último de la lista
                const lastVM = rps.app.stateManager.stateStack.length > 0 ? rps.app.stateManager.stateStack.current.vm : null;
                if (lastVM && !lastVM.isUnloaded && activateParentComponent) {
                    lastVM.onActivate();
                    lastVM.processRules(rps.viewmodels.rules.Triggers.OnActivate);
                }

                return this;
            });
        }
        else {
            //Además, lanzo el onActivate del último de la lista 
            const lastVM = rps.app.stateManager.stateStack.length > 0 ? rps.app.stateManager.stateStack.current.vm : null;
            if (lastVM && !lastVM.isUnloaded && activateParentComponent) {
                lastVM.onActivate();
                lastVM.processRules(rps.viewmodels.rules.Triggers.OnActivate);
            }
        }

        return Promise.resolve(this);
    }

    ngOnInit() {
        var s = this.activatedRoute.snapshot;
        this.configOptions = s.data['configOptions'];
        this._componentProvider = s.data['vm'].componentProvider;
        var vm: rps.viewmodels.BaseVM = s.data['vm'].vm;

        this.vm = vm;
        this.vm.isUnloaded = false;

        //Mezclar los parámetros para guardarlos
        this.routeParams = BaseComponent.getRouteParams(this.activatedRoute);

        //Si es el componente principal, limpiar el stack
        if (rps.extensions.functionName(this) == "$Main")
            rps.app.stateManager.stateStack.clear();
        //Y añadir el actual
        rps.app.stateManager.stateStack.add(this);

        //Lanzar el enter y el onActivate
        this.vm.processRules(rps.viewmodels.rules.Triggers.OnEnter).then(() => {
            this.onActivate();
        });

        //Se engancha al cambio de parámetros de ruta para casos como el Next/Previous, donde cambian los parámetros pero no la ruta
        //EN estos casos se reaprovecha el componente pero no el VM
        //Hacerlo sólo si cambian; se pone un tiempo de delay porque hay casos en los que cambian tanto params y queryparams
        //y no funciona bien el método suscrito.
        this.sub = this.activatedRoute.params.debounceTime(200).subscribe(p => this.paramsChanged(p));
        this.qSub = this.activatedRoute.queryParams.debounceTime(200).subscribe(p => this.paramsChanged(p));
    }

    public onActivate() {
        if (this.vm.isUnloaded)
            return;

        this.vm.onActivate();
        this.vm.processRules(rps.viewmodels.rules.Triggers.OnActivate);
    }

    private paramsChanged(params: Params) {
        var newParams = BaseComponent.getRouteParams(this.activatedRoute);

        //Mirar si ya está resuelto de antes
        var key = BaseComponent.getVMKey(this.activatedRoute);
        if (this._componentProvider.viewModels.containsKey(key) && this.vm != this._componentProvider.viewModels[key]) {
            this.setNewVM(key, newParams, this._componentProvider.viewModels[key]).then(() => {
                this.redirectNewToId(params);
            });;
        }
        else if (!this._componentProvider.viewModels.containsKey(key) && this.vm && this.vm.parentVM) {
            //Intentar resolver desde el padre
            this.vm.parentVM.resolveStateViewModel({ stateName: this.configOptions.stateAbsoluteName, stateParams: newParams }).then((m) => {
                if (!m) {
                    //Si no puede, se crea una nueva instancia
                    var vmNameParts = this.configOptions.viewModelName.split(".");
                    try {
                        var functionName = rps[vmNameParts[0]]["viewmodels"][vmNameParts[1]][vmNameParts[2]];
                        var initParams = { stateParams: newParams, parentVM: null };
                        if (this.vm.parentVM)
                            initParams.parentVM = this.vm.parentVM;

                        rps.app.viewModelFactory.createViewModel(functionName, initParams).then((newVM) => {
                            this.setNewVM(key, newParams, newVM).then(() => {
                                this.redirectNewToId(params);
                            });
                        });
                    }
                    catch (e) {
                    }
                }
                else {
                    this.setNewVM(key, newParams, <rps.viewmodels.BaseVM>m).then(() => {
                        this.redirectNewToId(params);
                    });;
                }
            });
        }
        else
            this.redirectNewToId(params);
    }

    private redirectNewToId(params: Params): void {
        //Además, mirar si hay que hacer un cambio de ruta /new a /id
        if (this.vm) {
            var entityVM = (<rps.viewmodels.EntityVM<any>>this.vm);
            if (entityVM.model && params[entityVM.idPropertyName] == rps.viewmodels.EntityVM.NEW_ID_VALUE) {
                //Si al hacer el replace la entidad no tiene ID, quiere decir que su PK es el código, y que no 
                //se realizará el replace del New. Hay que marcar el viewModel de alguna manera para que luego 
                //se controle bien el control de cambios al salir de la pantalla
                if (!entityVM.model.getEntityID())
                    entityVM.hasManualID = true;

                setTimeout(() => {
                    rps.app.stateManager.replaceID(entityVM.model.getEntityID());
                })
            }
        }
    }

    private setNewVM(key: string, newParams: rps.IParams, newVM: rps.viewmodels.BaseVM): Promise<BaseComponent> {
        //Al establecer un nuevo vm sin destruir el componente, no se guardan los __selectedViewType que hacen que
        //se vea uno u otro tipo de vista en el viewview. Se trasladan esas variables de un vm a otro (mirar el CellectionEditor.tt del TSFileGenerator)
        if (this.vm && newVM) {
            for (var prop in this.vm) {
                if (prop.startsWith("__selectedViewType"))
                    newVM[prop] = this.vm[prop];
            }
        }

        return this.onDeactivate(false).then(() => {
            this.vm = newVM;
            this.vm.isUnloaded = false;

            this.routeParams = JSON.parse(JSON.stringify(newParams));

            if (rps.app.stateManager.stateStack.current != this) {
                //Sólo se añade si no lo contiene ya...
                if (!rps.app.stateManager.stateStack.find((comp) => comp == this))
                    rps.app.stateManager.stateStack.add(this);

            }

            if (!this._componentProvider.viewModels.containsKey(key))
                this._componentProvider.viewModels.add(key, newVM);
            else
                this._componentProvider.viewModels[key] = newVM;

            if (this.vm) {
                this.onActivate();
            }

            return this;
        });
    }

    public static getVMKey(route: ActivatedRoute | ActivatedRouteSnapshot): string {

        return rps.extensions.functionName(route.component) + JSON.stringify(BaseComponent.getRouteParams(route, true));
    }

    public static getRouteParams(route: ActivatedRoute | ActivatedRouteSnapshot, onlyRouteSpecificParams: boolean = false): rps.IParams {
        var params: rps.IParams = {};
        var routeParams: any = (route instanceof ActivatedRoute ? route.params["value"] : route.params);
        for (var p in routeParams)
            params[p] = routeParams[p];

        var pathParts = (route instanceof ActivatedRoute ?
            route.snapshot.data["configOptions"].statePath.split('?') : route.data["configOptions"].statePath.split('?'));
        var routeQueryParams: Array<string> = [];
        if (pathParts.length > 1)
            routeQueryParams = pathParts[1].split('&');
        var queryParams: any = (route instanceof ActivatedRoute ? route.queryParams["value"] : route.queryParams);
        for (var p in queryParams) {
            //Si especifica onlyRouteSpecificParams, sólo se tienen en cuenta aquellos queryParams expresamente indicados en el path,
            //porque en realidad se comparten en toda la jerarquía, y hay veces que no se quiere esto.
            if (!onlyRouteSpecificParams ||
                (routeQueryParams.find(param => param == p) && rps.object.hasValue(queryParams[p]) && queryParams[p] != "null"))
                params[p] = queryParams[p];
        }

        return JSON.parse(JSON.stringify(params));
    }

    ngOnDestroy() {
        this.onDeactivate(true);

        if (this.sub)
            this.sub.unsubscribe();
        if (this.qSub)
            this.qSub.unsubscribe();

        //Destruir gadgets de kendo
        var mainView = $(this._elementRef.nativeElement).find("rps-view").first();
        if (mainView)
            kendo.destroy(mainView);
    }

    ngAfterViewInit() {
        this.trySetFocus();
    }

    private trySetFocus(): void {
        if (!this.rpsControls)
            return;
        const controls = this.rpsControls.toArray();
        for (var i = 0; i < controls.length; i++) {
            const control = controls[i];
            if (control.focus())
                return;
        }
    }

    public get $root() {
        return rps.app;
    }

    public get resources() {
        return rps.app.resources;
    }

    public isOrIsModalStateParent(stateName: string): boolean {
        //Si la actual es stateName, true
        if (rps.app.stateManager.stateStack.length > 0) {
            if (rps.app.stateManager.stateStack.current.configOptions.stateAbsoluteName == stateName)
                return true;

            //Si el actual es modal, mirar el primero no modal tirando hacia arriba
            if (rps.app.stateManager.stateStack.at(rps.app.stateManager.stateStack.length - 1).isModalComponent) {
                for (var i = rps.app.stateManager.stateStack.length - 1; i >= 0; i--) {
                    if (!rps.app.stateManager.stateStack.at(i).isModalComponent)
                        return rps.app.stateManager.stateStack.at(i).configOptions.stateAbsoluteName == stateName;
                }
            }
        }

        return false;
    }
}

export class MainComponent extends BaseComponent {
    ngOnInit() {
        super.ngOnInit();
    }
}

export class DialogComponent extends BaseComponent {

    public canDeactivate(): Promise<boolean> {        
        if (this.vm) {
            if (this.vm.isUnloaded)
                return Promise.resolve(true);

            //En el caso de que contenga este comando, significa que es una navegación modal y se está desnavegando
            if (this.vm["__CancelCommand" + this.configOptions.stateName.toLowerCase()]) {
                //Se llama al comando de cancel para que se ejecute la lógica de cerrado; entraría por aquí en caso de 
                //estar en una ventana modal y pulsar el botón de back. Se pasa un parámetro de avoidNavigation para que
                //no navegue dos veces
                return (<rps.viewmodels.commands.CommandProperty>this.vm["__CancelCommand" + this.configOptions.stateName.toLowerCase()]).execute({ fromBackButton: true }).
                    then(() => {
                        return true;
                    }).catch(() => {
                        return true;
                    });
            }
            else {
                //Sobreescrito para que no salte el onBeforeUnload si él y su padre tienen el mismo VM
                return Promise.resolve(true);
            }
        }

        return Promise.resolve(true);
    }

    public onActivate() {
       //Sobreescrito para que no salte el onActivate si él y su padre tienen el mismo VM
    }

    constructor(protected _elementRef: ElementRef,
        protected _router: Router,
        public activatedRoute: ActivatedRoute,
        configOptions: rps.viewmodels.IConfigOptions) {

        super(_elementRef,_router, activatedRoute, configOptions);

        this.isModalComponent = true;
    }
}


