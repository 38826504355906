import {
    Component, ElementRef, AfterViewInit, OnDestroy,
    OnChanges, SimpleChange, AfterViewChecked
} from '@angular/core';
import {errorDetailValidator} from '../utils/errorDetailValidator'

@Component({
    selector: 'rps-pivot-grid',
    template: `
        <div class="rps-layout-padding">
            <div class="kendoPivotGridContainer">
            </div>
        </div>
  `,
    inputs: ['rpsAdapter']
})
export class rpsPivotGrid implements AfterViewInit, OnDestroy, OnChanges, AfterViewChecked {

    private kendoPivotGrid: kendo.ui.PivotGrid;
    private kendoPivotConfigurator: kendo.ui.PivotConfigurator;
    private rpsAdapter: rps.viewmodels.adapters.PivotGridAdapter;
    private dataSource: kendo.data.PivotDataSource;
    private viewInitialised: boolean = false;
    //Variable creada para retrasar la generación de los widgets hasta que el contenedor tenga tamaño
    private createKendoWidgetsPending: boolean = false;
    //HACK:Workaround mandado por telerik, para hacer que las dimensiones atiendan a la propiedad caption
    private captions: Array<{ field: string, caption: string }>;

    constructor(private elementRef: ElementRef) {       
    }

    ngAfterViewInit() {
        this.viewInitialised = true;
        this.tryCreateKendoWidgets();
    }

    ngAfterViewChecked() {
        if (this.createKendoWidgetsPending) {
            var kendoPivotGridContainer = $(this.elementRef.nativeElement).find(".kendoPivotGridContainer");
            if (kendoPivotGridContainer.outerWidth() > 0) {
                this.tryCreateKendoWidgets();
            }
        }
    }

    tryCreateKendoWidgets() {

        var kendoPivotGridContainer = $(this.elementRef.nativeElement).find(".kendoPivotGridContainer");
        if (this.kendoPivotGrid) {
            this.kendoPivotGrid.destroy();
            this.kendoPivotGrid = null;
            this.kendoPivotConfigurator.destroy();
            this.kendoPivotConfigurator = null;
            kendoPivotGridContainer.empty();
        }

        //Sólo se crea si tiene items; si no, ya se hará en el set de los items o las columnas
        if (this.viewInitialised &&
            rps.object.hasValue(this.rpsAdapter)) {
            //Si el contenedor del PivotGrid ya tiene tamaño, se crean los widgets
            if (kendoPivotGridContainer.outerWidth() > 0) {
                this.createKendoWidgetsPending = false;                
                kendoPivotGridContainer.append(`
                    <div class="rps-pivot-grid k-widget">
                        <div class="rps-pivot-grid-pivot-grid-container">
                            <div class="rps-pivot-grid-pivot-grid"></div>
                            </div>
                        <div class="rps-pivot-grid-configurator"></div>
                    </div>`);

                var dataSource: kendo.data.PivotDataSource = this.getPivotDataSource();

                this.kendoPivotGrid = $(this.elementRef.nativeElement).find(".rps-pivot-grid-pivot-grid").kendoPivotGrid({
                    dataSource: dataSource,
                    dataBound: this.kendoListViewDataBound
                }).data("kendoPivotGrid");

                this.kendoPivotConfigurator = $(this.elementRef.nativeElement).find(".rps-pivot-grid-configurator").kendoPivotConfigurator({
                    dataSource: dataSource
                }).data("kendoPivotConfigurator");
                //Ocultar las cajas donde se pueden configurar los campos, ya que esto se puede hacer interactuando con la pivotGrid
                $(this.kendoPivotConfigurator.element.find(".k-state-default .k-state-default")[0]).width("100%");
                $(this.kendoPivotConfigurator.element.find(".k-state-default .k-state-default")[1]).hide();
            }
            else {
                this.createKendoWidgetsPending = true;
            }
        }
    }

    kendoListViewDataBound = (e: kendo.ui.PivotGridEvent) => {
        //HACK:Workaround mandado por telerik, para hacer que las dimensiones atiendan a la propiedad caption
        var captions: Array<{ field: string, caption: string }> = this.captions;        
        setTimeout(function (e) {
            for (var i = 0; i < captions.length; i++) {
                $("[data-name='" + captions[i].field + "']").each(function () {
                    $(this).contents().first()[0].textContent = captions[i].caption
                })
            }
        });
    }

    private getPivotDataSource(): kendo.data.PivotDataSource{
        //HACK:Workaround mandado por telerik, para hacer que las dimensiones atiendan a la propiedad caption
        this.captions = new Array<{ field: string, caption: string }>();

        //fields
        var fields = {};
        this.rpsAdapter.fields.forEach((field) => {
            if (field.dataType == rps.viewmodels.adapters.PivotGridAdapterFieldDataTypes.string)
                fields[field.field] = { type: "string" };
            else if (field.dataType == rps.viewmodels.adapters.PivotGridAdapterFieldDataTypes.number)
                fields[field.field] = { type: "number" };
        });

        //dimensions
        var schemaDimensions = {};        
        var columns: Array<kendo.data.PivotDataSourceAxisOptions> = new Array<kendo.data.PivotDataSourceAxisOptions>();
        var rows: Array<kendo.data.PivotDataSourceAxisOptions> = new Array<kendo.data.PivotDataSourceAxisOptions>();
        Enumerable.From<rps.viewmodels.adapters.PivotGridAdapterField>(this.rpsAdapter.fields)
            .Where(field => field.type == rps.viewmodels.adapters.PivotGridAdapterFieldTypes.dimension)
            .forEach((dimension: rps.viewmodels.adapters.PivotGridAdapterField) => {
                var title = dimension.title;
                var field = dimension.field;
                schemaDimensions[field] = { caption: title }

                //HACK:Workaround mandado por telerik, para hacer que las dimensiones atiendan a la propiedad caption
                this.captions.push({
                    field: field,
                    caption: title
                });

                if (this.rpsAdapter.columns.indexOf(dimension) > -1)
                    columns.push({
                        name: field,
                        expand:true
                    });                
                if (this.rpsAdapter.rows.indexOf(dimension) > -1)
                    rows.push({
                        name: field,
                        expand: true
                    });
            });

        //measures
        var schemaMeasures = {};
        var measures: Array<string> = new Array<string>();
        Enumerable.From<rps.viewmodels.adapters.PivotGridAdapterField>(this.rpsAdapter.fields)
            .Where(field => field.type == rps.viewmodels.adapters.PivotGridAdapterFieldTypes.measure)
            .forEach((measure: rps.viewmodels.adapters.PivotGridAdapterField) => {
                var title = measure.title;
                var field = measure.field;
                schemaMeasures[title] = {
                    field: field,
                    //format: `{0:${rps.utils.getDecimalFormat()}}`,
                    //aggregate:"sum",
                    aggregate: function (value, state, context) {
                        //Sustituye el SUM predefinido por esta función que hace lo mismo, para poder especificar la función
                        //result, que de otra manera no tiene efecto. En el result, se formatea tras redondear a 10,
                        //para evitar que aparezcan problemas por sumar decimales en coma flotante, que me mantienen muchos ceros
                        //'traseros' que no hacen falta
                        if (!isNaN(value)) {
                            return (state.accumulator || 0) + value;
                        } else {
                            return state.accumulator;
                        }
                    },
                    result: function (state) {
                        //Redondear a 10 antes de formatear, para evitar problemas por sumar en coma flotante
                        return rps.utils.getFormattedDecimal({
                            value: rps.utils.roundToDecimals(<number>(state.accumulator), 10)
                        });
                    }
                };
                if (this.rpsAdapter.measures.indexOf(measure) > -1)
                    measures.push(title);
            });        

        var dataSourceOptions: kendo.data.PivotDataSourceOptions = {
            schema: {
                model: {
                    fields: fields
                },
                cube: {
                    dimensions: schemaDimensions,
                    measures: schemaMeasures
                }
            },   
            columns: columns,
            rows: rows,
            measures: <any>measures,
            data: this.rpsAdapter.data
        };

        var dataSource: kendo.data.PivotDataSource = new kendo.data.PivotDataSource(dataSourceOptions);
        return dataSource;
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes["rpsAdapter"])
            this.tryCreateKendoWidgets();
    }

    ngOnDestroy() {
        var kendoPivotGridContainer = $(this.elementRef.nativeElement).find(".kendoPivotGridContainer");
        if (this.kendoPivotGrid) {
            this.kendoPivotGrid.destroy();
            this.kendoPivotGrid = null;
            this.kendoPivotConfigurator.destroy();
            this.kendoPivotConfigurator = null;
            kendoPivotGridContainer.empty();
        }
    }
}
  