import {Http, Response, Headers, URLSearchParams} from '@angular/http';
import {Injectable, Inject} from '@angular/core';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import {rpsAppSettings} from './appSettings';
import {rpsStateManager} from './stateManager';

@Injectable()
export class rpsAPIRef implements rps.services.IApiRef {

    constructor(private _http: Http,private appSettings: rpsAppSettings, private rpsStateManager: rpsStateManager) {
    }

    configure = () => {
        rps.app.apiRef = this;
    }

    public get<T>(params: { url: string; }): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            var resolvedUrl: string;
            resolvedUrl = this.appSettings.rpsAPIAddress + "apiref/" + params.url;
            this._http.get(
                resolvedUrl,
                {
                    body: '',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Company': this.rpsStateManager.getCurrentCompany()
                    })
                }).map((res: Response, ix?: number) => {
                    return rps.services.API.deserialize(res.text());
                }).subscribe((data: any) => {
                    resolve(data);
                }, (res:Response) => {
                    rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                });
        });
    }

    public getFile(fileUrl: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if (fileUrl) {
                this._http.get(fileUrl).map((res: Response, ix?: number) => {
                    return res.text();
                }).subscribe((data: string) => {
                    resolve(data);
                    }, (error) => {
                        if (fileUrl.endsWith(".html"))
                            resolve("<div>Could not get HTML: " + error.toString() + "</div>");
                        else
                            resolve(error.toString());
                });
            }
            else
                resolve("");
        });      
    }

    public previewQuery(query: any): Promise<{ queryDefinition: rps.data.QueryReference, querySource: rps.data.sources.QuerySource }>{
       return new Promise<{ queryDefinition: rps.data.QueryReference, querySource: rps.data.sources.QuerySource }>((resolve, reject) => {

            var resolvedUrl: string;
            resolvedUrl = this.appSettings.rpsAPIAddress + "clientapi/customqueries";
            this._http.post(
                resolvedUrl,
                JSON.stringify(rps.services.API.serialize(query)), //Se manda como string para que el deserializar en el servicio no dé problemas
                {
                    headers: new Headers({
                        'Content-Type': 'application/json',
                    })
                }).map((res: Response, ix?: number) => {
                    return rps.services.API.deserialize(res.text());
                }).subscribe((resultDefinition: rps.data.QueryReference) => {

                    //Llamar a la Url pasada para obtener datos
                    var qs = new rps.data.sources.AlternativeQuerySource({
                        queryUrl: resultDefinition.Url,
                        relatedVMFunction: rps.data.sources.QuerySourceItemVM,
                        executionPolicy: rps.queryExecutionPolicy.Automatic
                    });

                    resolve({queryDefinition: resultDefinition,querySource: qs});
                }, (res:Response) => {
                    rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                });
        });
    }

    //#region Favoritos

    public getFavourites(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this._http.get(
                this.appSettings.rpsAPIAddress + "clientapi/modules/favourites",
                {
                    body: '',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                    })
                }).map((res: Response, ix?: number) => {
                    return (res.json());
                }).subscribe((module: any) => {
                    resolve(module);
                }, (res:Response) => {
                    rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                });
        });
    }

    public addFavourite(menuId: string): Promise<boolean>{
        return new Promise<any>((resolve, reject) => {
            this._http.post(
                this.appSettings.rpsAPIAddress + "clientapi/modules/favourites",
                JSON.stringify(menuId),
                {
                    headers: new Headers({
                        'Content-Type': 'application/json',
                    })
                }).subscribe((data: any) => {
                    resolve(true);
                }, (res:Response) => {
                    rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                    resolve(false);
                });
        });
    }

    public deleteFavourite(menuId: string): Promise<boolean> {
        return new Promise<any>((resolve, reject) => {
            this._http.delete(
                this.appSettings.rpsAPIAddress + "clientapi/modules/favourites",
                {
                    body: JSON.stringify(menuId),
                    headers: new Headers({
                        'Content-Type': 'application/json',
                    })
                }).subscribe((data: any) => {
                    resolve(true);
                }, (res:Response) => {
                    rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                    resolve(false);
                });
        });
    }

    public getMenu(codUser: string, codRole: string): Promise<any[]> {
        return new Promise<any[]>((resolve, reject) => {
            this._http.get(this.appSettings.rpsAPIAddress + "clientapi/modules", {
                body: '',
                headers: new Headers({
                    'Content-Type': 'application/json',
                })
            }).map((res: Response, ix?: number) => {
                return (res.json());
            }).subscribe((moduleList: any[]) => {
                resolve(moduleList);
            }, (error) => {
                resolve(null);
            });
        });
    }

    public setMenuAccess(menuId: string, allow: boolean): Promise<boolean> {
        return new Promise<any>((resolve, reject) => {
            this._http.post(
                this.appSettings.rpsAPIAddress + "clientapi/modules/access",
                JSON.stringify({ menuId: menuId, allow: allow }),
                {
                    headers: new Headers({
                        'Content-Type': 'application/json',
                    })
                }).subscribe((data: any) => {
                    resolve(true);
                }, (res: Response) => {
                    rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                    resolve(false);
                });
        });
    }
}
