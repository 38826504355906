import { Component, ElementRef, forwardRef, Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsEditor} from './editorBase'
import {errorDetailValidator} from '../utils/errorDetailValidator'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'
import { rpsControl } from '../controlBase'

@Component({
    selector: 'rps-search-box',
    template: `
        <div class="rps-editor" 
             [rpsSemanticState]="rpsModel?.semanticState" 
             [class.rps-disabled-editor]="disabled"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label  class="rps-editor-label" 
                    [attr.for]="myId">
                {{rpsLabel}}<font *ngIf="isRequired" color="red"> *</font>
            </label>
            <span class="k-textbox k-space-left rps-search-box-span rps-editor-editor" 
                  [attr.disabled]="disabled" >
                <input class="rps-search-box-input rps-semantic-state" 
                       type="text" [attr.id]="myId"
                       [disabled]="disabled"
                       [ngModel] = "value"
                       (blur) = "setVMPropertyValue()"
                       (keypress) = "keyDownPress($event)"
                       (ngModelChange) = "nextValue = $event" 
                        placeholder="{{rpsPlaceHolder}}"/>
                <a class="k-icon k-i-search rps-search-box-link"  
                   [attr.disabled]="disabled" 
                   (click)="executeCommand()"
                    title="{{resources.directives.SEARCH}}">                    
                </a>
            </span>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsSearchBox) }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsEditor.getComponentInputs(['rpsCommandModel','rpsPlaceHolder'])
})
export class rpsSearchBox extends rpsEditor<string>  {

    public rpsCommandModel: rps.viewmodels.commands.CommandProperty;
    public rpsPlaceHolder: string;

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);        
    }

    private nextValue: string;

    private setVMPropertyValue() {
        this.updateSourceValue(this.nextValue);
    }

    protected updateTargetValue(newValue: string) {
        this.nextValue = newValue;
    }

    private keyDownPress(event) {
        if (event.keyCode == 13) {
            this.setVMPropertyValue();
            this.executeCommand();
            event.preventDefault();
        }
    }

    private executeCommand() {
        if (this.rpsCommandModel && this.rpsCommandModel.canExecute) {
            if (!this.disabled)
                this.rpsCommandModel.execute();
        }
    }

}
