import { Component, ElementRef, AfterContentInit, OnDestroy, OnChanges, SimpleChange, AfterViewChecked} from '@angular/core';
import {errorDetailValidator} from '../utils/errorDetailValidator'

@Component({
    selector: 'rps-calendar-view',
    template: `
        <div class="rps-layout-padding">
            <div [hidden]="!loaded"> 
                <div class="kendoContainer">
                </div>
            </div>
        </div>
  `,
    inputs: ['rpsSource', 'rpsLinkPropertyPath', 'rpsSelectionMode', 'rpsDefaultView']
})
export class rpsCalendarView implements AfterContentInit, OnDestroy, OnChanges,
    rps.data.sources.ChronologicalQuerySource.IChronologicalQuerySourceFilter, AfterViewChecked {

    private kendoScheduler: kendo.ui.Scheduler;
    private rpsLinkPropertyPath: string;
    private rpsSource: rps.data.sources.ChronologicalQuerySource;
    private rpsSourcePropertyChangedSub: rps.services.ISubscription;
    private viewInitialised: boolean = false;
    //Variable creada para retrasar la generación de los widgets hasta que el contenedor tenga tamaño
    private createKendoWidgetsPending: boolean = false;

    private rpsSelectionMode: string;
    private rpsDefaultView: string;
    private selectionMode: number = -1;

    public selectable: boolean = false;
    public vmPropertyBindings: Array<Function> = [];
    public date: Date = new Date();
    public views: Array<kendo.ui.SchedulerView> = [
        {
            type: "day",
            eventTemplate: rpsCalendarView.itemTemplate,
            allDayEventTemplate: rpsCalendarView.itemTemplate
        },
        {
            type: "week",
            eventTemplate: rpsCalendarView.itemTemplate,
            allDayEventTemplate: rpsCalendarView.itemTemplate
        },
        {
            type: "month",
            selected: true,
            eventTemplate: rpsCalendarView.itemTemplate,
            allDayEventTemplate: rpsCalendarView.itemTemplate
        },
        {
            type: "agenda",
            eventTemplate: (e: kendo.data.SchedulerEvent) => {
                //Si tiene link se crea template con link.
                if (this.rpsLinkPropertyPath) {
                    var template:string = ` <div class="rps-agenda-template">   
                                                <a>`+e.title+`</a>
                                            </div>`;
                } else {
                    var template:string = ` <div class="rps-agenda-template">   
                                                `+ e.title + `
                                            </div>`;
                }
                return template;
          },            
          editable: true
        }];
    public editable: kendo.ui.SchedulerEditable = {
        confirmation: false,
        create: false,
        destroy: false,
        move: false,
        resize: false,
        update: false
    };
    public items: kendo.data.SchedulerDataSource;
    public loaded: boolean = false;

    private static itemTemplate: string = ` 
            # if (typeof customColor != 'undefined') { #
            <div class="rps-agenda-item-container" style="background: #= customColor #; border-color: #= customColor #;">
            # } else { #
            <div class="rps-agenda-item-container">
            # } #
                # if (!isAllDay) { #
                    <div class="rps-agenda-item-hours">#: kendo.toString(start, "H:mm") # - #: kendo.toString(end, "H:mm")#</div>
                    <div class="rps-agenda-item-title">#: title # </div>               
                # } else { #
                    <div class="rps-agenda-item-content">#: title # </div>
                # } #
            </div>
    `;

    constructor(private elementRef: ElementRef) {
    }

    ngAfterContentInit() {
        if (this.rpsSelectionMode) {
            if (this.rpsSelectionMode == "Single") {
                this.selectable = true;
                this.selectionMode = 0;
            }
            else if (this.rpsSelectionMode == "Multiple") {
                this.selectable = true;
                this.selectionMode = 1;
            }
        }

        if (this.rpsDefaultView) {
            Enumerable.From<kendo.ui.SchedulerView>(this.views).Where(v => v.selected).forEach((view: kendo.ui.SchedulerView) => {
                view.selected = false;
            });
            Enumerable.From<kendo.ui.SchedulerView>(this.views).Where(v => v.type == this.rpsDefaultView).forEach((view: kendo.ui.SchedulerView) => {
                view.selected = true;
            });
        }

        this.viewInitialised = true;
        this.tryCreateKendoWidgets();
    }

    ngAfterViewChecked() {
        if (this.createKendoWidgetsPending) {
            var kendoContainer = $(this.elementRef.nativeElement).find(".kendoContainer");
            if (kendoContainer.outerWidth() > 0) {
                this.tryCreateKendoWidgets();
            }
        }
    }

    tryCreateKendoWidgets() {
        //Busca inputContainer (el div que contiene el input)
        var kendoContainer = $(this.elementRef.nativeElement).find(".kendoContainer").first();

        //Si el control estaba creado, destruirlo y vaciarlo
        if (this.kendoScheduler) {
            this.kendoScheduler.destroy();
            this.kendoScheduler = null;
            if (this.rpsSource)
                this.rpsSource.filter = null;
            kendoContainer.empty();
        }

        //Sólo se crea si tiene items; si no, ya se hará en el set de los items o las columnas
        if (this.viewInitialised &&
            rps.object.hasValue(this.items)) {
            //Si el contenedor del Scheduler ya tiene tamaño, se crean los widgets
            if (kendoContainer.outerWidth() > 0) {
                this.createKendoWidgetsPending = false;

                //Crear el editor
                kendoContainer.append("<div></div>");        
                this.kendoScheduler = kendoContainer.children().kendoScheduler(
                    {
                        dataSource: this.items,
                        views: this.views,
                        date: this.date,
                        autoBind: false,
                        editable: this.editable,
                        selectable: this.selectable,
                        change: this.selectionChanged,
                        edit: this.edit,
                        cancel: this.cancel
                    }).data("kendoScheduler");
                this.kendoScheduler.wrapper.on("click", ".rps-agenda-template a", (e) => {
                    //Si tiene link navegamos
                    if (this.rpsLinkPropertyPath) {
                        var uid = $(e.currentTarget).closest(".k-task").data("uid");
                        var event = this.kendoScheduler.occurrenceByUid(uid);
                        this.kendoScheduler.editEvent(event);
                    }

                });

                //HACK: Parece que la vista se selecciona en asíncrono, por lo que el método de refrescar el scheduler no funciona…
                setTimeout(() => {
                    if (this.kendoScheduler)
                        this.kendoScheduler.refresh();
                });

                if (this.rpsSource)
                    this.rpsSource.filter = this;
            }
            else {
                this.createKendoWidgetsPending = true;
            }
        }
    }

    ngOnDestroy() {
        if (this.rpsSource)
            this.rpsSource.filter = null;
        if (this.rpsSourcePropertyChangedSub)
            this.rpsSourcePropertyChangedSub.unsubscribe();        
        if (this.kendoScheduler) {
            this.kendoScheduler.destroy();
            this.kendoScheduler = null;
            var kendoContainer = $(this.elementRef.nativeElement).find(".kendoContainer").first();
            kendoContainer.empty();
        }
        $(this.elementRef.nativeElement).find(".kendoContainer").first().empty();
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes["rpsSource"])
            this.rpsSourceChanged(changes["rpsSource"].previousValue);
        if (changes["rpsLinkPropertyPath"])
            this.refreshEditable();
    }

    private refreshEditable() {
        var update: boolean = false;
        if (this.rpsLinkPropertyPath)
            update = true;
        this.editable = {
            confirmation: false,
            create: false,
            destroy: false,
            move: false,
            resize: false,
            update: update
        };
    }

    public rpsSourceChanged(oldSource: rps.data.sources.ChronologicalQuerySource) {
        if (this.rpsSourcePropertyChangedSub)
            this.rpsSourcePropertyChangedSub.unsubscribe();
        if (oldSource)
            oldSource.filter = null;

        if (this.rpsSource) {
            if (this.kendoScheduler)
                this.rpsSource.filter = this;
            this.items = this.rpsSource.dataSource;

            this.rpsSourcePropertyChangedSub = this.rpsSource.propertyChanged.subscribe((propChange) => {
                if (propChange.propertyName == "loaded")
                    this.loadedChanged(propChange.newValue);
                else if (propChange.propertyName == "items") {
                    //HACK: Si el scheduler no tiene tamaño, se intenta regenerar, para que se quede pendiente de generar
                    var kendoSchedulerContainer = $(this.elementRef.nativeElement).find(".kendoContainer");
                    if (!(kendoSchedulerContainer.outerWidth() > 0)) {
                        this.tryCreateKendoWidgets();
                    }
                }
            });
            this.loadedChanged(this.rpsSource.loaded);

            this.tryCreateKendoWidgets();
        }
        else {
            this.loaded = false;
            var newDataSource: kendo.data.SchedulerDataSource = new kendo.data.SchedulerDataSource({
                data: []
            });
            newDataSource.online(false);
            this.items = newDataSource;
        }
    }

    public loadedChanged = (newValue) => {
        this.loaded = newValue;
    }

    public edit = (e: kendo.ui.SchedulerEditEvent) => {
        e.preventDefault();
        this.kendoScheduler.dataSource.sync();
        setTimeout(() => {
            var linkProperty: rps.viewmodels.properties.LinkProperty = e.event[this.rpsLinkPropertyPath];
            linkProperty.go();
        });
    }

    public cancel = (e: kendo.ui.SchedulerCancelEvent) => {
        //HACK: Se sobrescribe el cancel, para evitar un stackOverflow del widget de kendo, 
        //al que no le encuentro el sentido…
        e.preventDefault();
    }

    public selectionChanged = (e: kendo.ui.SchedulerChangeEvent) => {
        var selectedEvents: Array<rps.viewmodels.BaseVM> = e.events;
        var selectedDataItems = [];
        if (selectedEvents.length > 0) {
            if (this.selectionMode == 0) {
                selectedDataItems.push(selectedEvents[0]);

                //TODO
                //if (selectedEvents.length > 1) {
                //    this.$timeout(() => {
                //        this.kendoScheduler.select([selectedEvents[0].uid],undefined);
                //    });                        
                //}
            }
            else {
                for (var i = 0; i < selectedEvents.length; i++) {
                    selectedDataItems.push(selectedEvents[i]);
                }
            }
        }

        this.rpsSource.setSelectedItems(selectedDataItems);
    }

    public fromDate(): Date {
        if (this.kendoScheduler && <any>this.kendoScheduler.view())
            return (<any>this.kendoScheduler.view()).startDate();
        else
            return null;
    }

    public toDate(): Date {
        if (this.kendoScheduler && <any>this.kendoScheduler.view())
            return (<any>this.kendoScheduler.view()).endDate();
        else
            return null;
    }
}
