import {Component, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { baseServerColumn } from './baseServerColumn';

@Component({
    template: `
        <div>{{formattedValue}} <span *ngIf="suffix" class="rps-formatted-number-suffix">{{suffix}}</span></div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class rpsFormattedNumberServerColumn extends baseServerColumn<rps.data.FormattedNumber>{
    value: rps.data.FormattedNumber;
    formattedValue: string;
    suffix: string;

    constructor(_cdr: ChangeDetectorRef) {
        super(_cdr);
    }

    setValue(newValue: rps.data.FormattedNumber) {
        if (this.value !== newValue) {
            this.value = newValue;

            if (this.value) {
                var newFormat = rps.utils.getDecimalFormat();
                this.formattedValue = kendo.toString(this.value.Value, newFormat);
                this.suffix = this.value.Suffix;
            }
            else {
                this.formattedValue = "";
                this.suffix = "";
            }
            this._cdr.markForCheck();
        }
    }
} 