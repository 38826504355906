import { Component, ElementRef, forwardRef,ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsEditor} from './editorBase'
import {errorDetailValidator} from '../utils/errorDetailValidator'
import {rpsSemanticState} from '../utils/semanticState'
import { rpsControl } from '../controlBase'

@Component({
    selector: 'rps-radio-button',
    template: `
        <div class="rps-editor"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label">
            </label>
            <div class="rps-radio-button-container"  
                [rpsSemanticState]="rpsModel?.semanticState"
                [class.rps-radiobutton-disabled]="disabled">
                <input class="rps-radiobutton"
                        type="radio"
                        [attr.id]="myId"
                        [attr.name]="rpsName"
                        [attr.value]="rpsValue"
                        [disabled]="disabled"
                        (change) = "onChanged()"
                        tabindex="-1">
                <label class="rps-radiobutton-label"
                        [attr.for]="myId"
                        [attr.tabindex]="tabIndexVal()"
                        (keydown.space)="checkSpaceBar($event)">
                    {{rpsLabel}}
                </label>
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsRadioButton) }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsEditor.getComponentInputs(['rpsName', 'rpsValue'])
})
export class rpsRadioButton extends rpsEditor<string> {

    rpsName: string;
    rpsValue: string;

    get inputCheckValue(): boolean {
        return this.element.find("input").prop("checked");
    }
    set inputCheckValue(newValue: boolean) {
        this.element.find("input").prop("checked", newValue);
    }

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef) {
        super(elementRef, changeDetectorRef);
    }

    updateTargetValue() {
        if (this.rpsModel && this.rpsModel.value == this.rpsValue)
            this.inputCheckValue = true;
        else
            this.inputCheckValue = false;
    }

    onChanged() {
        if (this.inputCheckValue)
            this.updateSourceValue(this.rpsValue);
    }
    checkSpaceBar(event: KeyboardEvent) {
        event.preventDefault();
        if (!this.disabled) {
            if (!this.inputCheckValue)
                this.rpsModel.value = this.rpsValue;
        }
    }
    tabIndexVal() {
        if (this.disabled) {
            return null;
        } else {
            return 0;
        }
    }
}
