import { Component, ElementRef, forwardRef, Optional, OnChanges, SimpleChange, ComponentRef,ViewChild,ViewContainerRef, ChangeDetectorRef} from '@angular/core';
import {rpsEditor} from './editorBase'
import {errorDetailValidator} from '../utils/errorDetailValidator'
import {rpsRowService} from '../layouts/row'
import {rpsButton} from './button'
import {rpsLabel} from '../readOnlyEditors/label'
import {rpsSemanticState} from '../utils/semanticState'
import { rpsControl } from '../controlBase'

@Component({
    template: `
        <div class="rps-stack-panel">
            <div class="rps-stack-panel-item" 
                 *ngIf="rpsDataComboBox.allowContextualSearch">
                <rps-button rpsTemplate="K-ICON" 
                            rpsIcon="search" 
                            [rpsModel]="rpsDataComboBox.searchCommand">
                </rps-button>
            </div>
            <div class="rps-stack-panel-item-right">
                <rps-label [rpsModel]="rpsDataComboBox.countMessage">
                </rps-label>
            </div>
        </div>
    `
})
export class rpsDataComboBoxFooter {
    rpsDataComboBox: rpsDataComboBox;
    constructor(public cdr: ChangeDetectorRef) {

    }

    setRPSDataComboBox(rpsDataComboBox: rpsDataComboBox) {
        this.rpsDataComboBox = rpsDataComboBox;
    }
}

@Component({
    selector: 'rps-data-combo-box',
    template: `
        <div class="rps-editor" 
            [rpsSemanticState]="rpsModel?.semanticState" 
            [class.rps-disabled-editor]="disabled"
            [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label" 
                   [attr.for]="myId">
                {{rpsLabel}}<font *ngIf="isRequired" color="red"> *</font>
            </label>
            <div>
                <input class="rps-editor-editor rps-semantic-state"/>
            </div>
        </div>
        <div #dynamicContainer>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsDataComboBox) }],
    inputs: rpsEditor.getComponentInputs(['rpsDataSource', 'rpsValuePath', 'rpsEditable'])
})
export class rpsDataComboBox extends rpsEditor<string> implements OnChanges {
    @ViewChild('dynamicContainer', { read: ViewContainerRef })
    private dynamicContainer: ViewContainerRef;

    private kendoControl: kendo.ui.ComboBox;
    private updatingTargetValue: boolean = false;
    private countMessage: string = rps.app.resources.directives.NO_ITEMS_TO_DISPLAY;
    private allowContextualSearch: boolean = false;
    private dataComboBoxFooterComponentRef: ComponentRef<rpsDataComboBoxFooter>;

    public rpsValuePath: string = "MainField";
    //Si  tiene rpsEditable, no es IsLimitedToList
    private _rpsEditable: boolean = false;    
    get rpsEditable(): string {
        if (this._rpsEditable)
            return "true";
        else
            return "false";
    }
    set rpsEditable(value: string) {
        if (value == "true")
            this._rpsEditable = true;
        else
            this._rpsEditable = false;
    }
    public rpsDataSource: rps.data.sources.LookupSource;

    private template: string = `
        <div id="template">
            <div class="rps-stack-panel">
                # if (data.ImageField){#
                <img class="rps-img-2 rps-layout-padding-sm rps-stack-panel-item" src="#: data.ImageField #">
                #}#
                <div class="rps-layout-padding-sm rps-stack-panel-item">#: data.MainField #</div>
                # if (data.SecondaryField){#
                <div class="rps-layout-padding-sm rps-stack-panel-item"> (#: data.SecondaryField #)</div>
                #}#
            </div>
        </div>
    `;

    private footerTemplate: string = `
        <div id="[id]" class="rps-stack-panel">
        </div>
    `;

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
        //TODO Angular2: queda probar el footerTemplate, con el allowContextualSearch, a ver si funciona el botón y todo eso
    }

    createTargetControl() {
        //Comprobar si contiene el foco, para volver a introducírselo
        var focused: boolean = $(this.elementRef.nativeElement).find("input").is(":focus");

        var footerID: string = "footer-" + this.myId;

        this.kendoControl = (<any>$(this.elementRef.nativeElement).find("input").first()).kendoLookupComboBox(
            {
                filter: "contains",
                dataTextField: this.rpsValuePath,
                dataValueField: this.rpsValuePath,
                autoBind: false,
                template: this.template,
                footerTemplate: this.footerTemplate.replace("[id]", footerID),
                change: (e: kendo.ui.ComboBoxChangeEvent) => { this.change(e) },
                clearButton: false
            }).data("kendoLookupComboBox");

        this.refreshKendoComboBoxDataSource();

        rps.app.uiFactory.createComponent<rpsDataComboBoxFooter>(rpsDataComboBoxFooter, this.dynamicContainer)
            .then((result: ComponentRef<rpsDataComboBoxFooter>) => {
                this.dataComboBoxFooterComponentRef = result;
                result.instance.setRPSDataComboBox(this);
                var footer = $(document).find("#" + footerID);
                if (footer.length > 0)
                    footer.append(result.location.nativeElement);
            });

        if (focused)
            $(this.elementRef.nativeElement).find("input").focus();
    }

    destroyTargetControl() {
        if (this.dataComboBoxFooterComponentRef) {
            this.dataComboBoxFooterComponentRef.destroy();
            this.dataComboBoxFooterComponentRef = null;
        }
        if (this.kendoControl) {
            this.kendoControl.destroy();
            this.kendoControl = null;
        }
    }

    refreshKendoComboBoxDataSource() {
        if (this.kendoControl && this.rpsDataSource) {
            if (this.rpsDataSource.variable()) {
                this.kendoControl.options.autoBind = true;
                this.kendoControl.input.on("focus", () => {
                    this.kendoControl.setDataSource(<any>this.getMatches());
                });
                this.kendoControl.input.on("focusout", () => {
                    this.kendoControl.setDataSource(null);
                });
            }
            else {
                this.kendoControl.options.autoBind = false;
                this.kendoControl.setDataSource(<any>this.getMatches());
            }
        }
    }

    updateTargetValue(newValue: string) {
        this.updatingTargetValue = true;
        this.kendoControl.value(newValue);
        this.updatingTargetValue = false;
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        super.ngOnChanges(changes);

        if (changes["rpsDataSource"]) {
            if (this.rpsDataSource)
                this.allowContextualSearch = (this.rpsDataSource.hasColumnsToRender || this.rpsDataSource.hasDescriptorField)
            else
                this.allowContextualSearch = false;
            this.refreshKendoComboBoxDataSource();
        }
    }

    enableControl() {
        if (this.kendoControl)
            this.kendoControl.enable(true);
    }

    disableControl() {
        if (this.kendoControl)
            this.kendoControl.enable(false);
    }

    getMatches(): kendo.data.DataSourceOptions {
        return {
            serverFiltering: true,
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) => { this.read(options) }
            }
        };
    }

    read(options: kendo.data.DataSourceTransportReadOptions) {
        if (this.updatingTargetValue) {
            this.countMessage = rps.app.resources.directives.NO_ITEMS_TO_DISPLAY;
            if (this.dataComboBoxFooterComponentRef)
                this.dataComboBoxFooterComponentRef.instance.cdr.markForCheck();
            //Se trampea para trabajar con un valor, que no pertenece a ningún elemento de la lista
            options.success([]);
            (<any>this.kendoControl)._state = "accept";
        }
        else {
            var filter: string;
            if (options.data.filter && options.data.filter.filters.length == 1)
                filter = (<any>options.data.filter.filters[0]).value;
            this.rpsDataSource.getMatches({
                filter: filter,
                page: 1,
                pageSize: 24

            }).then((result) => {
                if (result.items.length > 0)
                    this.countMessage = rps.string.format(rps.app.resources.directives.ITEMS_COUNT, result.items.length, result.count);
                else
                    this.countMessage = rps.app.resources.directives.NO_ITEMS_TO_DISPLAY;
                if (this.dataComboBoxFooterComponentRef)
                    this.dataComboBoxFooterComponentRef.instance.cdr.markForCheck();
                options.success(result.items);
            }).catch(() => {
                this.countMessage = rps.app.resources.directives.NO_ITEMS_TO_DISPLAY;
                if (this.dataComboBoxFooterComponentRef)
                    this.dataComboBoxFooterComponentRef.instance.cdr.markForCheck();
                options.success([]);
            });
        }
    }

    change(e: kendo.ui.ComboBoxChangeEvent) {
        var comboBox: any = e.sender;
        if (!this._rpsEditable) {
            // Se prueba 1º una búsqueda exacta, si devuelve un valor se establece.
            if (comboBox.value()) {
                return this.rpsDataSource.getMatches({
                    filter: comboBox.text(),
                    exact: true,
                    page: 1,
                    pageSize: 1
                }).then((result) => {
                    if (result.items.length == 1)
                        this.updateSourceValue(result.items[0][this.rpsValuePath]);
                    else
                        // Se prueba una búsqueda Like, y si devuelve un solo valor se establece
                        return this.rpsDataSource.getMatches({
                            filter: comboBox.text(),
                            exact: false,
                            page: 1,
                            pageSize: 1
                        }).then((result) => {
                            if (result.count == 1)
                                this.updateSourceValue(result.items[0][this.rpsValuePath]);
                            else
                                this.updateSourceValue(null);
                        });                        
                }).catch(() => {
                    this.updateSourceValue(null);
                });
            }
            else {
                this.updateSourceValue(null);
            }
        }
        else {
            this.updateSourceValue(comboBox.value());
        }
    }

    updateSourceValue(newValue: string) {
        //Si el valor que se quiere establecer en la VMProperty es el que ya tiene
        //Se actualiza el control, ya que es este el que está desincronizado
        if (this.rpsModel && this.rpsModel.value == newValue)
            this.updateTargetValue(newValue);
        else
            super.updateSourceValue(newValue);
    }

    searchCommand: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: this.search
    });

    search(): Promise<any> {
        //Se guarda una referencia al modelo, ya que en la grid, se destruye el control, antes de cerrar la ventana emergente.
        var currentModel = this.rpsModel;
        rps.app.entityManager.searchItem(this.rpsDataSource).then((newValue: rps.viewmodels.ModelVM) => {
            currentModel.value = newValue[this.rpsValuePath];
        }).catch(() => { });
        return Promise.resolve(this);
    }

    //Método que invoca la grid, para forzar que el editor recoja el foco
    setFocusAfterInit() {
        $(this.elementRef.nativeElement).find("input").focus();
    }
}
