import {
    Component, ElementRef, ViewContainerRef, ViewChild,
    ContentChild, TemplateRef, EmbeddedViewRef, ComponentRef,
    ComponentFactory,Injector
} from '@angular/core';
import { NgClass } from '@angular/common';
import { rpsCardViewBase, rpsCardViewBaseTemplate } from './cardViewBase'
import { rpsItemsControlToolbarItem } from './itemsControlToolbarItem'

@Component({
    selector: 'rps-templated-card-view',
    template: rpsCardViewBaseTemplate,
    inputs: ['rpsSource', 'rpsSelectionMode']
})
export class rpsTemplatedCardView extends rpsCardViewBase {    
    private itemComponentFactoryPromise: Promise<ComponentFactory<any>>;

    @ViewChild('dynamicContainer', { read: ViewContainerRef })
    private dynamicContainer: ViewContainerRef;

    @ContentChild("itemTemplate")
    public itemTemplate: TemplateRef<any>;

    constructor(elementRef: ElementRef, queryToolbarItem: rpsItemsControlToolbarItem) {
        super(elementRef, queryToolbarItem);
    }

    rpsSourceChanged() {
        super.rpsSourceChanged();

        this.itemComponentFactoryPromise = null;
        if (rps.object.hasValue(this.rpsSource.customCardViewItemUrl)) {
            this.itemComponentFactoryPromise = rps.app.uiFactory.createComponentFactoryFromUrl(this.rpsSource.customCardViewItemUrl);
        }
    }

    createItem(dataItem: any, element: JQuery): Promise<ComponentRef<any> | EmbeddedViewRef<any>> {
        if (rps.object.hasValue(this.itemComponentFactoryPromise)) {
            return this.itemComponentFactoryPromise.then((itemComponentFactory) => {
                return rps.app.uiFactory.createComponentRefFromFactory(itemComponentFactory, dataItem, this.dynamicContainer).then((component: ComponentRef<any>) => {
                    element.append(component.location.nativeElement.children);
                    return component;
                });                
            });
        }
        else {
            var result = this.dynamicContainer.createEmbeddedView<any>(this.itemTemplate, { $implicit: dataItem });
            element.append(result.rootNodes);
            return Promise.resolve<EmbeddedViewRef<any>>(result);
        }
    }
}