module rps.HTML5.viewmodels.Settings {

    export class SettingsViewModelExtension extends SettingsViewModel {

        public ChangeUserSettings: rps.viewmodels.commands.ApiActionCommand<{
            UserName?: string;
            CodLanguage?: string;
            CodCompany?: string;
            IDSite?: string;
        }, {}>;
        public ChangeUserSettingsCallback(result: {}): void { }

        configureVMProperties() {
            super.configureVMProperties();

            this.ChangeUserSettings = new rps.viewmodels.commands.ApiActionCommand<{}, {}>({
                target: this,
                actionName: "General/ChangeUserSettings",
                inputParams: new rps.data.parameters.QueryParams([
                    new rps.data.parameters.QueryParam("CodLanguage", () => { return this.UserLanguage.value }),
                    new rps.data.parameters.QueryParam("CodCompany", () => { return this.UserCompany.value }),
                    new rps.data.parameters.QueryParam("IDSite", () => { return this.UserSite.value }),
                    new rps.data.parameters.QueryParam("UserName", () => { return this.UserName.value })
                ]),
                outputParams: new rps.data.parameters.QueryParams([
                ]),
                resolve: this.ChangeUserSettingsCallback
            });
        }

        configureRules() {
            super.configureRules();

            //Cuando cambia la empresa, si tenía valor (NO cuando ejecuta la query, que también lanza el propertyChanged), limpiar site
            this.addRule("ClearSessionSiteOnCompanyChange")
                .onBeforePropertyChanged(this.SessionCompany).when()
                .isTrue((params) => {
                    if (rps.object.hasValue(this.SessionCompany.value) && this.SessionCompany.value != params.nextValue)
                        return true;
                }).then().setValue(this.SessionAllSitesProperty, true).cleanValue(this.SessionInvoicingPoint);

            this.addRule("ClearUserSiteOnCompanyChange")
                .onBeforePropertyChanged(this.UserCompany).when()
                .isTrue((params) => {
                    if (rps.object.hasValue(this.UserCompany.value) && this.UserCompany.value != params.nextValue)
                        return true;
                }).then().setValue(this.UserAllSites, true);

            this.addRule("StartupQueries")
                .onLoad()
                .then().executeQuery(this.CurrentSessionData).then(null).executeQuery(this.CurrentUserData).then(null)
                .executeCode(() => {
                    //Establecer visibilidad invoicingPoint
                    this.InvoicingPointIsVisible.value = !rps.string.isNullOrEmpty(this.SessionInvoicingPoint.value);
                });
        }

        public ApplySessionDataExecute(): Promise<any> {

            //La llamada se hace a través de método normal, no de acción, para que funcione el tema de cambio de variables de sesión
            return rps.app.api.invokeMethod("/Security/Session", "ChangeSessionData", {
                "codCompany": this.SessionCompany.value,
                "idSite": this.SessionSite.value,
                "codLanguage": this.SessionLanguage.value,
                "idInvoicingPoint": this.SessionInvoicingPoint.value,
            }).then((data) => {
                rps.app.notificationManager.show({
                    message: rps.app.resources.components.settings.CHANGES_APPLIED,
                    notificationType: rps.services.NotificationType.Success
                });

                return rps.app.stateManager.goToDesktop();
            }).catch((error) => {
                rps.app.notificationManager.show({
                    message:rps.app.resources.components.settings.ERROR_CHANGING_DATA,
                    notificationType: rps.services.NotificationType.Error
                });
                throw error;
            });
        }      
        public ApplySessionDataCanExecute(): rps.viewmodels.commands.CanExecuteResult {
            return rps.viewmodels.commands.CanExecuteResult.allow();
        }

        public SaveUserDataExecute(): Promise<any>{
            return this.ChangeUserSettings.execute().then((data) => {
                rps.app.notificationManager.show({
                    message: rps.app.resources.components.settings.CHANGES_APPLIED,
                    notificationType: rps.services.NotificationType.Success
                });
            }).catch((error) => {
                rps.app.notificationManager.show({
                    message: rps.app.resources.components.settings.ERROR_CHANGING_DATA,
                    notificationType: rps.services.NotificationType.Error
                });
                throw error;
            }).finally(() => {
            });
        }
        public SaveUserDataCanExecute(): rps.viewmodels.commands.CanExecuteResult {
            return rps.viewmodels.commands.CanExecuteResult.allow();
        }

        public ChangePasswordCommandExecute(result: {
            result: rps.services.NavigationResult; data: any
        }): void {
            if (result.result == rps.services.NavigationResult.OK) {
                //La llamada se hace a través de método normal, no de acción, para que funcione el tema de cambio de variables de sesión
                rps.app.api.invokeMethod("/Security/Session", "ChangePassword", {
                    "codUser": rps.app.session.user,
                    "oldPassword": this.PreviousPassword.value,
                    "newPassword": this.NewPassword.value
                }).then((data) => {
                    if (data && data.Result) {
                        rps.app.notificationManager.show({
                            message: rps.app.resources.components.settings.PASSWORD_CHANGED,
                            notificationType: rps.services.NotificationType.Success
                        });
                    }
                    else {
                        rps.app.notificationManager.show({
                            message: rps.app.resources.components.settings.ERROR_CHANGING_PASSWORD,
                            notificationType: rps.services.NotificationType.Error
                        });
                    }
                    }).catch((error) => {
                    rps.app.notificationManager.show({
                        message: rps.app.resources.components.settings.ERROR_CHANGING_PASSWORD,
                        notificationType: rps.services.NotificationType.Error
                    });
                    throw error;
                });
            }
        }
        public ChangePasswordCommandCanExecute(): rps.viewmodels.commands.CanExecuteResult {
            return rps.viewmodels.commands.CanExecuteResult.allow();
        }

        public ChangePasswordCommand_okExecute(): Promise<any> { return Promise.resolve<any>(this) }
        public ChangePasswordCommand_okCanExecute(): rps.viewmodels.commands.CanExecuteResult {
            if (rps.string.isNullOrEmpty(this.NewPassword.value))
                return rps.viewmodels.commands.CanExecuteResult.deny([resources.Settings.ERR_EMPTY_PASSWORD]);
            else if (this.NewPassword.value != this.ConfirmPassword.value)
                return rps.viewmodels.commands.CanExecuteResult.deny([resources.Settings.ERR_DIFFERENT_PASSWORDS]);

            return rps.viewmodels.commands.CanExecuteResult.allow();
        }

    }

}

rps.extensions.register(
    new rps.extensions.Extension(rps.HTML5.viewmodels.Settings.SettingsViewModelExtension, rps.HTML5.viewmodels.Settings.SettingsViewModel)
);
