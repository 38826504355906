import {Http, Response, Headers, URLSearchParams} from '@angular/http';
import {Injectable, Inject} from '@angular/core';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import {rpsAppSettings} from './appSettings';
import {rpsEventManager} from './eventManager';

@Injectable()
export class rpsResourceManager implements rps.services.IResourceManager {

    private currentCulture: string;

    public getCurrentCulture(): string {
        return this.currentCulture;
    }

    constructor(private http: Http, private appSettings: rpsAppSettings, private eventManager: rpsEventManager) {
    }

    configure = () => {
        rps.app.resourceManager = this;
    }

    public loadLanguageResources(languageId: string, loadMenu: boolean): Promise<boolean> {
        if (rps.string.isNullOrEmpty(languageId))
            return Promise.resolve(true);

        //Obtiene el idioma para saber el culture
        return rps.app.api.get({
            url: "General/Language",
            queryParams: { id: languageId },
            urlType: rps.services.UrlType.Relative
        }).then((language: { Culture: string, CultureHTML5:string }) => {
            return this.loadCultureResources(language.Culture,language.CultureHTML5, loadMenu);
        }).catch((error) => {
            return false;
        });
    }

    public loadCultureResources(culture: string, cultureHTML5: string, loadMenu: boolean): Promise<boolean> {
        culture = this.getAlternativeCulture(culture);
        cultureHTML5 = cultureHTML5 || culture;

        //Busca un fichero de recursos para el culture especificado, y lo carga
        var url: string = "./assets/lib/rps/resources/resources." + culture + ".json";

        return new Promise<boolean>((resolve) => {
            this.http.get(url)
                .map((res: Response, ix?: number) => {
                    return (res.json());
                }).subscribe((result: any) => {
                    if (result && (<any>result).rps && (<any>result).rps.app) {
                        this.mergeResources((<any>result).rps.app, rps.app);

                        this.currentCulture = culture;
                    }
                    moment.locale(culture);
                    //Localizar también los ficheros de las traducciones de los controles de Kendo, pero para la cultura a diferencia que para los mensajes, se usa la cultura de HTML5
                    //Obtenga o no el fichero con las traducciones, se configura la cultura de kendo
                    $.getScript("./assets/lib/kendo/js/cultures/kendo.culture." + cultureHTML5 + ".js").always(() => {
                        kendo.culture(cultureHTML5);
                        $.getScript("./assets/lib/kendo/js/messages/kendo.messages." + culture + ".js").always(() => {
                            //Se carga el menú, en el caso de que exista
                            if (loadMenu)
                                this.loadMenu().then(() => {
                                    resolve(true);
                                });
                            else
                                resolve(true);
                        });
                    });
                }, (error) => {
                    if (error.status == 404) {
                        if (culture != "en-GB")
                            this.loadCultureResources("en-GB", cultureHTML5, loadMenu).then(() => resolve(true));
                        else
                            resolve(false);
                    }
                    else
                        throw error;
                });
        });
    }

    private getAlternativeCulture(culture: string): string {
        if (culture) {
            var minCulture = culture.substr(0, 2);
            if (minCulture == "es")
                return "es-ES";
            else if (minCulture == "fr")
                return "fr-FR";
            else if (minCulture == "de")
                return "de-DE";
            else
                return culture;
        }

        return "en-GB";
    }

    private loadMenu(): Promise<boolean> {
        //Leer los menús
        return new Promise<boolean>((resolve) => {

            var menuPromises = [];

            //Leer módulos
            let userParam = new URLSearchParams(); userParam.set("codUser", rps.app.session.user);
            menuPromises.push(new Promise((moduleResolve) => {
                this.http.get(this.appSettings.rpsAPIAddress + "clientapi/modules", {
                    body: '',
                    search: userParam,
                    headers: new Headers({
                        'Content-Type': 'application/json',
                    })
                }).map((res: Response, ix?: number) => {
                    return (res.json());
                }).subscribe((moduleList: any[]) => {
                    moduleResolve(moduleList);
                }, (error) => {
                    moduleResolve(null);
                });
            }));

            //Y favoritos            
            menuPromises.push(rps.app.apiRef.getFavourites());

            return Promise.all(menuPromises).then((result) => {
                this.eventManager.rpsMenuChanged.emit({menu: result[0],favourites:result[1]});
                resolve(true);
            }).catch((err) => resolve(false));
        });
    }

    private mergeResources(source: {}, target: {}): void {
        for (var resource in source) {
            if (source[resource] instanceof Object && target[resource])
                this.mergeResources(source[resource], target[resource]);
            else
                target[resource] = source[resource];
        }
    }

}
