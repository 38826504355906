import {Directive, ElementRef, OnChanges, Renderer, SimpleChange } from '@angular/core';

@Directive({
    selector: '[rpsInputDisabled]',
    inputs: ['rpsInputDisabled'],
})
export class rpsInputDisabled implements OnChanges{
    rpsInputDisabled: boolean;

    constructor(private elementRef: ElementRef, private renderer: Renderer) {
    }
    ngOnChanges(changes: { [rpsInputDisabled: string]: SimpleChange }) {
        this.deleteClasses();
        if (changes['rpsInputDisabled'].currentValue) {
            this.renderer.setElementClass(this.elementRef.nativeElement, "rps-input-disabled", true);
        }
    }
    private deleteClasses() {
        this.renderer.setElementClass(this.elementRef.nativeElement, "rps-input-disabled", false);
    }
}