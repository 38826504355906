import { Pipe, PipeTransform } from '@angular/core';

// under systemjs, moment is actually exported as the default export, so we account for that
const momentConstructor: (value?: any) => moment.Moment = (<any>moment).default || moment;

@Pipe({ name: 'timeAgo', pure: false })
export class TimeAgoPipe implements PipeTransform {
    transform(value: Date | moment.Moment, omitSuffix?: boolean): string {
        return momentConstructor(value).fromNow();
    }
}

@Pipe({ name: 'calendar', pure: false })
export class CalendarPipe implements PipeTransform {
    transform(value: Date | moment.Moment, ...args: any[]): any {
        return momentConstructor(value).calendar();
    }
}