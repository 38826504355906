import {Directive, ElementRef, Input, OnChanges, SimpleChange} from '@angular/core';

@Directive({
    selector: '[rpsSrc]',
    inputs: ['rpsSrc']
})
export class rpsSrc implements OnChanges {
    rpsSrc: string;

    constructor(private el: ElementRef) {
    }

    ngOnChanges(changes: { [key: string]: SimpleChange }): any {
        if (changes['rpsSrc'])
            this.changeImage(changes['rpsSrc'].currentValue);
    }

    private changeImage(newValue: string) {
        if (newValue && newValue.length > 0)
            this.el.nativeElement.style.backgroundImage = "url(" + this.rpsSrc + ")";
        else
            this.el.nativeElement.style.backgroundImage = "none";
    }
}