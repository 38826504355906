import {Directive} from '@angular/core';

@Directive({
    selector: '[rpsImageSize]',
    inputs: ['rpsImageSize'],    
    host: {
        '[style.width]': 'rpsImageSize+"em"',
        '[style.height]': 'rpsImageSize+"em"'
    }
})
export class rpsImageSize {
    constructor() {}
}