import {
    Component, OnChanges, OnDestroy, SimpleChange, AfterViewInit,
    ElementRef, Output, EventEmitter, ViewChild, ViewContainerRef,
    ContentChild, TemplateRef, AfterContentInit
} from '@angular/core';
import {rpsButton} from '../editors/button';
import {rpsToggleButton} from '../editors/toggleButton';
import {rpsItemsControlToolbarService, rpsItemsControlToolbarItem} from './itemsControlToolbarItem';

const rpsQuerySourceToolbarBaseTemplate: string = `
    <div class="rps-sources-toolbar">
        <div>
            <div class="rps-stack-panel">
                <div #toolbarContainer>
                </div>                    
            </div>
            <div id="buttons"
                    class="rps-stack-panel rps-editor-fit-container">
                <div *ngIf="allowChangeView"
                        class="rps-stack-panel-item-right rps-layout-padding-horizontal">
                    <div class="rps-stack-panel-item"
                            *ngIf="gridViewValue">
                        <rps-toggle-button rpsTemplate="ICON"
                                            rpsIcon="fa fa-align-justify"
                                            [rpsModel]="gridViewSet"
                                            rpsLabel="{{resources.directives.GRID_VIEW}}">
                        </rps-toggle-button>
                    </div>
                    <div class="rps-stack-panel-item"
                            *ngIf="cardViewValue">
                        <rps-toggle-button rpsTemplate="ICON"
                                            rpsIcon="fa fa-th-large"
                                            [rpsModel]="cardViewSet"
                                            rpsLabel="{{resources.directives.CARD_VIEW}}">
                        </rps-toggle-button>
                    </div>
                </div>
                <rps-button *ngIf="allowExcelExport()"
                            class="rps-stack-panel-item-right rps-layout-padding-horizontal"
                            rpsTemplate="ICON"
                            rpsIcon="fa fa-file-excel-o"
                            rpsLabel="{{resources.directives.EXCEL_EXPORT}}"
                            [rpsModel]="excelExport">
                </rps-button>
                <rps-button *ngIf="allowSelectAll()"
                            class="rps-stack-panel-item-right rps-layout-padding-horizontal"
                            rpsTemplate="TEXT"
                            rpsLabel="{{resources.directives.SELECT_ALL}}"
                            [rpsModel]="selectAll">
                </rps-button>
                <rps-button *ngIf="allowUnselectAll()"
                            class="rps-stack-panel-item-right rps-layout-padding-horizontal"
                            rpsTemplate="TEXT"
                            rpsLabel="{{resources.directives.UNSELECT_ALL}}"
                            [rpsModel]="unselectAll">
                </rps-button>
                <rps-button *ngIf="allowRemovePagination()"
                            class="rps-stack-panel-item-right rps-layout-padding-horizontal"
                            rpsTemplate="TEXT"
                            rpsLabel="{{resources.directives.SHOW_ALL}}"
                            [rpsModel]="removePagination">
                </rps-button>
            </div>
            <div class="rps-layout-padding">
                <div class="kendoPagerContainer">
                </div>
                <ng-content></ng-content>
            </div>
        </div>
    </div>
    `;

const rpsQuerySourceToolbarBaseInputs: Array<string> = ['rpsSource', 'rpsViews', 'rpsSelectedView', 'rpsSelectionMode', 'rpsAllowRemovePagination', 'rpsItemTemplate', 'rpsItemTitle', 'rpsExportFormats'];

export abstract class rpsQuerySourceToolbarBase implements OnChanges, AfterViewInit, OnDestroy, AfterContentInit {
    @Output()
    rpsSelectedViewChanged: EventEmitter<string> = new EventEmitter();

    @ViewChild('toolbarContainer', { read: ViewContainerRef })
    toolbarContainer: ViewContainerRef;

    @ContentChild("toolbarTemplate")
    toolbarTemplate: TemplateRef<any>;

    private queryToolbarItems: Array<rpsItemsControlToolbarItem> = new Array<rpsItemsControlToolbarItem>();
    private viewInitialised: boolean = false;
    private items: kendo.data.DataSource;
    private kendoPager: kendo.ui.Pager;
    //Variable creada para evitar que se queden todos los tipos de vistas seleccionadas
    private refreshingViewsValues: boolean = false;

    public rpsSource: rps.data.sources.QuerySource | rps.data.sources.EntitySource;
    public rpsViews: string;
    set rpsSelectedView(newValue: string) {
        this.queryToolbarService.selectedView = newValue;
        this.rpsSelectedViewChanged.emit(newValue);
    }
    get rpsSelectedView(): string {
        return this.queryToolbarService.selectedView;
    }

    public rpsSelectionMode: string = "Single";
    public rpsAllowRemovePagination: string = "false";
    public rpsItemTemplate: string;
    public rpsItemTitle: string;

    public allowChangeView: boolean = false;
    //En el caso de que haya una vista de tipo grid view, se guarda su valor y se mantiene un boleano indicando si es la seleccionada o no, esto se hace para cambiar de vista con toggleButtons (lo mismo para el cardView)
    public gridViewValue: string = null;
    public cardViewValue: string = null;
    public viewsProperty: rps.viewmodels.properties.ComboProperty;
    public gridViewSet: rps.viewmodels.properties.VMProperty<boolean>;
    public cardViewSet: rps.viewmodels.properties.VMProperty<boolean>;
    public selectable: number = -1;

    private itemAdded$Sub: rps.services.ISubscription;
    private viewsPropertyPropertyChangedSub: rps.services.ISubscription;

    private _exportFormats: string[] = [];
    public set rpsExportFormats(formats: string) {
        this._exportFormats = formats ? formats.split(' ') : [];
    }

    public get resources() {
        return rps.app.resources;
    }

    constructor(private elementRef: ElementRef, private queryToolbarService: rpsItemsControlToolbarService) {
        this.itemAdded$Sub = this.queryToolbarService.itemAdded$.subscribe(this.addQueryToolbarItem);
        this.gridViewSet = new rps.viewmodels.properties.VMProperty<boolean>({
            target: this,
            initialValue: false
        });
        this.gridViewSet.propertyChanged.subscribe((e) => {
            //Si el usuario a deschequedo el botón, no se hace caso, ya que no se sabe que vista seleccionar
            if (this.refreshingViewsValues || e.newValue)
                this.gridViewSetPropertyChanged(e);
            else {
                setTimeout(() => {
                    this.gridViewSet.value = true;
                });
            }
        });
        this.cardViewSet = new rps.viewmodels.properties.VMProperty<boolean>({
            target: this,
            initialValue: false
        });
        this.cardViewSet.propertyChanged.subscribe((e) => {
            //Si el usuario a deschequedo el botón, no se hace caso, ya que no se sabe que vista seleccionar
            if (this.refreshingViewsValues || e.newValue)
                this.cardViewSetPropertyChanged(e);
            else {
                setTimeout(() => {
                    this.cardViewSet.value = true;
                });
            }
        });
    }

    ngAfterContentInit() {
        if (this.toolbarTemplate) {
            this.toolbarContainer.createEmbeddedView<any>(this.toolbarTemplate);
        }
    }

    addQueryToolbarItem = (queryToolbarItem: rpsItemsControlToolbarItem) => {
        this.queryToolbarItems.push(queryToolbarItem);
    }

    ngOnDestroy() {
        if (this.itemAdded$Sub)
            this.itemAdded$Sub.unsubscribe();
        if (this.viewsPropertyPropertyChangedSub)
            this.viewsPropertyPropertyChangedSub.unsubscribe();

        if (this.kendoPager) {
            this.kendoPager.destroy();
            this.kendoPager = null;
            $(this.elementRef.nativeElement).find(".kendoPagerContainer").first().empty();
        }

        if (this.cardViewSet)
            this.cardViewSet.onDestroy();
        if (this.gridViewSet)
            this.gridViewSet.onDestroy();
    }

    ngOnChanges(changes: { [key: string]: SimpleChange }): any {
        if (changes['rpsSelectedView'])
            this.selectedViewChanged();
        if (changes['rpsSelectionMode'])
            this.rpsSelectionModeChanged();
        if (changes['rpsViews'])
            this.viewsChanged();
        if (changes['rpsSource'])
            this.rpsSourceChanged();
        if (changes['rpsItemTemplate'])
            this.queryToolbarService.itemTemplate = this.rpsItemTemplate;
        if (changes['rpsItemTitle'])
            this.queryToolbarService.itemTitle = this.rpsItemTitle;
    }

    ngAfterViewInit() {
        this.viewInitialised = true;
        this.createKendoWidgets();
    }

    createKendoWidgets() {
        if (this.viewInitialised && this.items) {
            var kendoPagerContainer = $(this.elementRef.nativeElement).find(".kendoPagerContainer");

            //Se obtienen antes, por si no es la primera vez y ya están dentro del pager, que será destruido
            var buttons = $(this.elementRef.nativeElement).find("#buttons");

            if (this.kendoPager) {
                this.kendoPager.destroy();
                kendoPagerContainer.empty();
            }

            kendoPagerContainer.append("<div></div>");

            this.kendoPager = kendoPagerContainer.children().kendoPager(this.getKendoPagerOptions()).data("kendoPager");

            //HACK: En el caso de que tenga paginación, se insertan los botones en el pager, para esto, se mueve con JQuery…      
            buttons.appendTo(this.kendoPager.element);
        }
    }

    protected getKendoPagerOptions(): kendo.ui.PagerOptions {
        var options: kendo.ui.PagerOptions = {
            dataSource: this.items,
            change: () => this.pageChange()
        };
        if (this.rpsSource instanceof rps.data.sources.EntitySource ||
            this.rpsSource.disablePagination) {
            options.numeric = false;
            options.previousNext = false;
        }
        return options;
    }

    rpsSourceChanged() {
        if (this.rpsSource) {
            this.setItems(this.rpsSource.dataSource);
        }
        else {
            var newDataSource: kendo.data.DataSource = new kendo.data.DataSource({
                data: []
            });
            newDataSource.online(false);
            this.setItems(newDataSource);
        }
    }

    setItems(newItems: kendo.data.DataSource) {
        this.items = newItems;
        this.createKendoWidgets();
    }

    pageChange() {
    }

    viewsChanged() {
        //rpsViews, es de tipo string, para poder establecer valores estaticos en el HTML y de esta manera, evitar un binding
        var views: Array<rps.data.sources.IView>;
        if (rps.string.isNullOrEmpty(this.rpsViews))
            views = new Array<rps.data.sources.IView>();
        else
            views = JSON.parse(this.rpsViews);

        if (Enumerable.From<rps.data.sources.IView>(views).Any()) {
            if (rps.string.isNullOrEmpty(this.rpsSelectedView))
                this.rpsSelectedView = Enumerable.From<rps.data.sources.IView>(views).First().value;
        }
        else
            this.rpsSelectedView = null;
        this.selectedViewChanged();

        //Hay una propiedad como para manejar la vista con un combo, pero se mantiene con toggleButtons, de todas maneras, se deja la propiedad, ya que se usa para manejar el elemento seleccionado
        this.viewsProperty = new rps.viewmodels.properties.ComboProperty({
            target: this,
            initialValue: this.rpsSelectedView,
            displayPath: "name",
            valuePath: "value",
            relatedItems: views
        });
        if (this.viewsPropertyPropertyChangedSub)
            this.viewsPropertyPropertyChangedSub.unsubscribe();
        this.viewsPropertyPropertyChangedSub = this.viewsProperty.propertyChanged.subscribe((e) => {
            this.viewsPropertyPropertyChanged(e);
        });

        var viewsEnumerable: linq.Enumerable<rps.data.sources.IView> = Enumerable.From<rps.data.sources.IView>(views);
        if (viewsEnumerable.Count() > 1) {
            this.allowChangeView = true;
            if (viewsEnumerable.Any(v => v.type == rps.data.sources.ViewType.Grid))
                this.gridViewValue = viewsEnumerable.First(v => v.type == rps.data.sources.ViewType.Grid).value;
            else
                this.gridViewValue = null;
            if (viewsEnumerable.Any(v => v.type == rps.data.sources.ViewType.Card))
                this.cardViewValue = viewsEnumerable.First(v => v.type == rps.data.sources.ViewType.Card).value;
            else
                this.cardViewValue = null;
        }
        else {
            this.allowChangeView = false;
            this.gridViewValue = null;
            this.cardViewValue = null;

        }
        this.refreshViewsValues();
    }

    refreshViewsValues() {
        this.refreshingViewsValues = true;
        if (!this.rpsSelectedView) {
            this.gridViewSet.value = false;
            this.cardViewSet.value = false;
        }
        else if (this.gridViewValue == this.rpsSelectedView) {
            this.gridViewSet.value = true;
            this.cardViewSet.value = false;
        }
        else if (this.cardViewValue == this.rpsSelectedView) {
            this.gridViewSet.value = false;
            this.cardViewSet.value = true;
        }
        this.refreshingViewsValues = false;
    }

    viewsPropertyPropertyChanged(e: rps.viewmodels.properties.VMPropertyChange) {
        if (e.propertyName == "value") {
            this.rpsSelectedView = this.viewsProperty.value;
            this.selectedViewChanged();
            this.refreshViewsValues();
        }
    }

    gridViewSetPropertyChanged(e: rps.viewmodels.properties.VMPropertyChange) {
        if (e.propertyName == "value" && e.newValue)
            this.viewsProperty.value = this.gridViewValue;
    }

    cardViewSetPropertyChanged(e: rps.viewmodels.properties.VMPropertyChange) {
        if (e.propertyName == "value" && e.newValue)
            this.viewsProperty.value = this.cardViewValue;
    }

    rpsSelectionModeChanged() {
        if (this.rpsSelectionMode == "Single")
            this.selectable = 0;
        else if (this.rpsSelectionMode == "Multiple")
            this.selectable = 1;
    }

    selectedViewChanged() {
        Enumerable.From<rpsItemsControlToolbarItem>(this.queryToolbarItems).Where(ti => ti.isSelected && ti.rpsItemValue != this.rpsSelectedView).forEach((ti: rpsItemsControlToolbarItem) => {
            ti.isSelected = false;
        });
        Enumerable.From<rpsItemsControlToolbarItem>(this.queryToolbarItems).Where(ti => !ti.isSelected && ti.rpsItemValue == this.rpsSelectedView).forEach((ti: rpsItemsControlToolbarItem) => {
            ti.isSelected = true;
        });
    }

    public selectAll: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        canExecute: (): rps.viewmodels.commands.CanExecuteResult => {
            if (this.allowSelectAll()) {
                if (!this.rpsSource.hasItems()) {
                    var reasons: Array<string> = new Array<string>();
                    reasons.push(rps.app.resources.directives.NO_ITEMS_TO_SELECT);
                    return rps.viewmodels.commands.CanExecuteResult.deny(reasons);
                }
                else if (this.rpsSource.getItems().length == this.rpsSource.getSelectedItems().length) {
                    var reasons: Array<string> = new Array<string>();
                    reasons.push(rps.app.resources.directives.ALL_ITEMS_SELECTED);
                    return rps.viewmodels.commands.CanExecuteResult.deny(reasons);
                }
                else
                    return rps.viewmodels.commands.CanExecuteResult.allow();
            }
            else
                return rps.viewmodels.commands.CanExecuteResult.deny(null);
        },
        command: (): Promise<any> => {
            this.rpsSource.setSelectedItems(this.rpsSource.getItems());
            return Promise.resolve<any>(this);
        }
    });
    public allowSelectAll(): boolean {
        if (this.selectable == 1 && this.rpsSource && this.rpsSource.loaded)
            return true;
        else
            return false;
    }

    public unselectAll: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        canExecute: (): rps.viewmodels.commands.CanExecuteResult => {
            if (this.allowUnselectAll()) {
                if (!this.rpsSource.hasItems()) {
                    var reasons: Array<string> = new Array<string>();
                    reasons.push(rps.app.resources.directives.NO_ITEMS_TO_UNSELECT);
                    return rps.viewmodels.commands.CanExecuteResult.deny(reasons);
                }
                else if (!this.rpsSource.hasSelectedItems()) {
                    var reasons: Array<string> = new Array<string>();
                    reasons.push(rps.app.resources.directives.ALL_ITEMS_UNSELECTED);
                    return rps.viewmodels.commands.CanExecuteResult.deny(reasons);
                }
                else
                    return rps.viewmodels.commands.CanExecuteResult.allow();
            }
            else
                return rps.viewmodels.commands.CanExecuteResult.deny(null);
        },
        command: (): Promise<any> => {
            this.rpsSource.setSelectedItems (new Array<any>());
            return Promise.resolve<any>(this);
        }
    });
    public allowUnselectAll(): boolean {
        if (this.selectable == 1 && this.rpsSource && this.rpsSource.loaded)
            return true;
        else
            return false;
    }

    public allowExcelExport(): boolean {
        return this._exportFormats.indexOf('xlsx') != -1;
    }

    public excelExport: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        canExecute: (): rps.viewmodels.commands.CanExecuteResult => {
            if (this.rpsSource instanceof rps.data.sources.QuerySource && this.allowExcelExport())
                return this.rpsSource.export.canExecute();
            else
                return rps.viewmodels.commands.CanExecuteResult.deny(null);
        },
        command: (): Promise<any> => {
            if (this.rpsSource instanceof rps.data.sources.QuerySource)
                return this.rpsSource.export.execute({ format: 'xlsx' });
            else
                return Promise.resolve<any>(this);
        }
    });

    public removePagination: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        canExecute: (): rps.viewmodels.commands.CanExecuteResult => {
            if (this.rpsSource instanceof rps.data.sources.QuerySource) {
                var querySource: rps.data.sources.QuerySource = <rps.data.sources.QuerySource>this.rpsSource;
                var canExecuteResult: rps.viewmodels.commands.CanExecuteResult = (<() => rps.viewmodels.commands.CanExecuteResult>querySource.removePagination.canExecute)();
                return canExecuteResult;
            }
            else
                return rps.viewmodels.commands.CanExecuteResult.deny(null);
        },
        command: (): Promise<any> => {
            if (this.rpsSource instanceof rps.data.sources.QuerySource) {
                var querySource: rps.data.sources.QuerySource = <rps.data.sources.QuerySource>this.rpsSource;
                return querySource.removePagination.execute();
            }
            else {
                return Promise.resolve<any>(this);
            }
        }
    });
    public allowRemovePagination(): boolean {
        if (this.rpsAllowRemovePagination != "false" && this.rpsSource instanceof rps.data.sources.QuerySource) {
            var querySource: rps.data.sources.QuerySource = <rps.data.sources.QuerySource>this.rpsSource;
            var canExecuteResult: rps.viewmodels.commands.CanExecuteResult = (<() => rps.viewmodels.commands.CanExecuteResult>querySource.removePagination.canExecute)();
            return canExecuteResult.result;
        }
        else
            return false;
    }
}

@Component({
    selector: 'rps-query-source-toolbar',
    template: rpsQuerySourceToolbarBaseTemplate,
    inputs: rpsQuerySourceToolbarBaseInputs,
    providers: [rpsItemsControlToolbarService]
})
export class rpsQuerySourceToolbar extends rpsQuerySourceToolbarBase {

    constructor(elementRef: ElementRef, queryToolbarService: rpsItemsControlToolbarService) {
        super(elementRef, queryToolbarService);
    }
}

@Component({
    selector: 'rps-query-source-toolbar',
    template: rpsQuerySourceToolbarBaseTemplate,
    inputs: rpsQuerySourceToolbarBaseInputs,
    providers: [rpsItemsControlToolbarService]
})
export class rpsNarrowQuerySourceToolbar extends rpsQuerySourceToolbarBase {

    constructor(elementRef: ElementRef, queryToolbarService: rpsItemsControlToolbarService) {
        super(elementRef, queryToolbarService);
    }

    getKendoPagerOptions(): kendo.ui.PagerOptions {        
        var options = super.getKendoPagerOptions();                
        options.buttonCount = 1;
        options.info = true;
        options.previousNext = false;
        return options;
    }
}
