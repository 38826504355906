import {Injectable,Inject} from '@angular/core';
import {rpsLocalStorage, rpsSessionStorage} from './localStorage';
import {rpsEventManager} from './eventManager';
import {rpsStateManager} from './stateManager';
import { rpsHubsService } from './hubsService';

@Injectable()
export class rpsSession implements rps.services.ISession {

    private companiesSessionData: { [codCompany: string]: rps.services.CompanySessionData; } = {};

    private isLoggedIn: boolean = false;

    public get logged(): boolean {
        return this.isLoggedIn;
    }
    public set logged(value: boolean) {
        if (this.isLoggedIn != value) {
            this.isLoggedIn = value;
            this.raiseSessionDataChanged();
        }
    }
    user: string = "";
    role: string = "";
    language: string = "";
    company: string = "";
    idSite: string = "";
    codAccType: string = "";
    isAdmin: boolean = false;

    constructor(
        private rpsHubsService: rpsHubsService,
        private _rpsSessionStorage: rpsSessionStorage,
        private _rpsLocalStorage: rpsLocalStorage,
        private _rpsEventManager: rpsEventManager,
        private _rpsStateManager: rpsStateManager) {

    }

    public configure() {
        rps.app.session = this;
    }

    private raiseSessionDataChanged = () => {
        this._rpsEventManager.rpsSessionDataChanged.emit(null);
    }

    getMachineId(): Promise<string> {
        var context;
        return new Promise((resolve) => {
            try {

                if (context = new ((<any>window).OfflineAudioContext || (<any>window).webkitOfflineAudioContext)(1, 44100, 44100), !context) {
                    return resolve("machine");
                }

                // Create oscillator
                var result = 0;
                var pxi_oscillator = context.createOscillator();
                pxi_oscillator.type = "triangle";
                pxi_oscillator.frequency.value = 1e4;

                // Create and configure compressor
                var pxi_compressor = context.createDynamicsCompressor();
                pxi_compressor.threshold && (pxi_compressor.threshold.value = -50);
                pxi_compressor.knee && (pxi_compressor.knee.value = 40);
                pxi_compressor.ratio && (pxi_compressor.ratio.value = 12);
                pxi_compressor.reduction && (pxi_compressor.reduction.value = -20);
                pxi_compressor.attack && (pxi_compressor.attack.value = 0);
                pxi_compressor.release && (pxi_compressor.release.value = .25);

                // Connect nodes
                pxi_oscillator.connect(pxi_compressor);
                pxi_compressor.connect(context.destination);

                // Start audio processing
                pxi_oscillator.start(0);
                context.startRendering();
                context.oncomplete = function (evnt) {
                    //var sha1 = CryptoJS.algo.SHA1.create();
                    //for (var i = 0; i < evnt.renderedBuffer.length; i++) {
                    //    sha1.update(evnt.renderedBuffer.getChannelData(0)[i].toString());
                    //}
                    //hash = sha1.finalize();
                    //pxi_full_buffer_hash = hash.toString(CryptoJS.enc.Hex);
                    //set_result(pxi_full_buffer_hash, "pxi_full_buffer_result");
                    //console.log(pxi_full_buffer_hash);
                    //for (var i = 4500; 5e3 > i; i++) {
                    //    pxi_output += Math.abs(evnt.renderedBuffer.getChannelData(0)[i]);
                    //}
                    //set_result(pxi_output.toString(), "pxi_result");
                    for (var i = 0; i < evnt.renderedBuffer.length; i++) {
                        result += evnt.renderedBuffer.getChannelData(0)[i].toString().hashCode();
                    }
                    pxi_compressor.disconnect();
                    resolve(result.toString());
                }
            } catch (u) {
                resolve("machine");
            }
        });
    }

    logOn(user: string, password: string): any {
        return new Promise((resolve, reject) => {
            if (user) {

                return this.getMachineId().then((machineName) => {

                    var logOnParameters = {
                        basoaClientId: "2@" + machineName + "[1].HTML5",
                        codUser: user,
                        password: password
                    };
                    if (rps.app.stateManager.getCurrentCompany())
                        logOnParameters["codCompany"] = rps.app.stateManager.getCurrentCompany();

                    rps.app.api.invokeMethod("Security/Session", "RPSLogOn", logOnParameters, [500]).
                        then((data) => {
                            //Se ignora el 500 en este caso y ya se sacará el mensaje correspondiente en el llamante
                            if (data.status != 500) {
                                this.logged = true;

                                //Se engancha al servicio de notificaciones signalR
                                this.rpsHubsService.subscribeToNotifications(user);
                            }

                            resolve(data);
                        }).catch((data) => {
                            reject(data);
                        });
                });
            };

        });
    }

    windowsLogOn(): any {
        return new Promise((resolve, reject) => {

            return this.getMachineId().then((machineName) => {

                var logOnParameters = {
                    basoaClientId: "2@" + machineName + "[1].HTML5",
                };

                if (rps.app.stateManager.getCurrentCompany())
                    logOnParameters["codCompany"] = rps.app.stateManager.getCurrentCompany();

                rps.app.api.invokeMethod("Security/Session", "AuthenticatedWindowsLogOn", logOnParameters, [500]).
                    then((data) => {
                        //Se ignora el 500 en este caso y ya se sacará el mensaje correspondiente en el llamante
                        if (data.status != 500) {
                            this.logged = true;
                            const codUser: string = data.Result;
                            //Se engancha al servicio de notificaciones signalR
                            this.rpsHubsService.subscribeToNotifications(codUser);
                        }

                        resolve(data);
                    }).catch((data) => {
                        reject(data);
                    });
            });
        });
    }

    logOff(): Promise<boolean> {
        var user = this.user;
        return rps.app.api.invokeMethod("Security/Session", "LogOff", {}).
            then((data) => {
                this.logged = false;
                this.user = null;
                this.company = "";
                rps.app.stateManager.setCurrentCompany(this.company);

                this.rpsHubsService.unsubscribeFromNotifications(user);

                return true;
            }).catch((data) => {
                return false;
            });
    }

    //Establece los nuevos datos de sesión (llamado desde la api al detectar header con cambio de sesión - sessionContext)
    //También se llama desde el checkSessionStatus, si detecta que hay una sesión activa guardada
    public setSessionData = (newData, restoreLastSession: boolean) => {
        if (newData && newData.Id !== "00000000-0000-0000-0000-000000000000") {

            //Establecer los datos de sesión (sólo si son diferentes)
            if (this.user == newData.User && this.company == newData.CodCompany && this.role == newData.Role && this.codAccType == newData.CodAccType
                && this.idSite == newData.IDSite && this.language == newData.CodLanguage)
                return;

            this.logged = true;
            if (this.user && this.user != newData.User) {
                this.rpsHubsService.unsubscribeFromNotifications(this.user);
            }
            if (newData.User && this.user != newData.User) {
                this.rpsHubsService.subscribeToNotifications(newData.User);
            }
            this.user = newData.User;
            this.company = newData.CodCompany;
            this.role = newData.Role;
            this.codAccType = newData.CodAccType;
            this.idSite = newData.IDSite;
            this.language = newData.CodLanguage;
            this.isAdmin = newData.RoleAdministrator;

            rps.app.stateManager.setCurrentCompany(this.company);

            //Guardarlos para el caso del F5
            this._rpsSessionStorage.add("SessionData",
                {
                    User: this.user,
                    CodCompany: this.company,
                    CodAccType: this.codAccType,
                    Role: this.role,
                    CodLanguage: this.language,
                    IDSite: this.idSite,
                    CodACCType: this.codAccType,
                    RoleAdministrator: this.isAdmin
                });

            //Cargar recursos y menú
            rps.app.resourceManager.loadLanguageResources(this.language, true).then(() => {
                this.raiseSessionDataChanged();

                //Redirigir a un estado si es caso, si no a myaccount
                if (restoreLastSession) {
                    setTimeout(() => {
                        if (!this._rpsStateManager.restoreRedirectPath())
                            this._rpsStateManager.goToDesktop(true);
                    });
                }
            });

            //Obtener datos de sesión de todas las empresas, que luego se pueden usar desde CL
            rps.app.api.query({ queryName: "Security/GetAllCompaniesSessionData" }).then((qr: any) => {
                for (var companyCode in qr.Result) {
                    this.companiesSessionData[companyCode] = qr.Result[companyCode];
                }
            }).catch((errr) => {
                debugger;
            });

            //Obtener datos de configuración
            //readConfigurationData();
        }
        else {

            this._rpsSessionStorage.remove("SessionData");

            this.user = "";
            this.company = "";
            this.role = "";
            this.language = "";
            this.idSite = "";
            this.codAccType = "";
            this.logged = false;
            this.isAdmin = false;

            rps.app.stateManager.setCurrentCompany(this.company);
        }

        //Lanzar el evento de cambio de datos de sesion
        this.raiseSessionDataChanged();
    }

    getSessionData = (): rps.services.SessionData => {
        return {
            CodCompany: this.company,
            User: this.user, 
            Role: this.role,
            IDSite: this.idSite
        }
    }

    getCompanySessionData = (codCompany: string): rps.services.CompanySessionData => {
        return this.companiesSessionData[codCompany];
    }

    checkSessionState = (restoreLastSession: boolean): void => {
        //Mirar si tiene una sesión guardada (caso de F5)
        var sessionData = this._rpsSessionStorage.get("SessionData");
        if (sessionData) {
            this.setSessionData(sessionData, restoreLastSession);
        }
        else {
            rps.app.api.invokeMethod("Security/Session", "GetRestoredSessionData", {}).then((value) => {
                if (value.Result && !rps.string.isNullOrEmpty(value.Result.User)) {
                    //Todavía hay una sesión activa y logeada en el servidor;
                    this.setSessionData(value.Result, restoreLastSession);
                }
                else
                    this.redirectToLogon();
            }).catch((error) => {
                this.redirectToLogon();
            });
        }
    }

    private redirectToLogon() {
        //No tiene guardado un idioma por defecto en la sesión; recuperar el del navegador y traducir fwk
        //userLanguage no es estándar, pero IE contiene el lenguaje en esta propiedad
        var browserLang = navigator.language || (<any>navigator).userLanguage;
        rps.app.resourceManager.loadCultureResources(browserLang, browserLang, false).then(() => {
            //Una vez cargado, ir a la  pantalla de login
            rps.app.stateManager.goToLogon(true);

            this.raiseSessionDataChanged();
        });
    }
}

