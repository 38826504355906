import {ErrorHandler, Injectable} from '@angular/core';

@Injectable()
export class rpsExceptionHandler extends ErrorHandler {
    constructor() {        
        super(false);
    }

    handleError(error: any): void {
        //En el caso de que la excepción comience por “HANDLED_”, el error en realidad ya se le ha notificado al usuario, pero se lanza, por si hay alguien enganchado al catch
        if (error instanceof Error &&
            rps.object.hasValue(error["rejection"]) &&
            error["rejection"] instanceof rps.errors.ErrorDetail &&
            !rps.string.isNullOrEmpty(error["rejection"].code) &&
            error["rejection"].code.startsWith("HANDLED_")) {

            //Si es un forbidden, se muestra un mensaje de notificación (antes se hacía en el treatErrorStatus, pero está mejor aquí)
            if (error["rejection"].code == "HANDLED_FORBIDDEN_ERROR") {
                rps.app.notificationManager.show({
                    message: rps.app.resources.errors.ERR_FORBIDDEN,
                    notificationType: rps.services.NotificationType.Warning
                });
            }
            return;
        }

        if (rps.app && rps.app.errorManager)
            rps.app.errorManager.handleError(error);
        super.handleError(error);
    }
}
