import { Component, ElementRef, forwardRef, Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsEditor} from './editorBase'
import {errorDetailValidator} from '../utils/errorDetailValidator'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'
import { rpsControl } from '../controlBase'

@Component({
    selector: 'rps-multi-combo-box',
    template: `
        <div class="rps-editor" 
             [rpsSemanticState]="rpsModel?.semanticState" 
             [class.rps-disabled-editor]="disabled"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label" 
                   [attr.for]="myId">
                {{rpsLabel}}
            </label>
            <div class="kendoContainer">
                <select class="rps-editor-editor rps-semantic-state" 
                        [attr.id]="myId">
                </select>
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsMultiComboBox) }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsEditor.getComponentInputs()
})
export class rpsMultiComboBox extends rpsEditor<Array<string>> {

    private kendoControl: kendo.ui.MultiSelect;
    private kendoSourceHTML: string;

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    createTargetControl() {
        //Busca inputContainer (el div que contiene el input)
        var div = this.element.find(".kendoContainer").first();

        //Se queda con una copia del HTML del input, por si necesita crearlo de nuevo (en caso de que cambien las opciones, por ejemplo)
        if (!this.kendoSourceHTML)
            this.kendoSourceHTML = div.html();

        //Si el control estaba creado, destruirlo y vaciarlo
        if (this.kendoControl) {
            this.kendoControl.destroy();
            div.empty();
            //Volver a crear un input para que pueda llamar al método de Telerik para crear el editor concreto
            div.append(this.kendoSourceHTML);
        }

        //Crear el editor
        this.kendoControl = this.element.find("select").first().kendoMultiSelect(
            {
                dataValueField: "value",
                dataTextField: "description",
                valuePrimitive: true,
                dataSource: (<rps.viewmodels.properties.MultiComboBoxProperty>this.rpsModel).comboBoxSource,
                change: () => { this.change() },
                value: this.value,
                clearButton:false
            }).data("kendoMultiSelect");
    }

    destroyTargetControl() {
        if (this.kendoControl)
            this.kendoControl.destroy();
    }

    change() {
        var newValue: Array<string> = this.kendoControl.value();
        if (!rps.object.isNullOrUndefined(newValue)) {
            if (!rps.object.isNullOrUndefined(newValue))
                this.updateSourceValue(newValue);
            else
                this.kendoControl.value(this.value);
        }
        else
            this.kendoControl.value(this.value);
    }

    updateTargetValue(newValue: Array<string>) {
        this.kendoControl.value(newValue);
    }

    enableControl() {
        this.kendoControl.enable(true);
    }

    disableControl() {
        this.kendoControl.enable(false);
    }    
}
