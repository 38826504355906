import {Component, ElementRef, ViewContainerRef,ViewChild} from '@angular/core';
import {NgClass} from '@angular/common';
import { rpsCardViewBase, rpsCardViewBaseTemplate} from './cardViewBase'
import {rpsItemsControlToolbarItem} from './itemsControlToolbarItem'

@Component({
    template: `
        <div class="rps-card col-xs-12 col-sm-6">
            <div class="rps-card-padding">
                <div class="rps-card-border">
                    <div class="rps-card-left" 
                         *ngIf="descriptor.Image">
                        <div class="rps-img-4">
                            <img class="rps-img"
                                    src="{{descriptor.Image}}"
                                    [attr.alt]="descriptor.MainDescriptor"/>  
                        </div>       
                    </div>
                    <div *ngIf="downloadURL" class="rps-card-right">
                        <a class="fa fa-download" 
                           [attr.href]="downloadURL">
                        </a>
                    </div>
                    <div class="rps-card-content">
                        <div>
                            <h4><a [routerLink]="linkRouterParams">{{descriptor?.MainDescriptor}}</a></h4>
                        </div>
                        <div>    
                            <h4 class="rps-card-attribute" 
                                *ngIf="descriptor.EntityDate != undefined">
                                {{descriptor.EntityDate.DateName}} {{descriptor.EntityDate.Date | calendar}}
                            </h4>
                            <span [ngClass]="entityStateClass"
                                  *ngIf="descriptor.EntityState != undefined">
                                {{descriptor.EntityState.StateName}}
                            </span>
                        </div>
                    </div>            
                </div>
            </div>
        </div>
    `
})
export class rpsAttachedDocumentViewItem {    
    public descriptor: rps.data.FullDescriptor;
    public entityStateClass: string;
    public downloadURL: string;
    public linkRouterParams: Array<any>;
    constructor() {
    }

    setDescriptor(newValue: rps.data.FullDescriptor) {
        this.descriptor = newValue;
        if (this.descriptor && this.descriptor.EntityState) {
            switch (this.descriptor.EntityState.StateInfo) {
                case rps.data.SemanticState.Negative:
                    this.entityStateClass = "label label-danger";
                    break;
                case rps.data.SemanticState.Neutral:
                    this.entityStateClass = "label label-default";
                    break;
                case rps.data.SemanticState.Positive:
                    this.entityStateClass = "label label-success";
                    break;
                case rps.data.SemanticState.Warning:
                    this.entityStateClass = "label label-warning";
                    break;
                case rps.data.SemanticState.Info:
                    this.entityStateClass = "label label-info";
                default:
                    this.entityStateClass = "";
            }
        }
        else {
            this.entityStateClass = "";
        }
        if (this.descriptor && this.descriptor.UILink) {
            var link = rps.app.stateManager.createRouterLink(this.descriptor.UILink);
            this.linkRouterParams = link.routerLink;
        }
    }


    setDownloadURL(newValue: string) {
        this.downloadURL = newValue;
    }
}

@Component({
    selector: 'rps-attached-document-view',
    template: rpsCardViewBaseTemplate,
    inputs: ['rpsSource', 'rpsSelectionMode']
})
export class rpsAttachedDocumentView extends rpsCardViewBase  {   
    @ViewChild('dynamicContainer', { read: ViewContainerRef })
    private dynamicContainer: ViewContainerRef;
     
    constructor(elementRef: ElementRef, queryToolbarItem: rpsItemsControlToolbarItem) {
        super(elementRef, queryToolbarItem);
    }

    createItem(dataItem: any, element: JQuery) {
        return rps.app.uiFactory.createComponent<rpsAttachedDocumentViewItem>(rpsAttachedDocumentViewItem, this.dynamicContainer)
            .then((result) => {                
                (<rpsAttachedDocumentViewItem>result.instance).setDescriptor((<any>dataItem)["model"]["descriptor"]);                
                (<rpsAttachedDocumentViewItem>result.instance).setDownloadURL((<any>dataItem)["downloadURL"].value);
                element.append(result.location.nativeElement.children);
                return result;
            });
    }
}