import { Component, ElementRef, OnChanges, SimpleChange, AfterContentInit, OnDestroy} from '@angular/core';
import { ColumnsManagerService } from '../layouts/columns';

export interface rpsWindowAction {
    target: any;
    name: string;
    method: () => any;
}

@Component({
    selector: 'rps-window',
    template: `
        <div id="windowContent" class="rps-window" (window:resize)="onResize($event)">
            <ng-content></ng-content>
        </div>
    `,
    inputs: ['rpsOpen', 'rpsTitle', 'rpsHorizontalSize','rpsActions'],
    providers: [ColumnsManagerService]
})
export class rpsWindow implements OnChanges, AfterContentInit, OnDestroy {
    rpsActions: Array<rpsWindowAction>;
    rpsOpen: boolean;
    rpsTitle: any;
    rpsHorizontalSize: string;
    private maxColumns: number = 12;
    private windowWidth: number;
    private resolvedTitle: string = "";
    private position: kendo.ui.WindowPosition = {
        top: rps.utils.getWindowTop(),
        left: "60px"
    };
    private kendoWindow:kendo.ui.Window;

    constructor(private elementRef: ElementRef, private columnsManagerService: ColumnsManagerService) { 
    }

    createKendoWindow() {
        //Creamos un array de string con las acciones, para pasárselo al widget
        var actions: Array<string> = new Array<string>();
        if (this.rpsActions) {
            this.rpsActions.forEach((action) => {
                actions.push(action.name);
            });            
        }

        var windowContainer = $(this.elementRef.nativeElement).find("#windowContent");
        this.kendoWindow = windowContainer.kendoWindow({
            modal: true,
            close: () => {
                this.rpsOpen = false;

                //Ejecutar la acción de close si existe
                if (this.rpsActions) {
                    let closeAction = this.rpsActions.find((action) => action.name == "close");
                    if (closeAction)
                        closeAction.method.call(closeAction.target);
                }
            },
            open: () => {
                this.rpsOpen = true;
            },
            title: this.resolvedTitle,
            actions: actions,
            position: this.position,
            width: this.windowWidth,
            resizable: true,
            visible: false,
            maxHeight: rps.utils.getWindowMaxHeight()
        }).data("kendoWindow");

        //Nos anganchamos al click de los botones de las acciones personalizadas y ejecutamos el método
        if (this.rpsActions) {
            this.rpsActions.forEach((action) => {
                if (action.method) {
                    this.kendoWindow.wrapper.find(".k-window-action:has(.k-i-" + action.name + ") ").click((e: JQueryEventObject) => {
                        //El del close ya se ejecuta en el close de la ventana; el resto, aquí
                        if (action.name != "close")
                            action.method.call(action.target);
                        e.preventDefault();
                    });
                }
            });
        }

        this.applyOpen();
       
    }

    ngOnDestroy() {
        if (this.kendoWindow) {
            this.kendoWindow.destroy();
            this.kendoWindow = null;
        }
    }

    ngAfterContentInit() {
        this.setWindowSize();       
        this.createKendoWindow();
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }): any {
        if (changes["rpsTitle"]) {
            if (rps.string.isNullOrEmpty(this.rpsTitle))
                this.resolvedTitle = ""
            else
                this.resolvedTitle = this.rpsTitle;
            if (this.kendoWindow) 
                this.kendoWindow.title(this.resolvedTitle);
        }
        if (changes["rpsOpen"]) {
            this.applyOpen();
        }
        if (changes["rpsHorizontalSize"]) {
            if (rps.object.isNumeric(this.rpsHorizontalSize)) {
                //enviamos al servicio el tamaño para que la columna lo recoja
                this.columnsManagerService.setColumnSize(this.rpsHorizontalSize);
            }
        }
    }

    applyOpen() {
        if (!this.kendoWindow)
            return;
        if (this.rpsOpen) {
            //Abrir la ventana en asíncrono, porque si no no le da tiempo a coger bien los tamaños de la ventana
            //y hay veces que no muestra el título
            setTimeout(() => {
                if (this.kendoWindow)
                    this.kendoWindow.open();
            });
        }
        else {
            this.kendoWindow.close();
            this.kendoWindow.destroy();
        }
    }
    onResize(event) {     
        this.setWindowSize();
        this.kendoWindow.setOptions({ width: this.windowWidth, position: this.position });
    }
    setWindowSize(){
        //Ancho de cada columna
        var colWidth: number = $(window).width() / this.maxColumns;
        //Ancho de cada columna por el numero de columnas, quitamos 120 pixeles para que no tengamos desplazamiento lateral
        this.windowWidth = (colWidth * this.maxColumns) - 120;
        //Centramos la ventana
        this.position.left = ($(window).width() - this.windowWidth) / 2;
        if (rps.object.isNumeric(this.rpsHorizontalSize)) {
            if (+this.rpsHorizontalSize > 0 && +this.rpsHorizontalSize < (this.maxColumns)) {
                //Si el el numero de columnas está entre 1 y 12, aplicamos el nuevo tamaño en funcion del numero de columnas
                this.windowWidth = (colWidth * +this.rpsHorizontalSize);
                this.position.left = ($(window).width() - this.windowWidth) / 2;
            }
        } else if (this.rpsHorizontalSize == "*") {
            //Si el numero de columnas es un "*" se ajusta el tamaño al contenido de la ventana
            this.windowWidth = 0;
            this.position.left = "60px";
        }
    }

}
