import {Component,
    ElementRef,
    NgZone,
    SimpleChange,
    OnChanges,
    AfterViewInit,
    ComponentRef,
    OnDestroy,
    ViewChild,
    ViewContainerRef,
    Optional,
    OpaqueToken,
    Type,
    AfterViewChecked,
    NgModule,
    Inject} from '@angular/core';
import {NgClass} from '@angular/common';
import {rpsWindowAction} from '../layouts/window';
import {rpsReadOnlyTextBox} from '../readOnlyEditors/readOnlyTextBox'
import {rpsReadOnlyDatePicker} from '../readOnlyEditors/readOnlyDatePicker'
import {rpsReadOnlyLookup} from '../readOnlyEditors/readOnlyLookup'
import {rpsItemsControlToolbarService} from './itemsControlToolbarItem'
import {rpsReadOnlyCheckBox} from '../readOnlyEditors/readOnlyCheckBox'
import {rpsReadOnlyColorEditor} from '../readOnlyEditors/readOnlyColorEditor'
import {rpsReadOnlyAmountEditor} from '../readOnlyEditors/readOnlyAmountEditor'
import {rpsReadOnlyPriceEditor} from '../readOnlyEditors/readOnlyPriceEditor'
import {rpsReadOnlyQuantityEditor} from '../readOnlyEditors/readOnlyQuantityEditor'
import {rpsReadOnlyAddress} from '../readOnlyEditors/readOnlyAddress'
import {rpsReadOnlyAddressValue} from '../readOnlyEditors/readOnlyAddressValue'
import {rpsReadOnlyDurationEditor} from '../readOnlyEditors/readOnlyDurationEditor'
import { rpsReadOnlyImageEditor } from '../readOnlyEditors/readOnlyImageEditor'
import { rpsReadOnlyDecimalEditor } from '../readOnlyEditors/readOnlyDecimalEditor'
import { rpsReadOnlyTimePicker } from '../readOnlyEditors/readOnlyTimePicker'
import { rpsReadOnlyPercentageEditor } from '../readOnlyEditors/readOnlyPercentageEditor'
import { rpsReadOnlyIntegerEditor } from '../readOnlyEditors/readOnlyIntegerEditor'
import { rpsReadOnlyEnumEditor } from '../readOnlyEditors/readOnlyEnumEditor'
import { rpsReadOnlyDataComboBox } from '../readOnlyEditors/readOnlyDataComboBox'
import {rpsIcon} from '../readOnlyEditors/icon'
import {rpsDocuments} from '../readOnlyEditors/documents'
import {rpsReadOnlyEntityLink} from '../readOnlyEditors/readOnlyEntityLink'
import {rpsReadOnlyFormattedNumber} from '../readOnlyEditors/readOnlyFormattedNumber'
import {rpsReadOnlyUrlEditor} from '../readOnlyEditors/readOnlyUrlEditor'
import {rpsReadOnlyEmailEditor} from '../readOnlyEditors/readOnlyEmailEditor'
import {rpsButton} from '../editors/button'
import {rpsReadOnlyLongTextBoxLittle} from '../readOnlyEditors/readOnlyLongTextBoxLittle'
import {JitCompiler} from '@angular/compiler';
import { rpsModule, rpsDesktopModule, rpsNarrowModule } from '../modules';
import {FormsModule }   from '@angular/forms';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'rps-tree-list',
    template: `
        <div class="rps-tree-list"
             [ngClass]="{'rps-items-control-detail-opened': itemDetailFormOpened}">
            <div class="rps-prevent-scroll-tree-list">
                <div class="kendoTreeListContainer" 
                        [hidden]="!loaded">
                </div>
                <div *ngIf="!loaded" 
                        class="rps-unloaded-query">
                    {{resources.directives.UNLOADED_QUERY}}
                </div>
            </div>
            <div #dynamicContainer>
            </div>
            <div #itemsControlDetail>
            </div>
        </div>
  `,
    inputs: ['rpsSource', 'rpsColumnsDefinition', 'rpsSelectionMode', 'rpsAutoFit']
})
export class rpsTreeList implements AfterViewInit, OnChanges, OnDestroy, AfterViewChecked {
    @ViewChild('dynamicContainer', { read: ViewContainerRef })
    private dynamicContainer: ViewContainerRef;

    @ViewChild('itemsControlDetail', { read: ViewContainerRef })
    private itemsControlDetail: ViewContainerRef;

    //Variable creada para retrasar la generación de los widgets hasta que el contenedor tenga tamaño
    private createKendoWidgetsPending: boolean = false;
    private formTemplate: string;
    private windowTemplate: string;
    private itemDetailComponent: ComponentRef<any>;
    private detailTarget: JQuery;
    //Propiedad que indica, como se quiere mostrar el detalle de una línea (0:Form,1:Window)
    private itemDetailType: number = 0;

    private kendoTreeList: kendo.ui.TreeList;
    private kendoTooltipTD: kendo.ui.Tooltip;
    private kendoTooltipTH: kendo.ui.Tooltip;  
    private viewInitialised: boolean = false;
    //Lista que contiene los componentes creados para la última consulta mostrada
    private components: Array<ComponentRef<any>> = new Array<ComponentRef<any>>();

    protected myId: string;

    private get resources() {
        return rps.app.resources;
    }

    public rpsSource: rps.data.sources.HierarchicalQuerySource;
    private rpsSourcePropertyChangedSub: rps.services.ISubscription;

    get rpsColumnsDefinition(): string {
        return JSON.stringify(this.columnsDefinition);
    }
    set rpsColumnsDefinition(value: string) {
        if (rps.string.isNullOrEmpty(value))
            this.columnsDefinition = undefined;
        else
            this.columnsDefinition = JSON.parse(value);
    }
    public columnsDefinition: Array<rps.ui.itemsControl.EditableColumnDefinition>;
    public rpsSelectionMode: string;
    //Si se establece cualquier valor, el ancho de las columnas se adapta a la anchura de la grid y evita el scroll
    public rpsAutoFit: string;
    
    public selectable: number = -1;
    
    public loaded: boolean = false;

    //Propiedad que indica que el itemsControl, está mostrando el detalle de un elemento
    public itemDetailFormOpened: boolean = false;
    constructor(private elementRef: ElementRef, private zone: NgZone, @Optional() private sourcesToolbarService: rpsItemsControlToolbarService, private compiler: JitCompiler) {
        this.myId = rps.guid.newGuid().split("-").join("_");        
    }

    ngOnDestroy() {
        //Eliminar todos los componentes        
        this.components.forEach((component: ComponentRef<any>) => {
            component.destroy();
        });

        if (this.kendoTreeList) {

            //Eliminar el evento de click de selección de registro
            if (this.kendoTreeList.element)
                this.kendoTreeList.element.unbind("click");

            this.kendoTreeList.options.dataBound = null;
            var c = this.kendoTreeList.options.columns.length;
            for (var i = 0; i < c; i++) {
                this.kendoTreeList.options.columns[i].editor = null;
                this.kendoTreeList.options.columns[i].template = null;
            }
            this.kendoTreeList.options.columns = null;
            this.kendoTreeList.destroy();
            this.kendoTreeList = null;
        }

        if (this.rpsSourcePropertyChangedSub) {
            this.rpsSourcePropertyChangedSub.unsubscribe();
        }

        if (this.kendoTooltipTD)
            this.kendoTooltipTD.destroy();
        if (this.kendoTooltipTH)
            this.kendoTooltipTH.destroy();

        $(this.elementRef.nativeElement).find(".kendoTreeListContainer").first().empty();;
    }

    ngAfterViewInit() {
        if (this.rpsSelectionMode) {
            if (this.rpsSelectionMode == "Single")
                this.selectable = 0;
            else if (this.rpsSelectionMode == "Multiple")
                this.selectable = 1;
        }

        this.viewInitialised = true;
        this.tryCreateKendoWidgets();
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes["rpsSource"])
            this.rpsSourceChanged();
    }

    ngAfterViewChecked() {
        if (this.createKendoWidgetsPending) {
            var kendoTreeListContainer = $(this.elementRef.nativeElement).find(".kendoTreeListContainer");
            if (kendoTreeListContainer.outerWidth() > 0) {
                this.tryCreateKendoWidgets();
            }
        }
    }

    tryCreateKendoWidgets() {
        //Sólo se crea si tiene items y columnas; si no, ya se hará en el set de los items o las columnas
        if (this.viewInitialised &&
            rps.object.hasValue(this.rpsSource) &&
            this.hasColumns()) {

            var kendoTreeListContainer = $(this.elementRef.nativeElement).find(".kendoTreeListContainer");
            if (this.kendoTreeList) {
                this.kendoTreeList.destroy();
                this.kendoTreeList = null;
                kendoTreeListContainer.empty();
                this.kendoTooltipTD.destroy();
                this.kendoTooltipTH.destroy();
            }

            //Si el contenedor de la grid ya tiene tamaño, se crean los widgets
            if (kendoTreeListContainer.outerWidth() > 0) {
                this.createKendoWidgetsPending = false;

                kendoTreeListContainer.append("<div></div>");
                this.kendoTreeList = kendoTreeListContainer.children().kendoTreeList({
                    dataSource: this.rpsSource.treeListDataSource,
                    columns: this.getColumns(kendoTreeListContainer.outerWidth()),
                    autoBind: false,
                    dataBound: this.kendoTreeListDataBoundEvent,
                    resizable: true,
                    columnMenu: false
                }).data("kendoTreeList");

                //Cuando se pueden seleccionar registros, se añade una columna con un checkBox y nos subscribimos a su click, para seleccionar la línea
                //A diferencia de la grid, el treeList, no se sincroniza con los elementos seleccionados, simplemente los altera
                this.kendoTreeList.element.on("click", ".selectorCheckbox", (eventArgs: JQueryEventObject) => { this.selectRow(eventArgs) });
                
                this.kendoTreeList.refresh();

                this.kendoTooltipTD = this.kendoTreeList.element.kendoTooltip({
                    filter: "td div",
                    position: "bottom left",
                    show: (e) => {
                        $(e.sender["content"]).parent()
                        this.kendoTooltipTH.hide();
                        if (e.sender["content"].text() != "") {
                            $(e.sender["content"]).parent().css("visibility", "visible");
                        } else {
                            this.kendoTooltipTD.hide();
                        }
                    },
                    content: function (e) {
                        $(e.sender.content).parent().css("visibility", "hidden");
                        //Guardamos el elemento y su padre porque en algunos campos como los lookups, el elemento es un enlace y
                        //no podemos comprobar las medidas directamente sobre él, pero si por su padre.
                        var lastElement = $(e.target[0]).find("*").children().last();
                        var parentElement = $(e.target[0]).find("*").children().last().parent();
                        if (lastElement[0] || parentElement[0]) {
                            if (lastElement[0].offsetWidth < lastElement[0].scrollWidth || parentElement[0].offsetWidth < parentElement[0].scrollWidth) {
                                return e.target.text().trim();
                            } else {
                                return "";
                            }
                        }
                    }
                }).data("kendoTooltip");
                this.kendoTooltipTH = this.kendoTreeList.element.kendoTooltip({
                    filter: "th[role=columnheader]:not(:empty)",
                    position: "bottom left",
                    showAfter: rps.utils.getTooltipDelay(),
                    content: function (e) {
                        return e.target.text().trim();
                    }
                }).data("kendoTooltip");
                //Workaround de telerik para que las cabeceras vacias del treelist se queden vacias y no aparezca tooltip
                $("th[role=columnheader]").each(function (i, element) {
                    if ($(element).html() === "") {
                        $(element).html("");
                    }
                });
                
            }
            else {
                this.createKendoWidgetsPending = true;
            }
        }
    }

    hasColumns(): boolean {        
        if (this.columnsDefinition)
            return true;
        else
            return false;
    }

    kendoTreeListDataBoundEvent = (e: kendo.ui.TreeListDataBoundEvent) => {
        //Limpiar los componentes del último resultado
        this.components.forEach((component) => {
            component.destroy();
        });
        this.components = new Array<ComponentRef<any>>();

        var items: JQuery = (<any>e.sender).items();
        //Por cada una de las líneas, se llama al método createItems, para que modifique el DOM, con los componentes
        items.each((index: number, elem: Element) => {
            var element = jQuery(elem);
            var dataItem = e.sender.dataItem(element);
            this.createItems(dataItem, element);
        });

        //Cuando se enlazan los datos con el origen de datos, se cierra el detalle en el caso de que se esté mostrando
        if (this.itemDetailFormOpened)
            this.closeItemDetail();
    }

    createItems(dataItem: any, row: JQuery) {        
        //Se buscan todas las columnas pendientes de generar y se generan
        row.find(".DecimalColumn").each((index: number, elem: Element) => {
            this.loadDecimalColumn(row, dataItem, elem.id);
        });
        row.find(".TimePickerColumn").each((index: number, elem: Element) => {
            this.loadTimePickerColumn(row, dataItem, elem.id);
        });
        row.find(".PercentageColumn").each((index: number, elem: Element) => {
            this.loadPercentageColumn(row, dataItem, elem.id);
        });
        row.find(".IntegerColumn").each((index: number, elem: Element) => {
            this.loadIntegerColumn(row, dataItem, elem.id);
        });
        row.find(".EnumColumn").each((index: number, elem: Element) => {
            this.loadEnumColumn(row, dataItem, elem.id);
        });
        row.find(".ComboBoxColumn").each((index: number, elem: Element) => {
            this.loadDataComboBoxColumn(row, dataItem, elem.id);
        });
        row.find(".TextBoxColumn").each((index: number, elem: Element) => {
            this.loadTextBoxColumn(row, dataItem, elem.id);
        });
        row.find(".CheckBoxColumn").each((index: number, elem: Element) => {
            this.loadCheckBoxColumn(row, dataItem, elem.id);
        });
        row.find(".DatePickerColumn").each((index: number, elem: Element) => {
            this.loadDatePickerColumn(row, dataItem, elem.id);
        });
        row.find(".LookupColumn").each((index: number, elem: Element) => {
            this.loadLookupColumn(row, dataItem, elem.id);
        });
        row.find(".MailtoColumn").each((index: number, elem: Element) => {
            this.loadMailtoColumn(row, dataItem, elem.id);
        });
        row.find(".UrlColumn").each((index: number, elem: Element) => {
            this.loadUrlColumn(row, dataItem, elem.id);
        });
        row.find(".AmountEditorColumn").each((index: number, elem: Element) => {
            this.loadAmountEditorColumn(row, dataItem, elem.id);
        });
        row.find(".PriceEditorColumn").each((index: number, elem: Element) => {
            this.loadPriceEditorColumn(row, dataItem, elem.id);
        });
        row.find(".QuantityEditorColumn").each((index: number, elem: Element) => {
            this.loadQuantityEditorColumn(row, dataItem, elem.id);
        });
        row.find(".DurationEditorColumn").each((index: number, elem: Element) => {
            this.loadDurationEditorColumn(row, dataItem, elem.id);
        });
        row.find(".AddressColumn").each((index: number, elem: Element) => {
            this.loadAddressColumn(row, dataItem, elem.id);
        });
        row.find(".AddressValueColumn").each((index: number, elem: Element) => {
            this.loadAddressValueColumn(row, dataItem, elem.id);
        });
        row.find(".ImageEditorColumn").each((index: number, elem: Element) => {
            this.loadImageEditorColumn(row, dataItem, elem.id);
        });
        row.find(".ButtonColumn").each((index: number, elem: Element) => {
            var column: rps.ui.itemsControl.EditableColumnDefinition = Enumerable.From<rps.ui.itemsControl.EditableColumnDefinition>(this.columnsDefinition).First(cd => cd.field == elem.id);
            var buttonOptions: rps.ui.itemsControl.EditableButtonOptions = <rps.ui.itemsControl.EditableButtonOptions>column.options;
            var buttonTemplate: string = "TEXT";
            var buttonLabel: string = "";
            var buttonIcon: string = "";
            var type: string = "";
            if (buttonOptions) {
                if (buttonOptions.label)
                    buttonLabel = buttonOptions.label;
                if (buttonOptions.icon)
                    buttonIcon = buttonOptions.icon;
                if (buttonOptions.type)
                    type = buttonOptions.type;
                if (buttonOptions.template)
                    buttonTemplate = buttonOptions.template;
            }

            this.loadButtonColumn(row, dataItem, elem.id, buttonTemplate, buttonLabel, buttonIcon, type);
        });
        row.find(".EntityLinkColumn").each((index: number, elem: Element) => {
            this.loadEntityLinkColumn(row, dataItem, elem.id);
        });
        row.find(".FormattedNumberColumn").each((index: number, elem: Element) => {
            this.loadFormattedNumberColumn(row, dataItem, elem.id);
        });
        row.find(".ColorEditorColumn").each((index: number, elem: Element) => {
            this.loadColorEditorColumn(row, dataItem, elem.id);
        });
        row.find(".IconColumn").each((index: number, elem: Element) => {
            this.loadIconColumn(row, dataItem, elem.id);
        });
        row.find(".DocumentsColumn").each((index: number, elem: Element) => {
            this.loadDocumentsColumn(row, dataItem, elem.id);
        });
        row.find(".LongTextBoxLittleColumn").each((index: number, elem: Element) => {
            this.loadLongTextBoxLittle(row, dataItem, elem.id);
        });
    }

    rpsSourceChanged() {
        this.tryCreateKendoWidgets();

        if (this.rpsSourcePropertyChangedSub)
            this.rpsSourcePropertyChangedSub.unsubscribe();
        this.rpsSourcePropertyChangedSub = this.rpsSource.propertyChanged.subscribe((se: rps.viewmodels.properties.VMPropertyChange) => this.querySourcePropertyChanged(se));
        this.loadedChanged();
    }

    private querySourcePropertyChanged(e: rps.viewmodels.properties.VMPropertyChange) {
        if (e.propertyName == "loaded")
            this.loadedChanged();
    }

    loadedChanged() {
        this.loaded = this.rpsSource.loaded;
    }

    getColumns(containerWidth: number):Array<kendo.ui.TreeListColumn> {
        var result: Array<kendo.ui.TreeListColumn> = [];        
        if (this.columnsDefinition) {
            var commandsColumnWidth: number = 0;

            if (this.selectable >= 0) {
                var selectionColumn: kendo.ui.TreeListColumn = {};
                //Usamos el nodeID del treelist como identificador de los checkbox para para que  sea único y no cambie cada vez que se actualiza el treelist.
                //Si ponemos un id que cambie cada vez que se actualiza, no respetará que checkbox está chekeado y lo dejara vacio siempre
                selectionColumn.template = `
                    <div class="rps-treelist-checkbox-container">
                        <input type="checkbox" class="selectorCheckbox k-checkbox" id="#=data.nodeID + "-checkbox"#"/>
                        <label class="k-checkbox-label" for="#=data.nodeID + "-checkbox"#"></label>
                    </div>
                `;
                commandsColumnWidth += 50;
                selectionColumn.width = "50px";
                result.push(selectionColumn);
            }
            var commands: Array<any> = [];
            //Si contiene una plantilla para el formulario, se añade el comando para mostrarlo
            if (this.sourcesToolbarService && this.sourcesToolbarService.itemTemplate) {
                commands.push({
                    name: "detail",
                    text: " ",
                    className: "fa fa-wpforms rps-info rps-grid-command rps-grid-detail-command",
                    click: (e) => {
                        var target: JQuery = $(e.target);
                        //Si el botón contiene la clase “rps-grid-detail-command-selected”, significa que ya está seleccionado
                        if (!target.hasClass("rps-grid-detail-command-selected")) {
                            // e.target is the DOM element representing the button
                            // get the current table row (tr)
                            var tr = target.closest("tr");
                            // get the data bound to the current table row
                            var data: rps.viewmodels.BaseVM = <any>this.kendoTreeList.dataItem(tr);
                            //Se obtiene la instancia real, ya que Kendo envuelve los ítems…
                            if (data && !(data instanceof rps.viewmodels.BaseVM))
                                data = data["selfVM"];

                            //Mostrar el formulario
                            this.openItemDetail(target, data);
                            return false;
                        }
                        else {
                            return false;
                        }
                    }
                });
            }

            if (commands.length > 0) {
                var commandsColumn: kendo.ui.TreeListColumn;
                commandsColumn = {
                    command: commands
                };

                if (commands.length == 1) {
                    commandsColumnWidth += 45;
                    commandsColumn.width = "45px";
                }
                else if (commands.length == 2) {
                    commandsColumnWidth += 80;
                    commandsColumn.width = "80px";
                }
                else if (commands.length == 3) {
                    commandsColumnWidth += 115;
                    commandsColumn.width = "115px";
                }
                result.push(commandsColumn);
            }

            //Calcular el ancho de la grid, teniendo en cuenta los comandos y el scroll
            var elementWidth: number = containerWidth - commandsColumnWidth - rps.utils.getScrollBarWidth();
            //Se quitan 5 como medida de precaución
            elementWidth = elementWidth - 5;

            //Calcular el número de columnas de las columnas
            var columnsCount = Enumerable.From<rps.ui.itemsControl.ColumnDefinition>(this.columnsDefinition).Sum(cd => cd.columns);
            //Calcular si se requiere el scroll
            var scrollable: boolean;
            if (!rps.object.isDefined(this.rpsAutoFit)) {
                //Calcular el número de columnas que entran en la grid, en función del tamaño de esta, con un mínimo de 12
                var allowedColumnsCount = elementWidth < 1200 ? 12 : Math.floor((elementWidth / 100));
                scrollable = (columnsCount > allowedColumnsCount);
            }
            else
                scrollable = false;

            //Si tiene menos de las columnas que entran, se calcula la anchura de cada columna para que ocupe el 100%
            //Si tiene más de las columnas que entran, se establece una anchura de 100
            var columnWidth: number;
            if (scrollable)
                columnWidth = 100;
            else
                columnWidth = Math.floor(elementWidth / columnsCount);

            var expandable: boolean = true;
            this.columnsDefinition.forEach((column) => {
                var treeListColumn: kendo.ui.TreeListColumn = {};
                if (expandable) {
                    treeListColumn.expandable = true;
                    expandable = false;
                }
                treeListColumn.title = column.title ? column.title : " ";
                if (scrollable)
                    treeListColumn.width = (column.columns * columnWidth) + "px";
                else
                    treeListColumn.width = column.columns * columnWidth;
                this.createColumn(column, treeListColumn);
                result.push(treeListColumn);
            });
        }

        return result;        
    }

    createColumn(column: rps.ui.itemsControl.EditableColumnDefinition, treeListColumn: kendo.ui.TreeListColumn) {
        if (!rps.object.isNullOrUndefined(column["type"])) {
            var columnDefinition: rps.ui.itemsControl.EditableColumnDefinition = <rps.ui.itemsControl.EditableColumnDefinition>column;
            this.fillColumn(columnDefinition, treeListColumn);
        }
        else
            treeListColumn.field = column.field + ".value";
    }

    fillColumn(column: rps.ui.itemsControl.EditableColumnDefinition, treeListColumn: kendo.ui.TreeListColumn) {        
        switch (column.type) {
            case rps.ui.itemsControl.EditableColumnTypes.textBox:
                treeListColumn.field = column.field + ".value";
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column TextBoxColumn'></div>";
                treeListColumn.template = kendo.template(template);                
                break;
            case rps.ui.itemsControl.EditableColumnTypes.checkBox:
                treeListColumn.field = column.field + ".value";
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column CheckBoxColumn'></div>";
                treeListColumn.template = kendo.template(template);
                break;
            case rps.ui.itemsControl.EditableColumnTypes.datePicker:
                treeListColumn.field = column.field + ".value";
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column DatePickerColumn'></div>";                
                treeListColumn.template = kendo.template(template);                
                break;
            case rps.ui.itemsControl.EditableColumnTypes.lookup:
                treeListColumn.field = column.field + ".value";
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column LookupColumn'></div>";
                treeListColumn.template = kendo.template(template);                
                break;
            case rps.ui.itemsControl.EditableColumnTypes.emailEditor:
                treeListColumn.field = column.field + ".value";
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column MailtoColumn'></div>";                
                treeListColumn.template = kendo.template(template);
                break;
            case rps.ui.itemsControl.EditableColumnTypes.urlEditor:
                treeListColumn.field = column.field + ".value";
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column UrlColumn'></div>";                
                treeListColumn.template = kendo.template(template);             
                break;
            case rps.ui.itemsControl.EditableColumnTypes.decimalEditor:
                treeListColumn.field = column.field + ".value";
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column rps-number-editor-input DecimalColumn'></div>";                
                treeListColumn.template = kendo.template(template);                
                break;
            case rps.ui.itemsControl.EditableColumnTypes.timePicker:
                treeListColumn.field = column.field + ".value";
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column TimePickerColumn'></div>";                
                treeListColumn.template = kendo.template(template);                
                break;
            case rps.ui.itemsControl.EditableColumnTypes.percentageEditor:
                treeListColumn.field = column.field + ".value";
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column rps-number-editor-input PercentageColumn'></div>";
                treeListColumn.template = kendo.template(template);                
                break;
            case rps.ui.itemsControl.EditableColumnTypes.amountEditor:
                treeListColumn.field = column.field + ".value";
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column rps-number-editor-input AmountEditorColumn'></div>";
                treeListColumn.template = kendo.template(template);                
                break;
            case rps.ui.itemsControl.EditableColumnTypes.priceEditor:
                treeListColumn.field = column.field + ".value";
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column rps-number-editor-input PriceEditorColumn'></div>";                
                treeListColumn.template = kendo.template(template);                
                break;
            case rps.ui.itemsControl.EditableColumnTypes.quantityEditor:
                treeListColumn.field = column.field + ".value";
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column rps-number-editor-input QuantityEditorColumn'></div>";
                treeListColumn.template = kendo.template(template);                
                break;
            case rps.ui.itemsControl.EditableColumnTypes.durationEditor:
                treeListColumn.field = column.field + ".value";
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column rps-number-editor-input DurationEditorColumn'></div>";
                treeListColumn.template = kendo.template(template);                
                break;
            case rps.ui.itemsControl.EditableColumnTypes.address:
                treeListColumn.field = column.field + ".Text";  
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column AddressColumn'></div>";                
                treeListColumn.template = kendo.template(template);
                break;
            case rps.ui.itemsControl.EditableColumnTypes.addressValue:
                treeListColumn.field = column.field + ".Text"; 
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column AddressValueColumn'></div>";               
                treeListColumn.template = kendo.template(template);
                break;
            case rps.ui.itemsControl.EditableColumnTypes.integerEditor:
                treeListColumn.field = column.field + ".value";
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column rps-number-editor-input IntegerColumn'></div>";
                treeListColumn.template = kendo.template(template);
                treeListColumn.format = "n0";                
                break;
            case rps.ui.itemsControl.EditableColumnTypes.imageEditor:
                treeListColumn.field = column.field + ".value";
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column ImageEditorColumn'></div>";
                treeListColumn.template = kendo.template(template);                
                break;
            case rps.ui.itemsControl.EditableColumnTypes.button:
                treeListColumn.field = column.field;
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column ButtonColumn'></div>";
                treeListColumn.template = kendo.template(template);                
                break;
            case rps.ui.itemsControl.EditableColumnTypes.enumEditor:
                treeListColumn.field = column.field + ".description";
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column EnumColumn'></div>";
                treeListColumn.template = kendo.template(template);                
                break;
            case rps.ui.itemsControl.EditableColumnTypes.dataComboBox:
                treeListColumn.field = column.field + ".value";
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column ComboBoxColumn'></div>";
                treeListColumn.template = kendo.template(template);                
                break;
            case rps.ui.itemsControl.EditableColumnTypes.entityLink:
                treeListColumn.field = column.field + ".text";
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column EntityLinkColumn'></div>";
                treeListColumn.template = kendo.template(template);                
                break;
            case rps.ui.itemsControl.EditableColumnTypes.formattedNumber:
                treeListColumn.field = column.field + ".text";
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column rps-number-editor-input FormattedNumberColumn'></div>";                
                treeListColumn.template = kendo.template(template);                
                break;
            case rps.ui.itemsControl.EditableColumnTypes.colorEditor:
                treeListColumn.field = column.field + ".value";
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column ColorEditorColumn'></div>";
                treeListColumn.template = kendo.template(template);
                break;
            case rps.ui.itemsControl.EditableColumnTypes.icon:
                treeListColumn.field = "model." + column.field;
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column IconColumn'></div>";
                treeListColumn.template = kendo.template(template);                
                break;
            case rps.ui.itemsControl.EditableColumnTypes.documents:
                treeListColumn.field = "model." + column.field;
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column DocumentsColumn'></div>";
                treeListColumn.template = kendo.template(template);
                break;
            case rps.ui.itemsControl.EditableColumnTypes.longTextBox:
                treeListColumn.field = column.field + ".value";
                var template: string = "<div id=\"" + column.field + "\" class='rps-tree-list-column LongTextBoxLittleColumn'></div>";
                treeListColumn.template = kendo.template(template);
                break;
            default:
                break;
        }
    }    

    loadDecimalColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyDecimalEditor>(rpsReadOnlyDecimalEditor, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyDecimalEditor>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }
    loadTimePickerColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyTimePicker>(rpsReadOnlyTimePicker, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyTimePicker>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }
    loadPercentageColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyPercentageEditor>(rpsReadOnlyPercentageEditor, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyPercentageEditor>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }
    loadIntegerColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyIntegerEditor>(rpsReadOnlyIntegerEditor, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyIntegerEditor>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }
    loadEnumColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyEnumEditor>(rpsReadOnlyEnumEditor, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyEnumEditor>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }
    loadDataComboBoxColumn = (row: JQuery, dataItem: any, valuePath: string) => {  
        rps.app.uiFactory.createComponent<rpsReadOnlyDataComboBox>(rpsReadOnlyDataComboBox, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyDataComboBox>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }
    loadTextBoxColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyTextBox>(rpsReadOnlyTextBox, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyTextBox>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }

    loadDatePickerColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyDatePicker>(rpsReadOnlyDatePicker, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyDatePicker>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }

    loadIconColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsIcon>(rpsIcon, this.dynamicContainer)
            .then((result: ComponentRef<rpsIcon>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }

    loadDocumentsColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsDocuments>(rpsDocuments, this.dynamicContainer)
            .then((result: ComponentRef<rpsDocuments>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }

    loadLookupColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyLookup>(rpsReadOnlyLookup, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyLookup>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }

    loadCheckBoxColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyCheckBox>(rpsReadOnlyCheckBox, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyCheckBox>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }

    loadAmountEditorColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyAmountEditor>(rpsReadOnlyAmountEditor, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyAmountEditor>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }

    loadPriceEditorColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyPriceEditor>(rpsReadOnlyPriceEditor, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyPriceEditor>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }

    loadQuantityEditorColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyQuantityEditor>(rpsReadOnlyQuantityEditor, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyQuantityEditor>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }

    loadAddressColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyAddress>(rpsReadOnlyAddress, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyAddress>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }

    loadAddressValueColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyAddressValue>(rpsReadOnlyAddressValue, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyAddressValue>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }

    loadDurationEditorColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyDurationEditor>(rpsReadOnlyDurationEditor, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyDurationEditor>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }

    loadImageEditorColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyImageEditor>(rpsReadOnlyImageEditor, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyImageEditor>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }

    loadButtonColumn = (row: JQuery, dataItem: any, valuePath: string, buttonTemplate: string, buttonLabel: string, buttonIcon: string, type: string) => {
        rps.app.uiFactory.createComponent<rpsButton>(rpsButton, this.dynamicContainer)
            .then((result: ComponentRef<rpsButton>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                result.instance.rpsTemplate = buttonTemplate;
                result.instance.rpsLabel = buttonLabel;
                result.instance.rpsIcon = buttonIcon;
                result.instance.rpsType = type;
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                changes["rpsTemplate"] = new SimpleChange(undefined, result.instance.rpsTemplate);
                changes["rpsLabel"] = new SimpleChange(undefined, result.instance.rpsLabel);
                changes["rpsIcon"] = new SimpleChange(undefined, result.instance.rpsIcon);
                changes["rpsType"] = new SimpleChange(undefined, result.instance.rpsType);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }

    loadColorEditorColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyColorEditor>(rpsReadOnlyColorEditor, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyColorEditor>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }

    loadEntityLinkColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyEntityLink>(rpsReadOnlyEntityLink, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyEntityLink>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }

    loadMailtoColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyEmailEditor>(rpsReadOnlyEmailEditor, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyEmailEditor>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }

    loadUrlColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyUrlEditor>(rpsReadOnlyUrlEditor, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyUrlEditor>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }

    loadFormattedNumberColumn = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyFormattedNumber>(rpsReadOnlyFormattedNumber, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyFormattedNumber>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }
    loadLongTextBoxLittle = (row: JQuery, dataItem: any, valuePath: string) => {
        rps.app.uiFactory.createComponent<rpsReadOnlyLongTextBoxLittle>(rpsReadOnlyLongTextBoxLittle, this.dynamicContainer)
            .then((result: ComponentRef<rpsReadOnlyLongTextBoxLittle>) => {
                this.components.push(result);

                result.instance.rpsModel = dataItem[valuePath];
                var changes: { [key: string]: SimpleChange; } = {};
                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                result.instance.ngOnChanges(changes);

                row.find("#" + valuePath).html(result.location.nativeElement);
            });
    }
    //on click of the checkbox:
    selectRow(eventArgs: JQueryEventObject) {
        if (this.selectable >= 0) {
            var checkbox: HTMLInputElement = <any>eventArgs.target;
            var checked = checkbox.checked;            
            var row = $(checkbox).closest("tr");
            var dataItem = this.kendoTreeList.dataItem(row);
            //Se obtiene la instancia real, ya que Kendo envuelve los ítems…
            if (dataItem && !(dataItem instanceof rps.viewmodels.BaseVM))
                dataItem = dataItem["selfVM"];

            var selectedDataItems: Array<any> = new Array<any>();
            if (this.selectable == 1) {
                if (this.rpsSource.ISelectable) {
                    (<rps.ISelectable>this.rpsSource).getSelectedItems().forEach((si) => {
                        selectedDataItems.push(si);                       
                    });
                }
            }
            else {
                this.kendoTreeList.element.find(".k-state-selected").removeClass("k-state-selected");
                this.kendoTreeList.element.find(".selectorCheckbox").each(function () {
                    if (this != checkbox)
                        this.checked = false;
                });
            }

            if (checked) {
                //-select the row
                row.addClass("k-state-selected");
                selectedDataItems.push(dataItem);
            } else {
                //-remove selection
                row.removeClass("k-state-selected");
                if (selectedDataItems.indexOf(dataItem) > -1)
                    selectedDataItems.splice(selectedDataItems.indexOf(dataItem), 1);
            }

            if (rps.isISelectable(this.rpsSource)) {
                this.zone.run(() => {
                    this.rpsSource.setSelectedItems(selectedDataItems);
                });
            }
        }
    }

    openItemDetail(target: JQuery, data: rps.viewmodels.BaseVM) {
        if (this.detailTarget) {
            //Eliminar la clase para dejar de identificarlo como seleccionado
            this.detailTarget.removeClass("rps-grid-detail-command-selected");
            delete this.detailTarget;
        }

        //Añadir la clase para identificarlo como seleccionado
        this.detailTarget = target;
        this.detailTarget.addClass("rps-grid-detail-command-selected");

        if (this.itemDetailType == 0)
            this.showItemDetailForm(data);
        else
            this.showItemDetailWindow(data);
    }

    showItemDetailForm(data: rps.viewmodels.BaseVM) {
        this.itemDetailType = 0;
        this.itemDetailFormOpened = true;
        //Si ya está abierto, se elimina el componente anterior, sino se establece el modo detalle
        if (this.itemDetailComponent) {
            this.itemDetailComponent.destroy();
            delete this.itemDetailComponent;
        }

        //Se crea el componente, se instancia y se añade al dom
        var module: Type<any> = this.moduleProxyFactory(this.getFormTemplate(), data, true);
        this.compiler.compileModuleAndAllComponentsAsync(module).then((moduleWithComponent) => {
            var virtualFactory = Enumerable.From(moduleWithComponent.componentFactories).First(
                (fac) => fac.selector == "rps-virtual-component");
            var componentRef: ComponentRef<any> = this.itemsControlDetail.createComponent(virtualFactory);
            this.itemDetailComponent = componentRef;
        });
    }

    showItemDetailWindow(data: rps.viewmodels.BaseVM) {
        this.itemDetailType = 1;
        this.itemDetailFormOpened = false;
        //Si ya está abierto, se elimina el componente anterior, sino se establece el modo detalle
        if (this.itemDetailComponent) {
            this.itemDetailComponent.destroy();
            delete this.itemDetailComponent;
        }

        //Se crea el componente, se instancia y se añade al dom
        var module: Type<any> = this.moduleProxyFactory(this.getWindowTemplate(), data, false);
        this.compiler.compileModuleAndAllComponentsAsync(module).then((moduleWithComponent) => {
            var virtualFactory = Enumerable.From(moduleWithComponent.componentFactories).First(
                (fac) => fac.selector == "rps-virtual-component");
            var componentRef: ComponentRef<any> = this.itemsControlDetail.createComponent(virtualFactory);
            this.itemDetailComponent = componentRef;
        });
    }

    private moduleProxyFactory(templateContent: string, viewModel: rps.viewmodels.BaseVM, isNarrow: boolean): Type<any> {
        //Se guarda en una variable, ya que si no, el this no es this…
        var _rpsTreeList: rpsTreeList = this;

        //Los input-s de tipo JSon, tienen doble comilla, por lo que se sustituye la apertura y el cierre a comilla simple
        templateContent = templateContent.replace(/"\[/g, "'[");
        templateContent = templateContent.replace(/\]"/g, "]'");

        const VM = new OpaqueToken("vm");
        const TREE_LIST = new OpaqueToken("rpsTreeList");

        @Component({
            template: templateContent,
            selector: "rps-virtual-component",
            providers: [
                { provide: VM, useValue: viewModel },
                { provide: TREE_LIST, useValue: _rpsTreeList }]
        })
        class VirtualComponent implements OnDestroy {

            //Los tabControls usan un array especial para ir guardando los tabs que se van seleccionando (y creando); llamado desde las plantillas para crear este array
            //En este caso, crea un objeto que siempre devuelve 0 en el método indexOf, para que el contenido de todas las pestañas sea visible
            public createCreatedTabItemsArray(id: string) {
                this[id] = [];
                this[id].indexOf = (index: number) => {
                    return 0;
                }
            }

            public actions: [rpsWindowAction];
            public isOpen: boolean = true;
            public closeCommand: rps.viewmodels.commands.CommandProperty;
            public showWindowCommand: rps.viewmodels.commands.CommandProperty;            

            constructor( @Inject(VM) public vm: rps.viewmodels.BaseVM, @Inject(TREE_LIST) public rpsTreeList: rpsTreeList) {

                this.closeCommand = new rps.viewmodels.commands.CommandProperty({
                    target: this,
                    command: this.close
                });
                this.showWindowCommand = new rps.viewmodels.commands.CommandProperty({
                    target: this,
                    command: this.showWindow
                });

                this.actions = [
                    {
                        target: this,
                        name: 'rps-item-detail-minimize',
                        method: this.showForm
                    }, {
                        target: this,
                        name: 'close',
                        method: this.close
                    }];
            }

            public get $root() {
                return rps.app;
            }

            public get resources() {
                return rps.app.resources;
            }

            public ngOnDestroy() {
            }

            close(): Promise<any> {
                //Ocultar el formulario
                this.rpsTreeList.closeItemDetail();
                return Promise.resolve<any>(this);
            }

            showWindow(): Promise<any> {
                this.rpsTreeList.showItemDetailWindow(this.vm);
                return Promise.resolve<any>(this);
            }

            showForm(): Promise<any> {
                this.rpsTreeList.showItemDetailForm(this.vm);
                return Promise.resolve<any>(this);
            }
        }

        var imports: Array<any>;
        if (isNarrow)
            imports = [rpsModule, rpsNarrowModule, FormsModule, CommonModule]
        else
            imports = [rpsModule, rpsDesktopModule, FormsModule, CommonModule]

        @NgModule({
            imports:imports,
            declarations: [
                VirtualComponent
            ]
        })
        class VirtualModule {
        }
        // a module for just this Type
        return VirtualModule;
    }

    getFormTemplate(): string {
        if (!rps.object.hasValue(this.formTemplate)) {
            var title: string;
            if (!rps.string.isNullOrEmpty(this.sourcesToolbarService.itemTitle))
                if (this.sourcesToolbarService.itemTitle.startsWith("vm."))
                    title = `<h5 class="rps-stack-panel-item">{{${this.sourcesToolbarService.itemTitle}.text}}</h5>`;
                else
                    title = `<h5 class="rps-stack-panel-item">${this.sourcesToolbarService.itemTitle}</h5>`;
            else
                title = "";
            var result: string = `
                <div class="items-control-detail rps-editor-container">
                    <rps-columns-manager rpsHorizontalSize="1">
                        <div class="rps-stack-panel">
                            ${title}  
                            <rps-button rpsTemplate="ICON"
                                        rpsLabel="{{resources.directives.CLOSE_DETAIL}}"
                                        rpsIcon="fa fa-times"
                                        [rpsModel]="closeCommand"
                                        class="rps-stack-panel-item-right">
                            </rps-button>
                            <rps-button rpsTemplate="ICON"
                                        rpsLabel="{{resources.directives.SHOW_DETAIL_WINDOW}}"
                                        rpsIcon="k-icon rps-item-detail-maximize"
                                        [rpsModel]="showWindowCommand"
                                        class="rps-stack-panel-item-right">
                            </rps-button>
                        </div>
            `;
            result = result + this.sourcesToolbarService.itemTemplate;
            result = result + "</rps-columns-manager>";
            result = result + "</div>";
            this.formTemplate = result;
        }
        return this.formTemplate;
    }

    getWindowTemplate(): string {
        if (!rps.object.hasValue(this.windowTemplate)) {
            var title: string;
            if (!rps.string.isNullOrEmpty(this.sourcesToolbarService.itemTitle))
                if (this.sourcesToolbarService.itemTitle.startsWith("vm."))
                    title = `[rpsTitle]="${this.sourcesToolbarService.itemTitle}.text"`;
                else
                    title = `rpsTitle="${this.sourcesToolbarService.itemTitle}"`;
            else
                title = "";
            var result: string = `
                <rps-window [rpsOpen]='isOpen' 
                            id="detailWindow"
                            [rpsActions]="actions" ${title}>
            `;
            result = result + this.sourcesToolbarService.itemTemplate;
            result = result + "</rps-window>";
            this.windowTemplate = result;
        }
        return this.windowTemplate;
    }

    closeItemDetail() {
        this.itemDetailFormOpened = false;

        if (this.itemDetailComponent) {
            this.itemDetailComponent.destroy();
            delete this.itemDetailComponent;
        }
        if (this.detailTarget) {
            //Eliminar la clase para dejar de identificarlo como seleccionado
            this.detailTarget.removeClass("rps-grid-detail-command-selected");
            delete this.detailTarget;
        }
    }
}
