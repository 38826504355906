import {Injectable, Component, ElementRef, AfterViewInit, OnDestroy,Optional} from '@angular/core';
import {rpsViewService} from './view';
import {NgClass} from '@angular/common';

@Injectable()
export class rpsContainerService {  
    private childrenContainers: number = 0;  
    //Propiedad que indica que es el header más interno que hay en el árbol, útil para los estilos
    private _isLastLevel: boolean = true;
    set isLastLevel(newValue: boolean) {
        if (this._isLastLevel != newValue) {
            this._isLastLevel = newValue;
            this.refreshContainer$.emit(this);
        }
    }
    get isLastLevel(): boolean {
        return this._isLastLevel;
    }


    headers: Array<rpsContainerHeader> = new Array<rpsContainerHeader>();
    headerAdded$: rps.services.IEventEmitter<rpsContainerHeader> = rps.app.eventManager.createEmitter<rpsContainerHeader>();;
    headerRemoved$: rps.services.IEventEmitter<rpsContainerHeader> = rps.app.eventManager.createEmitter<rpsContainerHeader>();
    refreshContainer$: rps.services.IEventEmitter<rpsContainerService> = rps.app.eventManager.createEmitter<rpsContainerService>();    

    constructor() {
    }

    addHeader(newHeader: rpsContainerHeader) {
        this.headers.push(newHeader);
        this.headerAdded$.emit(newHeader);
    };

    removeHeader(newHeader: rpsContainerHeader) {
        this.headers.splice(this.headers.indexOf(newHeader), 1);
        this.headerRemoved$.emit(newHeader);
    };

    refreshContainer() {
        this.refreshContainer$.emit(this);
    }

    addChildContainer() {
        this.childrenContainers = this.childrenContainers + 1;
        this.isLastLevel = false;
    }

    removeChildContainer() {
        this.childrenContainers = this.childrenContainers - 1;
        if (this.childrenContainers == 0)
            this.isLastLevel = true;
    }
}

@Component({
    selector: 'rps-container-content',
    template: `
    <div class="rps-div">
        <ng-content></ng-content>
    </div>
  `
})
export class rpsContainerContent {
    constructor(public containerService: rpsContainerService) {
    }
}

@Component({
    selector: 'rps-container',
    template: `
        <div class="rps-container" (window:resize)="onResize($event)">
            <div id="header"
                 class="rps-container-header"
                 [ngClass]="{'rps-last-container-header': containerService.isLastLevel}">
                <ng-content select="footer"></ng-content>
            </div>
            <div id="content" 
                 class="rps-container-content"
                 [ngClass]="{'rps-last-container-content': containerService.isLastLevel}">
                <ng-content></ng-content>
            </div>
        </div>
    `,
    providers: [rpsContainerService]
})
export class rpsContainer implements AfterViewInit,OnDestroy {
    private containerContentHost: rpsContainerContent;

    get element(): JQuery {
        return $(this.elementRef.nativeElement);
    } 

    constructor(private elementRef: ElementRef, private containerService: rpsContainerService, @Optional() containerContentHost: rpsContainerContent) {
        this.containerContentHost = containerContentHost;
        containerService.headerAdded$.subscribe(this.addHeader);
        containerService.headerRemoved$.subscribe(this.removeHeader);
        containerService.refreshContainer$.subscribe(this.refreshContainer);
    }

    ngAfterViewInit() {
        if (this.containerContentHost)
            this.containerContentHost.containerService.addChildContainer();
    }

    ngOnDestroy() {
        if (this.containerContentHost)
            this.containerContentHost.containerService.removeChildContainer();
    }

    onResize(event) {
        this.refreshContainer();
    }

    public addHeader = (containerHeader: rpsContainerHeader) => {
        this.element.find("#header").append(containerHeader.element);
        this.refreshContainer();
    }

    public removeHeader = (containerHeader: rpsContainerHeader) => {
        containerHeader.element.remove();
        this.refreshContainer();
    }

    refreshContainer = () => {
        var header: JQuery = this.element.find("#header");
        var content: JQuery = this.element.find("#content");
        if (this.containerService.isLastLevel)
            content.css("top", (header.height() + 5) + "px");
        else
            content.css("top", (header.height() + 1) + "px");
        //HACK:Al panel de errores, se le da un trato especial, ya que hay que tenerlo en cuenta para esquivarlo con los contenidos que tienen rps-div
        if (content.find(".rps-error-details-viewer-list").length == 1)
            content.css("padding-top", content.find(".rps-error-details-viewer-list").height() + "px");
        else
            content.css("padding-top", "0");
    }
}

@Component({
    selector: 'rps-container-header',
    template: `
        <div [hidden]="!visible">
            <ng-content></ng-content>
        </div>
    `
})
export class rpsContainerHeader implements AfterViewInit, OnDestroy {    
    public visible: boolean = true;

    get element(): JQuery {
        return $(this.elementRef.nativeElement);
    } 

    constructor(private elementRef: ElementRef, private containerService: rpsContainerService,@Optional() viewService?: rpsViewService) {
        //HACK: Se mantiene una propiedad con la visibilidad del rpsView, para evitar que se quede visible al navegar a un hijo, ya que se modifica el DOM a mano y no se oculta en cascada…
        if (viewService) {            
            this.visible = viewService.visible;
            viewService.visibleChanged$.subscribe((value: boolean) => {
                this.visible = value;
                setTimeout(() => {
                    this.containerService.refreshContainer();
                });                
            });
        }
    }

    ngAfterViewInit(){
        this.containerService.addHeader(this);
    }

    ngOnDestroy() {
        this.containerService.removeHeader(this);
    }
}