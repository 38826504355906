import { Component, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { rpsContainerService } from '../layouts/container';
@Component({
    selector: 'rps-error-details-viewer',
    template: `
        <div>
            <div *ngIf="rpsErrorDetails?.hasErrors">
                <ul class="rps-error-details-viewer-list">
                    <li *ngFor="let error of (rpsErrorDetails.toArray())" 
                        class="rps-layout-padding rps-error-details-viewer-list-item">
                        <i class="fa fa-times-circle rps-error-details-viewer-list-icon"></i>                            
                        <div *ngIf="(error.code)" class="rps-error-details-viewer-list-content">{{error.description}} ({{error.code}})</div>
                        <div *ngIf="!(error.code)" class="rps-error-details-viewer-list-content">{{error.description}}</div>
                    </li>
                </ul>
            </div>
        </div>
    `,
    inputs: ['rpsErrorDetails']
})
export class rpsErrorDetailsViewer implements OnChanges, OnDestroy {
    private errorsChangedSubscription: rps.services.ISubscription;
    rpsErrorDetails: rps.errors.ErrorDetails;

    constructor(private containerService: rpsContainerService) {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['rpsErrorDetails']) {
            if (this.errorsChangedSubscription)
                this.errorsChangedSubscription.unsubscribe();
            if (this.rpsErrorDetails)
                this.errorsChangedSubscription = this.rpsErrorDetails.errorsChanged.subscribe(this.errorsChanged);
        }
    }

    errorsChanged = () => {
        setTimeout(() => {
            this.containerService.refreshContainer();
        });
    }

    ngOnDestroy() {
        if (this.errorsChangedSubscription)
            this.errorsChangedSubscription.unsubscribe();
    }
}