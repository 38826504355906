import { Component, EventEmitter, ChangeDetectionStrategy} from '@angular/core';

@Component({
    selector: 'rps-toggle-panel',
    template: `
        <div class="rps-layout-padding">
            <div>        
                <div class="rps-toggle-panel-header" >
                    <h5 (click)="rpsCollapsed = !rpsCollapsed">
                        <i [class.fa-angle-right]="rpsCollapsed" 
                           [class.fa-angle-down]="!rpsCollapsed" 
                           class="fa fa-lg toggle-panel-icon" 
                           aria-hidden="true"></i> 
                            {{rpsTitle}}
                    </h5>
                </div>
                <div *ngIf="!rpsCollapsed">
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    `,
    styles: [`
        .toggle-panel-icon{
            width:15px;
        }
    `],
    inputs: ['rpsTitle','rpsCollapsed'],
    outputs: ['rpsToggle'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class rpsTogglePanel{
    rpsTitle: string;    
    rpsToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

    private _collapsed: boolean = false;
    public get rpsCollapsed(): boolean {
        return this._collapsed;
    }
    public set rpsCollapsed(value: boolean) {
        if (value != this._collapsed) {
            this._collapsed = value;
            this.rpsCollapsed = value;            
            this.rpsToggle.emit(this.rpsCollapsed);
        }
    }    

    constructor() { }
}

