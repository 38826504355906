import { Component, ElementRef, forwardRef, Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsEditor} from './editorBase'
import {rpsRowService} from '../layouts/row'
import { rpsControl } from '../controlBase'

@Component({
    selector: 'rps-amount-editor',
    template: `
        <div class="rps-editor" 
            [rpsSemanticState]="rpsModel?.semanticState" 
            [class.rps-disabled-editor]="disabled"
            [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label" 
                   [attr.for]="myId">
                {{rpsLabel}}
            </label>
            <div class="inputContainer">
               <input class="rps-editor-editor rps-number-editor-input rps-semantic-state" 
                        [attr.id]="myId" >
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsAmountEditor) }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsEditor.getComponentInputs()
})
export class rpsAmountEditor extends rpsEditor<number> {

    private currencySymbol: rps.data.CurrencySymbol;

    private kendoControl: kendo.ui.NumericTextBox;
    private kendoSourceHTML: string;

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    createTargetControl() {
        //Busca inputContainer (el div que contiene el input)
        var div = this.element.find(".inputContainer").first();

        //Se queda con una copia del HTML del input, por si necesita crearlo de nuevo (en caso de que cambien las opciones, por ejemplo)
        if (!this.kendoSourceHTML)
            this.kendoSourceHTML = div.html();

        //Si el control estaba creado, destruirlo y vaciarlo
        if (this.kendoControl) {
            this.kendoControl.destroy();
            div.empty();
            //Volver a crear un input para que pueda llamar al método de Telerik para crear el editor concreto
            div.append(this.kendoSourceHTML);
        }
        var format = this.getEditorFormat();
       
        //Crear el editor
        this.kendoControl = this.element.find("input").first().kendoNumericTextBox(
            {
                decimals: rps.number.getMaxDecimalPlaces(),
                format: format,
                change: () => { this.updateSourceValue(this.kendoControl.value()) },
                spin: () => { this.updateSourceValue(this.kendoControl.value()) },
                value: this.value,
                spinners: false
            }).data("kendoNumericTextBox");
        $(this.kendoControl.element).on("focus", function () {
            rps.utils.selectInputContent($(this), 1);
        }).blur(function () {
            rps.utils.selectInputContent($(this), 0);
        });
        $(this.kendoControl.element).on("input", function (e) {
            rps.utils.controlNumberLenght(this);
        });

        //Si estamos en modo edición de la grid, hay que introducirle el foco al editor, ya que significa que hemos entrado en modo edición de la celda
        if (rps.ui.grid.isInEditMode) {
            $(this.elementRef.nativeElement).find("input").focus();
            rps.ui.grid.isInEditMode = false;
        }
    }

    destroyTargetControl() {
        if (this.kendoControl) {
            this.kendoControl.destroy();
            this.kendoControl = null;

            var div = this.element.find(".inputContainer").first();
            if (div)
                div.empty();
        }
    }

    updateTargetValue(newValue: number) {
        this.kendoControl.value(newValue);
    }

    enableControl() {
        this.kendoControl.enable(true);
    }

    disableControl() {
        this.kendoControl.enable(false);
    }

    onChangeModel() {
        super.onChangeModel();
        if (this.rpsModel)
            this.setCurrencySymbol((<rps.viewmodels.properties.AmountProperty>this.rpsModel).currencySymbol);
        else
            this.setCurrencySymbol(null);
    }

    rpsModelPropertyChanged(e: rps.viewmodels.properties.VMPropertyChange) {
        super.rpsModelPropertyChanged(e);

        if (e.propertyName == "currencySymbol")
            this.setCurrencySymbol((<rps.viewmodels.properties.AmountProperty>this.rpsModel).currencySymbol);
    }

    setCurrencySymbol(newValue: rps.data.CurrencySymbol) {
        if (newValue != this.currencySymbol) {          
            this.currencySymbol = newValue;            

            if (this.contentInitialized) {
                if (!this.kendoControl)
                    this.fillTemplate();
                else {
                    //En vez de recrear el control, se establece el formato y se refresca
                    this.kendoControl.setOptions({ format: this.getEditorFormat() });
                    (<any>this.kendoControl)._update(this.kendoControl.value())
                }
            }
        }
    }

    private getEditorFormat(): string {
        if (!rps.object.isNullOrUndefined(this.currencySymbol))
            return rps.utils.getDecimalFormat({ suffix: this.currencySymbol.Symbol });
        else
            return rps.utils.getDecimalFormat();
    }

    //Método que invoca la grid, para forzar que el editor recoja el foco
    setFocusAfterInit() {
        rps.ui.grid.isInEditMode = true;
    }
}

