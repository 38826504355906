import {Injectable} from '@angular/core';

@Injectable()
export class rpsAppSettings implements rps.services.IAppSettings {

    private _rpsAPIAddress: string;
    public get rpsAPIAddress(): string { return this._rpsAPIAddress; }

    public get APP_VERSION(): string { return '0.2'; }

    public configure(): void {
        rps.app.appSettings = this;

        var origin: string;
        if (window.location.origin)
            origin = window.location.origin;
        else {
            //Hay veces que en IE el origin está vacío; hay que montarlo con otras propiedades
            origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
        }

        //Se coge el API Address como el punto de acceso del index.html
        this._rpsAPIAddress = origin + window.location.pathname.substr(0, window.location.pathname.lastIndexOf('/') + 1);
    }
}