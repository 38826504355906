import {
    Component, ElementRef, OnDestroy, AfterContentInit, EventEmitter,
    ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { errorDetailValidatorService } from '../utils/errorDetailValidator';

@Component({
    selector: 'rps-tab-control',
    template: `
        <div class="rps-layout-padding">
            <div id="tabstrip">
            </div>
            <ng-content></ng-content>
        </div>
    `,
    inputs: ['rpsActiveItem'],
    outputs: ['rpsActiveItemChanged'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class rpsTabControl implements AfterContentInit,OnDestroy {    
    private kendoTabStrip: kendo.ui.TabStrip;
    private contentInitialized: boolean = false;
    private get element(): JQuery {
        return $(this.elementRef.nativeElement);
    }
    private tabItems: Array<rpsTabItem> = new Array<rpsTabItem>();

    private _activeItem: string = null;
    public get rpsActiveItem(): string {
        return this._activeItem;
    }
    public set rpsActiveItem(value: string) {
        if (value != this._activeItem) {
            this._activeItem = value;
            this.rpsActiveItem = value;
            this.rpsActiveItemChanged.emit(this.rpsActiveItem);
        }
    }

    rpsActiveItemChanged: EventEmitter<string> = new EventEmitter<string>();

    constructor(private elementRef: ElementRef) {
    }

    ngOnDestroy() {
        if (this.kendoTabStrip)
            this.kendoTabStrip.destroy();
        this.element.find("#tabstrip").first().empty();
    }

    ngAfterContentInit() {        
        this.contentInitialized = true;
        this.createWidget();
    }

    addItem(tabItem: rpsTabItem) {
        this.tabItems.push(tabItem);
    }

    showTabItem(tabItem: rpsTabItem) {
        if (this.kendoTabStrip) {
            var activeDataItem;
            var dataSource: Array<{ Title: string, ID: string }> = new Array<{ Title: string, ID: string }>();
            Enumerable.From<rpsTabItem>(this.tabItems).Where(tabItem => tabItem.rpsVisible).forEach((tabItem: rpsTabItem) => {
                var newDataItem = { Title: tabItem.rpsTitle, imageUrl: tabItem.getImageURL(), ID: tabItem.rpsItemID };
                if (tabItem.isActive)
                    activeDataItem = newDataItem;
                dataSource.push(newDataItem);
            });
            this.kendoTabStrip.setDataSource(dataSource);

            this.kendoTabStrip.select(dataSource.indexOf(activeDataItem));
        }
    }

    hideTabItem(tabItem: rpsTabItem) {        
        if (this.kendoTabStrip) {
            var activeDataItem;
            var dataSource: Array<{ Title: string, ID: string }> = new Array<{ Title: string, ID: string }>();
            Enumerable.From<rpsTabItem>(this.tabItems).Where(tabItem => tabItem.rpsVisible).forEach((tabItem: rpsTabItem) => {
                var newDataItem = { Title: tabItem.rpsTitle, imageUrl: tabItem.getImageURL(), ID: tabItem.rpsItemID };
                if (tabItem.isActive)
                    activeDataItem = newDataItem;
                dataSource.push(newDataItem);
            });
            this.kendoTabStrip.setDataSource(dataSource);

            if (activeDataItem)
                this.kendoTabStrip.select(dataSource.indexOf(activeDataItem));
            else if (dataSource.length > 0)
                this.kendoTabStrip.select(0);
            else
                Enumerable.From<rpsTabItem>(this.tabItems).Where(tabItem => tabItem.isActive).forEach((tabItem: rpsTabItem) => { tabItem.isActive = false;});
        }
    }

    setTabItemTitle(oldValue: string, newValue: string) {    
        this.refreshTitles();
    }

    refresTabItemTitle(value: string) {
        this.refreshTitles();
    }

    private refreshTitles() {
        if (this.kendoTabStrip) {
            var activeDataItem;
            var dataSource: Array<{ Title: string, ID: string }> = new Array<{ Title: string, ID: string }>();
            Enumerable.From<rpsTabItem>(this.tabItems).Where(tabItem => tabItem.rpsVisible).forEach((tabItem: rpsTabItem) => {
                var newDataItem = { Title: tabItem.rpsTitle, imageUrl: tabItem.getImageURL(), ID: tabItem.rpsItemID };
                if (tabItem.isActive)
                    activeDataItem = newDataItem;
                dataSource.push(newDataItem);
            });
            this.kendoTabStrip.setDataSource(dataSource);

            this.kendoTabStrip.select(dataSource.indexOf(activeDataItem));
        }
    }

    private createWidget() {
        var dataSource: Array<{ Title: string, imageUrl: string, ID: string }> = new Array<{ Title: string, imageUrl: string, ID: string }>();
        Enumerable.From<rpsTabItem>(this.tabItems).Where(tabItem => tabItem.rpsVisible).forEach((tabItem: rpsTabItem) => {            
            dataSource.push({ Title: tabItem.rpsTitle, imageUrl: tabItem.getImageURL(), ID: tabItem.rpsItemID });
        });
        this.kendoTabStrip = this.element.find("#tabstrip").kendoTabStrip({
            animation: false,
            dataTextField: "Title",
            dataImageUrlField: "imageUrl",
            dataSource: dataSource,
            select: this.onSelect,           
        }).data("kendoTabStrip");

        if (this.rpsActiveItem && Enumerable.From<{ Title: string, imageUrl: string, ID: string }>(dataSource).Any(dsi => dsi.ID == this.rpsActiveItem))
            this.kendoTabStrip.select(dataSource.indexOf(Enumerable.From<{ Title: string, imageUrl: string, ID: string }>(dataSource).First(dsi => dsi.ID == this.rpsActiveItem)));
        else if (dataSource.length > 0)
            this.kendoTabStrip.select(0);
    }

    private onSelect = (e: kendo.ui.TabStripSelectEvent) => {
        Enumerable.From<rpsTabItem>(this.tabItems).Where(tabItem => tabItem.isActive && tabItem.rpsTitle != e.item.textContent).forEach((tabItem: rpsTabItem) => {
            tabItem.isActive = false;
        });
        var activeItem = Enumerable.From<rpsTabItem>(this.tabItems).First(tabItem => tabItem.rpsTitle == e.item.textContent);
        activeItem.isActive = true;
        this.rpsActiveItem = activeItem.rpsItemID;
    }
}

@Component({
    selector: 'rps-tab-item',
    template: `
        <div *ngIf="isActive">
          <ng-content></ng-content>
        </div>
    `,
    inputs: ['rpsVisible', 'rpsTitle','rpsItemID'],
    providers: [errorDetailValidatorService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class rpsTabItem implements OnDestroy{
    private errorsChangedSubscription: rps.services.ISubscription;

    private _rpsVisible: boolean = true;
    get rpsVisible(): boolean {
        return this._rpsVisible;
    }
    set rpsVisible(newValue: boolean) {
        if (this._rpsVisible != newValue) {
            this._rpsVisible = newValue;
            if(this.rpsVisible)
                this.tabControl.showTabItem(this);
            else
                this.tabControl.hideTabItem(this);
        }
    }

    private _rpsTitle: string;
    get rpsTitle(): string {
        return this._rpsTitle;
    }
    set rpsTitle(newValue: string) {
        if (this._rpsTitle != newValue) {
            var oldValue: string = this._rpsTitle;
            this._rpsTitle = newValue;
            this.tabControl.setTabItemTitle(oldValue,newValue);
        }
    }

    public rpsItemID: string = rps.guid.newGuid();

    private _hasErrors: boolean = false;
    get hasErrors(): boolean {
        return this._hasErrors;
    }
    set hasErrors(value: boolean) {
        if (this._hasErrors != value) {
            this._hasErrors = value;
            this.tabControl.refresTabItemTitle(this.rpsTitle);
        }
    }    
    private _isActive: boolean = false;
    get isActive(): boolean {
        return this._isActive;
    }
    set isActive(value: boolean) {
        if (this._isActive != value) {
            this._isActive = value;
            this._cdr.markForCheck();
        }
    }

    constructor(private tabControl: rpsTabControl, private errorDetailValidatorService: errorDetailValidatorService, protected _cdr: ChangeDetectorRef) {
        tabControl.addItem(this);
        this.hasErrors = errorDetailValidatorService.hasErrors();

        this.errorsChangedSubscription= errorDetailValidatorService.errorsChanged$.subscribe(() => {
            this.hasErrors = errorDetailValidatorService.hasErrors();
        });
    }

    ngOnDestroy() {
        this.errorsChangedSubscription.unsubscribe();
    }

    public getImageURL() {
        if (this.hasErrors)
            return `./assets/img/exclamationCircle.png`;
        else
            return undefined;
    }
}
