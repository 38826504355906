import { Component, OnDestroy,AfterViewInit } from '@angular/core';
import {rpsContainerService} from './container';

@Component({
    selector: 'rps-breadcrumb-item',
    template: `
        <a  [routerLink]="rpsState?.routerLink" [queryParams]="rpsState?.queryParams">
            {{rpsState?.relatedVM?.title?.text}}
        </a>
    `,
    inputs: ['rpsState']
})
export class rpsBreadcrumbItem implements AfterViewInit ,OnDestroy{
    private titlePropertyChanged: rps.services.ISubscription;

    rpsState: rps.services.StateDescriptor;

    constructor(private containerService: rpsContainerService) {

    }

    ngAfterViewInit() {
        this.containerService.refreshContainer();
        rps.app.stateManager.refreshPageTitle();
        if (this.rpsState &&
            this.rpsState.relatedVM &&
            this.rpsState.relatedVM.title) {
            this.titlePropertyChanged = this.rpsState.relatedVM.title.propertyChanged.subscribe((value: rps.viewmodels.properties.VMPropertyChange) => {
                if (value.propertyName == "text") {
                    this.containerService.refreshContainer();
                    rps.app.stateManager.refreshPageTitle();
                }
            });
        }
    }

    ngOnDestroy() {
        if (this.titlePropertyChanged)
            this.titlePropertyChanged.unsubscribe();
    }
}

@Component({
    selector: 'rps-breadcrumb',
    template: `
        <div [hidden]="!rpsDataSource || rpsDataSource.length == 0" >
            <ol class="breadcrumb rps-breadcrumb" >
                <li *ngFor="let state of (rpsDataSource)">
                    <rps-breadcrumb-item [rpsState]="state" > </rps-breadcrumb-item>
                </li>
            </ol>
        </div>
    `,
    inputs: ['rpsDataSource']
})
export class rpsBreadcrumb  {
    rpsDataSource: rps.services.StateDescriptor[];
}

