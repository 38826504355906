import { FormsModule }   from '@angular/forms';
import { NgModule }       from '@angular/core';
import { RouterModule}       from '@angular/router';
import {CommonModule} from '@angular/common';

import {rpsTextBox} from './editors/textBox';
import {rpsReadOnlyTextBox} from './readOnlyEditors/readOnlyTextBox';
import {rpsLongTextBox} from './editors/longTextBox';
import {rpsLongTextBoxLittle} from './editors/longTextBoxLittle';
import {rpsReadOnlyLongTextBox} from './readOnlyEditors/readOnlyLongTextBox';
import {rpsReadOnlyLongTextBoxLittle} from './readOnlyEditors/readOnlyLongTextBoxLittle';
import {rpsPasswordBox} from './editors/passwordBox';
import {rpsButton} from './editors/button';
import {rpsView, rpsDialogView} from './layouts/view';
import {rpsTabControl, rpsTabItem} from './layouts/tabControl';
import {rpsRowsGroup} from './layouts/rowsGroup';
import {rpsRow} from './layouts/row';
import {rpsColumnsManager,rpsColumns} from './layouts/columns';
import {rpsOffset} from './layouts/offset';
import {rpsHorizontalSplitter, rpsHorizontalSplitterItem} from './layouts/horizontalSplitter';
import { rpsContainer, rpsContainerHeader, rpsContainerContent } from './layouts/container';
import { rpsErrorDetailsViewer } from './controls/errorDetailsViewer';
import {rpsCheckBox} from './editors/checkBox';
import {rpsReadOnlyCheckBox} from './readOnlyEditors/readOnlyCheckBox';
import {rpsRadioButton} from './editors/radioButton';
import {rpsReadOnlyRadioButton} from './readOnlyEditors/readOnlyRadioButton';
import {rpsDatePicker} from './editors/datePicker';
import {rpsReadOnlyDatePicker} from './readOnlyEditors/readOnlyDatePicker';
import {rpsLookup,rpsLookupFooter} from './editors/lookup';
import {rpsReadOnlyLookup} from './readOnlyEditors/readOnlyLookup';
import {rpsLabel} from './readOnlyEditors/label';
import {rpsDecimalEditor} from './editors/decimalEditor';
import {rpsReadOnlyDecimalEditor} from './readOnlyEditors/readOnlyDecimalEditor';
import {rpsIntegerEditor} from './editors/integerEditor';
import {rpsReadOnlyIntegerEditor} from './readOnlyEditors/readOnlyIntegerEditor';
import {rpsPercentageEditor} from './editors/percentageEditor';
import {rpsReadOnlyPercentageEditor} from './readOnlyEditors/readOnlyPercentageEditor';
import {rpsAmountEditor} from './editors/amountEditor';
import {rpsReadOnlyAmountEditor} from './readOnlyEditors/readOnlyAmountEditor';
import {rpsPriceEditor} from './editors/priceEditor';
import {rpsReadOnlyPriceEditor} from './readOnlyEditors/readOnlyPriceEditor';
import {rpsQuantityEditor} from './editors/quantityEditor';
import {rpsReadOnlyQuantityEditor} from './readOnlyEditors/readOnlyQuantityEditor';
import {rpsDurationEditor} from './editors/durationEditor';
import {rpsReadOnlyDurationEditor} from './readOnlyEditors/readOnlyDurationEditor';
import {rpsEnumEditor} from './editors/enumEditor';
import {rpsReadOnlyEnumEditor} from './readOnlyEditors/readOnlyEnumEditor';
import {rpsEmailEditor} from './editors/emailEditor';
import {rpsReadOnlyEmailEditor} from './readOnlyEditors/readOnlyEmailEditor';
import {rpsUrlEditor} from './editors/urlEditor';
import {rpsReadOnlyUrlEditor} from './readOnlyEditors/readOnlyUrlEditor';
import {rpsTimePicker} from './editors/timePicker';
import {rpsReadOnlyTimePicker} from './readOnlyEditors/readOnlyTimePicker';
import { rpsMultiEnum } from './editors/multiEnum';
import { rpsMultiComboBox } from './editors/multiComboBox';
import {rpsMultiLookup, rpsMultiLookupFooter} from './editors/multiLookup';
import {rpsSearchBox} from './editors/searchBox';
import {rpsComboBox} from './editors/comboBox';
import {rpsReadOnlyComboBox} from './readOnlyEditors/readOnlyComboBox';
import {rpsImageEditor}  from './editors/imageEditor';
import {rpsReadOnlyImageEditor}  from './readOnlyEditors/readOnlyImageEditor';
import { rpsDataComboBox, rpsDataComboBoxFooter } from './editors/dataComboBox';
import { rpsSelectEditor } from './editors/selectEditor';
import {rpsReadOnlyDataComboBox} from './readOnlyEditors/readOnlyDataComboBox';
import {rpsColorEditor} from './editors/colorEditor';
import {rpsReadOnlyColorEditor} from './readOnlyEditors/readOnlyColorEditor';
import {rpsToggleButton,iconToggleButton, textToggleButton, textWithIconToggleButton} from './editors/toggleButton';
import { rpsDescriptorView, rpsDescriptorViewItem, rpsNarrowDescriptorView, rpsNarrowDescriptorViewItem} from './itemsControls/descriptorView';
import {rpsAttachedDocumentView, rpsAttachedDocumentViewItem} from './itemsControls/attachedDocumentView';
import { rpsTemplatedCardView} from './itemsControls/templatedCardView';
import {rpsChart} from './itemsControls/chart';
import {rpsGantt} from './itemsControls/gantt';
import { rpsScheduler } from './itemsControls/scheduler';
import { rpsPivotGrid } from './itemsControls/pivotGrid';
import {rpsRecursiveTreeView, rpsRecursiveTreeViewItem} from './itemsControls/recursiveTreeView';
import {rpsTreeView,rpsTreeViewItem} from './itemsControls/treeView';
import {rpsCalendarView} from './itemsControls/calendarView';
import {rpsPanel} from './layouts/panel';
import {rpsBox} from './layouts/box';
import {rpsArrayToolbar} from './itemsControls/arrayToolbar';
import { rpsQuerySourceToolbar, rpsNarrowQuerySourceToolbar} from './itemsControls/querySourceToolbar';
import {rpsHierarchicalQuerySourceToolbar} from './itemsControls/hierarchicalQuerySourceToolbar';
import {rpsQuerySourceTools} from './controls/querySourceTools';
import {rpsEntitySourceTools} from './controls/entitySourceTools';
import {rpsHierarchicalQuerySourceTools} from './controls/hierarchicalQuerySourceTools';
import {rpsItemsControlToolbarItem} from './itemsControls/itemsControlToolbarItem';
import {rpsToolbar, rpsToolbarButton, rpsCanExecute} from './layouts/toolbar';
import {rpsWindow} from './layouts/window';
import {rpsWizard, rpsWizardStep} from './layouts/wizard';
import {rpsBreadcrumb, rpsBreadcrumbItem} from './layouts/breadcrumb';
import {rpsViewView,rpsViewSelector,rpsViewSelectorFooter}  from './itemsControls/viewView';
import {rpsGrid}  from './itemsControls/grid';
import {rpsTreeList}  from './itemsControls/treeList';
import {rpsPdf}  from './viewers/pdfViewer';
import {rpsDescriptor} from './templates/descriptor';
import {rpsBusyIndicator} from './layouts/busyIndicator';
import {rpsTogglePanel} from './layouts/togglePanel';
import {rpsFileUploader} from './editors/fileUploader';
import {rpsCustomEditor} from './layouts/customEditor';
import {rpsIcon} from './readOnlyEditors/icon';
import {rpsDocuments} from './readOnlyEditors/documents'
import { rpsFilters } from './controls/filters';
import { rpsProgressBar } from './controls/progressBar';
import {rpsReadOnlyEntityLink} from './readOnlyEditors/readOnlyEntityLink';
import {rpsReadOnlyFormattedNumber} from './readOnlyEditors/readOnlyFormattedNumber';
import {rpsReadOnlyAddressValue} from './readOnlyEditors/readOnlyAddressValue';
import {rpsReadOnlyAddress} from './readOnlyEditors/readOnlyAddress';
import {ProcessResultItem} from '../services/processResultManager'
import {rpsDatePickerServerColumn} from './gridColumns/datePickerServerColumn';
import {rpsLookupServerColumn} from './gridColumns/lookupServerColumn';
import {rpsFormattedNumberServerColumn} from './gridColumns/formattedNumberServerColumn';
import {rpsCheckBoxServerColumn} from './gridColumns/checkBoxServerColumn';
import {rpsIconServerColumn} from './gridColumns/iconServerColumn';
import {rpsDocumentsServerColumn} from './gridColumns/documentsServerColumn';
import {rpsAddressServerColumn} from './gridColumns/addressServerColumn';
import {rpsImageEditorServerColumn} from './gridColumns/imageEditorServerColumn';
import {rpsTimePickerServerColumn} from './gridColumns/timePickerServerColumn';

import {errorDetailValidator} from './utils/errorDetailValidator'
import {rpsSemanticState} from './utils/semanticState'
import { rpsImageSize } from './utils/imageSize'
import { rpsLink } from './controls/link'
import {iconButton, textButton, textWithIconButton, telerikIconButton} from './editors/button'

import { Empty } from '../components/components'

import { TimeAgoPipe, CalendarPipe } from '../pipes/moment';

const rpsInternalComponents: any[] = [errorDetailValidator, iconButton,
    textButton, textWithIconButton, telerikIconButton, iconToggleButton, textToggleButton,
    textWithIconToggleButton, rpsLookupFooter, rpsMultiLookupFooter,
    rpsRecursiveTreeViewItem, rpsTreeViewItem, rpsDataComboBoxFooter,
    Empty, rpsDatePickerServerColumn, rpsLookupServerColumn,
    rpsFormattedNumberServerColumn, rpsCheckBoxServerColumn,
    rpsIconServerColumn, rpsDocumentsServerColumn, rpsAddressServerColumn,
    rpsImageEditorServerColumn, rpsTimePickerServerColumn,
    ProcessResultItem, rpsAttachedDocumentViewItem
];

const rpsInternalDesktopComponents: any[] = [
    rpsDescriptorViewItem,
    rpsViewSelector, rpsViewSelectorFooter,
    rpsNarrowDescriptorViewItem
];

//TODO: El componente rpsNarrowDescriptorViewItem, está añadido en la lista de componentes de desktop, para que funcione el createComponent
const rpsInternalNarrowComponents: any[] = [];

const rpsExportedDirectives: any[] = [
    rpsColumns, rpsOffset, rpsCanExecute, rpsImageSize, rpsSemanticState
];

const rpsExportedComponents: any[] = [rpsTextBox, rpsReadOnlyTextBox, rpsLongTextBox, rpsReadOnlyLongTextBox,
    rpsPasswordBox, rpsButton, rpsView, rpsTabControl, rpsTabItem, rpsRowsGroup, rpsRow, 
    rpsContainer, rpsContainerHeader, rpsContainerContent, rpsCheckBox, rpsReadOnlyCheckBox,
    rpsRadioButton, rpsReadOnlyRadioButton, rpsHorizontalSplitter, rpsHorizontalSplitterItem,
    rpsDatePicker, rpsReadOnlyDatePicker, rpsLookup,  rpsReadOnlyLookup, rpsIntegerEditor,
    rpsReadOnlyIntegerEditor, rpsPercentageEditor, rpsReadOnlyPercentageEditor,
    rpsAmountEditor, rpsReadOnlyAmountEditor, rpsPriceEditor, rpsReadOnlyPriceEditor, rpsQuantityEditor,
    rpsReadOnlyQuantityEditor, rpsDurationEditor, rpsReadOnlyDurationEditor,
    rpsEnumEditor, rpsReadOnlyEnumEditor, rpsLongTextBoxLittle, rpsReadOnlyLongTextBoxLittle,
    rpsAttachedDocumentView, rpsSearchBox, rpsComboBox, rpsReadOnlyComboBox,
    rpsImageEditor, rpsReadOnlyImageEditor, rpsDataComboBox, rpsSelectEditor,
    rpsReadOnlyDataComboBox, rpsDecimalEditor, rpsReadOnlyDecimalEditor,
    rpsPanel, rpsBox, rpsItemsControlToolbarItem, rpsHierarchicalQuerySourceToolbar,
    rpsQuerySourceTools,rpsEntitySourceTools, rpsHierarchicalQuerySourceTools, rpsArrayToolbar, rpsBreadcrumbItem,
    rpsBreadcrumb, rpsToolbar, rpsToolbarButton, rpsGrid, rpsWindow, rpsLabel,
    rpsDialogView, rpsWizard, rpsWizardStep, rpsEmailEditor, rpsReadOnlyEmailEditor,
    rpsUrlEditor, rpsReadOnlyUrlEditor, rpsTimePicker, rpsReadOnlyTimePicker,
    rpsColorEditor, rpsReadOnlyColorEditor, rpsToggleButton, rpsScheduler, rpsRecursiveTreeView,
    rpsTreeView, rpsCalendarView, rpsTemplatedCardView,rpsPivotGrid,
    rpsChart, rpsMultiEnum, rpsMultiComboBox, rpsMultiLookup, rpsPdf, rpsDescriptor,
    rpsBusyIndicator, rpsGantt,
    rpsTogglePanel, rpsTreeList, rpsFileUploader, rpsCustomEditor, rpsIcon,
    rpsDocuments, rpsFilters, rpsReadOnlyEntityLink, rpsErrorDetailsViewer, rpsProgressBar,
    rpsReadOnlyFormattedNumber, rpsReadOnlyAddressValue, rpsReadOnlyAddress, rpsLink, rpsColumnsManager];

const rpsExportedDesktopComponents: any[] = [
    rpsDescriptorView, rpsQuerySourceToolbar, rpsViewView];

const rpsExportedNarrowComponents: any[] = [
    rpsNarrowDescriptorView, rpsNarrowQuerySourceToolbar];

const rpsExportedPipes: any[] = [TimeAgoPipe, CalendarPipe];

@NgModule({
    declarations: rpsExportedDirectives
        .concat(rpsInternalComponents)
        .concat(rpsExportedComponents)        
        .concat(rpsExportedPipes),
    imports: [FormsModule, CommonModule, RouterModule],
    exports: rpsExportedDirectives.concat(rpsExportedComponents)
        .concat(rpsExportedPipes),
    entryComponents: rpsExportedComponents
        .concat(rpsInternalComponents)
})
export class rpsModule { }

@NgModule({
    declarations: rpsInternalDesktopComponents
        .concat(rpsExportedDesktopComponents),
    imports: [FormsModule, CommonModule, RouterModule, rpsModule],
    exports: rpsExportedDesktopComponents,
    entryComponents: rpsExportedDesktopComponents
        .concat(rpsInternalDesktopComponents)
})
export class rpsDesktopModule { }

@NgModule({
    declarations: rpsInternalNarrowComponents
        .concat(rpsExportedNarrowComponents),
    imports: [FormsModule, CommonModule, RouterModule, rpsModule],
    exports: rpsExportedNarrowComponents,
    entryComponents: rpsExportedNarrowComponents
        .concat(rpsInternalNarrowComponents)
})
export class rpsNarrowModule { }
