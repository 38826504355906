//** Name: Settings
//** State: html5.Settings - /Settings?_state - SettingsView.html

module rps.HTML5.viewmodels.Settings {

export class SettingsViewModel extends rps.viewmodels.BaseVM{
	
    static fullTypeName: string = "rps.HTML5.viewmodels.Settings.SettingsViewModel";

	relatedState: string = "html5.Settings";
	static relatedState: string = "html5.Settings";

	
	initialize(parameters: rps.IParams): Promise<SettingsViewModel> {
		return super.initialize(parameters).then(() => {
			return Promise.all([
					this.Languages.initialize(),
					this.SessionCompanies.initialize(),
					this.SessionSites.initialize(),
					this.UserCompanies.initialize(),
					this.UserSites.initialize(),
					this.CurrentSessionData.initialize(),
					this.CurrentUserData.initialize(),
					this.SessionInvoicingPoints.initialize(),
				]).then(() => {
				return this;
			});
		});
	}

	



	configureVMProperties(){	
		super.configureVMProperties();	
		this.UserLanguage = new rps.viewmodels.properties.LookupProperty({entityName:"General/Language" , target: this,initialValue: null,allowAddNew:true,hasAnyEntityView:true,allowNavigate:true});
		this.Languages = new rps.data.sources.LookupSource({queryName:"General/Language",hasColumnsToRender: true,hasDescriptorField: true});
		this.ApplySessionData = new rps.viewmodels.commands.CommandProperty({target: this, command: this.ApplySessionDataExecute, canExecute: this.ApplySessionDataCanExecute});
		this.SaveUserData = new rps.viewmodels.commands.CommandProperty({target: this, command: this.SaveUserDataExecute, canExecute: this.SaveUserDataCanExecute});
		this.SessionCompanies = new rps.data.sources.LookupSource({queryName:"General/SessionUserCompanies",hasColumnsToRender: true,hasDescriptorField: true});
		this.UserCompany = new rps.viewmodels.properties.LookupProperty({entityName:"General/Company" , target: this,initialValue: null,allowAddNew:true,hasAnyEntityView:true,allowNavigate:true});
		this.UserSite = new rps.viewmodels.properties.LookupProperty({entityName:"General/Site" , target: this,initialValue: null,allowAddNew:true,hasAnyEntityView:true,allowNavigate:true});
		this.UserImage = new rps.viewmodels.properties.ImageProperty({ target: this,initialValue: null});
		this.UserName = new rps.viewmodels.properties.VMProperty<string>({ target: this,initialValue: null});
		this.SessionCompany = new rps.viewmodels.properties.LookupProperty({entityName:"General/Company" , target: this,initialValue: null,allowAddNew:true,hasAnyEntityView:true,allowNavigate:true});
		this.SessionSite = new rps.viewmodels.properties.LookupProperty({entityName:"General/Site" , target: this,initialValue: null,allowAddNew:true,hasAnyEntityView:true,allowNavigate:true});
		this.SessionLanguage = new rps.viewmodels.properties.LookupProperty({entityName:"General/Language" , target: this,initialValue: null,allowAddNew:true,hasAnyEntityView:true,allowNavigate:true});
		this.SessionSites = new rps.data.sources.LookupSource({queryName:"General/SessionUserSites",queryParams: new rps.data.parameters.QueryParams([
				new rps.data.parameters.QueryParam("CodCompany", this.SessionCompany,true,true),]),hasColumnsToRender: true,hasDescriptorField: true});
		this.UserCompanies = new rps.data.sources.LookupSource({queryName:"General/SessionUserCompanies",hasColumnsToRender: true,hasDescriptorField: true});
		this.UserSites = new rps.data.sources.LookupSource({queryName:"General/SessionUserSites",queryParams: new rps.data.parameters.QueryParams([
				new rps.data.parameters.QueryParam("CodCompany", this.UserCompany,true,true),]),hasColumnsToRender: true,hasDescriptorField: true});
		this.CurrentUserData = new rps.data.sources.QueryMethod({queryName:"Security/CurrentUserData", executionPolicy: rps.queryExecutionPolicy.Manual, inputParams: new rps.data.parameters.QueryParams([]), outputParams: new rps.data.parameters.QueryParams([
				new rps.data.parameters.QueryParam("CodUser", null),
				new rps.data.parameters.QueryParam("Description", this.UserName),
				new rps.data.parameters.QueryParam("Image", this.UserImage),
				new rps.data.parameters.QueryParam("Language", this.UserLanguage),
				new rps.data.parameters.QueryParam("Company", this.UserCompany),
				new rps.data.parameters.QueryParam("Site", this.UserSite)])});
		this.UserAllSites = new rps.viewmodels.properties.VMProperty<boolean>({ target: this,initialValue: false});
		this.SessionAllSitesProperty = new rps.viewmodels.properties.VMProperty<boolean>({ target: this,initialValue: false});
		this.addRule("UserSitesEnabled")
			.when().equals(this.UserAllSites,true)
			.then().lockField(this.UserSite).cleanValue(this.UserSite)		
			.else().unlockField(this.UserSite);

		this.addRule("SessionSitesEnabled")
			.when().equals(this.SessionAllSitesProperty,true)
			.then().lockField(this.SessionSite).cleanValue(this.SessionSite)		
			.else().unlockField(this.SessionSite);

		this.PreviousPassword = new rps.viewmodels.properties.VMProperty<string>({ target: this,initialValue: null});
		this.NewPassword = new rps.viewmodels.properties.VMProperty<string>({ target: this,initialValue: null});
		this.ConfirmPassword = new rps.viewmodels.properties.VMProperty<string>({ target: this,initialValue: null});
		this.ChangePasswordCommandOk = new rps.viewmodels.commands.CommandProperty({target: this, command: this.ChangePasswordCommand_ok, canExecute: this.ChangePasswordCommand_allowOk});
			this.ChangePasswordCommand = new rps.viewmodels.commands.ModalNavigationCommandProperty({target: this, state:"ChangePasswordDialog", vm: this, resolve: this.ChangePasswordCommandExecute, canExecute: this.ChangePasswordCommandCanExecute, okCommand: this.ChangePasswordCommandOk});
		this.SessionInvoicingPoint = new rps.viewmodels.properties.LookupProperty({entityName:"General/InvoicingPoint" , target: this,initialValue: null,allowAddNew:true,hasAnyEntityView:true,allowNavigate:true});
		this.CurrentSessionData = new rps.data.sources.QueryMethod({queryName:"Security/CurrentSessionData", executionPolicy: rps.queryExecutionPolicy.Manual, inputParams: new rps.data.parameters.QueryParams([]), outputParams: new rps.data.parameters.QueryParams([
				new rps.data.parameters.QueryParam("SessionCompany", this.SessionCompany),
				new rps.data.parameters.QueryParam("SessionSite", this.SessionSite),
				new rps.data.parameters.QueryParam("SessionLanguage", this.SessionLanguage),
				new rps.data.parameters.QueryParam("SessionInvoicingPoint", this.SessionInvoicingPoint)])});
		this.SessionInvoicingPoints = new rps.data.sources.LookupSource({queryName:"General/InvoicingPoint",queryParams: new rps.data.parameters.QueryParams([
				new rps.data.parameters.QueryParam("CodCompany", this.SessionCompany,true,true),]),hasColumnsToRender: true,hasDescriptorField: true});
		this.InvoicingPointIsVisible = new rps.viewmodels.properties.VMProperty<boolean>({ target: this,initialValue: false});

		//Creación de las condiciones de filtros de las listas de mi tipo
		
		//Configuración de querysources con custom card view
	}

	
 
 	//Declaración de las propiedades
	public UserLanguage: rps.viewmodels.properties.LookupProperty;
	public Languages: rps.data.sources.LookupSource;
	public SessionCompanies: rps.data.sources.LookupSource;
	public UserCompany: rps.viewmodels.properties.LookupProperty;
	public UserSite: rps.viewmodels.properties.LookupProperty;
	public UserImage: rps.viewmodels.properties.ImageProperty;
	public UserName: rps.viewmodels.properties.VMProperty<string>;
	public SessionCompany: rps.viewmodels.properties.LookupProperty;
	public SessionSite: rps.viewmodels.properties.LookupProperty;
	public SessionLanguage: rps.viewmodels.properties.LookupProperty;
	public SessionSites: rps.data.sources.LookupSource;
	public UserCompanies: rps.data.sources.LookupSource;
	public UserSites: rps.data.sources.LookupSource;
	public CurrentSessionData: rps.data.sources.QueryMethod;
	public CurrentUserData: rps.data.sources.QueryMethod;
	public UserAllSites: rps.viewmodels.properties.VMProperty<boolean>;
	public SessionAllSitesProperty: rps.viewmodels.properties.VMProperty<boolean>;
	public PreviousPassword: rps.viewmodels.properties.VMProperty<string>;
	public NewPassword: rps.viewmodels.properties.VMProperty<string>;
	public ConfirmPassword: rps.viewmodels.properties.VMProperty<string>;
	public SessionInvoicingPoint: rps.viewmodels.properties.LookupProperty;
	public SessionInvoicingPoints: rps.data.sources.LookupSource;
	public InvoicingPointIsVisible: rps.viewmodels.properties.VMProperty<boolean>;

	//Declaración de los comandos
	public ApplySessionData: rps.viewmodels.commands.CommandProperty;
	public ApplySessionDataExecute(): Promise<any>{ return Promise.resolve<any>(this) }
	public ApplySessionDataCanExecute(): rps.viewmodels.commands.CanExecuteResult { return rps.viewmodels.commands.CanExecuteResult.allow();}
	public SaveUserData: rps.viewmodels.commands.CommandProperty;
	public SaveUserDataExecute(): Promise<any>{ return Promise.resolve<any>(this) }
	public SaveUserDataCanExecute(): rps.viewmodels.commands.CanExecuteResult { return rps.viewmodels.commands.CanExecuteResult.allow();}
	public ChangePasswordCommandOk: rps.viewmodels.commands.CommandProperty;
	public ChangePasswordCommand: rps.viewmodels.commands.ModalNavigationCommandProperty;
	public ChangePasswordCommandExecute(result: { result: rps.services.NavigationResult; data: any }): void{}

	public ChangePasswordCommandCanExecute(): rps.viewmodels.commands.CanExecuteResult { return rps.viewmodels.commands.CanExecuteResult.allow();}
	public ChangePasswordCommand_allowOk(): rps.viewmodels.commands.CanExecuteResult { return rps.viewmodels.commands.CanExecuteResult.allow();}
	public ChangePasswordCommand_ok(): Promise<any>{ return Promise.resolve<any>(this) }


	//Declaración de las condiciones de filtros de las listas de mi tipo
	}






    export class EntityDocumentsResultVM extends rps.viewmodels.ModelVM {
	
		static fullTypeName: string = "rps.HTML5.viewmodels.Settings.EntityDocumentsResultVM";   

		relatedState: string = "";
		static relatedState: string = "";


	



	configureVMProperties(){	
		super.configureVMProperties();	
		this.ID = new rps.viewmodels.properties.VMProperty<string>({ target: this,valuePropertyPath:  "model.ID"});
		this.descriptor = new rps.viewmodels.properties.VMProperty<rps.data.FullDescriptor>({ target: this,valuePropertyPath:  "model.descriptor"});
		this.link = new rps.viewmodels.properties.VMProperty<any>({ target: this,valuePropertyPath:  "model.link"});

		//Creación de las condiciones de filtros de las listas de mi tipo
		
		//Configuración de querysources con custom card view
	}

	
 
 	//Declaración de las propiedades
	public ID: rps.viewmodels.properties.VMProperty<string>;
	public descriptor: rps.viewmodels.properties.VMProperty<rps.data.FullDescriptor>;
	public link: rps.viewmodels.properties.VMProperty<any>;



	//Declaración de las condiciones de filtros de las listas de mi tipo
	}





}

module rps.HTML5.viewmodels.Settings.resources{
	export class Settings{
 		public static TITLE: string = '|^SR(TITLE)^|';
 		public static LBL_SEC_USER_DATA: string = '|^SR(LBL_SEC_USER_DATA)^|';
 		public static LBL_SEC_SESSION_DATA: string = '|^SR(LBL_SEC_SESSION_DATA)^|';
 		public static BTN_SAVE_DATA: string = '|^SR(BTN_SAVE_DATA)^|';
 		public static BTN_APPLY_CHANGES: string = '|^SR(BTN_APPLY_CHANGES)^|';
 		public static BTN_CHANGE_PASSWORD: string = '|^SR(BTN_CHANGE_PASSWORD)^|';
 		public static CHK_ALL_SITES: string = '|^SR(CHK_ALL_SITES)^|';
 		public static OLD_PASSWORD: string = '|^SR(OLD_PASSWORD)^|';
 		public static NEW_PASSWORD_CONF: string = '|^SR(NEW_PASSWORD_CONF)^|';
 		public static NEW_PASSWORD: string = '|^SR(NEW_PASSWORD)^|';
 		public static ERR_EMPTY_PASSWORD: string = '|^SR(ERR_EMPTY_PASSWORD)^|';
 		public static ERR_DIFFERENT_PASSWORDS: string = '|^SR(ERR_DIFFERENT_PASSWORDS)^|';
	}
}

rps.extensions.register(
	  rps.HTML5.viewmodels.Settings.SettingsViewModel,rps.HTML5.viewmodels.Settings.EntityDocumentsResultVM
);