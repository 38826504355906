import {Injectable, Inject,EventEmitter} from '@angular/core';

export class rpsEventEmitter<T> extends EventEmitter<T> implements rps.services.IEventEmitter<T>{
    public isIDestroyable: boolean = true;

    subscribe(generatorOrNext?: any, error?: any, complete?: any): rps.services.ISubscription {
        var sub = super.subscribe(generatorOrNext, error, complete);
        return sub;
    }

    unsubscribe(): void { };

    public onDestroy() {
        for (var i = this.observers.length -1 ; i >= 0; i--) {
            (<any>this.observers[i]).unsubscribe();
        }
    }
}

@Injectable()
export class rpsEventManager implements rps.services.IEventManager {
    public rpsSessionDataChanged: rps.services.IEventEmitter<any>;
    public rpsMenuChanged: rps.services.IEventEmitter<{menu: any[], favourites: any[]}>;

    public configure() {
        if (!rps.app.eventManager)
            rps.app.eventManager = this;
    }

    constructor() {
        this.rpsSessionDataChanged = new rpsEventEmitter<any>(false);
        this.rpsMenuChanged = new rpsEventEmitter<{ menu: any[], favourites: any[] }>(false);
    }

    public createEmitter<T>(isAsync: boolean = false): rps.services.IEventEmitter<T> {
        return new rpsEventEmitter<T>(isAsync);
    }


}
