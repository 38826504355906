import {Component, ElementRef, ComponentRef, AfterContentInit, OnChanges,OnDestroy, SimpleChange, ViewChild,ViewContainerRef} from '@angular/core';

@Component({
    template: `
        <div>
            <div *ngIf="descriptor">
                <div class="rps-tree-left" 
                        *ngIf="descriptor.Image">
                    <div class="rps-img-2">
                        <img class="rps-img"
                                src="{{descriptor.Image}}"
                                [attr.alt]="descriptor.MainDescriptor"/>
                    </div>
                </div>
                <div *ngIf="descriptor.SecondaryDescriptor == undefined"
                        class="rps-tree-right"
                        [class.rps-tree-right-prevent-left]="descriptor.Image">
                    {{descriptor.MainDescriptor}}
                </div>           
                <div *ngIf="descriptor.SecondaryDescriptor != undefined"
                        class="rps-tree-right"
                        [class.rps-tree-right-prevent-left]="descriptor.Image">
                    {{descriptor.MainDescriptor}} ({{descriptor.SecondaryDescriptor}})
                </div>
            </div>
        </div>
    `
})
export class rpsRecursiveTreeViewItem {
    public descriptor: rps.data.FullDescriptor;    

    constructor() {
    }

    setDescriptor(newValue: rps.data.FullDescriptor) {
        this.descriptor = newValue;
    }
}

@Component({
    selector: 'rps-recursive-tree-view',
    template: `
        <div>
            <div>
                <div id="kendoTreeView">
                </div>
                <ul id="kendoContextMenu">
                </ul>
            </div>
            <div #dynamicContainer>
            </div>
        </div>
  `,
    inputs: ['rpsSource', 'rpsLinkPropertyPath', 'rpsDescriptorPropertyPath']
})
export class rpsRecursiveTreeView implements AfterContentInit, OnDestroy, OnChanges{
    @ViewChild('dynamicContainer', { read: ViewContainerRef })
    private dynamicContainer: ViewContainerRef;

    private kendoTreeView: kendo.ui.TreeView;
    private kendoContextMenu: kendo.ui.ContextMenu;

    private rpsSource: rps.data.sources.RecursiveHierarchicalQuerySource;    

    private rpsLinkPropertyPath: string;
    private rpsDescriptorPropertyPath: string;

    private dynamicItems = new Array<ComponentRef<rpsRecursiveTreeViewItem>>();

    constructor(private elementRef: ElementRef) {
    }

    ngAfterContentInit() {
        this.createTargetControl();
    }

    ngOnDestroy() {
        if (this.kendoTreeView)
            this.kendoTreeView.destroy();
        if (this.kendoContextMenu)
            this.kendoContextMenu.destroy();

        $(this.elementRef.nativeElement).find("#kendoTreeView").first().empty();
        $(this.elementRef.nativeElement).find("#kendoContextMenu").first().empty();

        this.dynamicItems.forEach((item) => {
            item.destroy();
        });
        this.dynamicItems.length = 0;
        this.dynamicItems = null;
    }

    createTargetControl() {
        //Crear el treeview
        this.kendoTreeView = $(this.elementRef.nativeElement).find("#kendoTreeView").kendoTreeView(
            {
                autoBind: false,
                select: this.select,
                dragAndDrop: true,
                dragstart: this.dragstart,
                dragend: this.dragend,
                template: "<div class='rps-recursive-tree-view-item-temp'></div>",
                dataBound: this.kendoTreeViewDataBoundEvent
            }).data("kendoTreeView");
        //Se establece el origen de datos
        this.rpsSourceChanged();            
        //Crear el context menu
        //HACK:Se crea en asíncrono, ya que sino no funciona, aunque no sé porque… (Se puede probar en la pantalla de la organización)
        setTimeout(() => {
            this.kendoContextMenu = $(this.elementRef.nativeElement).find("#kendoContextMenu").kendoContextMenu({
                target: "#kendoTreeView",
                filter: ".k-in",
                select: this.contextMenuOnSelect,
                dataSource: this.getContextMenuItems()
            }).data("kendoContextMenu");
        });
    }

    kendoTreeViewDataBoundEvent = (e: kendo.ui.TreeViewDataBoundEvent) => {  
        (<JQuery>(<any>e.sender).items()).find(".rps-recursive-tree-view-item-temp").each((index: number, elem: Element) => {
            var element = jQuery(elem);
            var dataItem = e.sender.dataItem(element);
            this.createItem(dataItem, element);
        });
    }

    createItem(dataItem: any, element: JQuery) {
        element.removeClass("rps-recursive-tree-view-item-temp");
        rps.app.uiFactory.createComponent<rpsRecursiveTreeViewItem>(rpsRecursiveTreeViewItem, this.dynamicContainer)
            .then((result) => {    
                this.dynamicItems.push(result);                            
                (<rpsRecursiveTreeViewItem>result.instance).setDescriptor(dataItem[this.rpsDescriptorPropertyPath].value);
                element.append(result.location.nativeElement);                
            });
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes["rpsSource"])
            this.rpsSourceChanged();
    }

    rpsSourceChanged() {
        if (this.rpsSource && this.kendoTreeView) {
            //Se establece el origen de datos del widget y se le indica a la query cuál es el widget, para que estén sincronizados
            this.kendoTreeView.setDataSource(this.rpsSource.dataSource);
            //HACK:Se refresca a mano, ya que en el caso de que la lectura ya esté hecha, no se dibujaba…
            if (this.rpsSource.dataSource.data().length) {
                (<any>this.kendoTreeView).refresh({
                    items: this.rpsSource.dataSource.data()
                })
            }
            this.rpsSource.setKendoTreeView(this.kendoTreeView);
        }
    }

    private getContextMenuItems(): Array<{ text: string, spriteCssClass: string, cssClass:string }> {
        if (rps.object.hasValue(this.rpsSource))
            return [{ text: rps.app.resources.directives.NEW, spriteCssClass: "fa fa-plus", cssClass:"rps-tree-view-context-menu-item" }];
        else
            return null;
    }

    select = (e: kendo.ui.TreeViewSelectEvent) => {
        if (this.rpsLinkPropertyPath) {
            var selectedVM: kendo.data.Node = this.kendoTreeView.dataItem(e.node);
            var linkProperty: rps.viewmodels.properties.LinkProperty = selectedVM[this.rpsLinkPropertyPath];
            linkProperty.go();
        }
        else {
            setTimeout(() => {
                var currentSelectedNode: JQuery = this.kendoTreeView.select();
                var selectedVM: kendo.data.Node = this.kendoTreeView.dataItem(e.node);
                this.rpsSource.goToModelVM(<any>selectedVM).catch(() => {
                    this.kendoTreeView.select(currentSelectedNode);
                });
            });
        }
    }

    dragstart = (e: kendo.ui.TreeViewDragstartEvent) => {        
        var sourceVM: rps.viewmodels.ModelVM = <any>this.kendoTreeView.dataItem(e.sourceNode);
        if (this.rpsSource.allowDrag(sourceVM)) {
            setTimeout(() => {
                this.rpsSource.dragstart(sourceVM);
            });
        }
        else
            e.preventDefault();
    }

    dragend = (e: kendo.ui.TreeViewDragendEvent) => {
        var sourceVM: rps.viewmodels.ModelVM = <any>this.kendoTreeView.dataItem(e.sourceNode);
        var destinationVM: rps.viewmodels.ModelVM;
        if (e.destinationNode) {
            switch (e.dropPosition) {
                case "over":  
                    destinationVM = <any>this.kendoTreeView.dataItem(e.destinationNode);
                    break;
                case "before":
                case "after":
                    if (e.destinationNode.parentElement)
                        destinationVM = <any>this.kendoTreeView.dataItem(e.destinationNode.parentElement);
                    break;
            }
        }
        this.rpsSource.dragend(sourceVM, destinationVM).catch(() => {
            this.rpsSource.undoDragend(sourceVM.model.ID);
        });
    }

    contextMenuOnSelect = (e: kendo.ui.ContextMenuSelectEvent) => {
        var button = $(e.item);
        var node = $(e.target);
        this.kendoTreeView.expand(node);
        var modelVM: rps.viewmodels.ModelVM = <any>this.kendoTreeView.dataItem(node);
        switch (button.text()) {
            case rps.app.resources.directives.NEW:
                this.rpsSource.addChild(modelVM);
                break;
        }
    }
}
