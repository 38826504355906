import {Component, ElementRef, AfterViewInit, OnChanges, SimpleChanges,OnDestroy} from '@angular/core';

@Component({
    selector: 'rps-chart',
    template: `
        <div>
            <div class="container-fluid">
                <div id="kendoChart">
                </div>
            </div>
            <div *ngIf="!loaded" 
                 class="rps-unloaded-query">
                {{resources.directives.UNLOADED_QUERY}}
            </div>
        </div>
    `,
    inputs: ['rpsSource']
})
export class rpsChart implements AfterViewInit, OnChanges, OnDestroy {
    private contentInitialized: boolean = false;    
    private kendoChart: kendo.dataviz.ui.Chart;
    private rpsSourcePropertyChangedSubscription: rps.services.ISubscription;
    private loaded: boolean;

    rpsSource: rps.data.sources.ChartSource;

    private get resources() {
        return rps.app.resources;
    }    

    protected get element(): JQuery {
        return $(this.elementRef.nativeElement);
    }

    constructor(protected elementRef: ElementRef) {
    }

    ngAfterViewInit(): any {
        this.contentInitialized = true;
        this.tryCreateTargetControls();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['rpsSource'])
            this.rpsSourceChanged();
    }

    rpsSourceChanged() {
        if (this.rpsSourcePropertyChangedSubscription)
            this.rpsSourcePropertyChangedSubscription.unsubscribe();

        if (this.rpsSource) {
            this.rpsSourcePropertyChangedSubscription = this.rpsSource.propertyChanged.subscribe((vmPropertyChange:rps.viewmodels.properties.VMPropertyChange) => {
                if (vmPropertyChange.propertyName == "loaded" || vmPropertyChange.propertyName == "chartData")
                    this.tryCreateTargetControls();
            });
        }

        this.tryCreateTargetControls();              
    }

    //Crea el chart
    private tryCreateTargetControls() {
        this.tryDestroyTargetControls();

        if (rps.object.hasValue(this.rpsSource) &&
            this.rpsSource.loaded &&
            this.contentInitialized) {
            this.loaded = true;

            //Se crea un nuevo contenedor en el que construir el widget, con la intención de poder borrarlo y dejar todo en el estado inicial
            var kendoChartDiv: JQuery = jQuery('<div/>');
            this.element.find("#kendoChart").append(kendoChartDiv);

            this.kendoChart = kendoChartDiv
                .kendoChart(this.toKendoChartData(this.rpsSource.chartData))
                .data("kendoChart");            
        }
        else
            this.loaded = false;
    }

    private tryDestroyTargetControls() {
        //Destruye los controles de kendo y todo su rastro
        if (this.kendoChart) {
            this.kendoChart.destroy();
            this.kendoChart = null;
            this.element.find("#kendoChart").html("");
        }
    }

    private toKendoChartData(chartData: rps.data.ChartData): kendo.dataviz.ui.ChartOptions {
        var chartAreaHeight: number = 400;        
        var seriesChartData: rps.data.SeriesChartData;
        if (chartData["Categories"])
            seriesChartData = <any>chartData;
        var pieChartData: rps.data.PieChartData;
        if (chartData["Values"])
            pieChartData = <any>chartData

        var categoryAxis: kendo.dataviz.ui.ChartCategoryAxisItem = {
            labels: {
                rotation: -45
            }
        };
        if (seriesChartData && rps.object.hasValue(seriesChartData.Categories)) {
            categoryAxis.categories = seriesChartData.Categories;

            var maxCategoryLength: number;
            if (seriesChartData.Categories.length > 0)
                maxCategoryLength = Enumerable.From<string>(seriesChartData.Categories).Max((cat => cat.length));
            else
                maxCategoryLength = 0;
            chartAreaHeight += (maxCategoryLength * 5);
        }

        var valueAxes: Array<kendo.dataviz.ui.ChartValueAxisItem> = new Array<kendo.dataviz.ui.ChartValueAxisItem>();        
        valueAxes.push({
            labels: {
                format: rps.utils.getDecimalFormat({suffix: chartData.ValueOptions.Suffix})
            }
        });

        var series: Array<kendo.dataviz.ui.ChartSeriesItem> = new Array<kendo.dataviz.ui.ChartSeriesItem>();
        if (seriesChartData && seriesChartData.Series) {
            seriesChartData.Series.forEach((chartSerie: rps.data.ChartSerie) => {
                var chartSeriesItem: kendo.dataviz.ui.ChartSeriesItem = {
                    name: chartSerie.Name,
                    data: chartSerie.Data,                   
                    field: "Value"
                };

                var toolTipValueFormat = rps.utils.getDecimalFormat({
                    decimalPlaces: seriesChartData.ValueOptions.DecimalPlaces,
                    suffix: seriesChartData.ValueOptions.Suffix,
                    toKendoTemplate:true
                })
                chartSeriesItem.tooltip = {
                    visible: true,
                    template: "#= kendo.toString(category) #<br/>#= series.name #: #= kendo.toString(value,\"" + toolTipValueFormat + "\") #"
                }

                switch (chartSerie.SeriesType) {
                    case "Column":
                        chartSeriesItem.type = "column";
                        break;
                    case "Line":
                        chartSeriesItem.type = "line";
                        break;
                    default:
                        chartSeriesItem.type = "column";
                        break;
                }
                series.push(chartSeriesItem);
            });
        }
        else if (pieChartData && pieChartData.Values) {
            var chartSeriesItem: kendo.dataviz.ui.ChartSeriesItem = {
                data: pieChartData.Values,
                field: "Value"
            };
            var toolTipValueFormat = rps.utils.getDecimalFormat({
                decimalPlaces: pieChartData.ValueOptions.DecimalPlaces,
                suffix: pieChartData.ValueOptions.Suffix,
                toKendoTemplate:true
            });
            chartSeriesItem.tooltip = {
                visible: true,
                template: "#= category #<br/>#= kendo.toString(value,\"" + toolTipValueFormat + "\") #<br/>#= kendo.format('{0:P}', percentage) #"
            }
            chartSeriesItem.type = "pie";
            chartSeriesItem.categoryField = "Description";
            chartSeriesItem.labels = {
                template: "#= category # #= kendo.format('{0:P}', percentage) #",
                visible: true
            };
            series.push(chartSeriesItem);
        }   

        return {
            title: chartData.Title,
            theme: "office365",
            categoryAxis: [categoryAxis],
            valueAxis: valueAxes,
            series: series,
            zoomable: {
                mousewheel: {
                    lock: "y"
                },
                selection: {
                    lock: "y"
                }
            },
            chartArea: {
                height: chartAreaHeight
            }
        };
    }

    ngOnDestroy() {
        if (this.rpsSourcePropertyChangedSubscription)
            this.rpsSourcePropertyChangedSubscription.unsubscribe();
        this.tryDestroyTargetControls();
    }
}