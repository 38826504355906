import {Component, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { baseServerColumn } from './baseServerColumn';

@Component({
    template: `
        <div>{{text}}</div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class rpsDatePickerServerColumn extends baseServerColumn<Date>{
    date: Date;
    text: string;

    constructor(_cdr: ChangeDetectorRef) {
        super(_cdr);
    }

    setValue(newValue: Date) {
        if (this.date !== newValue) {
            this.date = newValue;
            if (this.date)
                this.text = this.date.toShortDate();
            else
                this.text = null;
            this._cdr.markForCheck();
        }
    }
} 