import { Component, ElementRef, forwardRef, Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsEditor} from './editorBase'
import {errorDetailValidator} from '../utils/errorDetailValidator'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'
import { rpsControl } from '../controlBase'

@Component({
    selector: 'rps-long-text-box-little',
    template: `
        <div class="rps-editor  rps-long-text-box-little-container" 
             [rpsSemanticState]="rpsModel?.semanticState" 
             [ngClass]="{'rps-disabled-editor': disabled}"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label" 
                    [attr.for]="myId">
                {{rpsLabel}}<font *ngIf="isRequired" color="red"> *</font>
            </label>
            <div class="rps-long-text-box-little">
               <input class="k-textbox rps-editor-editor rps-semantic-state" 
                   type="text" 
                   [attr.id]="myId"
                   [disabled]="disabled"
                   [ngModel] = "value"
                   (blur) = "setVMPropertyValue(); isEditing = false; setCursorToLeft()"
                   (keypress) = "keyDownPress($event)"
                   (focus) ="isEditing = true"
                   (ngModelChange) = "nextValue = $event" />
                <div class="rps-long-text-box-little-edit-button rps-editor-fit-container">
                    <rps-button rpsTemplate="ICON" 
                                rpsIcon="fa fa-lg fa fa-pencil-square-o" 
                                [rpsModel]="openEditor"
                                rpsPreventTabindex="true" 
                                [ngClass]="{'rps-long-text-box-little-edit-button-disabled':!rpsModel?.hasValue(),
                                            'rps-long-text-box-little-edit-button-focus':isEditing}">
                    </rps-button>
                </div>
            </div>
        </div>
        <div id="editor-window">
            <div>
                <rps-rows-group>        
                    <rps-row>
                        <rps-long-text-box rpsColumns="12"
                                           [rpsModel]="rpsModel">
                        </rps-long-text-box>
                    </rps-row>     
                    <rps-row>            
                        <rps-button rpsColumns="6"
                                    rpsLabel="{{resources?.directives?.ACCEPT}}"
                                    [rpsModel]="acceptButton">
                        </rps-button>
                    </rps-row>   
                </rps-rows-group>
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsLongTextBoxLittle) }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsEditor.getComponentInputs()
})
export class rpsLongTextBoxLittle extends rpsEditor<string>  {
    private kendoWindow: kendo.ui.Window;
    private nextValue: string;

    openEditor: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: (): Promise<any> => {
            rps.ui.grid.isInEditMode = true;
            this.kendoWindow.center().open();
            return Promise.resolve<any>(this);
        }
    });

    acceptButton: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: (): Promise<any> => {
            this.kendoWindow.close();
            rps.ui.grid.isInEditMode = false;
            $(this.elementRef.nativeElement).find("input").focus();
            return Promise.resolve<any>(this);
        }
    });

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    private setVMPropertyValue() {
        this.updateSourceValue(this.nextValue);
    }

    protected updateTargetValue(newValue: string) {
        this.nextValue = newValue;       
    }

    private keyDownPress(event) {
        if (event.keyCode == 13) {
            this.setVMPropertyValue();
            event.preventDefault();
        }
    }

    createTargetControl() {
        this.kendoWindow = $(this.elementRef.nativeElement).find("#editor-window").kendoWindow({
            modal: true,
            title: this.rpsLabel,
            width: 600,
            visible: false
        }).data("kendoWindow");
    }

    destroyTargetControl() {
        if (this.kendoWindow) {
            this.kendoWindow.destroy();
            this.kendoWindow = null;
            this.element.find("#editor-window").first().empty();
        }
    }

    setCursorToLeft() {
        //HACK para que cuando pierda el foco y se termine de escribir cuando no esta agrandado, 
        //el cursor vuelva a la posicion inicial del input y no se quede el texto cortado
        $(this.elementRef.nativeElement).find(".rps-editor-editor").blur();
    }

    //Método que invoca la grid, para forzar que el editor recoja el foco
    setFocusAfterInit() {
        $(this.elementRef.nativeElement).find("input").focus();
    }
}
