import { Component, ElementRef, forwardRef,Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsEditor} from './editorBase'
import {errorDetailValidator} from '../utils/errorDetailValidator'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'
import { rpsControl } from '../controlBase'

@Component({
    selector: 'rps-date-picker',
    template: `
        <div class="rps-editor" 
            [rpsSemanticState]="rpsModel?.semanticState" 
            [class.rps-disabled-editor]="disabled"
            [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label" 
                   [attr.for]="myId">
                {{rpsLabel}}<font *ngIf="isRequired" color="red"> *</font>
            </label>
            <div>
               <input class="rps-editor-editor rps-semantic-state"    
                        (keydown)="formatDate($event)" 
                        [attr.id]="myId" >
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsDatePicker) }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsEditor.getComponentInputs()
})
export class rpsDatePicker extends rpsEditor<Date> {

    private kendoControl: kendo.ui.DatePicker;

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }   
     
    createTargetControl() {
        //Comprobar si contiene el foco, para volver a introducírselo
        var focused:boolean = $(this.elementRef.nativeElement).find("input").is(":focus");

        var format: string = rps.utils.getPatternDate();       
        var mask: string = format.replace(/d|M|y/g, "0");
        this.kendoControl = this.element.find("input").first().kendoDatePicker(
            {
                change: () => {
                    this.updateSourceValue(this.kendoControl.value())
                },
                format: format,
                value: this.value,
            }).data("kendoDatePicker");
        this.kendoControl.element.kendoMaskedTextBox({
            mask: mask,
            //Hay veces, por ejemplo, con la app en alemán, que el formato de fechas es separado con .
            //Si se establece el mask por defecto, cambia . por , y se fastidia el mask
            //Como Telerik no tiene mask sin cultura, nos han dicho que pongamos el en-US para que no cambie puntos por comas
            culture:"en-US" 
        });      
        this.kendoControl.element.closest(".k-datepicker")
            .add(this.kendoControl.element)
            .removeClass("k-textbox");

        //Cambiar iconos de kendo por iconos fa para que al modificar el "em" cambien tambien su tamaño
        $(this.element).find(".k-datepicker .k-i-calendar").removeClass("k-icon").addClass("fa fa-lg fa-calendar-o");

        if (focused)
            $(this.elementRef.nativeElement).find("input").focus();
    }

    destroyTargetControl() {
        if (this.kendoControl) {
            this.kendoControl.destroy();
            this.kendoControl = null;

            var container = this.element.find("input").first();
            if (container)
                container.empty();
        }
    }

    updateTargetValue(newValue: Date) {
        if (rps.object.isUndefined(newValue))
            newValue = null;
        if (newValue && !rps.object.isDate(newValue) && !rps.object.isString(newValue))
            newValue = null;
        this.kendoControl.value(newValue);
    }
    enableControl() {
        this.kendoControl.enable(true);
    }

    disableControl() {
        this.kendoControl.enable(false);
    }

    formatDate(value: KeyboardEvent) {
        //Si pulsamos tabulador comprobamos que la fecha  esté completa, sino la rellenamos con los datos restantes.
        var currentValue: string = (<any>value.target).value;
        if (value.code == "Tab") {
            var formatedDate: Date = rps.utils.completeDateFormat(currentValue);
            if (formatedDate == null) {
                //Se actualiza el valor del control porque a veces no se cambiaba
                this.kendoControl.value(null);
                this.updateSourceValue(formatedDate);            
            } else {
                this.updateSourceValue(formatedDate);            
            }
            
        }
        else if (value.code == "KeyT") {
            const formatedDate = rps.date.today();
            this.updateSourceValue(formatedDate);           
            value.preventDefault();
        }
        else if (value.code == "KeyF") {
            const formatedDate = rps.date.firstDayOfMonth();
            this.updateSourceValue(formatedDate);
            value.preventDefault();
        }
        else if (value.code == "KeyL") {
            const formatedDate = rps.date.lastDayOfMonth();
            this.updateSourceValue(formatedDate);
            value.preventDefault();
        }
    }

    //Método que invoca la grid, para forzar que el editor recoja el foco
    setFocusAfterInit() {
        $(this.elementRef.nativeElement).find("input").focus(); 
    }
}
   