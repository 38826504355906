import { Component, ElementRef, forwardRef,Optional, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import {rpsEditor} from './editorBase'
import {errorDetailValidator} from '../utils/errorDetailValidator'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'
import { rpsControl } from '../controlBase'

@Component({
    selector: 'rps-text-box',
    template: `
        <div class="rps-editor" 
             [rpsSemanticState]="rpsModel?.semanticState" 
             [ngClass]="{'rps-disabled-editor': disabled}"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label" 
                    [attr.for]="myId">
                {{rpsLabel}}<font *ngIf="isRequired" color="red"> *</font>
            </label>
            <input class="k-textbox rps-editor-editor rps-semantic-state" 
                   type="text" 
                   [attr.id]="myId"
                   [disabled]="disabled"
                   [(ngModel)] = "inputValue"
                   (focus) = "inputFocus()"
                   (blur) = "inputBlur()"
                   (keypress) = "keyDownPress($event)" />
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsTextBox) }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsEditor.getComponentInputs()
})
export class rpsTextBox extends rpsEditor<string>  {
    private focused: boolean = false;

    _inputValue: string;
    get inputValue(): string {
        return this._inputValue;
    }
    set inputValue(value: string) {
        this._inputValue = value;
        if (!this.focused && this.value != this.inputValue)
            this.setVMPropertyValue();
        this._cdr.markForCheck();
    }

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    inputFocus() {
        this.focused = true;
    }

    inputBlur() {
        this.focused = false;
        this.setVMPropertyValue();
    }

    private setVMPropertyValue() {        
        this.updateSourceValue(this.inputValue);
    }

    protected updateTargetValue(newValue: string) {
        this.inputValue = newValue;
    }

    private keyDownPress(event) {
        if (event.keyCode == 13) {
            this.setVMPropertyValue();
            event.preventDefault();
        }
    }
    
    //Método que invoca la grid, para forzar que el editor recoja el foco
    setFocusAfterInit() {
        $(this.elementRef.nativeElement).find("input").focus();
    }
}