import { Component, ElementRef, forwardRef,Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsEditor} from './editorBase'
import {errorDetailValidator} from '../utils/errorDetailValidator'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'
import { rpsControl } from '../controlBase'

@Component({
    selector: 'rps-percentage-editor',
    template: `
        <div class="rps-editor" 
             [rpsSemanticState]="rpsModel?.semanticState" 
             [class.rps-disabled-editor]="disabled"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label" 
                   [attr.for]="myId">
                {{rpsLabel}}
            </label>
            <div>
               <input class="rps-editor-editor rps-number-editor-input rps-semantic-state" 
                        (focus)="selectContent()" 
                        (blur)="removeInterval()"
                        [attr.id]="myId">
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsPercentageEditor) }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsEditor.getComponentInputs()
})
export class rpsPercentageEditor extends rpsEditor<number> {

    private kendoControl: kendo.ui.NumericTextBox;

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    createTargetControl() {
        var format = rps.utils.getDecimalFormat({ suffix: "%" });
        this.kendoControl = this.element.find("input").first().kendoNumericTextBox(
            {
                decimals: rps.number.getMaxDecimalPlaces(),
                format: format,
                change: () => {
                    this.updateSourceValue(this.kendoControl.value())
                },
                spin: () => {
                    this.updateSourceValue(this.kendoControl.value())
                },
                value: this.value,
                spinners:false
            }).data("kendoNumericTextBox");
        $(this.kendoControl.element).on("input", function (e) {
            rps.utils.controlNumberLenght(this);
        });

        //Si estamos en modo edición de la grid, hay que introducirle el foco al editor, ya que significa que hemos entrado en modo edición de la celda
        if (rps.ui.grid.isInEditMode) {
            $(this.elementRef.nativeElement).find("input").focus();
            rps.ui.grid.isInEditMode = false;
        }
    }

    updateTargetValue(newValue: number) {
        this.kendoControl.value(newValue);
    }

    enableControl() {
        this.kendoControl.enable(true);
    }

    disableControl() {
        this.kendoControl.enable(false);
    }

    selectContent() {
        rps.utils.selectInputContent($(this.kendoControl.element), 1);
    }

    removeInterval() {
        rps.utils.selectInputContent($(this.kendoControl.element), 0);
    }

    //Método que invoca la grid, para forzar que el editor recoja el foco
    setFocusAfterInit() {
        rps.ui.grid.isInEditMode = true;
    }

    destroyTargetControl() {
        if (this.kendoControl) {
            $(this.kendoControl.element).off();

            this.kendoControl.destroy();
            this.kendoControl = null;

            var div = this.element.find("input").first();
            if (div)
                div.empty();
        }
    }
}
