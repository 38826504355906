import {Component, Injector,AfterContentInit} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import { MainComponent, BaseComponent, Empty, ComponentProvider } from 'rps/src/components/components';

@Component({
    selector: 'logon',
    template: `
        <div>
            <button >Advanced filter</button>
        </div>
        <div>
            <rps-pdf [rpsData]="reportPath"></rps-pdf>
            <!--<object data="http://www.urartuuniversity.com/content_images/pdf-sample.pdf" type="application/pdf" width="100%" height="100%"></object>-->
        </div>
`
})
export class reportViewerComponent implements AfterContentInit{

    public reportPath: string;

    constructor(private activatedRoute:ActivatedRouteSnapshot) {
    }

    ngAfterContentInit() {
        if (!rps.app.session.logged) {
            rps.app.stateManager.goTo(rps.data.IUILinkDefinition.create("html5.logon"));
        }

        var queryParams = this.activatedRoute.params;

        //url: "/reportViewer/:service/:reportQuery/:params?advancedCriteria",
        var service = queryParams["service"];
        var reportName = queryParams["reportQuery"];
        var params = queryParams["params"].split('&');
        var url = rps.app.appSettings.rpsAPIAddress + "api/" + service + "/query/" + reportName + "?" + queryParams["params"];

        this.reportPath = url;
    }

}
