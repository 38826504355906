import { Component, ElementRef, forwardRef, Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsEditor} from './editorBase'
import {errorDetailValidator} from '../utils/errorDetailValidator'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'
import { rpsControl } from '../controlBase'

@Component({
    selector: 'rps-long-text-box',
    template: `
        <div class="rps-editor" 
             [rpsSemanticState]="rpsModel?.semanticState" 
             [class.rps-disabled-editor]="disabled"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label  class="rps-editor-label" 
                    [attr.for]="myId">
                {{rpsLabel}}<font *ngIf="rpsModel?.isRequired" color="red"> *</font>
            </label>
            <textarea class="k-textbox rps-long-text-box-input rps-editor-editor rps-semantic-state" 
                        [attr.id]="myId"
                        [disabled]="disabled"
                        [ngModel] = "value"
                        (focus)="selectContent()"
                        (blur) = "setVMPropertyValue(); removeInterval()"
                        (ngModelChange) = "nextValue = $event">
            </textarea>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsLongTextBox) }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsEditor.getComponentInputs()
})
export class rpsLongTextBox extends rpsEditor<string>  {
    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    private nextValue: string;

    private setVMPropertyValue() {
        this.updateSourceValue(this.nextValue);       
    }

    protected updateTargetValue(newValue: string) {
        this.nextValue = newValue;
    }

    selectContent() {
        rps.utils.selectInputContent($(this.element.find(".rps-long-text-box-input").first()), 1);
    }
    removeInterval() {
        rps.utils.selectInputContent($(this.element.find(".rps-long-text-box-input").first()), 0);
    }
}