import { Component, ElementRef, forwardRef, Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsEditor} from './editorBase'
import {errorDetailValidator} from '../utils/errorDetailValidator'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'
import {rpsEnumEditor} from './enumEditor'
import { rpsControl } from '../controlBase'

@Component({
    selector: 'rps-duration-editor',
    template: `
        <div class="rps-editor" 
             [rpsSemanticState]="rpsModel?.semanticState" 
             [class.rps-disabled-editor]="disabled"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label" 
                   [attr.for]="myId">{{rpsLabel}}</label>
            <div class="rps-duration-editor-container">
                <div class="inputContainer">
                   <input class="rps-editor-editor rps-number-editor-input  rps-semantic-state" 
                            [attr.id]="myId">
                </div>
                <div class="timeUnitContainer rps-editor-fit-container">
                    <rps-enum-editor [rpsModel]="rpsModel?.timeUnit">
                    </rps-enum-editor>
                </div>
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsDurationEditor) }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsEditor.getComponentInputs(),
    styles: [`
        .rps-duration-editor-container{
            position:relative;
        }
        .inputContainer{
            padding-right:100px;
        }
        .timeUnitContainer{
            position:absolute;
            right:0;
            top:0;
            width:100px;
        }
    `]
})
export class rpsDurationEditor extends rpsEditor<number> {

    private durationFactor: rps.data.DurationFactor;
    private kendoControl: kendo.ui.NumericTextBox;
    private kendoSourceHTML: string;

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    createTargetControl() {
        //Busca inputContainer (el div que contiene el input)
        var div = this.element.find(".inputContainer").first();

        //Se queda con una copia del HTML del input, por si necesita crearlo de nuevo (en caso de que cambien las opciones, por ejemplo)
        if (!this.kendoSourceHTML)
            this.kendoSourceHTML = div.html();

        //Si el control estaba creado, destruirlo y vaciarlo
        if (this.kendoControl) {
            this.kendoControl.destroy();
            div.empty();
            //Volver a crear un input para que pueda llamar al método de Telerik para crear el editor concreto
            div.append(this.kendoSourceHTML);
        }

        //Crear el editor
        this.kendoControl = this.element.find(".inputContainer input").first().kendoNumericTextBox(
            {
                decimals: rps.number.getMaxDecimalPlaces(),
                format: rps.utils.getDecimalFormat({decimalPlaces:2}),
                change: () => { this.updateSourceValue(this.kendoControl.value()) },
                spin: () => { this.updateSourceValue(this.kendoControl.value()) },
                spinners: false
            }).data("kendoNumericTextBox");

        $(this.kendoControl.element).on("focus", function () {
            rps.utils.selectInputContent($(this), 1);
        }).blur(function () {
            rps.utils.selectInputContent($(this), 0);
        });
        $(this.kendoControl.element).on(".inputContainer input", function (e) {
            rps.utils.controlNumberLenght(this);
        });

        //Si estamos en modo edición de la grid, hay que introducirle el foco al editor, ya que significa que hemos entrado en modo edición de la celda
        if (this.focusAfterInit) {            
            $(this.elementRef.nativeElement).find(".inputContainer input").focus();
            this.focusAfterInit = false;
            rps.ui.grid.isInEditMode = false;
        }
    }

    updateTargetValue(newValue: number) {
        var uiValue: number = null;
        if (this.rpsModel && (<rps.viewmodels.properties.DurationProperty>this.rpsModel).durationFactor)
            uiValue = (newValue / (<rps.viewmodels.properties.DurationProperty>this.rpsModel).durationFactor.Factor);

        this.kendoControl.value(uiValue);
    }

    protected updateSourceValue(newValue: number) {
        if (this.rpsModel && (<rps.viewmodels.properties.DurationProperty>this.rpsModel).durationFactor)
            super.updateSourceValue(newValue * (<rps.viewmodels.properties.DurationProperty>this.rpsModel).durationFactor.Factor);
    }

    enableControl() {
        this.kendoControl.enable(true);
    }

    disableControl() {
        this.kendoControl.enable(false);
    }

    onChangeModel() {
        super.onChangeModel();
        if (this.rpsModel)
            this.setDurationFactor((<rps.viewmodels.properties.DurationProperty>this.rpsModel).durationFactor);
        else
            this.setDurationFactor(null);
    }

    rpsModelPropertyChanged(e: rps.viewmodels.properties.VMPropertyChange) {
        super.rpsModelPropertyChanged(e);
        if (e.propertyName == "durationFactor")
            this.setDurationFactor((<rps.viewmodels.properties.DurationProperty>this.rpsModel).durationFactor);
    }

    setDurationFactor(newValue: rps.data.DurationFactor) {
        if (newValue != this.durationFactor) {
            this.durationFactor = newValue;
            if (this.contentInitialized) {
                this.fillTemplate();
            }
        }
    }

    //Método que invoca la grid, para forzar que el editor recoja el foco
    setFocusAfterInit() {        
        this.focusAfterInit = true;
        rps.ui.grid.isInEditMode = true;
    }

    destroyTargetControl() {
        if (this.kendoControl) {
            this.kendoControl.destroy();
            this.kendoControl = null;

        var container = this.element.find(".inputContainer").first();
            if (container)
                container.empty();
        }
    }
}
