import { Component, ElementRef, forwardRef, Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsEditor} from './editorBase'
import {errorDetailValidator} from '../utils/errorDetailValidator'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'
import { rpsControl } from '../controlBase'


@Component({
    selector: 'rps-time-picker',
    template: `
        <div class="rps-editor" 
             [rpsSemanticState]="rpsModel?.semanticState" 
             [class.rps-disabled-editor]="disabled"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label" 
                   [attr.for]="myId">
                {{rpsLabel}}
            </label>
            <div>
               <input class="rps-editor-editor rps-semantic-state" 
                        [attr.id]="myId" 
                        (keydown)="formatTime($event)" >
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsTimePicker) }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsEditor.getComponentInputs()
})
export class rpsTimePicker extends rpsEditor<number> {

    private kendoControl: kendo.ui.TimePicker;

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    createTargetControl() {
        var mask = "00:00:00";
        this.kendoControl = this.element.find("input").first().kendoTimePicker(
            {
                format: 'HH:mm:ss',
                change: () => {
                    this.convertAndUpdateSourceValue(this.kendoControl.value())
                }
            }).data("kendoTimePicker");
        this.kendoControl.element.kendoMaskedTextBox({
            mask: mask
        });
        this.kendoControl.element.closest(".k-datepicker")
            .add(this.kendoControl.element)
            .removeClass("k-textbox");     

        this.updateTargetValue(this.value);

        //Si estamos en modo edición de la grid, hay que introducirle el foco al editor, ya que significa que hemos entrado en modo edición de la celda
        if (rps.ui.grid.isInEditMode) {
            $(this.elementRef.nativeElement).find("input").focus();
            rps.ui.grid.isInEditMode = false;
        }
    }

    destroyTargetControl() {
        if (this.kendoControl)
            this.kendoControl.destroy();
    }

    private convertAndUpdateSourceValue(newValue: Date) {
        var nValue: number;

        if (this.rpsModel && !rps.time.equals(newValue, this.rpsModel.value)) {
            if (!rps.object.isNullOrUndefined(newValue)) {
                nValue = rps.time.convertToNumber(newValue);
            }
            else {
                nValue = 0;
            }
        }
        this.updateSourceValue(nValue)
    }

    updateTargetValue(newValue: number) {
        this.kendoControl.value(rps.time.convertToDate(newValue));
    }

    enableControl() {
        this.kendoControl.enable(true);
    }

    disableControl() {
        this.kendoControl.enable(false);
    }

    formatTime(value: any) {
        //Si pulsamos tabulador comprobamos que la fecha  esté completa, sino la rellenamos con los datos restantes.
        var currentValue: string = value.target.value;
        if (value.keyCode == 9) {
            this.rpsModel.value = rps.utils.completeTimeFormat(currentValue);
            this.kendoControl.value(rps.time.convertToDate(this.rpsModel.value));
        }
    }

    //Método que invoca la grid, para forzar que el editor recoja el foco
    setFocusAfterInit() {
        rps.ui.grid.isInEditMode = true;
    }
}

