import {
    Component, ElementRef, AfterViewInit, OnDestroy,
    OnChanges, SimpleChange, AfterViewChecked
} from '@angular/core';
import {errorDetailValidator} from '../utils/errorDetailValidator'

@Component({
    selector: 'rps-scheduler',
    template: `
        <div class="rps-layout-padding">
            <div [hidden]="!loaded" 
                 class="kendoSchedulerContainer">
            </div>
        </div>
  `,
    inputs: ['rpsAdapter']
})
export class rpsScheduler implements AfterViewInit, OnDestroy,OnChanges,
    rps.data.sources.ChronologicalQuerySource.IChronologicalQuerySourceFilter, AfterViewChecked {

    private kendoScheduler: kendo.ui.Scheduler;
    private rpsAdapter: rps.viewmodels.adapters.SchedulerAdapterBase;
    private dataSource: rps.data.sources.ChronologicalQuerySource;
    private viewInitialised: boolean = false;
    //Variable creada para retrasar la generación de los widgets hasta que el contenedor tenga tamaño
    private createKendoWidgetsPending: boolean = false;
    private dataSourcePropertyChangedSubscription: rps.services.ISubscription;

    public selectable: boolean = false;
    public date: Date = new Date();
    public views: Array<kendo.ui.SchedulerView> = [
        {
            type: "day",
            eventTemplate: rpsScheduler.itemTemplate(false),
            allDayEventTemplate: rpsScheduler.itemTemplate(false)
        },
        {
            type: "week",
            eventTemplate: rpsScheduler.itemTemplate(false),
            allDayEventTemplate: rpsScheduler.itemTemplate(false)
        },
        {
            type: "month",
            selected: true,
            eventTemplate: rpsScheduler.itemTemplate(true),
            allDayEventTemplate: rpsScheduler.itemTemplate(false)
        },
        {
            type: "agenda",
            eventTemplate: (e: kendo.data.SchedulerEvent) => {
                //Si tiene link se crea template con link.
                if (this.rpsAdapter.allowEditItem) {
                    var template: string = ` <div class="rps-agenda-template">   
                                                <a>`+ e.title + `</a>
                                            </div>`;
                } else {
                    var template: string = ` <div class="rps-agenda-template">   
                                                `+ e.title + `
                                            </div>`;
                }
                return template;
            },      
            editable: true
        }];

    public editable: kendo.ui.SchedulerEditable = {
        confirmation: false,
        create: false,
        destroy: false,
        move: false,
        resize: false,
        update: false
    };

    public items: kendo.data.SchedulerDataSource;
    public loaded: boolean = false;

    private static itemTemplate(oneLine: boolean): string {
        var oneLineTemplate = oneLine ? "rps-agenda-item-content-one-line" : "";
        return ` 
            # if (typeof customColor != 'undefined') { #
            <div class="rps-agenda-item-container" style="background: #= customColor #; border-color: #= customColor #; color: #= calculatedForeColor #;">
            # } else { #
            <div class="rps-agenda-item-container" style="color: #= calculatedForeColor #;">
            # } #
                <div class="rps-agenda-item-content ${oneLineTemplate}">#: title # </div>
            </div>
    `}

    constructor(private elementRef: ElementRef) {       
    }

    ngAfterViewInit() {
        this.viewInitialised = true;
        this.tryCreateKendoWidgets();
    }

    ngAfterViewChecked() {
        if (this.createKendoWidgetsPending) {
            var kendoSchedulerContainer = $(this.elementRef.nativeElement).find(".kendoSchedulerContainer");
            if (kendoSchedulerContainer.outerWidth() > 0) {
                this.tryCreateKendoWidgets();
            }
        }
    }

    tryCreateKendoWidgets() {

        var kendoSchedulerContainer = $(this.elementRef.nativeElement).find(".kendoSchedulerContainer");
        if (this.kendoScheduler) {
            this.kendoScheduler.destroy();
            this.kendoScheduler = null;
            if (this.dataSource)
                this.dataSource.filter = null;
            kendoSchedulerContainer.empty();
        }

        //Sólo se crea si tiene items; si no, ya se hará en el set de los items o las columnas
        if (this.viewInitialised &&
            rps.object.hasValue(this.items)) {
            //Si el contenedor del Scheduler ya tiene tamaño, se crean los widgets
            if (kendoSchedulerContainer.outerWidth() > 0) {
                this.createKendoWidgetsPending = false;

                kendoSchedulerContainer.append("<div></div>");
                this.kendoScheduler = kendoSchedulerContainer.children().kendoScheduler(
                    {
                        dataSource: this.items,
                        views: this.views,
                        date: this.date,
                        autoBind: false,
                        editable: this.editable,
                        selectable: this.selectable,
                        add: this.add,
                        moveEnd: this.moveEnd,
                        edit: this.edit,
                        cancel: this.cancel,
                        remove: this.remove
                    }).data("kendoScheduler");

                this.kendoScheduler.wrapper.on("click", ".rps-agenda-template a", (e) => {
                    if (this.rpsAdapter.allowEditItem) {
                        var uid = $(e.currentTarget).closest(".k-task").data("uid");
                        var event = this.kendoScheduler.occurrenceByUid(uid);
                        this.kendoScheduler.editEvent(event);
                    }
                });

                kendoSchedulerContainer.kendoTooltip({
                    filter: ".k-event:not(.k-event-drag-hint) > div, .k-task",
                    position: "top",
                    width: 200,
                    
                    content: ((e) => {
                        var element = e.target.is(".k-event") || e.target.is(".k-task")  ? e.target : e.target.parent();
                        var uid = element.attr("data-uid");
                        var scheduler = e.target.closest("[data-role=scheduler]").data("kendoScheduler");
                        var model = scheduler.occurrenceByUid(uid);
                        if (model && model.model && model.model.event) {
                            let event = <rps.data.CalendarEventResult>model.model.event;
                            let template = `<div class="rps-agenda-item-tooltip">`;

                            if (!event.AllDay) {
                                template += `<div class="rps-agenda-item-hours" >${kendo.toString(event.EventStart, "H:mm")} - ${kendo.toString(event.EventEnd, "H:mm")}</div>`;
                                template += `<div class="rps-agenda-item-title">${event.EventName}</div>`;
                            }
                            else
                                template += `<div class="rps-agenda-item-content">${event.EventName}</div>`;

                            template += `</div>`;

                            return kendo.template(template);
                        }
                    })
                });

                //HACK: Parece que la vista se selecciona en asíncrono, por lo que el método de refrescar el scheduler no funciona…
                setTimeout(() => {
                    if (this.kendoScheduler)
                        this.kendoScheduler.refresh();
                });

                if (this.dataSource)
                    this.dataSource.filter = this;
            }
            else {
                this.createKendoWidgetsPending = true;
            }
        }
    }

    ngOnDestroy() {
        var kendoSchedulerContainer = $(this.elementRef.nativeElement).find(".kendoSchedulerContainer");
        if (this.kendoScheduler) {
            this.kendoScheduler.destroy();
            this.kendoScheduler = null;
            kendoSchedulerContainer.empty();
        }

        if (this.dataSource)
            this.dataSource.filter = null;

        if (this.dataSourcePropertyChangedSubscription)
            this.dataSourcePropertyChangedSubscription.unsubscribe();
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes["rpsAdapter"]) {
            var newValue: rps.data.sources.ChronologicalQuerySource = null;
            var oldValue: rps.data.sources.ChronologicalQuerySource = null;
            if (changes["rpsAdapter"].currentValue)
                newValue = changes["rpsAdapter"].currentValue.querySource;
            if (changes["rpsAdapter"].previousValue)
                oldValue = changes["rpsAdapter"].currentValue.querySource;
            this.rpsSourceChanged(newValue, oldValue);
            this.refreshEditable();
        }
    }


    public rpsSourceChanged(newValue: rps.data.sources.ChronologicalQuerySource, oldValue: rps.data.sources.ChronologicalQuerySource) {
        if (this.dataSource != newValue) {
            if (this.dataSourcePropertyChangedSubscription)
                this.dataSourcePropertyChangedSubscription.unsubscribe();
            if (oldValue)
                oldValue.filter = null;
            this.dataSource = newValue;

            if (this.dataSource) {
                if (this.kendoScheduler)
                    this.dataSource.filter = this;
                this.items = this.dataSource.dataSource;
                this.dataSourcePropertyChangedSubscription = this.dataSource.propertyChanged.subscribe((value: rps.viewmodels.properties.VMPropertyChange) => {
                    if (value.propertyName == "loaded")
                        this.loadedChanged(value.newValue);
                    else if (value.propertyName == "items") {
                        //HACK: Si el scheduler no tiene tamaño, se intenta regenerar, para que se quede pendiente de generar
                        var kendoSchedulerContainer = $(this.elementRef.nativeElement).find(".kendoSchedulerContainer");
                        if (!(kendoSchedulerContainer.outerWidth() > 0)) {
                            this.tryCreateKendoWidgets();
                        }
                    }
                });
                this.loadedChanged(this.dataSource.loaded);

                this.tryCreateKendoWidgets();
            }
            else {
                this.loaded = false;
                var newDataSource: kendo.data.SchedulerDataSource = new kendo.data.SchedulerDataSource({
                    data: []
                });
                newDataSource.online(false);
                this.items = newDataSource;
            }
        }
    }

    private refreshEditable() {
        if (this.rpsAdapter) {
            this.editable = {
                confirmation: false,
                create: this.rpsAdapter.allowCreate,
                destroy: this.rpsAdapter.allowRemoveItem,
                move: this.rpsAdapter.allowMoveItem,
                resize: false,
                update: this.rpsAdapter.allowEditItem
            }
        }
        else {
            this.editable = {
                confirmation: false,
                create: false,
                destroy: false,
                move: false,
                resize: false,
                update: false
            }
        }
    }

    public loadedChanged(newValue){
        this.loaded = newValue;
    }

    public fromDate(): Date {
        if (this.kendoScheduler && <any>this.kendoScheduler.view())
            return (<any>this.kendoScheduler.view()).startDate();
        else
            return null;
    }

    public toDate(): Date {
        if (this.kendoScheduler && <any>this.kendoScheduler.view())
            return (<any>this.kendoScheduler.view()).endDate();
        else
            return null;
    }

    public add = (e: kendo.ui.SchedulerAddEvent) => {
        e.preventDefault();
        this.rpsAdapter.createItem(e.event.start, e.event.end);
    }

    public moveEnd = (e: kendo.ui.SchedulerMoveEndEvent) => {
        e.preventDefault();
        this.rpsAdapter.moveItem(<any>e.event, e.start, e.end).then(() => {
            e.event.set("start", e.start);
            e.event.set("end", e.end);
        });
    }

    public edit = (e: kendo.ui.SchedulerEditEvent) => {
        e.preventDefault(); 
        this.rpsAdapter.editItem(<any>e.event);
    }

    public cancel = (e: kendo.ui.SchedulerCancelEvent) => {
        //HACK: Se sobrescribe el cancel, para evitar un stackOverflow del widget de kendo, 
        //al que no le encuentro el sentido…
        e.preventDefault();
    }

    public remove = (e: kendo.ui.SchedulerRemoveEvent) => {
        if (!e.event["remove"]) {
            e.preventDefault();
            this.rpsAdapter.removeItem(<any>e.event).then(() => {
                e.event["remove"] = true;
                this.kendoScheduler.removeEvent(e.event);
                }).catch(() => {
                    e.event["remove"] = false;
            });
        }
    }   
}
  