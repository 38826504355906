import {Injectable, Inject} from '@angular/core';
import { rpsEventManager } from './eventManager';
import { rpsWindowAction } from '../directives/layouts/window';

@Injectable()
export class rpsErrorManager implements rps.services.IErrorManager {
    /** @internal **/
    private handlingError: boolean = false;

    public errorDetails: rps.errors.ErrorDetails;

    //Lista de errores que se muestra en la ventana modal. Es distinta a la lista principal, para que los errores de la ventana
    //principal no se trasladen a nuevas ventanas modales (aunque los errores provocados en ventanas modales sí que se muestran en la principal)
    /** @rpsInternal **/
    public dialogErrorDetails: rps.errors.ErrorDetails;

    constructor(_rpsEventManager: rpsEventManager) {
        //Puede ser que salte antes del startup, donde se inicializa el eventManager, y como errorManagerObject usa el evenManager, lo inicializamos aquí por si acaso
        _rpsEventManager.configure();

        this.errorDetails = new rps.errors.ErrorDetails();
        this.dialogErrorDetails = new rps.errors.ErrorDetails();
    }

    /** @rpsInternal **/
    configure = () => {
        rps.app.errorManager = this;
    }

    add(errDetail: rps.errors.ErrorDetail): Array<rps.errors.ErrorDetail>;
    add(validationErrors: Array<rps.data.ValidationError>): Array<rps.errors.ErrorDetail>;
    add(errorDetails: Array<rps.errors.ErrorDetail>): Array<rps.errors.ErrorDetail>;
    add(errorDetails: rps.errors.ErrorDetails): Array<rps.errors.ErrorDetail>;        
    add(errorMessages: Array<string>): Array<rps.errors.ErrorDetail>;
    add(errorMessages: Array<rps.services.IException>): Array<rps.errors.ErrorDetail>;
    add(code: string, message: string): Array<rps.errors.ErrorDetail>;
    add(error: Error): Array<rps.errors.ErrorDetail>;
    add(serviceExceptionError: rps.services.IServiceExceptionError): Array<rps.errors.ErrorDetail>; 
    add(param: any, additionalMessage?: any): Array<rps.errors.ErrorDetail> {
        const newErrors = this.errorDetails.addError(param, additionalMessage);
        Array.prototype.push.apply(this.dialogErrorDetails, newErrors)
        return newErrors;
    }

    /** @internal **/
    remove(key: string): any {
        return null;
    }

    clear() {
        this.errorDetails.clear();
        this.dialogErrorDetails.clear();
    }

    /** @internal **/
    clearDialogErrorDetails() {
        this.dialogErrorDetails.clear();
    }

    /** @internal **/
    handleError(error: any) {
        //Si ya se está mostrando un error, no se muestra el nuevo
        if (!this.handlingError) {
            this.handlingError = true;
            //Se crea con Jquery, ya que en función del error, no funciona el framework de Angular 2…
            rps.app.api.getHTML({
                template: `<div id="errorHandlerWindow">     <div class="container-fluid">         <div>             <div class="rps-layout-padding rps-error-handler-icon">                 <i class="fa fa-3x fa-times-circle">                 </i>             </div>             <div class="rps-error-handler-text">                 <div class="rps-editor">                     <div>                         <label class="rps-label-label rps-layout-padding"                                 id="label">                         </label>                     </div>                 </div>             </div>         </div>         <div class="row">             <div class="col-md-4 col-xs-12 rps-layout-padding">                 <div id="accept"                      class="rps-button-button rps-editor-editor rps-primary">                 </div>             </div>             <div class="col-md-4 col-xs-12 rps-layout-padding">                 <div id="export"                       class="rps-button-button rps-editor-editor">                 </div>             </div>             <div class="col-md-4 col-xs-12 rps-layout-padding">                 <div id="showDetail"                      class="rps-button-button rps-editor-editor">                 </div>             </div>         </div>         <div class="row">             <div class="rps-layout-padding col-md-12 col-xs-12"                  id="errorContainer">                 <div class="rps-error-handler-content"                      id="error">                 </div>             </div>         </div>     </div> </div>`,
                urlType: rps.services.UrlType.Relative
            }).then((result) => {
                //Crear el VM, aunque no se use como tal
                var vm: errorHandlerVM = new errorHandlerVM({ error: error });

                //Calcular la posición de la ventana
                var position: kendo.ui.WindowPosition = {
                    top: rps.utils.getWindowTop(),
                    left: ($(window).width() / 4) + "px"
                };
                var windowWidth: number = ($(window).width() / 2);

                //Crear la ventana
                var kendoWindow: kendo.ui.Window = $(result).appendTo(document.body).kendoWindow({
                    actions: ["Close"],
                    title: vm.title,
                    position: position,
                    width: windowWidth,
                    close: () => {
                        this.handlingError = false;
                    },
                    maxHeight: rps.utils.getWindowMaxHeight()
                }).data("kendoWindow");

                //Añadirle el texto a la label
                kendoWindow.element.find("#label").html(vm.message);

                //Crear los botones
                kendoWindow.element.find("#export").html(vm.exportText);
                kendoWindow.element.find("#export").kendoButton({
                    click: () => {
                        vm.export();
                    }
                });
                kendoWindow.element.find("#showDetail").html(vm.showDetailText);
                kendoWindow.element.find("#showDetail").kendoButton({
                    click: () => {
                        //Mostrar y ocultar el detalle en función del VM
                        vm.showDetail = !vm.showDetail;                        
                        if (vm.showDetail) {
                            kendoWindow.element.find("#showDetail").html(vm.hideDetailText);
                            kendoWindow.element.find("#errorContainer").show();
                        }
                        else {
                            kendoWindow.element.find("#showDetail").html(vm.showDetailText);
                            kendoWindow.element.find("#errorContainer").hide();
                        }                        
                    }                    
                });
                kendoWindow.element.find("#accept").html(vm.acceptText);
                kendoWindow.element.find("#accept").kendoButton({
                    click: () => {
                        kendoWindow.close();
                    }
                });

                //Crear la sección del detalle del error (Por defecto oculta)
                kendoWindow.element.find("#error").html(vm.errorString);
                kendoWindow.element.find("#errorContainer").hide();

                //Abrir la ventana
                kendoWindow.open();
            });            
        }
    }

    /** @internal **/
    showError(errDetail: rps.errors.ErrorDetail): Array<rps.errors.ErrorDetail>;
    /** @internal **/
    showError(validationErrors: Array<rps.data.ValidationError>): Array<rps.errors.ErrorDetail>;
    /** @internal **/
    showError(errorDetails: Array<rps.errors.ErrorDetail>): Array<rps.errors.ErrorDetail>;
    /** @internal **/
    showError(errorDetails: rps.errors.ErrorDetails): Array<rps.errors.ErrorDetail>;
    /** @internal **/
    showError(errorMessages: Array<string>): Array<rps.errors.ErrorDetail>;
    /** @internal **/
    showError(errorMessages: Array<rps.services.IException>): Array<rps.errors.ErrorDetail>;
    /** @internal **/
    showError(code: string, message: string): Array<rps.errors.ErrorDetail>;
    /** @internal **/
    showError(error: Error): Array<rps.errors.ErrorDetail>;
    /** @internal **/
    showError(serviceExceptionError: rps.services.IServiceExceptionError): Array<rps.errors.ErrorDetail>;
    /** @internal **/
    showError(param: any, additionalMessage?: any): Array<rps.errors.ErrorDetail> {
        var errors = rps.errors.createErrorDetails(param, additionalMessage);
        if (errors.length > 0) {
            let errText = errors[0].description;
            if (errors[0].code) {
                errText += ' (' + errors[0].code + ')';
            }
            rps.app.messageManager.show({
                message: errText,
                messageButton: rps.services.MessageButton.Ok,
                messageType:rps.services.MessageType.Error
            });
        }
        return errors;
    }
}

class errorHandlerVM {
    public error: any;
    public title: string;
    public message: string;
    public errorString: string;
    public acceptText: string;
    public exportText: string;
    public showDetailText: string;
    public hideDetailText: string;
    public showDetail: boolean;
    constructor(params: { error: any }) {
        this.error = params.error;
        this.title = rps.app.resources.messages.MSG_UNHANDLER_EXCEPTION;
        this.message = rps.errors.getErrorString(this.error);
        this.acceptText = rps.app.resources.directives.ACCEPT;
        this.exportText = rps.app.resources.services.EXPORT;
        this.showDetailText = rps.app.resources.services.SHOW_DETAIL;
        this.hideDetailText = rps.app.resources.services.HIDE_DETAIL;
        this.showDetail = false;
        this.errorString = this.getJSON();
    }

    getJSON(): string {
        return rps.utils.serializer.serialize(this.error);
    }

    export(): Promise<any> {
        window.open("data:application/json," + encodeURIComponent(this.getJSON()), 'neuesDokument');
        return Promise.resolve<any>(this);
    }
}
