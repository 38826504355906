import {Injectable, Inject, Component, ComponentRef, ElementRef,ViewContainerRef,ViewChild   } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { rpsHubsService, IJobProgress } from './hubsService';

enum serviceStatus {
    Connecting = 1,
    Connected = 2,
    Reconnecting = 3,
    Disconnected = 4
}

@Component({
    template: `
        <div>
            <div *ngIf="dataItem?.relatedEntity">
                <div>
                    <div>
                        <div class="rps-tree-left"
                                *ngIf="dataItem.relatedEntity.Image">
                            <div class="rps-img-2">
                                <img class="rps-img"
                                        src="{{dataItem.relatedEntity.Image}}"
                                        [attr.alt]="dataItem.relatedEntity.MainDescriptor"/>
                            </div>
                        </div>
                        <div class="rps-tree-right"
                             [class.rps-tree-right-prevent-left]="dataItem.relatedEntity.Image"
                                *ngIf="dataItem.relatedEntity.SecondaryDescriptor == undefined">
                            <div *ngIf="dataItem.relatedEntity.UILink == undefined">
                                {{dataItem.relatedEntity.MainDescriptor}}
                            </div>
                            <div *ngIf="dataItem.relatedEntity.UILink != undefined">
                                <a target="_blank" [routerLink]="linkParameters" [queryParams]="linkQueryParameters">{{dataItem.relatedEntity.MainDescriptor}}</a>
                            </div>
                            <span style="white-space: pre-line;">{{dataItem.message}}</span>
                        </div>
                        <div class="rps-tree-right"
                             [class.rps-tree-right-prevent-left]="dataItem.relatedEntity.Image"
                                *ngIf="dataItem.relatedEntity.SecondaryDescriptor != undefined">
                            <div *ngIf="dataItem.relatedEntity.UILink == undefined">
                                {{dataItem.relatedEntity.MainDescriptor}} - {{dataItem.relatedEntity.SecondaryDescriptor}}
                            </div>
                            <div *ngIf="dataItem.relatedEntity.UILink != undefined">
                                <a target="_blank" [routerLink]="linkParameters" [queryParams]="linkQueryParameters">{{dataItem.relatedEntity.MainDescriptor}} - {{dataItem.relatedEntity.SecondaryDescriptor}}</a>
                            </div>
                            <span style="white-space: pre-line;">{{dataItem.message}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!dataItem.relatedEntity">
                <div *ngIf="dataItem.state == 0" style="white-space: pre-line;">
                    <i class="fa fa-check-circle rps-text-ok"></i> {{dataItem?.message}}
                </div>
                <div *ngIf="dataItem.state == 1" style="white-space: pre-line;">
                    <i class="fa fa-times-circle rps-text-error"></i> {{dataItem?.message}}
                </div>
                <div *ngIf="dataItem.state == 2" style="white-space: pre-line;">
                    {{dataItem?.message}}
                </div>
            </div>
        </div>
    `
})
export class ProcessResultItem {
    public dataItem: ProcessResultMessageVM;

    constructor() {
    }

    setProcessResultMessageVM(newValue: ProcessResultMessageVM) {
        this.dataItem = newValue;
    }

    //Hay que hacer todo esto porque el treeview clona los elementos añadiendo funciones, y el binding de routerLink no resuelve bien los href
    private _linkParameters: Array<any>;
    private _linkQueryParameters: rps.IParams;
    public get linkParameters(): Array<any> {
        if (!this._linkParameters && this.dataItem && this.dataItem.linkParameters)
            this._linkParameters = this.dataItem.linkParameters.slice();
        return this._linkParameters;
    }
    public get linkQueryParameters(): rps.IParams {
        if (!this._linkQueryParameters && this.dataItem && this.dataItem.linkQueryParameters) {
            this._linkQueryParameters = {};
            this.dataItem.linkQueryParameters.keys().forEach((key) => {
                this._linkQueryParameters[key] = this.dataItem.linkQueryParameters[key];
            });
        }
        return this._linkQueryParameters;
    }
}

@Injectable()
export class rpsProcessResultManager implements rps.services.IProcessResultManager {
    constructor(private _rpsHubsService: rpsHubsService) {


    }

    show(params: { result: rps.data.SyncProcessResult }): Promise<any> {
        return new Promise<any>((resolve: rps.async.IResolveReject<any>) => {
            var processResultVM: ProcessResultVM;
            processResultVM = new ProcessResultVM({
                resolve: resolve,
                processDescription: params.result.ProcessDescription,
                result: params.result
            });
            rps.app.uiFactory.showWindow({
                template: `<rps-window [rpsOpen]='vm.isOpen'             id="processResultWindow"             [rpsTitle]="vm.processDescription"             rpsHorizontalSize="6">     <div [hidden]="vm.result">         <rps-rows-group class="rps-loading-container">             <rps-busy-indicator [rpsIsBusy]="true">             </rps-busy-indicator>         </rps-rows-group>         <rps-rows-group>             <rps-row>                 <rps-progressbar min="0" max="100" [value]="vm.percent" [progressLabel]="vm.label"></rps-progressbar>             </rps-row>         </rps-rows-group>     </div>     <rps-rows-group [hidden]="!vm.result">         <rps-row>             <rps-label *ngIf="vm.hasErrors"                        rpsColumns="6"                        [rpsModel]="vm.message"                        class="rps-text-error"></rps-label>             <rps-label *ngIf="!vm.hasErrors"                        rpsColumns="6"                        [rpsModel]="vm.message"                        class="rps-text-ok"></rps-label>         </rps-row>         <rps-row>             <div class="rps-layout-padding"                  rpsColumns="6"                  id="kendoTreeView">             </div>         </rps-row>         <rps-row>             <rps-button rpsColumns="2"                         rpsTemplate="TEXT_WITH_ICON"                         rpsLabel="{{$root.resources.directives.OK}}"                         rpsIcon="fa fa-check"                         [rpsModel]="vm.Ok">             </rps-button>         </rps-row>         <div #dynamicContainer>         </div>     </rps-rows-group> </rps-window>`,
                viewModel: processResultVM,
                preventCloseOnNavigate: true
            });
        });
    }

    showAsync(params: { result:rps.data.AsyncProcessResult }): () => void {
        var processResultVM: ProcessResultVM;
            processResultVM = new ProcessResultVM({
                resolve: null,
                processDescription: params.result.ProcessDescription,
                idJob: params.result.IDProcessJob,
                rpsHubsService: this._rpsHubsService
            });
        rps.app.uiFactory.showWindow({
            template: `<rps-window [rpsOpen]='vm.isOpen'             id="processResultWindow"             [rpsTitle]="vm.processDescription"             rpsHorizontalSize="6">     <div [hidden]="vm.result">         <rps-rows-group class="rps-loading-container">             <rps-busy-indicator [rpsIsBusy]="true">             </rps-busy-indicator>         </rps-rows-group>         <rps-rows-group>             <rps-row>                 <rps-progressbar min="0" max="100" [value]="vm.percent" [progressLabel]="vm.label"></rps-progressbar>             </rps-row>         </rps-rows-group>     </div>     <rps-rows-group [hidden]="!vm.result">         <rps-row>             <rps-label *ngIf="vm.hasErrors"                        rpsColumns="6"                        [rpsModel]="vm.message"                        class="rps-text-error"></rps-label>             <rps-label *ngIf="!vm.hasErrors"                        rpsColumns="6"                        [rpsModel]="vm.message"                        class="rps-text-ok"></rps-label>         </rps-row>         <rps-row>             <div class="rps-layout-padding"                  rpsColumns="6"                  id="kendoTreeView">             </div>         </rps-row>         <rps-row>             <rps-button rpsColumns="2"                         rpsTemplate="TEXT_WITH_ICON"                         rpsLabel="{{$root.resources.directives.OK}}"                         rpsIcon="fa fa-check"                         [rpsModel]="vm.Ok">             </rps-button>         </rps-row>         <div #dynamicContainer>         </div>     </rps-rows-group> </rps-window>`,
            viewModel: processResultVM,
            preventCloseOnNavigate: true
            });
        return () => {
            processResultVM.setProgress("", 100);
            processResultVM.close();
        }
    }

    configure() {
        rps.app.processResultManager = this;
    }

}

class ProcessResultVM {    
    private kendoTreeView: JQuery;
    //HACK: Método que se le invoca, después de establecerse como vm, de un Componente, para modificar el DOM a tu antojo…
    afterContentInit = (component: ComponentRef<any>) => {        
        //Se guarda la referencia hacia el elemento de UI, ya que en el afterViewInit, no se encuentra...
        this.kendoTreeView = $(component.location.nativeElement).find("#kendoTreeView");
    };
    //HACK: Método que se le invoca, después de iniciar la view del componente, para poder crear componentes desde el VM
    afterViewInit = (dynamicContainer: ViewContainerRef) => {
        this.dynamicContainer = dynamicContainer;  
        
        this.kendoTreeView.kendoTreeView({
            dataSource: this.treeViewDataSource,
            dataBound: this.dataBound,
            template: "<div class='process-result-item-temp'></div>"
        });
    }    

    private dynamicContainer: ViewContainerRef;
    private resolve: rps.async.IResolveReject<any>;

    private _isOpen: boolean = false;
    public get isOpen(): boolean {
        return this._isOpen;
    }
    public set isOpen(newValue:boolean) {
        if (this._isOpen != newValue) {
            this._isOpen = newValue;
            this.isOpenChanged.emit(newValue);
        }
    }
    public isOpenChanged: rps.services.IEventEmitter<boolean>;
    public defaultCloseCommand = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: (commandParameters: any): Promise<any> => {
            return this.Ok.execute();
        }
    });
    public result: rps.data.SyncProcessResult;
    public hasErrors: boolean = false;
    public message: rps.viewmodels.properties.VMProperty<string>;
    public Ok: rps.viewmodels.commands.CommandProperty;
    public hierarchicalMessages: Array<ProcessResultMessageVM>;
    public treeViewDataSource: kendo.data.HierarchicalDataSource;
    public propertyChanged: rps.services.IEventEmitter<rps.viewmodels.properties.VMPropertyChange>;
    private idJob: string;
    public processDescription: string;
    public label: string;
    public percent: number;
    public setProgress(label: string, percent: number) {
        this.label = label;
        this.percent = percent;
    }

    constructor(params: {
        resolve: rps.async.IResolveReject<any>,
        processDescription: string,
        result?: rps.data.SyncProcessResult,
        idJob?: string,
        rpsHubsService?: rpsHubsService
    }) {
        this.processDescription = params.processDescription;
        this.isOpenChanged  = rps.app.eventManager.createEmitter<boolean>();
        this.propertyChanged = rps.app.eventManager.createEmitter<rps.viewmodels.properties.VMPropertyChange>();
        if (params.idJob && params.rpsHubsService) {
            this.idJob = params.idJob;
            params.rpsHubsService.subscribeToServiceJob(this.idJob);
            params.rpsHubsService.onJobProgress.subscribe((progress:IJobProgress) => {
                if (progress.IDJob == this.idJob)
                    this.setProgress(progress.ProgressLabel, progress.ProgressPercent);
            })
            params.rpsHubsService.onJobCompleted.subscribe((idJob: string) => {
                if (idJob == this.idJob) {
                    params.rpsHubsService.unsubscribeFromServiceJob(idJob);
                    this.close();
                }
                    
            })
        }
        else
           this.setResult(params.result);

        this.resolve = params.resolve;
        this.Ok = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<any> => {
                this.close();
                return Promise.resolve<any>(this);
            }
        });

        
    }

    setResult(result: rps.data.SyncProcessResult) {
        this.result = result;
        if (Enumerable.From<rps.data.ProcessResultDetail>(result.Details).Any(d => d.State == rps.data.ProcessDetailState.Error))
            this.hasErrors = true;
        if (this.hasErrors)
            this.message = new rps.viewmodels.properties.VMProperty<string>({
                target: this,
                initialValue: rps.app.resources.services.MSG_PROCESS_COMPLETED_WITH_ERRORS
            });
        else
            this.message = new rps.viewmodels.properties.VMProperty<string>({
                target: this,
                initialValue: rps.app.resources.services.MSG_PROCESS_COMPLETED_SUCCESSFULLY
            });
        this.fillHierarchicalMessages();
    }

    fillHierarchicalMessages() {
        this.hierarchicalMessages = new Array<ProcessResultMessageVM>();
        if (Enumerable.From<rps.data.ProcessResultDetail>(this.result.Details).Any(d => d.State == rps.data.ProcessDetailState.Error)){
            var errorsMessages = new ProcessResultMessageVM({
                message: rps.app.resources.services.PROCESS_ERRORS,
                state:1
            });
            Enumerable.From<rps.data.ProcessResultDetail>(this.result.Details).Where(d => d.State == rps.data.ProcessDetailState.Error).forEach((detail: rps.data.ProcessResultDetail) => {
                var errorMessage: ProcessResultMessageVM;
                if (rps.string.isNullOrEmpty(detail.Message))
                    errorMessage = new ProcessResultMessageVM({
                        message: rps.app.resources.services.PROCESS_ERROR,
                        relatedEntity:detail.RelatedEntity
                    });
                else
                    errorMessage = new ProcessResultMessageVM({
                        message: detail.Message,
                        relatedEntity:detail.RelatedEntity
                    });
                errorsMessages.addChild(errorMessage);
            });
            this.hierarchicalMessages.push(errorsMessages);
        }

        if (Enumerable.From<rps.data.ProcessResultDetail>(this.result.Details).Any(d => d.State == rps.data.ProcessDetailState.Ok)) {
            var okMessages = new ProcessResultMessageVM({
                message: rps.app.resources.services.PROCESS_SUCCESSFULL,
                state: 0
            });
            Enumerable.From<rps.data.ProcessResultDetail>(this.result.Details).Where(d => d.State == rps.data.ProcessDetailState.Ok).forEach((detail: rps.data.ProcessResultDetail) => {
                var okMessage: ProcessResultMessageVM;
                if (rps.string.isNullOrEmpty(detail.Message))
                    okMessage = new ProcessResultMessageVM({
                        message: rps.app.resources.services.PROCESS_SUCCESSFULL,
                        relatedEntity:detail.RelatedEntity
                    });
                else
                    okMessage = new ProcessResultMessageVM({
                        message: detail.Message,
                        relatedEntity:detail.RelatedEntity
                    });
                okMessages.addChild(okMessage);
            });
            this.hierarchicalMessages.push(okMessages);
        }

        this.treeViewDataSource = new kendo.data.HierarchicalDataSource({
            data: this.hierarchicalMessages,
            schema: {
                model: {
                    children: "messages"
                }
            }
        });
    }

    close() {
        this.isOpen = false;
        if (this.resolve)
            this.resolve(this);
    }



    dataBound = (e: kendo.ui.TreeViewDataBoundEvent) => {
        (<JQuery>(<any>e.sender).items()).find(".process-result-item-temp").each((index: number, elem: Element) => {
            var element = jQuery(elem);
            var dataItem = e.sender.dataItem(element);
            this.createItem(dataItem, element);
        });
        if (this.hasErrors)
            e.sender.expand(e.sender.findByUid(e.sender.dataSource.data()[0].uid));
    }

    createItem(dataItem: any, element: JQuery) {
        element.removeClass("process-result-item-temp");
        rps.app.uiFactory.createComponent < ProcessResultItem>(ProcessResultItem, this.dynamicContainer)
            .then((result) => {
                (<ProcessResultItem>result.instance).setProcessResultMessageVM(dataItem);
                element.append(result.location.nativeElement);
            });
    }
}

export class ProcessResultMessageVM {
    public message: string;        
    public messages: Array<ProcessResultMessageVM> = new Array<ProcessResultMessageVM>();        
    public state: number = 2;
    public relatedEntity: rps.data.Descriptor;
    public linkParameters: Array<any>;
    public linkQueryParameters: rps.collections.Dictionary<string,any>;

    constructor(params: {
        message: string,
        state?: number,
        relatedEntity?: rps.data.Descriptor
    }) {
        this.message = params.message;
        this.relatedEntity = params.relatedEntity;
        if (this.relatedEntity && this.relatedEntity.UILink && this.relatedEntity.UILink.length > 0) {
            var link = rps.app.stateManager.createRouterLink(this.relatedEntity.UILink);
            this.linkParameters = link.routerLink;
            this.linkQueryParameters = new rps.collections.Dictionary<string, any>([]);
            for (var p in link.queryParams)
                this.linkQueryParameters.add(p, link.queryParams[p]);
        }
        else
            this.linkParameters = rps.app.stateManager.notFoundLink;

        if (rps.object.hasValue(params.state))
            this.state = params.state;
    }

    public addChild(child: ProcessResultMessageVM) {
        this.messages.push(child);
    }

    public hasChildren(): boolean {
        if (this.messages.length > 0)
            return true;
        else
            return false;
    }
}
