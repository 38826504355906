import { Component, ElementRef, AfterViewInit, OnDestroy, OnChanges, SimpleChange } from '@angular/core';
import { errorDetailValidator } from '../utils/errorDetailValidator'
import { rpsToggleButton } from '../editors/toggleButton';
import { rpsDatePicker } from '../editors/datePicker';
import { rpsButton } from '../editors/button';
import { rpsRowsGroup } from '../layouts/rowsGroup';
import { rpsRow } from '../layouts/row';
import { rpsColumns } from '../layouts/columns';
import { rpsTreeList } from '../itemsControls/treeList';

export enum ganttRangeType {
    DayView, WeekView, MonthView, YearView, FillView, FillViewSelection
}
export enum ganttViewType {
    ProjectView, ResourceView, TreeListView
}
@Component({
    selector: 'rps-gantt',
    template: `
        <div class="rps-layout-padding">
            <rps-rows-group>
                <rps-row>                                       
                    <div rpsColumns="12" >
                        <div class="rps-stack-panel">
                            <div class="rps-stack-panel-item rps-editor-right-fit-container">
                                <rps-toggle-button rpsTemplate='ICON' rpsLabel='{{resources.gantt.PROJECT_VIEW}}' rpsIcon="k-icon rps-gantt-button-view-project" [rpsModel]='ganttViewProject' class="rps-button-view-gantt"></rps-toggle-button>
                            </div> 
                            <div class="rps-stack-panel-item rps-editor-right-fit-container">
                                <rps-toggle-button rpsTemplate='ICON' rpsLabel='{{resources.gantt.RESOURCE_VIEW}}' rpsIcon="k-icon rps-gantt-button-view-resource" [rpsModel]='ganttViewResource' class="rps-button-view-gantt"></rps-toggle-button>
                            </div>
                            <!--<div class="rps-stack-panel-item rps-editor-right-fit-container">
                                <rps-toggle-button rpsTemplate='ICON' rpsLabel='{{resources.gantt.TREELIST_VIEW}}' rpsIcon='fa fa-align-left' [rpsModel]='ganttViewTreelist' class="rps-button-view-gantt"></rps-toggle-button>
                            </div>  -->
                             <rps-date-picker [rpsModel]='currentDate' rpsColumns="2" ></rps-date-picker>
                            <div *ngIf="!treelist">
                                <div class="rps-stack-panel-item rps-editor-right-fit-container">
                                    <rps-toggle-button rpsTemplate='TEXT_WITH_ICON' rpsLabel='{{resources.gantt.VIEW_DAY}}' rpsIcon="k-icon rps-gantt-button-view-day" [rpsModel]='viewDay' class="rps-button-view-gantt"></rps-toggle-button>
                                </div>
                                <div class="rps-stack-panel-item rps-editor-right-fit-container">
                                    <rps-toggle-button rpsTemplate='TEXT_WITH_ICON' rpsLabel='{{resources.gantt.VIEW_WEEK}}' rpsIcon="k-icon rps-gantt-button-view-week" [rpsModel]='viewWeek' class="rps-button-view-gantt"></rps-toggle-button>
                                </div>
                                <div class="rps-stack-panel-item rps-editor-right-fit-container">
                                    <rps-toggle-button rpsTemplate='TEXT_WITH_ICON' rpsLabel='{{resources.gantt.VIEW_MONTH}}' rpsIcon="k-icon rps-gantt-button-view-month" [rpsModel]='viewMonth' class="rps-button-view-gantt"></rps-toggle-button>
                                </div>
                                <div class="rps-stack-panel-item rps-editor-right-fit-container" *ngIf="theGantt">
                                    <rps-toggle-button rpsTemplate='TEXT_WITH_ICON' rpsLabel='{{resources.gantt.VIEW_YEAR}}' rpsIcon="k-icon rps-gantt-button-view-year" [rpsModel]='viewYear' class="rps-button-view-gantt"></rps-toggle-button>
                                </div>
                                <div class="rps-stack-panel-item rps-editor-right-fit-container" *ngIf="theGantt">
                                    <rps-toggle-button rpsTemplate='TEXT_WITH_ICON' rpsLabel='{{resources.gantt.FIT_ALL}}' rpsIcon="k-icon rps-gantt-button-fit-all" [rpsModel]='viewFill' class="rps-button-fill-gantt"></rps-toggle-button>                           
                                </div>
                                <div class="rps-stack-panel-item rps-editor-right-fit-container" *ngIf="theGantt">
                                    <rps-toggle-button rpsTemplate='TEXT_WITH_ICON' rpsLabel='{{resources.gantt.FIT_SELECTION}}' rpsIcon="k-icon rps-gantt-button-fit-selection" [rpsModel]='viewFillSelection' class="rps-button-fill-gantt"></rps-toggle-button>                           
                                </div>
                                <div class="rps-stack-panel-item rps-editor-right-fit-container" *ngIf="theScheduler">
                                    <rps-button rpsTemplate='TEXT_WITH_ICON' rpsLabel='{{resources.directives.DELETE}}' rpsIcon='fa fa-trash-o' [rpsModel]='btnDelete'></rps-button>
                                </div>
                                <div class="rps-stack-panel-item rps-editor-right-fit-container" *ngIf="theGantt">
                                    <rps-button rpsTemplate="ICON" [rpsModel]='btnExpanded' rpsLabel='{{resources.directives.EXPAND}}' rpsIcon="k-icon rps-gantt-button-expand-all"></rps-button>
                                </div>
                                <div class="rps-stack-panel-item rps-editor-right-fit-container" *ngIf="theGantt">
                                    <rps-button rpsTemplate="ICON" [rpsModel]='btnCollapse' rpsLabel='{{resources.directives.COLLAPSE}}' rpsIcon="k-icon rps-gantt-button-collapse-all"></rps-button>
                                </div>
                                <div class="rps-stack-panel-item rps-editor-right-fit-container">
                                    <rps-button rpsTemplate="K-ICON" [rpsModel]='btnPrevious' rpsLabel='{{resources.directives.PREVIOUS}}' rpsIcon="arrow-w"></rps-button>
                                </div>
                                <div class="rps-stack-panel-item rps-editor-right-fit-container">
                                    <rps-button rpsTemplate="K-ICON" [rpsModel]='btnNext' rpsLabel='{{resources.directives.NEXT}}' rpsIcon="arrow-e"></rps-button>
                                </div>
                            </div>
                        </div>
                    </div>     

                </rps-row>
            </rps-rows-group>            
            <div id="kendoGanttContainer" (window:resize)="onResize($event)" (mouseleave)="onMouseLeave()">
                <div>                    
                </div>               
            </div>
                    
 
        </div>
  `,
    inputs: ['rpsAdapter']
})


export class rpsGantt implements AfterViewInit, OnDestroy, OnChanges {

    private theGantt: kendo.ui.Gantt;
    private theScheduler: kendo.ui.Scheduler;
    private treelist: kendo.ui.TreeList;
    private treeView: kendo.ui.TreeView;

    public ganttOptions: kendo.ui.GanttOptions;
    public schedulerOptions: kendo.ui.SchedulerOptions;
    public treelistOptions: kendo.ui.TreeListOptions;
    public treeviewOptions: kendo.ui.TreeViewOptions;

    private schedulerTooltip: kendo.ui.Tooltip;

    private tasksDataSource: kendo.data.GanttDataSource;
    private schedulerDataSource: kendo.data.SchedulerDataSource;
    private treelistDataSource: kendo.data.TreeListDataSource;

    public rpsAdapter: rps.viewmodels.adapters.GanttAdapterBase;

    public slotSize = 100;
    public timelineWidth = 800;
    private isRefreshing = false;

    public dinamycColumnWidth = 50;

    public columnWidthScheduler = 100;

    public initGanttStart: Date;
    public initGanttEnd: Date;
    public ganttStart: Date;
    public ganttEnd: Date;

    public schedulerStart: Date;
    public schedulerEnd: Date;

    public tasksExpanded: Array<rps.viewmodels.adapters.enumTaskExpanded> = [];
    public taskSelectedUID: string;

    public currentView: ganttRangeType;

    private refreshTimer: NodeJS.Timer;

    public btnDelete: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: this.btnDeleteExecute,
        canExecute: this.btnDeleteCanExecute
    });
    public btnPrevious: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: this.btnNavigatePreviousExecute,
        canExecute: this.btnNavigateCanExecute
    });
    public btnNext: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: this.btnNavigateNextExecute,
        canExecute: this.btnNavigateCanExecute
    });
    public btnExpanded: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: this.btnExpandedExecute,
        canExecute: this.btnCollapseCanExecute
    });
    public btnCollapse: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: this.btnCollapseExecute,
        canExecute: this.btnCollapseCanExecute
    });
    public viewType: rps.viewmodels.properties.EnumProperty = new rps.viewmodels.properties.EnumProperty({ target: this, enumItems: [{ value: 0, description: rps.app.resources.directives.PROJECT_VIEW }, { value: 1, description: rps.app.resources.directives.RESOURCE_VIEW }, { value: 2, description: rps.app.resources.directives.TREELIST_VIEW }, { value: 3, description: 'FinalConsumer' }, { value: 4, description: 'Other' }], initialValue: 1 });

    constructor(private elementRef: ElementRef) {
        this.configureKendoGantt();
        this.configureKendoScheduler();
        this.setWidgetOptions();
        this.currentDate = new rps.viewmodels.properties.VMProperty<Date>({ target: this });
        this.currentDate.propertyChanged.subscribe((e: rps.viewmodels.properties.VMPropertyChange) => {
            if (e.propertyName == "value") {
                this.setCurrentDate();
            }
        });
        this.viewDay = new rps.viewmodels.properties.VMProperty<boolean>({ target: this });
        this.viewDay.propertyChanged.subscribe((e: rps.viewmodels.properties.VMPropertyChange) => {
            if (e.propertyName == "value") {
                if (this.viewDay.value) {
                    this.setCurrentView(ganttRangeType.DayView);
                } else {
                    setTimeout(() => {
                        if (this.currentView == ganttRangeType.DayView)
                            this.viewDay.value = true;
                    });
                }
            }
        });
        this.viewWeek = new rps.viewmodels.properties.VMProperty<boolean>({ target: this });
        this.viewWeek.propertyChanged.subscribe((e: rps.viewmodels.properties.VMPropertyChange) => {
            if (e.propertyName == "value") {
                if (this.viewWeek.value) {
                    this.setCurrentView(ganttRangeType.WeekView);
                } else {
                    setTimeout(() => {
                        if (this.currentView == ganttRangeType.WeekView)
                            this.viewWeek.value = true;
                    });
                }
            }
        });
        this.viewMonth = new rps.viewmodels.properties.VMProperty<boolean>({ target: this });
        this.viewMonth.propertyChanged.subscribe((e: rps.viewmodels.properties.VMPropertyChange) => {
            if (e.propertyName == "value") {
                if (this.viewMonth.value) {
                    this.setCurrentView(ganttRangeType.MonthView);
                } else {
                    setTimeout(() => {
                        if (this.currentView == ganttRangeType.MonthView)
                            this.viewMonth.value = true;
                    });
                }
            }
        });
        this.viewYear = new rps.viewmodels.properties.VMProperty<boolean>({ target: this });
        this.viewYear.propertyChanged.subscribe((e: rps.viewmodels.properties.VMPropertyChange) => {
            if (e.propertyName == "value") {
                if (this.viewYear.value) {
                    this.setCurrentView(ganttRangeType.YearView);
                } else {
                    setTimeout(() => {
                        if (this.currentView == ganttRangeType.YearView)
                            this.viewYear.value = true;
                    });
                }
            }
        });
        this.viewFill = new rps.viewmodels.properties.VMProperty<boolean>({ target: this, initialValue: true });
        this.viewFill.propertyChanged.subscribe((e: rps.viewmodels.properties.VMPropertyChange) => {                     
            if (e.propertyName == "value") {                  
                if (this.viewFill.value) {
                    this.setCurrentView(ganttRangeType.FillView);                   
                } else  {   
                    setTimeout(() => {
                        if (this.currentView == ganttRangeType.FillView)
                            this.viewFill.value = true;
                    });                   
                }                
            }
        });
        this.viewFillSelection = new rps.viewmodels.properties.VMProperty<boolean>({ target: this });
        this.viewFillSelection.propertyChanged.subscribe((e: rps.viewmodels.properties.VMPropertyChange) => {
            if (e.propertyName == "value") {
                if (this.viewFillSelection.value) {
                    this.setCurrentView(ganttRangeType.FillViewSelection);
                } else {
                    setTimeout(() => {
                        if (this.currentView == ganttRangeType.FillViewSelection)
                            this.viewFillSelection.value = true;
                    });
                }
            }

        });
        this.ganttViewProject = new rps.viewmodels.properties.VMProperty<boolean>({ target: this });
        this.ganttViewProject.propertyChanged.subscribe((e: rps.viewmodels.properties.VMPropertyChange) => {
            if (e.propertyName == "value") {
                if (this.ganttViewProject.value) {                   
                    this.ganttViewResource.value = false;
                    this.ganttViewTreelist.value = false;
                    this.rpsAdapter.viewType.value = 0;               
                }
            }

        });
        this.ganttViewResource = new rps.viewmodels.properties.VMProperty<boolean>({ target: this });
        this.ganttViewResource.propertyChanged.subscribe((e: rps.viewmodels.properties.VMPropertyChange) => {
            if (e.propertyName == "value") {
                if (this.ganttViewResource.value) {
                    if (this.viewFill.value || this.viewFillSelection.value) {
                        this.viewWeek.value = true;
                        this.viewFill.value = false;
                        this.viewFillSelection.value = false;
                    }    
                    this.ganttViewProject.value = false;
                    this.ganttViewTreelist.value = false;
                    this.rpsAdapter.viewType.value = 1;
                       
                }
            }               
        });
        this.ganttViewTreelist = new rps.viewmodels.properties.VMProperty<boolean>({ target: this });
        this.ganttViewTreelist.propertyChanged.subscribe((e: rps.viewmodels.properties.VMPropertyChange) => {

            if (e.propertyName == "value") {
                if (this.ganttViewTreelist.value) {
                    this.ganttViewProject.value = false;
                    this.ganttViewResource.value = false;
                    this.rpsAdapter.viewType.value = 2;
                }
            }

        });
    }

    ngAfterViewInit() {
        if (this.theGantt)
            this.refreshSlotBind();
        /*guardar en array las tareas expandidas cuando se pulse el boton de expandir*/
        /*No uso el evento Click porque tiene prevent default desde kendo*/

        $("#kendoGanttContainer").on("mousedown", ".k-treelist span.k-i-collapse", (e) => {
            var dataItem = this.theGantt.dataSource.getByUid($(e.currentTarget).parents(".k-treelist-group").attr("data-uid"));
            this.tasksExpanded.forEach((e, index) => {
                if (e.id == dataItem.id) {
                    this.tasksExpanded.splice(index, 1);
                }
            });
        });
        $("#kendoGanttContainer").on("mousedown", ".k-treelist span.k-i-expand", (e) => {
            var dataItem = this.theGantt.dataSource.getByUid($(e.currentTarget).parents(".k-treelist-group").attr("data-uid"));
            this.tasksExpanded.push({ expanded: true, id: dataItem.id });

        });
        if (this.rpsAdapter.viewType.value == rps.viewmodels.adapters.ganttViewTypes.resourceView) {
            //En recurso por defecto la vista semanal
            this.viewWeek.value = true;
            this.viewFill.value = false;
        }
    }
    refreshSchedulerOptions() {
        if (this.viewDay.value)
            this.dinamycColumnWidth = $(this.theScheduler.element).find(".k-scheduler-header-wrap").width() / 24;
        else if (this.viewWeek.value)
            this.dinamycColumnWidth = $(this.theScheduler.element).find(".k-scheduler-header-wrap").width() / 7;
        else if (this.viewMonth.value)
            this.dinamycColumnWidth = $(this.theScheduler.element).find(".k-scheduler-header-wrap").width() / 31;
        else if (this.viewYear.value)
            this.dinamycColumnWidth = $(this.theScheduler.element).find(".k-scheduler-header-wrap").width() / 12;
        this.setWidgetOptions();
        this.rebindData();

    }
    refreshSlotBind() {
        this.dynamycSlotSize();
        this.setWidgetOptions();
        this.rebindData();
    }

    ngOnDestroy() {
        if (this.refreshTimer)
            clearInterval(this.refreshTimer);
    }

    onResize(event) {
        this.refreshSlotBind();
    }

    btnDeleteExecute(): Promise<rpsGantt> {
        var arr = this.getSchedulerSelectedItems();
        this.rpsAdapter.onTaskDeleteSelected(arr).then((result: boolean) => {
            if (result) {
                this.theScheduler.dataSource.read();
                this.theScheduler.refresh();
            }
        }).catch((error) => {
        });
        return Promise.resolve<rpsGantt>(this);
    }
    btnDeleteCanExecute(): rps.viewmodels.commands.CanExecuteResult {
        var arr = this.getSchedulerSelectedItems();
        if (arr.length > 0) {
            return rps.viewmodels.commands.CanExecuteResult.allow();
        } else {
            return rps.viewmodels.commands.CanExecuteResult.deny(["No hay seleccionada ninguna tarea"]);
        }
    }
    btnNavigateNextExecute(): Promise<rpsGantt> {
        /*Navegacion de la vista a el intervalo siguiente, depende de el tipo de vista actual.*/
        if (this.theGantt) {
            if (this.viewDay.value)
                this.ganttStart.setDate(this.ganttStart.getDate() + 1);
            else if (this.viewWeek.value)
                this.ganttStart.setDate(this.ganttStart.getDate() + 7);
            else if (this.viewMonth.value)
                this.ganttStart.setMonth(this.ganttStart.getMonth() + 1);
            else if (this.viewYear.value)
                this.ganttStart.setFullYear(this.ganttStart.getFullYear() + 1);
            this.refreshSlotBind();
            return Promise.resolve<rpsGantt>(this);
        } else if (this.theScheduler) {
            if (this.viewDay.value)
                this.schedulerStart.setDate(this.schedulerStart.getDate() + 1);
            else if (this.viewWeek.value)
                this.schedulerStart.setDate(this.schedulerStart.getDate() + 7);
            else if (this.viewMonth.value)
                this.schedulerStart.setMonth(this.schedulerStart.getMonth() + 1);
            else if (this.viewYear.value)
                this.schedulerStart.setFullYear(this.schedulerStart.getFullYear() + 1);
            this.refreshSchedulerOptions();
            return Promise.resolve<rpsGantt>(this);
        } else {
            return Promise.resolve<rpsGantt>(this);
        }

    }
    btnNavigatePreviousExecute(): Promise<rpsGantt> {
        if (this.theGantt) {
            if (this.viewDay.value) {
                this.ganttStart.setDate(this.ganttStart.getDate() - 1);
            } else if (this.viewWeek.value) {
                this.ganttStart.setDate(this.ganttStart.getDate() - 6);
            } else if (this.viewMonth.value) {
                this.ganttStart.setMonth(this.ganttStart.getMonth() - 1);
            } else if (this.viewYear.value) {
                this.ganttStart.setFullYear(this.ganttStart.getFullYear() - 1);
            }
            this.refreshSlotBind();
            return Promise.resolve<rpsGantt>(this);
        } else if (this.theScheduler) {
            if (this.viewDay.value) {
                this.schedulerStart.setDate(this.schedulerStart.getDate() - 1);
            } else if (this.viewWeek.value) {
                this.schedulerStart.setDate(this.schedulerStart.getDate() - 6);
            } else if (this.viewMonth.value) {
                this.schedulerStart.setMonth(this.schedulerStart.getMonth() - 1);
            } else if (this.viewYear.value) {
                this.schedulerStart.setFullYear(this.schedulerStart.getFullYear() - 1);
            }
            this.refreshSchedulerOptions();
            return Promise.resolve<rpsGantt>(this);
        } else {
            return Promise.resolve<rpsGantt>(this);
        }
    }
    btnNavigateCanExecute(): rps.viewmodels.commands.CanExecuteResult {
        if (!this.viewFill.value && !this.viewFillSelection.value) {
            return rps.viewmodels.commands.CanExecuteResult.allow();
        } else {
            return rps.viewmodels.commands.CanExecuteResult.deny(null);
        }
    }
    btnCollapseExecute(): Promise<rpsGantt> {
        this.expandOrCollapse(false);
        return Promise.resolve<rpsGantt>(this);
    }
    btnExpandedExecute(): Promise<rpsGantt> {
        this.expandOrCollapse(true);
        return Promise.resolve<rpsGantt>(this);
    }
    btnCollapseCanExecute(): rps.viewmodels.commands.CanExecuteResult {
        if (this.theGantt)
            return rps.viewmodels.commands.CanExecuteResult.allow();
        else
            return rps.viewmodels.commands.CanExecuteResult.deny(null);
    }
    expandOrCollapse(value: boolean) {
        if (this.theGantt) {
            this.tasksExpanded = [];
            this.theGantt.element.find(".k-task").each((e) => {
                var dataitem = this.theGantt.dataSource.getByUid(this.theGantt.element.find(".k-task").eq(e).attr("data-uid"));
                if (value == true)
                    this.tasksExpanded.push({ id: dataitem.id, expanded: value });
                dataitem["expanded"] = value;


            });
            this.theGantt.refresh();
        }

    }
    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes["rpsAdapter"]) {
            this.rpsAdapterChanged(this.rpsAdapter);
        }
    }

    configureKendoGantt() {

        var oldGantt = kendo.ui.Gantt;
        var GanttExtended = kendo.ui.Gantt.extend({
            init: function (element, options) {
                oldGantt.fn.init.call(this, element, options);
                this.startDate = options.startDate || new Date();
                this.endDate = options.endDate || new Date(new Date().setDate((new Date()).getDate() + 1));
            },
            //Eliminar el footer del gantt
            _footer: function () {
                this.footer = $([]);
            },
            //Eliminar el toolbar del gantt
            _toolbar: function () {
                this.toolbar = $([]);
            },

            startDate: Date,

            endDate: Date,

        });

        kendo.ui.plugin(GanttExtended);
        (<any>kendo.ui).rpsGanttDayView = (<any>kendo.ui).GanttWeekView.extend({
            name: "rpsDay",
            options: {
                dayTemplate: kendo.template('#=kendo.toString(start, \'dddd dd MMMM yyyy\')#'),
                hourTemplate: kendo.template('#=kendo.toString(start, \'t\')#')
            },
            range: function (range) {
                this.start = this.options.startDate;
                this.end = this.options.endDate;
            },
            _generateSlots: function (incrementCallback, span) {
                var slots = [];
                var slotStart = new Date(this.start);
                var slotEnd;
                while (slotStart < this.end) {
                    slotEnd = new Date(slotStart.toString());
                    incrementCallback(slotEnd);
                    slots.push({ start: slotStart, end: slotEnd, span: span });
                    slotStart = slotEnd;
                }
                return slots;
            },
            _createSlots: function () {
                var slots = [];
                slots.push(this._generateSlots(function (date) { date.setHours(date.getHours() + 24); }, 24));
                slots.push(this._generateSlots(function (date) { date.setHours(date.getHours() + 1); }, 1));
                return slots;
            },
            _layout: function () {
                var rows = [];
                var options = this.options;
                rows.push(this._slotHeaders(this._slots[0], kendo.template(options.dayTemplate)));
                rows.push(this._slotHeaders(this._slots[1], kendo.template(options.hourTemplate)));
                return rows;
            }
        });
        (<any>kendo.ui).rpsGanttWeekView = (<any>kendo.ui).GanttView.extend({
            name: "rpsWeek",
            options: {
                dayHeaderTemplate: kendo.template('#=kendo.toString(start, \'ddd M/dd\')#'),
                weekHeaderTemplate: kendo.template('#=kendo.toString(start, \'dd MMMM\')# - #=kendo.toString(kendo.date.addDays(end, -1), \'dd MMMM\')# '),
            },
            range: function (range) {
                this.start = this.options.startDate;
                this.end = this.options.endDate;


            },
            _generateSlots: function (incrementCallback, span) {
                var slots = [];
                var slotStart = new Date(this.start);
                var slotEnd;
                while (slotStart < this.end) {
                    slotEnd = new Date(slotStart.toString());
                    incrementCallback(slotEnd);
                    slots.push({ start: slotStart, end: slotEnd, span: span });
                    slotStart = slotEnd;
                }
                return slots;
            },
            _createSlots: function () {
                var slots = [];
                slots.push(this._generateSlots(function (date) { date.setDate(date.getDate() + 7); }, 7));
                slots.push(this._generateSlots(function (date) { date.setDate(date.getDate() + 1); }, 1));
                return slots;
            },
            _layout: function () {
                var rows = [];
                var options = this.options;
                rows.push(this._slotHeaders(this._slots[0], kendo.template(options.weekHeaderTemplate)));
                rows.push(this._slotHeaders(this._slots[1], kendo.template(options.dayHeaderTemplate)));
                return rows;
            }
        });

        (<any>kendo.ui).rpsGanttMonthView = (<any>kendo.ui).GanttMonthView.extend({
            name: "rpsMonth",
            options: {
                weekHeaderTemplate: kendo.template('#=kendo.toString(start, "dd")#'),
                monthHeaderTemplate: kendo.template('#=kendo.toString(start, \'MMMM yyyy\')#'),
                resizeTooltipFormat: 'dddd, MMM d, yyyy'
            },
            range: function (range) {
                this.start = this.options.startDate;
                var fechafin: Date = new Date(this.options.endDate.toString());
                this.end = new Date(fechafin.getFullYear(), fechafin.getMonth() + 1, 1, 0, 0);
            },
            _generateSlots: function (incrementCallback, span) {
                var slots = [];
                var slotStart = new Date(this.start);
                var slotEnd;
                while (slotStart < this.end) {
                    slotEnd = new Date(slotStart.toString());
                    incrementCallback(slotEnd);
                    slots.push({ start: slotStart, end: slotEnd, span: span });
                    slotStart = slotEnd;
                }
                return slots;
            },
            _createSlots: function () {
                var slots = [];

                slots.push(this._generateSlots(function (date) { date.setDate(date.getDate() + new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()); }, new Date(this.start.getFullYear(), this.start.getMonth() + 1, 0).getDate()));
                slots.push(this._generateSlots(function (date) { date.setDate(date.getDate() + 1); }, 1));
                return slots;
            },
            _layout: function () {
                var rows = [];
                var options = this.options;
                rows.push(this._slotHeaders(this._slots[0], kendo.template(options.monthHeaderTemplate)));
                rows.push(this._slotHeaders(this._slots[1], kendo.template(options.weekHeaderTemplate)));
                return rows;
            }
        });
        (<any>kendo.ui).rpsGanttYearView = (<any>kendo.ui).GanttYearView.extend({
            name: "rpsYear",
            options: {
                yearHeaderTemplate: kendo.template('#=kendo.toString(start, \'yyyy\')#'),
                monthHeaderTemplate: kendo.template('#=kendo.toString(start, \'MMM\')#'),
                resizeTooltipFormat: 'dddd, MMM d, yyyy'
            },
            range: function (range) {
                this.start = this.options.startDate;
                this.end = this.options.endDate;
            },
            _generateSlots: function (incrementCallback, span) {
                var slots = [];
                var slotStart = new Date(this.start);
                var slotEnd;
                while (slotStart < this.end) {
                    slotEnd = new Date(slotStart.toString());
                    incrementCallback(slotEnd);
                    slots.push({ start: slotStart, end: slotEnd, span: span });
                    slotStart = slotEnd;
                }
                return slots;
            },
            _createSlots: function () {
                var slots = [];

                slots.push(this._generateSlots(function (date) { date.setMonth(date.getMonth() + 12); }, 12));
                slots.push(this._generateSlots(function (date) { date.setMonth(date.getMonth() + 1); }, 1));
                return slots;
            },
            _layout: function () {
                var rows = [];
                var options = this.options;
                rows.push(this._slotHeaders(this._slots[0], kendo.template(options.yearHeaderTemplate)));
                rows.push(this._slotHeaders(this._slots[1], kendo.template(options.monthHeaderTemplate)));
                return rows;
            }
        });

        (<any>kendo.ui).rpsGanttDayViewFill = (<any>kendo.ui).GanttView.extend({
            name: "rpsDayFill",
            options: {
                dayTemplate: kendo.template('#=kendo.toString(start, \'dddd dd MMMM yyyy\')#'),
                hourTemplate: kendo.template('#=kendo.toString(start, \'t\')#')
            },
            range: function (range) {
                this.start = this.options.startDate;
                this.end = this.options.endDate;
            },
            _generateSlots: function (incrementCallback, span) {
                var slots = [];
                var slotStart = new Date(this.start);
                var slotEnd;
                while (slotStart < this.end) {
                    slotEnd = new Date(slotStart.toString());
                    incrementCallback(slotEnd);
                    slots.push({ start: slotStart, end: slotEnd, span: span });
                    slotStart = slotEnd;
                }
                return slots;
            },
            _createSlots: function () {
                var slots = [];
                var hours = Math.abs(this.end.getTime() - this.start.getTime()) / 3600000;
                hours = Math.ceil(hours);
                if (hours < 1) {
                    hours = 1;
                }
                var start = this.start;
                var end = this.end;
                slots.push(this._generateSlots(function (date) { date.setHours(date.getHours() + hours); }, hours));
                slots.push(this._generateSlots(function (date) { date.setHours(date.getHours() + 1); }, 1));
                return slots;
            },
            _layout: function () {
                var rows = [];
                var options = this.options;
                rows.push(this._slotHeaders(this._slots[0], kendo.template(options.dayTemplate)));
                rows.push(this._slotHeaders(this._slots[1], kendo.template(options.hourTemplate)));
                return rows;
            }
        });
        (<any>kendo.ui).rpsGanttWeekViewFill = (<any>kendo.ui).GanttWeekView.extend({
            name: "rpsWeekFill",
            options: {
                dayHeaderTemplate: kendo.template('#=kendo.toString(start, \'ddd M/dd\')#'),
                weekHeaderTemplate: kendo.template('#=kendo.toString(start, \'dd MMMM\')# - #=kendo.toString(kendo.date.addDays(end, -1), \'dd MMMM\')# '),
            },
            range: function (range) {
                this.start = this.options.startDate;
                this.end = this.options.endDate;
            },
            _generateSlots: function (incrementCallback, span) {
                var slots = [];
                var slotStart = new Date(this.start);
                var slotEnd;
                while (slotStart < this.end) {
                    slotEnd = new Date(slotStart.toString());
                    incrementCallback(slotEnd);
                    slots.push({ start: slotStart, end: slotEnd, span: span });
                    slotStart = slotEnd;
                }
                return slots;
            },
            _createSlots: function () {
                var slots = [];
                var oneDay = 24 * 60 * 60 * 1000;
                var firstDate = this.start;
                var secondDate = this.end;
                var diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
                slots.push(this._generateSlots(function (date) { date.setDate(date.getDate() + diffDays); }, diffDays));
                slots.push(this._generateSlots(function (date) { date.setDate(date.getDate() + 1); }, 1));
                return slots;
            },
            _layout: function () {
                var rows = [];
                var options = this.options;
                rows.push(this._slotHeaders(this._slots[0], kendo.template(options.weekHeaderTemplate)));
                rows.push(this._slotHeaders(this._slots[1], kendo.template(options.dayHeaderTemplate)));
                return rows;
            }
        });

        (<any>kendo.ui).rpsGanttMonthViewFill = (<any>kendo.ui).GanttMonthView.extend({
            name: "rpsMonthFill",
            options: {
                weekHeaderTemplate: kendo.template('#=kendo.toString(start, \'ddd d\')# / #=kendo.toString(kendo.date.addDays(end, -1), \'ddd d\')# '),
                monthHeaderTemplate: kendo.template('#=kendo.toString(start, \'dd MMMM yyyy\')# - #=kendo.toString(kendo.date.addDays(end, -1), \'dd MMMM yyyy\')# '),
                resizeTooltipFormat: 'dddd, MMM d, yyyy'
            },
            range: function (range) {
                var startOpt = new Date(this.options.startDate.toString());
                var endOpt = new Date(this.options.endDate.toString());
                var firstStart = startOpt.getDate() - startOpt.getDay() + 1;

                var firstEnd = endOpt.getDate() - endOpt.getDay() + 1;
                var lastEnd = firstEnd + 6;

                this.start = new Date(startOpt.setDate(firstStart));
                this.end = new Date(endOpt.setDate(lastEnd));
            },
            _generateSlots: function (incrementCallback, span) {
                var slots = [];
                var slotStart = new Date(this.start);
                var slotEnd;
                while (slotStart < this.end) {
                    slotEnd = new Date(slotStart.toString());
                    incrementCallback(slotEnd);
                    slots.push({ start: slotStart, end: slotEnd, span: span });
                    slotStart = slotEnd;
                }
                return slots;
            },
            _createSlots: function () {
                var slots = [];
                var oneDay = 24 * 60 * 60 * 1000;
                var firstDate = this.start;
                var secondDate = this.end;
                var diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
                var semanas = diffDays / 7;
                slots.push(this._generateSlots(function (date) { date.setDate(date.getDate() + diffDays); }, diffDays));
                slots.push(this._generateSlots(function (date) { date.setDate(date.getDate() + 7); }, 7));
                return slots;
            },
            _layout: function () {
                var rows = [];
                var options = this.options;
                rows.push(this._slotHeaders(this._slots[0], kendo.template(options.monthHeaderTemplate)));
                rows.push(this._slotHeaders(this._slots[1], kendo.template(options.weekHeaderTemplate)));
                return rows;
            }
        });
        (<any>kendo.ui).rpsGanttYearViewFill = (<any>kendo.ui).GanttYearView.extend({
            name: "rpsYearFill",
            options: {
                yearHeaderTemplate: kendo.template('#=kendo.toString(start, \'yyyy\')# - #=kendo.toString(end, \'yyyy\')#'),
                monthHeaderTemplate: kendo.template('#=kendo.toString(start, \'MMM\')#'),
                resizeTooltipFormat: 'dddd, MMM d, yyyy'
            },
            range: function (range) {
                this.start = this.options.startDate;
                this.end = this.options.endDate;
            },
            _generateSlots: function (incrementCallback, span) {
                var slots = [];
                var slotStart = new Date(this.start);
                var slotEnd;
                while (slotStart < this.end) {
                    slotEnd = new Date(slotStart.toString());
                    incrementCallback(slotEnd);
                    slots.push({ start: slotStart, end: slotEnd, span: span });
                    slotStart = slotEnd;
                }
                return slots;
            },
            _createSlots: function () {
                var slots = [];
                var months;
                months = (this.end.getFullYear() - this.start.getFullYear()) * 12;

                var result = months + 1;
                if (result < 1) {
                    result = 1;
                }
                slots.push(this._generateSlots(function (date) { date.setMonth(date.getMonth() + result); }, result));
                slots.push(this._generateSlots(function (date) { date.setMonth(date.getMonth() + 1); }, 1));
                return slots;
            },
            _layout: function () {
                var rows = [];
                var options = this.options;
                rows.push(this._slotHeaders(this._slots[0], kendo.template(options.yearHeaderTemplate)));
                rows.push(this._slotHeaders(this._slots[1], kendo.template(options.monthHeaderTemplate)));
                return rows;
            }
        });


        (<any>kendo.ui).GanttCustomView = (<any>kendo.ui).GanttView.extend({
            name: "custom",
            init: function (element, options) {
                (<any>kendo.ui).GanttView.fn.init.call(this, element, options);

                var taskContainer = this.element.first().find(".k-grid-content");
                taskContainer.on("drop", (event) => {
                    if (this._timeByPosition) {
                        //Calcular la fecha en la que hace drop
                        var dropDate = this._timeByPosition(event.originalEvent.clientX, 1);
                        //Calcular la fila sobre la que hace drop (con el orderId de la tarea relacionada)
                        var dropTarget = $(event.target).find("div[data-uid]");
                        if (dropTarget.length > 0) {
                            var dataUid = dropTarget.attr("data-uid");
                            if (dataUid && this.options.dataSource && this.options.dataSource.getByUid) {
                                var dropTask = this.options.dataSource.getByUid(dataUid);
                                if (dropTask) {                   
                                    alert("TODO: Añadir tarea en " + dropDate.toString() + " calculando también orderId y el parentId");
                                }
                            }
                        }
                    }
                });
                taskContainer.on("dragover", this.allowDrop);
            },

            options: {
                dayHeaderTemplate: kendo.template("#=kendo.toString(start, 'D')#"),
                yearHeaderTemplate: kendo.template("#=kendo.toString(start, 'yyyy')#"),
                quarterHeaderTemplate: kendo.template("# return ['Q1', 'Q2', 'Q3', 'Q4'][start.getMonth() / 3] #"),
                monthHeaderTemplate: kendo.template("#=kendo.toString(start, 'MMM')#")
            },
            drop(event) {                
            },
            allowDrop(ev) {
                ev.preventDefault();
            },
            range: function (range) {
                this.start = this.options.startDate;
                this.end = this.options.endDate;
            },

            _generateSlots: function (incrementCallback, span) {
                if (span == 1) {
                    var tasksWidth: number = this.element.first().find(".k-grid-content").outerWidth(true);
                    var visibleSlots = tasksWidth / this.options.slotSize;
                    var totalMilliseconds = Math.abs(this.end - this.start);
                    var millisecondsPerSlot = totalMilliseconds / visibleSlots;
                    var slots = [];
                    var slotStart = new Date(this.start);
                    var slotEnd;
                    while (slotStart < this.end) {
                        slotEnd = new Date();
                        slotEnd.setTime(slotStart.getTime() + millisecondsPerSlot);
                        slots.push({ start: slotStart, end: slotEnd, span: span });

                        slotStart = slotEnd;
                    }
                }
                else {
                    var slots = [];
                    var slotStart = new Date(this.start);
                    var slotEnd;
                    while (slotStart < this.end) {
                        slotEnd = new Date(slotStart.toDateString());
                        incrementCallback(slotEnd);

                        slots.push({ start: slotStart, end: slotEnd, span: span });

                        slotStart = slotEnd;
                    }
                }
                return slots;
            },

            _createSlots: function () {
                var slots = [];

                slots.push(this._generateSlots(function (date) { date.setFullYear(date.getFullYear() + 1); }, 12));
                slots.push(this._generateSlots(function (date) { date.setMonth(date.getMonth() + 3); }, 3));
                slots.push(this._generateSlots(function (date) { date.setMonth(date.getMonth() + 1); }, 1));

                return slots;
            },

            _layout: function () {
                var rows = [];
                var options = this.options;
                rows.push(this._slotHeaders(this._slots[0], kendo.template(options.dayHeaderTemplate)));
                rows.push(this._slotHeaders(this._slots[1], kendo.template(options.yearHeaderTemplate)));
                rows.push(this._slotHeaders(this._slots[2], kendo.template(options.quarterHeaderTemplate)));
                rows.push(this._slotHeaders(this._slots[3], kendo.template(options.monthHeaderTemplate)));

                return rows;
            }
        });
    }
    configureKendoScheduler() {
        //Se extiende el timelineview para poder añadir una imagen en el recurso (poder pasar el data al template)
        (<any>kendo).ui.TimelineView = (<any>kendo).ui.TimelineView.extend({
            _createColumnsLayout: (resources, inner, template) => {
                return this.createLayoutConfiguration("columns", resources, inner, template);
            },
            _createRowsLayout: (resources, inner, template) => {
                return this.createLayoutConfiguration("rows", resources, inner, template);
            },

        });
        (<any>kendo).ui.TimelineMonthView = (<any>kendo).ui.TimelineMonthView.extend({
            _createColumnsLayout: (resources, inner, template) => {
                return this.createLayoutConfiguration("columns", resources, inner, template);
            },
            _createRowsLayout: (resources, inner, template) => {
                return this.createLayoutConfiguration("rows", resources, inner, template);
            },

        });
        (<any>kendo).ui.TimelineWeekView = (<any>kendo).ui.TimelineWeekView.extend({

            _createColumnsLayout: (resources, inner, template) => {
                return this.createLayoutConfiguration("columns", resources, inner, template);
            },
            _createRowsLayout: (resources, inner, template) => {
                return this.createLayoutConfiguration("rows", resources, inner, template);
            },

        });
        (<any>kendo).ui.TimelineWorkWeekView = (<any>kendo).ui.TimelineWorkWeekView.extend({
            _createColumnsLayout: (resources, inner, template) => {
                return this.createLayoutConfiguration("columns", resources, inner, template);
            },
            _createRowsLayout: (resources, inner, template) => {
                return this.createLayoutConfiguration("rows", resources, inner, template);
            },

        });

        (<any>kendo).ui.TimelineYearView = (<any>kendo).ui.TimelineView.extend({
            _createColumnsLayout: (resources, inner, template) => {
                return this.createLayoutConfiguration("columns", resources, inner, template);
            },
            _createRowsLayout: (resources, inner, template) => {
                return this.createLayoutConfiguration("rows", resources, inner, template);
            },
            nextDate: function () {
                var start = this.startDate();
                return new Date(start.getFullYear() + 1, 0, 1);
            },
            previousDate: function () {
                var start = this.startDate();
                return new Date(start.getFullYear() - 1, 0, 1);
            },
            calculateDateRange: function () {
                var selectedDate = this.options.date,
                    start = new Date(selectedDate.getFullYear() - 1, 11, 31),
                    end = new Date(selectedDate.getFullYear(), 11, 30),
                    dates = [];

                while (start <= end) {
                    dates.push(start);
                    start = new Date(start.setDate(start.getDate() + 1));

                }
                this._render(dates);
            }

        });
        (<any>kendo).ui.TimelineCustomView = (<any>kendo).ui.TimelineView.extend({
            options: {
                name: 'TimelineCustomView',
                title: 'Timeline Custom',
                selectedDateFormat: '{0:D} - {1:D}',
                selectedShortDateFormat: '{0:d} - {1:d}',
                majorTick: 120
            },
            name: 'custom',
            calculateDateRange: function () {
                var events = new kendo.data.ObservableArray([]);
                var selectedDate = this.options.date, start = (<any>kendo).date.dayOfWeek(selectedDate, this.calendarInfo().firstDay, -1), idx, length, dates = [];
                for (idx = 0, length = 200; idx < length; idx++) {
                    dates.push(start);
                    start = (<any>kendo).date.nextDay(start);
                }
                this._render(dates);
            }
        });
    }

    private createLayoutConfiguration(name, resources, inner, template) {

        var resource = resources[0];
        if (resource) {
            var configuration = [];

            var data = resource.dataSource.view();

            for (var dataIndex = 0; dataIndex < data.length; dataIndex++) {
                var obj = {
                    text: template({
                        text: kendo.htmlEncode((<any>kendo).getter(resource.dataTextField)(data[dataIndex])),
                        color: (<any>kendo).getter(resource.dataColorField)(data[dataIndex]),
                        field: resource.field,
                        title: resource.title,
                        name: resource.name,
                        data: data[dataIndex],
                        value: (<any>kendo).getter(resource.dataValueField)(data[dataIndex])
                    }),
                    className: "k-slot-cell"
                };
                obj[name] = this.createLayoutConfiguration(name, resources.slice(1), inner, template);

                configuration.push(obj);
            }
            return configuration;
        }
        return inner;
    }

    public viewDay: rps.viewmodels.properties.VMProperty<boolean>;
    public viewWeek: rps.viewmodels.properties.VMProperty<boolean>;
    public viewMonth: rps.viewmodels.properties.VMProperty<boolean>;
    public viewYear: rps.viewmodels.properties.VMProperty<boolean>;
    public viewFill: rps.viewmodels.properties.VMProperty<boolean>;
    public viewFillSelection: rps.viewmodels.properties.VMProperty<boolean>;
    public ganttViewProject: rps.viewmodels.properties.VMProperty<boolean>;
    public ganttViewResource: rps.viewmodels.properties.VMProperty<boolean>;
    public ganttViewTreelist: rps.viewmodels.properties.VMProperty<boolean>;
    public treeChildren: rps.data.sources.HierarchicalQuerySource;
    public currentDate: rps.viewmodels.properties.VMProperty<Date>;
    public setCurrentDate(newDate?: Date) {
        if (newDate)
            this.currentDate.value = newDate;
        else {
            if (this.theGantt) {
                this.ganttStart = this.currentDate.value;
                //Si está en modo ajuste, se cambia a modo mes, para que no quede descuadrado
                if (!rps.object.hasValue(this.currentView) || this.currentView == ganttRangeType.FillView || this.currentView == ganttRangeType.MonthView)
                    this.setCurrentView(ganttRangeType.MonthView); //Internamente ya se hará un refreshSlotBind...
                else
                    this.refreshSlotBind();
            } else if (this.theScheduler) {
                this.schedulerStart = this.currentDate.value;
                this.refreshSchedulerOptions();
            }
        }
    }

    public setCurrentView(viewType: ganttRangeType) {
        this.viewDay.value = (viewType == ganttRangeType.DayView);
        this.viewWeek.value = (viewType == ganttRangeType.WeekView);
        this.viewMonth.value = (viewType == ganttRangeType.MonthView);
        this.viewYear.value = (viewType == ganttRangeType.YearView);
        this.viewFill.value = (viewType == ganttRangeType.FillView);
        this.viewFillSelection.value = (viewType == ganttRangeType.FillViewSelection);
        this.currentView = viewType;

        if (viewType == ganttRangeType.DayView) {
            if (this.theGantt) {
                this.ganttStart = this.initGanttStart;
                this.refreshSlotBind();
            } else if (this.theScheduler) {
                this.refreshSchedulerOptions();
            }
        } else if (viewType == ganttRangeType.WeekView) {
            if (this.theGantt) {
                this.ganttStart = this.initGanttStart;
                this.ganttEnd = this.initGanttEnd;
                this.ganttStart.setHours(0, 0, 0);
                this.ganttEnd.setHours(23, 59, 59);
                this.refreshSlotBind();
            } else if (this.theScheduler) {
                this.refreshSchedulerOptions();
            }
        } else if (viewType == ganttRangeType.MonthView) {
            if (this.theGantt) {
                this.ganttStart = this.initGanttStart;
                this.ganttEnd = this.initGanttEnd;
                this.ganttStart.setHours(0, 0, 0);
                this.ganttEnd.setHours(23, 59, 59);
                this.refreshSlotBind();
            } else if (this.theScheduler) {
                this.refreshSchedulerOptions();
            }
        } else if (viewType == ganttRangeType.YearView) {
            if (this.theGantt) {
                this.ganttStart = this.initGanttStart;
                this.refreshSlotBind();
            } else if (this.theScheduler) {
                this.refreshSchedulerOptions();
            }
        } else if (viewType == ganttRangeType.FillView) {
            if (this.theGantt) {
                //Buscar fecha mínimia y máxima
                const allChildren: kendo.data.GanttTask[] = (<any>this.theGantt.dataSource).taskAllChildren();
                if (allChildren.length > 0) {
                    let lowerDate = new Date(2100, 1, 1);
                    let upperDate = new Date(1900, 1, 1);
                    for (var i = 0; i < allChildren.length; i++) {
                        if (allChildren[i].start > upperDate)
                            upperDate = allChildren[i].start;
                        if (allChildren[i].end < lowerDate)
                            lowerDate = allChildren[i].end;
                    }
                    this.ganttStart = lowerDate;
                    this.ganttEnd = upperDate;
                    this.refreshSlotBind();
                }
            } else if (this.theScheduler) {
                this.refreshSchedulerOptions();
            }
        } else if (viewType == ganttRangeType.FillViewSelection) {
            if (this.theGantt) {
                if (this.theGantt.select().length) {
                    this.taskSelectedUID = this.theGantt.select().attr("data-uid");
                    var taskSelected = this.theGantt.dataSource.getByUid(this.theGantt.select().attr("data-uid"))
                    this.ganttStart = taskSelected["start"]
                    this.ganttEnd = taskSelected["end"];
                    this.refreshSlotBind();
                }
            } else if (this.theScheduler) {

            }
        }
    }

    /*Cambio del tamaño del slot dinamicamente segun el tamaño de la ventana del gantt*/
    public dynamycSlotSize() {
        if (this.theGantt) {
            /*Numero de tareas visibles en el gantt*/
            var nTasks = this.theGantt.element.find(".k-task-wrap").length;
            var ganttSlotSize = this.slotSize;
            var lastElement;
            var lastElementPos = 0;
            //Numero de días entre el inicio y el fin de el gantt
            var dias = this.ganttDays(this.ganttStart, this.ganttEnd);
            //Numero de horas entre el inico y el fin de el gantt
            var hours = this.ganttHours(this.ganttStart, this.ganttEnd);            
            //Espacio para meter los slot de la vista
            var ganttWidth = this.timelineWidth;
            //Segun el tipo de vista, se va cambiando el tamaño del slot
            if (((dias > 364 && (this.viewFill.value || this.viewFillSelection.value)) || (this.viewYear.value))) {
                if (this.viewYear.value) {
                    ganttWidth = this.timelineWidth / 12;
                } else if (this.viewFill.value) {
                    //vista año, cada slot es un mes
                    dias = dias / 30;
                    ganttWidth = (this.timelineWidth - 30) / dias;
                }
            } else if ((dias > 30 && (this.viewFill.value || this.viewFillSelection.value)) || (this.viewMonth.value)) {

                if (this.viewMonth.value) {
                    ganttWidth = this.timelineWidth / new Date(this.ganttStart.getFullYear(), this.ganttStart.getMonth() + 1, 0).getDate();
                } else {
                    //vista mes, cada slot es una semana        
                    //dia inicio y fin
                    var startOpt = new Date(this.ganttStart.toString());
                    var endOpt = new Date(this.ganttEnd.toString());
                    var firstStart = startOpt.getDate() - startOpt.getDay() + 1;
                    var firstEnd = endOpt.getDate() - endOpt.getDay() + 1;
                    var lastEnd = firstEnd + 6;
                    var weekStartDate = new Date(startOpt.setDate(firstStart));
                    var weekEndDate = new Date(endOpt.setDate(lastEnd));
                    //dias entre fechas
                    var diffDays = this.ganttDays(weekStartDate, weekEndDate)
                    var semana = diffDays / 7
                    ganttWidth = this.timelineWidth / semana;
                }
            } else {
                if ((hours > 24 && (this.viewFill.value || this.viewFillSelection.value)) || (this.viewWeek.value)) {
                    if (this.viewWeek.value) {
                        ganttWidth = this.timelineWidth / 7;
                    } else if (this.viewFill.value || this.viewFillSelection.value) {
                        //vista semana, cada slot es un dia                                             
                        ganttWidth = this.timelineWidth / dias;
                    }
                } else {
                    //vista dia, cada slot es una hora
                    if (this.viewDay.value) {
                        ganttWidth = this.timelineWidth / 24;
                    } else {
                        ganttWidth = this.timelineWidth / hours;
                    }

                }
            }
            this.slotSize = ganttWidth;
        }
    }
    public ganttDays(start: Date, end: Date): number {
        var oneDay = 24 * 60 * 60 * 1000;
        var firstDate = start;
        var secondDate = end;
        var diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
        return diffDays;

    }
    public ganttHours(start: Date, end: Date): number {
        var oneHour = 60 * 60 * 1000;
        var firstDate = start;
        var secondDate = end;
        var diffHours = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneHour)));

        return diffHours;
    }
    public addTask(e) {
        if (!this.rpsAdapter)
            return;

        if (!e.item.id)
            return;

        var promise: Promise<rps.viewmodels.adapters.GanttTask>;
        var selection = this.theGantt.select();
        if (!selection.length) {
            promise = this.rpsAdapter.onTaskAdd(undefined, undefined, undefined);
        } else {
            var dataItem = this.theGantt.dataItem(selection);
            var position = undefined;
            if (e.item.id == "addBefore")
                position = dataItem.orderId;
            else if (e.item.id == "addAfter")
                position = dataItem.orderId + 1;

            //En principio, se añaden al mismo nivel de padre que el elemento seleccionado
            promise = this.rpsAdapter.onTaskAdd(position, undefined, dataItem.parentId.toString());
        }
        if (promise)
            promise.then((task) => {
                if (task)
                    alert("TODO: Meter la tarea en el datasource");
            });
    }

    private setWidgetOptions() {
        var format: string = "{0:" + rps.utils.getPatternDate() + "}";       

        this.ganttOptions = {
            views: [
                { type: "day", slotSize: 130 },
                { type: "week", slotSize: 400 },
                { type: "month", slotSize: 550 },
                { type: "year", slotSize: 350 },
                { type: "kendo.ui.rpsGanttDayView", slotSize: this.slotSize },
                { type: "kendo.ui.rpsGanttWeekView", slotSize: this.slotSize },
                { type: "kendo.ui.rpsGanttMonthView", slotSize: this.slotSize },
                { type: "kendo.ui.rpsGanttYearView", slotSize: this.slotSize },
                { type: "kendo.ui.rpsGanttDayViewFill", slotSize: this.slotSize },
                { type: "kendo.ui.rpsGanttWeekViewFill", slotSize: this.slotSize },
                { type: "kendo.ui.rpsGanttMonthViewFill", slotSize: this.slotSize },
                { type: "kendo.ui.rpsGanttYearViewFill", slotSize: this.slotSize }
            ],
            columns: [
                { field: "title", title: rps.app.resources.gantt.TITLE, editable: true },
                { field: "start", title: rps.app.resources.gantt.START_TIME, format: format, width: 100 },
                { field: "end", title: rps.app.resources.gantt.END_TIME, format: format, width: 100 }
            ],
            height: 500,

            showWorkHours: false,
            showWorkDays: false,

            dataBound: (e) => { this.onGanttDataBound(e) },
            save: (e) => {
                this.onSave(e);
            },
            edit: (e) => {
                this.onEdit(e);
            },
            //remove: (e) => {
            //    this.onRemove(e);
            //},
            //add: (e) => {
            //    this.onAdd(e);
            //},
            tooltip: {
                template: this.templateGanttTask("#=task#")
            },
            //Evitar el resize y el mover en el gantt
            resizeStart: (e) => {
                e.preventDefault()
            },
            moveStart: (e) => {
                e.preventDefault()
            },
            rowHeight: 24,
            messages: {
                deleteTaskConfirmation:rps.app.resources.gantt.TASK_DELETE_CONFIRM,
                deleteDependencyConfirmation: rps.app.resources.gantt.DEPENDENCY_DELETE_CONFIRM,
            }
        };

        this.schedulerOptions = {
            majorTick: 60,
            height: 700,
            editable: {
                destroy: false
            },
            views: [
                {
                    minorTickCount: 1,
                    majorTick: 60, //Minutos por tick
                    type: "kendo.ui.TimelineView",
                    columnWidth: this.dinamycColumnWidth,
                },
                {
                    minorTickCount: 1,
                    majorTick: 1440, //Minutos por tick
                    type: "kendo.ui.TimelineWorkWeekView",
                    selected: true,
                    dateHeaderTemplate: "#=kendo.toString(date, \'dd\')#", //mes      
                    majorTimeHeaderTemplate: "#=kendo.toString(date, \'dd\')#",
                },
                {
                    type: "timelineWeek",
                    majorTick: 1440,
                    minorTickCount: 1,

                },
                "timelineWorkWeek",
                {
                    minorTickCount: 1,
                    majorTick: 1440, //Minutos por tick
                    type: "timelineMonth",
                    columnWidth: this.dinamycColumnWidth,
                    dateHeaderTemplate: "#=kendo.toString(date, \'dd\')#", //mes      
                    majorTimeHeaderTemplate: "#=kendo.toString(date, \'dd\')#"
                },
                "timelineYear",
                {
                    type: "kendo.ui.TimelineYearView",
                    minorTickCount: 1,
                    majorTick: 44640, //Minutos por tick
                    columnWidth: this.dinamycColumnWidth,
                    dateHeaderTemplate: "#=kendo.toString(date, \'MM\')#", //mes      
                    majorTimeHeaderTemplate: "#=kendo.toString(date, \'MM\')#"
                }
            ],

            group: {
                resources: ["Resources"], orientation: "vertical"
            },
            resources: [
                {
                    dataTextField: "description",
                    field: "idResource",
                    dataValueField: "id",
                    title: "Resource",
                    name: "Resources",
                    dataSource: []
                }
            ],
            groupHeaderTemplate: `<a href=""><div class='rps-resource-navigate' data-id=#: data.id #><div class='rps-img-2' style='float:left; background-image :url(#: data.image #);background-size: cover;'></div><div> #=text# </div></div></a>`,
            edit: (e) => {
                this.onEdit(e);
                //Ñapa: necesario porque, tras hacer un preventDefault del edit, hay casos en los que la tarea
                //queda inconsistente y se desatacha de los eventso
                this.theScheduler.dataSource.sync();

            },
            save: (e) => {
                this.onSave(e);
            },
            snap: true,
            dataBound: (e) => {
                this.onSchedulerDataBound(e);
            },
        };
    }
    rebindData = () => {
        if (this.rpsAdapter && this.rpsAdapter.tasks && this.rpsAdapter.resources) {
            //Eliminar los widgets si existían
            if (this.theGantt) {
                this.theGantt.destroy();
                this.theGantt = null;
            }
            if (this.theScheduler) {
                this.theScheduler.destroy();
                this.theScheduler = null;
            }
            if (this.treelist) {
                this.treelist.destroy();
                this.treelist = null;
            }
            //Busca inputContainer (el div que contiene el input)
            var containerDiv = $(this.elementRef.nativeElement).find("#kendoGanttContainer").first();
            containerDiv.empty();

            if (this.rpsAdapter.viewType.value == rps.viewmodels.adapters.ganttViewTypes.resourceView) {
                //Recursos del scheduler
                this.schedulerOptions.resources[0].dataSource = this.rpsAdapter.resources;
                if (this.schedulerStart) {
                    this.schedulerOptions.date = this.schedulerStart;
                } else {
                    this.schedulerOptions.date = this.rpsAdapter.startDate;
                    this.schedulerStart = this.rpsAdapter.startDate;
                }
                //Tareas del scheduler
                var schedulerTasks = new rps.viewmodels.ObservableArray<any>();
                this.rpsAdapter.tasks.forEach((task: rps.viewmodels.adapters.GanttTask) => {
                    task.$Assignments.forEach((assignment: rps.viewmodels.adapters.GanttResource) => {
                        if (!rps.object.isNullOrUndefined(assignment)) {
                            var sTask = {
                                id: task.id + "|" + assignment.id,
                                title: task.title,
                                start: task.start,
                                end: task.end,
                                idResource: assignment.id,
                                task: task
                            };
                            schedulerTasks.push(sTask);
                        }
                    });

                });
                this.schedulerDataSource = new kendo.data.SchedulerDataSource({
                    schema: {
                        model: {
                            id: "id",
                            fields: {
                                id: { type: "string" },
                                idResource: { type: "string" },
                                start: { type: "date" },
                                end: { type: "date" },
                                title: { defaultValue: "", type: "string" }
                            }
                        }
                    },
                    sync: function () { this.read(); },
                    data: schedulerTasks
                });
                this.schedulerOptions.dataSource = this.schedulerDataSource;
                this.theScheduler = $(containerDiv).append("<div></div>").find("div").kendoScheduler(this.schedulerOptions).data("kendoScheduler");
                if (this.viewDay.value)
                    this.theScheduler.view("kendo.ui.TimelineView");
                else if (this.viewWeek.value)
                    this.theScheduler.view("kendo.ui.TimelineWeek");
                else if (this.viewMonth.value)
                    this.theScheduler.view("timelineMonth");
                else if (this.viewYear.value)
                    this.theScheduler.view("kendo.ui.TimelineYearView");

                //Tooltip             
                this.schedulerTooltip = this.theScheduler.element.kendoTooltip({
                    filter: ".k-event:not(.k-event-drag-hint) > div, .k-task",
                    position: "bottom",
                    width: 250,
                    content: (e) => {           
                        var element = e.target.is(".k-task") ? e.target : e.target.parent();
                        var uid = element.attr("data-uid");
                        var scheduler = e.target.closest("[data-role=scheduler]").data("kendoScheduler");
                        var model = scheduler.occurrenceByUid(uid);                  
                        var task = <rps.viewmodels.adapters.GanttTask> model.task;
                        if (model) {
                            return kendo.template(`
                                <div class="k-task-details" >
                                    <strong>${task.title}</strong>
                                    <div class="k-task-secondaryTitle">${task.secondaryTitle}</div>
                                    <div class="k-task-pct" >${kendo.toString(task.percentComplete, "p0")}</div>
                                    <div class="k-task-duration">${task.durationText}</div>
                                    <ul class="k-reset">
                                        <li><b>Start: </b>${kendo.toString(task.start, "H:mm tt ddd, MMM d")}</li>
                                        <li><b>End: </b>${kendo.toString(task.end, "H:mm tt ddd, MMM d")}</li>
                                    </ul>
                                </div>`
                            );
                        }
                    }
                }).data("kendoTooltip");               

            } else if (this.rpsAdapter.viewType.value == rps.viewmodels.adapters.ganttViewTypes.projectView) {
                //Tareas del gantt
                this.tasksDataSource = new kendo.data.GanttDataSource({
                    schema: {
                        model: {
                            id: "id",
                            fields: {
                                id: { type: "string" },
                                orderId: { type: "number", validation: { required: true } },
                                parentId: { type: "string", defaultValue: null, validation: { required: true } },
                                start: { type: "date" },
                                end: { type: "date" },
                                title: { defaultValue: "", type: "string" },
                                summary: { type: "boolean" },
                                percentComplete: { type: "number" },
                                expanded: { from: "Expanded", type: "boolean", defaultValue: this.tasksExpanded }
                            }
                        }
                    },
                    data: this.rpsAdapter.tasks
                });

                this.ganttOptions.dataSource = this.tasksDataSource;
                //Dependencias
                var dep = new Array<any>();
                this.rpsAdapter.tasks.forEach((task) => {
                    task.$Predecessors.forEach((predecessor) => {
                        dep.push({
                            ID: rps.guid.newGuid(),
                            SuccessorID: task.id,
                            PredecessorID: rps.object.isString(predecessor.taskOrTaskId) ? predecessor.taskOrTaskId : (<rps.viewmodels.adapters.GanttTask>predecessor.taskOrTaskId).id,
                            Type: predecessor.ganttRelationType,
                        });
                    });
                    task.$Successors.forEach((successor) => {
                        dep.push({
                            ID: rps.guid.newGuid(),
                            PredecessorID: task.id,
                            SuccessorID: rps.object.isString(successor.taskOrTaskId) ? successor.taskOrTaskId : (<rps.viewmodels.adapters.GanttTask>successor.taskOrTaskId).id,
                            Type: successor.ganttRelationType,
                        });
                    });
                });
                this.ganttOptions.dependencies = new kendo.data.GanttDependencyDataSource({
                    schema: {
                        model: {
                            id: "id",
                            fields: {
                                id: { from: "ID", type: "string" },
                                predecessorId: { from: "PredecessorID", type: "string" },
                                successorId: { from: "SuccessorID", type: "string" },
                                type: { from: "Type", type: "number" }
                            }
                        }
                    },
                    data: dep
                });
                if (!this.rpsAdapter.startDate) {
                    this.rpsAdapter.startDate = rps.date.today();
                    this.rpsAdapter.endDate = rps.date.addDays(this.rpsAdapter.startDate, 7);
                    this.viewWeek.value = true;
                }
                if (!this.rpsAdapter.endDate) {
                    this.rpsAdapter.endDate = rps.date.addDays(this.rpsAdapter.startDate, 7);
                    this.viewWeek.value = true;
                }
                if (!this.ganttStart) {
                    this.ganttStart = new Date(this.rpsAdapter.startDate.getTime());
                    this.ganttEnd = new Date(this.rpsAdapter.endDate.getTime());
                    this.initGanttStart = new Date(this.ganttStart);
                    this.initGanttEnd = new Date(this.ganttEnd);
                }
                if (this.viewDay.value) {
                    var gStart = new Date(this.ganttStart.toString());
                    var gEnd = new Date(this.ganttStart.toString());
                    gStart.setHours(0, 0, 0);
                    gEnd.setHours(23, 59, 59);
                    this.ganttStart = new Date(gStart.toString());
                    this.ganttEnd = new Date(gEnd.toString());
                    (<any>this.ganttOptions).startDate = this.ganttStart;
                    (<any>this.ganttOptions).endDate = this.ganttEnd;
                } else if (this.viewWeek.value) {
                    var startOpt = new Date(this.ganttStart.toString());
                    startOpt.setHours(0, 0, 0);
                    var first = startOpt.getDate() - startOpt.getDay() + 1;
                    this.ganttStart = new Date(startOpt.setDate(first));
                    this.ganttEnd = new Date(this.ganttStart);
                    this.ganttEnd.setDate(this.ganttEnd.getDate() + 7);
                    (<any>this.ganttOptions).startDate = this.ganttStart;
                    (<any>this.ganttOptions).endDate = this.ganttEnd;
                } else if (this.viewMonth.value) {
                    this.ganttStart = new Date(this.ganttStart.getFullYear(), this.ganttStart.getMonth(), 1);
                    this.ganttEnd = new Date(this.ganttStart.getFullYear(), this.ganttStart.getMonth() + 1, 0);
                    this.ganttStart.setHours(0, 0, 0);
                    (<any>this.ganttOptions).startDate = this.ganttStart;
                    (<any>this.ganttOptions).endDate = this.ganttEnd;
                } else if (this.viewYear.value) {
                    this.ganttStart = new Date(this.ganttStart.getFullYear(), 0, 1);
                    this.ganttEnd = new Date(this.ganttStart.getFullYear(), 11, 31);
                    this.ganttStart.setHours(0, 0, 0);
                    (<any>this.ganttOptions).startDate = this.ganttStart;
                    (<any>this.ganttOptions).endDate = this.ganttEnd;
                } else if (this.viewFill.value) {
                    (<any>this.ganttOptions).startDate = new Date(this.rpsAdapter.startDate.getTime());
                    (<any>this.ganttOptions).endDate = new Date(this.rpsAdapter.endDate.getTime());
                } else if (this.viewFillSelection.value) {
                    (<any>this.ganttOptions).startDate = this.ganttStart;
                    (<any>this.ganttOptions).endDate = this.ganttEnd;
                }
                (<any>this.ganttOptions).controller = this;

                this.theGantt = $(containerDiv).append("<div></div>").find("div").kendoGantt(this.ganttOptions).data("kendoGantt");
                this.redimensionGantt();
                var dias = this.ganttDays(this.ganttStart, this.ganttEnd);
                var hours = this.ganttHours(this.ganttStart, this.ganttEnd);
                if (this.viewFill.value || this.viewFillSelection.value) {

                    if (dias > 364) {
                        this.theGantt.view("kendo.ui.rpsGanttYearViewFill");
                    } else if (dias > 30) {
                        this.theGantt.view("kendo.ui.rpsGanttMonthViewFill");
                    } else {
                        if (hours > 24) {
                            this.ganttStart.setHours(0, 0, 0);
                            this.ganttEnd.setHours(23, 59, 59);
                            this.theGantt.view("kendo.ui.rpsGanttWeekViewFill");
                        } else {
                            this.theGantt.view("kendo.ui.rpsGanttDayViewFill");
                        }

                    }
                } else if (this.viewDay.value) {
                    this.theGantt.view("kendo.ui.rpsGanttDayView");
                } else if (this.viewWeek.value) {
                    this.theGantt.view("kendo.ui.rpsGanttWeekView");
                } else if (this.viewMonth.value) {
                    this.theGantt.view("kendo.ui.rpsGanttMonthView");
                } else if (this.viewYear.value) {
                    this.theGantt.view("kendo.ui.rpsGanttYearView");
                }
                $(".k-splitbar").on("click", () => {
                    this.timelineWidth = $(".k-gantt-timeline").width();
                    this.refreshSlotBind();
                });

            }
        }
    }

    private redimensionGantt() {
        var ganttWidth = $(".k-gantt").width();
        $(".k-gantt-timeline").width(this.timelineWidth);
        $(".k-gantt-timeline .k-grid-header-wrap").width(this.timelineWidth - 7);
        $(".k-gantt-treelist").width((ganttWidth - this.timelineWidth));
    }

    /**
     * Establece la propiedad expanded de aquellas tareas que se habían guardado como expandidas
     */
    private expandedTasks() {
        this.theGantt.element.find(".k-task").each((e) => {
            var dataitem = this.theGantt.dataSource.getByUid(this.theGantt.element.find(".k-task").eq(e).attr("data-uid"));
            this.tasksExpanded.forEach((i) => {
                if (dataitem.id == i.id)
                    dataitem["expanded"] = true;
            });
        });

        //Establece un semáforo para evitar el bucle refresh -> onGanttDataBound -> refresh -> onGanttDataBound -> ...
        this.isRefreshing = true;
        this.theGantt.refresh();
    }

    private onSave(e) {
        //Salta cuando se actualiza una tarea desde el gantt; como hay veces que se queda tirado, se cancela la acción, y se vuelven a establecer los valores
        //a mano
        e.preventDefault();
        if (this.theScheduler) {
            this.rpsAdapter.onTaskUpdate(e.event.task, e.event.start, e.event.end, e.event.idResource).then((result) => {
                if (result) {
                    this.theScheduler.dataSource.read();
                    this.theScheduler.refresh();
                }
            });
        } else if (this.theGantt) {
            this.rpsAdapter.onTaskUpdate(e.task, e.values.start, e.values.end, null).then((result) => {
                if (result) {
                    this.theGantt.refresh();
                }
            });
        }

    }

    private onEdit(e) {
        //Evitar que salga la ventana de edición
        e.preventDefault();
        //Salta al hacer doble click en la tarea, para habilitar la edición de la tarea
        if (this.rpsAdapter) {
            if (e.task) {
                this.rpsAdapter.onTaskEdit(e.task);
            }
        }
        if (this.theScheduler) {
            //Entra por aquí cuando hace doble-click sobre el scheduler, sin evento - se crea el elemento
            if (!e.event.task) {
                for (var i = 0; i < this.rpsAdapter.resources.length; i++) {
                    if (this.rpsAdapter.resources[i].id == e.event.idResource) {
                        this.rpsAdapter.onTaskAdd(i, e.event.start, undefined);


                    }
                }
            }
            else
                this.rpsAdapter.onTaskEdit(e.event.task);
        }
    }

    //private onRemove(e) {
    //    //Evitar que salga la ventana de edición
    //    debugger;
    //    e.preventDefault();
    //    //Salta al hacer doble click en la tarea, para habilitar la edición de la tarea
    //    //if (this.rpsAdapter) {
    //    //    if (e.task) {
    //    //        this.rpsAdapter.onTaskEdit(e.task);
    //    //    }
    //    //}
    //    //if (this.theScheduler) {

    //    //    for (var i = 0; i < this.rpsAdapter.resources.length; i++) {
    //    //        if (this.rpsAdapter.resources[i].id == e.event.idResource) {
    //    //            this.rpsAdapter.onTaskAdd(i, e.event.start, undefined);

    //    //        }
    //    //    }
    //    //    this.rpsAdapter.onTaskEdit(e.event.task);
    //    //}
    //}

    //private onAdd(e) {
    //    if (this.rpsAdapter) {
    //        if (e.task) { //Se añade una tarea
    //            this.rpsAdapter.onTaskAdd(0, new Date(), "idParent");
    //            console.log("Task added");
    //        } else if (e.dependency) { //Se añade relación
    //            this.rpsAdapter.onDependencyAdd(e.dependency.predecessorId, e.dependency.successorId, e.dependency.type)
    //                .then((dependency) => {
    //                    if (dependency) {
    //                        alert("TODO Añadir la dependencia al gantt ");
    //                    }
    //                });
    //        }
    //    }

    //}

    private getSchedulerSelectedItems() {
        var sche: any = this;
        var arr = [];
        if (!rps.object.isNullOrUndefined(this.theScheduler)) {
            this.theScheduler.element.find(".k-event").each(function (e) {
                var sel = $(this).attr("selec");
                if (sel == "true") {
                    var dataItem = sche.theScheduler.dataSource.getByUid($(this).attr("data-uid"));
                    if (dataItem)
                        arr.push(dataItem.task);
                }
            });
        }
        return arr;
    }

    private onGanttDataBound(e) {
        if (this.isRefreshing) {
            this.isRefreshing = false;
            return;
        }

        this.expandedTasks();
        
        if (this.theGantt) {
            this.theGantt.resize(true);
            /*Se situa el scroll del gantt en la tarea que hemos selecionado*/
            if (this.viewFillSelection.value) {
                for (var i = 0; i < this.theGantt.dataSource.view().length; i++) {
                    if (this.taskSelectedUID == this.theGantt.dataSource.view()[i].uid) {
                        this.theGantt.element.find("div[data-uid='" + this.theGantt.dataSource.view()[i].uid + "'], .k-line").css("opacity", "1");
                        $('.k-timeline .k-grid-content').animate({
                            scrollTop: ((this.theGantt.element.find("div[data-uid='" + this.theGantt.dataSource.view()[i].uid + "']").offset().top) - ($(".k-timeline .k-grid-content").offset().top))
                        }, 0);
                    } else {
                        this.theGantt.element.find("div[data-uid='" + this.theGantt.dataSource.view()[i].uid + "'], .k-line").css("opacity", "0.3");
                    }
                }
            }
        }

        //Cambiar el color de las tareas
        if (this.theGantt) {
            this.theGantt.element.find(".k-task").each((index,element:any) => {
                var dataItem: rps.viewmodels.adapters.GanttTask = <any>(this.theGantt.dataSource.getByUid($(element).attr("data-uid")));
                // colorize task per business requirements
                var taskColor = rps.color.getHexValue(dataItem.color);
                if (dataItem.summary) {
                    element.style.backgroundColor = "#333333";
                    element.style.color = (parseInt("333333", 16) > 0xffffff / 2) ? 'black' : 'white';
                } else {
                    if (taskColor) {
                        element.style.backgroundColor = taskColor;
                        element.style.color = (parseInt(taskColor.slice(1), 16) > 0xffffff / 2) ? 'black' : 'white';
                    } else {
                        element.style.backgroundColor = "#C60000";
                        element.style.color = (parseInt("C60000", 16) > 0xffffff / 2) ? 'black' : 'white';
                    }
                }

                //Además, añadir una clase adicional para marcar tareas de duración cero (milestones especiales)
                if (rps.date.areEqual(dataItem.start, dataItem.end) && !dataItem.milestone)
                    $(element).addClass("rps-gantt-task-duration-zero");
            });
        }
    }

    private onSchedulerDataBound(e) {
        var botonBorrar = this.btnDelete;
        var sche = this.theScheduler;

        //separacion entre tareas de las colapsadas
        var dt = 29;
        var contador = 0;
        var ncontenedor = 0;
        var element1 = 0;

        var menorleft = -1;
        var mayorwidth = -1;

        //Click en el scheduler y se quitan las tareas seleccionadas
        sche.element.find(".k-scheduler-content").on("click", (e) => {
            sche.element.find(".k-event[selec='true']").each(function (e) {
                $(this).css({ 'border': '0px solid #FF7566' });
                $(this).attr("selec", "false");
            });
        });

        //workaround para que la vista semanal salga el mes por un lado y los días por otro
        if (this.viewWeek.value && this.rpsAdapter.startDate) {
            $(".k-scheduler-timelineWorkWeekview > tbody > tr:nth-child(1) .k-scheduler-table tr:nth-child(2)").hide();
            $(".k-scheduler-timelineMonthview > tbody > tr:nth-child(1) .k-scheduler-table tr:nth-child(1)").show();
            $(".k-scheduler-timelineWorkWeekview > tbody > tr:nth-child(1) .k-scheduler-table tr:nth-child(1)").first().before("<th></th>");
            //Comparamos el intervalo de tiempo de la semana por si tenemos que poner dos meses.
            var compDate: Date = new Date(this.rpsAdapter.startDate.toString());
            compDate.setDate(compDate.getDate() - 5);
            if (this.rpsAdapter.startDate.getMonth() == compDate.getMonth())
                $(".k-scheduler-timelineWorkWeekview > tbody > tr:nth-child(1) .k-scheduler-table tr:nth-child(1)").last().before("<th colspan='5'>" + kendo.toString(this.schedulerOptions.date, "MMMM") + " (" + kendo.toString(this.schedulerOptions.date, "yyyy") + ") </th>");
            else
                $(".k-scheduler-timelineWorkWeekview > tbody > tr:nth-child(1) .k-scheduler-table tr:nth-child(1)").last().before("<th colspan='5'>" + kendo.toString(compDate, "MMMM") + " (" + kendo.toString(this.schedulerOptions.date, "yyyy") + ") - " + kendo.toString(this.schedulerOptions.date, "MMMM") + " (" + kendo.toString(this.schedulerOptions.date, "yyyy") + ")</th>");
        } else if (this.viewMonth.value) {
            $(".k-scheduler-timelineMonthview > tbody > tr:nth-child(1) .k-scheduler-table tr:nth-child(2)").hide();
            $(".k-scheduler-timelineMonthview > tbody > tr:nth-child(1) .k-scheduler-table tr:nth-child(1)").show();
            $(".k-scheduler-timelineMonthview > tbody > tr:nth-child(1) .k-scheduler-table tr:nth-child(1)").first().before("<th></th>");
            $(".k-scheduler-timelineMonthview > tbody > tr:nth-child(1) .k-scheduler-table tr:nth-child(1)").last().before("<th colspan='" + new Date(this.schedulerOptions.date.getFullYear(), this.schedulerOptions.date.getMonth() + 1, 0).getDate() + "'>" + kendo.toString(this.schedulerOptions.date, "MMMM") + " (" + kendo.toString(this.schedulerOptions.date, "yyyy") + ") </th>");
        } else if (this.viewYear.value) {
            $(".k-scheduler-TimelineYearView > tbody > tr:nth-child(1) .k-scheduler-table tr:nth-child(2)").hide();
            $(".k-scheduler-TimelineYearView > tbody > tr:nth-child(1) .k-scheduler-table tr:nth-child(1)").show();
            $(".k-scheduler-TimelineYearView > tbody > tr:nth-child(1) .k-scheduler-table tr:nth-child(1)").first().before("<th></th>");
            $(".k-scheduler-TimelineYearView > tbody > tr:nth-child(1) .k-scheduler-table tr:nth-child(1)").last().before("<th colspan='" + new Date(this.schedulerOptions.date.getFullYear(), this.schedulerOptions.date.getMonth() + 1, 0).getDate() + "'>" + kendo.toString(this.schedulerOptions.date, "yyyy") + " </th>");
        } else if (!this.rpsAdapter.startDate) {
            $(".k-scheduler-timelineWorkWeekview > tbody > tr:nth-child(1) .k-scheduler-table tr:nth-child(2)").hide();
            $(".k-scheduler-timelineWorkWeekview > tbody > tr:nth-child(1) .k-scheduler-table tr:nth-child(1)").hide();
        }

        //Navegacion del recurso a donde quiera enviar el adapter. Solo envia el Id y recibimos un boolean
        //Si el boolean es true la navegacion se hace cuando se está sobrescribiendo
        sche.element.find(".rps-resource-navigate").on("click", (e) => {
            e.preventDefault();
            var resource: rps.viewmodels.adapters.GanttResource = Enumerable.From<rps.viewmodels.adapters.GanttResource>(this.rpsAdapter.resources).FirstOrDefault(null, c => c.id == $(e.currentTarget).attr("data-id"));
            if (!this.rpsAdapter.onResourceNavigate(resource.id, resource.type)) {
                //No hay personalización de la navegación
                var entityUrl ;
                switch (resource.type) {
                    case rps.viewmodels.adapters.ganttResourceType.employee:
                        entityUrl = "General/Employee";
                        break;
                    case rps.viewmodels.adapters.ganttResourceType.machine:
                        entityUrl = "Project/Machine";
                        break;
                    case rps.viewmodels.adapters.ganttResourceType.tool:
                        entityUrl = "Project/Tool";
                        break;
                    case rps.viewmodels.adapters.ganttResourceType.machineGroup:
                        entityUrl = "Project/MachineGroup";
                        break;
                    case rps.viewmodels.adapters.ganttResourceType.qualify:
                        entityUrl = "General/Qualify";
                        break;
                    }
            }
            if (entityUrl)
                rps.app.stateManager.navigateToEntity(entityUrl, resource.id, false);
        });

        //Recorrer todas las citas para enganchar el click (seleccionar /deseleccionar)
        sche.element.find(".k-event").each(function (e) {
            var dataItem = sche.dataSource.getByUid($(this).attr("data-uid"));
            $(this).on('click', (i) => {
                //Click en la tarea para seleccionarla
                i.stopPropagation();
                if (!i.ctrlKey) {

                    //Si no está pulsado la tecla control se quitaran las tareas que ya estaban seleccionadas
                    sche.element.find(".k-event[selec='true']").each(function (e) {
                        $(this).css({ 'border': '0px solid #FF7566' });
                        $(this).attr("selec", "false");
                    });
                }

                //Para la tarea clickada, seleccionar o deseleccionar dependiendo del caso
                var sel = $(this).attr("selec");
                if (sel == "true") {
                    $(this).css({ 'border': '0px solid #FF7566' });
                    $(this).attr("selec", "false");
                } else {
                    $(this).css({ 'border': '2px solid #FF7566' });
                    $(this).attr("selec", "true");
                }
            });
        })
    }

    dataChanged = () => {
        setTimeout(() => {
            //Puede ocurrir que el dataChanged se lance cuando el gantt no está visible, con lo que lo crea 
            //con tamaños a cero y queda invisible; en este caso, se crea un timer para que siga saltando hasta que
            //el gantt coja un tamaño y ya se recree correctamente
            //Se reproduce cuando se oculta el tab de gantt, se navega a una tarea, y luego se vuelve al proyecto no estando el gantt visible
            let el = $(this.elementRef.nativeElement);
            if (el.height() == 0 || el.width() == 0) {
                this.refreshTimer = setInterval(() => {
                    let el = $(this.elementRef.nativeElement);
                    if (el.height() > 0 && el.width() > 0) {
                        clearInterval(this.refreshTimer);
                        this.rebindData();
                    }
                },500);
            }
            else
                this.rebindData();
        });
    };

    rpsAdapterChanged(newValue) {
        this.rebindData();
        this.rpsAdapter.bind("dataChanged", this.dataChanged);
    }

    templateGanttTask(task) {
        var template: string = `
        <div class="#=styles.taskDetails# rps-gantt-tooltip" >
            <strong>#=task.title#</strong>
            <div class="#=styles.taskDetailsPercent#">#=kendo.toString(task.percentComplete, "p0")#</div>
            <ul class="#=styles.reset#">
                <li><b>${rps.app.resources.gantt.TT_TASK_START}:</b> #=kendo.toString(task.start, "H:mm tt ddd, MMM d")#</li>
                <li><b>${rps.app.resources.gantt.TT_TASK_END}:</b> #=kendo.toString(task.end, "H:mm tt ddd, MMM d")#</li>
                # var nEmployee=-1; var fEmployee; var nMachine=-1; var fMachine; var nTool=-1; var fTool;
                if (task.$Assignments[0]) {
                    for (var i=0; i < task.$Assignments.length; i++){
                        if (task.$Assignments[i].type==0){ nEmployee++;fEmployee=task.$Assignments[i].description;}
                        if (task.$Assignments[i].type==1){ nMachine++;fMachine=task.$Assignments[i].description;}
                        if (task.$Assignments[i].type==2){ nTool++;fTool=task.$Assignments[i].description;}
                    }
                    if (fEmployee){#<li><b>${rps.app.resources.gantt.TT_TASK_EMPLOYEE}: </b>#=fEmployee# #if(nEmployee>0){#(+#=nEmployee#)#}#</li>#}
                    if (fMachine){#<li><b>${rps.app.resources.gantt.TT_TASK_MACHINE}: </b>#=fMachine# #if(nMachine>0){#(+#=nMachine#)#}#</li>#}
                    if (fTool){#<li><b>${rps.app.resources.gantt.TT_TASK_TOOL}: </b>#=fTool# #if(nTool>0){#(+#=nTool#)#}#</li>#}
                }#
            </ul>
        </div>`;
        return template;
    }
    onMouseLeave() {
        //Ocultamos el tooltip cuando salimos del containerDiv para asegurarse de que no se queda abierto
        if (this.schedulerTooltip)
            this.schedulerTooltip.hide();
    }

    private get resources() {
        return rps.app.resources;
    }
}
interface Iresource {
    id: string;
    childs: Array<any>;
}








