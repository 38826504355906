import { Component, ElementRef, Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsReadOnlyEditor} from './readOnlyEditorBase'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'
import {rpsButton} from '../editors/button'
import {NgClass} from '@angular/common';

@Component({
    selector: 'rps-read-only-lookup',
    template: `
        <div class="rps-editor"             
             [ngClass]="{'rps-navigable-read-only-lookup': rpsModel?.allowNavigate}"
             [rpsSemanticState]="rpsModel?.semanticState"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label">
                {{rpsLabel}}
            </label>
            <div class="rps-read-only-lookup-container">
                <div class="rps-editor-editor rps-read-only-editor-viewer rps-read-only-semantic-state">
                    <div class="rps-img-2 rps-lookup-image" 
                         *ngIf="image">
                        <img class="rps-img" 
                             src="{{image}}" />
                    </div>
                    <div *ngIf="!link" >
                        {{completeDescriptor}}
                    </div>
                    <div *ngIf="link">
                        <a tabindex="-1" 
                           [routerLink]="link" [queryParams]="linkQueryParams">                            
                            {{completeDescriptor}}
                        </a>
                    </div>
                   
                </div>               
                <div class="rps-lookup-navigate-to-button rps-editor-fit-container" 
                     *ngIf="rpsModel?.allowNavigate">
                    <rps-button rpsTemplate="ICON"
                                rpsIcon="fa fa-lg fa-share-square-o" 
                                [rpsModel]="navigateToCommand"
                                rpsPreventTabindex="true"
                                [ngClass]="{'rps-lookup-navigate-to-button-disabled':!rpsModel?.hasValue()}">
                    </rps-button>
                </div>
                <div class="contextContainer" *ngIf="rpsModel?.allowNavigate && !isEditing">
                    <ul id="kendoContextMenu" class="rps-navigable-lookup-context-menu"></ul>
                </div>
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors" >
        </error-detail-validator>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsReadOnlyEditor.getComponentInputs(['rpsDataSource'])
})
export class rpsReadOnlyLookup extends rpsReadOnlyEditor<string, rps.viewmodels.properties.LookupProperty>  {

    public rpsDataSource: rps.data.sources.LookupSource;

    private descriptor: rps.data.Descriptor;
    private mainDescriptor: string;
    private secondaryDescriptor: string;
    private completeDescriptor: string;
    private link: Array<any>;
    private linkQueryParams: rps.IParams;
    public image: string;
    private static kendoContextMenuItemTemplate: string = `
        <div class="navigable-states-container">
            <div class=" rps-img-2 navigable-state-image"><img class="rps-img" src="{0}"/></div>       
            <div class="navigable-state-description">{1}</div>                        
        </div>
    `;
    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    setValue(newValue: string) {
        super.setValue(newValue);
        this.resolveHeader();
    }

    resolveHeader() {
        if (this.rpsModel) {
            (<rps.viewmodels.properties.LookupProperty>this.rpsModel).getHeader().then((newDescriptor: rps.data.Descriptor) => {
                this.setHeader(newDescriptor);
            }).catch(() => {
                this.setHeader(null);
            });
        }
        else {
            this.setHeader(null);
        }
    }

    setHeader(newHeader: rps.data.Descriptor) {
        this.descriptor = newHeader;
        if (newHeader) {
            this.mainDescriptor = newHeader.MainDescriptor;
            this.secondaryDescriptor = newHeader.SecondaryDescriptor;
            if (newHeader.SecondaryDescriptor)
                this.completeDescriptor = newHeader.MainDescriptor + " (" + newHeader.SecondaryDescriptor + ")";
            else
                this.completeDescriptor = newHeader.MainDescriptor;
            this.image = newHeader.Image;
            var link = rps.app.stateManager.createRouterLink(newHeader.UILink);
            this.link = link.routerLink;
            this.linkQueryParams = link.queryParams;
        }
        else {
            this.mainDescriptor = "";
            this.secondaryDescriptor = "";
            this.completeDescriptor = "";
            this.image = "";
            this.link = rps.app.stateManager.notFoundLink;
        }
        this.changeDetectorRef.markForCheck();
    }

    navigateToCommand: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: this.openContextMenu,
        canExecute: this.canNavigateTo
    });
    openContextMenu(): Promise<any> {
        var lookupProperty: rps.viewmodels.properties.LookupProperty = <rps.viewmodels.properties.LookupProperty>this.rpsModel;
        return rps.app.entityManager.getNavigableStates(lookupProperty.entityName,
            lookupProperty.value,
            rps.app.stateManager.getCurrentCompany())
            .then((states: Array<rps.data.NavigableState>) => {
                //Obtenemos los estados de la navegacion y los metemos al dataSource del ContextMenu
                //Se guarda el TargetState en el urlSelect para despues poder acceder al dato y hacer la navegacion                
                var contextMenuDataSource: Array<{ text: string; url: string; urlSelect: rps.data.IUILinkDefinition; encoded: boolean; }> = new Array<{ text: string; url: string; urlSelect: rps.data.IUILinkDefinition; encoded: boolean; }>();
                Enumerable.From(states).OrderBy(s => s.Description).forEach((state) => {
                    contextMenuDataSource.push({
                        text: rps.string.format(rpsReadOnlyLookup.kendoContextMenuItemTemplate, state.Image, state.Description),
                        url: rps.app.stateManager.href(state.TargetState),
                        urlSelect: state.TargetState,
                        encoded: false
                    });
                });

                //Máximo de 10 items por columna
                let navigationListColumns = Math.trunc(contextMenuDataSource.length / 10) + 1;

                var kendoContextMenu: kendo.ui.ContextMenu;
                var container = $(this.elementRef.nativeElement).find("#kendoContextMenu");
                if (container.length > 0) {

                    container.css('columns', navigationListColumns + " auto");
                    //Filter "#none" para que solo se abra el contextMenu donde ejecutemos el "Open". El elemento "#none" no existe.
                    kendoContextMenu = container
                        .kendoContextMenu({
                            dataSource: contextMenuDataSource,
                            orientation: "vertical",
                            filter: "#none",
                            close: (e) => {
                                kendoContextMenu.destroy();
                                $(this.elementRef.nativeElement).find(".contextContainer").html("<ul id='kendoContextMenu' class='rps-navigable-lookup-context-menu' style='columns:" + navigationListColumns + ";' ></ul>");

                            },
                            select: (e) => {
                                //Evitamos que vaya directamente para no romper la navegacion y obtenemos la ruta del DataSource para crear un RouterLink
                                e.preventDefault();
                                var index = $(e.item).index();
                                rps.app.stateManager.goTo(e.sender.options.dataSource[index].urlSelect);
                            }
                        })
                        .data("kendoContextMenu");
                    //Ejecutamos el open para forzar la apertura del contextMenu indicando donde tiene que abrirlo.
                    kendoContextMenu.open($(this.elementRef.nativeElement).find(".rps-lookup-navigate-to-button"));
                }
            });
    }
    canNavigateTo(): rps.viewmodels.commands.CanExecuteResult {
        if (this.rpsModel.hasValue())
            return rps.viewmodels.commands.CanExecuteResult.allow();
        else
            return rps.viewmodels.commands.CanExecuteResult.deny([rps.app.resources.directives.SELECT_AN_ITEM]);
    }
}