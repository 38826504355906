import { Component, ElementRef, forwardRef, Optional, OnChanges, SimpleChange, ComponentRef, ViewContainerRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {NgClass} from '@angular/common';
import {rpsFocusableEditor, rpsEditor} from './editorBase'
import {errorDetailValidator} from '../utils/errorDetailValidator'
import {rpsRowService} from '../layouts/row'
import {rpsButton} from './button'
import {rpsLabel} from '../readOnlyEditors/label'
import {rpsSemanticState} from '../utils/semanticState'
import { rpsControl } from '../controlBase'

@Component({
    template: `
        <div>
            <div class="rps-stack-panel-item" 
                 *ngIf="rpsLookup.rpsModel.hasAnyEntityView || rpsLookup.allowContextualSearch">
                <rps-button rpsTemplate="K-ICON" 
                            rpsIcon="search" 
                            [rpsModel]="rpsLookup.searchCommand">
                </rps-button>
            </div>
            <div class="rps-stack-panel-item" 
                 *ngIf="rpsLookup.rpsModel.allowAddNew">
                <rps-button rpsTemplate="K-ICON" 
                            rpsIcon="plus" 
                            [rpsModel]="rpsLookup.addNewCommand">
                </rps-button>
            </div>
            <div class="rps-stack-panel-item-right">
                <rps-label [rpsModel]="rpsLookup.countMessage">
                </rps-label>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class rpsLookupFooter {
    rpsLookup: rpsLookup;

    constructor(public cdr: ChangeDetectorRef) {

    }

    setRPSLookup(rpsLookup: rpsLookup) {
        this.rpsLookup = rpsLookup;
    }
}

@Component({
    selector: 'rps-lookup',
    template: `
        <div class="rps-editor" 
             [class.rps-navigable-lookup]="rpsModel?.allowNavigate" 
             [rpsSemanticState]="rpsModel?.semanticState" 
             [class.rps-disabled-editor]="disabled"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label" 
                   [attr.for]="myId">
                {{rpsLabel}}<font *ngIf="isRequired" color="red"> *</font>
            </label>
            <div class="rps-lookup-container">
                <div *ngIf="!isEditing">
                    <div class="rps-editor-editor rps-editor-viewer rps-semantic-state" 
                        [attr.tabindex]="tabIndexVal()"                     
                        (click)="onClick();"
                        (focus)="isEditing = true;">
                        <div class="rps-img-2 rps-lookup-image" *ngIf="image">
                            <img class="rps-img" src="{{image}}" />
                        </div>
                        <div *ngIf="!link">{{completeDescriptor}}</div>
                        <div *ngIf="link">
                            <a tabindex="-1" [routerLink]="link" [queryParams]="linkQueryParams">{{completeDescriptor}}</a>
                        </div>
                   
                    </div>
                </div>
                <div *ngIf="isEditing"
                     [class.rps-navigable-lookup]="rpsModel?.allowNavigate"                                
                     [rpsSemanticState]="rpsModel?.semanticState" 
                     [class.rps-disabled-editor]="disabled">
                    <div class="rps-lookup-main-descriptor-container" 
                         (blur) = "focusout()">
                        <input class="rps-editor-editor rps-semantic-state" 
                            (keydown)="keydown($event)" 
                            [disabled]="disabled"/>
                    </div>
                </div>
                <div class="rps-lookup-navigate-to-button rps-editor-fit-container" 
                        *ngIf="rpsModel?.allowNavigate && !isEditing && rpsModel?.hasValue()">
                    <rps-button rpsTemplate="ICON" 
                                rpsIcon="fa fa-lg fa-share-square-o" 
                                [rpsModel]="navigateToCommand"
                                rpsPreventTabindex="true" 
                                [ngClass]="{'rps-lookup-navigate-to-button-disabled':!rpsModel?.hasValue()}"
                                rpsLabel= "{{navigateTooltipText}}">
                    </rps-button>
                </div>
                <div class="rps-lookup-navigate-to-button rps-editor-fit-container" 
                        *ngIf="!rpsModel?.hasValue() && !disabled && !isEditing && !isOpen">
                    <rps-button rpsTemplate="K-ICON" 
                                rpsIcon="arrow-s"
                                [rpsModel]="openDropdownCommand"
                                rpsPreventTabindex="true">
                    </rps-button>
                </div>
                <div class="contextContainer" *ngIf="rpsModel?.allowNavigate && !isEditing">
                    <ul id="kendoContextMenu" class="rps-navigable-lookup-context-menu" ></ul>
                </div>
        </div>
        </div>
       
        <div #dynamicContainer>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors" >
        </error-detail-validator>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsLookup) }],
    inputs: rpsEditor.getComponentInputs(['rpsDataSource']),
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class rpsLookup extends rpsFocusableEditor<string>{
    @ViewChild('dynamicContainer', { read: ViewContainerRef })
    private dynamicContainer: ViewContainerRef;

    public rpsDataSource: rps.data.sources.LookupSource;

    private valuePath: string = "ID";
    private textPath: string = "MainField";
    private isEditable: boolean;
    private descriptor: rps.data.Descriptor;
    private mainDescriptor: string;
    private secondaryDescriptor: string;
    private completeDescriptor: string;
    private link: Array<any>;
    private linkQueryParams: rps.IParams;
    private image: string;
    private kendoComboBox: kendo.ui.ComboBox;
    private lookupFooterComponentRef: ComponentRef<rpsLookupFooter>;
    private countMessage: string = rps.app.resources.directives.NO_ITEMS_TO_DISPLAY;
    private navigateTooltipText: string = rps.app.resources.directives.BUTTON_NAVIGABLE_TOOLTIP;
    private allowContextualSearch: boolean = false;

    private static itemTemplate: string = `
        <div id="template">
            # if (data.ImageField) {#
            <div class="rps-img-2 rps-lookup-popup-image"><img class="rps-img" src="#: data.ImageField #"/></div>
            #}#
            # if (data.SecondaryField ) {#
            <div class="rps-lookup-popup-item-text">#: data.MainField # (#: data.SecondaryField #)</div>
            #} else {#
            <div class="rps-lookup-popup-item-text">#: data.MainField #</div>
            #}#
        </div>
    `;

    private footerTemplate: string = `
        <div id="[id]" class="rps-stack-panel">
        </div>
    `;
    private focusout() {
        if (this.mainDescriptor != this.kendoComboBox.text())
            this.textChange();
        this.isEditing = false;
    }
    private onClick() {
        if (!this.disabled) 
            this.isEditing = true;
    }
    private tabIndexVal() {
        if (this.disabled) {
            return null;
        } else {
            return 0;
        }
       
    }
    private _isOpen: boolean;
    get isOpen(): boolean {
        return this._isOpen;
    }
    set isOpen(value: boolean) {
        this._isOpen = value;
        this._cdr.markForCheck();
    }

    private static kendoContextMenuItemTemplate: string = `
        <div class="navigable-states-container">
            <div class=" rps-img-2 navigable-state-image"><img class="rps-img" src="{0}"/></div>       
            <div class="navigable-state-description">{1}</div>                        
        </div>
    `;
    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        super.ngOnChanges(changes);
        if (changes["rpsDataSource"]) {
            if (this.rpsDataSource)
                this.allowContextualSearch = (this.rpsDataSource.hasColumnsToRender || this.rpsDataSource.hasDescriptorField)
            else
                this.allowContextualSearch = false;
        }
    }

    protected updateTargetValue(newValue: string) {
        super.updateTargetValue(newValue);
        this.resolveHeader();
    }

    createEditableTargetControl() {
        var footerID: string = "footer-" + this.myId;

        //Crear el editor
        //El widget kendoLookupComboBox es un widget que extiende el ComboBox, pero con un pie, por lo que las opciones son las mismas.
        var options: kendo.ui.ComboBoxOptions = {
            filter: "contains",
            dataTextField: this.textPath,
            dataValueField: this.valuePath,
            autoBind: false,
            template: rpsLookup.itemTemplate,
            dataSource: this.getMatches(),            
            change: (e: kendo.ui.ComboBoxChangeEvent) => { this.valueChange(e) },
            text: this.mainDescriptor,
            clearButton: false,
            delay: 300,
            open: () => { this.isOpen = true },
            close: () => { this.isOpen = false }
        };
        options['footerTemplate'] = this.footerTemplate.replace("[id]", footerID);
        this.kendoComboBox = (<any>this.element.find("input"))
            .kendoLookupComboBox(options)
            .data("kendoLookupComboBox");

        rps.app.uiFactory.createComponent<rpsLookupFooter>(rpsLookupFooter, this.dynamicContainer)
            .then((result: ComponentRef<rpsLookupFooter>) => {
                this.lookupFooterComponentRef = result;
                result.instance.setRPSLookup(this);
                var footer = $(document).find("#" + footerID);
                if (footer.length > 0)
                    footer.append(result.location.nativeElement);    
                this._cdr.markForCheck();            
            });

        this.kendoComboBox.input.bind("focusout", () => this.focusout());
        this.kendoComboBox.input.bind("keydown", (e) => this.keydown(e));

        setTimeout(() => {            
            this.kendoComboBox.input.focus();
            this.kendoComboBox.input.select();

            //Cuando el editor está dentro de la grid, se está forzando el “isInEditMode”, por lo que se quita después del retardo
            if (rps.ui.grid.isInEditMode)
                rps.ui.grid.isInEditMode = false;
        });
    }

    destroyEditableTargetControl() {
        this.isOpen = false;
        if (this.lookupFooterComponentRef) {
            this.lookupFooterComponentRef.destroy();
            this.lookupFooterComponentRef = undefined;
        }
        if (this.kendoComboBox) {
            this.kendoComboBox.destroy();
            this.kendoComboBox = undefined;
            this.element.find("input").first().empty();
        }
    }

    enableControl() {
        if (this.kendoComboBox)
            this.kendoComboBox.enable(true);
    }

    disableControl() {
        if (this.kendoComboBox)
            this.kendoComboBox.enable(false);
    }

    resolveHeader() {
        if (this.rpsModel) {
            var value: string = this.rpsModel.value;
            (<rps.viewmodels.properties.LookupProperty>this.rpsModel).getHeader().then((newDescriptor: rps.data.Descriptor) => {
                if (this.rpsModel && value == this.rpsModel.value)
                    this.setHeader(newDescriptor);
            }).catch(() => {
                if (value == this.rpsModel.value)
                    this.setHeader(null);
            });
        }
        else {
            this.setHeader(null);
        }
    }

    setHeader(newHeader: rps.data.Descriptor) {
        this.descriptor = newHeader;
        if (newHeader) {
            this.mainDescriptor = newHeader.MainDescriptor;
            this.secondaryDescriptor = newHeader.SecondaryDescriptor;
            if (newHeader.SecondaryDescriptor)
                this.completeDescriptor = newHeader.MainDescriptor + " (" + newHeader.SecondaryDescriptor + ")";
            else
                this.completeDescriptor = newHeader.MainDescriptor;
            this.image = newHeader.Image;

            if (newHeader.UILink) {
                var link = rps.app.stateManager.createRouterLink(newHeader.UILink);
                this.link = link.routerLink;
                this.linkQueryParams = link.queryParams;
            }
            else {
                this.link = null;
                this.linkQueryParams = null;
            }
        }
        else {
            this.mainDescriptor = "";
            this.secondaryDescriptor = "";
            this.completeDescriptor = "";
            this.image = "";
            this.link = null;
        }
        this._cdr.markForCheck();
        //Si se está resolviendo el valor y ya está creado el control, se le establece el valor
        if (this.kendoComboBox)
            this.kendoComboBox.text(this.mainDescriptor);
    }

    getMatches(): kendo.data.DataSourceOptions {
        return {
            serverFiltering: true,
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) => { this.read(options) }
            },
            serverPaging: true,
            pageSize: 24,
            schema: {
                total: this.total
            }
        };
    }

    total = (items: Array<any>): number => {
        if (items && items["total"])
            return items["total"];
        else
            return 0;
    }

    read(options: kendo.data.DataSourceTransportReadOptions) {
        var filter: string;
        if (options.data.filter && options.data.filter.filters.length == 1)
            filter = (<any>options.data.filter.filters[0]).value;
        this.rpsDataSource.getMatches({
            filter: filter,
            page: 1,
            pageSize: 24
        }).then((result) => {
            if (result.items.length > 0)
                this.countMessage = rps.string.format(rps.app.resources.directives.ITEMS_COUNT, result.items.length, result.count);
            else
                this.countMessage = rps.app.resources.directives.NO_ITEMS_TO_DISPLAY;
            result.items["total"] = result.count;
            if (this.lookupFooterComponentRef)
                this.lookupFooterComponentRef.instance.cdr.markForCheck();
            options.success(result.items);
        }).catch(() => {
            this.countMessage = rps.app.resources.directives.NO_ITEMS_TO_DISPLAY;
            if (this.lookupFooterComponentRef)
                this.lookupFooterComponentRef.instance.cdr.markForCheck();
            options.success([]);
        });
    }

    textChange():Promise<any> {
        var comboBox: kendo.ui.ComboBox = this.kendoComboBox;
        if (this.kendoComboBox.dataItem()) {
            this.updateSourceValue(this.kendoComboBox.dataItem().ID);
            return Promise.resolve<any>(this);
        }
        // Se prueba 1º una búsqueda exacta, si devuelve un valor se establece.
        if (comboBox.text()) {
            return this.rpsDataSource.getMatches({
                filter: comboBox.text(),
                exact: true,
                page: 1,
                pageSize: 1
            }).then((result) => {
                if (result.count == 1)
                    this.updateSourceValue(result.items[0][this.valuePath]);
                else
                    // Se prueba una búsqueda Like, y si devuelve un solo valor se establece
                    return this.rpsDataSource.getMatches({
                        filter: comboBox.text(),
                        exact: false,
                        page: 1,
                        pageSize: 1
                    }).then((result) => {
                        if (result.count == 1)
                            this.updateSourceValue(result.items[0][this.valuePath]);
                        else
                            this.updateSourceValue(null);
                    });
            }).catch(() => {
                this.updateSourceValue(null);
            });
        }
        else {
            this.updateSourceValue(null);
            return Promise.resolve<any>(this);
        }
    }

    valueChange(e: kendo.ui.ComboBoxChangeEvent) {
        //Si la propiedad selectedIndex (que no está en las plantillas), es superior a -1, 
        //significa que tiene algún elemento seleccionado, por lo queel value, 
        //contiene un ID, si no, puede que contenga el string escrito por el usuario
        var comboBox: kendo.ui.ComboBox = this.kendoComboBox;
        if (comboBox["selectedIndex"] > -1)
            this.updateSourceValue(comboBox.value());
        else if (this.mainDescriptor != this.kendoComboBox.text())
            this.textChange();
    }

    addNewCommand: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: this.addNew
    });
    addNew(): Promise<any> {
        rps.app.stateManager.navigateToEntity((<rps.viewmodels.properties.LookupProperty>this.rpsModel).entityName, rps.viewmodels.EntityVM.NEW_ID_VALUE, true);
        return Promise.resolve(this);
    }

    searchCommand: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: this.search
    });
    search(): Promise<any> {
        (<rps.viewmodels.properties.LookupProperty>this.rpsModel).search(this.rpsDataSource);
        return Promise.resolve(this);
    }

    navigateToCommand: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: this.openContextMenu,
        canExecute: this.canNavigateTo
    });
    openContextMenu(): Promise<any> {
        var lookupProperty: rps.viewmodels.properties.LookupProperty = <rps.viewmodels.properties.LookupProperty>this.rpsModel;
        return rps.app.entityManager.getNavigableStates(lookupProperty.entityName,
            lookupProperty.value,
            rps.app.stateManager.getCurrentCompany())
            .then((states: Array<rps.data.NavigableState>) => {
                //Obtenemos los estados de la navegacion y los metemos al dataSource del ContextMenu
                //Se guarda el TargetState en el urlSelect para despues poder acceder al dato y hacer la navegacion                
                var contextMenuDataSource: Array<{ text: string; url: string; urlSelect: rps.data.IUILinkDefinition; encoded: boolean; }> = new Array<{ text: string; url: string; urlSelect: rps.data.IUILinkDefinition; encoded: boolean; }>();
                Enumerable.From(states).OrderBy(s => s.Description).forEach((state) => {
                    contextMenuDataSource.push({
                        text: rps.string.format(rpsLookup.kendoContextMenuItemTemplate, state.Image, state.Description),
                        url: rps.app.stateManager.href(state.TargetState),
                        urlSelect: state.TargetState,
                        encoded: false
                    });
                });

                //Máximo de 10 items por columna
                let navigationListColumns = Math.trunc(contextMenuDataSource.length / 10) + 1;

                var kendoContextMenu: kendo.ui.ContextMenu;
                var container = $(this.elementRef.nativeElement).find("#kendoContextMenu");
                if (container.length > 0) {

                    container.css('columns', navigationListColumns + " auto");

                    //Filter "#none" para que solo se abra el contextMenu donde ejecutemos el "Open". El elemento "#none" no existe.
                    kendoContextMenu = container
                        .kendoContextMenu({
                            dataSource: contextMenuDataSource,
                            orientation: "vertical",
                            filter: "#none",
                            deactivate: (e) => {
                                kendoContextMenu.destroy();
                                $(this.elementRef.nativeElement).find(".contextContainer").html("<ul id='kendoContextMenu' class='rps-navigable-lookup-context-menu' style='columns:" + navigationListColumns + ";' ></ul>");
                            },
                            select: (e) => {
                                //Evitamos que vaya directamente para no romper la navegacion y obtenemos la ruta del DataSource para crear un RouterLink
                                e.preventDefault();
                                var index = $(e.item).index();
                                rps.app.stateManager.goTo(e.sender.options.dataSource[index].urlSelect);
                            }
                        })
                        .data("kendoContextMenu");
                    //Ejecutamos el open para forzar la apertura del contextMenu indicando donde tiene que abrirlo.
                    kendoContextMenu.open($(this.elementRef.nativeElement).find(".rps-lookup-navigate-to-button"));
                }

                return this;
            });
    }

    canNavigateTo(): rps.viewmodels.commands.CanExecuteResult {
        if (this.rpsModel.hasValue())
            return rps.viewmodels.commands.CanExecuteResult.allow();
        else
            return rps.viewmodels.commands.CanExecuteResult.deny([rps.app.resources.directives.SELECT_AN_ITEM]);
    }

    openDropdownCommand: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: this.openDropdown
    });

    openDropdown(): Promise<any> {
        this.isEditing = true;
        setTimeout(() => {
            this.kendoComboBox.open();
        });
        return Promise.resolve(this);
    }


    //Método que invoca la grid, para forzar que el editor recoja el foco
    setFocusAfterInit() {
        rps.ui.grid.isInEditMode = true;
        this.isEditing = true;
    }    

    setFocus() {
        if (this.kendoComboBox)
            this.kendoComboBox.focus();
        else
            this.setFocusAfterInit();
    }

    keydown(value: JQueryKeyEventObject) {
        if (rps.app.userInputManager.getActionForKey(<any>value) == rps.services.Action.Find) {
            this.search();
            value.preventDefault(); 
        }
    }
}
