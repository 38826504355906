import {Http, Response, Headers, URLSearchParams} from '@angular/http';
import {Injectable, Inject} from '@angular/core';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import {rpsAppSettings} from './appSettings';

class typeExtension {
    extensions: Array<string>;
    resolvedType: string;

    constructor() {
        this.extensions = new Array<string>();
    }
}

@Injectable()
export class rpsViewModelFactory implements rps.services.IViewModelFactory {

    /**
    * Diccionario de funciones de componentes ya cargadas; se usa como caché para no crear el mismo tipo dos veces
    */
    private componentTypes: { [id: string]: Promise<Function>; } = {};

    constructor(private _http: Http, private appSettings: rpsAppSettings) {
        this.componentTypes = {};
    }

    configure = () => {
        rps.app.viewModelFactory = this;
    }

    /**
    * Crea una instancia de viewModel cogiendo como parámetro la función de creación, e invocando a toda la cadena de métodos necesaria para la inicialización con los parámetros indicados
    */
    public createViewModel(viewModelFunction: Function | rps.viewmodels.BaseVM, initParams?: rps.IParams): Promise<rps.viewmodels.BaseVM> {
        var newModel: rps.viewmodels.BaseVM = viewModelFunction instanceof rps.viewmodels.BaseVM ? viewModelFunction : rps.object.instantiate(<Function>viewModelFunction);
        return (<any>newModel).configure().then((newModel) => {
            //Establecer los valores de las VMProperties bindeadas a parámetros del estado (quitando las que sean "null")
            var finalParams = (initParams && initParams["stateParams"] ? initParams["stateParams"] : rps.app.stateManager.getStateParams());
            finalParams = JSON.parse(JSON.stringify(finalParams));
            for (var p in finalParams) {
                if (!rps.object.hasValue(finalParams[p]) || finalParams[p] == "null")
                    delete finalParams[p];
            }
            newModel.setStateParameter(finalParams);
            newModel.setStateArguments(finalParams);

            return newModel.initialize(initParams).then((newModel) => {
                newModel.rulesEnabled = true;

                //Establecer parentVM si se pasa antes del navigate, para que esté establecido si se sobreescribe
                if (initParams && initParams["parentVM"])
                    newModel.parentVM = initParams["parentVM"];

                //Guardar la última URL desde la que ha navegado aquí, por si hay que usarla tras un borrado o en la misma navegación
                newModel.navigationSourceUrl = rps.app.stateManager.previousUrl;

                //Llamar a la navegación
                return newModel.navigate(finalParams).then((newModel) => {
                    newModel.processRules.call(newModel, rps.viewmodels.rules.Triggers.OnNavigate);

                    //Mirar si tiene que recuperar el _status del queryString (o si lo tiene que guardar)
                    return newModel.loadState().then(() => {
                        return newModel.processRules(rps.viewmodels.rules.Triggers.OnLoad).then(() => {
                            return newModel;
                        });
                    });
                });
            });
        });
    }

    public createViewModelCollection<MT extends rps.viewmodels.BaseVM, AT extends rps.viewmodels.ObservableArray<MT>>(params: {
        viewModelCollectionFunction: Function;
        initParams: {
            viewModelFunction: Function;
            parentVM: rps.viewmodels.BaseVM;
        };
    }): Promise<AT> {
        var newModelCollection: AT = rps.object.instantiate(params.viewModelCollectionFunction);
        return Promise.resolve<AT>(newModelCollection);
    }

    public createEntityViewModelCollection<VMT extends rps.viewmodels.EntityVM<rps.entities.IBaseEntity>>(params: {
        viewModelCollectionFunction: Function;
        initParams: {
            childCollection: rps.entities.ChildCollection<rps.entities.IBaseEntity>;
            viewModelFunction: Function;
            checkFilters: (viewModel: VMT) => boolean;
            orderDefinition: Array<{ propertyName: string; direction: number }>;
            parentVM: rps.viewmodels.BaseVM;
        };
    }): Promise<rps.viewmodels.EntityVMCollection<VMT>> {
        var newModelCollection: rps.viewmodels.EntityVMCollection<VMT> = rps.object.instantiate(params.viewModelCollectionFunction);
        return newModelCollection.initialize(params.initParams).then((newModelCollection) => {
            return newModelCollection;
        });
    }

    /**
    * Dado un nombre de componente, crea y carga la función correspondiente, y devuelve una instancia de ella correctamente inicializada
    * También crea y carga en memoria todas las funciones de viewModel relacionados con el componente en cuestión
    */
    public createComponent(componentName: string, initParams?: rps.IParams): Promise<rps.viewmodels.ComponentVM> {
        return this.internalCreateComponent(componentName).then((vm) => {
            return <Promise<rps.viewmodels.ComponentVM>>(<any>vm).configure();
        }).then<rps.viewmodels.ComponentVM>((vm) => {

            //Establecer los valores de las VMProperties bindeadas a parámetros del estado (quitando las que sean "null")
            var finalParams = initParams["stateParams"] || rps.app.stateManager.getStateParams();
            finalParams = JSON.parse(JSON.stringify(finalParams));
            for (var p in finalParams) {
                if (!rps.object.hasValue(finalParams[p]) || finalParams[p] == "null")
                    delete finalParams[p];
            }
            (<rps.viewmodels.BaseVM>vm).setStateParameter(finalParams);
            (<rps.viewmodels.BaseVM>vm).setStateArguments(finalParams);

            return vm.initialize(initParams).then((newModel) => {
                (<rps.viewmodels.BaseVM>newModel).rulesEnabled = true;

                //Guardar la última URL desde la que ha navegado aquí, por si hay que usarla tras un borrado o en la misma navegación
                newModel.navigationSourceUrl = rps.app.stateManager.previousUrl;

                //Llamar a la navegación
                return (<rps.viewmodels.BaseVM>newModel).navigate(finalParams).then((newModel) => {
                    (<rps.viewmodels.BaseVM>newModel).processRules.call(newModel, rps.viewmodels.rules.Triggers.OnNavigate);

                    //Mirar si tiene que recuperar el _status del queryString (o si lo tiene que guardar)
                    return newModel.loadState(initParams).then(() => {
                        return (<rps.viewmodels.BaseVM>newModel).processRules(rps.viewmodels.rules.Triggers.OnLoad).then(() => {
                            return newModel;
                        });
                    });
                });
            });
        });
    }

    /**
    * Para un componente, obtiene la función asociada y la instancia
    */
    private internalCreateComponent(componentName: string): Promise<rps.viewmodels.ComponentVM> {
        return this.getComponentFunction(componentName).then((newFunc) => {
            ///Crear un nuevo view model del tipo buscado (resuelto)
            if (newFunc.prototype instanceof rps.viewmodels.BaseVM)
                newFunc = (<any>newFunc).getResolvedType(newFunc);

            var newModel: rps.viewmodels.ComponentVM = rps.object.instantiate(newFunc);
            return newModel;
        });
    }


    /**
    * Para un nombre de componente, devuelve la función que resulta de mezclar y cargar todos los js relacionados con ese componente;
    * la llamada, además del nombre del componente, mezcla y crea todos los js relacionados con ese componente
    */
    public getComponentFunction(componentName: string): Promise<FunctionConstructor> {
        if (!this.componentTypes[componentName]) {
            this.componentTypes[componentName] = new Promise<Function>((resolve, reject) => {
                //Pedir los ficheros implicados en la creación del view model
                this.getViewModelFile(componentName).then((result: string[]) => {
                    try {
                        //Por cada resultado, evaluarlo, y distinguir si es tipo principal o extension (que se guardan para aplicar más tarde)
                        var finalType = null;
                        var finalTypeName = "";
                        var mainTypes = [];
                        var typeExtensions = new rps.collections.Dictionary<string, typeExtension>([]);
                        for (var i = 0; i < result.length; i++) {
                            let jsCode = result[i];
                            //Si no es el primero, se supone que es una extensión; hay que reemplazar la clase de la que hereda
                            if (i > 0) {
                                //Extraer todas las cadenas de extensión del fichero
                                var match = null;
                                var extPattern = /new\s*rps.extensions.Extension\(\s*([\w\.]*)\s*\,*\s*([\w\.]*)\s*\)/g;
                                while (match = extPattern.exec(jsCode)) {
                                    var extension: string = match[1];
                                    let base: string = match[2];
                                    //Primero, mira si está extendiendo la base o una personalizada
                                    let baseName = Enumerable.From(base.split('.')).Last();
                                    var mainBaseType = Enumerable.From(mainTypes).FirstOrDefault(null, mt => rps.extensions.functionName(mt) == baseName);
                                    if (mainBaseType != null) {
                                        //Extiende clase estándar; mirar si ya está extendida
                                        if (!typeExtensions.containsKey(baseName)) {
                                            //Si no está extendida, se registra y se deja como está (heredando directamente)
                                            let ext = new typeExtension();
                                            ext.resolvedType = extension;
                                            ext.extensions.push(baseName);
                                            ext.extensions.push(Enumerable.From(extension.split('.')).Last());
                                            typeExtensions.add(baseName, ext);
                                        }
                                        else {
                                            //Si está extendida, reemplazar la cadena de herencia por la clase resuelta; lo mismo para todas las extensions
                                            let ext: typeExtension = typeExtensions[baseName];
                                            //} (BankAccountCompany.BankAccountCompanyVM));
                                            //    /^\s*\}\(((?:[\w\.\d]*\.||\()BankAccountCompanyVM)\)\);$/gm;
                                            ext.extensions.forEach((eBaseName) => {
                                                var inhPattern = new RegExp("^(\\s*\\}\\()((?:[\\w\\.\\d]*\.||\\()" + eBaseName + ")(\\)\\);)$", "gm");
                                                jsCode = jsCode.replace(inhPattern, "$1" + ext.resolvedType + "$3");
                                            });
                                            ext.resolvedType = extension;
                                            ext.extensions.push(Enumerable.From(extension.split('.')).Last());
                                        }
                                    }
                                }
                            }
                            var types = eval.call(this, jsCode);
                            for (var obj in types) {
                                //TODO: el primero es el principal
                                if (!finalType)
                                    finalType = types[obj];
                                mainTypes.push(types[obj]);
                            }
                        }

                        //Recorrer el diccionario para poner los tipos resueltos
                        typeExtensions.keys().forEach((key) => {
                            let ext: typeExtension = typeExtensions[key];

                            //Sustituir el tipo correspondiente
                            var typeToExtend = Enumerable.From(mainTypes).FirstOrDefault(undefined, mt => rps.extensions.functionName(mt) === key);
                            if (typeToExtend && typeToExtend["fullTypeName"]) {
                                var nameParts: Array<string> = typeToExtend["fullTypeName"].split('.');
                                var target = window;
                                for (var i = 0; i < nameParts.length - 1; i++) {
                                    if (target[nameParts[i]])
                                        target = (target[nameParts[i]]);
                                }
                                var finalNameParts: Array<string> = ext.resolvedType.split('.');
                                var finalTarget = window;
                                for (var i = 0; i < finalNameParts.length - 1; i++) {
                                    if (finalTarget[finalNameParts[i]])
                                        finalTarget = (finalTarget[finalNameParts[i]]);
                                }

                                if (target && target[nameParts[nameParts.length - 1]] &&
                                    finalTarget && finalTarget[finalNameParts[finalNameParts.length - 1]])
                                    target[nameParts[nameParts.length - 1]] = finalTarget[finalNameParts[finalNameParts.length - 1]];
                            }
                        });

                        resolve(finalType);
                    }
                    catch (ex) {
                        reject(ex);
                    }
                }).catch((reason) => {
                    reject(reason);
                });
            });
        }

        return this.componentTypes[componentName];
    }


    /**
    * Obtiene los js correspondientes al componente pedido; se obtienen todos los js que estén incluidos en el fichero de definición del componente
    */
    private getViewModelFile(componentName: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this._http.get(
                this.appSettings.rpsAPIAddress + "clientapi/" + componentName.replace('.', '/') + "/viewModel",
                {
                    body: '',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                    })
                }).map((res: Response, ix?: number) => {
                    return res.json();
                }).subscribe(
                (data: string) => {
                    resolve(data);
                }, (error) => {
                    reject(error);
                });
        });
    }
}