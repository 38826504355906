import {Component, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { baseServerColumn } from './baseServerColumn';

@Component({
    template: `
        <div>{{text}}</div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class rpsTimePickerServerColumn extends baseServerColumn<number> {
    value: number;
    text: string;

    constructor(_cdr: ChangeDetectorRef) {
        super(_cdr);
    }

    setValue(newValue: number) {
        if (this.value !== newValue) {
            this.value = newValue;
            if (this.value) {
                var format = 'HH:mm:ss';
                this.text = kendo.toString(rps.time.convertToDate(this.value), format);
            }
            else {
                this.text = null;
            }
            this._cdr.markForCheck();
        }
    }
} 