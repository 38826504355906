import {Component, OnChanges, SimpleChange, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsSemanticState} from '../utils/semanticState';

@Component({
    selector: 'rps-label',
    template: `
        <div class="rps-editor" 
             [rpsSemanticState]="rpsModel?.semanticState" 
             [class.rps-disabled-editor]="rpsModel?.isLocked">
            <label class="rps-editor-label">
            </label>
            <div>
                <label class="rps-label-label rps-layout-padding">{{value}}</label>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['rpsModel']
})
export class rpsLabel implements OnChanges{    
    rpsModel: rps.viewmodels.properties.VMProperty<string> | string;
    value: string;

    constructor(private changeDetectorRef: ChangeDetectorRef) {        
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {        
        if (changes['rpsModel']) {          
            if (rps.object.hasValue(this.rpsModel)) {
                //En el rpsModel, se puede establecer un string o una vmProperty de tipo string
                //Si es de tipo string, se establece el valor directamente, sino, se establece el valor actual de la vmPorperty y nos subscribimos a su cambio
                if (this.rpsModel instanceof rps.viewmodels.properties.VMProperty) {
                    var vmProperty: rps.viewmodels.properties.VMProperty<any> = <rps.viewmodels.properties.VMProperty<any>>this.rpsModel;
                    vmProperty.propertyChanged.subscribe((value: rps.viewmodels.properties.VMPropertyChange) => {
                        if (value.propertyName == "text") {
                            this.value = vmProperty.text;
                            this.changeDetectorRef.markForCheck();
                        }
                    });
                    this.value = vmProperty.text;
                }
                else
                    this.value = <string>this.rpsModel;
            }
            else {
                this.value = null;
            }
            this.changeDetectorRef.markForCheck();
        }
    }
}
