import { Component, ElementRef, forwardRef, Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsEditor} from './editorBase'
import {errorDetailValidator} from '../utils/errorDetailValidator'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'
import { rpsControl } from '../controlBase'

@Component({
    selector: 'rps-enum-editor',
    template: `
        <div class="rps-editor" 
             [rpsSemanticState]="rpsModel?.semanticState" 
             [class.rps-disabled-editor]="disabled"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label" [attr.for]="myId">{{rpsLabel}}</label>
            <div class="kendoContainer">
                <select class="rps-editor-editor rps-semantic-state" 
                        [attr.id]="myId">
                </select>
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsEnumEditor) }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsEditor.getComponentInputs()
})
export class rpsEnumEditor extends rpsEditor<number> {    

    private kendoControl: kendo.ui.ComboBox;
    private kendoSourceHTML: string;

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }    

    private getStringValue(): string {
        return !rps.object.isNullOrUndefined(this.value) ? this.value.toString() : null;
    }

    createTargetControl() {
        //Busca inputContainer (el div que contiene el input)
        var div = this.element.find(".kendoContainer").first();

        //Se queda con una copia del HTML del input, por si necesita crearlo de nuevo (en caso de que cambien las opciones, por ejemplo)
        if (!this.kendoSourceHTML)
            this.kendoSourceHTML = div.html();

        //Si el control estaba creado, destruirlo y vaciarlo
        if (this.kendoControl) {
            this.kendoControl.destroy();
            div.empty();
            //Volver a crear un input para que pueda llamar al método de Telerik para crear el editor concreto
            div.append(this.kendoSourceHTML);
        }

        //Crear el editor
        this.kendoControl = this.element.find("select").first().kendoComboBox(
            {
                dataValueField: "stringValue",
                dataTextField: "description",
                valuePrimitive:true,
                dataSource: (<rps.viewmodels.properties.EnumProperty>this.rpsModel).enumSource,
                change: () => { this.change() },
                value: this.getStringValue(),
                clearButton:false
            }).data("kendoComboBox");

        //Si estamos en modo edición de la grid, hay que introducirle el foco al editor, ya que significa que hemos entrado en modo edición de la celda
        if (this.focusAfterInit) {
            $(this.elementRef.nativeElement).find("input").focus();
            this.focusAfterInit = false;
            rps.ui.grid.isInEditMode = false; 
        }
    }

    destroyTargetControl() {
        if (this.kendoControl) {
            this.kendoControl.destroy();
            this.kendoControl = null;

            var container = this.element.find(".kendoContainer").first();
            if (container)
                container.empty();
        }
    }

    change() {
        var newValue: string = this.kendoControl.value();
        if (!rps.object.isNullOrUndefined(newValue)) {            
            if (rps.object.isNumeric(newValue))
                this.updateSourceValue(rps.number.toNumber(newValue));
            else
                this.kendoControl.value(this.getStringValue());
        }
        else
            this.kendoControl.value(this.getStringValue());
    }

    updateTargetValue(newValue: number) {
        this.kendoControl.value(this.getStringValue());
    }

    enableControl() {
        this.kendoControl.enable(true);
    }

    disableControl() {
        this.kendoControl.enable(false);
    }


    //Método que invoca la grid, para forzar que el editor recoja el foco
    setFocusAfterInit() {        
        this.focusAfterInit = true;
        rps.ui.grid.isInEditMode = true;
    }
}
