import { Component, ElementRef, AfterViewInit, OnChanges, SimpleChange, OnDestroy, ComponentRef, EmbeddedViewRef} from '@angular/core';
import {rpsItemsControlToolbarItem} from './itemsControlToolbarItem'

export const rpsCardViewBaseTemplate: string = `
        <div>
            <div class="container-fluid"
                    [hidden]="!loaded">
                <div id="kendoListView" 
                        class="row">
                </div>
            </div>
            <div *ngIf="!loaded" 
                    class="rps-unloaded-query">
                {{resources.directives.UNLOADED_QUERY}}
            </div>
            <div #dynamicContainer>
            </div>
        </div>
    `;

export abstract class rpsCardViewBase implements AfterViewInit, OnChanges, OnDestroy {
    rpsSelectionMode: string = "None";
    rpsSource: rps.data.sources.QuerySource | rps.viewmodels.properties.BaseVMCollectionProperty<any, any> | rps.data.sources.EntitySource;

    protected kendoListView: kendo.ui.ListView;
    private selectedItems: Array<any> = new Array<any>();
    private get resources() {
        return rps.app.resources;
    }

    protected get element(): JQuery {
        return $(this.elementRef.nativeElement);
    }

    protected vmProperty: rps.viewmodels.properties.EntityVMCollectionProperty<any>;
    private vmPropertyChangedSub: rps.services.ISubscription;

    private dataSourcePropertyChangedSub: rps.services.ISubscription;
    private dataSourcePropertyChangedToLoadingSub: rps.services.ISubscription;

    //Lista que contiene los componentes creados para la última consulta mostrada
    private components: Array<ComponentRef<any> | EmbeddedViewRef<any>> = new Array<ComponentRef<any> | EmbeddedViewRef<any>>();

    public template: string = `
        <div>
        </div>  
    `;
    public selectable: any = false;
    public items: kendo.data.DataSource;
    public loaded: boolean = false;

    constructor(protected elementRef: ElementRef, private queryToolbarItem: rpsItemsControlToolbarItem) {
    }

    ngAfterViewInit(): any {
        this.createTargetControls();
    }

    ngOnDestroy() {
        if (this.kendoListView) {
            this.kendoListView.destroy();
            this.element.find("#kendoListView").first().empty();
        }

        if (this.dataSourcePropertyChangedSub)
            this.dataSourcePropertyChangedSub.unsubscribe();

        if (this.dataSourcePropertyChangedToLoadingSub)
            this.dataSourcePropertyChangedToLoadingSub.unsubscribe();

        if (this.vmPropertyChangedSub)
            this.vmPropertyChangedSub.unsubscribe();

        //Limpiar los componentes del último resultado
        this.components.forEach((component) => {
            component.destroy();
        });
    }

    //Crea el pager y el listView
    private createTargetControls() {
        //Se crea un nuevo contenedor en el que construir el widget, con la intención de poder borrarlo y dejar todo en el estado inicial
        var kendoListViewDiv: JQuery = jQuery('<div/>');
        this.element.find("#kendoListView").append(kendoListViewDiv);
        this.kendoListView = kendoListViewDiv.kendoListView({
            dataSource: this.items,
            template: this.getItemTemplate(),
            selectable: this.selectable,
            change: this.selectionChanged,
            autoBind: false,
            dataBound: this.kendoListViewDataBound
        }).data("kendoListView");
        //Se le añade esta clase directamente al widget, para que tabule correctamente los ítems dentro del contenedor, ya que si no, aunque los elementos están aparentemente bien pintados, el borde de su contenedor, no les contiene
        this.kendoListView.element.addClass("row");

        //Hack: El widget no actúa correctamente si no le llama el al leer directamente, por lo al crearlo, se comprueba a ver si está leyendo y se recrea el mismo estado.
        if (this.dataSourcePropertyChangedToLoadingSub)
            this.dataSourcePropertyChangedToLoadingSub.unsubscribe();
        if (this.rpsSource instanceof rps.data.sources.QuerySource &&
            this.rpsSource.isReading) {
            this.kendoListView.element.css("height", "300px");
            kendo.ui.progress(this.kendoListView.element, true);            
            this.dataSourcePropertyChangedToLoadingSub = this.rpsSource.propertyChanged.subscribe((value: rps.viewmodels.properties.VMPropertyChange) => {
                if (value.propertyName == "isReading") {
                    this.kendoListView.element.css("height", "");
                    this.dataSourcePropertyChangedToLoadingSub.unsubscribe();
                }                    
            });
        }
        else {
            //HACK: Cuando el origen de datos ya está cargado, hay que refrescar la lista, para que funciona, ya que si no, se queda la lista en blanco…
            this.kendoListView.refresh();
        }

        if (this.selectedItems.length > 0)
            this.syncSelectedItems();
    }

    //Comprueba si se han creado ya los widget de kendo
    private targetControlsCreated(): boolean {
        if (this.kendoListView)
            return true;
        else
            return false;
    }

    //Destruye los controles de kendo y todo su rastro
    private destroyTargetControls() {
        this.kendoListView.destroy();
        this.element.find("#kendoListView").html("");
    }

    kendoListViewDataBound = (e: kendo.ui.ListViewEvent) => {
        this.createItems(e.sender.items());        
    }

    private createItemsCallID: string;
    createItems(dataItems: JQuery) {
        //Limpiar los componentes del último resultado
        this.components.forEach((component) => {
            component.destroy();
        });
        this.components = new Array<ComponentRef<any> | EmbeddedViewRef<any>>();

        //HACK: Se añade un retardo, ya que sino en ocasiones se joden los nuevos componentes al refrescar
        //Ejemplo: En la pantalla de calendarios, refrescar
        this.createItemsCallID = rps.guid.newGuid();
        var currentCallID = this.createItemsCallID;
        setTimeout(() => {
            if (this.createItemsCallID == currentCallID) {
                dataItems.each((index: number, elem: Element) => {
                    var element = jQuery(elem);
                    var dataItem = this.kendoListView.dataItem(element);
                    this.createItem(dataItem, element).then((newComponent: ComponentRef<any>) => {
                        this.components.push(newComponent);
                    });
                });
            }
        });
    }

    abstract createItem(dataItem: any, element: JQuery): Promise<ComponentRef<any> | EmbeddedViewRef<any>>;

    getItemTemplate(): (data: any) => string {
        return kendo.template(this.template);
    }

    syncSelectedItems() {
        var selectedItemEnumerable: linq.Enumerable<any> = Enumerable.From<any>(this.selectedItems);
        if (selectedItemEnumerable.Any()) {
            var selectedElements: Array<Element> = new Array<Element>();
            this.kendoListView.element.children().each((index: number, element: Element) => {
                var dataItem = this.kendoListView.dataItem(element);
                if (selectedItemEnumerable.Contains(dataItem)) {
                    selectedElements.push(element);
                }
            });
            this.kendoListView.clearSelection();
            this.kendoListView.select(selectedElements);
        }
        else {
            this.kendoListView.clearSelection();
        }
    }

    ngOnChanges(changes: { [key: string]: SimpleChange }): any {
        if (changes['rpsSource'])
            this.rpsSourceChanged();
        if (changes['rpsSelectionMode'])
            this.rpsSelectionModeChanged(changes['rpsSelectionMode'].currentValue);
    }

    rpsSelectionModeChanged(newValue: string) {
        if (newValue == "Single")
            this.selectable = "single";
        else if (newValue == "Multiple")
            this.selectable = "multiple";
        else
            this.selectable = false;
    }

    rpsSourceChanged() {
        this.selectedItems = new Array<any>();
        if (this.dataSourcePropertyChangedSub)
            this.dataSourcePropertyChangedSub.unsubscribe();
        if (this.rpsSource instanceof rps.data.sources.QuerySource ||
            this.rpsSource instanceof rps.data.sources.EntitySource) {           
            this.setItems(this.rpsSource.dataSource);
            this.dataSourcePropertyChangedSub = this.rpsSource.propertyChanged.subscribe((e: rps.viewmodels.properties.VMPropertyChange) => {
                this.dataSourcePropertyChanged(e);
            });
            this.selectedItemsChanged();
            this.loadedChanged(this.rpsSource.loaded);
        }
        else {
            this.loaded = true;
            var newDataSource: kendo.data.DataSource = new kendo.data.DataSource({
                data: []
            });
            newDataSource.online(false);
            this.setItems(newDataSource);
            this.setVMProperty(this.rpsSource);
        }
    }

    setItems(newItems: kendo.data.DataSource) {
        this.items = newItems;

        //En el caso de que ya estén creados los widget de Kendo, se destruyen y se vuelven a generar, con la intención de refrescar todos los “bindings”
        if (this.targetControlsCreated()) {
            this.destroyTargetControls();
            this.createTargetControls();
        }
    }

    setVMProperty(newValue) {
        this.vmProperty = newValue;

        if (this.vmProperty) {
            if (this.vmPropertyChangedSub)
                this.vmPropertyChangedSub.unsubscribe();
            this.vmPropertyChangedSub = this.vmProperty.propertyChanged.subscribe((e: rps.viewmodels.properties.VMPropertyChange) => {
                this.vmPropertyPropertyChanged(e);
            });
            this.valueChanged(this.vmProperty.value);
            this.selectedItemsChanged();
        }
        else {
            this.items.data([]);
        }
    }

    vmPropertyPropertyChanged(e: rps.viewmodels.properties.VMPropertyChange) {
        if (e.propertyName == "value")
            this.valueChanged(e.newValue);
        else if (e.propertyName == "selectedItems")
            this.selectedItemsChanged();
    }

    valueChanged(newValue) {
        this.items.data(newValue);
    }

    selectedItemsChanged() {
        if (this.selectedItems.length > 0 || this.rpsSource.hasSelectedItems()) {
            if (this.selectedItems.length == this.rpsSource.getSelectedItems().length) {
                var querySourceEnumerable: linq.Enumerable<any> = Enumerable.From<any>(this.rpsSource.getSelectedItems());
                if (!Enumerable.From<any>(this.selectedItems).Any(i => !querySourceEnumerable.Contains(i)))
                    return;
            }
            this.selectedItems = this.rpsSource.getSelectedItems();
            if (this.kendoListView)
                this.syncSelectedItems();
        }
    }

    dataSourcePropertyChanged(e: rps.viewmodels.properties.VMPropertyChange) {
        if (e.propertyName == "selectedItems")
            this.selectedItemsChanged();
        else if (e.propertyName == "loaded")
            this.loadedChanged(e.newValue);
    }

    selectionChanged = (e) => {
        var selectedRows = this.kendoListView.select();
        var selectedDataItems = [];
        for (var i = 0; i < selectedRows.length; i++) {
            var dataItem = this.kendoListView.dataItem(selectedRows[i]);
            selectedDataItems.push(dataItem);
        }
        if (this.rpsSource &&
            (this.rpsSource instanceof rps.data.sources.QuerySource || this.rpsSource instanceof rps.data.sources.EntitySource)) {
            this.selectedItems = selectedDataItems;
            this.rpsSource.setSelectedItems(selectedDataItems);
        }
        else if (this.vmProperty) {
            this.selectedItems = selectedDataItems;
            this.vmProperty.setSelectedItems(selectedDataItems);
        }
    }

    loadedChanged(newValue) {
        this.loaded = newValue;
    }

}