import { Component, ElementRef, forwardRef, Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsEditor} from './editorBase'
import {errorDetailValidator} from '../utils/errorDetailValidator'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'
import { rpsControl } from '../controlBase'

@Component({
    selector: 'rps-combo-box',
    template: `
        <div class="rps-editor" 
            [rpsSemanticState]="rpsModel?.semanticState" 
            [class.rps-disabled-editor]="disabled"
            [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label" 
                   [attr.for]="myId">
                {{rpsLabel}}
            </label>
            <div class="kendoContainer">
                <select class="rps-editor-editor rps-semantic-state" 
                        [attr.id]="myId">
                </select>
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsComboBox) }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsEditor.getComponentInputs(["rpsEditable"])
})
export class rpsComboBox extends rpsEditor<string> {

    //Si  tiene rpsEditable, no es IsLimitedToList
    private _rpsEditable: boolean = false;
    get rpsEditable(): string {
        if (this._rpsEditable)
            return "true";
        else
            return "false";
    }
    set rpsEditable(value: string) {
        if (value == "true")
            this._rpsEditable = true;
        else
            this._rpsEditable = false;
    }

    private kendoControl: kendo.ui.ComboBox;
    private kendoSourceHTML: string;

    //Propiedad que contiene el comboSource del vmProperty
    protected comboSource: rps.data.sources.ComboSource;

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    createTargetControl() {
        //Busca inputContainer (el div que contiene el input)
        var div = this.element.find(".kendoContainer").first();

        //Se queda con una copia del HTML del input, por si necesita crearlo de nuevo (en caso de que cambien las opciones, por ejemplo)
        if (!this.kendoSourceHTML)
            this.kendoSourceHTML = div.html();

        //Si el control estaba creado, destruirlo y vaciarlo
        if (this.kendoControl) {
            this.kendoControl.destroy();
            div.empty();
            //Volver a crear un input para que pueda llamar al método de Telerik para crear el editor concreto
            div.append(this.kendoSourceHTML);
        }

        //Crear el editor
        this.kendoControl = this.element.find("select").first().kendoComboBox(
            {
                dataValueField: "value",
                filter:"contains",
                dataTextField: "description",
                valuePrimitive: true,
                dataSource: this.comboSource,
                change: (e: kendo.ui.ComboBoxChangeEvent) => { this.change(e) },
                value: this.value,
                clearButton:false
            }).data("kendoComboBox");
    }

    destroyTargetControl() {
        if (this.kendoControl)
            this.kendoControl.destroy();
    }

    change(e: kendo.ui.ComboBoxChangeEvent) {
        var comboBox: any = e.sender;
        var selectedItem = null;        
        for (var i = 0; i < this.comboSource.length; i += 1) {
            if (this.comboSource[i]["value"] == comboBox.value()) {
                selectedItem = this.comboSource[i];
                break;
            }
        }
        if (selectedItem != null)
            this.updateSourceValue(selectedItem["value"]);
        else if(this._rpsEditable)
            this.updateSourceValue(comboBox.value());
        else
            this.updateSourceValue(null);
    }

    updateSourceValue(newValue: string) {
        //Si el valor que se quiere establecer en la VMProperty es el que ya tiene
        //Se actualiza el control, ya que es este el que está desincronizado
        if (this.rpsModel && this.rpsModel.value == newValue)
            this.updateTargetValue(newValue);
        else
            super.updateSourceValue(newValue);
    }

    //Al cambiar el vmProperty, también hay que refrescar el origen de datos del combo, para esto se hace lo mismo que para el value en la base, con la diferencia de que el origen de datos no varía, por lo que no hay que tenerlo en cuenta en el método rpsModelPropertyChanged
    //Para esto se sobreescribe el onChangeModel y se crean el setComboSource y updateTargetDataSource
    onChangeModel() {
        if (this.rpsModel)
            this.setComboSource((<rps.viewmodels.properties.ComboProperty>this.rpsModel).comboSource);
        else
            this.setComboSource(null);

        super.onChangeModel();
    }
    setComboSource(newValue: rps.data.sources.ComboSource) {
        if (this.comboSource != newValue) {
            this.comboSource = newValue;
            if (this.contentInitialized)
                this.updateTargetComboSource(newValue);
        }
    }
    updateTargetComboSource(newValue: rps.data.sources.ComboSource) {
        this.kendoControl.setDataSource(<any>newValue);
    }

    updateTargetValue(newValue: string) {
        //HACK: Si está sin definir, se establece un null, porque si no, el widget, se queda el valor…
        if (rps.string.isNullOrEmpty(this.value))
            this.kendoControl.value(null);
        else
            this.kendoControl.value(this.value);
    }

    enableControl() {
        this.kendoControl.enable(true);
    }

    disableControl() {
        this.kendoControl.enable(false);
    }

}

