import {ElementRef, AfterViewInit, OnChanges, SimpleChange, OnDestroy, ChangeDetectorRef } from '@angular/core';

export class rpsControl {
   
    //Propiedad creada para poder explotar los recursos desde las plantillas
    protected get resources() {
        return rps.app.resources;
    }

    constructor() {
    }

    /**
     * Método llamado para que el control coja el foco. Por defecto devuelve false, para que el framework intente
     * establecer el foco en otro control
     */
    focus(): boolean {        
        return false;
    }
}
