import { Component, ElementRef, AfterContentInit, OnDestroy} from '@angular/core';
import {rpsContainerService} from './container';

@Component({
    selector: 'rps-horizontal-splitter',
    template: `
        <div id="horizontal" style="height: 100%; width: 100%;">
            <ng-content></ng-content>
        </div>
    `
})
export class rpsHorizontalSplitter implements AfterContentInit,OnDestroy{ 

    private items: Array<rpsHorizontalSplitterItem> = new Array<rpsHorizontalSplitterItem>();
    private refreshSub: rps.services.ISubscription;

    private get element(): JQuery {
        return $(this.elementRef.nativeElement);
    }
    private kendoSplitter: kendo.ui.Splitter;

    constructor(private elementRef: ElementRef, private containerService: rpsContainerService) {
        this.refreshSub = containerService.refreshContainer$.subscribe(this.refreshWidget);
    }

    ngAfterContentInit() {
        this.createWidget();
    }

    ngOnDestroy() {
        if (this.kendoSplitter) {
            this.kendoSplitter.destroy();
            this.kendoSplitter = null;
            this.element.find("#horizontal").first().empty();
        }        

        if (this.refreshSub)
            this.refreshSub.unsubscribe();
    }

    addItem(item: rpsHorizontalSplitterItem) {
        this.items.push(item);
    }

    private createWidget() {
        var panes: Array<kendo.ui.SplitterPane> = new Array<kendo.ui.SplitterPane>();
        this.items.forEach((item) => {
            panes.push({
                collapsible: true,
                size:item.getHorizontalPercentage()
            });
        });
        this.kendoSplitter = this.element.find("#horizontal").kendoSplitter({
            panes: panes
        }).data("kendoSplitter");       
    }

    private refreshWidget = () => {        
        setTimeout(() => {
            if (this.kendoSplitter)
                this.kendoSplitter.resize(true);
        });
    }
}

@Component({
    selector: 'rps-horizontal-splitter-item',
    template: `
        <ng-content></ng-content>
    `,
    inputs: ['rpsHorizontalSize']
})
export class rpsHorizontalSplitterItem {
    rpsHorizontalSize: string = "12";

    constructor(private horizontalSplitter: rpsHorizontalSplitter) {
        horizontalSplitter.addItem(this);
    }

    getHorizontalPercentage() {
        return rps.string.format("{0}%", (rps.number.toNumber(this.rpsHorizontalSize) * 100 / 12));
    }
}