import { Component, ElementRef, forwardRef, Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsEditor} from './editorBase'
import {errorDetailValidator} from '../utils/errorDetailValidator'
import {rpsRowService} from '../layouts/row'
import { rpsControl } from '../controlBase'

@Component({
    selector: 'rps-color-editor',
    template: `
        <div class="rps-editor" 
             [class.rps-disabled-editor]="disabled"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label" 
                   [attr.for]="myId">
                {{rpsLabel}}
            </label>
            <div class="kendoContainer">
                <input class="rps-color-editor-input" [attr.id]="myId"/>
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsColorEditor) }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsEditor.getComponentInputs()
})
export class rpsColorEditor extends rpsEditor<number> {

    private kendoControl: kendo.ui.ColorPicker;

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    createTargetControl() {
        //Comprobar si contiene el foco, para volver a introducírselo
        var focused: boolean = $(this.elementRef.nativeElement).find("input").is(":focus");

        //Crear el editor
        this.kendoControl = this.element.find("input").first().kendoColorPicker(
            {
                change: () => { this.change() },
                value: this.getValueString(this.value)
            }).data("kendoColorPicker");

        if (focused)
            $(this.elementRef.nativeElement).find(".k-colorpicker").focus();
    }

    destroyTargetControl() {
        //Si el control estaba creado, destruirlo y vaciarlo
        if (this.kendoControl) {
            //HACK: Casca al eliminar el widget, por lo que se controla el error hasta que lo arreglen…
            try {
                this.kendoControl.destroy();
            }
            catch (err){
            }
            this.kendoControl = null;

            //Busca inputContainer (el div que contiene el input)
            var div = this.element.find(".kendoContainer");
            div.empty();
        }
    }

    change() {
        var newValue: string = this.kendoControl.value();
        if (!rps.object.isNullOrUndefined(newValue)) {
            this.updateSourceValue( this.getValueNumber(newValue));
        }
    }

    updateTargetValue(newValue: number) {
        this.kendoControl.value(this.getValueString(this.value));
    }

    enableControl() {
        this.kendoControl.enable(true);

    }

    disableControl() {
        if (this.kendoControl) {
            this.kendoControl.enable(false);          
        }
    }

    getValueString(numericValue: number): string {
        return rps.color.getHexValue(numericValue);
    }

    getValueNumber(stringValue: string): number {
        return rps.color.getNumericValue(stringValue);
    }

    //Método que invoca la grid, para forzar que el editor recoja el foco
    setFocusAfterInit() {
        $(this.elementRef.nativeElement).find("input").focus();
    }

}
