import { Component, Input, OnChanges, SimpleChanges, NgZone, ElementRef, AfterViewInit,OnDestroy,Injectable,Optional} from '@angular/core';

@Injectable()
export class errorDetailValidatorService {
    public errors: Array<rps.errors.ErrorDetail> = new Array<rps.errors.ErrorDetail>();
    public errorsChanged$: rps.services.IEventEmitter<Array<rps.errors.ErrorDetail>> = rps.app.eventManager.createEmitter<Array<rps.errors.ErrorDetail>>();

    addErrors(newValue: Array<rps.errors.ErrorDetail>) {
        if (newValue && newValue.length) {
            newValue.forEach((error) => {
                this.errors.push(error);
            });
            this.errorsChanged$.emit(newValue);
        }
    }

    addErrorDetails(newValue: rps.errors.ErrorDetails) {
        if (newValue && newValue.toArray)
            this.addErrors(newValue.toArray());
    }

    removeErrors(oldValue: Array<rps.errors.ErrorDetail>) {
        if (oldValue && oldValue.length) {
            oldValue.forEach((error) => {
                if (this.errors.indexOf(error) >= 0)
                    this.errors.splice(this.errors.indexOf(error),1)
            });
            this.errorsChanged$.emit(oldValue);
        }
    }

    removeErrorDetails(oldValue: rps.errors.ErrorDetails) {
        if (oldValue && oldValue.toArray)
            this.removeErrors(oldValue.toArray());
    }

    hasErrors(): boolean {
        if (this.errors.length)
            return true;
        else
            return false;
    }
}

/**
 * Componente que muestra la lista de errores que recoge como par�metro. 
 */
@Component({
    selector: 'error-detail-validator',
    template: `
        <div class="rps-error-detail-vertical">
            <ul class="rps-error-detail-validator-list">
                <li *ngFor="let error of (errorDetails?.toArray())" 
                    class="rps-error-detail-validator-list-item">
                    <i class="fa fa-exclamation-circle rps-error-detail-validator-list-icon "></i>
                    <div class="rps-error-detail-validator-list-content">{{error.description}}</div>
                </li>
            </ul>
        </div>
        <div class="rps-error-detail-horizontal">
            <i class="fa fa-exclamation-circle"></i>
        </div>
  `
})
export class errorDetailValidator implements AfterViewInit, OnChanges, OnDestroy{
    @Input() errorDetails: rps.errors.ErrorDetails;

    private errorsChangedSubscription: rps.services.ISubscription;
    private kendoTooltip: kendo.ui.Tooltip;

    constructor(protected elementRef: ElementRef, @Optional() private errorDetailValidatorService?: errorDetailValidatorService) {
    }

    ngAfterViewInit(): any {
        this.kendoTooltip = $(this.elementRef.nativeElement).kendoTooltip({
            filter: ".rps-error-detail-horizontal",
            position: "bottom",
            content: (e) => {                
                var message: string = "";
                this.errorDetails.forEach((errorDetail: rps.errors.ErrorDetail) => {
                    if (message.length > 0)
                        message += "<br>";
                    if (errorDetail.code)
                        message += `${errorDetail.description} (${errorDetail.code})`;
                    else
                        message += errorDetail.description;
                    
                });
                return message;
            },
            showAfter: rps.utils.getTooltipDelay()
        }).data("kendoTooltip");
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["errorDetails"]) {
            if (this.errorDetailValidatorService && changes["errorDetails"].previousValue)
                this.errorDetailValidatorService.removeErrorDetails((<rps.errors.ErrorDetails>changes["errorDetails"].previousValue));
            if (this.errorsChangedSubscription)
                this.errorsChangedSubscription.unsubscribe();

            if (this.errorDetails) {
                if (this.errorDetailValidatorService)
                    this.errorDetailValidatorService.addErrorDetails(this.errorDetails);
                this.errorsChangedSubscription = this.errorDetails.errorsChanged.subscribe((changes) => {
                    if (this.errorDetailValidatorService) {
                        this.errorDetailValidatorService.removeErrors(changes.oldErrors);
                        this.errorDetailValidatorService.addErrors(changes.newErrors);
                    }
                    if (this.kendoTooltip)
                        this.kendoTooltip.refresh();
                });
            }
        }
    }

    ngOnDestroy() {
        if (this.errorDetailValidatorService && this.errorDetails)
            this.errorDetailValidatorService.removeErrorDetails(this.errorDetails);
        if (this.errorsChangedSubscription)
            this.errorsChangedSubscription.unsubscribe();
    }
}
