import {
    Component,
    ElementRef,
    NgZone,
    SimpleChange,
    OnChanges,
    AfterViewInit,
    ComponentRef,
    OnDestroy,
    ViewChild,
    Output,
    EventEmitter,
    OpaqueToken,
    ViewContainerRef,
    Optional,
    Injector,
    Inject,
    Type,
    AfterViewChecked,
    NgModule, ChangeDetectorRef
} from '@angular/core';
import { NgClass } from '@angular/common';
import { rpsWindowAction } from '../layouts/window';
import { rpsReadOnlyEmailEditor } from '../readOnlyEditors/readOnlyEmailEditor'
import { rpsEmailEditor } from '../editors/emailEditor'
import { rpsReadOnlyColorEditor } from '../readOnlyEditors/readOnlyColorEditor'
import { rpsColorEditor } from '../editors/colorEditor'
import { rpsReadOnlyUrlEditor } from '../readOnlyEditors/readOnlyUrlEditor'
import { rpsUrlEditor } from '../editors/urlEditor'
import { rpsReadOnlyDatePicker } from '../readOnlyEditors/readOnlyDatePicker'
import { rpsDatePicker } from '../editors/datePicker'
import { rpsReadOnlyDecimalEditor } from '../readOnlyEditors/readOnlyDecimalEditor'
import { rpsDecimalEditor } from '../editors/decimalEditor'
import { rpsReadOnlyPercentageEditor } from '../readOnlyEditors/readOnlyPercentageEditor'
import { rpsPercentageEditor } from '../editors/percentageEditor'
import { rpsReadOnlyDurationEditor } from '../readOnlyEditors/readOnlyDurationEditor'
import { rpsDurationEditor } from '../editors/durationEditor'
import { rpsReadOnlyIntegerEditor } from '../readOnlyEditors/readOnlyIntegerEditor'
import { rpsIntegerEditor } from '../editors/integerEditor'
import { rpsReadOnlyTextBox } from '../readOnlyEditors/readOnlyTextBox'
import { rpsTextBox } from '../editors/textBox'
import { rpsReadOnlyLookup } from '../readOnlyEditors/readOnlyLookup'
import { rpsLookup } from '../editors/lookup'
import { rpsReadOnlyDataComboBox } from '../readOnlyEditors/readOnlyDataComboBox'
import { rpsDataComboBox } from '../editors/dataComboBox'
import { rpsReadOnlyEnumEditor } from '../readOnlyEditors/readOnlyEnumEditor'
import { rpsEnumEditor } from '../editors/enumEditor'
import { rpsCheckBox } from '../editors/checkBox'
import { rpsReadOnlyAmountEditor } from '../readOnlyEditors/readOnlyAmountEditor'
import { rpsAmountEditor } from '../editors/amountEditor'
import { rpsReadOnlyPriceEditor } from '../readOnlyEditors/readOnlyPriceEditor'
import { rpsPriceEditor } from '../editors/priceEditor'
import { rpsReadOnlyQuantityEditor } from '../readOnlyEditors/readOnlyQuantityEditor'
import { rpsQuantityEditor } from '../editors/quantityEditor'
import { rpsReadOnlyAddress } from '../readOnlyEditors/readOnlyAddress'
import { rpsReadOnlyAddressValue } from '../readOnlyEditors/readOnlyAddressValue'
import { rpsReadOnlyImageEditor } from '../readOnlyEditors/readOnlyImageEditor'
import { rpsImageEditor } from '../editors/imageEditor'
import { rpsIcon } from '../readOnlyEditors/icon'
import { rpsDocuments } from '../readOnlyEditors/documents'
import { rpsDatePickerServerColumn } from '../gridColumns/datePickerServerColumn'
import { rpsLookupServerColumn } from '../gridColumns/lookupServerColumn'
import { rpsFormattedNumberServerColumn } from '../gridColumns/formattedNumberServerColumn'
import { rpsCheckBoxServerColumn } from '../gridColumns/checkBoxServerColumn'
import { rpsIconServerColumn } from '../gridColumns/iconServerColumn'
import { rpsDocumentsServerColumn } from '../gridColumns/documentsServerColumn'
import { rpsAddressServerColumn } from '../gridColumns/addressServerColumn'
import { rpsReadOnlyEntityLink } from '../readOnlyEditors/readOnlyEntityLink'
import { rpsReadOnlyFormattedNumber } from '../readOnlyEditors/readOnlyFormattedNumber'
import { rpsButton } from '../editors/button'
import { rpsImageEditorServerColumn } from '../gridColumns/imageEditorServerColumn'
import { rpsReadOnlyTimePicker } from '../readOnlyEditors/readOnlyTimePicker'
import { rpsTimePicker } from '../editors/timePicker'
import { rpsTimePickerServerColumn } from '../gridColumns/timePickerServerColumn'
import { rpsItemsControlToolbarService } from './itemsControlToolbarItem'
import { rpsLongTextBoxLittle } from '../editors/longTextBoxLittle'
import { rpsReadOnlyLongTextBoxLittle } from '../readOnlyEditors/readOnlyLongTextBoxLittle'
import { JitCompiler } from '@angular/compiler';
import { rpsModule, rpsDesktopModule, rpsNarrowModule } from '../modules';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { rpsControl } from '../controlBase'

@Component({
    selector: 'rps-grid',
    template: `
        <div class="rps-grid">
            <div [ngClass]="{'rps-items-control-detail-opened': itemDetailFormOpened}">            
                <div class="rps-prevent-scroll-grid">
                    <div class="kendoGridContainer" 
                            [hidden]="!loaded">
                    </div>
                    <div *ngIf="!loaded" 
                            class="rps-unloaded-query">
                        {{resources.directives.UNLOADED_QUERY}}
                    </div>
                    <div #dynamicContainer>
                    </div>
                </div>
            </div>
            <div #itemsControlDetail>
            </div>
        <div>
  `,
    inputs: ['rpsSource', 'rpsColumnsDefinition', 'rpsLinkPropertyPath', 'rpsSelectionMode', 'rpsAutoFit']
})
//Cuidado, si queremos cambiar el resultado de la grid, tendremos que establecer el origen de datos y las columnas a la vez, ya que si no, se creará con las columnas anteriores.
//Esto está hecho, para evitar que cuando se cambia el origen de datos y las columnas, se regenere dos veces.
export class rpsGrid extends rpsControl implements AfterViewInit, OnChanges, OnDestroy, AfterViewChecked {
    @ViewChild('dynamicContainer', { read: ViewContainerRef })
    private dynamicContainer: ViewContainerRef;

    @ViewChild('itemsControlDetail', { read: ViewContainerRef })
    private itemsControlDetail: ViewContainerRef;

    @Output() rpsComponentCreated = new EventEmitter<rpsGrid>();

    //Variable creada para retrasar la generación de los widgets hasta que el contenedor tenga tamaño
    private createKendoWidgetsPending: boolean = false;
    private formTemplate: string;
    private windowTemplate: string;
    private itemDetailComponent: ComponentRef<any>;
    private detailTarget: JQuery;
    //Propiedad que indica, como se quiere mostrar el detalle de una línea (0:Form,1:Window)
    private itemDetailType: number = 0;
    private _forceEdit: boolean = false;

    public kendoGrid: kendo.ui.Grid;
    private kendoTooltipTD: kendo.ui.Tooltip;
    private kendoTooltipDelay: kendo.ui.Tooltip;
    private viewInitialised: boolean = false;

    private vmPropertyChangedSub: rps.services.ISubscription;
    private childVMsPropertyChangedSub: Array<rps.services.ISubscription> = new Array<rps.services.ISubscription>();

    private selectedItems: Array<any> = new Array<any>();

    protected myId: string;

    public allowDelete: boolean;
    public vmProperty: rps.viewmodels.properties.EntityVMCollectionProperty<any>;

    public rpsSource: rps.data.sources.QuerySource | rps.viewmodels.properties.BaseVMCollectionProperty<any, any> | rps.data.sources.EntitySource;
    private rpsSourcePropertyChangedSub: rps.services.ISubscription;
    private rpsQuerySourcePropertyChangedSub: rps.services.ISubscription;

    private dataItemPropertyChangedSubs = new Array<rps.services.ISubscription>();

    public rpsLinkPropertyPath: string;
    get rpsColumnsDefinition(): string {
        return JSON.stringify(this.columnsDefinition);
    }
    set rpsColumnsDefinition(value: string) {
        if (rps.string.isNullOrEmpty(value))
            this.columnsDefinition = undefined;
        else
            this.columnsDefinition = JSON.parse(value);
    }
    public columnsDefinition: Array<rps.ui.itemsControl.ColumnDefinition>;
    public rpsSelectionMode: string;
    //Si se establece cualquier valor, el ancho de las columnas se adapta a la anchura de la grid y evita el scroll
    public rpsAutoFit: string;

    public showPager: boolean = false;
    public sortable: boolean = false;
    public items: any;
    public toolbar: kendo.ui.GridToolbarItem[] = [];
    public selectable: number = -1;
    public autoBind: boolean = false;
    public loaded: boolean = false;
    //Propiedad que indica que el itemsControl, está mostrando el detalle de un elemento
    public itemDetailFormOpened: boolean = false;

    constructor(private elementRef: ElementRef, private zone: NgZone, @Optional() private sourcesToolbarService: rpsItemsControlToolbarService,
        private compiler: JitCompiler, private cdRef: ChangeDetectorRef) {
        super();
        this.myId = rps.guid.newGuid().split("-").join("_");
        //Se crea una función por cada tipo de columna que tiene que generar un componente, por cada instancia de grid
        rps.ui.itemsControl[this.myId] = {};
        rps.ui.itemsControl[this.myId].loadTimePickerColumn = this.loadTimePickerColumn;
        rps.ui.itemsControl[this.myId].loadDataComboBoxColumn = this.loadDataComboBoxColumn;
        rps.ui.itemsControl[this.myId].loadEnumEditorColumn = this.loadEnumEditorColumn;
        rps.ui.itemsControl[this.myId].loadDecimalEditorColumn = this.loadDecimalEditorColumn;
        rps.ui.itemsControl[this.myId].loadPercentageEditorColumn = this.loadPercentageEditorColumn;
        rps.ui.itemsControl[this.myId].loadIntegerEditorColumn = this.loadIntegerEditorColumn;
        rps.ui.itemsControl[this.myId].loadTextBoxColumn = this.loadTextBoxColumn;
        rps.ui.itemsControl[this.myId].loadDatePickerColumn = this.loadDatePickerColumn;
        rps.ui.itemsControl[this.myId].loadLookupColumn = this.loadLookupColumn;
        rps.ui.itemsControl[this.myId].loadCheckBoxColumn = this.loadCheckBoxColumn;
        rps.ui.itemsControl[this.myId].loadAmountEditorColumn = this.loadAmountEditorColumn;
        rps.ui.itemsControl[this.myId].loadPriceEditorColumn = this.loadPriceEditorColumn;
        rps.ui.itemsControl[this.myId].loadQuantityEditorColumn = this.loadQuantityEditorColumn;
        rps.ui.itemsControl[this.myId].loadDurationEditorColumn = this.loadDurationEditorColumn;
        rps.ui.itemsControl[this.myId].loadImageEditorColumn = this.loadImageEditorColumn;
        rps.ui.itemsControl[this.myId].loadColorEditorColumn = this.loadColorEditorColumn;
        rps.ui.itemsControl[this.myId].loadAddressColumn = this.loadAddressColumn;
        rps.ui.itemsControl[this.myId].loadAddressValueColumn = this.loadAddressValueColumn;
        rps.ui.itemsControl[this.myId].loadIconColumn = this.loadIconColumn;
        rps.ui.itemsControl[this.myId].loadDocumentsColumn = this.loadDocumentsColumn;
        rps.ui.itemsControl[this.myId].loadDatePickerServerColumn = this.loadDatePickerServerColumn;
        rps.ui.itemsControl[this.myId].loadLookupServerColumn = this.loadLookupServerColumn;
        rps.ui.itemsControl[this.myId].loadFormattedNumberServerColumn = this.loadFormattedNumberServerColumn;
        rps.ui.itemsControl[this.myId].loadCheckBoxServerColumn = this.loadCheckBoxServerColumn;
        rps.ui.itemsControl[this.myId].loadTimePickerServerColumn = this.loadTimePickerServerColumn;
        rps.ui.itemsControl[this.myId].loadIconServerColumn = this.loadIconServerColumn;
        rps.ui.itemsControl[this.myId].loadDocumentsServerColumn = this.loadDocumentsServerColumn;
        rps.ui.itemsControl[this.myId].loadAddressServerColumn = this.loadAddressServerColumn;
        rps.ui.itemsControl[this.myId].loadEntityLinkColumn = this.loadEntityLinkColumn;
        rps.ui.itemsControl[this.myId].loadFormattedNumberColumn = this.loadFormattedNumberColumn;
        rps.ui.itemsControl[this.myId].loadButtonColumn = this.loadButtonColumn;
        rps.ui.itemsControl[this.myId].loadImageEditorServerColumn = this.loadImageEditorServerColumn;
        rps.ui.itemsControl[this.myId].loadUrlColumn = this.loadUrlColumn;
        rps.ui.itemsControl[this.myId].loadMailtoColumn = this.loadMailtoColumn;
        rps.ui.itemsControl[this.myId].loadLongTextBoxLittleColumn = this.loadLongTextBoxLittleColumn;
    }

    ngOnDestroy() {
        //Eliminar todos los componentes
        for (var key in this.rowColumnComponents) {
            this.rowColumnComponents[key].destroy();
        }
        for (var key in this.rowColumnEditableComponents) {
            this.rowColumnEditableComponents[key].destroy();
        }
        //Eliminar la clase con los métodos estaticos
        delete rps.ui.itemsControl[this.myId];

        if (this.kendoGrid) {

            //Eliminar el evento de click de selección de registro
            if (this.kendoGrid.table)
                this.kendoGrid.table.unbind("click");

            this.kendoGrid.options.dataBound = null;
            var c = this.kendoGrid.options.columns.length;
            for (var i = 0; i < c; i++) {
                this.kendoGrid.options.columns[i].editor = null;
                this.kendoGrid.options.columns[i].template = null;
            }
            this.kendoGrid.options.columns = null;
            this.kendoGrid.destroy();

            this.childVMsPropertyChangedSub.forEach((sub) => {
                sub.unsubscribe();
            });
            this.childVMsPropertyChangedSub = new Array<rps.services.ISubscription>();
        }

        if (this.vmPropertyChangedSub)
            this.vmPropertyChangedSub.unsubscribe();

        if (this.rpsSourcePropertyChangedSub)
            this.rpsSourcePropertyChangedSub.unsubscribe();

        if (this.rpsQuerySourcePropertyChangedSub)
            this.rpsQuerySourcePropertyChangedSub.unsubscribe();

        this.dataItemPropertyChangedSubs.forEach((sub) => sub.unsubscribe());

        if (this.kendoTooltipDelay)
            this.kendoTooltipDelay.destroy();
        if (this.kendoTooltipTD)
            this.kendoTooltipTD.destroy();

        $(this.elementRef.nativeElement).find(".kendoGridContainer").first().empty();
    }

    ngAfterViewInit() {
        if (this.rpsSelectionMode) {
            if (this.rpsSelectionMode == "Single")
                this.selectable = 0;
            else if (this.rpsSelectionMode == "Multiple")
                this.selectable = 1;
        }

        this.viewInitialised = true;
        this.tryCreateKendoWidgets();
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes["rpsSource"])
            this.rpsSourceChanged();
    }

    ngAfterViewChecked() {
        if (this.createKendoWidgetsPending) {
            var kendoGridContainer = $(this.elementRef.nativeElement).find(".kendoGridContainer");
            if (kendoGridContainer.outerWidth() > 0) {
                this.tryCreateKendoWidgets();
            }

            this.cdRef.detectChanges();
        }
    }

    tryCreateKendoWidgets() {

        var kendoGridContainer = $(this.elementRef.nativeElement).find(".kendoGridContainer");
        if (this.kendoGrid) {
            this.kendoTooltipDelay.destroy();
            this.kendoTooltipDelay = null;
            this.kendoTooltipTD.destroy();
            this.kendoTooltipTD = null;
            this.kendoGrid.destroy();
            this.kendoGrid = null;
            kendoGridContainer.empty();

            this.childVMsPropertyChangedSub.forEach((sub) => {
                sub.unsubscribe();
            });
            this.childVMsPropertyChangedSub = new Array<rps.services.ISubscription>();
        }

        //Sólo se crea si tiene items y columnas; si no, ya se hará en el set de los items o las columnas
        if (this.viewInitialised &&
            rps.object.hasValue(this.items) &&
            this.hasColumns()) {
            //Si el contenedor de la grid ya tiene tamaño, se crean los widgets
            if (kendoGridContainer.outerWidth() > 0) {
                this.createKendoWidgetsPending = false;

                kendoGridContainer.append("<div></div>");
                this.kendoGrid = kendoGridContainer.children().kendoGrid({
                    dataSource: this.items,
                    columns: this.getColumns(kendoGridContainer.outerWidth()),
                    pageable: false,
                    resizable: true,
                    reorderable: true,
                    navigatable: true,
                    sortable: this.sortable,
                    editable: true,
                    scrollable: true,
                    autoBind: this.autoBind,
                    columnMenu: false,
                    dataBound: this.kendoGridDataBound
                }).data("kendoGrid");

                //Hack: El widget no actúa correctamente si no le llama el al leer directamente, por lo al crearlo, se comprueba a ver si está leyendo y se recrea el mismo estado.
                if (this.rpsQuerySourcePropertyChangedSub)
                    this.rpsQuerySourcePropertyChangedSub.unsubscribe();
                if (this.rpsSource && this.rpsSource instanceof rps.data.sources.QuerySource) {
                    var querySource: rps.data.sources.QuerySource = this.rpsSource;
                    if (querySource.isReading) {
                        this.kendoGrid.element.find(".k-grid-content").css("height", "300px");
                        kendo.ui.progress(this.kendoGrid.element.find(".k-grid-content"), true);
                        this.rpsQuerySourcePropertyChangedSub = querySource.propertyChanged.subscribe((value: rps.viewmodels.properties.VMPropertyChange) => {
                            if (value.propertyName == "isReading") {
                                this.kendoGrid.element.find(".k-grid-content").css("height", "");
                                this.rpsQuerySourcePropertyChangedSub.unsubscribe();
                            }
                        });
                    }
                    else {
                        //HACK: Cuando el origen de datos ya está cargado, hay que refrescar la grid, para que funciona, ya que si no, se queda la grid en blanco…
                        this.kendoGrid.refresh();
                    }
                }

                this.kendoGrid.table.on("click", ".selectorCheckbox", (eventArgs: JQueryEventObject) => { this.selectRow(eventArgs) });
                if (this.selectedItems.length > 0)
                    this.syncSelectedItems();
                //En el Tooltip tenemos que estar cambiando la visibilidad para poder hacer que se muestre solo cuando el texto no entre y tenga puntos suspensivos
                this.kendoTooltipTD = this.kendoGrid.element.kendoTooltip({
                    filter: "td div",
                    position: "bottom left",
                    show: (e) => {
                        $(e.sender["content"]).parent()
                        this.kendoTooltipDelay.hide();
                        if (e.sender["content"].text() != "") {
                            $(e.sender["content"]).parent().css("visibility", "visible");
                        } else {
                            this.kendoTooltipTD.hide();
                        }
                    },
                    content: function (e) {
                        $(e.sender.content).parent().css("visibility", "hidden");
                        //Guardamos el elemento y su padre porque en algunos campos como los lookups, el elemento es un enlace y
                        //no podemos comprobar las medidas directamente sobre él, pero si por su padre.
                        var lastElement = $(e.target[0]).find("*").children().last();
                        var parentElement = $(e.target[0]).find("*").children().last().parent();
                        if (lastElement[0] || parentElement[0]) {
                            if (lastElement[0].offsetWidth < lastElement[0].scrollWidth || parentElement[0].offsetWidth < parentElement[0].scrollWidth) {
                                return e.target.text().trim();
                            } else {
                                return "";
                            }
                        }
                    }
                }).data("kendoTooltip");

                this.kendoTooltipDelay = this.kendoGrid.element.kendoTooltip({
                    filter: "th[role=columnheader]:not(:empty), th i, td .rps-grid-command",
                    position: "bottom left",
                    showAfter: rps.utils.getTooltipDelay(),
                    content: function (e) {
                        if ($(e.target).hasClass("rps-grid-navigate-command"))
                            return rps.app.resources.directives.NAVIGATE_ROW_GRID;
                        else if ($(e.target).hasClass("rps-grid-remove-command"))
                            return rps.app.resources.directives.DELETE_ROW_GRID;
                        else if ($(e.target).hasClass("rps-grid-detail-command"))
                            return rps.app.resources.directives.SHOW_DETAIL_ROW_GRID;
                        else if ($(e.target).hasClass("rps-grid-header-editable-icon"))
                            return rps.app.resources.directives.ICON_EDITABLE_GRID;
                        else
                            return e.target.text().trim();
                    }
                }).data("kendoTooltip");
                this.rpsComponentCreated.emit(this);
            }
            else {
                this.createKendoWidgetsPending = true;
            }
        }
    }

    kendoGridDataBound = (e: kendo.ui.GridDataBoundEvent) => {
        //Eliminar todos los componentes que ya no tienen línea
        var uids: Array<string> = new Array<string>();
        e.sender.dataSource.data().forEach((dataItem) => {
            uids.push((<any>dataItem).uid);
        });
        for (var key in this.rowComponents) {
            if (uids.indexOf(key) == -1) {
                this.rowComponents[key].forEach((component) => {
                    component.destroy();
                });
            }
        }
        //Cuando se enlazan los datos con el origen de datos, se cierra el detalle en el caso de que se esté mostrando
        if (this.itemDetailFormOpened)
            this.closeItemDetail();
    }

    rpsSourceChanged() {
        this.selectedItems = new Array<any>();

        if (this.rpsSourcePropertyChangedSub)
            this.rpsSourcePropertyChangedSub.unsubscribe();

        if (this.rpsSource instanceof rps.data.sources.QuerySource) {
            this.disablePaginationChanged();
            this.autoBind = false;
            this.sortable = false;
            this.allowDelete = false;
            this.setItems(this.rpsSource.dataSource);
            this.rpsSourcePropertyChangedSub = this.rpsSource.propertyChanged.subscribe((se: rps.viewmodels.properties.VMPropertyChange) => this.querySourcePropertyChanged(se));
            this.selectedItemsChanged();
            this.querySourceLoadedChanged();
        }
        else if (this.rpsSource instanceof rps.data.sources.EntitySource) {
            var entitySource: rps.data.sources.EntitySource = this.rpsSource;
            this.showPager = false;
            this.autoBind = true;
            this.sortable = true;
            this.allowDelete = true;
            this.setItems(entitySource.dataSource);
            this.rpsSourcePropertyChangedSub = entitySource.propertyChanged.subscribe((se: rps.viewmodels.properties.VMPropertyChange) => this.entitySourcePropertyChanged(se));
            this.selectedItemsChanged();
            this.querySourceLoadedChanged();
        }
        else {
            this.loaded = true;
            this.showPager = false;
            this.autoBind = true;
            this.sortable = true;
            this.setVmProperty(this.rpsSource);
        }
    }

    private querySourcePropertyChanged(e: rps.viewmodels.properties.VMPropertyChange) {
        if (e.propertyName == "disablePagination")
            this.disablePaginationChanged();
        else if (e.propertyName == "selectedItems")
            this.selectedItemsChanged();
        else if (e.propertyName == "loaded")
            this.querySourceLoadedChanged();
        else if (e.propertyName == "items")
            this.querySourceItemsChanged();
    }

    private entitySourcePropertyChanged(e: rps.viewmodels.properties.VMPropertyChange) {
        if (e.propertyName == "selectedItems")
            this.selectedItemsChanged();
        else if (e.propertyName == "loaded")
            this.entitySourceLoadedChanged();
    }

    disablePaginationChanged() {
        if (this.showPager != !(<rps.data.sources.QuerySource>this.rpsSource).disablePagination)
            this.showPager = !(<rps.data.sources.QuerySource>this.rpsSource).disablePagination;
    }

    querySourceItemsChanged() {
        //El evento salta cuando se establecen items a mano en el querySource (p.e., caso de filtrado de items en pantalla de usuarios)

        //Se Eliminan todos los componentes cacheados para que no dé problemas al reconstruir la grid con los nuevos elementos
        //(se reusan componentes que están destruidos)
        for (var key in this.rowColumnComponents) {
            this.rowColumnComponents[key].destroy();
        }
        for (var key in this.rowColumnEditableComponents) {
            this.rowColumnEditableComponents[key].destroy();
        }
        this.rowColumnComponents = {};
        this.rowColumnEditableComponents = {};
    }

    selectedItemsChanged() {
        if (this.rpsSource.ISelectable) {
            var selectableItemsSource: rps.ISelectable = this.rpsSource;
            if (this.selectedItems.length > 0 || selectableItemsSource.hasSelectedItems()) {
                if (this.selectedItems.length == selectableItemsSource.getSelectedItems().length) {
                    var querySourceEnumerable: linq.Enumerable<any> = Enumerable.From<any>(selectableItemsSource.getSelectedItems());
                    if (!Enumerable.From<any>(this.selectedItems).Any(i => !querySourceEnumerable.Contains(i)))
                        return;
                }
                this.selectedItems = selectableItemsSource.getSelectedItems();
                if (this.kendoGrid)
                    this.syncSelectedItems();
            }
        }
    }

    querySourceLoadedChanged() {
        this.loaded = (<rps.data.sources.QuerySource>this.rpsSource).loaded;
    }

    entitySourceLoadedChanged() {
        this.loaded = (<rps.data.sources.EntitySource>this.rpsSource).loaded;
    }

    syncSelectedItems() {
        var selectedItemEnumerable: linq.Enumerable<any> = Enumerable.From<any>(this.selectedItems);
        this.kendoGrid.element.find(".selectorCheckbox").each((index: number, element: HTMLInputElement) => {
            var row = $(element).closest("tr");
            var dataItem = this.kendoGrid.dataItem(row);
            if (selectedItemEnumerable.Contains(dataItem)) {
                element.checked = true;
                row.addClass("k-state-selected");
            }
            else {
                element.checked = false;
                row.removeClass("k-state-selected");
            }
        });
    }

    setVmProperty(newValue) {
        this.vmProperty = newValue;

        if (this.vmProperty) {
            this.valueChanged(this.vmProperty.value);
            this.allowDeleteChanged();
            if (this.vmPropertyChangedSub)
                this.vmPropertyChangedSub.unsubscribe();
            this.vmPropertyChangedSub = this.vmProperty.propertyChanged.subscribe(this.vmPropertyChanged);
        }
        else {
            this.setItems([]);
            this.allowDelete = false;
        }
    }

    private vmPropertyChanged = (e: rps.viewmodels.properties.VMPropertyChange) => {
        if (e.propertyName == "value")
            this.valueChanged(e.newValue);
        else if (e.propertyName == "allowDelete")
            this.allowDeleteChanged();
        else if (e.propertyName == "selectedItems")
            this.selectedItemsChanged();
        else if (e.propertyName == "sort") {
            if (this.kendoGrid) {
                var sortFields = new Array<{ field: string, dir: string }>()
                this.vmProperty.value.orderDefinition.forEach((od) => {
                    sortFields.push({
                        field: od.propertyName + ".value",
                        dir: od.direction == 0 ? "asc" : "desc"
                    })
                });
                this.kendoGrid.dataSource.sort(sortFields);
            }
        }
    }

    valueChanged(newValue) {
        this.setItems(newValue);
    }

    setItems(newValue: any) {
        if (this.items != newValue) {
            this.items = newValue;

            this.tryCreateKendoWidgets();
        }
    }

    allowDeleteChanged() {
        if (this.allowDelete !== this.vmProperty.allowDelete) {
            this.allowDelete = this.vmProperty.allowDelete;
            this.tryCreateKendoWidgets();
        }
    }

    hasColumns(): boolean {
        if (this.columnsDefinition || (this.rpsSource instanceof rps.data.sources.QuerySource && this.rpsSource.variableColumns))
            return true;
        else
            return false;
    }

    getColumns(containerWidth: number): Array<kendo.ui.GridColumn> {
        var result: Array<kendo.ui.GridColumn> = [];
        var variableColumns: Array<rps.ui.itemsControl.ColumnDefinition> = this.rpsSource instanceof rps.data.sources.QuerySource ? this.rpsSource.variableColumns : null;
        if (this.columnsDefinition || variableColumns) {
            var resolvedColumnsDefinition: Array<rps.ui.itemsControl.ColumnDefinition> = new Array<rps.ui.itemsControl.ColumnDefinition>();
            if (this.columnsDefinition)
                resolvedColumnsDefinition = resolvedColumnsDefinition.concat(this.columnsDefinition);
            if (variableColumns)
                resolvedColumnsDefinition = resolvedColumnsDefinition.concat(variableColumns);

            //Generar las columnas fijas
            var commandsColumnWidth: number = 0;
            //Añadir la columna de selección
            if (this.selectable >= 0) {
                var selectionColumn: kendo.ui.GridColumn = {};
                selectionColumn.template =
                    "#var guid=rps.guid.newGuid()#" +
                    "<div class='rps-grid-server-checkbox-container'>" +
                    "<input type='checkbox' class='selectorCheckbox k-checkbox' id='#=guid#'/>" +
                    "<label class='k-checkbox-label' for='#=guid#'></label>" +
                    "</div>";
                commandsColumnWidth += 50;
                selectionColumn.width = "50px";
                result.push(selectionColumn);
            }
            var commands: Array<any> = [];
            //Si es capaz de navegar, añadir el comando para navegar
            if (this.rpsLinkPropertyPath) {
                commands.push({
                    name: "navigate",
                    text: "",
                    className: "fa fa-external-link rps-primary rps-grid-command rps-grid-navigate-command",
                    click: (e) => {
                        //Forzamos que el hide del Tooltip para que no se quede abierto cuando damos click
                        this.kendoTooltipDelay.hide();
                        // e.target is the DOM element representing the button
                        var tr = $(e.target).closest("tr"); // get the current table row (tr)
                        // get the data bound to the current table row
                        var data = this.kendoGrid.dataItem(tr);

                        return this.zone.run(() => {
                            (<rps.viewmodels.properties.LinkProperty>data[this.rpsLinkPropertyPath]).go();
                            return false;
                        });
                    }
                });
            }
            //Si contiene una plantilla para el formulario, se añade el comando para mostrarlo
            if (this.sourcesToolbarService && this.sourcesToolbarService.itemTemplate) {
                commands.push({
                    name: "detail",
                    text: "",
                    className: "fa fa-wpforms rps-info rps-grid-command rps-grid-detail-command",
                    click: (e) => {
                        //Forzamos que el hide del Tooltip para que no se quede abierto cuando damos click
                        this.kendoTooltipDelay.hide();
                        var target: JQuery = $(e.target);
                        //Si el botón contiene la clase “rps-grid-detail-command-selected”, significa que ya está seleccionado
                        if (!target.hasClass("rps-grid-detail-command-selected")) {
                            // e.target is the DOM element representing the button
                            // get the current table row (tr)
                            var tr = target.closest("tr");
                            // get the data bound to the current table row
                            var data: rps.viewmodels.BaseVM = <any>this.kendoGrid.dataItem(tr);

                            //Mostrar el formulario
                            this.openItemDetail(target, data);
                            return false;
                        }
                        else {
                            return false;
                        }
                    }
                });
            }
            //Si es capaz de eliminar registros, añadir el comando para eliminar
            if (this.allowDelete) {
                commands.push({
                    name: "remove",
                    text: "",
                    className: "fa fa-trash-o rps-danger rps-grid-command rps-grid-remove-command",
                    click: (e) => {
                        if (this.allowDelete) {
                            //Forzamos que el hide del Tooltip para que no se quede abierto cuando damos click
                            this.kendoTooltipDelay.hide();
                            // e.target is the DOM element representing the button
                            var tr = $(e.target).closest("tr"); // get the current table row (tr)
                            // get the data bound to the current table row
                            var data = this.kendoGrid.dataItem(tr);

                            return this.zone.run(() => {
                                if (data && this.rpsSource) {
                                    if (this.rpsSource instanceof rps.viewmodels.properties.BaseVMCollectionProperty)
                                        this.rpsSource.userRemove(data);
                                    else if (this.rpsSource instanceof rps.data.sources.EntitySource)
                                        (<rps.data.sources.EntitySource>this.rpsSource).remove(<any>data);
                                    else
                                        return false;
                                }
                                return false;
                            });
                        }
                        else {
                            return this.zone.run(() => {
                                rps.app.messageManager.show({
                                    message: rps.app.resources.commonResources.MSG_READ_ONLY,
                                    messageButton: rps.services.MessageButton.Ok,
                                    messageType: rps.services.MessageType.Info
                                });
                                return false;
                            });
                        }
                    }
                });
            }
            if (commands.length > 0) {
                var commandsColumn: kendo.ui.GridColumn = {
                    command: commands
                };

                if (commands.length == 1) {
                    commandsColumnWidth += 45;
                    commandsColumn.width = "45px";
                }
                else if (commands.length == 2) {
                    commandsColumnWidth += 80;
                    commandsColumn.width = "80px";
                }
                else if (commands.length == 3) {
                    commandsColumnWidth += 115;
                    commandsColumn.width = "115px";
                }

                result.push(commandsColumn);
            }

            //Calcular el ancho de la grid, teniendo en cuenta los comandos y el scroll
            var elementWidth: number = containerWidth - commandsColumnWidth - rps.utils.getScrollBarWidth();
            //Se quitan 5 como medida de precaución
            elementWidth = elementWidth - 5;

            //Calcular el número de columnas de las columnas
            var columnsCount = Enumerable.From<rps.ui.itemsControl.ColumnDefinition>(resolvedColumnsDefinition).Sum(cd => cd.columns);
            //Calcular si se requiere el scroll
            var scrollable: boolean;
            if (!rps.object.isDefined(this.rpsAutoFit)) {
                //Calcular el número de columnas que entran en la grid, en función del tamaño de esta, con un mínimo de 12
                var allowedColumnsCount = elementWidth < 1200 ? 12 : Math.floor((elementWidth / 100));
                scrollable = (columnsCount > allowedColumnsCount);
            }
            else
                scrollable = false;

            //Si tiene menos de las columnas que entran, se calcula la anchura de cada columna para que ocupe el 100%
            //Si tiene más de las columnas que entran, se establece una anchura de 100
            var columnWidth: number;
            if (scrollable)
                columnWidth = 100;
            else
                columnWidth = Math.floor(elementWidth / columnsCount);
            resolvedColumnsDefinition.forEach((column) => {
                //Si tiene un binding en la visibilidad, 
                //comprobamos el valor, para generar la columna o no 
                //y nos enganchamos al cambio del valor
                if (column.visibilityBinding &&
                    this.rpsSource &&
                    this.rpsSource.parentVM) {
                    var vmProperty = this.rpsSource.parentVM[column.visibilityBinding];
                    if (rps.viewmodels.properties.isVMProperty(vmProperty) && vmProperty.hasValue()) {
                        this.childVMsPropertyChangedSub.push(vmProperty.propertyChanged.subscribe((eventArgs: rps.viewmodels.properties.VMPropertyChange) => {
                            if (eventArgs.propertyName == "value")
                                this.tryCreateKendoWidgets();
                        }));
                        if (!vmProperty.value)
                            return;
                    }
                }
                var gridColumn: kendo.ui.GridColumn = {};
                if (column.title)
                    gridColumn.title = column.title;
                else if (column.titleBinding &&
                    this.rpsSource &&
                    this.rpsSource.parentVM) {
                    //En el caso de que el título de la columna de la grid, 
                    //esté enlazado a una propiedad del vm contenedor de la lista, 
                    //nos enganchamos al cambio de sus propiedades, 
                    //para regenerar la grid en el caso de que este cambie
                    var vmProperty = this.rpsSource.parentVM[column.titleBinding];
                    if (rps.viewmodels.properties.isVMProperty(vmProperty) && vmProperty.hasValue()) {
                        gridColumn.title = vmProperty.value;
                        this.childVMsPropertyChangedSub.push(vmProperty.propertyChanged.subscribe((eventArgs: rps.viewmodels.properties.VMPropertyChange) => {
                            if (eventArgs.propertyName == "value")
                                this.tryCreateKendoWidgets();
                        }));
                    }
                    else
                        gridColumn.title = " ";
                }
                else
                    gridColumn.title = " ";
                if (scrollable)
                    gridColumn.width = (column.columns * columnWidth) + "px";
                else
                    gridColumn.width = column.columns * columnWidth;
                this.createColumn(column, gridColumn);
                result.push(gridColumn);
            });
        }
        return result;
    }

    createColumn(column: any, gridColumn: kendo.ui.GridColumn) {
        if (rps.ui.itemsControl.isEditableColumnDefinition(column)) {
            rpsGrid.setEditableToGridColumn(gridColumn, column.isEditable);
            (<any>gridColumn).rpsModel = column.field;
            this.createEditableColumn(column, gridColumn);
        }
        else if (rps.ui.itemsControl.isServerColumnDefinition(column)) {
            rpsGrid.setEditableToGridColumn(gridColumn, false);
            (<any>gridColumn).rpsModel = column.field;
            this.createServerColumn(column, gridColumn);
        }
        else {
            console.log(rps.string.format("Not implemented column type"));
        }
    }

    static setEditableToGridColumn(gridColumn: kendo.ui.GridColumn, editable: boolean) {
        if (editable) {
            gridColumn.headerTemplate = rps.string.format("<i class=\"fa fa-pencil-square-o rps-grid-header-editable-icon\"></i>{0}", gridColumn.title);
            gridColumn.editable = (itemVM: rps.viewmodels.BaseVM) => {
                if (!itemVM[(<any>gridColumn).rpsModel].isLocked)
                    return true;
                else
                    return false;
            };
        }
        else {
            gridColumn.editable = () => {
                return false;
            };
        }
    }

    createEditableColumn(column: rps.ui.itemsControl.EditableColumnDefinition, gridColumn: kendo.ui.GridColumn) {
        switch (column.type) {
            case rps.ui.itemsControl.EditableColumnTypes.textBox:
                gridColumn.field = column.field + ".value";
                var template: string = "#= rps.ui.itemsControl.loadTextBoxColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                gridColumn.editor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
                    rps.app.uiFactory.createComponent<rpsTextBox>(rpsTextBox, this.dynamicContainer)
                        .then((result: ComponentRef<rpsTextBox>) => {
                            this.addEditableCellComponent(<any>options.model, column.field, result);

                            result.instance.rpsModel = options.model[column.field];
                            var changes: { [key: string]: SimpleChange; } = {};
                            changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                            result.instance.ngOnChanges(changes);

                            container.html(result.location.nativeElement);
                            result.instance.setFocusAfterInit();
                        });
                };
                break;
            case rps.ui.itemsControl.EditableColumnTypes.checkBox:
                gridColumn.field = column.field + ".value";
                if (!column.isEditable) {
                    const template = "#= rps.ui.itemsControl.loadCheckBoxServerColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                    gridColumn.template = kendo.template(template);
                } else {
                    const template = "#= rps.ui.itemsControl.loadCheckBoxColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                    gridColumn.template = kendo.template(template);

                    gridColumn.editor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
                        rps.app.uiFactory.createComponent<rpsCheckBox>(rpsCheckBox, this.dynamicContainer)
                            .then((result: ComponentRef<rpsCheckBox>) => {
                                this.addEditableCellComponent(<any>options.model, column.field, result);

                                result.instance.rpsModel = options.model[column.field];
                                var changes: { [key: string]: SimpleChange; } = {};
                                changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                                result.instance.ngOnChanges(changes);

                                container.html(result.location.nativeElement);
                                result.instance.setFocusAfterInit();
                            });
                    };
                    //HACK: Se machaca el método editable (método invocado por la grid de kendo), 
                    //para hacer que el checkBox, cambie el valor al hacer click, después con un setTimeout,
                    //se fuerza la edición de la celda
                    gridColumn.editable = (itemVM: rps.viewmodels.BaseVM) => {
                        if (!itemVM[(<any>gridColumn).rpsModel].isLocked) {
                            if (!this._forceEdit) {
                                setTimeout(() => {
                                    this._forceEdit = true;
                                    this.kendoGrid.editCell(this.kendoGrid.current());
                                    this._forceEdit = false;
                                });
                                return false;
                            }
                            else
                                return true;
                        }
                        else
                            return false;
                    }
                }
                break;
            case rps.ui.itemsControl.EditableColumnTypes.datePicker:
                gridColumn.field = column.field + ".value";
                var template: string = "#= rps.ui.itemsControl.loadDatePickerColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                gridColumn.editor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
                    rps.app.uiFactory.createComponent<rpsDatePicker>(rpsDatePicker, this.dynamicContainer)
                        .then((result: ComponentRef<rpsDatePicker>) => {
                            this.addEditableCellComponent(<any>options.model, column.field, result);

                            result.instance.rpsModel = options.model[column.field];
                            var changes: { [key: string]: SimpleChange; } = {};
                            changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                            result.instance.ngOnChanges(changes);

                            container.html(result.location.nativeElement);
                            result.instance.setFocusAfterInit();
                        });
                };
                break;
            case rps.ui.itemsControl.EditableColumnTypes.lookup:
                gridColumn.field = column.field + ".value";
                var lookupOptions: rps.ui.itemsControl.EditableLookupOptions = <rps.ui.itemsControl.EditableLookupOptions>column.options;
                var template: string = "#= rps.ui.itemsControl.loadLookupColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                gridColumn.editor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
                    rps.app.uiFactory.createComponent<rpsLookup>(rpsLookup, this.dynamicContainer)
                        .then((result: ComponentRef<rpsLookup>) => {
                            this.addEditableCellComponent(<any>options.model, column.field, result);

                            result.instance.rpsModel = options.model[column.field];
                            result.instance.rpsDataSource = options.model[lookupOptions.dataSource];
                            var changes: { [key: string]: SimpleChange; } = {};
                            changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                            changes["rpsDataSource"] = new SimpleChange(undefined, result.instance.rpsDataSource);
                            result.instance.ngOnChanges(changes);

                            container.html(result.location.nativeElement);
                            result.instance.setFocusAfterInit();
                        });
                };
                break;
            case rps.ui.itemsControl.EditableColumnTypes.emailEditor:
                gridColumn.field = column.field + ".value";
                var template: string = "#= rps.ui.itemsControl.loadMailtoColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                gridColumn.editor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
                    rps.app.uiFactory.createComponent<rpsEmailEditor>(rpsEmailEditor, this.dynamicContainer)
                        .then((result: ComponentRef<rpsEmailEditor>) => {
                            this.addEditableCellComponent(<any>options.model, column.field, result);

                            result.instance.rpsModel = options.model[column.field];
                            var changes: { [key: string]: SimpleChange; } = {};
                            changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                            result.instance.ngOnChanges(changes);

                            container.html(result.location.nativeElement);
                            result.instance.setFocusAfterInit();
                        });
                };
                break;
            case rps.ui.itemsControl.EditableColumnTypes.urlEditor:
                gridColumn.field = column.field + ".value";
                var template: string = "#= rps.ui.itemsControl.loadUrlColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                gridColumn.editor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
                    rps.app.uiFactory.createComponent<rpsUrlEditor>(rpsUrlEditor, this.dynamicContainer)
                        .then((result: ComponentRef<rpsUrlEditor>) => {
                            this.addEditableCellComponent(<any>options.model, column.field, result);

                            result.instance.rpsModel = options.model[column.field];
                            var changes: { [key: string]: SimpleChange; } = {};
                            changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                            result.instance.ngOnChanges(changes);

                            container.html(result.location.nativeElement);
                            result.instance.setFocusAfterInit();
                        });
                };
                break;
            case rps.ui.itemsControl.EditableColumnTypes.decimalEditor:
                gridColumn.field = column.field + ".value";
                var template: string = "#= rps.ui.itemsControl.loadDecimalEditorColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                gridColumn.editor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
                    rps.app.uiFactory.createComponent<rpsDecimalEditor>(rpsDecimalEditor, this.dynamicContainer)
                        .then((result: ComponentRef<rpsDecimalEditor>) => {
                            this.addEditableCellComponent(<any>options.model, column.field, result);

                            result.instance.rpsModel = options.model[column.field];
                            var changes: { [key: string]: SimpleChange; } = {};
                            changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                            result.instance.ngOnChanges(changes);

                            container.html(result.location.nativeElement);
                            result.instance.setFocusAfterInit();
                        });
                };
                break;
            case rps.ui.itemsControl.EditableColumnTypes.timePicker:
                gridColumn.field = column.field + ".value";
                var template: string = "#= rps.ui.itemsControl.loadTimePickerColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                gridColumn.editor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
                    rps.app.uiFactory.createComponent<rpsTimePicker>(rpsTimePicker, this.dynamicContainer)
                        .then((result: ComponentRef<rpsTimePicker>) => {
                            this.addEditableCellComponent(<any>options.model, column.field, result);

                            result.instance.rpsModel = options.model[column.field];
                            var changes: { [key: string]: SimpleChange; } = {};
                            changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                            result.instance.ngOnChanges(changes);

                            container.html(result.location.nativeElement);
                            result.instance.setFocusAfterInit();
                        });
                };
                break;
            case rps.ui.itemsControl.EditableColumnTypes.percentageEditor:
                gridColumn.field = column.field + ".value";
                var template: string = "#= rps.ui.itemsControl.loadPercentageEditorColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                gridColumn.editor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
                    rps.app.uiFactory.createComponent<rpsPercentageEditor>(rpsPercentageEditor, this.dynamicContainer)
                        .then((result: ComponentRef<rpsPercentageEditor>) => {
                            this.addEditableCellComponent(<any>options.model, column.field, result);

                            result.instance.rpsModel = options.model[column.field];
                            var changes: { [key: string]: SimpleChange; } = {};
                            changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                            result.instance.ngOnChanges(changes);

                            container.html(result.location.nativeElement);
                            result.instance.setFocusAfterInit();
                        });
                };
                break;
            case rps.ui.itemsControl.EditableColumnTypes.amountEditor:
                gridColumn.field = column.field + ".value";
                var template: string = "#= rps.ui.itemsControl.loadAmountEditorColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                gridColumn.editor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
                    rps.app.uiFactory.createComponent<rpsAmountEditor>(rpsAmountEditor, this.dynamicContainer)
                        .then((result: ComponentRef<rpsAmountEditor>) => {
                            this.addEditableCellComponent(<any>options.model, column.field, result);

                            result.instance.rpsModel = options.model[column.field];
                            var changes: { [key: string]: SimpleChange; } = {};
                            changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                            result.instance.ngOnChanges(changes);

                            container.html(result.location.nativeElement);
                            result.instance.setFocusAfterInit();
                        });
                };
                break;
            case rps.ui.itemsControl.EditableColumnTypes.priceEditor:
                gridColumn.field = column.field + ".value";
                var template: string = "#= rps.ui.itemsControl.loadPriceEditorColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                gridColumn.editor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
                    rps.app.uiFactory.createComponent<rpsPriceEditor>(rpsPriceEditor, this.dynamicContainer)
                        .then((result: ComponentRef<rpsPriceEditor>) => {
                            this.addEditableCellComponent(<any>options.model, column.field, result);

                            result.instance.rpsModel = options.model[column.field];
                            var changes: { [key: string]: SimpleChange; } = {};
                            changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                            result.instance.ngOnChanges(changes);

                            container.html(result.location.nativeElement);
                            result.instance.setFocusAfterInit();
                        });
                };
                break;
            case rps.ui.itemsControl.EditableColumnTypes.quantityEditor:
                gridColumn.field = column.field + ".value";
                var template: string = "#= rps.ui.itemsControl.loadQuantityEditorColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                gridColumn.editor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
                    rps.app.uiFactory.createComponent<rpsQuantityEditor>(rpsQuantityEditor, this.dynamicContainer)
                        .then((result: ComponentRef<rpsQuantityEditor>) => {
                            this.addEditableCellComponent(<any>options.model, column.field, result);

                            result.instance.rpsModel = options.model[column.field];
                            var changes: { [key: string]: SimpleChange; } = {};
                            changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                            result.instance.ngOnChanges(changes);

                            container.html(result.location.nativeElement);
                            result.instance.setFocusAfterInit();
                        });
                };
                break;
            case rps.ui.itemsControl.EditableColumnTypes.durationEditor:
                gridColumn.field = column.field + ".value";
                var template: string = "#= rps.ui.itemsControl.loadDurationEditorColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                gridColumn.editor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
                    rps.app.uiFactory.createComponent<rpsDurationEditor>(rpsDurationEditor, this.dynamicContainer)
                        .then((result: ComponentRef<rpsDurationEditor>) => {
                            this.addEditableCellComponent(<any>options.model, column.field, result);

                            result.instance.rpsModel = options.model[column.field];
                            var changes: { [key: string]: SimpleChange; } = {};
                            changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                            result.instance.ngOnChanges(changes);

                            container.html(result.location.nativeElement);
                            result.instance.setFocusAfterInit();
                        });
                };
                break;
            case rps.ui.itemsControl.EditableColumnTypes.address:
                gridColumn.field = column.field + ".Text";
                rpsGrid.setEditableToGridColumn(gridColumn, false);
                var template: string = "#= rps.ui.itemsControl.loadAddressColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                break;
            case rps.ui.itemsControl.EditableColumnTypes.addressValue:
                gridColumn.field = column.field + ".Text";
                rpsGrid.setEditableToGridColumn(gridColumn, false);
                var template: string = "#= rps.ui.itemsControl.loadAddressValueColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                break;
            case rps.ui.itemsControl.EditableColumnTypes.integerEditor:
                gridColumn.field = column.field + ".value";
                var template: string = "#= rps.ui.itemsControl.loadIntegerEditorColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                gridColumn.format = "n0";
                gridColumn.editor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
                    rps.app.uiFactory.createComponent<rpsIntegerEditor>(rpsIntegerEditor, this.dynamicContainer)
                        .then((result: ComponentRef<rpsIntegerEditor>) => {
                            this.addEditableCellComponent(<any>options.model, column.field, result);

                            result.instance.rpsModel = options.model[column.field];
                            var changes: { [key: string]: SimpleChange; } = {};
                            changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                            result.instance.ngOnChanges(changes);

                            container.html(result.location.nativeElement);
                            result.instance.setFocusAfterInit();
                        });
                };
                break;
            case rps.ui.itemsControl.EditableColumnTypes.imageEditor:
                gridColumn.field = column.field + ".value";
                gridColumn.sortable = false;
                var template: string = "#= rps.ui.itemsControl.loadImageEditorColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                gridColumn.editor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
                    rps.app.uiFactory.createComponent<rpsImageEditor>(rpsImageEditor, this.dynamicContainer)
                        .then((result: ComponentRef<rpsImageEditor>) => {
                            this.addEditableCellComponent(<any>options.model, column.field, result);

                            result.instance.rpsModel = options.model[column.field];
                            var changes: { [key: string]: SimpleChange; } = {};
                            changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                            result.instance.ngOnChanges(changes);

                            container.html(result.location.nativeElement);
                            result.instance.setFocusAfterInit();
                        });
                };
                break;
            case rps.ui.itemsControl.EditableColumnTypes.button:
                gridColumn.field = column.field;
                gridColumn.sortable = false;
                var buttonOptions: rps.ui.itemsControl.EditableButtonOptions = <rps.ui.itemsControl.EditableButtonOptions>column.options;
                var buttonTemplate: string = "TEXT";
                var buttonLabel: string = "";
                var buttonIcon: string = "";
                var type: string = "";
                if (buttonOptions) {
                    if (buttonOptions.label)
                        buttonLabel = buttonOptions.label;
                    if (buttonOptions.icon)
                        buttonIcon = buttonOptions.icon;
                    if (buttonOptions.type)
                        type = buttonOptions.type;
                    if (buttonOptions.template)
                        buttonTemplate = buttonOptions.template;
                }
                var template: string = "#= rps.ui.itemsControl.loadButtonColumn(\"" + this.myId + "\",data,\"" + column.field + "\",\"" + buttonTemplate + "\",\"" + buttonLabel + "\",\"" + buttonIcon + "\",\"" + type + "\") #";
                gridColumn.template = kendo.template(template);
                gridColumn.editor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
                    rps.app.uiFactory.createComponent<rpsButton>(rpsButton, this.dynamicContainer)
                        .then((result: ComponentRef<rpsButton>) => {
                            this.addEditableCellComponent(<any>options.model, column.field, result);

                            result.instance.rpsModel = options.model[column.field];
                            result.instance.rpsTemplate = buttonTemplate;
                            result.instance.rpsLabel = buttonLabel;
                            result.instance.rpsIcon = buttonIcon;
                            result.instance.rpsType = type;
                            var changes: { [key: string]: SimpleChange; } = {};
                            changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                            changes["rpsTemplate"] = new SimpleChange(undefined, result.instance.rpsTemplate);
                            changes["rpsLabel"] = new SimpleChange(undefined, result.instance.rpsLabel);
                            changes["rpsIcon"] = new SimpleChange(undefined, result.instance.rpsIcon);
                            changes["rpsType"] = new SimpleChange(undefined, result.instance.rpsType);
                            result.instance.ngOnChanges(changes);

                            container.html(result.location.nativeElement);
                            result.instance.setFocusAfterInit();
                        });
                };
                break;
            case rps.ui.itemsControl.EditableColumnTypes.enumEditor:
                gridColumn.field = column.field + ".description";
                var template: string = "#= rps.ui.itemsControl.loadEnumEditorColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                gridColumn.editor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
                    rps.app.uiFactory.createComponent<rpsEnumEditor>(rpsEnumEditor, this.dynamicContainer)
                        .then((result: ComponentRef<rpsEnumEditor>) => {
                            this.addEditableCellComponent(<any>options.model, column.field, result);

                            result.instance.rpsModel = options.model[column.field];
                            var changes: { [key: string]: SimpleChange; } = {};
                            changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                            result.instance.ngOnChanges(changes);

                            container.html(result.location.nativeElement);
                            result.instance.setFocusAfterInit();
                        });
                };
                break;
            case rps.ui.itemsControl.EditableColumnTypes.dataComboBox:
                gridColumn.field = column.field + ".value";
                var template: string = "#= rps.ui.itemsControl.loadDataComboBoxColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                var dataComboBoxOptions: rps.ui.itemsControl.EditableDataComboBoxOptions = <rps.ui.itemsControl.EditableDataComboBoxOptions>column.options;
                gridColumn.editor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
                    rps.app.uiFactory.createComponent<rpsDataComboBox>(rpsDataComboBox, this.dynamicContainer)
                        .then((result: ComponentRef<rpsDataComboBox>) => {
                            this.addEditableCellComponent(<any>options.model, column.field, result);

                            result.instance.rpsModel = options.model[column.field];
                            result.instance.rpsDataSource = options.model[dataComboBoxOptions.dataSource];
                            result.instance.rpsValuePath = dataComboBoxOptions.valuePath
                            if (dataComboBoxOptions.editable)
                                result.instance.rpsEditable = "true";
                            else
                                result.instance.rpsEditable = "false";
                            var changes: { [key: string]: SimpleChange; } = {};
                            changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                            changes["rpsDataSource"] = new SimpleChange(undefined, result.instance.rpsDataSource);
                            changes["rpsValuePath"] = new SimpleChange(undefined, result.instance.rpsValuePath);
                            result.instance.ngOnChanges(changes);

                            container.html(result.location.nativeElement);
                            result.instance.setFocusAfterInit();
                        });
                };
                break;
            case rps.ui.itemsControl.EditableColumnTypes.entityLink:
                gridColumn.field = column.field + ".text";
                var template: string = "#= rps.ui.itemsControl.loadEntityLinkColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                rpsGrid.setEditableToGridColumn(gridColumn, false);
                break;
            case rps.ui.itemsControl.EditableColumnTypes.formattedNumber:
                gridColumn.field = column.field + ".text";
                var template: string = "#= rps.ui.itemsControl.loadFormattedNumberColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                rpsGrid.setEditableToGridColumn(gridColumn, false);
                break;
            case rps.ui.itemsControl.EditableColumnTypes.colorEditor:
                gridColumn.field = column.field + ".value";
                gridColumn.sortable = false;
                var template: string = "#= rps.ui.itemsControl.loadColorEditorColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                gridColumn.editor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
                    rps.app.uiFactory.createComponent<rpsColorEditor>(rpsColorEditor, this.dynamicContainer)
                        .then((result: ComponentRef<rpsColorEditor>) => {
                            this.addEditableCellComponent(<any>options.model, column.field, result);

                            result.instance.rpsModel = options.model[column.field];
                            var changes: { [key: string]: SimpleChange; } = {};
                            changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                            result.instance.ngOnChanges(changes);

                            container.html(result.location.nativeElement);
                            result.instance.setFocusAfterInit();
                        });
                };
                break;
            case rps.ui.itemsControl.EditableColumnTypes.icon:
                gridColumn.field = "model." + column.field;
                gridColumn.sortable = false;
                var template: string = "#= rps.ui.itemsControl.loadIconColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                rpsGrid.setEditableToGridColumn(gridColumn, false);
                break;
            case rps.ui.itemsControl.EditableColumnTypes.documents:
                gridColumn.field = "model." + column.field;
                gridColumn.sortable = false;
                var template: string = "#= rps.ui.itemsControl.loadDocumentsColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                rpsGrid.setEditableToGridColumn(gridColumn, false);
                break;
            case rps.ui.itemsControl.EditableColumnTypes.longTextBox:
                gridColumn.field = column.field + ".value";
                var template: string = "#= rps.ui.itemsControl.loadLongTextBoxLittleColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                gridColumn.editor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
                    rps.app.uiFactory.createComponent<rpsLongTextBoxLittle>(rpsLongTextBoxLittle, this.dynamicContainer)
                        .then((result: ComponentRef<rpsLongTextBoxLittle>) => {
                            this.addEditableCellComponent(<any>options.model, column.field, result);

                            result.instance.rpsModel = options.model[column.field];
                            var changes: { [key: string]: SimpleChange; } = {};
                            changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                            result.instance.ngOnChanges(changes);

                            container.html(result.location.nativeElement);
                            result.instance.setFocusAfterInit();
                        });
                };
                break;
            default:
                break;
        }
    }

    createServerColumn(column: rps.ui.itemsControl.ServerColumnDefinition, gridColumn: kendo.ui.GridColumn) {
        switch (column.template) {
            case rps.ui.itemsControl.ServerColumnTypes.textBox:
                gridColumn.field = "model." + column.field;
                break;
            case rps.ui.itemsControl.ServerColumnTypes.datePicker:
                gridColumn.field = "model." + column.field;
                var template: string = "#= rps.ui.itemsControl.loadDatePickerServerColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                break;
            case rps.ui.itemsControl.ServerColumnTypes.decimalEditor:
                gridColumn.field = "model." + column.field + ".Value";
                var format = rps.utils.getDecimalFormat({ toKendoTemplate: true });
                var template: string = "<div class='rps-number-editor-input'>#=kendo.toString(+" + gridColumn.field + ",'" + format + "')#</div>";
                gridColumn.template = kendo.template(template);
                break;
            case rps.ui.itemsControl.ServerColumnTypes.integerEditor:
                gridColumn.field = "model." + column.field + ".Value";
                var template: string = "<div class='rps-number-editor-input'>#:" + gridColumn.field + "#</div>";
                gridColumn.template = kendo.template(template);
                break;
            case rps.ui.itemsControl.ServerColumnTypes.imageEditor:
                gridColumn.field = "model." + column.field;
                gridColumn.sortable = false;
                var template: string = "#= rps.ui.itemsControl.loadImageEditorServerColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                break;
            case rps.ui.itemsControl.ServerColumnTypes.lookup:
                gridColumn.field = "model." + column.field + ".Value";
                var template: string = "#= rps.ui.itemsControl.loadLookupServerColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                break;
            case rps.ui.itemsControl.ServerColumnTypes.address:
                gridColumn.field = "model." + column.field + ".Value";
                var template: string = "#= rps.ui.itemsControl.loadAddressServerColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                break;
            case rps.ui.itemsControl.ServerColumnTypes.formattedNumber:
                gridColumn.field = "model." + column.field + ".Value";
                var template: string = "#= rps.ui.itemsControl.loadFormattedNumberServerColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                break;
            case rps.ui.itemsControl.ServerColumnTypes.checkBox:
                gridColumn.field = "model." + column.field;
                var template: string = "#= rps.ui.itemsControl.loadCheckBoxServerColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                break;
            case rps.ui.itemsControl.ServerColumnTypes.uri:
                gridColumn.field = "model." + column.field;
                gridColumn.template = "<div># if (" + gridColumn.field + ") {#<a target='_blank' href='#: " + gridColumn.field + " #'>#=" + gridColumn.field + "#</a>#}#</div>"
                break;
            case rps.ui.itemsControl.ServerColumnTypes.email:
                gridColumn.field = "model." + column.field;
                gridColumn.template = "<div># if (" + gridColumn.field + ") {#<a href='mailto:#: " + gridColumn.field + " #'>#=" + gridColumn.field + "#</a>#}#</div>"
                break;
            case rps.ui.itemsControl.ServerColumnTypes.icon:
                gridColumn.field = "model." + column.field;
                gridColumn.sortable = false;
                var template: string = "#= rps.ui.itemsControl.loadIconServerColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                break;
            case rps.ui.itemsControl.ServerColumnTypes.timePicker:
                gridColumn.field = "model." + column.field;
                var template: string = "#= rps.ui.itemsControl.loadTimePickerServerColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                break;
            case rps.ui.itemsControl.ServerColumnTypes.documents:
                gridColumn.field = "model." + column.field;
                gridColumn.sortable = false;
                var template: string = "#= rps.ui.itemsControl.loadDocumentsServerColumn(\"" + this.myId + "\",data,\"" + column.field + "\") #";
                gridColumn.template = kendo.template(template);
                break;
            default:
                console.log("rpsGridController --> Not Implemented Column Type: " + column.template);
                break;
        }
    }

    loadTimePickerColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyTimePicker>(rpsReadOnlyTimePicker, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyTimePicker>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadDataComboBoxColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyDataComboBox>(rpsReadOnlyDataComboBox, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyDataComboBox>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadEnumEditorColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyEnumEditor>(rpsReadOnlyEnumEditor, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyEnumEditor>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadDecimalEditorColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyDecimalEditor>(rpsReadOnlyDecimalEditor, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyDecimalEditor>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadIntegerEditorColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyIntegerEditor>(rpsReadOnlyIntegerEditor, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyIntegerEditor>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadPercentageEditorColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyPercentageEditor>(rpsReadOnlyPercentageEditor, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyPercentageEditor>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadTextBoxColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyTextBox>(rpsReadOnlyTextBox, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyTextBox>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadDatePickerColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyDatePicker>(rpsReadOnlyDatePicker, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyDatePicker>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadIconColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsIcon>(rpsIcon, this.dynamicContainer)
                .then((result: ComponentRef<rpsIcon>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadDocumentsColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsDocuments>(rpsDocuments, this.dynamicContainer)
                .then((result: ComponentRef<rpsDocuments>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadDatePickerServerColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsDatePickerServerColumn>(rpsDatePickerServerColumn, this.dynamicContainer)
                .then((result: ComponentRef<rpsDatePickerServerColumn>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.setValue(dataItem.model[valuePath]);
                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadImageEditorServerColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsImageEditorServerColumn>(rpsImageEditorServerColumn, this.dynamicContainer)
                .then((result: ComponentRef<rpsImageEditorServerColumn>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.setValue(dataItem.model[valuePath]);
                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadLookupServerColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsLookupServerColumn>(rpsLookupServerColumn, this.dynamicContainer)
                .then((result: ComponentRef<rpsLookupServerColumn>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.setValue(dataItem.model[valuePath]);
                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadFormattedNumberServerColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsFormattedNumberServerColumn>(rpsFormattedNumberServerColumn, this.dynamicContainer)
                .then((result: ComponentRef<rpsFormattedNumberServerColumn>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.setValue(dataItem.model[valuePath]);
                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadAddressServerColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsAddressServerColumn>(rpsAddressServerColumn, this.dynamicContainer)
                .then((result: ComponentRef<rpsAddressServerColumn>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.setValue(dataItem.model[valuePath]);
                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadCheckBoxServerColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsCheckBoxServerColumn>(rpsCheckBoxServerColumn, this.dynamicContainer)
                .then((result: ComponentRef<rpsCheckBoxServerColumn>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.setValue(dataItem.model[valuePath]);
                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadTimePickerServerColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsTimePickerServerColumn>(rpsTimePickerServerColumn, this.dynamicContainer)
                .then((result: ComponentRef<rpsTimePickerServerColumn>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.setValue(dataItem.model[valuePath]);
                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadIconServerColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsIconServerColumn>(rpsIconServerColumn, this.dynamicContainer)
                .then((result: ComponentRef<rpsIconServerColumn>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.setValue(dataItem.model[valuePath]);
                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadDocumentsServerColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsDocumentsServerColumn>(rpsDocumentsServerColumn, this.dynamicContainer)
                .then((result: ComponentRef<rpsDocumentsServerColumn>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.setValue(dataItem.model[valuePath]);
                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadLookupColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyLookup>(rpsReadOnlyLookup, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyLookup>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        //Nos suscribimos al cambio de vmProperty, para resolver los combosLocos dentro de la grid
                        this.dataItemPropertyChangedSubs.push(
                            dataItem.propertyChanged.subscribe((ea: rps.viewmodels.properties.VMPropertyChange) => {
                                if (ea.propertyName == valuePath) {
                                    result.instance.rpsModel = dataItem[valuePath];
                                    var changes: { [key: string]: SimpleChange; } = {};
                                    changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                                    result.instance.ngOnChanges(changes);
                                }
                            })
                        );
                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadCheckBoxColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsCheckBox>(rpsCheckBox, this.dynamicContainer)
                .then((result: ComponentRef<rpsCheckBox>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadAmountEditorColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyAmountEditor>(rpsReadOnlyAmountEditor, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyAmountEditor>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadPriceEditorColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyPriceEditor>(rpsReadOnlyPriceEditor, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyPriceEditor>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadQuantityEditorColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyQuantityEditor>(rpsReadOnlyQuantityEditor, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyQuantityEditor>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadAddressColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyAddress>(rpsReadOnlyAddress, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyAddress>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadAddressValueColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyAddressValue>(rpsReadOnlyAddressValue, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyAddressValue>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadDurationEditorColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyDurationEditor>(rpsReadOnlyDurationEditor, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyDurationEditor>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadImageEditorColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyImageEditor>(rpsReadOnlyImageEditor, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyImageEditor>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadButtonColumn = (dataItem: any, valuePath: string, divID: string, buttonTemplate: string, buttonLabel: string, buttonIcon: string, type: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsButton>(rpsButton, this.dynamicContainer)
                .then((result: ComponentRef<rpsButton>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsPreventTabindex = "true";

                        result.instance.rpsModel = dataItem[valuePath];
                        result.instance.rpsTemplate = buttonTemplate;
                        result.instance.rpsLabel = buttonLabel;
                        result.instance.rpsIcon = buttonIcon;
                        result.instance.rpsType = type;
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        changes["rpsTemplate"] = new SimpleChange(undefined, result.instance.rpsTemplate);
                        changes["rpsLabel"] = new SimpleChange(undefined, result.instance.rpsLabel);
                        changes["rpsIcon"] = new SimpleChange(undefined, result.instance.rpsIcon);
                        changes["rpsType"] = new SimpleChange(undefined, result.instance.rpsType);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadColorEditorColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyColorEditor>(rpsReadOnlyColorEditor, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyColorEditor>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadEntityLinkColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyEntityLink>(rpsReadOnlyEntityLink, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyEntityLink>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadMailtoColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyEmailEditor>(rpsReadOnlyEmailEditor, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyEmailEditor>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadUrlColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyUrlEditor>(rpsReadOnlyUrlEditor, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyUrlEditor>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    loadFormattedNumberColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyFormattedNumber>(rpsReadOnlyFormattedNumber, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyFormattedNumber>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }
    loadLongTextBoxLittleColumn = (dataItem: any, valuePath: string, divID: string) => {
        if (!this.trySetCellComponent(dataItem, valuePath, divID)) {
            rps.app.uiFactory.createComponent<rpsReadOnlyLongTextBoxLittle>(rpsReadOnlyLongTextBoxLittle, this.dynamicContainer)
                .then((result: ComponentRef<rpsReadOnlyLongTextBoxLittle>) => {
                    var rowCell: JQuery = this.getRowCell("#" + divID);
                    if (rowCell && rowCell.length > 0) {
                        this.addCellComponent(dataItem, valuePath, result);
                        result.instance.rpsModel = dataItem[valuePath];
                        var changes: { [key: string]: SimpleChange; } = {};
                        changes["rpsModel"] = new SimpleChange(undefined, result.instance.rpsModel);
                        result.instance.ngOnChanges(changes);

                        rowCell.html(result.location.nativeElement);
                    }
                    else {
                        result.destroy();
                    }
                });
        }
    }

    getRowCell(selector: string): JQuery {
        if (this.kendoGrid.tbody)
            return this.kendoGrid.tbody.find(selector);
        else
            return null;
    }

    //Diccionario creado para reaprovechar los componentes, ya que las líneas se repintan muchas veces
    //Hay dos diccionarios, uno con los componentes por línea y otro por línea y columna, de esta manera, cuando se elimina una línea, se pueden borrar todos sus componentes
    private rowColumnComponents: { [id: string]: ComponentRef<any>; } = {};
    private rowComponents: { [id: string]: Array<ComponentRef<any>>; } = {};
    //Hay un tercer diccionario, con los componentes creados para la edición
    private rowColumnEditableComponents: { [id: string]: ComponentRef<any>; } = {};

    //Añade el componente en el diccionario
    addCellComponent(dataItem: rps.viewmodels.BaseVM, valuePath: string, component: ComponentRef<any>) {
        this.rowColumnComponents[rps.string.format("{0}_{1}", dataItem.uid, valuePath)] = component;
        if (!this.rowComponents[dataItem.uid])
            this.rowComponents[dataItem.uid] = new Array<ComponentRef<any>>();
        this.rowComponents[dataItem.uid].push(component);
    }

    addEditableCellComponent(dataItem: rps.viewmodels.BaseVM, valuePath: string, component: ComponentRef<any>) {
        this.rowColumnEditableComponents[rps.string.format("{0}_{1}", dataItem.uid, valuePath)] = component;
    }

    deleteEditableCellComponent(dataItem: rps.viewmodels.BaseVM, valuePath: string) {
        //Si existe el componente para editar esta celda, se destruye
        if (this.rowColumnEditableComponents[rps.string.format("{0}_{1}", dataItem.uid, valuePath)]) {
            this.rowColumnEditableComponents[rps.string.format("{0}_{1}", dataItem.uid, valuePath)].destroy();
            delete this.rowColumnEditableComponents[rps.string.format("{0}_{1}", dataItem.uid, valuePath)];
        }
    }

    //Busca el componente en el diccionario y si está lo pinta en la celda, si no, notifica que no lo ha hecho
    trySetCellComponent(dataItem: rps.viewmodels.BaseVM, valuePath: string, divID: string) {
        this.deleteEditableCellComponent(dataItem, valuePath);
        if (this.rowColumnComponents[rps.string.format("{0}_{1}", dataItem.uid, valuePath)]) {
            setTimeout(() => {
                var rowCell: JQuery = this.getRowCell("#" + divID);
                if (rowCell && rowCell.length > 0) {
                    var component: ComponentRef<any> = this.rowColumnComponents[rps.string.format("{0}_{1}", dataItem.uid, valuePath)];
                    rowCell.html(component.location.nativeElement);
                }
            });
            return true;
        }
        else {
            return false;
        }
    }

    getFormTemplate(): string {
        if (!rps.object.hasValue(this.formTemplate)) {
            var title: string;
            if (!rps.string.isNullOrEmpty(this.sourcesToolbarService.itemTitle))
                if (this.sourcesToolbarService.itemTitle.startsWith("vm."))
                    title = `<h5 class="rps-stack-panel-item">{{${this.sourcesToolbarService.itemTitle}.text}}</h5>`;
                else
                    title = `<h5 class="rps-stack-panel-item">${this.sourcesToolbarService.itemTitle}</h5>`;
            else
                title = "";
            var result: string = `
                <div class="items-control-detail rps-editor-container">
                    <rps-columns-manager rpsHorizontalSize="1">
                        <div class="rps-stack-panel">
                            ${title}  
                            <rps-button rpsTemplate="ICON"
                                        rpsLabel="{{resources.directives.CLOSE_DETAIL}}"
                                        rpsIcon="fa fa-times"
                                        [rpsModel]="closeCommand"
                                        class="rps-stack-panel-item-right">
                            </rps-button>
                            <rps-button rpsTemplate="ICON"
                                        rpsLabel="{{resources.directives.SHOW_DETAIL_WINDOW}}"
                                        rpsIcon="k-icon rps-item-detail-maximize"
                                        [rpsModel]="showWindowCommand"
                                        class="rps-stack-panel-item-right">
                            </rps-button>
                        </div>
            `;
            result = result + this.sourcesToolbarService.itemTemplate;
            result = result + "</rps-columns-manager>";
            result = result + "</div>";
            this.formTemplate = result;
        }
        return this.formTemplate;
    }

    getWindowTemplate(): string {
        if (!rps.object.hasValue(this.windowTemplate)) {
            var title: string;
            if (!rps.string.isNullOrEmpty(this.sourcesToolbarService.itemTitle))
                if (this.sourcesToolbarService.itemTitle.startsWith("vm."))
                    title = `[rpsTitle]="${this.sourcesToolbarService.itemTitle}.text"`;
                else
                    title = `rpsTitle="${this.sourcesToolbarService.itemTitle}"`;
            else
                title = "";
            var result: string = `
                <rps-window [rpsOpen]="isOpen"
                            id="detailWindow"
                            [rpsActions]="actions" ${title}>
            `;
            result = result + this.sourcesToolbarService.itemTemplate;
            result = result + "</rps-window>";
            this.windowTemplate = result;
        }
        return this.windowTemplate;
    }

    //on click of the checkbox:
    selectRow(eventArgs: JQueryEventObject) {
        if (this.selectable >= 0) {
            var checkbox: HTMLInputElement = <any>eventArgs.target;
            var checked = checkbox.checked;
            var row = $(checkbox).closest("tr");
            var dataItem = this.kendoGrid.dataItem(row);

            var selectedDataItems: Array<any> = new Array<any>();
            if (this.selectable == 1) {
                if (rps.isISelectable(this.rpsSource)) {
                    this.rpsSource.getSelectedItems().forEach((si) => {
                        selectedDataItems.push(si);
                    });
                }
                else if (this.vmProperty) {
                    this.vmProperty.getSelectedItems().forEach((si) => {
                        selectedDataItems.push(si);
                    });
                }
            }
            else {
                this.kendoGrid.element.find(".k-state-selected").removeClass("k-state-selected");
                this.kendoGrid.element.find(".selectorCheckbox").each(function () {
                    if (this != checkbox)
                        this.checked = false;
                });
            }

            if (checked) {
                //-select the row
                row.addClass("k-state-selected");
                selectedDataItems.push(dataItem);
            } else {
                //-remove selection
                row.removeClass("k-state-selected");
                if (selectedDataItems.indexOf(dataItem) > -1)
                    selectedDataItems.splice(selectedDataItems.indexOf(dataItem), 1);
            }

            if (rps.isISelectable(this.rpsSource)) {
                this.zone.run(() => {
                    this.selectedItems = selectedDataItems;
                    this.rpsSource.setSelectedItems(selectedDataItems);
                });
            }
            else if (this.vmProperty) {
                this.zone.run(() => {
                    this.selectedItems = selectedDataItems;
                    this.vmProperty.setSelectedItems(selectedDataItems);
                });
            }
        }
    }

    pageChange() {
    }

    openItemDetail(target: JQuery, data: rps.viewmodels.BaseVM) {
        if (this.detailTarget) {
            //Eliminar la clase para dejar de identificarlo como seleccionado
            this.detailTarget.removeClass("rps-grid-detail-command-selected");
            delete this.detailTarget;
        }

        //Añadir la clase para identificarlo como seleccionado
        this.detailTarget = target;
        this.detailTarget.addClass("rps-grid-detail-command-selected");

        if (this.itemDetailType == 0)
            this.showItemDetailForm(data);
        else
            this.showItemDetailWindow(data);
    }

    showItemDetailForm(data: rps.viewmodels.BaseVM) {
        this.itemDetailType = 0;
        this.itemDetailFormOpened = true;
        //Si ya está abierto, se elimina el componente anterior, sino se establece el modo detalle
        if (this.itemDetailComponent) {
            this.itemDetailComponent.destroy();
            delete this.itemDetailComponent;
        }

        //Se crea el componente, se instancia y se añade al dom
        var module: Type<any> = this.moduleProxyFactory(this.getFormTemplate(), data, true);
        this.compiler.compileModuleAndAllComponentsAsync(module).then((moduleWithComponent) => {
            var virtualFactory = Enumerable.From(moduleWithComponent.componentFactories).First(
                (fac) => fac.selector == "rps-virtual-component");
            var componentRef: ComponentRef<any> = this.itemsControlDetail.createComponent(virtualFactory);
            this.itemDetailComponent = componentRef;
        });
    }

    showItemDetailWindow(data: rps.viewmodels.BaseVM) {
        this.itemDetailType = 1;
        this.itemDetailFormOpened = false;
        //Si ya está abierto, se elimina el componente anterior, sino se establece el modo detalle
        if (this.itemDetailComponent) {
            this.itemDetailComponent.destroy();
            delete this.itemDetailComponent;
        }

        //Se crea el componente, se instancia y se añade al dom
        var module: Type<any> = this.moduleProxyFactory(this.getWindowTemplate(), data, false);
        this.compiler.compileModuleAndAllComponentsAsync(module).then((moduleWithComponent) => {
            var virtualFactory = Enumerable.From(moduleWithComponent.componentFactories).First(
                (fac) => fac.selector == "rps-virtual-component");
            var componentRef: ComponentRef<any> = this.itemsControlDetail.createComponent(virtualFactory);
            this.itemDetailComponent = componentRef;
        });
    }

    closeItemDetail() {
        this.itemDetailFormOpened = false;

        if (this.itemDetailComponent) {
            this.itemDetailComponent.destroy();
            delete this.itemDetailComponent;
        }
        if (this.detailTarget) {
            //Eliminar la clase para dejar de identificarlo como seleccionado
            this.detailTarget.removeClass("rps-grid-detail-command-selected");
            delete this.detailTarget;
        }
    }

    private moduleProxyFactory(templateContent: string, viewModel: rps.viewmodels.BaseVM, isNarrow: boolean): Type<any> {
        //Se guarda en una variable, ya que si no, el this no es this…
        var _rpsGrid: rpsGrid = this;

        //Los input-s de tipo JSon, tienen doble comilla, por lo que se sustituye la apertura y el cierre a comilla simple
        templateContent = templateContent.replace(/"\[/g, "'[");
        templateContent = templateContent.replace(/\]"/g, "]'");

        const VM = new OpaqueToken("vm");
        const GRID = new OpaqueToken("rpsGrid");

        @Component({
            template: templateContent,
            selector: "rps-virtual-component",
            providers: [
                { provide: VM, useValue: viewModel },
                { provide: GRID, useValue: _rpsGrid }
            ]
        })
        class VirtualComponent implements OnDestroy {

            //Los tabControls usan un array especial para ir guardando los tabs que se van seleccionando (y creando); llamado desde las plantillas para crear este array
            //En este caso, crea un objeto que siempre devuelve 0 en el método indexOf, para que el contenido de todas las pestañas sea visible
            public createCreatedTabItemsArray(id: string) {
                this[id] = [];
                this[id].indexOf = (index: number) => {
                    return 0;
                }
            }

            public actions: [rpsWindowAction];
            public isOpen: boolean = true;
            public closeCommand: rps.viewmodels.commands.CommandProperty;
            public showWindowCommand: rps.viewmodels.commands.CommandProperty;

            constructor( @Inject(VM) public vm: rps.viewmodels.BaseVM, @Inject(GRID) public rpsGrid: rpsGrid) {
                this.closeCommand = new rps.viewmodels.commands.CommandProperty({
                    target: this,
                    command: this.close
                });
                this.showWindowCommand = new rps.viewmodels.commands.CommandProperty({
                    target: this,
                    command: this.showWindow
                });

                this.actions = [
                    {
                        target: this,
                        name: 'rps-item-detail-minimize',
                        method: this.showForm
                    }, {
                        target: this,
                        name: 'close',
                        method: this.close
                    }];
            }

            public get $root() {
                return rps.app;
            }

            public get resources() {
                return rps.app.resources;
            }

            public ngOnDestroy() {
            }

            close(): Promise<any> {
                //Ocultar el formulario
                this.rpsGrid.closeItemDetail();
                return Promise.resolve<any>(this);
            }

            showWindow(): Promise<any> {
                this.rpsGrid.showItemDetailWindow(this.vm);
                return Promise.resolve<any>(this);
            }

            showForm(): Promise<any> {
                this.rpsGrid.showItemDetailForm(this.vm);
                return Promise.resolve<any>(this);
            }
        }

        var imports: Array<any>;
        if (isNarrow)
            imports = [rpsModule, rpsNarrowModule, FormsModule, CommonModule]
        else
            imports = [rpsModule, rpsDesktopModule, FormsModule, CommonModule]

        @NgModule({
            imports: imports,
            declarations: [
                VirtualComponent
            ]
        })
        class VirtualModule {
        }
        // a module for just this Type
        return VirtualModule;
    }
}
