import {Injectable, Inject} from '@angular/core';

@Injectable()
export class rpsNotificationManager implements rps.services.INotificationManager {

    private kendoNotification: kendo.ui.Notification;

    constructor() {
    }

    configure = () => {
        rps.app.notificationManager = this;
    }

    setKendoNotification(kendoNotification) {
        this.kendoNotification = kendoNotification;
    }

    show(params: {
        message: string;
        notificationType: rps.services.NotificationType;
    }) {
        var type: string = "info";
        switch (params.notificationType) {
            case rps.services.NotificationType.Info:
                type = "info";
                break;
            case rps.services.NotificationType.Success:
                type = "success";
                break;
            case rps.services.NotificationType.Warning:
                type = "warning";
                break;
            case rps.services.NotificationType.Error:
                type = "error";
                break;
        }
        this.kendoNotification.show(params.message, type);
    }
}
