import {Component, ElementRef, Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsReadOnlyEditor} from './readOnlyEditorBase'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'

@Component({
    selector: 'rps-read-only-duration-editor',
    template: `
        <div class="rps-editor" 
             [rpsSemanticState]="rpsModel?.semanticState"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label">
                {{rpsLabel}}
            </label>
            <div class="rps-editor-editor rps-read-only-editor-viewer rps-read-only-semantic-state rps-read-only-number-editor-viewer">
                {{rpsModel?.formattedValue}} <span *ngIf="rpsModel?.suffix" class="rps-formatted-number-suffix">{{rpsModel.suffix}}</span>
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors" >
        </error-detail-validator>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsReadOnlyEditor.getComponentInputs()
})
export class rpsReadOnlyDurationEditor extends rpsReadOnlyEditor<number, rps.viewmodels.properties.DurationProperty>  {
    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }
}