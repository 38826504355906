import { Component } from '@angular/core';
import {rpsRowsGroup} from '../layouts/rowsGroup';
import {rpsRow} from '../layouts/row';
import {rpsColumns} from '../layouts/columns';
import {rpsButton} from '../editors/button';
import {rpsSearchBox} from '../editors/searchBox';

@Component({
    selector: 'rps-hierarchical-query-source-tools',
    template: `
        <div>
            <rps-rows-group *ngIf="isQueriable">
                <rps-row>
                    <rps-button rpsColumns="2"
                                rpsTemplate="TEXT_WITH_ICON"
                                rpsLabel="{{resources.directives.QUERY}}"
                                rpsIcon="fa fa-refresh"
                                [rpsModel]="rpsSource?.loadItems"
                                rpsType="PRIMARY">
                    </rps-button>
                    <div rpsColumns="9">
                    </div>
                </rps-row>
            </rps-rows-group>                
        </div>
    `,
    inputs: ['rpsSource','rpsIsQueriable'],
})
export class rpsHierarchicalQuerySourceTools{

    public isQueriable: boolean = true;
    public get rpsIsQueriable(): string {
        if (this.isQueriable)
            return "true";
        else
            return "false";
    }
    public set rpsIsQueriable(newValue: string) {
        if (newValue == "true")
            this.isQueriable = true;
        else
            this.isQueriable = false;
    }

    public get resources() {
        return rps.app.resources;
    }

    public rpsSource: rps.data.sources.HierarchicalQuerySource;

    constructor() {
    }
}