import { Component, OnChanges, SimpleChange, AfterContentInit} from '@angular/core';
import {rpsRowsGroup} from './rowsGroup';
import {rpsRow} from './row';
import {rpsColumns} from './columns';
import {rpsButton} from '../editors/button';

@Component({
    selector: 'rps-wizard',
    template: `
        <div>
            <rps-rows-group>
                <ng-content></ng-content>
                <rps-row>
                    <div rpsColumns="3"
                         rpsColumnsInvariant="true"></div>
                    <div rpsColumns="3"
                         rpsColumnsInvariant="true">
                        <rps-button *ngIf="rpsModel?.allowBack()"
                                    rpsTemplate="TEXT_WITH_ICON"
                                    rpsLabel="{{resources.directives.BACK}}"
                                    rpsIcon="fa fa-chevron-left"
                                    [rpsModel]="rpsModel?.back">
                        </rps-button>
                    </div>            
                    <rps-button rpsColumns="3"
                                rpsColumnsInvariant="true"
                                rpsTemplate="TEXT_WITH_ICON"
                                rpsLabel="{{resources.directives.CANCEL}}"
                                rpsIcon="fa fa-times"
                                [rpsModel]="rpsModel?.cancel">
                    </rps-button>
                    <rps-button *ngIf="rpsModel?.allowNext()"
                                rpsColumns="3"
                                rpsColumnsInvariant="true"
                                rpsTemplate="TEXT_WITH_ICON"
                                rpsLabel="{{resources.directives.NEXT}}"
                                rpsIcon="fa fa-chevron-right"
                                [rpsModel]="rpsModel?.next"
                                rpsType="PRIMARY">
                    </rps-button>
                    <rps-button *ngIf="rpsModel?.allowOk()"
                                rpsColumns="3"
                                rpsColumnsInvariant="true"
                                rpsTemplate="TEXT_WITH_ICON"
                                rpsLabel="{{resources.directives.OK}}"
                                rpsIcon="fa fa-check"
                                [rpsModel]="rpsModel?.ok"
                                rpsType="PRIMARY">
                    </rps-button>
                </rps-row>
            </rps-rows-group>
        </div>
    `,
    inputs: ['rpsModel']
})
export class rpsWizard {
    rpsModel: rps.data.wizards.Wizard;

    public get resources() {
        return rps.app.resources;
    }
}

@Component({
    selector: 'rps-wizard-step',
    template: `
        <div>
            <div *ngIf="isActive">
                <div class="rps-wizard-step-title">{{rpsTitle}}</div>
                <ng-content></ng-content>
            </div>
        </div>
    `,
    inputs: ['rpsModel', 'rpsTitle']
})
export class rpsWizardStep implements OnChanges {
    rpsModel: rps.data.wizards.WizardStep;
    rpsTitle: string;

    public isActive: boolean = false;

    ngOnChanges(changes: { [key: string]: SimpleChange; }): any {
        if (changes["rpsModel"])
            this.setWizardStep();
    }

    setWizardStep() {
        if (this.rpsModel) {
            this.rpsModel.propertyChanged.subscribe((e: rps.viewmodels.properties.VMPropertyChange) => this.isActiveChanged(e.newValue));

            this.isActiveChanged(this.rpsModel.isActive);
        }
        else {
            this.isActive = false;
        }
    }

    isActiveChanged(newValue: boolean){
        if (this.isActive !== newValue)
            this.isActive = newValue;
    }

}

       