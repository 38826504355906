declare var System;
import {Component, Provider, ElementRef, Injector, Injectable, AfterViewInit, ViewContainerRef, ViewChild} from '@angular/core';
import {startup} from './startup';
import {Http, Response, Headers, URLSearchParams} from '@angular/http';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import {Router,Route,LoadChildren} from '@angular/router';
import { Empty } from 'rps/src/components/components'
import {rpsAppSettings} from 'rps/src/services/appSettings';
//import {DynamicRouteConfigurator} from 'rps/src/utils/dynamicRouteConfigurator';
import {rpsMenu} from 'rps/src/app/menu';

@Component({
    selector: "rps-app",
    template: `
        <div class="rps-master-container">
            <rps-container>
                <rps-container-header style="height:'auto'">
                    <rps-menu [rpsModel]="vm.newMenu">
                    </rps-menu>
                    <rps-breadcrumb [rpsDataSource]="vm.rpsStateManager.uiStateHierarchy" #breadcrumb>
                    </rps-breadcrumb>
                    <div class="rps-help-button fa fa-question-circle" [hidden]="breadcrumb.hidden"
                        (click)="openHelpPage()">
                    </div>        
                </rps-container-header>
                <rps-container-content>
                    <rps-busy-indicator [rpsIsBusy]="busyIndicatorManager.isBusy">
                        <div>
                            <rps-error-details-viewer [rpsErrorDetails]="vm?.errorManager?.errorDetails">
                            </rps-error-details-viewer>                            
                        </div>
                        <router-outlet>
                        </router-outlet>
                    </rps-busy-indicator>
                </rps-container-content>
            </rps-container>
        </div>                
    <span id="KendoNotification">
    </span>
    <div #showWindow></div>
`,
    providers: [startup]
})
export class rpsApp implements AfterViewInit {
    @ViewChild('showWindow', { read: ViewContainerRef })
    showWindow: ViewContainerRef;    

    private busyIndicator: number = 0;
    private static openedComponents = new Array<string>();

    public logged: boolean = false;
    public user: string = "";
    public language: string = "";

    public newMenu: rps.app.viewmodels.UIMenu = new rps.app.viewmodels.UIMenu();

    public get sessionCompany(): string {
        return rps.app.session.company;
    }

    public get errorManager(): rps.services.IErrorManager {
        return rps.app.errorManager;
    }

    public get busyIndicatorManager(): rps.services.IBusyIndicatorManager {
        return rps.app.busyIndicatorManager;
    }

    public get vm() {
        return this;
    }

    public get rpsStateManager(): rps.services.IStateManager {
        return rps.app.stateManager;
    }

    public get resources() {
        return rps.app.resources;
    }
    constructor(private _element: ElementRef, startup: startup,
        private _router: Router,
        private _http: Http, private _rpsAppSettings: rpsAppSettings) {

        rps.app.eventManager.rpsMenuChanged.subscribe((data: {menu:any[],favourites:any}) => {
            this.newMenu.modules.splice(0, this.newMenu.modules.length);
            data.menu.forEach((module) => {
                var newModule = new rps.app.viewmodels.UIModule(module.ID, module.Description, module.Image);
                module.Groups.forEach((group) => {
                    var newGroup = new rps.app.viewmodels.
                        UIMenuItemGroup(group.ID, group.Description, group.Image);
                    group.Items.forEach((item) => {
                        let uiMenuItem = this.itemToUIMenuItem(item);
                        if (uiMenuItem != null) {
                            newGroup.items.push(uiMenuItem);
                        }
                    });
                    newModule.menuGroups.push(newGroup);
                });
                this.newMenu.modules.push(newModule);
            });

            let favModule = new rps.app.viewmodels.UIModule(data.favourites.ID, data.favourites.Description, data.favourites.Image);
            data.favourites.Groups.forEach((group) => {
                var newGroup = new rps.app.viewmodels.
                    UIMenuItemGroup(group.ID, group.Description, group.Image);
                group.Items.forEach((item) => {
                    let uiMenuItem = this.itemToUIMenuItem(item);
                    if (uiMenuItem != null) {
                        newGroup.items.push(uiMenuItem);
                    }
                });
                favModule.menuGroups.push(newGroup);
            });
            this.newMenu.favourites = favModule.menuGroups;
        });

        rps.app.eventManager.rpsSessionDataChanged.subscribe(() => {
            if (rps.app.session.user && this.user != rps.app.session.user) {
                //Recargar rutas para el usuario concreto
                this.loadAppRoutes();
                if (rps.app.session.language && this.language != rps.app.session.language) {
                    this.removeSystemJSCache();
                }
            }
            else if (rps.app.session.language && this.language != rps.app.session.language) {
                //En el cambio de idioma reseteamos la configuración del router para que borre la 
                //caché de vistas y vuelva a pedir las vistas de las pantallas aunque estas ya hayan sido abiertas;
                //si no, el cambio de idioma no se aplica a las pantallas que ya hayan sido abiertas hasta que 
                //se cierre sesión y se vuelvan a configurar las rutas
                this.loadAppRoutes();
                this.removeSystemJSCache();
            }

            this.logged = rps.app.session.logged;
            this.user = rps.app.session.user;
            this.language = rps.app.session.language;
        });

        rps.app.session.checkSessionState(true);

    }

    private itemToUIMenuItem(item: any): rps.app.viewmodels.UIMenuItem {
        switch (item.ItemType) {
            case "component":
                return new rps.app.viewmodels.UIMenuItemComponent(item.ID, item.Description, item.Image, item.TargetState, item.IsFavourite);
            case "workflow":
                return new rps.app.viewmodels.UIMenuItemWorkflow(item.ID, item.Description, item.Image, item.TargetWorkflowID, item.IsFavourite);
            case "report":
                return new rps.app.viewmodels.UIMenuItemReport(item.ID, item.Description, item.Image, item.TargetCodReport, item.IsFavourite);
            default:
                return null;    
        }
    }

    public ngAfterViewInit() {
        var notificationElement = $(this._element.nativeElement).find("#KendoNotification").kendoNotification().data("kendoNotification");
        rps.app.notificationManager.setKendoNotification(notificationElement);
        rps.app.uiFactory.setWindowContainer(this.showWindow);
    }

    private staticRoutes: Route[];
    private loadAppRoutes(): Promise<any> {
        //Pedir la lista de estados para registrarlos
        return new Promise((resolve, reject) => {
            let userParam = new URLSearchParams();
            userParam.set("codUser", rps.app.session.user);
            this._http.get(
                this._rpsAppSettings.rpsAPIAddress + "clientapi/State",
                {
                    body: '',
                    search:userParam,
                    headers: new Headers({
                        'Content-Type': 'application/json',
                    })
                }).toPromise().then((states: Response) => {
                    var config: Route[] = [];
                    states.json().forEach((state: { Service: string, Component: string, MainState: string }) => {
                        //Crear array con las nuevas rutas (pasando a minúscula la principal)
                        var pathParts: Array<string> = state.MainState.replace('/', '.').split('/');
                        pathParts[0] = pathParts[0].toLowerCase();
                        var route = {
                            path: pathParts.join('/'),
                            loadChildren: () => {
                                return rpsApp.loadRouteChildren(state.Service, state.Component);
                            },
                            data: rpsApp.getRouteData(state.Service, state.Component, state.MainState)
                        };

                        if (state.Component != "Logon" &&
                            state.Component != "Desktop")
                            config.push(route);
                    });

                    this.reconfigureRouter(config);
                    resolve(true);
                }).catch((error) => {
                    debugger;
                    alert("Error: " + error.toString());
                    reject(error);
                });
        });
    }

    private reconfigureRouter(config: Route[]) {
        //Reconfigurar el router (la primera vez se guarda por si luego cambia de usuario)
        var mainConfig = this._router.config;
        if (!this.staticRoutes)
            this.staticRoutes = mainConfig[0].children;
        mainConfig[0].children = this.staticRoutes.concat(config);
        this._router.resetConfig(mainConfig);
    }

    public static getRouteData(service: string, componentName: string, mainState:string) {
        return {
            name: this.toPascalCase(service) + "." + this.toPascalCase(componentName),
            path: mainState.toLowerCase(),
            absName: this.toPascalCase(service) + "." + this.toPascalCase(componentName)
        }
    }

    public static loadRouteChildren(service: string, componentName: string) {
        rps.app.busyIndicatorManager.setIsBusy(true);
        let key = rps.app.appSettings.rpsAPIAddress + "clientapi/" + service + "/" + componentName + "/view";
        rpsApp.openedComponents.push(key)
        return System.import(key).then((m) => {
            return m.ComponentModule;
        }).catch((err) => {
            rps.app.busyIndicatorManager.setIsBusy(false);
        });
    }

    private removeSystemJSCache() {
        //Borra la caché de SystemJS. Parece que en v0.20 se puede acceder directamente al registry, pero en esta versión
        //se van guardando los componentes abiertos (el nombre) para luego borrarlos todos. Se llama en el cambio de idioma, para 
        //que no devuelva el componente cacheado para el idioma antiguo
        try {
            for (var i = 0; i < rpsApp.openedComponents.length; i++) {
                System.delete(rpsApp.openedComponents[i]);
            }
            rpsApp.openedComponents.length = 0;

        } catch (e) {
        }
    }

    private static toPascalCase(str:string) {
        return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1); });
    }

    public openHelpPage(): void {
        if (rps.app.stateManager.stateStack.length > 0) {
            let currentState = rps.app.stateManager.stateStack.at(0);
            let service = ""; let name = "";
            if (currentState && currentState.configOptions) {
                service = currentState.configOptions.stateName.split('.')[0];
                name = currentState.configOptions.stateName.split('.')[1];
            }

            var windowRef = rps.app.appSettings.rpsAPIAddress + "clientapi/" + service + "/" + name + "/help";
            window.open(windowRef, "_blank");
        }
    }
}
