import { Component, ElementRef, Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsReadOnlyEditor} from './readOnlyEditorBase'
import {errorDetailValidator} from '../utils/errorDetailValidator'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'

@Component({
    selector: 'rps-read-only-long-text-box-little',
    template: `
        <div class="rps-editor  rps-long-text-box-little-container rps-read-only-long-text-box-little" 
             [rpsSemanticState]="rpsModel?.semanticState" 
             [ngClass]="{'rps-disabled-editor': disabled}"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label" 
                    [attr.for]="myId">
                {{rpsLabel}}<font *ngIf="isRequired" color="red"> *</font>
            </label>            
             <div class="rps-long-text-box-little ">
                <div class="rps-editor-editor rps-read-only-editor-viewer rps-read-only-semantic-state">                    
                    <div>
                        {{text}}
                    </div>                                       
                </div>               
                <div class="rps-long-text-box-little-edit-button rps-editor-fit-container">
                    <rps-button rpsTemplate="ICON" 
                                rpsIcon="fa fa-lg fa fa-pencil-square-o" 
                                [rpsModel]="openEditor"
                                rpsPreventTabindex="true"
                                [ngClass]="{'rps-long-text-box-little-edit-button-disabled':!rpsModel?.hasValue(),
                                            'rps-long-text-box-little-edit-button-focus':isEditing}">
                    </rps-button>
                </div>
            </div>
        </div>
        <div id="read-only-editor-window">
            <div>
                <rps-rows-group>        
                    <rps-row>
                        <div class="rps-editor rps-read-only-long-text-box" [rpsSemanticState]="rpsModel?.semanticState">
                            <div class="rps-editor-editor rps-read-only-editor-viewer rps-read-only-semantic-state">{{text}}</div>
                        </div>
                    </rps-row>
                    <rps-row>            
                        <rps-button rpsColumns="6"
                                    rpsLabel="{{resources?.directives?.ACCEPT}}"
                                    [rpsModel]="acceptButton">
                        </rps-button>
                    </rps-row>   
                </rps-rows-group>
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsReadOnlyEditor.getComponentInputs()
})
export class rpsReadOnlyLongTextBoxLittle extends rpsReadOnlyEditor<string, rps.viewmodels.properties.VMProperty<string>>  {
    private kendoWindow: kendo.ui.Window;

    openEditor: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: (): Promise<any> => {
            this.kendoWindow.center().open();
            return Promise.resolve<any>(this);
        }
    });

    acceptButton: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: (): Promise<any> => {
            this.kendoWindow.close();
            return Promise.resolve<any>(this);
        }
    });

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    createTargetControl() {
        this.kendoWindow = $(this.elementRef.nativeElement).find("#read-only-editor-window").kendoWindow({
            modal: true,
            title: this.rpsLabel,
            width: 600,
            visible: false
        }).data("kendoWindow");
    }

    destroyTargetControl() {
        if (this.kendoWindow) {
            this.kendoWindow.destroy();
            this.kendoWindow = null;
            this.element.find("#read-only-editor-window").first().empty();
        }
    }
}
