
import {Injectable} from '@angular/core';
import {DefaultUrlSerializer, UrlTree} from '@angular/router';

@Injectable()
export class rpsUrlSerializer extends DefaultUrlSerializer {

    parse(url: string): UrlTree{
        return super.parse(url);
    }

    serialize(tree: UrlTree) : string{
        return super.serialize(tree);
    }
}
