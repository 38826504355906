import {Directive, ElementRef, OnChanges, Renderer, SimpleChange } from '@angular/core';

@Directive({
    selector: '[rpsLabelDisabled]',
    inputs: ['rpsLabelDisabled'],
})
export class rpsLabelDisabled implements OnChanges{
    rpsLabelDisabled: boolean;

    constructor(private elementRef: ElementRef, private renderer: Renderer) {
    }
    ngOnChanges(changes: { [rpsLabelDisabled: string]: SimpleChange }) {
        this.deleteClasses();
        if (changes['rpsLabelDisabled'].currentValue) {
            this.renderer.setElementClass(this.elementRef.nativeElement, "rps-editor-disabled", true);
        }
    }
    private deleteClasses() {
        this.renderer.setElementClass(this.elementRef.nativeElement, "rps-editor-disabled", false);
    }
}