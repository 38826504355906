import {Component, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { baseServerColumn } from './baseServerColumn';

@Component({    
    template: `
        <div align="center" class="rps-read-only-checkbox-container">
            <input class="rps-read-only-checkbox"
                   type="checkbox"
                   [ngModel]="value"
                   disabled="true">
            <label class="rps-read-only-checkbox-label"></label>
        </div>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class rpsCheckBoxServerColumn extends baseServerColumn<boolean>{
    value: boolean;

    constructor(_cdr: ChangeDetectorRef) {
        super(_cdr);
    }

    setValue(newValue: boolean) {
        if (this.value !== newValue) {
            this.value = newValue;
            this._cdr.markForCheck();
        }
    }
}