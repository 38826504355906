import {Component, ElementRef, ViewChild, ViewContainerRef,ComponentRef, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {NgClass} from '@angular/common';
import { rpsCardViewBase, rpsCardViewBaseTemplate} from './cardViewBase'
import {rpsItemsControlToolbarItem} from './itemsControlToolbarItem'
import {rpsSemanticState} from '../utils/semanticState';

export enum descriptorSizeHeight {
    size1 = 0,
    size2 = 1,
    size3 = 2,
    size4 = 3
}
export enum descriptorSizeWidth {
    small = 0,
    big = 1
}

@Component({
    template: `
        <div class="rps-card col-xs-12 col-sm-6 col-md-4" 
			 [rpsColumnsLG]="rpsColumnsLG" 
             rpsColumns="4"
             rpsColumnsSM="6">
            <div class="rps-card-padding">
                <div class="rps-card-border">
                    <div class="rps-card-left rps-img-container" *ngIf="descriptor?.Image && descriptorHeight==0" [rpsImageSize]="3">                    
                        <img class="rps-img" src="{{descriptor.Image}}" [attr.alt]="descriptor.MainDescriptor"/>                       
                    </div>
                    <div class="rps-card-left rps-img-container" *ngIf="descriptor?.Image && descriptorHeight==1" [rpsImageSize]="5">
                        <img class="rps-img" src="{{descriptor.Image}}" [attr.alt]="descriptor.MainDescriptor"/>
                    </div>
                    <div class="rps-card-left rps-img-container" *ngIf="descriptor?.Image && descriptorHeight==2" [rpsImageSize]="6">
                        <img class="rps-img" src="{{descriptor.Image}}" [attr.alt]="descriptor.MainDescriptor"/>
                    </div>
                    <div class="rps-card-left rps-img-container" *ngIf="descriptor?.Image && descriptorHeight==3" [rpsImageSize]="9">
                        <img class="rps-img" src="{{descriptor.Image}}" [attr.alt]="descriptor.MainDescriptor"/>
                    </div>
                    <div class="rps-card-right" *ngIf="descriptor?.EntityAmount != undefined">
                        <h3 [rpsSemanticState]="descriptor?.EntityAmount?.StateInfo">{{entityAmountString}}</h3>
                        <h4>{{descriptor.EntityAmount.Unit}}</h4>
                    </div>
                    <div [ngClass]="{'rps-card-size-1': descriptorHeight == 0,'rps-card-size-2': descriptorHeight == 1,'rps-card-size-3': descriptorHeight == 2,'rps-card-size-4': descriptorHeight == 3}"
                         class="rps-card-content">
                        <a *ngIf="descriptor && linkRouterParams" [routerLink]="linkRouterParams" [queryParams]="linkRouterQueryParams">
                            <h3>{{descriptor.MainDescriptor}}</h3>
                            <h4>{{descriptor.SecondaryDescriptor}}</h4>
                        </a>
                        <div *ngIf="descriptor && !linkRouterParams">
                            <h3>{{descriptor.MainDescriptor}}</h3>
                            <h4>{{descriptor.SecondaryDescriptor}}</h4>
                        </div>
                        <div>    
                            <span *ngIf="descriptor?.EntityState != undefined"
                                  [ngClass]="entityStateClass">
                                {{descriptor.EntityState.StateName}}
                            </span>
                            <h4 *ngIf="descriptor?.EntityDate != undefined" [rpsSemanticState]="descriptor?.EntityDate?.StateInfo">
                                {{descriptor.EntityDate.DateName}} {{entityDateString}}
                            </h4>
                            <h4 *ngIf="descriptor?.FirstAttribute != undefined"
                                class="rps-card-attribute">
                                {{descriptor.FirstAttribute}}
                            </h4>
                            <h4 *ngIf="descriptor?.SecondAttribute != undefined"
                                class="rps-card-attribute">
                                {{descriptor.SecondAttribute}}
                            </h4>
                        </div>
                    </div>
            
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class rpsDescriptorViewItem {    
    public descriptor: rps.data.FullDescriptor;
    public entityStateClass: string;
    public linkRouterParams: Array<any>;
    public linkRouterQueryParams: rps.IParams;
    public entityDateString: string;
    public entityAmountString: string;
    public descriptorHeight: descriptorSizeHeight;
    public rpsColumnsLG: string = "4";
    private _descriptorWidth: descriptorSizeWidth = descriptorSizeWidth.big;
    set descriptorWidth(value: descriptorSizeWidth) {
        if (this._descriptorWidth != value) {
            this._descriptorWidth = value;
            switch (value) {
                case descriptorSizeWidth.big:
                    this.rpsColumnsLG = "4";
                    break;
                case descriptorSizeWidth.small:
                    this.rpsColumnsLG = "3";
                    break;
                default:
                    this.rpsColumnsLG = "4";
            }
            this._cdr.markForCheck();
        }
    }
    get descriptorWidth(): descriptorSizeWidth {
        return this._descriptorWidth;
    }    
    constructor(private _cdr: ChangeDetectorRef) {         
    }

    setDescriptor = (newValue: rps.data.FullDescriptor, sizeHeight: descriptorSizeHeight, sizeWidth: descriptorSizeWidth) => {
        this.descriptor = newValue;
        if (!rps.object.isNullOrUndefined(sizeHeight))
            this.descriptorHeight = sizeHeight;
        if (!rps.object.isNullOrUndefined(sizeWidth))
            this.descriptorWidth = sizeWidth;
        if (this.descriptor && this.descriptor.EntityDate)
            this.entityDateString = kendo.toString(this.descriptor.EntityDate.Date, rps.utils.getPatternDate());
        if (this.descriptor && this.descriptor.EntityAmount)
            this.entityAmountString = kendo.toString(this.descriptor.EntityAmount.Amount, rps.utils.getDecimalFormat());
        if (this.descriptor && this.descriptor.EntityState) {
            switch (this.descriptor.EntityState.StateInfo) {
                case rps.data.SemanticState.Negative:
                    this.entityStateClass = "label label-danger";
                    break;
                case rps.data.SemanticState.Neutral:
                    this.entityStateClass = "label label-default";
                    break;
                case rps.data.SemanticState.Positive:
                    this.entityStateClass = "label label-success";
                    break;
                case rps.data.SemanticState.Warning:
                    this.entityStateClass = "label label-warning";
                    break;
                case rps.data.SemanticState.Info:
                    this.entityStateClass = "label label-info";
                    break;
                default:
                    this.entityStateClass = "label";
            }
        }
        else {
            this.entityStateClass = "";
        }
        this._cdr.markForCheck();
    }

    setLinkProperty(newValue: rps.viewmodels.properties.ILinkProperty) {
        var params = newValue.getRouterLinkParameters();
        this.linkRouterParams = params.routerLink;
        this.linkRouterQueryParams = params.queryParams;
    }
}

@Component({
    selector: 'rps-descriptor-view',
    template: rpsCardViewBaseTemplate,
    inputs: ['rpsSource', 'rpsSelectionMode', 'rpsLinkPropertyPath', 'rpsDescriptorPropertyPath']
})
export class rpsDescriptorView extends rpsCardViewBase implements OnDestroy {   
    @ViewChild('dynamicContainer', { read: ViewContainerRef })
    private dynamicContainer: ViewContainerRef;
    private descriptorHeight: descriptorSizeHeight;
    private descriptorWidth: descriptorSizeWidth;
    private propChangedSubscriptions: Array<rps.services.ISubscription> = new Array<rps.services.ISubscription>();

    rpsLinkPropertyPath: string;    
    //Se hace un Split para agilizar la búsqueda en cada uno de los ítems y no hacerlo por cada uno de ellos
    private descriptorPropertyPaths: Array<string>;
    set rpsDescriptorPropertyPath(newValue: string) {
        if (rps.string.isNullOrEmpty(newValue))
            this.descriptorPropertyPaths = null;
        else
            this.descriptorPropertyPaths = newValue.split(".");
    }    

    constructor(elementRef: ElementRef, queryToolbarItem: rpsItemsControlToolbarItem) {
        super(elementRef, queryToolbarItem);        
    }

    private getItemDescriptor(dataItem: any): rps.data.FullDescriptor {
        //Si la longitud es de uno, significa, que es una vpProperty del VM, si es de 2, es porque es una propiedad del modelo                
        if (this.descriptorPropertyPaths.length == 1) {
            var vmProperty: rps.viewmodels.properties.VMProperty<rps.data.FullDescriptor> = (<any>dataItem)[this.descriptorPropertyPaths[0]];
            return vmProperty.value;
        } else {
            return (<any>dataItem)[this.descriptorPropertyPaths[0]][this.descriptorPropertyPaths[1]];
        }
    }
    private attachItemDescriptorChanged(dataItem: any,itemDescriptorChanged: (newValue: rps.data.FullDescriptor) => any) {
        //Si la longitud es de uno, significa, que es una vpProperty del VM, si es de 2, es porque es una propiedad del modelo                
        if (this.descriptorPropertyPaths.length == 1) {
            var vmProperty: rps.viewmodels.properties.VMProperty<rps.data.FullDescriptor> = (<any>dataItem)[this.descriptorPropertyPaths[0]];
            //Nos enganchamos al cambio del valor de la VM property
            var propChangedSubscription: rps.services.ISubscription;
            propChangedSubscription = vmProperty.propertyChanged.subscribe((value: rps.viewmodels.properties.VMPropertyChange) => {
                if (value.propertyName == "value") {
                    itemDescriptorChanged(vmProperty.value);
                    propChangedSubscription.unsubscribe();
                    this.propChangedSubscriptions.splice(this.propChangedSubscriptions.indexOf(propChangedSubscription), 1);
                }
            });
            this.propChangedSubscriptions.push(propChangedSubscription);
        }
    }
    private getNumberItemsDescriptor(dataItem: any): number{   
        var nItems: number = 0;
        var descriptor = this.getItemDescriptor(dataItem);
        if (descriptor) {
            //Devuelve el numero de elementos del descriptor           
            if (!rps.object.isNullOrUndefined(descriptor.MainDescriptor))
                nItems++;
            if (!rps.object.isNullOrUndefined(descriptor.SecondaryDescriptor))
                nItems++;
            if (!rps.object.isNullOrUndefined(descriptor.EntityState))
                nItems++;
            if (!rps.object.isNullOrUndefined(descriptor.EntityDate))
                nItems++;
            if (!rps.object.isNullOrUndefined(descriptor.FirstAttribute))
                nItems++;
            if (!rps.object.isNullOrUndefined(descriptor.SecondAttribute))
                nItems++;
        }       
        return nItems;
    }

    createItems(dataItems: JQuery) { 
        //En el caso de que todos los elementos no contengan el descriptor, 
        //se espera a que esto suceda, de esta manera, 
        //se permite cargar los elementos en asíncrono.      
        if (!Enumerable.From<rps.viewmodels.ModelVM>(this.kendoListView.dataSource.data()).Any(vm => !this.getItemDescriptor(vm)))
            this.createItemsAsync(dataItems);
        else {
            Enumerable.From<rps.viewmodels.ModelVM>(this.kendoListView.dataSource.data()).Where(vm => !this.getItemDescriptor(vm)).forEach((vm) => {
                this.attachItemDescriptorChanged(vm, () => {
                    if (!Enumerable.From<rps.viewmodels.ModelVM>(this.kendoListView.dataSource.data()).Any(vm => !this.getItemDescriptor(vm)))
                        this.createItemsAsync(dataItems);
                });
            });
        }            
    }

    createItemsAsync(dataItems: JQuery) {
        //Se asigna el tamaño del descriptor en funcion de los datos que tenga.
        //Depende de la cantidad de elementos que tenga, se asignará un tamaño u otro.          
        if (this.kendoListView.dataSource.data().length > 0) {
            switch (Enumerable.From<rps.viewmodels.ModelVM>(this.kendoListView.dataSource.data()).Max(vm => this.getNumberItemsDescriptor(vm))) {
                case 1:
                case 2:
                    this.descriptorHeight = descriptorSizeHeight.size1;
                    break;
                case 3:
                    this.descriptorHeight = descriptorSizeHeight.size2;
                    break;
                case 4:
                    this.descriptorHeight = descriptorSizeHeight.size3;
                    break;
                case 5:
                case 6:
                    this.descriptorHeight = descriptorSizeHeight.size4;
                    break;
                default:
                    this.descriptorHeight = descriptorSizeHeight.size4;
            }
        }
        //Si tiene amount se mostraran 3 descriptores en cada fila en pantallas grandes, si no tiene se mostraran 4 por fila.
        if (Enumerable.From<rps.viewmodels.ModelVM>(this.kendoListView.dataSource.data())
            .Any(vm => {
                if (this.getItemDescriptor(vm))
                    return !rps.object.isUndefined(this.getItemDescriptor(vm).EntityAmount)
                else
                    return true
            })) {
            this.descriptorWidth = descriptorSizeWidth.big;
        } else {
            this.descriptorWidth = descriptorSizeWidth.small;
        }

        super.createItems(dataItems);  
    }

    createItem(dataItem: any, element: JQuery) {
        return rps.app.uiFactory.createComponent<rpsDescriptorViewItem>(rpsDescriptorViewItem,this.dynamicContainer)
            .then((result: ComponentRef<rpsDescriptorViewItem>) => {
                (<rpsDescriptorViewItem>result.instance).setDescriptor(this.getItemDescriptor(dataItem), this.descriptorHeight, this.descriptorWidth);
                if (!rps.string.isNullOrEmpty(this.rpsLinkPropertyPath))
                    (<rpsDescriptorViewItem>result.instance).setLinkProperty((<any>dataItem)[this.rpsLinkPropertyPath]);
                element.append(result.location.nativeElement.children);

                return result;
            });
    }

    ngOnDestroy() {
        this.propChangedSubscriptions.forEach((propChangedSubscription) => {
            propChangedSubscription.unsubscribe();
        });        
        this.propChangedSubscriptions = null;

        super.ngOnDestroy();
    }
}

@Component({
    template: `
        <div class="rps-card">
            <div class="rps-card-padding">
                <div class="rps-card-border">
                    <div class="rps-card-left rps-img-container" 
                         *ngIf="descriptor?.Image" 
                         [rpsImageSize]="3">                    
                        <img class="rps-img" 
                             src="{{descriptor.Image}}" 
                             [attr.alt]="descriptor.MainDescriptor"/>                       
                    </div>
                    <div class="rps-card-content">
                        <a *ngIf="descriptor && linkRouterParams" [routerLink]="linkRouterParams" [queryParams]="linkRouterQueryParams">
                            <h3>{{descriptor.MainDescriptor}}</h3>
                            <h4>{{descriptor.SecondaryDescriptor}}</h4>
                        </a>
                        <div *ngIf="descriptor && !linkRouterParams">
                            <h3>{{descriptor.MainDescriptor}}</h3>
                            <h4>{{descriptor.SecondaryDescriptor}}</h4>
                        </div>
                        <div>
                            <div class="rps-stack-panel">
                                <span *ngIf="descriptor?.EntityState != undefined"
                                      [ngClass]="entityStateClass"
                                      class="rps-stack-panel-item">
                                    {{descriptor.EntityState.StateName}}
                                </span>
                                <h5 class="rps-stack-panel-item-right"
                                    *ngIf="descriptor?.EntityAmount != undefined">
                                    {{descriptor.EntityAmount.Unit}}
                                </h5>
                                <h5 class="rps-stack-panel-item-right"
                                    [rpsSemanticState]="descriptor?.EntityAmount?.StateInfo"
                                    *ngIf="descriptor?.EntityAmount != undefined">
                                    {{entityAmountString}}                               
                                </h5>
                            </div>
                            <h4 *ngIf="descriptor?.EntityDate != undefined" [rpsSemanticState]="descriptor?.EntityDate?.StateInfo">
                                {{descriptor.EntityDate.DateName}} {{entityDateString}}
                            </h4>
                            <h4 *ngIf="descriptor?.FirstAttribute != undefined"
                                class="rps-card-attribute">
                                {{descriptor.FirstAttribute}}
                            </h4>
                            <h4 *ngIf="descriptor?.SecondAttribute != undefined"
                                class="rps-card-attribute">
                                {{descriptor.SecondAttribute}}
                            </h4>
                        </div>
                    </div>
            
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class rpsNarrowDescriptorViewItem {
    public descriptor: rps.data.FullDescriptor;
    public entityStateClass: string;
    public linkRouterParams: Array<any>;
    public linkRouterQueryParams: rps.IParams;
    public entityDateString: string;
    public entityAmountString: string;
    constructor(private _cdr: ChangeDetectorRef) {        
    }

    setDescriptor = (newValue: rps.data.FullDescriptor) => {
        this.descriptor = newValue;
        if (this.descriptor && this.descriptor.EntityDate)
            this.entityDateString = kendo.toString(this.descriptor.EntityDate.Date, rps.utils.getPatternDate());
        if (this.descriptor && this.descriptor.EntityAmount)
            this.entityAmountString = kendo.toString(this.descriptor.EntityAmount.Amount, rps.utils.getDecimalFormat());
        if (this.descriptor && this.descriptor.EntityState) {
            switch (this.descriptor.EntityState.StateInfo) {
                case rps.data.SemanticState.Negative:
                    this.entityStateClass = "label label-danger";
                    break;
                case rps.data.SemanticState.Neutral:
                    this.entityStateClass = "label label-default";
                    break;
                case rps.data.SemanticState.Positive:
                    this.entityStateClass = "label label-success";
                    break;
                case rps.data.SemanticState.Warning:
                    this.entityStateClass = "label label-warning";
                    break;
                case rps.data.SemanticState.Info:
                    this.entityStateClass = "label label-info";
                    break;
                default:
                    this.entityStateClass = "label";
            }
        }
        else {
            this.entityStateClass = "";
        }
        this._cdr.markForCheck();
    }

    setLinkProperty(newValue: rps.viewmodels.properties.ILinkProperty) {
        var params = newValue.getRouterLinkParameters();
        this.linkRouterParams = params.routerLink;
        this.linkRouterQueryParams = params.queryParams;
    }
}

@Component({
    selector: 'rps-descriptor-view',
    template: `
        <div>
            <div [hidden]="!loaded">
                <div id="kendoListView">
                </div>
            </div>
            <div *ngIf="!loaded" 
                    class="rps-unloaded-query">
                {{resources.directives.UNLOADED_QUERY}}
            </div>
            <div #dynamicContainer>
            </div>
        </div>
    `,
    inputs: ['rpsSource', 'rpsSelectionMode', 'rpsLinkPropertyPath', 'rpsDescriptorPropertyPath']
})
export class rpsNarrowDescriptorView extends rpsCardViewBase implements OnDestroy {
    @ViewChild('dynamicContainer', { read: ViewContainerRef })
    private dynamicContainer: ViewContainerRef;
    private propChangedSubscriptions: Array<rps.services.ISubscription> = new Array<rps.services.ISubscription>();

    rpsLinkPropertyPath: string;
    //Se hace un Split para agilizar la búsqueda en cada uno de los ítems y no hacerlo por cada uno de ellos
    private descriptorPropertyPaths: Array<string>;
    set rpsDescriptorPropertyPath(newValue: string) {
        if (rps.string.isNullOrEmpty(newValue))
            this.descriptorPropertyPaths = null;
        else
            this.descriptorPropertyPaths = newValue.split(".");
    }

    constructor(elementRef: ElementRef, queryToolbarItem: rpsItemsControlToolbarItem) {
        super(elementRef, queryToolbarItem);        
    }

    private getItemDescriptor(dataItem: any): rps.data.FullDescriptor {
        //Si la longitud es de uno, significa, que es una vpProperty del VM, si es de 2, es porque es una propiedad del modelo                
        if (this.descriptorPropertyPaths.length == 1) {
            var vmProperty: rps.viewmodels.properties.VMProperty<rps.data.FullDescriptor> = (<any>dataItem)[this.descriptorPropertyPaths[0]];
            return vmProperty.value;
        } else {
            return (<any>dataItem)[this.descriptorPropertyPaths[0]][this.descriptorPropertyPaths[1]];
        }
    }

    createItem(dataItem: any, element: JQuery) {
        return rps.app.uiFactory.createComponent<rpsNarrowDescriptorViewItem>(rpsNarrowDescriptorViewItem, this.dynamicContainer)
            .then((result: ComponentRef<rpsNarrowDescriptorViewItem>) => {
                (<rpsNarrowDescriptorViewItem>result.instance).setDescriptor(this.getItemDescriptor(dataItem));
                if (!rps.string.isNullOrEmpty(this.rpsLinkPropertyPath))
                    (<rpsNarrowDescriptorViewItem>result.instance).setLinkProperty((<any>dataItem)[this.rpsLinkPropertyPath]);
                element.append(result.location.nativeElement.children);

                return result;
            });
    }

    ngOnDestroy() {
        this.propChangedSubscriptions.forEach((propChangedSubscription) => {
            propChangedSubscription.unsubscribe();
        });
        this.propChangedSubscriptions = null;

        super.ngOnDestroy();
    }
}