import { Component, Optional, ElementRef, forwardRef, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsFocusableEditor, rpsEditor} from './editorBase'
import {errorDetailValidator} from '../utils/errorDetailValidator'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'
import { rpsControl } from '../controlBase'

@Component({
    selector: 'rps-email-editor',
    template: `
        <div class="rps-editor" 
             [rpsSemanticState]="rpsModel?.semanticState" 
             [class.rps-disabled-editor]="disabled"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label" 
                   [attr.for] = "myId" >
                {{rpsLabel}}<font *ngIf="isRequired" color= "red" > *</font>
            </label>
            <div *ngIf="!isEditing && !disabled">
                <div class="rps-editor-editor rps-editor-viewer rps-semantic-state" tabindex= "0" (focus)="isEditing = true">
                    <div class="rps-editor-link">
                        <a tabindex="-1" [attr.href]="getHref" >{{value}}</a>
                    </div>                  
                </div>
            </div>
            <div *ngIf="isEditing && !disabled">
                <input class="k-textbox rps-editor-editor rps-semantic-state"
                    type= "text" 
                    [attr.id] = "myId"
                    [disabled] = "disabled"
                    [ngModel] = "value"
                    (focus)="selectContent()"
                    (blur) = "focusout(); removeInterval()"
                    (keypress) = "keyDownPress($event)"
                    (ngModelChange) = "nextValue = $event" />
            </div>
            <div *ngIf="disabled" class="rps-editor-editor rps-read-only-editor-viewer rps-read-only-semantic-state">
                <a tabindex="-1" [attr.href]="getHref">{{value}}</a>
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsEmailEditor) }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsEditor.getComponentInputs()
})
export class rpsEmailEditor extends rpsFocusableEditor<string>  {

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    private nextValue: string;

    private get getHref(): string {
        return "mailto:" + this.value;
    }

    private focusout() {
        this.setVMPropertyValue();
        this.isEditing = false;
    }

    private setVMPropertyValue() {
        this.updateSourceValue(this.nextValue);
    }

    protected updateTargetValue(newValue: string) {
        this.nextValue = newValue;
    }

    private keyDownPress(event) {
        if (event.keyCode == 13) {
            this.setVMPropertyValue();
            event.preventDefault();
        }
    }

    createEditableTargetControl() {
        setTimeout(() => {
            var input = $(this.elementRef.nativeElement).find("input").focus();

            //Cuando el editor está dentro de la grid, se está forzando el “isInEditMode”, por lo que se quita después del retardo
            if (rps.ui.grid.isInEditMode)
                rps.ui.grid.isInEditMode = false;
        });
    }

    selectContent() {
        rps.utils.selectInputContent($(this.element.find(".k-textbox").first()), 1);
    }

    removeInterval() {
        rps.utils.selectInputContent($(this.element.find(".k-textbox").first()), 0);
    }

    //Método que invoca la grid, para forzar que el editor recoja el foco
    setFocusAfterInit() {
        rps.ui.grid.isInEditMode = true;
        this.isEditing = true;
    }
}
