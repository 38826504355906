
import {Injectable, Inject,Injector,Component} from '@angular/core';
import {Http, Response, Headers, URLSearchParams} from '@angular/http';
import {Observable} from 'rxjs';
import 'rxjs/Rx';

import {rpsSession} from 'rps/src/services/session';
import {rpsErrorManager} from 'rps/src/services/errorManager';
import {rpsEntityFactory} from 'rps/src/services/entityFactory';
import {rpsProcessResultManager} from 'rps/src/services/processResultManager';
import {rpsBusyIndicatorManager} from 'rps/src/services/busyIndicatorManager';
import {rpsNotificationManager} from 'rps/src/services/notificationManager';
import {rpsAPI} from 'rps/src/services/api';
import {rpsClientAPI} from 'rps/src/services/clientAPI';
import {rpsAPIRef} from 'rps/src/services/apiRef';
import {rpsResourceManager} from 'rps/src/services/resourceManager';
import {rpsAppSettings} from 'rps/src/services/appSettings';
import {rpsMessageManager} from 'rps/src/services/messageManager';
import {rpsUIFactory} from 'rps/src/services/uiFactory';
import {rpsViewModelFactory} from 'rps/src/services/viewModelFactory';
import {rpsStateManager} from 'rps/src/services/stateManager';
import {rpsEventManager} from 'rps/src/services/eventManager';
import {rpsViewFactory} from 'rps/src/services/viewFactory';
import {rpsEntityManager} from 'rps/src/services/entityManager';
import {rpsCurrencyResolver} from 'rps/src/services/currencyResolver';
import {rpsQuantityUnitResolver, rpsPriceUnitResolver} from 'rps/src/services/unitResolver';
import {rpsDurationResolver} from 'rps/src/services/durationResolver';
import {rpsLocalStorage,rpsSessionStorage} from 'rps/src/services/localStorage';
import { rpsUserInputManager } from 'rps/src/services/userInputManager';



@Injectable()
export class startup {
    constructor(
        _http: Http,
        _rpsStateManager: rpsStateManager,
        _rpsViewModelFactory: rpsViewModelFactory,
        _rpsUIFactory: rpsUIFactory,
        _rpsMessageManager: rpsMessageManager,
        _rpsAppSettings: rpsAppSettings,
        _rpsResourceManager: rpsResourceManager,
        _rpsAPI: rpsAPI,
        _rpsClientAPI: rpsClientAPI,
        _rpsAPIRef: rpsAPIRef,
        _rpsNotificationManager: rpsNotificationManager,
        _rpsEntityFactory: rpsEntityFactory,
        _rpsErrorManager: rpsErrorManager,
        _rpsEventManager: rpsEventManager,
        _rpsViewFactory: rpsViewFactory,
        _rpsSession: rpsSession,
        _rpsProcessResultManager: rpsProcessResultManager,
        _rpsBusyIndicatorManager: rpsBusyIndicatorManager,
        _rpsEntityManager: rpsEntityManager,
        _rpsCurrencyResolver: rpsCurrencyResolver,
        _rpsQuantityUnitResolver: rpsQuantityUnitResolver,
        _rpsPriceUnitResolver: rpsPriceUnitResolver,
        _rpsDurationResolver: rpsDurationResolver,
        _rpsLocalStorage: rpsLocalStorage,
        _rpsSessionStorage: rpsSessionStorage,
        _rpsUserInputManager: rpsUserInputManager)
    {

        _rpsAppSettings.configure();
        _rpsEventManager.configure();
        _rpsEntityFactory.configure();
        _rpsStateManager.configure();
        _rpsSession.configure();
        _rpsViewModelFactory.configure();
        _rpsAPI.configure();
        _rpsClientAPI.configure();
        _rpsAPIRef.configure();
        _rpsMessageManager.configure();
        _rpsErrorManager.configure();
        _rpsNotificationManager.configure();
        _rpsUIFactory.configure();
        _rpsResourceManager.configure();
        _rpsViewFactory.configure();
        _rpsEntityManager.configure();
        _rpsProcessResultManager.configure();
        _rpsBusyIndicatorManager.configure();
        _rpsCurrencyResolver.configure();
        _rpsQuantityUnitResolver.configure();
        _rpsPriceUnitResolver.configure();
        _rpsDurationResolver.configure();
        _rpsSessionStorage.configure();
        _rpsLocalStorage.configure();
        _rpsUserInputManager.configure();

        rps.app.rpsComponent = Component;

        //Mirar si tiene alguna redirección del servidor
        rps.app.stateManager.saveRedirectPath();
    }
}

