import {Injectable, Inject, Injector, ApplicationRef, ComponentFactoryResolver,NgModule,
    Component, Type, OpaqueToken, OnDestroy, ComponentRef,ComponentFactory, ViewContainerRef, ViewChild,
    AfterViewInit} from '@angular/core';

import {JitCompiler} from '@angular/compiler';
import { rpsModule,rpsDesktopModule } from '../directives/modules';
import { FormsModule }   from '@angular/forms';
import {CommonModule} from '@angular/common';
import { RouterModule } from '@angular/router';


@Injectable()
export class rpsUIFactory implements rps.services.IUIFactory {

    /** @internal **/
    private windowContainer: ViewContainerRef;

    /** @rpsInternal **/
    configure = () => {
        rps.app.uiFactory = this;
    }

    /** @internal **/
    constructor(private app: ApplicationRef, private factoryResolver: ComponentFactoryResolver, private compiler: JitCompiler) {
    }

    /** @rpsInternal **/
    setWindowContainer(windowContainer: ViewContainerRef) {
        this.windowContainer = windowContainer;
    }

    showWindow(params: {
        viewModel: rps.viewmodels.IWindowVM;
        templateUrl?: string;
        template?: string;        
        preventCloseOnNavigate?: boolean;
    }) {
        return new Promise<any>((resolve) => {
            let templatePromise: Promise<string>;
            const activeElement = document.activeElement;

            if (params.template)
                templatePromise = Promise.resolve(params.template);
            else
                templatePromise = rps.app.api.getHTML({
                    templateUrl: params.templateUrl,
                    urlType: rps.services.UrlType.Relative
                });
            templatePromise.then((result) => {
                var navigationStartedSubscription: rps.services.ISubscription;
                if (!params.preventCloseOnNavigate) {
                    //Si se navega a otra pantalla por medio de un link o algo similar, cerramos la ventana emergente
                    navigationStartedSubscription = rps.app.stateManager.navigationStarted.subscribe(() => {
                        params.viewModel.defaultCloseCommand.execute();
                    });
                }
                this.createComponentRefFromTemplate<any>(result, params.viewModel, this.windowContainer).then(
                    (component: ComponentRef<any>) => {
                        params.viewModel.isOpen = true;
                        //HACK: Si contiene este método, se le invoca, para modificar el DOM en el VM, rompiendo el patrón...
                        if ((<any>params.viewModel).afterContentInit)
                            (<any>params.viewModel).afterContentInit(component);
                        params.viewModel.isOpenChanged.subscribe((newValue: boolean) => {
                            if (navigationStartedSubscription)
                                navigationStartedSubscription.unsubscribe();
                            resolve(params.viewModel);
                            component.destroy();

                            setTimeout(() => {
                                if (activeElement)
                                    (<any>activeElement).focus();
                            });
                        });
                    });
            });
        });
    }

    /** @internal **/
    private componentProxyFactory(templateContent: string): Type<any> {
        @Component({            
            template: templateContent,
            selector: "rps-virtual-component"
        })
        class VirtualComponent implements OnDestroy, AfterViewInit {

            public _vm: any;
            get vm(): any {
                return this._vm;
            }
            set vm(value: any) {
                this._vm = value;
            }

            //HACK: Si el HTML contiene un elemento llamado "dynamicContainer", se obtiene para invocar el afterViewInit y añadir la posibilidad de crear componentes en el DOM
            @ViewChild('dynamicContainer', { read: ViewContainerRef })
            dynamicContainer: ViewContainerRef = null;
            ngAfterViewInit() {
                if (this.dynamicContainer && (<any>this.vm).afterViewInit)
                    (<any>this.vm).afterViewInit(this.dynamicContainer);
            }

            public get $root() {
                return rps.app;
            }

            public get resources() {
                return rps.app.resources;
            }

            public ngOnDestroy() {
            }

        }

        @NgModule({
            imports: [                
                rpsModule,rpsDesktopModule, FormsModule, CommonModule, RouterModule
            ],
            declarations: [
                VirtualComponent
            ]
        })
        class VirtualModule {
        }
        // a module for just this Type
        return VirtualModule;
    }

    /** @internal **/
    public createComponent<T>(componentType: Type<T>, container: ViewContainerRef): Promise<ComponentRef<T>> {
        var factory = this.factoryResolver.resolveComponentFactory(componentType);
        return Promise.resolve(container.createComponent(factory));
    }

    /** @internal **/
    public createComponentRefFromUrl<T>(url: string, viewModel: any, container: ViewContainerRef): Promise<ComponentRef<T>> {
        return rps.app.apiRef.getFile(url).then((result) => {
            return this.createComponentRefFromTemplate<any>(result, viewModel, container);
        });
    }

    /** @internal **/
    public createComponentFactoryFromUrl<T>(url: string): Promise<ComponentFactory<T>> {
        return rps.app.apiRef.getFile(url).then((result) => {
            return this.createComponentFactoryFromTemplate<any>(result);
        });
    }

    /** @internal **/
    public createComponentRefFromTemplate<T>(template: string, viewModel: any, container: ViewContainerRef): Promise<ComponentRef<T>>{
        return this.createComponentFactoryFromTemplate(template).then((virtualFactory) => {            
            return this.createComponentRefFromFactory(virtualFactory,viewModel,container);
        });
    }

    /** @internal **/
    public createComponentFactoryFromTemplate<T>(template: string): Promise<ComponentFactory<T>> {
        return new Promise((resolve) => {
            var module = this.componentProxyFactory(template);

            this.compiler.compileModuleAndAllComponentsAsync(module).then((moduleWithFactories) => {

                var virtualFactory:ComponentFactory<T> = Enumerable.From(moduleWithFactories.componentFactories).First(
                    (fac) => fac.selector == "rps-virtual-component");
                resolve(virtualFactory);
            });
        });
    }

    /** @internal **/
    public createComponentRefFromFactory<T>(componentFactory: ComponentFactory<T>, viewModel: any, container: ViewContainerRef): Promise<ComponentRef<T>> {
        var component: ComponentRef<T> = componentFactory.create(container.injector);               
        component.instance["vm"] = viewModel;
        container.insert(component.hostView);
        return Promise.resolve<ComponentRef<T>>(component);
    }
}
