import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';

export enum serviceStatus {
    Connecting = 1,
    Connected = 2,
    Reconnecting = 3,
    Disconnected = 4
}

export interface IJobProgress {
    IDJob: string;
    ProgressLabel: string;
    ProgressPercent: number;
}

@Injectable()
export class rpsHubsService {    

    private notificationsHub: any;
    private serviceJobHub: any;
    private pendingNotificationSubscriptions: Array<string> = new Array<string>();
    private pendingJobSubscriptions: Array<string> = new Array<string>();

    public onNewNotification: EventEmitter<any> = new EventEmitter<any>();
    public onNotificationRemoved: EventEmitter<string> = new EventEmitter<string>();

    public onJobProgress: EventEmitter<IJobProgress> = new EventEmitter<IJobProgress>();
    public onJobCompleted: EventEmitter<string> = new EventEmitter<string>();

    public onReconnected: EventEmitter<any> = new EventEmitter<any>();


    constructor() {

        this.setupNotificationsHub();

        this.setupServiceJobHub();

        this.startConnection();

        $.connection.hub.disconnected(() => {
            setTimeout(() => {
                this.startConnection();
            }, 5000); // Restart connection after 5 seconds.
        });

        $.connection.hub.reconnected(() => {
            this.onReconnected.emit();
        });
    }

    private setupNotificationsHub(): void {
        this.notificationsHub = $.connection["notificationsHub"];

        this.notificationsHub.client.sendNotification = (notification) => {
            this.onNewNotification.emit({ descriptor: notification });
        };

        this.notificationsHub.client.notificationRemoved = (idNotification: string) => {
            this.onNotificationRemoved.emit(idNotification);
        }
    }

    private setupServiceJobHub(): void {
        this.serviceJobHub = $.connection["serviceJobHub"];

        this.serviceJobHub.client.jobProgress = (jobProgress: IJobProgress) => {
            this.onJobProgress.emit(jobProgress);
        }

        this.serviceJobHub.client.jobCompleted = (idJob: string) => {
            this.onJobCompleted.emit(idJob);
        }
    }

    private startConnection(): void {
        // Start the connection y conectar a los que estaban pendientes
        $.connection.hub.start().done(() => {
            this.pendingNotificationSubscriptions.forEach((sub) => {
                this.notificationsHub.server.subscribe(sub);
            });
            this.pendingNotificationSubscriptions.length = 0;

            this.pendingJobSubscriptions.forEach((sub) => {
                this.serviceJobHub.server.subscribe(sub);
            });
            this.pendingJobSubscriptions.length = 0;
        });
    }

    public subscribeToNotifications(codUser: string): void {
        if (this.notificationsHub && this.notificationsHub.connection.state == SignalR.ConnectionState.Connected &&
            this.notificationsHub.server) {
            this.notificationsHub.server.subscribe(codUser);
        }
        else
            this.pendingNotificationSubscriptions.push(codUser);
    }

    public unsubscribeFromNotifications(codUser: string): void {
        if (this.notificationsHub && this.notificationsHub.connection.state == SignalR.ConnectionState.Connected &&
            this.notificationsHub.server) {
            this.notificationsHub.server.unsubscribe(codUser);
        }
    }

    public subscribeToServiceJob(idJob: string): void {
        if (this.serviceJobHub && this.serviceJobHub.connection.state == SignalR.ConnectionState.Connected &&
            this.serviceJobHub.server) {
            this.serviceJobHub.server.subscribe(idJob);
        }
        else
            this.pendingJobSubscriptions.push(idJob);
    }

    public unsubscribeFromServiceJob(idJob: string): void {
        if (this.serviceJobHub && this.serviceJobHub.connection.state == SignalR.ConnectionState.Connected &&
            this.serviceJobHub.server) {
            this.serviceJobHub.server.unsubscribe(idJob);
        }
    }
}