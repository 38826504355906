import { Component, ElementRef, forwardRef, Optional, SimpleChange, ComponentRef, ViewChild, ViewContainerRef, ChangeDetectorRef} from '@angular/core';
import {rpsEditor} from './editorBase'
import {errorDetailValidator} from '../utils/errorDetailValidator'
import {rpsRowService} from '../layouts/row'
import {rpsButton} from './button'
import {rpsLabel} from '../readOnlyEditors/label';
import { rpsControl } from '../controlBase'
import {rpsSemanticState} from '../utils/semanticState'

@Component({
    template: `
        <div>
            <div class="rps-stack-panel-item" 
                 *ngIf="rpsMultiLookup.rpsModel.hasAnyEntityView || rpsMultiLookup.allowContextualSearch">
                <rps-button rpsTemplate="K-ICON" 
                            rpsIcon="search" 
                            [rpsModel]="rpsMultiLookup.searchCommand">
                </rps-button>
            </div>
            <div class="rps-stack-panel-item" 
                 *ngIf="rpsMultiLookup.rpsModel.allowAddNew">
                <rps-button rpsTemplate="K-ICON" 
                            rpsIcon="plus" 
                            [rpsModel]="rpsMultiLookup.addNewCommand">
                </rps-button>
            </div>
            <div class="rps-stack-panel-item-right">
                <rps-label [rpsModel]="rpsMultiLookup.countMessage">
                </rps-label>
            </div>
        </div>
    `
})
export class rpsMultiLookupFooter {
    rpsMultiLookup: rpsMultiLookup;
    constructor(public cdr: ChangeDetectorRef) {

    }

    setRPSLookup(rpsMultiLookup: rpsMultiLookup) {
        this.rpsMultiLookup = rpsMultiLookup;
    }
}

@Component({
    selector: 'rps-multi-lookup',
    template: `
        <div class="rps-editor" 
             [rpsSemanticState]="rpsModel.semanticState" 
             [class.rps-disabled-editor]="disabled"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label"
                   [attr.for]="myId">
                {{rpsLabel}}
            </label>
            <div class="kendoContainer">
                <select class="rps-editor-editor rps-semantic-state" 
                        [attr.id]="myId">
                </select>
            </div>         
        </div>
        <div #dynamicContainer>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsMultiLookup) }],
    inputs: rpsEditor.getComponentInputs(['rpsDataSource'])
})
export class rpsMultiLookup extends rpsEditor<Array<string>> {
    @ViewChild('dynamicContainer', { read: ViewContainerRef })
    private dynamicContainer: ViewContainerRef;

    private kendoMultiSelect: kendo.ui.MultiSelect;
    private kendoSourceHTML: string;
    private loadedItems: Array<any> = new Array<any>();
    private itemValuePath: string = 'ID';
    private itemTextPath: string = 'MainField';
    private countMessage: string = rps.app.resources.directives.NO_ITEMS_TO_DISPLAY;
    private allowContextualSearch: boolean = false;
    private static itemTemplate: string = `
        <div id="template">
            # if (data.ImageField) {#
            <div class="rps-img-2 rps-lookup-popup-image"><img class="rps-img" src="#: data.ImageField #"/></div>
            #}#
            # if (data.SecondaryField ) {#
            <div class="rps-lookup-popup-item-text">#: data.MainField # (#: data.SecondaryField #)</div>
            #} else {#
            <div class="rps-lookup-popup-item-text">#: data.MainField #</div>
            #}#
        </div>
    `;
    private static tagTemplate:string= `
        <div id="template">
            # if (data.ImageField) {#
            <div class="rps-img-138 rps-lookup-popup-image"><img class="rps-img" src="#: data.ImageField #"/></div>
            #}#
            # if (data.SecondaryField ) {#
            <div class="rps-multi-lookup-popup-item-text">#: data.MainField # (#: data.SecondaryField #)</div>
            #} else {#
            <div class="rps-multi-lookup-popup-item-text">#: data.MainField #</div>
            #}#
        </div>
    `;
    private footerTemplate: string = `
        <div id="[id]" class="rps-stack-panel">
        </div>
    `;
    //Propiedad que indica que el origen de datos ya está ligado con el widget
    private bound: boolean = false;
    private multiLookupFooterComponentRef: ComponentRef<rpsMultiLookupFooter>;

    public rpsDataSource: rps.data.sources.LookupSource;

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    createTargetControl() {
        //Busca inputContainer (el div que contiene el input)
        var div = this.element.find(".kendoContainer").first();

        //Se queda con una copia del HTML del input, por si necesita crearlo de nuevo (en caso de que cambien las opciones, por ejemplo)
        if (!this.kendoSourceHTML)
            this.kendoSourceHTML = div.html();

        //Si el control estaba creado, destruirlo y vaciarlo
        if (this.kendoMultiSelect) {
            this.kendoMultiSelect.destroy();
            div.empty();
            //Volver a crear un input para que pueda llamar al método de Telerik para crear el editor concreto
            div.append(this.kendoSourceHTML);
        }

        //Crear el editor
        //El widget kendoMultiLookupMultiSelect es un widget que extiende el MultiSelect, pero con un pie, por lo que las opciones son las mismas.
        var options: kendo.ui.MultiSelectOptions = {
            autoBind: false,
            autoClose: false,
            dataTextField: this.itemTextPath,
            dataValueField: this.itemValuePath,
            change: (e: kendo.ui.MultiSelectChangeEvent) => { this.change(e) },
            itemTemplate: rpsMultiLookup.itemTemplate,
            tagTemplate: rpsMultiLookup.tagTemplate,
            clearButton: false
        };
        var footerID: string = "footer-" + this.myId;
        options['footerTemplate'] = this.footerTemplate.replace("[id]", footerID);
        this.kendoMultiSelect = (<any>this.element.find("select").first())
            .kendoMultiLookupMultiSelect(options)
            .data("kendoMultiLookupMultiSelect");
        this.refreshKendoMultiSelectDataSource(false);

        rps.app.uiFactory.createComponent<rpsMultiLookupFooter>(rpsMultiLookupFooter, this.dynamicContainer)
            .then((result: ComponentRef<rpsMultiLookupFooter>) => {       
                this.multiLookupFooterComponentRef = result;         
                result.instance.setRPSLookup(this);
                var footer = $(document).find("#" + footerID);
                if (footer.length > 0)
                    footer.append(result.location.nativeElement);
            });
    }

    destroyTargetControl() {
        if (this.kendoMultiSelect) {
            this.kendoMultiSelect.destroy();
            this.kendoMultiSelect = null;
        }

        if (this.multiLookupFooterComponentRef) {
            this.multiLookupFooterComponentRef.destroy();
            this.rpsDataSource = null;
        }
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        super.ngOnChanges(changes);
        if (changes["rpsDataSource"]) {
            if (this.rpsDataSource)
                this.allowContextualSearch = (this.rpsDataSource.hasColumnsToRender || this.rpsDataSource.hasDescriptorField)
            else
                this.allowContextualSearch = false;
            this.refreshKendoMultiSelectDataSource(true);
            if (this.rpsDataSource) {
                this.rpsDataSource.attachToParamsChanged(() => {
                    this.refreshKendoMultiSelectDataSource(true);
                });
            }
        }
    }

    private refreshKendoMultiSelectDataSource(clearModelValue : boolean) {
        if (this.kendoMultiSelect) {
            //Cuando se refresca el datasource, limpiar también posibles valores que haya tenido seleccionados, ya que 
            //puede ser que no estén en la nueva lista de valores
            if (this.rpsModel.value && clearModelValue)
                this.rpsModel.value = [];

            this.kendoMultiSelect.setDataSource(<any>this.getMatches());
        }
    }

    private getMatches(): kendo.data.DataSourceOptions {
        return {
            serverFiltering: true,
            transport: {
                read: this.read
            }
        };
    }

    read = (options: kendo.data.DataSourceTransportReadOptions) => {
        this.bound = true;
        var filter: string;
        if (this.rpsDataSource) {
            if (options.data.filter && options.data.filter.filters.length == 1)
                filter = (<any>options.data.filter.filters[0]).value;
            this.rpsDataSource.getMatches({
                filter: filter,
                page: 1,
                pageSize:24
            }).then((result) => {
                this.addToLoadedDescriptors(result.items);
                this.addDescriptors(this.value, result.items).then(() => {
                    if (result.items.length > 0)
                        this.countMessage = rps.string.format(rps.app.resources.directives.ITEMS_COUNT, result.items.length, result.count);
                    else
                        this.countMessage = rps.app.resources.directives.NO_ITEMS_TO_DISPLAY;
                    if (this.multiLookupFooterComponentRef)
                        this.multiLookupFooterComponentRef.instance.cdr.markForCheck();
                    options.success(result.items);
                });
            }).catch(() => {
                this.countMessage = rps.app.resources.directives.NO_ITEMS_TO_DISPLAY;
                if (this.multiLookupFooterComponentRef)
                    this.multiLookupFooterComponentRef.instance.cdr.markForCheck();
                options.success([]);
            });
        }
        else {
            this.countMessage = rps.app.resources.directives.NO_ITEMS_TO_DISPLAY;
            if (this.multiLookupFooterComponentRef)
                this.multiLookupFooterComponentRef.instance.cdr.markForCheck();
            options.success([]);
        }
    }

    updateTargetValue(newValue: Array<string>) {
        if (!this.equals(newValue, this.kendoMultiSelect.value())) {
            if (this.kendoMultiSelect.dataSource) {
                this.addDescriptors(newValue, this.kendoMultiSelect.dataSource.data()).then(() => {
                    this.kendoMultiSelect.value(newValue);
                    //HACK: Si el widget todavía no está ligado a la lista, se desliga a mano, ya que al añadir un elemento a mano se marca como ligado…
                    if (!this.bound)
                        (<any>this.kendoMultiSelect).listView.bound(false)
                });
            }
            else {
                this.kendoMultiSelect.value(newValue);
            }
        }
    }

    enableControl() {
        this.kendoMultiSelect.enable(true);
    }

    disableControl() {
        this.kendoMultiSelect.enable(false);
    }

    change(e: kendo.ui.MultiSelectChangeEvent) {
        this.updateSourceValue(this.kendoMultiSelect.value());
    }

    private equals(val1: Array<string>, val2: Array<string>) {
        if (!rps.object.isNullOrUndefined(val1) && !rps.object.isNullOrUndefined(val2)) {
            if (val1.length != val2.length)
                return false;
            else {
                for (var i: number = 0; i < val1.length; i++) {
                    if (val1[i] != val2[i])
                        return false;
                }
                return true;
            }
        }
        else if ((!rps.object.isNullOrUndefined(val1) && rps.object.isNullOrUndefined(val2)) ||
            (rps.object.isNullOrUndefined(val1) && !rps.object.isNullOrUndefined(val2)))
            return false;
        else
            return true;
    }

    private addDescriptors(ids: Array<string>, data: kendo.data.ObservableArray): Promise<any> {
        if (!rps.object.isNullOrUndefined(ids) && ids.length > 0 && !rps.object.isNullOrUndefined(ids)) {
            var dataIDsEnumerable: linq.Enumerable<string> = Enumerable.From<any>(<any>data).Select(r => r[this.itemValuePath]);
            var idsToAddEnumerable: Array<string> = Enumerable.From<string>(ids).Where(v => !dataIDsEnumerable.Contains(v)).ToArray();

            var loadedItemsEnumerable = Enumerable.From<any>(this.loadedItems);
            var newIDs: Array<string> = Enumerable.From<string>(idsToAddEnumerable).Where(id => !loadedItemsEnumerable.Any(ld => ld[this.itemValuePath] == id)).ToArray();
            return this.rpsDataSource.getItems(newIDs).then((items: Array<any>) => {
                items.forEach((item: rps.data.FullDescriptor) => {
                    this.loadedItems.push(item);
                });
                loadedItemsEnumerable = Enumerable.From<any>(this.loadedItems);
                idsToAddEnumerable.forEach((id) => {
                    data.push(loadedItemsEnumerable.First(ld => ld[this.itemValuePath] == id));
                });
            }).catch((error) => {
                console.error(error);
                this.updateSourceValue([]);                
            });
        }
        else {
            return Promise.resolve<any>(this);
        }
    }

    private addToLoadedDescriptors(data: kendo.data.ObservableArray) {
        if (data) {
            var loadedDescriptorsEnumerable = Enumerable.From<any>(this.loadedItems);
            data.forEach((d) => {
                if (!loadedDescriptorsEnumerable.Any(ld => ld[this.itemValuePath] == d[this.itemValuePath])) {
                    this.loadedItems.push(d);
                }
            });
        }
    }

    searchCommand: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: this.search
    });
    search(): Promise<any> {
        (<rps.viewmodels.properties.MultiLookupProperty>this.rpsModel).search(this.rpsDataSource);
        return Promise.resolve(this);
    }

    addNewCommand: rps.viewmodels.commands.CommandProperty = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: this.addNew
    });
    addNew(): Promise<any> {
        rps.app.stateManager.navigateToEntity((<rps.viewmodels.properties.MultiLookupProperty>this.rpsModel).entityName, rps.viewmodels.EntityVM.NEW_ID_VALUE, true);
        return Promise.resolve(this);
    }
}
