import {Http, Response, Headers, URLSearchParams} from '@angular/http';
import {Injectable, Inject} from '@angular/core';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import {rpsErrorManager} from './errorManager';
import {rpsAppSettings} from './appSettings';
import {rpsSessionStorage} from './localStorage';
import { Router, Route, ActivatedRoute, ActivatedRouteSnapshot,NavigationStart,NavigationEnd,NavigationError, NavigationCancel} from '@angular/router';
import {Location} from '@angular/common';
import {ComponentProvider,BaseComponent,} from '../components/components';



export class stateStack implements rps.services.IStateStack{
    public stackChanged: rps.services.IEventEmitter<any>;
    private items: Array<rps.services.IBaseComponent>;

    constructor() {
        this.items = new Array<rps.services.IBaseComponent>();
        this.stackChanged = rps.app.eventManager.createEmitter(false);
    }

    public find(predicate: (value: rps.services.IBaseComponent) => boolean): rps.services.IBaseComponent {
        try {
             return this.items.find(predicate);
        }
        catch(e){
            return null;
        }
    }

    public add(item: rps.services.IBaseComponent) {
        this.items.push(item);
        this.stackChanged.emit(item);
    }

    public removeLast(): rps.services.IBaseComponent{
        if (this.length == 0) 
            return null;
        var state = this.items.pop();
        this.stackChanged.emit(state);
        return state;
    }

    public tryRemove(component: rps.services.IBaseComponent): rps.services.IBaseComponent {
        var removeIndex = this.items.indexOf(component);
        if (removeIndex >= 0) {
            var removedState = this.items.splice(removeIndex,1)[0];
            this.stackChanged.emit(removedState);
            return removedState;
        }

        return null;
    }

    public clear() {
        this.items.length = 0;
        this.stackChanged.emit(null);
    }

    public get current(): rps.services.IBaseComponent {
        if (this.items.length > 0)
            return this.items[this.items.length - 1];
        else
            return null;
    }

    public at(i: number): rps.services.IBaseComponent {
        return this.items[i];
    }

    public get length(): number {
        return this.items.length;
    }

    public getCurrentStack(url:string): rps.services.IHistoryState  {
        var ret = { id: rps.guid.newGuid(), URL: url, stateStack:[] };
        this.items.forEach((item) => {
            ret.stateStack.push({
                stateName: item.configOptions.stateAbsoluteName,
                vmId: (item.vm ? item.vm.uid : null)
            });
        });
        return ret;
    }
}

export class VMCache implements rps.services.IVMCache{
    private items: Array<{ id: string, URL: string, entryDate: Date, stateStack: Array<{ stateName: string, vm: rps.viewmodels.BaseVM }>}>;
    private lastGetTime: Date;
    private max_cache_items = 30; //numero máximo de viewmodel distintos que mantiene cacheados

    constructor() {
        this.items = [];
    }

    public replaceLastEntry(entry: rps.services.IHistoryState) {
        //Mirar si puedo cambiar la última entrada de items
        var lastItem = this.items[this.items.length - 1];
        if (lastItem.URL == entry.URL) {
            lastItem.id = entry.id;
            lastItem.entryDate = new Date();
            lastItem.stateStack.length = 0;
            entry.stateStack.forEach((s) => {
                let vm = null;
                for (var i = 0; i < rps.app.stateManager.stateStack.length; i++) {
                    if (rps.app.stateManager.stateStack.at(i).vm && rps.app.stateManager.stateStack.at(i).vm.uid == s.vmId)
                        vm = rps.app.stateManager.stateStack.at(i).vm;
                }
                lastItem.stateStack.push({ stateName: s.stateName, vm: vm });
            })

        }
    }

    public add(entry: { id: string, URL: string, stateStack: Array<{ stateName: string, vmId: string }> }, deleteItemsAfterLastGet:boolean): void{
        if (entry.id && entry.stateStack) {
            //Borrar aquellas entradas posteriores al lastGetTime si viene de una navegación sin pop
            if (deleteItemsAfterLastGet && this.lastGetTime) {
                for (var i = this.items.length - 1; i >= 0; i--) {
                    if (this.items[i].entryDate > this.lastGetTime) {
                        let deletedItem = this.items.splice(i, 1)[0];
                        this.destroyIfLast(deletedItem);
                    }
                }
                this.lastGetTime = null;
            }

            //Añadir la entrada actual
            let entryDate = new Date();
            let newItem = { id: entry.id, URL: entry.URL, entryDate: entryDate, stateStack:[] };
            entry.stateStack.forEach((s) => {
                let vm = null;
                for (var i = 0; i < rps.app.stateManager.stateStack.length; i++) {
                    if (rps.app.stateManager.stateStack.at(i).vm && rps.app.stateManager.stateStack.at(i).vm.uid == s.vmId)
                        vm = rps.app.stateManager.stateStack.at(i).vm;
                }
                newItem.stateStack.push({stateName:s.stateName, vm: vm});
            })
            this.items.push(newItem);

            //Ordenar por fecha (Ascendente), por si luego hay que quitar, quitar las más viejas
            this.items.sort((a, b) => {
                return new Date(a.entryDate).getTime() - new Date(b.entryDate).getTime();
            });

            //Si aquí todavía hay demasiados items en la caché, se quitan todos los de la entrada más vieja
            while (this.getDistinctVMCount() > this.max_cache_items) {
                //Quitar la primera (más vieja) y todas las que tengan el mismo id
                let deletedItem = this.items.splice(0, 1)[0];
                this.destroyIfLast(deletedItem);
            }
        }
    }

    private getDistinctVMCount(): number {
        let vmList = new Array<rps.viewmodels.BaseVM>();
        this.items.forEach((item) => {
            item.stateStack.forEach((s) => {
                if (s.vm && vmList.indexOf(s.vm) < 0)
                    vmList.push(s.vm);
            })
        })
        return vmList.length;
    }

    //Cuando borra una entrada de caché, mirar si quedan instancias de esos vm en la caché, 
    //y si no quedan, llamar al onDestroy
    private destroyIfLast(deletedItem: { stateStack: Array<{ vm: rps.viewmodels.BaseVM }> }) {
        deletedItem.stateStack.forEach((d) => {
            let deletingVM = d.vm;
            if (deletingVM) {
                let found = false;
                for (var i = 0; i < this.items.length; i++) {
                    let item = this.items[i];
                    for (var s = 0; s < item.stateStack.length; s++) {
                        if (item.stateStack[s].vm == deletingVM) {
                            found = true;
                            break;
                        }
                    }
                }

                if (!found) {
                    //Si el vm de entrada es el mismo que el actual (no ha cambaido de componente),
                    //sólo eliminar aquellas que sean principales, porque si no, podemos eliminar demasiado
                    //p.e., Si quitamos un EntityVM cuyo padre está vivo, puede terminar destruyendo todo
                    if (rps.app.stateManager.stateStack.at(0).vm === deletedItem.stateStack[0].vm && (
                        !(deletingVM instanceof rps.viewmodels.ComponentVM) && !(deletingVM instanceof rps.viewmodels.MainEntityVM)
                    )) {
                        //No eliminar
                    }
                    else
                        deletingVM.onDestroy();
                }
            }
        })
    }

    /**
     * Devuelve la última instancia cacheada para la Url especificada
     * @param url
     */
    getLastForUrl(url: string,stateName): rps.viewmodels.BaseVM {
        let urlEntry;
        //Buscar empezando por el último
        for (var i = this.items.length - 1; i >= 0; i--) {
            if (this.items[i].URL == url) {
                urlEntry = this.items[i];
                break;
            }
        }

        if (urlEntry) {
            //Buscar la de este estado
            for (var i = 0; i < urlEntry.stateStack.length; i++) {
                if (urlEntry.stateStack[i].stateName == stateName )
                    return urlEntry.stateStack[i].vm;
            }
        }
        return null;
    }

    getVM(stateName: string): rps.viewmodels.BaseVM {
        var poppedState = (<rpsStateManager>rps.app.stateManager).poppedState;
        if (poppedState) {
            //Buscar la entrada concreta
            var poppedEntry = Enumerable.From(this.items).FirstOrDefault(null, (item) => item.id == poppedState.id);
            if (poppedEntry) {
                //Buscar la del estado y vm concretos
                let st = Enumerable.From(poppedState.stateStack).FirstOrDefault(null, (s) => s.stateName == stateName);
                if (!st)
                    return;

                for (var i = 0; i < poppedEntry.stateStack.length; i++) {
                    var s = poppedEntry.stateStack[i];
                    if (poppedEntry.stateStack[i].stateName == st.stateName &&
                        ((!poppedEntry.stateStack[i].vm && !st.vmId) || poppedEntry.stateStack[i].vm.uid == st.vmId )) {
                        //Actualizar lastGetTime para luego borrar posteriores en caso de hacer add (se rompería el historial hacia adelante)
                        this.lastGetTime = poppedEntry.entryDate;
                        console.log("Using cached vm: " + stateName + " for " + poppedEntry.URL);
                        return poppedEntry.stateStack[i].vm;
                    }
                }
            }
        }
        return null;
    }
}

@Injectable()
export class rpsStateManager implements rps.services.IStateManager {

    private modalStates: Array<string> = new Array<string>();
    private isChangingState = false;
    private useCachedVMOnNextNavigation = false;

    public stateHierarchy: rps.services.StateDescriptor[];
    public uiStateHierarchy: rps.services.StateDescriptor[];

    public forceNewWindowInModal: boolean;
    public previousUrl: string;
    public nextUrl: string;

    private currentCompany: string;
    public getCurrentCompany() {
        if (!this.currentCompany)
            this.currentCompany = rps.app.session.company;
        return this.currentCompany;
    }
    public setCurrentCompany(value: string) {
        this.currentCompany = value;
    }

    public stateStack: stateStack;
    private vmCache: VMCache;

    public poppedState: rps.services.IHistoryState;

    public navigationStarted: rps.services.IEventEmitter<string>;

    private _getLinkParams(instructions, until) {
        let linkParams = [];

        instructions.forEach((item, index) => {
            let component = item.component;

            if (index <= until) {

                linkParams.push(component.routeData.get('name'));

                if (!this._isEmpty(component.params)) {
                    linkParams.push(component.params);
                }
            }
        });

        return linkParams;
    }

    private _isEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop))
                return false;
        }

        return true;
    }
    constructor(
        private _rpsErrorManager: rpsErrorManager,
        private _rpsSessionStorage: rpsSessionStorage,
        private _rpsAppSettings: rpsAppSettings,
        private _http: Http,
        private _router: Router,
        private _location: Location) {

        this.stateHierarchy = [];
        this.uiStateHierarchy = [];
        this.vmCache = new VMCache();
        this.stateStack = new stateStack();
        this.navigationStarted = rps.app.eventManager.createEmitter<string>(false);

        (<stateStack>this.stateStack).stackChanged.subscribe(() => {
            this.resetStateHierarchy();

            //Ha cambiado el stack correspondiente a esta url
            if (history && history.state && history.state.URL == this._location.path()) {
                var stack = this.stateStack.getCurrentStack(history.state.URL);
                stack.id = history.state.id;
                history.replaceState(stack, null);
                this.vmCache.replaceLastEntry(stack);
            }
        });

        //Engancharse al onPopState
        window.onpopstate = (event: PopStateEvent) => {
            //Si viene de una ventana modal, no se restauran los datos de persisntencia, no tiene sentido y puede
            //truñarse la pantalla actual
            if (this.stateStack.current && this.stateStack.current.isModalComponent)
                return;

            this.poppedState = event.state;
        };

        this._router.events.subscribe((e) => {
            if (e instanceof NavigationStart) {
                //Tratar casos de redirección a otra empresa
                // /index.html?redirect=/app/F01-001/sales.invoiceconsult
                if (e.url.toLowerCase().startsWith("/index.html?redirect")) {
                    let url = decodeURIComponent(e.url).split("=")[1];
                    if (url.startsWith("/"))
                        url = url.substring(1);
                    let company = url.split("/")[1];
                    if (rps.app.session.logged && company != rps.app.session.company) {
                        rps.app.stateManager.goToDesktop();
                    }
                    else if (!rps.app.session.logged) {
                        //Se guarda la de la URL para aprovechar en el logon si es caso
                        this.currentCompany = company;
                        this.saveRedirectPath();
                        rps.app.stateManager.goToLogon();
                    }
                }
                else {
                    this.nextUrl = e.url;

                    rps.app.busyIndicatorManager.setIsBusy(true);
                    this.navigationStarted.emit(e.url);
                }
            }
            else if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
                if (e instanceof NavigationEnd) {
                    //Guardo el stack en el estado y vms en caché (siempre que no venga de un pop)
                    if (!this.poppedState) {
                        let stack = this.stateStack.getCurrentStack((<NavigationEnd>e).url);
                        history.replaceState(stack, null);
                        this.vmCache.add(stack, this.poppedState == null);
                    }
                    else if (history.state == null)
                        history.replaceState(this.poppedState, null);

                    this.previousUrl = this.nextUrl;
                }
                this.poppedState = null;
                rps.app.busyIndicatorManager.setIsBusy(false);
                this.nextUrl = "";
                this.forceNewWindowInModal = true;
                this.useCachedVMOnNextNavigation = false;
            }
        });
    }

    public getCachedVM(stateName: string): rps.viewmodels.BaseVM {
        //Si navega por url indicando que quiere un vm cacheado, tendrá la variable distinta de nulo
        if (this.useCachedVMOnNextNavigation)
            return this.vmCache.getLastForUrl(this.nextUrl, stateName);
        else
            return this.vmCache.getVM(stateName);
    }

    private replaceLinkParams(linkDefinition: rps.data.IUILinkDefinition, params?: {}) {
        //Clonar los parámetros antes de usarlos
        params = params || {};
        let localParams = JSON.parse(JSON.stringify(params));

        var usedParams = [];

        //En el caso del último estado, si coinciden los parámetros, se machacan
        var lastPart = linkDefinition[linkDefinition.length - 1];
        for (var prop in localParams) {
            if (lastPart.Parameters && lastPart.Parameters.hasOwnProperty(prop)) {
                lastPart.Parameters[prop] = localParams[prop];
                usedParams.push(prop);
            }
            if (lastPart.Arguments && lastPart.Arguments.hasOwnProperty(prop)) {
                lastPart.Arguments[prop] = localParams[prop];
                usedParams.push(prop);
            }
        }

        usedParams.forEach((param) => {
            delete localParams[param];
        });

        //Todos los parámetros no usados se reparten 
        if (!rps.object.isEmpty(localParams)) {
            for (var prop in localParams) {
                if (rps.object.hasValue(localParams[prop])) {
                    for (var i = 0; i < linkDefinition.length; i++) {
                        var part = linkDefinition[i];
                        if (part.Parameters && part.Parameters.hasOwnProperty(prop) && !rps.object.hasValue(part.Parameters[prop])) {
                            part.Parameters[prop] = localParams[prop];
                        }
                        if (part.Arguments && part.Arguments.hasOwnProperty(prop) && !rps.object.hasValue(part.Arguments[prop])) {
                            part.Arguments[prop] = localParams[prop];
                        }
                    }
                }
            }
        }
    }

    public get notFoundLink(): Array<any>{
        //TODO Igor: definir alguna página de notFound
        return ["app", rps.app.session.company,  "general.desktop"];
    }

    public createRouterLink(linkDefinition: rps.data.IUILinkDefinition): { routerLink: Array<any>, queryParams: rps.IParams }
    public createRouterLink(linkDefinition: rps.data.IUILinkDefinition, routeParams: {}): { routerLink: Array<any>, queryParams: rps.IParams }
    public createRouterLink(linkDefinition: rps.data.IUILinkDefinition, routeParams?: {}): { routerLink: Array<any>, queryParams: rps.IParams }{
        if (rps.object.isNullOrUndefined(linkDefinition))
            return {
                routerLink: [], queryParams: {}
            };

        var linkCopy = new rps.data.IUILinkDefinition();
        linkDefinition.slice().forEach((part) => {
            linkCopy.push(JSON.parse(JSON.stringify(part)));;
        });

        //Por defecto, la empresa es la que está logeada; puede ser qeu en los parámetros se pase, en ese caso se coge esa
        var urlCompany = this.getCurrentCompany();

        //En el caso de que el UILinkDefinition tenga ya un currentCompany con valor, se usa ese valor
        if (linkCopy[0].Parameters && linkCopy[0].Parameters["currentCompany"]) {
            urlCompany = linkCopy[0].Parameters["currentCompany"];
            delete linkCopy[0].Parameters["currentCompany"];
        }

        //Si se pasan parámetros, se sustituyren en el linkDefinition
        routeParams = routeParams || {};
        //Además de los parámetros pasados, se mezclan con los de la ruta actual si están a null y corresponden
        if (this.stateStack.length> 0 && this.stateStack.at(0).configOptions.stateName.toLowerCase() == linkCopy[0].State.toLowerCase()) {
            for (var i = 0; i < linkCopy.length; i++) {
                var state = this.stateStack.find((st) => st.configOptions.stateName.toLowerCase() == linkCopy[i].State.toLowerCase());
                if (state != null) {
                    //Mezclar parámetros del target con el state si no vienen en riouteParams pasados
                    for (var param in linkCopy[i].Parameters) {
                        if (!rps.object.hasValue(linkCopy[i].Parameters[param]) &&
                            rps.object.hasValue(state.routeParams[param]) && state.routeParams[param] != "null")
                            linkCopy[i].Parameters[param] = state.routeParams[param];
                    }
                    for (var param in linkCopy[i].Arguments) {
                        if (!rps.object.hasValue(linkCopy[i].Arguments[param]) &&
                            rps.object.hasValue(state.routeParams[param]) && state.routeParams[param] != "null")
                            linkCopy[i].Arguments[param] = state.routeParams[param];
                    }
                }
            }
        }
        this.replaceLinkParams(linkCopy, routeParams);

        //Una vez que ya están puestos los valores de parámetros, se crea el routerLink como tal
        var routerLink: Array<any> = [];;
        if (urlCompany)
            routerLink.push('/app',urlCompany);
        var queryParams = {};
        linkCopy.forEach((part) => {
            routerLink.push(part.State.toLowerCase());
            if (part.Parameters && !rps.object.isEmpty(part.Parameters)) {
                for (var param in part.Parameters) {
                    routerLink.push(part.Parameters[param]);
                }
            }
            if (part.Arguments && !rps.object.isEmpty(part.Arguments)) {
                for (var arg in part.Arguments) {
                    //Añadir queryParam sólo si lo que se añade es distinto de null
                    if (rps.object.hasValue(part.Arguments[arg]))
                        queryParams[arg] = part.Arguments[arg];
                }
            }
        });
        //return { routerLink: routerLink, queryParams: queryParams };
        //Comprobar si existe la ruta (???????): no sé si esto será muy fiable...
        try {
            var h = this._router.createUrlTree(routerLink, { queryParams: queryParams });
            var s = this._router.serializeUrl(h);
            return { routerLink: routerLink, queryParams: queryParams };
        }
        catch (e) {
            return { routerLink: [], queryParams: null};
        }
    }

    public isStateRegistered(stateName: string): boolean {
        var mainConfig = this._router.config;
        return Enumerable.From(mainConfig[0].children).Any((route) => route.path == stateName);
    }

    public getStateParams(): rps.IParams {
        var params = {}; 
        for (var i = 0; i < this.stateStack.length;i++ ){
            var state = this.stateStack.at(i);
            for (var param in state.routeParams) {
                params[param] = state.routeParams[param];
            }
        }

        return params;
    }

    public currentPath(): string {
        return this._location.path();
    }
        

    public replaceID(newID: string): void{
        //Si no pasan un ID válido, no se hace el replace (caso de una entidad cuyo ID es el código, que está a nulo a hacer el new)
        if (!newID)
            return;

        var newUrl = this._location.path();
        if (newUrl.endsWith("/new") || newUrl.indexOf("/new?") > -1 || newUrl.indexOf("/new;") > -1) {

            //debugger;
            //this._router.navigate([newID], { relativeTo: this.stateStack.current.activatedRoute.parent })
            //return;

            if (newUrl.endsWith("/new"))
                newUrl = newUrl.substr(0, newUrl.lastIndexOf("/") + 1) + newID;
            newUrl = newUrl.replace("/new?", "/" + newID + "?");
            newUrl = newUrl.replace("/new;", "/" + newID + ";");

            this._router.navigateByUrl(newUrl, { replaceUrl: true });
            
        }
    }

    public refreshPageTitle() {
        if (this.stateStack.current && this.stateStack.current.vm && this.stateStack.current.vm.title) {
            this.stateStack.current.vm.title.formatValue().then((text: string) => {
                window.document.title = text;
            });
        }
        else
            window.document.title = "RPS 2017";
    }

    public restoreRedirectPath(): boolean {
        //Mirar si tiene una redirección guardada
        var redirect = this._rpsSessionStorage.get("RedirectPath");
        if (redirect && redirect.Path) {
            this._rpsSessionStorage.remove("RedirectPath");
            this._router.navigateByUrl(redirect.Path, { replaceUrl: true });           
            return true;
        }

        return false;
    }

    public saveRedirectPath() {
        //Mirar si el index.html viene con un parámetro redirect, que se rellena en el servicio en el caso de que
        //llegue con un F5 o metiendo directamente la ruta en la barrtcha de direcciones
        var redirect = this.getParameterByName("redirect", this._location.path());
        if (!rps.object.isNullOrUndefined(redirect))
            this._rpsSessionStorage.add("RedirectPath", { Path: redirect });
        else
            this._rpsSessionStorage.remove("RedirectPath");
    }

    private getParameterByName(name, url) {
        if (!url) url = window.location.href;
        //url = url.toLowerCase(); // This is just to avoid case sensitiveness  
        name = name.replace(/[\[\]]/g, "\\$&");//.toLowerCase();// This is just to avoid case sensitiveness for query parameter name
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    public navigateToUrl(url: string, useCachedViewModel:boolean = false) {
        if (url.startsWith("http://") || url.startsWith("https://"))
            window.location.href = url;
        else {
            this.useCachedVMOnNextNavigation = useCachedViewModel;
            this._router.navigateByUrl(url);
        }
    }

    public goTo(linkDefinition: rps.data.IUILinkDefinition, params ?: {}, options ?: rps.services.INavigationOptions): Promise < any > {
        return new Promise<any>((resolve, reject) => {
            var routerLink = this.createRouterLink(linkDefinition, params);
            var newWindow: boolean = options && options.openInNewWindow;

            if (linkDefinition[0].State == "navigateToEntity")
                return rps.app.stateManager.navigateToEntity(params["entity"], params["id"], newWindow);
            else {
                if (newWindow) {
                    var url = this._router.serializeUrl(this._router.createUrlTree(routerLink.routerLink, { queryParams: routerLink.queryParams }));
                    if (url.startsWith('/'))
                        url = url.substr(1);

                    if (!url.startsWith(rps.app.appSettings.rpsAPIAddress))
                        url = rps.app.appSettings.rpsAPIAddress + url;

                    window.open(url, "Popup", "location=0,status=1,scrollbars=1, resizable=1, directories=0, toolbar=0, titlebar=0, menubar=0,width=1000, height=600");
                    resolve(this);
                }
                else {
                    var replaceUrl = false;
                    if (options && options.replaceLocation)
                        replaceUrl = true;
                    else if ((this.stateStack.length > 0 && this.stateStack.current.isModalComponent) &&
                        (!options || options.replaceLocation !== false)) {
                        //Si está en modo modal, no se registran estados en el historial, salvo  que expresamente se indique lo contrario
                        replaceUrl = true;
                    }

                    //Si el replaceLocation está activo, no se hará el truco de cancelar la navegación para lanzarla en otra ventana
                    if (replaceUrl)
                        this.forceNewWindowInModal = false;

                    this._router.navigate(routerLink.routerLink, { queryParams: routerLink.queryParams, replaceUrl: replaceUrl }).then((retValue) => {
                        if (retValue === null) //Suele llegar cuando el path en el que está es el mismo al que se va...
                            retValue = true;

                        resolve(retValue);
                    }).catch((err) => {
                        reject(err);
                    });
                }
            }
        });
    }

    public goToRoute(route: ActivatedRouteSnapshot): Promise<any>{
        return new Promise<any>((resolve, reject) => {

            var url: string;
            let _route = route;

            var routerLink = [];
            route.pathFromRoot.forEach((part) => {
                var urlSegments: any = part.url;// (part.snapshot ? part.snapshot.url : part.url);
                urlSegments.forEach((segment: any) => {
                    routerLink.push(segment.path)
                });
            });
            let queryParams = (<any>(route.queryParams)).value;
            this._router.navigate(routerLink).then((retValue) => {
                if (retValue === null) //Suele llegar cuando el path en el que está es el mismo al que se va...
                    retValue = true;

                resolve(retValue);
            }).catch((err) => {
                reject(err);
            });
        });
    }

    public goToParent(): Promise<any> {
        //Ir hacia arriba en el stack de componentes
        if (this.stateStack.length<= 1)
            return this.goToDesktop();

        return new Promise((resolve) => {
            this._router.navigate(["."], {
                relativeTo: this.stateStack.at(this.stateStack.length - 2).activatedRoute,
                preserveQueryParams:true 
            }).then(() => {
                resolve();
            });;
        });
    }

    public goBack():Promise<any>{
        return new Promise((resolve, reject) => {
            var unsub = this._router.events.subscribe((e) => {
                if (e instanceof NavigationStart) {
                }
                else if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
                    resolve(true);
                    unsub.unsubscribe();
                }
            });
            this._location.back();
        });
    }

    public href(stateName: rps.data.IUILinkDefinition, params?: {}, options?: rps.services.IHrefOptions): string {
        var target = this.createRouterLink(stateName, params);
        var tree = this._router.createUrlTree(target.routerLink, { queryParams: target.queryParams });
        var url = this._router.serializeUrl(tree);
        if (url.startsWith('/'))
            url = url.substr(1);

        if (!url.startsWith(rps.app.appSettings.rpsAPIAddress))
            url = rps.app.appSettings.rpsAPIAddress + url;

        return url;
    }

    public configure() {
        rps.app.stateManager = this;
    }

    private resetErrors(toState: any, toParams: any, fromState: any, fromParams: any) {
        if (!toState.name.startsWith(fromState.name))
            this._rpsErrorManager.clear();
    }

    public resetStateHierarchy() {

        //Limpiar el array y volver a crearlo
        this.stateHierarchy.length = 0;
        this.uiStateHierarchy.length = 0;

        for (var i = 0; i < this.stateStack.length; i++) {
            let component = this.stateStack.at(i);
            //Recorrer los estados para crear descriptores, y además, ir creando el string de parámetros que hay que pasarle
            var newStateDescriptor: rps.services.StateDescriptor = new rps.services.StateDescriptor(component.vm);

            //Calcular la ruta hasta llegar aquí
            newStateDescriptor.routerLink = [];
            var stateRoute: ActivatedRoute = component.activatedRoute;
            stateRoute.pathFromRoot.forEach((part) => {
                var urlSegments: any = (part.snapshot ? part.snapshot.url : part.url);
                urlSegments.forEach((segment: any) => {
                    newStateDescriptor.routerLink.push(segment.path)
                });
            });
            newStateDescriptor.queryParams = (<any>(stateRoute.queryParams)).value;

            this.stateHierarchy.push(newStateDescriptor);

            if (!Enumerable.From(this.uiStateHierarchy).Any() ||
            !(Enumerable.From<rps.services.StateDescriptor>(this.uiStateHierarchy).Last().relatedVM == newStateDescriptor.relatedVM)) {
                this.uiStateHierarchy.push(newStateDescriptor);
            }                
        };
    }

    private navigationHandlers: { [state: string]: navigationHandler; } = {};

    public setAsModalState = (stateName: string) => {
        if (!Enumerable.From<string>(this.modalStates).Contains(stateName))
            this.modalStates.push(stateName);
    }

    public navigateModal = (state: string, vm: rps.viewmodels.BaseVM, okCommand: rps.viewmodels.commands.CommandProperty): Promise<{ result: rps.services.NavigationResult; data: any }> => {
        return new Promise<{ result: rps.services.NavigationResult; data: any }>(
            (resolve: rps.async.IResolveReject<{ result: rps.services.NavigationResult; data: any }>, reject) => {

                var targetState = state.toLowerCase();
                this.navigationHandlers[targetState] = new navigationHandler(targetState, resolve, reject, okCommand);

                //Navegar desde el componente cuyo viewmodel es el pasado como parámetro
                let component: rps.services.IBaseComponent = null;
                for (var i = 0; i < this.stateStack.length; i++) {
                    if (this.stateStack.at(i).vm == vm) {
                        component = this.stateStack.at(i);
                        break;
                    }
                }
                if (component == null)
                    reject("Could not navigate to " + state);

                //Añadir un array de acciones con close al vm para poder gestionar el botón de cerrar; se añade antes de la navegación
                //para que las acciones estén disponibles al abrir la ventana, para crearla ya con la X de cerrar
                (<any>vm)["__actions" + targetState] = [{
                    name: 'close',
                    method: () => {
                        if ((<any>vm)["__CancelCommand" + targetState]) {
                            (<rps.viewmodels.commands.CommandProperty>((<any>vm)["__CancelCommand" + targetState])).execute();
                        }
                    },
                    target: vm
                }];

                component.navigate([targetState]).then((result) => {
                    if (result) {
                        //Además, se le añaden dos comandos de OK y Cancel para que pueda cerrar la ventana                    
                        (<any>vm)["__OKCommand" + targetState] = new rps.viewmodels.commands.CommandProperty({
                            target: vm,
                            command: (commandParams?: any): Promise<rpsStateManager> => {
                                return this.resolveNavigation(rps.services.NavigationResult.OK, commandParams ? commandParams["fromBackButton"] : false).then(() => {
                                    return this;
                                });
                            },
                            canExecute: this.resolveNavigationCanExecuteOk
                        });
                        (<any>vm)["__CancelCommand" + targetState] = new rps.viewmodels.commands.CommandProperty({
                            target: vm,
                            command: (commandParams?: any): Promise<rpsStateManager> => {
                                return this.resolveNavigation(rps.services.NavigationResult.Cancel, commandParams ? commandParams["fromBackButton"] : false).then(() => {
                                    return this;
                                });
                            },
                            canExecute: this.resolveNavigationCanExecuteCancel
                        });
                    }
                    else {
                        reject(`Navigation to ${state} has been canceled`);
                    }
                });
        });
    };

    public navigateChild = (link: rps.viewmodels.properties.LinkProperty, navigationArguments: rps.IParams, vm: rps.viewmodels.BaseVM, okCommand: rps.viewmodels.commands.CommandProperty): Promise<{ result: rps.services.NavigationResult; data: any }> => {
        return new Promise(
            (resolve: rps.async.IResolveReject<{ result: rps.services.NavigationResult; data: any }>, reject) => {

                //Se usa el último estado como clave de los handlers
                var targetState: string = link.state[link.state.length - 1].State.toLowerCase();

                if (targetState) {
                    this.navigationHandlers[targetState] = new navigationHandler(targetState, resolve, reject, okCommand);
                    //var params = new Object();;
                    //if (this.$state.current.name != vm.relatedState)
                    //    vm.fillStateParams(params);                    

                    link.go(navigationArguments, { openInNewWindow: false,replaceLocation: false }).then((promiseValue: any) => {
                        //Marcar el componente como modal
                        setTimeout(() => {
                            this.stateStack.current.isModalComponent = true;

                            //Hay veces que el viewModel todavía no está asignado; engancharse al evento para asignar comandos de OK y cancel
                            if (this.stateStack.current.vm)
                                this.setVMCommands(targetState, this.stateStack.current.vm, vm);
                            else
                                this.stateStack.current.vmChanged.subscribe((windowVM) => {
                                    this.setVMCommands(targetState, windowVM, vm);
                                });
                        });
                    });
                }
            });
    }

    private setVMCommands(stateId: string, windowVM: any,targetVM:any) {
        //Además, se le añaden dos comandos de OK y Cancel para que pueda cerrar la ventana    
        windowVM["__OKCommand" + stateId] = new rps.viewmodels.commands.CommandProperty({
            target: targetVM,
            command: (commandParams?:any): Promise<rpsStateManager> => {
                return this.resolveNavigation(rps.services.NavigationResult.OK, commandParams ? commandParams["fromBackButton"] : false).then((result: boolean) => {
                    return this;
                });
            },
            canExecute: this.resolveNavigationCanExecuteOk
        });
        windowVM["__CancelCommand" + stateId] = new rps.viewmodels.commands.CommandProperty({
            target: targetVM,
            command: (commandParams?: any): Promise<rpsStateManager> => {
                return this.resolveNavigation(rps.services.NavigationResult.Cancel, commandParams ? commandParams["fromBackButton"] : false).then((result: boolean) => {
                    return this;
                });
            },
            canExecute: this.resolveNavigationCanExecuteCancel
        });
    }

    public resolveNavigationCanExecuteOk = (): rps.viewmodels.commands.CanExecuteResult => {
        var currentComponent = this.stateStack.current;
        var handler = this.navigationHandlers[currentComponent.configOptions.stateName.toLowerCase()];
        if (handler)
            return handler.okCommand.canExecute(currentComponent.vm);
        else
            return rps.viewmodels.commands.CanExecuteResult.deny(null);
    }
    public resolveNavigationCanExecuteCancel = (): rps.viewmodels.commands.CanExecuteResult => {
        var currentComponent = this.stateStack.current;
        var handler = this.navigationHandlers[currentComponent.configOptions.stateName.toLowerCase()];
        if (handler)
            return rps.viewmodels.commands.CanExecuteResult.allow();
        else
            return rps.viewmodels.commands.CanExecuteResult.deny(null);
    }

    public resolveNavigation(result: rps.services.NavigationResult, fromBackButton: boolean): Promise<boolean> {
        var currentComponent = this.stateStack.current;
        var handler = this.navigationHandlers[currentComponent.configOptions.stateName.toLowerCase()];
        if (handler) {
            //Se guarda el vm en el handler porque hay algún caso en el que se quita del componente antes de resolver la navegación
            handler.vm = currentComponent.vm;
            if (result == rps.services.NavigationResult.OK) {
                handler.isExecutingOK = true;
                return handler.okCommand.execute(handler.vm)
                    .then(() => {
                        return this.continueResolvingNavigation(result, handler.vm, handler, fromBackButton);
                    }).finally(() => {
                        handler.isExecutingOK = false;
                    });
            }
            else if (!handler.isExecutingOK){
                return this.continueResolvingNavigation(result, handler.vm, handler, fromBackButton);
            }
        }
        return Promise.resolve(true);        
    }

    private continueResolvingNavigation(result: rps.services.NavigationResult,
        vm: rps.viewmodels.BaseVM, handler: navigationHandler,
        fromBackButton: boolean): Promise<boolean>{

        if (!vm) //Para casos raros en los que se navega a un modelo en el OK y entra por aquí
            return;

        if (vm.isUnloaded) {
            //TODO: revisar que haya algún caso en el que entra por aqui
            console.log("SI ENTRA: ContinueResolvingNavigation: vm.isUnloaded");

            delete vm["__OKCommand" + handler.relatedState];
            delete vm["__CancelCommand" + handler.relatedState];
            return Promise.resolve(true);
        }

        //Ejecutar el onBeforeUnload por si éste cancela la navegación
        return vm.onBeforeUnload().then((resolve) => {
            if (resolve) {

                if (!fromBackButton) {
                    //Si tiene nextUrl, significa que está en mitad de una navegación sin terminar, no tiene sentido ir hacia atrás 
                    //en este caso
                    if (!this.nextUrl && this.stateStack.current.configOptions.stateName.toLowerCase() == handler.relatedState)
                        //Para cerrar la ventana, se hace un goBack, para que no quede registro de la ventana modal
                        return this.goBack().then(() => {
                            //En este caso el comando de navegación se resuelve una vez que ha terminado el goBack
                            if (handler instanceof navigationHandler) {
                                vm.isUnloaded = false;
                                //Resuelve el comando de navegación modal
                                handler.executeResolve({ result: <rps.services.NavigationResult>result, data: vm });
                                this.navigationHandlers[handler.relatedState] = null;
                                delete this.navigationHandlers[handler.relatedState];
                            }
                            delete vm["__OKCommand" + handler.relatedState];
                            delete vm["__CancelCommand" + handler.relatedState];
                            return true;
                        });
                    else {
                        //Revisado: sí que hay algún caso en el que entra por aqui (navegaciones en el OK de la ventana modal)
                        //console.log("SI ENTRA: ContinueResolvingNavigation: this.nextUrl != null");
                    }
                }

                if (handler instanceof navigationHandler) {
                    vm.isUnloaded = false;
                    handler.executeResolve({ result: <rps.services.NavigationResult>result, data: vm });
                    this.navigationHandlers[handler.relatedState] = null;
                    delete this.navigationHandlers[handler.relatedState];
                }
                delete vm["__OKCommand" + handler.relatedState];
                delete vm["__CancelCommand" + handler.relatedState];

                return true;
            }
            else {
                //El onBeforeUnload ha cancelado la navegación
                if (fromBackButton)
                    history.forward();
                return false;
            }
        });
    }

    public navigateToEntity(entity: string, id: string, openInNewWindow: boolean): Promise<any> {
        //Y mirar donde tiene que navegar realmente
        var navigationParams = new URLSearchParams();
        var targetUrl: string;
        if (id != rps.viewmodels.EntityVM.NEW_ID_VALUE) {
            navigationParams.set('entity', entity);
            navigationParams.set('id', id);
            navigationParams.set('company', rps.app.session.company);
            targetUrl = "clientapi/entity/nav";
        }
        else {
            navigationParams.set('entity', entity);
            targetUrl = "clientapi/entity/nav/new"
        }

        return new Promise((resolve, reject) => {
            //Mirar el estado resuelto para navegar
            this._http.get(
                this._rpsAppSettings.rpsAPIAddress + targetUrl,
                {
                    search: navigationParams,
                    body: '',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                    })
                }).map((res: Response, ix?: number) => {
                    return res.json();
                }).subscribe((navigationTarget: rps.data.IUILinkDefinition) => {
                    //Navegar al estado resultante
                    var routerLink = rps.app.stateManager.createRouterLink(navigationTarget);
                    if (openInNewWindow) {
                        var url = this._router.serializeUrl(this._router.createUrlTree(routerLink.routerLink, { queryParams: routerLink.queryParams }));
                        if (url.startsWith('/'))
                            url = url.substr(1);

                        if (!url.startsWith(rps.app.appSettings.rpsAPIAddress))
                            url = rps.app.appSettings.rpsAPIAddress + url;

                        window.open(url, "Popup", "location=0,status=1,scrollbars=1, resizable=1, directories=0, toolbar=0, titlebar=0, menubar=0,width=1000, height=600");

                        resolve(true);
                    }
                    else {
                        this._router.navigate(routerLink.routerLink, { queryParams: routerLink.queryParams }).then((res) => {
                            resolve(res);
                        }).catch((err) => {
                            reject(err);
                        });
                    }

                }, (error) => {
                    debugger;
                    this._router.navigate(this.notFoundLink).then((res) => {
                        resolve(res);
                    }).catch((err) => {
                        reject(err);
                    });
                });
        });
    }

    public goToLogon(replaceUrl:boolean = false): Promise<any> {
        return new Promise((resolve, reject) => {
            this._router.navigate(["html5.logon"], { replaceUrl: replaceUrl }).then(() => resolve()).catch(() => reject());
        });
    }
    public goToDesktop(replaceUrl: boolean = false): Promise<any> {
        return rps.app.stateManager.goTo(
            rps.data.IUILinkDefinition.create("html5.desktop"), {}, { openInNewWindow: false, replaceLocation: replaceUrl }
        );
    }
    public goToNotFound(replaceUrl: boolean = false): Promise<any> {
        return new Promise((resolve, reject) => {
            this._router.navigate(["html5.notFound"], { replaceUrl: replaceUrl }).then(() => resolve()).catch(() => reject());
        });
    }
}

export class navigationHandler {
    public isExecutingOK: boolean;
    public vm: rps.viewmodels.BaseVM;

    constructor(public relatedState: string, resolve: rps.async.IResolveReject<{ result: rps.services.NavigationResult; data: any }>, reject: (any) => void, public okCommand: rps.viewmodels.commands.CommandProperty) {
        this.executeResolve = (value: { result: rps.services.NavigationResult; data: any }) => {
            resolve(value);
        };
        this.executeReject = (value: { result: rps.services.NavigationResult; data: any }) => {
            reject(value);
        };

        this.isExecutingOK = false;
    }
    public executeResolve: (any: { result: rps.services.NavigationResult; data: any }) => void;
    public executeReject: (any: { result: rps.services.NavigationResult; data: any }) => void;
}


