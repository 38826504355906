//** Name: Desktop
//** State: html5.Desktop - /Desktop?_state - DesktopView.html

module rps.HTML5.viewmodels.Desktop {

export class DesktopViewModel extends rps.viewmodels.BaseVM{
	
    static fullTypeName: string = "rps.HTML5.viewmodels.Desktop.DesktopViewModel";

	relatedState: string = "html5.Desktop";
	static relatedState: string = "html5.Desktop";


	

	configureVMProperties(){	
		super.configureVMProperties();	
		this.Desktop = new rps.viewmodels.properties.CustomComponentHandler();

		//Creación de las condiciones de filtros de las listas de mi tipo
		
		//Configuración de querysources con custom card view
	}

	
 
 	//Declaración de las propiedades
	public Desktop: rps.viewmodels.properties.CustomComponentHandler;



	//Declaración de las condiciones de filtros de las listas de mi tipo
	}





}

module rps.HTML5.viewmodels.Desktop.resources{
	export class Desktop{
 		public static TITLE: string = '|^SR(TITLE)^|';
	}
}

rps.extensions.register(
	  rps.HTML5.viewmodels.Desktop.DesktopViewModel
);