import { Component,Input } from '@angular/core';
import {rpsRowsGroup} from '../layouts/rowsGroup';
import {rpsRow} from '../layouts/row';
import {rpsColumns} from '../layouts/columns';
import {rpsButton} from '../editors/button';
import {rpsSearchBox} from '../editors/searchBox';
import {rpsFilters} from './filters';

@Component({
    selector: 'rps-query-source-tools',
    template: `
        <div>
            <rps-rows-group>
                <rps-row class="rps-stack-panel">
                    <rps-button *ngIf="isQueriable && !rpsHideQueryCommand"
                                rpsColumns="2"
                                rpsTemplate="TEXT_WITH_ICON"
                                rpsLabel="{{resources.directives.QUERY}}"
                                rpsIcon="fa fa-refresh"
                                [rpsModel]="rpsSource?.loadItems"
                                rpsType="PRIMARY">
                    </rps-button>
                    <rps-button *ngIf="isPrintable && !rpsHidePrintCommand"
                                rpsColumns="2"
                                rpsTemplate="TEXT_WITH_ICON"
                                rpsLabel="{{resources.directives.PRINT}}"
                                rpsIcon="fa fa-print"
                                [rpsModel]="rpsSource?.printItems"
                                rpsType="{{isQueriable && !rpsHideQueryCommand ? '' : 'PRIMARY'}}">
                    </rps-button>

                    <ng-content></ng-content>

                    <rps-search-box *ngIf="isSearchable" 
                                    rpsColumns="3" class="rps-stack-panel-item-right"
                                    [rpsModel]="rpsSource?.filtersManager.search"
                                    [rpsCommandModel]="rpsSource?.loadItems">
                    </rps-search-box>
                </rps-row>
                <rps-filters [rpsFiltersManager]="rpsSource?.filtersManager"
                             *ngIf="isFiltrable">
                </rps-filters>
            </rps-rows-group>                
        </div>
    `,
    inputs: ['rpsSource', 'rpsIsSearchable', 'rpsIsQueriable', 'rpsIsFiltrable', 'rpsIsPrintable', 'rpsHideQueryCommand', 'rpsHidePrintCommand'],
})
export class rpsQuerySourceTools{
    public get resources() {
        return rps.app.resources;
    }

    @Input()
    public rpsHideQueryCommand: boolean;
    @Input()
    public rpsHidePrintCommand: boolean;

    public rpsSource: rps.data.sources.QuerySource;
    public isSearchable: boolean = false;
    public get rpsIsSearchable(): string {
        if (this.isSearchable)
            return "true";
        else
            return "false";
    }
    public set rpsIsSearchable(newValue: string) {
        if (newValue == "true")
            this.isSearchable = true;
        else
            this.isSearchable = false;
    }
    public isFiltrable: boolean = false;
    public get rpsIsFiltrable(): string {
        if (this.isFiltrable)
            return "true";
        else
            return "false";
    }
    public set rpsIsFiltrable(newValue: string) {
        if (newValue == "true")
            this.isFiltrable = true;
        else
            this.isFiltrable = false;
    }
    public isQueriable: boolean = true;
    public get rpsIsQueriable(): string {
        if (this.isQueriable)
            return "true";
        else
            return "false";
    }
    public set rpsIsQueriable(newValue: string) {
        if (newValue == "true")
            this.isQueriable = true;
        else
            this.isQueriable = false;
    }
    public isPrintable: boolean = false;
    public get rpsIsPrintable(): string {
        if (this.isPrintable)
            return "true";
        else
            return "false";
    }
    public set rpsIsPrintable(newValue: string) {
        if (newValue == "true")
            this.isPrintable = true;
        else
            this.isPrintable = false;
    }

    constructor() {
        this.rpsHideQueryCommand = false;
        this.rpsHidePrintCommand = false;
    }
}