import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders,Component } from '@angular/core';
import {reportViewerComponent} from './tsComponents/reports/reportViewer';
import {rpsApp} from './rpsApp';
import { notFoundComponent } from './tsComponents/notFound/notFound';

@Component({
    template: '<router-outlet></router-outlet>',
})
export class CurrentCompanyComponent {
}

@Component({
    template: `
        <div class="rps-div">
            <rps-busy-indicator [rpsIsBusy]="isBusy">
            </rps-busy-indicator>
        </div>`,
})
export class LoadingComponent {
    isBusy: boolean = true;
}

const appRoutes: Routes = [
    {
        path: "app/:currentCompany",
        component: CurrentCompanyComponent,
        children: [
            {
                path: "html5.desktop",
                loadChildren: () => {
                    return rpsApp.loadRouteChildren("HTML5", "Desktop");
                },
                data: rpsApp.getRouteData("HTML5", "Desktop", "HTML5.Desktop")
            }]
    },
    {
        path: "index.html",
        component: LoadingComponent
    },
    {
        path: "",
        component: LoadingComponent
    },
    {
        path: "html5.logon",
        loadChildren: () => {
            return rpsApp.loadRouteChildren("HTML5", "Logon");
        },
        data: rpsApp.getRouteData("HTML5", "Logon", "HTML5.Logon")
    },
    {
        path: '**',
        component: notFoundComponent
    }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);