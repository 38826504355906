import {Component,
    Directive,
    ElementRef,
    OnChanges,
    SimpleChange,
    AfterContentInit,
    AfterViewInit,
    OnDestroy,
    AfterViewChecked,
    ChangeDetectorRef} from '@angular/core';
import {rpsContainerService} from './container';

@Directive({
    selector: '[rpsCanExecute]',
    inputs: [
        'rpsCanExecute','rpsCanExecuteTarget'
    ]
})
export class rpsCanExecute implements OnChanges {
    rpsCanExecuteTarget: any;
    rpsCanExecute: boolean;

    constructor( ) {
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes["rpsCanExecute"]) {
            if (this.rpsCanExecuteTarget && this.rpsCanExecuteTarget.setEnable)
                this.rpsCanExecuteTarget.setEnable(<boolean>changes["rpsCanExecute"].currentValue);
        }
    }
}

@Component({
    selector: 'rps-toolbar',
    template: `
        <div class="rps-toolbar">
            <div id="kendoToolbar">
            </div>
            <ng-content></ng-content>
        </div>
    `,
    inputs: []
})
export class rpsToolbar implements AfterViewInit, OnDestroy, AfterViewChecked  {
    //Variable creada para retrasar la generación de los widgets hasta que el contenedor tenga tamaño
    private createKendoWidgetsPending: boolean = false;
    private buttons: Array<rpsToolbarButton> = new Array<rpsToolbarButton>();
    //Prevenir, que se genere dos veces, debido al timeOut, para esto, se genera un ID y se comprueba que sea la última llamada.
    private tryCreateKendToolBarID: string;

    public kendToolBar: kendo.ui.ToolBar;
    public toolbarOptions: kendo.ui.ToolBarOptions;

    constructor(private elementRef: ElementRef, private containerService: rpsContainerService, private cdRef: ChangeDetectorRef) {
    }

    ngAfterViewInit() {
        this.createOptions();
    }

    ngOnDestroy() {
        if (this.kendToolBar) {
            this.kendToolBar.options.click = null;
            $(this.kendToolBar).off('click');
            this.kendToolBar.destroy();
            this.kendToolBar = null;
        }

        $(this.elementRef.nativeElement).find("#kendoToolbar").empty();
    }

    addButton(button: rpsToolbarButton) {
        this.buttons.push(button);
        if (this.toolbarOptions) {
            this.createOptions();
        }
    }

    createOptions() {
        var newToolbarOptions: kendo.ui.ToolBarOptions = {};
        var newToolbarItems: Array<kendo.ui.ToolBarItem> = new Array<kendo.ui.ToolBarItem>();
        this.buttons.forEach((button) => {
            var toolBarItem: kendo.ui.ToolBarItem = {};
            toolBarItem.type = "button";
            toolBarItem.text = button.rpsLabel;
            if (button.rpsIcon)
                toolBarItem.spriteCssClass = button.rpsIcon;
            toolBarItem.hidden = !button.rpsVisible;
            toolBarItem.enable = button.enable;
            //Se añade el ID autogenerado en cada botón, para poder buscar los elementos en el DOM
            if (button.rpsCssClass)
                toolBarItem.attributes = { "class": button.rpsCssClass + " " + button.myId}
            else
                toolBarItem.attributes = { "class": button.myId };
            toolBarItem.id = button.myId;
            button.setToolBarItem(toolBarItem);
            newToolbarItems.push(toolBarItem);
        });
        newToolbarOptions.items = newToolbarItems;
        newToolbarOptions.click = (e) => {
            var b = Enumerable.From(this.buttons).FirstOrDefault(null, but => but.myId == e.id);
            if (b)
                b.click();
        };
        this.toolbarOptions = newToolbarOptions;

        this.tryCreateKendToolBar();        
    }

    ngAfterViewChecked() {
        if (this.createKendoWidgetsPending) {
            var kendoToolbar = $(this.elementRef.nativeElement).find("#kendoToolbar");
            if (kendoToolbar.outerWidth() > 0) {
                this.tryCreateKendToolBar();
            }

            this.cdRef.detectChanges();
        }
    }

    tryCreateKendToolBar() {
        var kendoToolbar = $(this.elementRef.nativeElement).find("#kendoToolbar");
        var refreshContainer: boolean = false;
        if (this.kendToolBar) {
            this.kendToolBar.destroy();
            kendoToolbar.empty();
            this.kendToolBar = null;

            refreshContainer = true;
        }

        //Si el contenedor de la grid ya tiene tamaño, se crean los widgets
        if (kendoToolbar.outerWidth() > 0) {            
            this.createKendoWidgetsPending = false;
            if (rps.object.hasValue(this.toolbarOptions) && Enumerable.From<kendo.ui.ToolBarItem>(this.toolbarOptions.items).Any(i => i.hidden == false)) {
                //Se añade un retardo, ya que en ocasiones, parece que sigue sin ser capaz de calcular el tamaño correctamente
                //En los multiMaintenance, cuando haces nuevo y navegas a este…
                this.tryCreateKendToolBarID = rps.guid.newGuid();
                var currentTryCreateKendToolBarID = this.tryCreateKendToolBarID;
                setTimeout(() => {
                    if (currentTryCreateKendToolBarID == this.tryCreateKendToolBarID) {
                        this.kendToolBar = kendoToolbar.kendoToolBar(this.toolbarOptions).data("kendoToolBar");
                        this.containerService.refreshContainer();
                        this.tryCreateKendToolBarID = null;
                    }
                });
            }
        }
        else {
            this.createKendoWidgetsPending = true;
        }

        if (refreshContainer)
            this.containerService.refreshContainer();
    }
}

@Component({
    selector: 'rps-toolbar-button',
    template: `<div [rpsCanExecute]="evalCanExecute()" 
                    [rpsCanExecuteTarget] = "buttonInstance" > <div>`,
    inputs: ['rpsLabel','rpsIcon','rpsModel','rpsCssClass','rpsVisible']
})
export class rpsToolbarButton implements OnChanges ,AfterContentInit,OnDestroy{
    
    private toolBarItem: kendo.ui.ToolBarItem;
    private toolbar: rpsToolbar;

    private commandProperty: rps.viewmodels.commands.CommandProperty;
    private commandPropertyPropertyChangedSub: rps.services.ISubscription;

    private linkProperty: rps.viewmodels.properties.ILinkProperty;
    private isBusy: boolean = false;

    public myId: string;
    public rpsLabel: string;
    public rpsIcon: string;    
    public rpsCssClass: string;
    public rpsVisible: boolean = true;
    public rpsModel: rps.viewmodels.properties.ILinkProperty|rps.viewmodels.commands.CommandProperty;
    public enable: boolean = true;

    public buttonInstance: rpsToolbarButton;

    constructor(_toolbar: rpsToolbar, private _elementRef: ElementRef) {
        if (_elementRef.nativeElement.id)
            this.myId = "_" + _elementRef.nativeElement.id;
        else
            this.myId = rps.guid.newGuid().split("-").join("_");
        _toolbar.addButton(this);
        this.setToolbarController(_toolbar);
    }

    ngAfterContentInit() {
        this.setProperty();
        this.buttonInstance = this;
    }

    ngOnDestroy() {
        this.setToolbarController(null);
        this.setToolBarItem(null);

        if (this.commandPropertyPropertyChangedSub)
            this.commandPropertyPropertyChangedSub.unsubscribe();
    }

    getUIElement():JQuery {
        return this.toolbar.kendToolBar.element.find("." + this.myId);        
    }

    getOverflowUIElement(): JQuery {
        //HACK: Si el elemento no entra, tambien esta en el popup de la toolbar…
        return (<any>this.toolbar.kendToolBar).popup.element.find("." + this.myId);
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {

        if (changes["rpsLabel"])
            this.setLabel();
        if (changes["rpsVisible"])
            this.setVisibility();
        if (changes["rpsModel"])
            this.setProperty();
    }

    setLabel() {
        if (this.toolBarItem) {
            this.toolBarItem.text = this.rpsLabel;            
            //HACK: Se modifica el DOM, para no forzar la regeneración de todos los ítems…
            if (this.toolbar.kendToolBar) {
                if (this.getUIElement().html().indexOf(">") != -1) {
                    //Si contiene algún elemento, se respeta
                    this.getUIElement().html(this.getUIElement().html().substr(0, this.getUIElement().html().lastIndexOf(">") + 1) + this.toolBarItem.text);
                    this.getOverflowUIElement().html(this.getOverflowUIElement().html().substr(0, this.getOverflowUIElement().html().lastIndexOf(">") + 1) + this.toolBarItem.text);
                }
                else {
                    this.getUIElement().html(this.toolBarItem.text);
                    this.getOverflowUIElement().html(this.toolBarItem.text);
                }
                this.toolbar.kendToolBar.resize(true);                
            }
        }
    }

    setVisibility() {
        if (this.toolBarItem) {
            this.toolBarItem.hidden = !this.rpsVisible;
            this.toolbar.tryCreateKendToolBar();
        }
    }

    setEnable(newValue: boolean) {
        if (this.enable != newValue) {
            this.enable = newValue;

            if (this.toolBarItem) {
                this.toolBarItem.enable = this.enable;                
                //HACK: Se modifica el DOM, para no forzar la regeneración de todos los ítems…
                if (this.toolbar.kendToolBar) {
                    if (this.toolBarItem.enable) {
                        this.getUIElement().removeClass("k-state-disabled");
                        this.getOverflowUIElement().removeClass("k-state-disabled");
                    }
                    else {
                        this.getUIElement().addClass("k-state-disabled");
                        this.getOverflowUIElement().addClass("k-state-disabled");
                    }                    
                }
            }
        }
    }

    setIsBusy() {
        if (this.toolBarItem) {
            //HACK: Se modifica el DOM, para no forzar la regeneración de todos los ítems…
            if (this.toolbar && this.toolbar.kendToolBar) {
                if (this.isBusy) {
                    setTimeout(() => {
                        if (this.isBusy && this.toolbar) {
                            this.getUIElement().addClass("toolbar-button-is-busy");
                            this.getOverflowUIElement().addClass("toolbar-button-is-busy");
                        }
                    }, 300)
                }
                else {
                    this.getUIElement().removeClass("toolbar-button-is-busy");
                    this.getOverflowUIElement().removeClass("toolbar-button-is-busy");
                }
            }
        }
    }

    setToolbarController(toolbar: rpsToolbar) {
        this.toolbar= toolbar;
    }

    setToolBarItem(toolBarItem: kendo.ui.ToolBarItem) {
        this.toolBarItem = toolBarItem;
    }

    public click = () => {
        if (this.commandProperty)
            this.commandProperty.tryExecute();
        else if (this.linkProperty)
            this.linkProperty.go();
    }

    evalCanExecute = (): boolean => {
        if (this.isBusy)
            return false;

        if (this.commandProperty)
            return this.commandProperty.canExecute().result;
        else if (this.linkProperty)
            return true;
        else
            return false;
    }

    setProperty() {
        if (rps.viewmodels.commands.isCommandProperty(this.rpsModel)) {
            this.setLinkProperty(null);
            this.setCommandProperty(this.rpsModel);
        }
        else if (rps.viewmodels.properties.isILinkProperty(this.rpsModel)) {
            this.setCommandProperty(null);
            this.setLinkProperty(this.rpsModel);
        }
        else {
            this.setCommandProperty(null);
            this.setLinkProperty(null);
        }
    }

    setCommandProperty(newValue:rps.viewmodels.commands.CommandProperty) {
        this.commandProperty = newValue;

        if (this.commandProperty) {
            if (this.commandPropertyPropertyChangedSub)
                this.commandPropertyPropertyChangedSub.unsubscribe();
            this.commandPropertyPropertyChangedSub = this.commandProperty.propertyChanged.subscribe((ch: rps.viewmodels.properties.VMPropertyChange) => {
                if (ch.propertyName == "isBusy")
                    this.isBusyChanged();
            });
            this.isBusyChanged();
        }
        else {            
            this.isBusy = false;
        }
    }

    isBusyChanged() {
        if (this.isBusy !== this.commandProperty.isBusy) {
            this.isBusy = this.commandProperty.isBusy;
            this.setIsBusy();
        }
    }

    setLinkProperty(newValue:rps.viewmodels.properties.ILinkProperty) {
        this.linkProperty = newValue;
    }
}


