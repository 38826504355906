import {Component, ElementRef, Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsReadOnlyEditor} from './readOnlyEditorBase'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'

@Component({
    selector: 'rps-read-only-address',
    template: `
        <div class="rps-editor" 
             [rpsSemanticState]="rpsModel?.semanticState" 
             [class.rps-disabled-editor]="disabled"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label" 
                    [attr.for]="myId">
                {{rpsLabel}}<font *ngIf="isRequired" color="red"> *</font>
            </label>
            <div class="rps-address-container">
                <div>
                    <div class="rps-editor-editor rps-read-only-editor-viewer rps-read-only-semantic-state">
                        <div class="rps-img-2 rps-address-image">
                            <img class="rps-img" src="{{image}}" />
                        </div>
                        <div>
                            {{description}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsReadOnlyEditor.getComponentInputs()
})
export class rpsReadOnlyAddress extends rpsReadOnlyEditor<any, rps.viewmodels.properties.VMProperty<any>>  {    
    description: string;
    image: string;
    countryText: string;
    countryFlag: string;
    stateText: string;
    countyText: string;
    zipCodeText: string;
    cityText: string;
    addressText: string;

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    onChangeModel() {
        super.onChangeModel();
        if (this.rpsModel) {
            var addressProperty: rps.viewmodels.properties.AddressProperty = <any>this.rpsModel;
            addressProperty.propertyChanged.subscribe((e: rps.viewmodels.properties.VMPropertyChange) => {
                if (e.propertyName == "Text")
                    this.TextChanged(e.newValue);
                if (e.propertyName == "Image")
                    this.ImageChanged(e.newValue);
                if (e.propertyName == "countryText")
                    this.countryTextChanged(e.newValue);
                if (e.propertyName == "countryFlag")
                    this.countryFlagChanged(e.newValue);
                if (e.propertyName == "stateText")
                    this.stateTextChanged(e.newValue);
                if (e.propertyName == "countyText")
                    this.countyTextChanged(e.newValue);
                if (e.propertyName == "zipCodeText")
                    this.zipCodeTextChanged(e.newValue);
                if (e.propertyName == "cityText")
                    this.cityTextChanged(e.newValue);
                if (e.propertyName == "addressText")
                    this.addressTextChanged(e.newValue);
            });
            this.TextChanged(addressProperty.Text);
            this.ImageChanged(addressProperty.Image);
            this.countryTextChanged(addressProperty.countryText);
            this.countryFlagChanged(addressProperty.countryFlag);
            this.stateTextChanged(addressProperty.stateText);
            this.countyTextChanged(addressProperty.countyText);
            this.zipCodeTextChanged(addressProperty.zipCodeText);
            this.cityTextChanged(addressProperty.cityText);
            this.addressTextChanged(addressProperty.addressText);
        }
        else {
            this.description = null;
        }
    }


    TextChanged(newValue) {
        if (this.description !== newValue) {
            this.description = newValue;
        }
    }

    ImageChanged(newValue) {
        if (this.image !== newValue) {
            this.image = newValue;
        }
    }

    countryTextChanged(newValue) {
        if (this.countryText !== newValue) {
            this.countryText = newValue;
        }
    }

    countryFlagChanged(newValue) {
        if (this.countryFlag !== newValue) {
            this.countryFlag = newValue;
        }
    }

    stateTextChanged(newValue) {
        if (this.stateText !== newValue) {
            this.stateText = newValue;
        }
    }

    countyTextChanged(newValue) {
        if (this.countyText !== newValue) {
            this.countyText = newValue;
        }
    }

    zipCodeTextChanged(newValue) {
        if (this.zipCodeText !== newValue) {
            this.zipCodeText = newValue;
        }
    }

    cityTextChanged(newValue) {
        if (this.cityText !== newValue) {
            this.cityText = newValue;
        }
    }

    addressTextChanged(newValue) {
        if (this.addressText !== newValue) {
            this.addressText = newValue;
        }
    }

    //TODO:ToolTip de las direcciones
    //ngAfterContentInit() {        
        //$(this.elementRef.nativeElement).find(".rps-address-c-container").kendoTooltip({});
    //}
}