import { Component, ElementRef, AfterViewInit, forwardRef,ComponentRef, OnDestroy, ViewContainerRef, ViewChild, SimpleChange, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsEditor} from './editorBase'
import { rpsControl } from '../controlBase'

export class baseToggleButton  {
    public rpsButton: rpsToggleButton;

    constructor(protected elementRef: ElementRef) {
    }

    public createButton(rpsButton: rpsToggleButton) {
        this.rpsButton = rpsButton;
        this.createKendoButton($(this.elementRef.nativeElement).find(".rps-toggle-button-button"));
        if (this.rpsButton) {
            this.rpsButton.kendoTooltip = $(this.elementRef.nativeElement).kendoTooltip({
                filter: ".rps-toggle-button-button",
                position: "bottom",
                show: (e) => {
                    //Comprobamos que entre el texto en el boton para mostrar o no el tooltip
                    if (($(e.sender.target())[0].scrollWidth > $(e.sender.target()).innerWidth() || this.rpsButton.rpsTemplate == "ICON") && this.rpsButton.rpsLabel)
                        $(e.sender["content"]).parent().css("visibility", "visible");
                    else
                        this.rpsButton.kendoTooltip.hide();
                },
                content: (e) => {
                    $(e.sender.content).parent().css("visibility", "hidden");
                    return this.rpsButton.rpsLabel;
                },
                showAfter: rps.utils.getTooltipDelay()
            }).data("kendoTooltip");
        }
    }

    protected createKendoButton(element: JQuery) {
        element.kendoButton();
    }    
    mouseLeave() {
        if (this.rpsButton.kendoTooltip)
            this.rpsButton.kendoTooltip.hide();
    }
}

@Component({
    template: `
        <div class="rps-editor-editor rps-toggle-button-button"
             [class.k-state-disabled] = "rpsButton.disabled"
             [class.rps-selected] = "rpsButton.isChecked"
             type="button" 
             [attr.id]="rpsButton.myId"
             (click)="rpsButton.click()"
             (keyup.space)="rpsButton.click()"
             (mouseleave)="mouseLeave()">
            {{rpsButton.rpsLabel}}
        </div>
    `
})
export class textToggleButton extends baseToggleButton {
    constructor(protected elementRef: ElementRef) {
        super(elementRef);
    }
}

@Component({
    template: `
        <div class="rps-editor-editor rps-toggle-button-button"
             [class.k-state-disabled] = "rpsButton.disabled"
             [class.rps-selected] = "rpsButton.isChecked"
             type="button" 
             [attr.id]="rpsButton.myId"
             (click)="rpsButton.click()"
             (keyup.space)="rpsButton.click()"
             (mouseleave)="mouseLeave()">
            <span class="k-icon k-sprite"></span>
        </div>
    `
})
export class iconToggleButton extends baseToggleButton {
    private kendoTooltip: kendo.ui.Tooltip;
    constructor(protected elementRef: ElementRef) {
        super(elementRef);
    }

    protected createKendoButton(element: JQuery) {
        element.kendoButton({
            spriteCssClass: this.rpsButton.rpsIcon
        });       
    }     
}

@Component({
    template: `
        <div class="rps-editor-editor rps-toggle-button-button"           
            [class.k-state-disabled] = "rpsButton.disabled"
            [class.rps-selected] = "rpsButton.isChecked"
            type="button" 
            [attr.id]="rpsButton.myId"
            (click)="rpsButton.click()"
            (keyup.space)="rpsButton.click()"
            (mouseleave)="mouseLeave()">
            <span class="k-icon k-sprite"></span>
            {{rpsButton.rpsLabel}}
        </div>
    `
})
export class textWithIconToggleButton extends baseToggleButton {

    constructor(protected elementRef: ElementRef) {
        super(elementRef);
    }

    protected createKendoButton(element: JQuery) {
        element.kendoButton({
            spriteCssClass: this.rpsButton.rpsIcon
        });
    }
}

@Component({
    selector: 'rps-toggle-button',
    template: `
        <div class="rps-editor">
            <label class="rps-editor-label"></label>
            <div #container></div>
        </div>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsToggleButton) }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsEditor.getComponentInputs(['rpsTemplate', 'rpsIcon'])
})
export class rpsToggleButton extends rpsEditor<boolean> implements AfterViewInit, OnDestroy {
    @ViewChild('container', { read: ViewContainerRef })
    private container: ViewContainerRef;

    public rpsTemplate: string;
    public rpsIcon: string;

    private internalButtonRef: ComponentRef<baseToggleButton>;
    private isChecked: boolean = false;
    public kendoTooltip: kendo.ui.Tooltip;

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef) {
        super(elementRef, changeDetectorRef);
    }

    public click() {
        if (!this.disabled) {
            this.isChecked = !this.isChecked;
            this.updateSourceValue(this.isChecked);
            this._cdr.markForCheck();
        }
    }

    updateTargetValue() {
        this.isChecked = this.value;
        this._cdr.markForCheck();
    }

    ngAfterViewInit() {
        this.createButton();
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        //Se pone el tooltip en el destroy para que cuando se oculte el boton, el tooltip desaparezca
        if (this.internalButtonRef)
            this.internalButtonRef.destroy();
        if (this.kendoTooltip)
            this.kendoTooltip.destroy();

        super.ngOnDestroy();
    }
   
    private createButton() {
        switch (this.rpsTemplate) {
            case "ICON":
                rps.app.uiFactory.createComponent <iconToggleButton>(iconToggleButton, this.container)
                    .then((compRef: ComponentRef<baseToggleButton>) => {
                        compRef.instance.createButton(this);  
                        this.internalButtonRef = compRef;                        
                    });
                break;
            case "TEXT_WITH_ICON":
                rps.app.uiFactory.createComponent <textWithIconToggleButton>(textWithIconToggleButton, this.container)
                    .then((compRef: ComponentRef<baseToggleButton>) => {
                        compRef.instance.createButton(this);
                        this.internalButtonRef = compRef;                        
                    });
                break;
            default:
                rps.app.uiFactory.createComponent <textToggleButton>(textToggleButton, this.container)
                    .then((compRef: ComponentRef<baseToggleButton>) => {
                        compRef.instance.createButton(this);
                        this.internalButtonRef = compRef;
                    });
                break;
        }
    }
    ngOnChanges(changes: { [key: string]: SimpleChange; }) {  
        super.ngOnChanges(changes);     
        if (changes["rpsLabel"]) {
            if (this.kendoTooltip)
                this.kendoTooltip.refresh();
        }
        this._cdr.markForCheck();
    }
}
