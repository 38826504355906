import {Injectable, Inject} from '@angular/core';

@Injectable()
export class rpsQuantityUnitResolver implements rps.services.IQuantityUnitResolver {
    private cache: { [id: string]: rps.data.QuantityUnitAbbreviation }
    configure = () => {
        this.cache = {};
        rps.app.quantityUnitResolver = this;
    }
    resolve(codCompany: string, entityType: string, entityId: string, isSecondaryUnit: boolean): Promise<rps.data.QuantityUnitAbbreviation> {
        var cacheKey = `${codCompany};${entityType};${entityId};${isSecondaryUnit}`;
        if (this.cache[cacheKey])            
            return Promise.resolve<rps.data.QuantityUnitAbbreviation>(this.cache[cacheKey]);
        return rps.app.api.query({
            queryName: "General/QuantityUnitAbbreviation",
            params: {
                CodCompany: codCompany,
                EntityType: entityType,
                EntityId: entityId,
                IsSecondaryUnit: isSecondaryUnit
            }
        }).then((result:rps.data.QuantityUnitAbbreviation) => {
            this.cache[cacheKey] = result;
            return result;
        });
    }
}

@Injectable()
export class rpsPriceUnitResolver implements rps.services.IPriceUnitResolver {
    private cache: { [id: string]: rps.data.PriceUnitAbbreviation }
    configure = () => {
        this.cache = {};
        rps.app.priceUnitResolver = this;
    }
    resolve(codCompany: string, entityType: string, entityId: string): Promise<rps.data.PriceUnitAbbreviation> {
        var cacheKey = `${codCompany};${entityType};${entityId}`;
        if (this.cache[cacheKey])
            return Promise.resolve<rps.data.PriceUnitAbbreviation>(this.cache[cacheKey]);
        return rps.app.api.query({
            queryName: "General/PriceUnitAbbreviation",
            params: {
                CodCompany: codCompany,
                EntityType: entityType,
                EntityId: entityId
            }
        }).then((result: rps.data.PriceUnitAbbreviation) => {
            this.cache[cacheKey] = result;
            return result;
        });
    }
}