import { Component } from '@angular/core';

@Component({
    selector: 'rps-panel',
    template: `
        <div class="rps-layout-padding">
            <div>        
                <div *ngIf="rpsTitle">
                    <h5>
                        {{rpsTitle}}
                    </h5>
                </div>
                <div>
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    `,
    inputs:['rpsTitle']
})
export class rpsPanel  {
    rpsTitle: string;

    constructor() {
    }

}