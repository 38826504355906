import {Directive} from '@angular/core';

@Directive({
    selector: '[rpsSemanticState]',
    inputs: ['rpsSemanticState'],
    host: {
        '[class.rps-semantic-state-positive]':'rpsSemanticState==1',
        '[class.rps-semantic-state-info]':'rpsSemanticState==2',
        '[class.rps-semantic-state-warning]':'rpsSemanticState==3',
        '[class.rps-semantic-state-negative]':'rpsSemanticState==4',
    }
})
export class rpsSemanticState {
    constructor() { }
}