import {Component, ElementRef, Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsReadOnlyEditor} from './readOnlyEditorBase'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'

@Component({
    selector: 'rps-read-only-address-value',
    template: `
        <div class="rps-editor" 
             [rpsSemanticState]="rpsModel?.semanticState" 
             [class.rps-disabled-editor]="disabled"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label" 
                    [attr.for]="myId">
                {{rpsLabel}}<font *ngIf="isRequired" color="red"> *</font>
            </label>
            <div class="rps-editor-editor rps-read-only-editor-viewer rps-read-only-semantic-state">
                <div class="rps-img-2 rps-address-value-image">
                    <img class="rps-img" src="{{image}}" />
                </div>
                <div>
                    {{description}}
                </div>
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsReadOnlyEditor.getComponentInputs()
})
export class rpsReadOnlyAddressValue extends rpsReadOnlyEditor<rps.data.AddressValue, rps.viewmodels.properties.AddressValueProperty>  {    
    description: string;
    image: string;
    countryText: string;
    countryFlag: string;
    stateText: string;
    countyText: string;
    zipCodeText: string;
    cityText: string;
    addressText: string;

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    onChangeModel() {
        super.onChangeModel();
        if (this.rpsModel) {
            var addressValueProperty: rps.viewmodels.properties.AddressValueProperty = <any>this.rpsModel;
            addressValueProperty.propertyChanged.subscribe((e: rps.viewmodels.properties.VMPropertyChange) => {
                if (e.propertyName == "text")
                    this.textChanged(e.newValue);
                if (e.propertyName == "Image")
                    this.ImageChanged(e.newValue);
                if (e.propertyName == "CountryText")
                    this.CountryTextChanged(e.newValue);
                if (e.propertyName == "CountryFlag")
                    this.CountryFlagChanged(e.newValue);
                if (e.propertyName == "StateText")
                    this.StateTextChanged(e.newValue);
                if (e.propertyName == "CountyText")
                    this.CountyTextChanged(e.newValue);
                if (e.propertyName == "ZipCodeText")
                    this.ZipCodeTextChanged(e.newValue);
                if (e.propertyName == "CityText")
                    this.CityTextChanged(e.newValue);
                if (e.propertyName == "AddressText")
                    this.AddressTextChanged(e.newValue);
            });
            this.textChanged(addressValueProperty.text);
            this.ImageChanged(addressValueProperty.Image);
            this.CountryTextChanged(addressValueProperty.CountryText);
            this.CountryFlagChanged(addressValueProperty.CountryFlag);
            this.StateTextChanged(addressValueProperty.StateText);
            this.CountyTextChanged(addressValueProperty.CountyText);
            this.ZipCodeTextChanged(addressValueProperty.ZipCodeText);
            this.CityTextChanged(addressValueProperty.CityText);
            this.AddressTextChanged(addressValueProperty.AddressText);
        }
        else {
            this.textChanged(null);
            this.ImageChanged(null);
            this.CountryTextChanged(null);
            this.CountryFlagChanged(null);
            this.StateTextChanged(null);
            this.CountyTextChanged(null);
            this.ZipCodeTextChanged(null);
            this.CityTextChanged(null);
            this.AddressTextChanged(null);
        }
    }

    textChanged(newValue) {
        if (this.description !== newValue) {
            this.description = newValue;
        }
    }

    ImageChanged(newValue) {
        if (this.image !== newValue) {
            this.image = newValue;
        }
    }

    CountryTextChanged(newValue) {
        if (this.countryText !== newValue) {
            this.countryText = newValue;
        }
    }

    CountryFlagChanged(newValue) {
        if (this.countryFlag !== newValue) {
            this.countryFlag = newValue;
        }
    }

    StateTextChanged(newValue) {
        if (this.stateText !== newValue) {
            this.stateText = newValue;
        }
    }

    CountyTextChanged(newValue) {
        if (this.countyText !== newValue) {
            this.countyText = newValue;
        }
    }

    ZipCodeTextChanged(newValue) {
        if (this.zipCodeText !== newValue) {
            this.zipCodeText = newValue;
        }
    }

    CityTextChanged(newValue) {
        if (this.cityText !== newValue) {
            this.cityText = newValue;
        }
    }

    AddressTextChanged(newValue) {
        if (this.addressText !== newValue) {
            this.addressText = newValue;
        }
    }

    //TODO:ToolTip de las direcciones
    //ngAfterContentInit() { 
        //$(this.elementRef.nativeElement).find(".rps-address-c-container").kendoTooltip({});
    //}
}