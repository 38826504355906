import { ElementRef, AfterViewInit, OnChanges, SimpleChange, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {rpsRowService} from '../layouts/row'

export class rpsReadOnlyEditor<T, TVMProperty extends rps.viewmodels.properties.VMProperty<T>> implements AfterViewInit, OnChanges, OnDestroy {
    //Input
    rpsLabel: string;
    rpsModel: TVMProperty;

    private rpsModelPropertyChangedSubscription: rps.services.ISubscription;

    protected myId: string = rps.guid.newGuid();
    protected value: T;
    protected contentInitialized: boolean = false;
    protected get element(): JQuery {
        return $(this.elementRef.nativeElement);
    }    
    protected get text(): string {
        if (this.rpsModel)
            return this.rpsModel.text;
        else
            return "";
    }    

    //Propiedad creada para poder explotar los recursos desde las plantillas
    get resources() {
        return rps.app.resources;
    }

    constructor(protected elementRef: ElementRef, protected changeDetectorRef: ChangeDetectorRef, private rpsRowService?: rpsRowService) {
    }

    /**
     * Sobreescribir para crear el control de terceros (si hiciese falta)
     */
    createTargetControl() {
    }

    /**
     * Sobreescribir para destruir el control de terceros (si hiciese falta)
     */
    destroyTargetControl() {
    }

    /**
     * Salta cuando se detecta un cambio en rpsValue. Sirve para poder sincronizar (si hace falta) el valor del modelo con el de terceros (si no acepta bindings)
     * @param newValue
     */
    protected updateTargetValue(newValue: T) {
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Método que devuelve la lista de Inputs que necesitan los componentes que heredan de él, más los que le pasen como parámetro
     */
    public static getComponentInputs(extendedInputs?: Array<string>): Array<string> {
        if (extendedInputs)
            return ['rpsLabel', 'rpsModel'].concat(extendedInputs);
        else
            return ['rpsLabel', 'rpsModel'];
    }

    ngAfterViewInit() {
        this.contentInitialized = true;
        this.fillTemplate();
    }

    ngOnDestroy() {
        if (this.rpsRowService)
            this.rpsRowService.labelChanged(this.myId, "")
        this.destroyTargetControl();
        if (this.rpsModelPropertyChangedSubscription)
            this.rpsModelPropertyChangedSubscription.unsubscribe();
        if (this.rpsModel) {
            this.rpsModel = null;
        }
    }

    fillTemplate() {
        this.createTargetControl();
        this.updateTargetValue(this.value);
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes["rpsModel"])
            this.onChangeModel();
        if (changes["rpsLabel"] && this.rpsRowService)
            this.rpsRowService.labelChanged(this.myId, this.rpsLabel);        
    }

    onChangeModel() {
        if (this.rpsModelPropertyChangedSubscription)
            this.rpsModelPropertyChangedSubscription.unsubscribe();

        if (this.rpsModel) {
            this.setValue(this.rpsModel.value);

            this.rpsModelPropertyChangedSubscription = this.rpsModel.propertyChanged.subscribe((e) => {
                this.rpsModelPropertyChanged(e);
            });
        }
        else {
            this.setValue(null);            
        }
    }

    rpsModelPropertyChanged(e: rps.viewmodels.properties.VMPropertyChange) {
        if (e.propertyName == "value")
            this.setValue(this.rpsModel.value);
        this.changeDetectorRef.markForCheck();
    }

    areDistinct(val1: T, val2: T) {
        //Si el primer valor está sin inicializar, se comprueba que sean exactos, para establecer un nulo o un blanco al inicio, por ejemplo
        if (val1 === undefined)
            return val1 !== val2;
        else
            return val1 != val2;
    }

    setValue(newValue:T) {
        if (this.areDistinct(this.value,newValue)) {
            this.value = newValue;
            if (this.contentInitialized)
                this.updateTargetValue(this.value);
        }
    }
}
