import { Injectable, Component, OnDestroy } from '@angular/core';

@Injectable()
export class rpsRowService {
    private _labels: Array<string> = new Array<string>();

    public labeledChanged$: rps.services.IEventEmitter<boolean> = rps.app.eventManager.createEmitter<boolean>();
    constructor() {
    }

    labelChanged(editorID: string, newlabel: string) {
        if (rps.string.isNullOrEmpty(newlabel)) {
            if (this._labels.indexOf(editorID) >= 0) {
                this._labels.splice(this._labels.indexOf(editorID), 1);
                if (this._labels.length == 0)
                    this.labeledChanged$.emit(false);
            }
        }
        else {
            if (this._labels.indexOf(editorID) < 0) {
                this._labels.push(editorID);
                if (this._labels.length == 1)
                    this.labeledChanged$.emit(true);
            }
        }
    }
}

@Component({
    selector: 'rps-row',
    template: `
        <div class="row" 
             [class.rps-editor-long-container]="labeled">
            <ng-content></ng-content>
        </div>
    `,
    providers: [rpsRowService]
})
export class rpsRow implements OnDestroy {
    private subscription: rps.services.ISubscription;

    public labeled: boolean = false;

    constructor(private rpsRowService: rpsRowService) {
        this.subscription = rpsRowService.labeledChanged$.subscribe(
            (labeled: boolean) => {
                this.labeled = labeled;
            });
    }

    ngOnDestroy() {        
        this.subscription.unsubscribe();
    }
}
