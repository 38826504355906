import { Component, ElementRef, forwardRef, Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsEditor} from './editorBase'
import {errorDetailValidator} from '../utils/errorDetailValidator'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'
import { rpsControl } from '../controlBase'

@Component({
    selector: 'rps-select-editor',
    template: `
        <div class="rps-editor" 
            [rpsSemanticState]="rpsModel?.semanticState" 
            [class.rps-disabled-editor]="disabled"
            [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label" 
                   [attr.for]="myId">
                {{rpsLabel}}
            </label>
            <div class="kendoContainer">
                <select class="rps-editor-editor rps-semantic-state" 
                        [attr.id]="myId">
                </select>
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsSelectEditor) }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsEditor.getComponentInputs(["rpsDataSource", "rpsValuePath","rpsDisplayPath"])
})
export class rpsSelectEditor extends rpsEditor<string> {

    private kendoControl: kendo.ui.ComboBox;
    private kendoSourceHTML: string;

    //Propiedad que contiene el comboSource del vmProperty
    private _rpsDataSource: rps.viewmodels.properties.VMProperty<rps.viewmodels.ObservableArray<any>>;
    get rpsDataSource(): rps.viewmodels.properties.VMProperty<rps.viewmodels.ObservableArray<any>>{
        return this._rpsDataSource;
    }
    set rpsDataSource(newValue: rps.viewmodels.properties.VMProperty<rps.viewmodels.ObservableArray<any>>){
        if (this._rpsDataSource != newValue) {
            this._rpsDataSource = newValue;
            if (this.contentInitialized)
                this.updateTargetComboSource(newValue);
        }
    }
    public rpsValuePath: string;
    public rpsDisplayPath: string;

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    createTargetControl() {
        //Busca inputContainer (el div que contiene el input)
        var div = this.element.find(".kendoContainer").first();

        //Se queda con una copia del HTML del input, por si necesita crearlo de nuevo (en caso de que cambien las opciones, por ejemplo)
        if (!this.kendoSourceHTML)
            this.kendoSourceHTML = div.html();

        //Si el control estaba creado, destruirlo y vaciarlo
        if (this.kendoControl) {
            this.kendoControl.destroy();
            div.empty();
            //Volver a crear un input para que pueda llamar al método de Telerik para crear el editor concreto
            div.append(this.kendoSourceHTML);
        }

        //Crear el editor
        this.kendoControl = this.element.find("select").first().kendoComboBox(
            {
                dataValueField: this.rpsValuePath + ".value",
                filter:"contains",
                dataTextField: this.rpsDisplayPath + ".value",
                valuePrimitive: true,
                dataSource: this.rpsDataSource.value,
                change: (e: kendo.ui.ComboBoxChangeEvent) => { this.change(e) },
                value: this.value,
                clearButton:false
            }).data("kendoComboBox");
    }

    destroyTargetControl() {
        if (this.kendoControl)
            this.kendoControl.destroy();
    }

    change(e: kendo.ui.ComboBoxChangeEvent) {
        var comboBox: any = e.sender;
        var selectedItem = null;        
        for (var i = 0; i < this.rpsDataSource.value.length; i += 1) {
            if (this.rpsDataSource.value[i][this.rpsValuePath]["value"] == comboBox.value()) {
                selectedItem = this.rpsDataSource.value[i];
                break;
            }
        }
        if (selectedItem != null)
            this.updateSourceValue(selectedItem[this.rpsValuePath]["value"]);
        else
            this.updateSourceValue(null);
    }

    updateSourceValue(newValue: string) {
        //Si el valor que se quiere establecer en la VMProperty es el que ya tiene
        //Se actualiza el control, ya que es este el que está desincronizado
        if (this.rpsModel && this.rpsModel.value == newValue)
            this.updateTargetValue(newValue);
        else
            super.updateSourceValue(newValue);
    }

    updateTargetComboSource(newValue: rps.viewmodels.properties.VMProperty<rps.viewmodels.ObservableArray<any>>) {
        this.kendoControl.setDataSource(<any>newValue.value);
    }

    updateTargetValue(newValue: string) {
        //HACK: Si está sin definir, se establece un null, porque si no, el widget, se queda el valor…
        if (rps.string.isNullOrEmpty(this.value))
            this.kendoControl.value(null);
        else
            this.kendoControl.value(this.value);
    }

    enableControl() {
        this.kendoControl.enable(true);
    }

    disableControl() {
        this.kendoControl.enable(false);
    }

}

