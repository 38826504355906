import {Component, OnChanges, SimpleChange, ElementRef,OnDestroy} from '@angular/core';
import {NgClass} from '@angular/common';
import {rpsContainerService} from '../layouts/container';
import {rpsSemanticState} from '../utils/semanticState';

@Component({
    selector: 'rps-descriptor',
    template: `
        <div>
            <div class="rps-descriptor" 
                 style="display: none;">
                <div class="rps-descriptor-padding">
                    <div class="rps-stack-panel" >
                        <div class="rps-descriptor-left"
                             *ngIf="descriptor?.Image">
                            <div class="rps-img-container rps-descriptor-img" [rpsImageSize]="4">
                                <img class="rps-img"
                                     src="{{descriptor?.Image}}"
                                     [attr.alt]="descriptor?.MainDescriptor"/>
                             </div>
                        </div>
                        <div class="rps-descriptor-left">
                            <div class="rps-descriptor-div">                 
                                    <h3 *ngIf="descriptor && link" >
                                        <a [routerLink]="linkRouterParams">{{descriptor?.MainDescriptor}}</a>
                                    </h3>
                                    <h3 *ngIf="descriptor && !link" >
                                        {{descriptor?.MainDescriptor}}
                                    </h3>          
                            </div>
                            <div class="rps-descriptor-div">
                                <h4 *ngIf="descriptor?.SecondaryDescriptor != undefined">
                                    {{descriptor?.SecondaryDescriptor}}
                                </h4>
                            </div>
                        </div>
                        <div class="rps-descriptor-left">
                            <div class="rps-descriptor-div">
                                <span [ngClass]="entityStateClass" *ngIf="descriptor?.EntityState != undefined">
                                    {{descriptor?.EntityState.StateName}}
                                </span>
                            </div>
                            <div class="rps-descriptor-div">
                                <h4 *ngIf="descriptor?.EntityDate != undefined"  
                                [rpsSemanticState]="descriptor?.EntityDate?.StateInfo">
                                    {{descriptor?.EntityDate.DateName}} {{entityDateString}}
                                </h4>
                            </div>
                        </div>
                        <div class="rps-descriptor-left">
                            <div class="rps-descriptor-div">
                                <h4 class="rps-descriptor-attribute" 
                                *ngIf="descriptor?.FirstAttribute != undefined">
                                    {{descriptor?.FirstAttribute}}
                                </h4>
                            </div>
                            <div class="rps-descriptor-div">
                                <h4 class="rps-descriptor-attribute" 
                                    *ngIf="descriptor?.SecondAttribute != undefined">
                                    {{descriptor?.SecondAttribute}}
                                </h4>
                            </div>
                        </div>
                        <div class="rps-descriptor-left"
                             *ngIf="descriptor?.EntityAmount != undefined">
                            <div class="rps-descriptor-div rps-descriptor-amount">
                                <h3 *ngIf="descriptor?.EntityAmount != undefined"  
                                [rpsSemanticState]="descriptor?.EntityAmount?.StateInfo">
                                    {{entityAmountString}}
                                </h3>
                                <h4 *ngIf="descriptor?.EntityAmount != undefined">
                                    {{descriptor?.EntityAmount.Unit}}
                                </h4>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    `,
    inputs: ['rpsModel','rpsLink']
})
export class rpsDescriptor implements OnChanges,OnDestroy {
    rpsModel: rps.viewmodels.properties.LookupProperty | rps.data.FullDescriptor;
    public linkRouterParams: Array<any>;

    private rpsModelPropertyChangedSubscription: rps.services.ISubscription;

    private _descriptor: rps.data.FullDescriptor;
    //Si rpsLink es true, veremos el link que tiene el modelo.
    private link: boolean = false;
    public get rpsLink(): string {
        if (this.link)
            return "true";
        else
            return "false";
    }
    public set rpsLink(newValue: string) {
        if (newValue == "true") {
            this.link = true;
        }
        else
            this.link = false;
    }
    get descriptor(): rps.data.FullDescriptor {
        return this._descriptor;
    }
    set descriptor(newValue: rps.data.FullDescriptor) {
        this._descriptor = newValue;
        if (this.descriptor && this.descriptor.EntityState) {
            switch (this.descriptor.EntityState.StateInfo) {
                case rps.data.SemanticState.Negative:
                    this.entityStateClass = "label label-danger";
                    break;
                case rps.data.SemanticState.Neutral:
                    this.entityStateClass = "label label-default";
                    break;
                case rps.data.SemanticState.Positive:
                    this.entityStateClass = "label label-success";
                    break;
                case rps.data.SemanticState.Warning:
                    this.entityStateClass = "label label-warning";
                    break;
                case rps.data.SemanticState.Info:
                    this.entityStateClass = "label label-info";
                    break;
                default:
                    this.entityStateClass = "label";
            }         
        }
        else {
            this.entityStateClass = "";
        }
        //Se refresca el tamaño de la cabecera en asíncrono, para que se cree correctamente el componente y calcule bien la altura
        setTimeout(() => {
            this.containerService.refreshContainer();
        });                
    }
    public entityStateClass: string;
    public entityDateString: string;
    public entityAmountString: string;
    constructor(private containerService: rpsContainerService, private elementRef: ElementRef) {                           
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes["rpsModel"]) {
            if (this.rpsModelPropertyChangedSubscription)
                this.rpsModelPropertyChangedSubscription.unsubscribe();

            this.resolveDescriptor();
            if (this.rpsModel && this.rpsModel instanceof rps.viewmodels.properties.LookupProperty) {
                this.rpsModelPropertyChangedSubscription = this.rpsModel.propertyChanged.subscribe((value: rps.viewmodels.properties.VMPropertyChange) => {
                    if (value.propertyName == "value")
                        this.resolveDescriptor();
                })
            }
        }
    }

    ngOnDestroy() {
        if (this.rpsModelPropertyChangedSubscription)
            this.rpsModelPropertyChangedSubscription.unsubscribe();
    }

    resolveDescriptor() {
        if (this.rpsModel && this.rpsModel instanceof rps.viewmodels.properties.LookupProperty){
            this.rpsModel.getDescriptor().then((descriptor: rps.data.FullDescriptor) => {
                this.setDescriptor(descriptor);
            });
        }
        else if (this.rpsModel) {
            //En el caso de que no sea un LookupProperty, tiene que ser un descriptor
            this.setDescriptor(<rps.data.FullDescriptor>this.rpsModel);
        }
        else {
            this.setDescriptor(null);            
        }
    }

    setDescriptor(descriptor: rps.data.FullDescriptor) {
        this.descriptor = descriptor;

        //Se oculta y se muestra el elemento con Jquery, para que haya desfase con el refresco de la cabecera
        if (this.descriptor && this.descriptor.MainDescriptor) {
            if (this.descriptor.EntityDate && this.descriptor.EntityDate.Date)
                this.entityDateString = kendo.toString(this.descriptor.EntityDate.Date, rps.utils.getPatternDate());
            if (this.descriptor.EntityAmount && this.descriptor.EntityAmount.Amount)
                this.entityAmountString = kendo.toString(this.descriptor.EntityAmount.Amount, rps.utils.getDecimalFormat());

            $(this.elementRef.nativeElement).find(".rps-descriptor").show();
        }
        else
            $(this.elementRef.nativeElement).find(".rps-descriptor").hide();
        this.containerService.refreshContainer();

        //crear el link de navegacion
        if (descriptor && descriptor.UILink) {
            var link = rps.app.stateManager.createRouterLink(descriptor.UILink);
            this.linkRouterParams = link.routerLink;
        }
    }
}