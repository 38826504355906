import {Injectable, Type, Component, Injector, ElementRef, AfterViewInit,
    OnDestroy, OpaqueToken, ComponentRef, ViewChild, ViewContainerRef,Inject} from '@angular/core';

@Component({
    selector: 'rps-custom-editor',
    template: `
        <div #dynamicContainer>
        </div>
    `,
    inputs: ['rpsModel']
})
export class rpsCustomEditor implements OnDestroy {
    @ViewChild('dynamicContainer', { read: ViewContainerRef })
    private dynamicContainer: ViewContainerRef;
    private dynamicComponent: ComponentRef<any>;

    private _rpsModel: rps.viewmodels.properties.CustomComponentHandler;
    public get rpsModel(): rps.viewmodels.properties.CustomComponentHandler {
        return this._rpsModel;
    }
    public set rpsModel(newValue: rps.viewmodels.properties.CustomComponentHandler) {
        this.setViewModel(newValue);
    }

    constructor() {
    }

    ngOnDestroy() {
        if (this.dynamicComponent)
            this.dynamicComponent.destroy();

        if (this.dynamicContainer && this.dynamicContainer.element && this.dynamicContainer.element.nativeElement)
            $(this.dynamicContainer.element.nativeElement).empty();

    }

    setViewModel(newValue: rps.viewmodels.properties.CustomComponentHandler) {
        if (this._rpsModel != newValue) {
            this._rpsModel = newValue;

            if (this._rpsModel) {
                this.ngOnDestroy();

                this._rpsModel.getComponent().then((componentType) => {
                    if (componentType != null) {
                        this._rpsModel.getVM().then((vm) => {
                            rps.app.uiFactory.createComponent<any>(componentType, this.dynamicContainer).then((component: ComponentRef<any>) => {
                                this.dynamicComponent = component;

                                component["vm"] = vm;
                                //HACK: Si contiene este método, se le invoca, para modificar el DOM en el VM, rompiendo el patrón...
                                if ((<any>vm).afterContentInit)
                                    (<any>vm).afterContentInit(component);
                            });
                        });
                    }
                    else{
                        this._rpsModel.getVM().then((vm) => {
                            this._rpsModel.getHTML().then((html) => {
                                rps.app.uiFactory.createComponentRefFromTemplate<any>(html, vm, this.dynamicContainer).then((component: ComponentRef<any>) => {
                                    this.dynamicComponent = component;

                                    //HACK: Si contiene este método, se le invoca, para modificar el DOM en el VM, rompiendo el patrón...
                                    if ((<any>vm).afterContentInit)
                                        (<any>vm).afterContentInit(component);
                                });
                            });
                        });
                    }
                });
            }
        }
    }
}

