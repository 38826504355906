import {Injectable, Inject} from '@angular/core';

export abstract class rpsStorage implements rps.services.IStorage {

    constructor(storage: Storage) {
        this.workingStorage = storage;
    }

    protected workingStorage: Storage;
    private prefix: string = "rps";

    abstract configure();

    // Checks the browser to see if local storage is supported
    browserSupportsStorage(): boolean {
        try {
            if (this.workingStorage === localStorage) {
                return ('localStorage' in window && window['localStorage'] !== null);
            } else if (this.workingStorage === sessionStorage) {
                return ('sessionStorage' in window && window['sessionStorage'] !== null);
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }

    // Directly adds a value to local storage
    // Example use: localStorageService.add('library','angular');
    add(key: string, value: any) {

        // If this browser does not support local storage use cookies
        if (!this.browserSupportsStorage()) {
            return false;
        }

        // Let's convert undefined values to null to get the value consistent
        if (typeof value === "undefined") {
            value = null;
        }

        try {
            if (rps.object.isObject(value) || rps.object.isArray(value)) {
                value = JSON.stringify(value);
            }
            this.workingStorage.setItem(this.prefix + key, value);
        } catch (e) {
            return false;
        }
        return true;
    }

    // Directly get a value from local storage
    // Example use: localStorageService.get('library'); // returns 'angular'
    "get"(key: string): any {
        if (!this.browserSupportsStorage()) {
            return false;
        }


        var item = this.workingStorage.getItem(this.prefix + key);
        // angular.toJson will convert null to 'null', so a proper conversion is needed
        // FIXME not a perfect solution, since a valid 'null' string can't be stored
        if (!item || item === 'null') return null;


        if (item.charAt(0) === "{" || item.charAt(0) === "[") {
            return JSON.parse(item);
        }
        return item;
    }

    // Remove an item from local storage
    // Example use: localStorageService.remove('library'); // removes the key/value pair of library='angular'
    remove(key) {
        if (!this.browserSupportsStorage()) {
            return false;
        }


        try {
            this.workingStorage.removeItem(this.prefix + key);
        } catch (e) {
            return false;
        }
        return true;
    }

    // Remove all data for this app from local storage
    // Example use: localStorageService.clearAll();
    // Should be used mostly for development purposes
    clearAllFromStorage() {

        if (!this.browserSupportsStorage()) {
            return false;
        }

        var prefixLength = this.prefix.length;

        for (var key in this.workingStorage) {
            // Only remove items that are for this app
            if (key.substr(0, prefixLength) === this.prefix) {
                try {
                    this.remove(key.substr(prefixLength));
                } catch (e) {
                    return false;
                }
            }
        }
        return true;
    }
}

@Injectable()
export class rpsSessionStorage extends rpsStorage {
    constructor() {
        super( sessionStorage);
    }

    configure() {
        rps.app.sessionStorage = this;
    }
}

@Injectable()
export class rpsLocalStorage extends rpsStorage {
    constructor() {
        super( localStorage);
    }

    configure() {
        rps.app.localStorage = this;
    }
}

