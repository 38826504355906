import { Component, ElementRef, forwardRef,ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsEditor} from './editorBase'
import {errorDetailValidator} from '../utils/errorDetailValidator'
import {rpsSemanticState} from '../utils/semanticState'
import { rpsControl } from '../controlBase'

//Se le añade la clase "k-input", para que la grid (método isInputElement) sea consciente de que es un editor
@Component({
    selector: 'rps-check-box',
    template: `
        <div class="rps-editor" 
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label"></label>
            <div class="rps-checkbox-container"  
                [rpsSemanticState]="rpsModel?.semanticState" 
                [class.rps-checkbox-disabled]="disabled">
                <input class="rps-checkbox"
                       type="checkbox" 
                       [attr.id]="myId"
                       [disabled]="disabled"
                       (change) = "checkedChanged()"
                        tabindex="-1">
                <label class="rps-checkbox-label k-input" 
                       (keydown.space)="checkSpaceBar($event)"
                       [attr.for]="myId"
                       tabindex="0"
                       [attr.tabindex]="tabIndexVal()">
                    {{rpsLabel}}
                </label>
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsCheckBox) }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsEditor.getComponentInputs()
})
export class rpsCheckBox extends rpsEditor<boolean> {

    get inputCheckValue(): boolean {
        return this.element.find("input").prop("checked");
    }
    set inputCheckValue(newValue: boolean) {
        this.element.find("input").prop("checked", newValue);
    }

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef) {
        super(elementRef, changeDetectorRef);
    }

    updateTargetValue() {
        this.inputCheckValue = this.value;        
    }

    checkedChanged() {
        this.updateSourceValue(this.inputCheckValue);
    }

    checkSpaceBar(event: KeyboardEvent) {
        event.preventDefault();
        if (!this.disabled) {
            if (this.inputCheckValue)
                this.rpsModel.value = false;
            else
                this.rpsModel.value = true;
        }
    }

    tabIndexVal() {
        if (this.disabled) {
            return null;
        } else {
            return 0;
        }
    }

    //Método que invoca la grid, para forzar que el editor recoja el foco
    setFocusAfterInit() {
        $(this.elementRef.nativeElement).find(".rps-checkbox-label").focus();
    }
}
