import {Component, ElementRef, Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsReadOnlyEditor} from './readOnlyEditorBase'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'

@Component({
    selector: 'rps-read-only-formatted-number',
    template: `
        <div class="rps-editor" 
             [rpsSemanticState]="rpsModel?.semanticState"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label">
                {{rpsLabel}}
            </label>
            <div class="rps-editor-editor rps-read-only-editor-viewer rps-read-only-semantic-state rps-read-only-number-editor-viewer">
                {{formattedNumber}} <span *ngIf="value?.Suffix" class="rps-formatted-number-suffix">{{value.Suffix}}</span>
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsReadOnlyEditor.getComponentInputs()
})
export class rpsReadOnlyFormattedNumber extends rpsReadOnlyEditor<rps.data.FormattedNumber, rps.viewmodels.properties.FormattedNumberProperty>  {

    formattedNumber: string = "";

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    setValue(newValue: rps.data.FormattedNumber) {
        super.setValue(newValue);

        if (newValue) {

            var format: string = rps.utils.getDecimalFormat();
            //if (this.value)
            //    format = rps.utils.getDecimalFormat({ suffix: this.value.Suffix});                
            //else
            //    format = rps.utils.getDecimalFormat();
            this.formattedNumber = kendo.toString(this.value.Value, format)
        }
        else {
            this.formattedNumber = "";
        }
    }
}