import {Directive} from '@angular/core';

@Directive({
    selector: '[rpsSemanticStateColumn]',
    inputs: ['rpsSemanticStateColumn'],
    host: {
        '[class.rps-semantic-state-grid-positive]': 'rpsSemanticStateColumn==1',
        '[class.rps-semantic-state-grid-info]': 'rpsSemanticStateColumn==2',
        '[class.rps-semantic-state-grid-warning]': 'rpsSemanticStateColumn==3',
        '[class.rps-semantic-state-grid-negative]': 'rpsSemanticStateColumn==4',
    }
})
export class rpsSemanticStateColumn {
    constructor() {}  
}