import {ElementRef, AfterViewInit, OnChanges, SimpleChange, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {rpsRowService} from '../layouts/row'
import { rpsControl } from '../controlBase'

export class rpsEditor<T> extends rpsControl implements AfterViewInit, OnChanges, OnDestroy {
    //Input
    rpsLabel: string;
    rpsModel: rps.viewmodels.properties.VMProperty<T>;

    private rpsModelPropertyChangedSubscription: rps.services.ISubscription;
    private rpsModelPropertyChangeCancelledSubscription: rps.services.ISubscription;
    protected myId: string = rps.guid.newGuid();
    //Propiedad que indica que está pendiente de forzar el foco después de iniciarse
    protected focusAfterInit: boolean = false;
    protected value: T;
    protected contentInitialized: boolean = false;    
    protected isRequired: boolean = false;   
    protected get element(): JQuery {
        return $(this.elementRef.nativeElement);
    }

    public disabled: boolean = false;

    //Propiedad creada para poder explotar los recursos desde las plantillas
    get resources() {
        return rps.app.resources;
    }

    constructor(protected elementRef: ElementRef, protected _cdr: ChangeDetectorRef, private rpsRowService?: rpsRowService) {
        super();
    }

    /**
     * Sobreescribir para crear el control de terceros (si hiciese falta)
     */
    createTargetControl() {
    }

    /**
     * Sobreescribir para destruir el control de terceros (si hiciese falta)
     */
    destroyTargetControl() {
    }

    /**
     * Salta cuando se detecta un cambio en rpsValue. Sirve para poder sincronizar (si hace falta) el valor del modelo con el de terceros (si no acepta bindings)
     * @param newValue
     */
    protected updateTargetValue(newValue: T) {
    }

    protected enableControl() { };
    protected disableControl() { };

    /**
     * Llamado para poder sincronizar el modelo con el valor del control
     * @param newValue
     */
    protected updateSourceValue(newValue: T) {
        if (this.rpsModel) {
            //En el caso de que devuelva false, se revierte el valor en el control, ya que no se ha establecido, de esta manera se evita, que el usuario vacíe campos obligatorios entre otras cosas
            if (!this.rpsModel.userSetValue(newValue))
                this.updateTargetValue(this.rpsModel.value);
        }
    }

    /**
     * Método que devuelve la lista de Inputs que necesitan los componentes que heredan de él, más los que le pasen como parámetro
     */
    public static getComponentInputs(extendedInputs?: Array<string>): Array<string> {
        if (extendedInputs)
            return ['rpsLabel', 'rpsModel'].concat(extendedInputs);
        else
            return ['rpsLabel', 'rpsModel'];
    }

    ngAfterViewInit() {
        this.contentInitialized = true;
        this.fillTemplate();
    }

    ngOnDestroy() {
        if (this.rpsRowService)
            this.rpsRowService.labelChanged(this.myId, "")
        this.destroyTargetControl();
        if (this.rpsModelPropertyChangedSubscription)
            this.rpsModelPropertyChangedSubscription.unsubscribe();
        if (this.rpsModelPropertyChangeCancelledSubscription)
            this.rpsModelPropertyChangeCancelledSubscription.unsubscribe();
        if (this.rpsModel) {
            this.rpsModel = null;
        }
    }

    fillTemplate() {
        this.createTargetControl();
        this.updateTargetValue(this.value);
        if (this.disabled)
            this.disableControl();
        else
            this.enableControl();
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes["rpsModel"])
            this.onChangeModel();
        if (changes["rpsLabel"] && this.rpsRowService)
            this.rpsRowService.labelChanged(this.myId, this.rpsLabel);        
    }

    onChangeModel() {
        if (this.rpsModelPropertyChangedSubscription)
            this.rpsModelPropertyChangedSubscription.unsubscribe();

        if (this.rpsModelPropertyChangeCancelledSubscription)
            this.rpsModelPropertyChangeCancelledSubscription.unsubscribe();

        if (this.rpsModel) {
            this.setValue(this.rpsModel.value,false);            
            this.setDisabled(this.rpsModel.isLocked);
            this.isRequired = this.rpsModel.isRequired;

            this.rpsModelPropertyChangedSubscription = this.rpsModel.propertyChanged.subscribe((e) => {
                this.rpsModelPropertyChanged(e);
            });

            this.rpsModelPropertyChangeCancelledSubscription = this.rpsModel.valueChangeCancelled.subscribe(() => {
                this.setValue(this.rpsModel.value,true);
            });
        }
        else {
            this.setValue(null,false);            
            this.setDisabled(null);
            this.isRequired = null;            
        }
    }

    rpsModelPropertyChanged(e: rps.viewmodels.properties.VMPropertyChange) {
        if (e.propertyName == "value")
            this.setValue(this.rpsModel.value,false);
        else if (e.propertyName == "isLocked")
            this.setDisabled(this.rpsModel.isLocked);
        else if (e.propertyName == "isRequired")
            this.isRequired = this.rpsModel.isRequired;
        this._cdr.markForCheck();
    }

    areDistinct(val1: T, val2: T) {
        //Si el primer valor está sin inicializar, se comprueba que sean exactos, para establecer un nulo o un blanco al inicio, por ejemplo
        if (val1 === undefined)
            return val1 !== val2;
        else
            return val1 != val2;
    }

    setValue(newValue:T,forceSet: boolean) {
        if (forceSet || this.areDistinct(this.value,newValue)) {
            this.value = newValue;
            if (this.contentInitialized)
                this.updateTargetValue(this.value);
            this._cdr.markForCheck();
        }
    }

    setDisabled(newValue: boolean) {
        if (newValue != this.disabled) {
            this.disabled = newValue;
            if (this.contentInitialized) {
                if (this.disabled)
                    this.disableControl();
                else
                    this.enableControl();
            }
            this._cdr.markForCheck();
        }
    }

    //Método que invoca la grid, para forzar que el editor recoja el foco
    setFocusAfterInit() {
        $(this.elementRef.nativeElement).find("input").focus();
    }

    /**
    * Sobreescrito para establecer el foco a un elemento intero del componente. Por defecto, establece el foco al primer input (si existe)
    * @internal
    */
    protected setFocus() {
        //Buscar el primer input y poner el foco
        $(this.elementRef.nativeElement).find('input').first().focus();
    }

    focus(): boolean {
        if (this.rpsModel && !this.rpsModel.isLocked) {
            if (this.contentInitialized)
                this.setFocus();
            else
                this.setFocusAfterInit();
            return true;
        }
        else
            return false;

    }
}

export class rpsFocusableEditor<T> extends rpsEditor<T>{
    private _isEditing = false;
    protected get isEditing(): boolean {
        return this._isEditing;
    }
    protected set isEditing(newValue: boolean) {
        this._isEditing = newValue;

        if (this._isEditing) {
            this._cdr.markForCheck();
            setTimeout(() => {
                this.createEditableTargetControl();
                if (this.disabled)
                    this.disableControl();
                else
                    this.enableControl();
            });
        }
        else{
            this.destroyEditableTargetControl();
            this._cdr.markForCheck();
        }
    }

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    /**
     * Sobreescribir para crear el control de terceros (si hiciese falta)
     */
    createEditableTargetControl() {
    }

    /**
     * Sobreescribir para destruir el control de terceros (si hiciese falta)
     */
    destroyEditableTargetControl() {
    }
}
