import { Component, OnChanges, SimpleChange, ElementRef } from '@angular/core';

@Component({
    selector: 'rps-busy-indicator',
    template: `
        <div class="rps-div">
            <ng-content></ng-content>        
        </div>
    `,
    inputs: ['rpsIsBusy']
})
export class rpsBusyIndicator implements OnChanges {
    rpsIsBusy: boolean = false;
    constructor(protected elementRef: ElementRef) {
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes["rpsIsBusy"])
            kendo.ui.progress($(this.elementRef.nativeElement), this.rpsIsBusy);
    }
}
