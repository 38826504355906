import { Component, ElementRef, forwardRef, Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsEditor} from './editorBase'
import {errorDetailValidator} from '../utils/errorDetailValidator'
import {rpsRowService} from '../layouts/row'
import {rpsRowsGroup} from '../layouts/rowsGroup'
import {rpsRow} from '../layouts/row'
import {rpsColumns} from '../layouts/columns'
import {rpsButton} from './button'
import {rpsSemanticState} from '../utils/semanticState'
import { rpsControl } from '../controlBase'

@Component({
    selector: 'rps-image-editor',
    template: `
        <div class="rps-editor rps-image-editor"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors" 
            [rpsSemanticState]="rpsModel?.semanticState" 
            [class.rps-disabled-editor]="disabled">
            <label class="rps-editor-label"
                   [attr.for]="myId">
                {{rpsLabel}}<font *ngIf="isRequired" color="red"> *</font>
            </label>
            <div>
            <div class="rps-image-editor-button rps-semantic-state rps-img-5"                
                 [attr.id]="myId">             
                    <img class="rps-img" src="{{value}}"/>                     
            </div>
        </div>
        <div id="uploadWindow">
            <div>
                <form name="form">
                    <rps-rows-group>
                        <rps-row>
                            <div class="rps-img-editor-preview">
                                <img rpsColumns="12" src="{{value}}"/>
                            </div>
                        </rps-row>
                        <rps-row>
                            <input type="file"                                   
                                   id="kendoUpload"
                                   rpsColumns="12"/>
                        </rps-row>
                        <rps-row>
                            <rps-button rpsColumns="4"
                                        [rpsLabel]="resources.directives.CHANGE_IMAGE"
                                        [rpsModel]="upload"
                                        rpsType="PRIMARY">
                            </rps-button>
                            <rps-button rpsColumns="4"
                                        [rpsLabel]="resources.directives.REMOVE_IMAGE"
                                        [rpsModel]="remove"
                                        rpsType="DANGER">
                            </rps-button>
                            <rps-button rpsColumns="4"
                                        [rpsLabel]="resources.directives.CANCEL"
                                        [rpsModel]="cancelUpload">
                            </rps-button>
                        </rps-row>
                    </rps-rows-group>
                </form>            
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
    `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsImageEditor) }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsEditor.getComponentInputs()
})
export class rpsImageEditor extends rpsEditor<string>  {
    private kendoWindow: kendo.ui.Window;
    private kendoButton: kendo.ui.Button;
    private files: Array<any>;    

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    createTargetControl() {
        this.kendoButton = this.element.find(".rps-image-editor-button").kendoButton({
            click: this.kendoButtonClick
        }).data("kendoButton");
        this.element.find("#kendoUpload").kendoUpload({
            multiple: false,
            select: this.kendoUploadSelect
        });
        this.kendoWindow = this.element.find("#uploadWindow").kendoWindow({
            modal: true,
            title: rps.app.resources.directives.IMAGE_EDITOR,
            width: 600,
            visible:false
        }).data("kendoWindow");

        //Si estamos en modo edición de la grid, hay que introducirle el foco al editor, ya que significa que hemos entrado en modo edición de la celda
        if (rps.ui.grid.isInEditMode) {
            $(this.elementRef.nativeElement).find(".k-button").focus();
            rps.ui.grid.isInEditMode = false;
        }
    }

    destroyTargetControl() {
        if (this.kendoWindow) {
            this.kendoWindow.destroy();
            this.kendoWindow = null;
            this.element.find("#uploadWindow").first().empty();
        }
        if (this.kendoButton) {
            this.kendoButton.destroy();
            this.kendoButton = null;
            this.element.find(".rps-image-editor-button").first().empty();
        }
    }

    kendoButtonClick = (e: kendo.ui.ButtonClickEvent) => {
        if (!this.disabled) {
            if (!this.unsavedEntity())
                this.kendoWindow.center().open();
            else
                rps.app.messageManager.show({
                    message: rps.app.resources.messages.MSG_ITEM_SAVED_TO_UPDATE_IMAGE,
                    messageButton: rps.services.MessageButton.Ok,
                    messageType: rps.services.MessageType.Info
                });
        }
    }

    unsavedEntity():boolean {
        //En el caso de que el valor esté en blanco, la imagen no puede ser modificada, ya que no contiene la URL a la que subirla…
        //Esto sucede en entidades nuevas que no tienen el ID autogenerado
        if (rps.string.isNullOrEmpty(this.value))
            return true;
        else
            return false;
    }

    kendoUploadSelect = (e: kendo.ui.UploadSelectEvent) => {
        this.files = e.files;
        this._cdr.markForCheck();
    }

    upload = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: (): Promise<any> => {
            return new Promise<any>((resolve) => {
                rps.app.api.uploadImage({
                    url: this.value,
                    file: this.files[0].rawFile
                }).then((response) => {                        
                    this.closeWindow();
                    rps.app.notificationManager.show({
                        message: "Image uploaded",
                        notificationType: rps.services.NotificationType.Success
                    });

                    //Al subir una imagen de una entidad, el servicio responde con un 201 y una nueva URL
                    //Se establece la nueva URL en la propiedad del componente, para que no se marque la entidad como modificada
                    this.updateSourceValue(response);
                    //En el caso de que el VM que contiene la image, sea de tipo Entity, se refresca el descriptor, ya que este normalmente muestra la imagen
                    if (this.rpsModel.getTarget() instanceof rps.viewmodels.MainEntityVM)
                        (<rps.viewmodels.MainEntityVM<rps.entities.IBaseEntity>>this.rpsModel.getTarget()).createDescriptor();
                    else if (this.rpsModel.getTarget() instanceof rps.viewmodels.EntityVM)
                        (<rps.viewmodels.EntityVM<rps.entities.IBaseEntity>>this.rpsModel.getTarget()).createDescriptor();
                    this._cdr.markForCheck();
                    resolve(this);
                }).catch((error) => {
                    rps.app.errorManager.showError(error);
                    resolve(this);
                });
            });
        },
        canExecute: () => {
            if (this.files && this.files.length == 1 && this.value && this.value.length > 0) {
                return rps.viewmodels.commands.CanExecuteResult.allow();
            }
            return rps.viewmodels.commands.CanExecuteResult.deny([]);
        }
    });

    remove = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: (): Promise<any> => {
            return rps.app.api.removeImage({
                url: this.value
            }).then((location) => {
                this.closeWindow();
                //Al subir una imagen de una entidad, el servicio responde con un 201 y una nueva URL
                //Se establece la nueva URL en la propiedad del componente, para que no se marque la entidad como modificada
                this.updateSourceValue(location);
                //En el caso de que el VM que contiene la image, sea de tipo Entity, se refresca el descriptor, ya que este normalmente muestra la imagen
                if (this.rpsModel.getTarget() instanceof rps.viewmodels.MainEntityVM)
                    (<rps.viewmodels.MainEntityVM<rps.entities.IBaseEntity>>this.rpsModel.getTarget()).createDescriptor();
                else if (this.rpsModel.getTarget() instanceof rps.viewmodels.EntityVM)
                    (<rps.viewmodels.EntityVM<rps.entities.IBaseEntity>>this.rpsModel.getTarget()).createDescriptor();
                this._cdr.markForCheck();
            });
        }
    });

    cancelUpload = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: (): Promise<any> => {
            this.closeWindow();
            return Promise.resolve<any>(this);
        }
    });

    closeWindow = () => {        
        this.kendoWindow.close();
    }

    pad(number): string {
        var r = String(number);
        if (r.length === 1) {
            r = '0' + r;
        }
        return r;
    }

    getTodayString(): string {
        var today: Date = rps.date.today();
        return today.getFullYear()
            + this.pad(today.getMonth() + 1)
            + this.pad(today.getDate())
            + this.pad(today.getHours())
            + this.pad(today.getMinutes())
            + this.pad(today.getSeconds())
            + this.pad(today.getMilliseconds());
    }

    //Método que invoca la grid, para forzar que el editor recoja el foco
    setFocusAfterInit() {
        rps.ui.grid.isInEditMode = true;
    }
}