import { Component, OnChanges, SimpleChange,
    OnInit, ElementRef, OnDestroy, Injectable} from '@angular/core';
import {ColumnsManagerService } from './columns';

@Injectable()
export class rpsViewService {
    visible: boolean = true;
    visibleChanged$: rps.services.IEventEmitter<boolean> = rps.app.eventManager.createEmitter<boolean>();;    

    constructor() {
    }

    setVisible(value: boolean) {        
        if (this.visible != value) {
            this.visible = value;
            this.visibleChanged$.emit(value);
        }
    };
}

@Component({
    selector: 'rps-view',
    template: `
        <div *ngIf="rpsVisible">
            <form (ngSubmit)="rpsModel.onSubmit()" 
                  (keyup.enter)="viewForm.ngSubmit.emit()" 
                  #viewForm="ngForm">                
                <ng-content></ng-content>
            </form>
        </div>
    `,
    inputs: ['rpsModel', 'rpsVisible', 'rpsTitle'],
    providers: [rpsViewService]
})
export class rpsView implements OnChanges {
    rpsModel: rps.viewmodels.BaseVM;
    rpsVisible: boolean = true;
    rpsTitle: any;

    constructor(private viewService: rpsViewService) {
        this.viewService.setVisible(this.rpsVisible);
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }): any {
        if (changes["rpsModel"] || changes["rpsTitle"])
            this.refreshrpsTitle();
        if (changes["rpsModel"] || changes["rpsVisible"])
            this.refreshVisibility();
    }

    private refreshVisibility() {
        this.viewService.setVisible(this.rpsVisible);
    }

    private refreshrpsTitle() {
        if (this.rpsModel && this.rpsTitle) {
            this.rpsModel.setTitle(this.rpsTitle);
        }
    }
}

@Component({
    selector: 'rps-dialog-view',
    template: `
        <div>
            <div id="windowContent" class="rps-window" (window:resize)="onResize($event)">
                <rps-error-details-viewer [rpsErrorDetails]="errorManager?.dialogErrorDetails"></rps-error-details-viewer>
                <form (ngSubmit)="rpsModel.onSubmit()" 
                      (keyup.enter)="viewForm.ngSubmit.emit()" 
                      #viewForm="ngForm">                    
                    <ng-content></ng-content>
                </form>
            </div>
        </div>
    `,
    inputs: ['rpsModel', 'rpsTitle', 'rpsStyle', 'rpsHorizontalSize'],
    providers: [ColumnsManagerService]
})
export class rpsDialogView implements OnChanges, OnInit, OnDestroy {
    rpsModel: rps.viewmodels.BaseVM;
    rpsTitle: any;
    rpsHorizontalSize: string;
    rpsStyle: number;

    private resolvedTitle: string = "";
    private kendoWindow: kendo.ui.Window;
    private maxColumns: number = 12;
    private windowWidth: number;
    private position: kendo.ui.WindowPosition = {
        top: rps.utils.getWindowTop(),
        left: "60px"
    };
    private titlePropertyChanged: rps.services.ISubscription;
    
    public get errorManager():rps.services.IErrorManager {
        return rps.app.errorManager;
    }

    constructor(private elementRef: ElementRef, private columnsManagerService: ColumnsManagerService) {
        //Se limpia la lista de errores modales, por si tenía errores de la ventana principal
        rps.app.errorManager.clearDialogErrorDetails(); 
    }

    //Se hace en el OnInit, para que los componentes hijos, obtengan la anchura correctamente, 
    //si no, la grid, por ejemplo, se dibuja sin tener en cuenta los márgenes de la ventana
    ngOnInit() {
        this.setWindowSize();
        this.createKendoWindow();
    }

    createKendoWindow() {        
        var windowContainer = $(this.elementRef.nativeElement).find("#windowContent");
        this.kendoWindow = windowContainer.kendoWindow({
            modal: true,
            close: (e) => { this.close(e)},
            title: this.resolvedTitle,
            actions: ['Close'],
            position: this.position,
            width: this.windowWidth,
            resizable: true,
            maxHeight: rps.utils.getWindowMaxHeight()
        }).data("kendoWindow");
    }

    close(e: kendo.ui.WindowCloseEvent) {
        e.preventDefault();
        //rps.app.stateManager.goToParent();
        rps.app.stateManager.goBack();
    }

    ngOnDestroy() {
        if (this.kendoWindow)
            this.kendoWindow.destroy();
        if (this.titlePropertyChanged)
            this.titlePropertyChanged.unsubscribe();
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }): any {
        if (changes["rpsModel"] || changes["rpsTitle"])
            this.refreshrpsTitle();
        if (changes["rpsHorizontalSize"]) {
            if (rps.object.isNumeric(this.rpsHorizontalSize)) {
                //enviamos al servicio el tamaño para que la columna lo recoja
                this.columnsManagerService.setColumnSize(this.rpsHorizontalSize);
            }
        }
    }

    private refreshrpsTitle() {
        if (this.rpsModel && this.rpsTitle) {
            this.rpsModel.setTitle(this.rpsTitle);
            //Después de establecer la vmProperty al vm, se resuelve su valor, para establecerlo como título de la ventana
            this.rpsModel.title.formatValue().then((formatValue: string) => {
                this.resolvedTitle = formatValue;
                if (this.kendoWindow)
                    this.kendoWindow.title(this.resolvedTitle);                
            });
            if (this.titlePropertyChanged)
                this.titlePropertyChanged.unsubscribe();
            this.titlePropertyChanged = this.rpsModel.title.propertyChanged.subscribe((value: rps.viewmodels.properties.VMPropertyChange) => {
                if (value.propertyName == "text")
                    this.kendoWindow.title(this.rpsModel.title.text);
            });
        }
    }

    onResize(event) {
        this.setWindowSize();
        this.kendoWindow.setOptions({ width: this.windowWidth, position: this.position });
    }

    setWindowSize() {
        //Ancho de cada columna
        var colWidth: number = $(window).width() / this.maxColumns;
        //Ancho de cada columna por el numero de columnas, quitamos 120 pixeles para que no tengamos desplazamiento lateral
        this.windowWidth = (colWidth * this.maxColumns) - 120;
        //Centramos la ventana
        this.position.left = ($(window).width() - this.windowWidth) / 2;
        if (rps.object.isNumeric(this.rpsHorizontalSize)) {
            if (+this.rpsHorizontalSize > 0 && +this.rpsHorizontalSize < (this.maxColumns)) {
                //Si el el numero de columnas está entre 1 y 12, aplicamos el nuevo tamaño en funcion del numero de columnas
                this.windowWidth = (colWidth * +this.rpsHorizontalSize);
                this.position.left = ($(window).width() - this.windowWidth) / 2;
            }
        } else if (this.rpsHorizontalSize == "*") {
            //Si el numero de columnas es un "*" se ajusta el tamaño al contenido de la ventana
            this.windowWidth = 0;
            this.position.left = "60px";
        }
    }
}
