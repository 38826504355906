import {Http, Response, Headers, URLSearchParams} from '@angular/http';
import {Injectable, Inject} from '@angular/core';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import {rpsAppSettings} from './appSettings';
import {rpsStateManager} from './stateManager';

@Injectable()
export class rpsClientAPI implements rps.services.IClientAPI {

    constructor(private _http: Http,private appSettings: rpsAppSettings, private _rpsStateManager: rpsStateManager) {
    }

    configure = () => {
        rps.app.clientAPI = this;
    }

    public get<T>(params: { url: string; queryParams?: any; }): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            var url: string = this.appSettings.rpsAPIAddress + "clientapi/" + params.url;
            var searchParams = new URLSearchParams();
            if (params.queryParams) {
                for (var param in params.queryParams)
                    searchParams.set(param, params.queryParams[param]);
            }
            this._http.get(
                url,
                {
                    body: '',
                    search: searchParams,
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    })
                }).map((res: Response, ix?: number) => {
                    return rps.services.API.deserialize(res.text());
                }).subscribe((data: T) => {
                    resolve(data);
                }, (res:Response) => {
                    rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                });
        });
    }

    public put(params: {
        url: string;
        body: any;
    }): Promise<rps.services.API.IResponse> {
        return new Promise<rps.services.API.IResponse>((resolve, reject) => {
            var resolvedUrl: string = this.appSettings.rpsAPIAddress + "clientapi/" + params.url;
            this._http.request(
                resolvedUrl,
                {
                    body: rps.services.API.serialize(params.body),
                    method: 'PUT',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Company': this._rpsStateManager.getCurrentCompany()
                    })
                }).subscribe((res: Response) => {
                    resolve(<any>res);
                }, (res: Response) => {
                    rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                });
        });
    }

    public post(params: {
        url: string;
        body: any;        
    }): Promise<rps.services.API.IResponse> {
        return new Promise<rps.services.API.IResponse>((resolve, reject) => {
            var resolvedUrl: string = this.appSettings.rpsAPIAddress + "clientapi/" + params.url;
            this._http.request(
                resolvedUrl,
                {
                    body: rps.services.API.serialize(params.body),
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Company': this._rpsStateManager.getCurrentCompany()
                    })
                }).subscribe((res: Response) => {
                    resolve(<any>res);
                }, (res: Response) => {
                    rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                });
        });
    }

    public delete(params: { url: string; body: any; }): Promise<rps.services.API.IResponse> {
        return new Promise<rps.services.API.IResponse>((resolve, reject) => {
            var resolvedUrl: string = this.appSettings.rpsAPIAddress + "clientapi/" + params.url;
            this._http.request(
                resolvedUrl,
                {        
                    body: rps.services.API.serialize(params.body),            
                    method: 'DELETE',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Company': this._rpsStateManager.getCurrentCompany()
                    })
                }).subscribe((res: Response) => {
                    resolve(<any>res);
                }, (res: Response) => {
                    rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                });
        });
    }
}
