import {Component, ElementRef, AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { baseServerColumn } from './baseServerColumn';

@Component({
    selector: 'rps-documents-server',
    template: `
        <div class="rps-editor-editor rps-read-only-editor-viewer rps-read-only-semantic-state">
         <template ngFor let-document [ngForOf]="documentsResult?.Data" let-i="index">
                    <div class="rps-img-2 rps-document-img" *ngIf="i<nDocumentos">
                        <img class="rps-img" src="{{document?.descriptor?.Image}}" />
                    </div> 
                    <i *ngIf="i==maxDocumentos-1 && documentsResult?.Count>=nDocumentos"> 
                        +{{documentsResult?.Count-nDocumentos}}
                    </i> 
         </template>       
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class rpsDocumentsServerColumn extends baseServerColumn<rps.data.DocumentsResult> implements AfterViewChecked {
    documentsResult: rps.data.DocumentsResult;
    nDocumentos;
    maxDocumentos: number = 5;
    componentWidth: number = 0;
    private kendoTooltip: kendo.ui.Tooltip;
    constructor(protected elementRef: ElementRef, _cdr: ChangeDetectorRef) {   
        super(_cdr);
    }
    
    setValue(newValue: rps.data.DocumentsResult) {
        if (this.documentsResult !== newValue) {
            this.documentsResult = newValue;
            if (this.kendoTooltip)
                this.kendoTooltip.destroy();
            this.kendoTooltip = $(this.elementRef.nativeElement).kendoTooltip({
                filter: ".rps-document-img",
                position: "bottom",
                showAfter: rps.utils.getTooltipDelay(),
                content: (e) => {
                    return this.documentsResult.Data[$(e.target).index()].descriptor.MainDescriptor;
                }
            }).data("kendoTooltip");
            this._cdr.markForCheck();
        }
    }
    ngAfterViewChecked() {
        //Comprobamos que tenga tamaño y que no sea el tamaño que tenia anteriormente para no estar constantemente realizando la operacion
        if ($(this.elementRef.nativeElement).find(".rps-editor-editor").width() > 0 && this.componentWidth != $(this.elementRef.nativeElement).find(".rps-editor-editor").width()) {
            this.nDocumentos = Math.floor($(this.elementRef.nativeElement).find(".rps-editor-editor").width() / 35);
            if (this.nDocumentos > this.maxDocumentos)
                this.nDocumentos = this.maxDocumentos;
            this.componentWidth = $(this.elementRef.nativeElement).find(".rps-editor-editor").width();
        }        
    }
} 