import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Injectable, OnChanges, OnDestroy, SimpleChange} from '@angular/core';

@Injectable() //Se marca como injectable para que la DI funcione correctamente en las clases que heredan de esta
export abstract class baseServerColumn<T> implements  OnChanges, OnDestroy {
    public value: T;
    public rpsModel: rps.viewmodels.properties.VMProperty<T>;
    private rpsModelPropertyChangedSubscription: rps.services.ISubscription;

    constructor(protected _cdr: ChangeDetectorRef) {
    }

    abstract setValue(newValue: T);

    ngOnDestroy() {
        if (this.rpsModelPropertyChangedSubscription)
            this.rpsModelPropertyChangedSubscription.unsubscribe();
        if (this.rpsModel) {
            this.rpsModel = null;
        }
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes["rpsModel"])
            this.onChangeModel();
    }

    onChangeModel() {
        if (this.rpsModelPropertyChangedSubscription)
            this.rpsModelPropertyChangedSubscription.unsubscribe();

        if (this.rpsModel) {
            this.setValue(this.rpsModel.value);

            this.rpsModelPropertyChangedSubscription = this.rpsModel.propertyChanged.subscribe((e) => {
                this.rpsModelPropertyChanged(e);
            });
        }
        else {
            this.setValue(null);
        }
    }

    rpsModelPropertyChanged(e: rps.viewmodels.properties.VMPropertyChange) {
        if (e.propertyName == "value")
            this.setValue(this.rpsModel.value);

        this._cdr.markForCheck();
    }
} 