import {Http, Response, Headers, URLSearchParams} from '@angular/http';
import {Injectable, Inject} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Observer} from 'rxjs/Observer';
import 'rxjs/add/operator/map';
import 'rxjs/Rx';
import {rpsAppSettings} from './appSettings';
import {rpsStateManager} from './stateManager';
import { rpsWindowAction } from '../directives/layouts/window';

@Injectable()
export class UploadService {
    public progress$: Observable<any>;
    private progressObserver: Observer<any>;
    private progress: number;

    constructor() {
        this.progress$ = Observable.create((observer) => {
            this.progressObserver = observer;
        }).share();
    }

    public makeFileRequest(url: string, params: string[], files: File[], httpMethod: string): Observable<XMLHttpRequest> {        
        return Observable.create((observer) => {
            let formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            for (let i = 0; i < files.length; i++) {
                formData.append("uploads[]", files[i], files[i].name);
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status < 400) {
                        observer.next(xhr);                        
                        observer.complete();
                    } else {
                        observer.error(xhr);
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                this.progress = Math.round(event.loaded / event.total * 100);

                if (this.progressObserver)
                    this.progressObserver.next(this.progress);
            };

            xhr.open(httpMethod, url, true);
            xhr.send(formData);
        });
    }
}

@Injectable()
export class rpsAPI implements rps.services.IApi {

    constructor(private appSettings: rpsAppSettings,
        private _rpsStateManager: rpsStateManager,
        private _uploadService: UploadService,
        private _http: Http) {
    }

    configure = () => {
        rps.app.api = this;
    }

    /** 
        * This method is used to call a Business Logic method using the POST method
        * @param {string} url - The service/blName where the business logic is located
        * @param {string} methodName - The method name to be called
        * @param {Object} parameters - The parameters to be used to call the method
        * @returns {Promise<any>} - The promise when the method will be completed or rejected 
        */
    public invokeMethod(url: string, methodName: string, parameters: Object): Promise<any> {
        return new Promise((resolve, reject) => {
            this._http.post(
                this.appSettings.rpsAPIAddress + "api/" + url + "/action/" + methodName,
                rps.services.API.serialize(parameters),
                {
                    headers: new Headers({
                        'Content-Type': 'application/json',
                    })
                }).map((res: Response, ix?: number) => {
                    //Mirar si la llamada ha cambiado los datos de sesión; si es así, guardar los nuevos datos de sesión
                    //Si es a raíz de un logon, intenta restaurar el path original si es que se había metido
                    if (res.headers.has('SessionContext')) {
                        rps.app.session.setSessionData(
                            JSON.parse(rps.services.API.decode64(res.headers.get('SessionContext'))),
                            (methodName == "RPSLogOn" || methodName == "AuthenticatedWindowsLogOn"));
                    }

                    return rps.services.API.deserialize(res.text());
                }).subscribe((data: any) => {
                    resolve(data);
                }, (res: Response) => {
                    rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                });
        });
    }

    public invokeAction(actionName: string, parameters: Object): Promise<any> {
        return new Promise((resolve, reject) => {
            var actionParts = actionName.split("/");
            this._http.post(
                this.appSettings.rpsAPIAddress + "api/" + actionParts[0] + "/action/" + actionParts[1],
                rps.services.API.serialize(parameters, true),
                {
                    headers: new Headers({
                        'Content-Type': 'application/json',
                    })
                }).map((res: Response, ix?: number) => {
                    return rps.services.API.deserialize(res.text());
                }).subscribe((data: any) => {
                    resolve(data);
                }, (res:Response) => {
                    rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                });
        });
    }

    public update<T extends rps.entities.IBaseEntity>(entity: T): Promise<T> {
        return new Promise((resolve, reject) => {
            var params = new URLSearchParams();
            if (!entity.__isNew)
                params.set("id", entity.getEntityID());
            this._http.request(
                this.appSettings.rpsAPIAddress + "api/" + entity.__entityUrl,
                {
                    body: rps.services.API.serialize(entity),
                    method: (entity.__isNew ? 'POST' : 'PUT'),
                    search: params,
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Company': this._rpsStateManager.getCurrentCompany()
                    })
                }).map((res: Response, ix?: number) => {
                    return rps.services.API.deserialize(res.text());
                }).subscribe((data: T) => {
                    resolve(data);
                }, (res: Response) => {
                    rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                });
        });
    }

    public patch<T extends rps.entities.BaseEntity>(entity: T): Promise<T> {
        var patches = this.computePatch(entity);
        return new Promise((resolve, reject) => {
            var params = new URLSearchParams();
            if (!entity.__isNew)
                params.set("id", entity.getEntityID());
            this._http.request(
                this.appSettings.rpsAPIAddress + "api/" + entity.__entityUrl,
                {
                    body: rps.services.API.serialize(patches, true, true),
                    method: (entity.__isNew ? 'POST' : 'PATCH'),
                    search: params,
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Company': this._rpsStateManager.getCurrentCompany()
                    })
                }).map((res: Response, ix?: number) => {
                    return rps.services.API.deserialize(res.text());
                }).subscribe((data: T) => {
                    resolve(data);
                }, (res: Response) => {
                    rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                });
        });
    }

    public delete<T extends rps.entities.IBaseEntity>(entity: T | string, entityId?: string): Promise<boolean> {
        if (typeof entity == "string")
            return this.doDelete(<string>entity, entityId);
        else
            return this.doDelete((<T>entity).__entityUrl, (<T>entity).getEntityID());
    }

    private doDelete(entityUrl: string, entityId: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            var params = new URLSearchParams();
            params.set("id", entityId);
            this._http.delete(
                this.appSettings.rpsAPIAddress + "api/" + entityUrl,
                {
                    body: '',
                    search: params,
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Company': this._rpsStateManager.getCurrentCompany()
                    })
                }).map((res: Response, ix?: number) => {
                    return rps.services.API.deserialize(res.text());
                }).subscribe((data: any) => {
                    resolve(null);
                }, (res: Response) => {
                    rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                });
        });
    }

    public query(params: {
        queryName: string;
        pageOptions?: {
            page: number;
            pageSize: number;
        };
        params?: { [id: string]: Object; };
        forPrint?: boolean;
    }): Promise<any> {
        var queryParams = new URLSearchParams();
        if (params.pageOptions) {
            queryParams.set("$rpp", params.pageOptions.pageSize.toString());
            queryParams.set("$Page", params.pageOptions.page.toString());
        }
        else {
            queryParams.set("$nopaging", "");
        }
        if (params.params) {
            for (var param in params.params) {
                //Si el parámetro no tiene valor, ni siquiera se envía
                if (rps.object.hasValue(params.params[param])) {
                    if (rps.object.isDate(params.params[param]))
                        queryParams.set(param, (<Date>params.params[param]).toISOString());
                    else if (rps.object.isArray(params.params[param]) && (<Array<any>>params.params[param]).length == 0) {
                        //Filtrar los arrays que no tienen elementos para no mandarlos
                    }
                    else
                        queryParams.set(param, params.params[param].toString());
                }
            }
        }
        return new Promise<any>((resolve, reject) => {
            let queryUrl = params.queryName;
            if (!queryUrl.startsWith("http") && queryUrl.indexOf('/') > 0) {
                var queryNameParts = params.queryName.split('/');
                queryUrl = this.appSettings.rpsAPIAddress + "api/" + queryNameParts[0] + "/query/" + queryNameParts[1];
            }

            if (rps.object.hasValue(params.forPrint) && params.forPrint == true) {
                queryParams.set("$forprint", "1");
                var windowRef = queryUrl + "?" + queryParams.toString();
                window.open(windowRef, "_blank");
                resolve(true);
            }
            else {
                this._http.get(
                    queryUrl,
                    {
                        body: '',
                        search: queryParams,
                        headers: new Headers({
                            'Content-Type': 'application/json',
                            'Company': this._rpsStateManager.getCurrentCompany()
                        })
                    }).map((res: Response, ix?: number) => {
                        return rps.services.API.deserialize(res.text());
                    }).subscribe((data: any) => {
                        resolve(data);
                    }, (res: Response) => {
                        rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                    });
            }
        });
    }

    public entityInstanceQuery(queryName: string, id: string): Promise<rps.services.IEntityInstanceQueryResult> {
        var queryParams = new URLSearchParams()
        queryParams.set("ID", id);
        return new Promise<rps.services.IEntityInstanceQueryResult>((resolve, reject) => {
            var queryNameParts = queryName.split('/');
            this._http.get(
                this.appSettings.rpsAPIAddress + "api/" + queryNameParts[0] + "/query/" + queryNameParts[1],
                {
                    search: queryParams,
                    body: '',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Company': this._rpsStateManager.getCurrentCompany()
                    })
                }).map((res: Response, ix?: number) => {
                    return rps.services.API.deserialize(res.text());
                }).subscribe((data: any) => {
                    resolve(data);
                }, (res: Response) => {
                    rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                });
        });
    }

    public get<T>(params: { url: string; urlType: rps.services.UrlType; queryParams?: any; }): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            var resolvedUrl: string;
            if (params.urlType == rps.services.UrlType.Absolute)
                resolvedUrl = params.url;
            else
                resolvedUrl = this.appSettings.rpsAPIAddress + "api/" + params.url;

            var searchParams = new URLSearchParams();
            if (params.queryParams) {
                for (var param in params.queryParams)
                    searchParams.set(param, params.queryParams[param]);
            }
            this._http.get(
                resolvedUrl,
                {
                    search: searchParams,
                    body: '',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Company': this._rpsStateManager.getCurrentCompany()
                    })
                }).map((res: Response, ix?: number) => {
                    return rps.services.API.deserialize(res.text());
                }).subscribe((data: T) => {
                    resolve(data);
                }, (res: Response) => {
                    rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                });
        });
    }

    getHTML(params: { templateUrl?: string; template?: string; urlType: rps.services.UrlType }): Promise<string> {
        if (params.template)
            return Promise.resolve(params.template);

        return new Promise<string>((resolve, reject) => {
            var resolvedUrl: string;
            if (params.urlType == rps.services.UrlType.Absolute)
                resolvedUrl = params.templateUrl;
            else
                resolvedUrl = this.appSettings.rpsAPIAddress + params.templateUrl;

            this._http.get(resolvedUrl)
                .map((res: Response, ix?: number) => {
                    return res.text();
                }).subscribe((data: string) => {
                    resolve(data);
                }, (res: Response) => {
                    rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                });
        });
    }

    private computePatch(entity: rps.entities.BaseEntity): rps.entities.IPropertyPatch[] {
        return this.computeEntityPatches("", entity.__originalEntity, entity);
    }

    private computeEntityPatches(partialPath: string, originalEntity: rps.entities.IBaseEntity, currentEntity: rps.entities.IBaseEntity): rps.entities.IPropertyPatch[] {
        var patches = new Array<rps.entities.IPropertyPatch>();

        var simpleBaseEntity = new rps.entities.BaseEntity();

        let currentPath: string = rps.string.isNullOrEmpty(partialPath) ? "" : partialPath + ".";
        for (var prop in originalEntity) {
            //Saltarse ciertas propiedades
            if (prop.startsWith("_") || prop.startsWith("$") || rps.object.isFunction(originalEntity[prop]) ||
                prop == "IID" || prop == "Image" || prop.endsWith("RowTimestamp") ||
                Object.prototype.hasOwnProperty.call(simpleBaseEntity, prop) ||
                originalEntity.getChildRelations().indexOf(prop) > -1 ||
                (originalEntity.getParentRelations() && originalEntity.getParentRelations()[0].ParentRelation == prop))
                continue;

            let originalValue = originalEntity[prop];
            let currentValue = currentEntity[prop];
            if (!rps.object.isArray(currentValue)) {
                //Mirar si es una propiedad relativa a una imagen (si es así, se ignora, no entra en el proceso de patch)
                if ((rps.object.isString(originalValue) && originalValue.startsWith("http://") && originalValue.indexOf("/image?") > -1) ||
                    (rps.object.isString(currentValue) && currentValue.startsWith("http://") && currentValue.indexOf("/image?") > -1))
                    continue;
                if ((rps.object.isString(originalValue) && originalValue.startsWith("https://") && originalValue.indexOf("/image?") > -1) ||
                    (rps.object.isString(currentValue) && currentValue.startsWith("https://") && currentValue.indexOf("/image?") > -1))
                    continue;

                //Caso especial de que la entidad sea de tipo baseEntity sin ser padre (DeliveryNoteLine-> OrderLine)
                if (originalValue instanceof rps.entities.BaseEntity || currentValue instanceof rps.entities.BaseEntity) {
                    this.computeEntityPatches(currentPath + prop, originalValue, currentValue).forEach(function (patch) {
                        patches.push(patch);
                    });
                }
                else if (!rps.object.equals(originalValue, currentValue))
                    patches.push({
                        Path: currentPath + prop,
                        Operation: rps.entities.PatchOperationType.Replace,
                        NewValue: currentValue,
                        OldValue: originalValue
                    });
            }
        }

        for (var p = 0; p < originalEntity.getChildRelations().length; p++) {
            let prop: string = originalEntity.getChildRelations()[p]
            let originalEntities: Array<rps.entities.BaseEntity> = originalEntity[prop];
            let originalChildIds: Array<string> = Enumerable.From(originalEntities).Select((be => be.getEntityID())).ToArray();
            let originalEntityDict = new rps.collections.Dictionary<string, rps.entities.BaseEntity>([]);
            for (var i = 0; i < originalEntities.length; i++) {
                originalEntityDict.add(originalEntities[i].getEntityID(), originalEntities[i]);
            };

            let currentEntities: Array<rps.entities.BaseEntity> = currentEntity[prop];
            let currentChildIds: Array<string> = Enumerable.From(currentEntities).Select((be => be.getEntityID())).ToArray();
            var currentEntityDict = new rps.collections.Dictionary<string, rps.entities.BaseEntity>([]);
            for (var i = 0; i < currentEntities.length; i++) {
                currentEntityDict.add(currentEntities[i].getEntityID(), currentEntities[i]);
            };

            // Changed children
            for (var i = 0; i < originalEntities.length; i++) {
                let originalChildEntity: rps.entities.BaseEntity = originalEntities[i];
                if (currentChildIds.indexOf(originalChildEntity.getEntityID()) > -1) // Existing child
                {
                    var currentChildEntity = currentEntityDict[originalChildEntity.getEntityID()];
                    let childPath: string = "{0}{1}[{2}]".format(currentPath, prop, originalChildEntity.getEntityID());
                    this.computeEntityPatches(childPath, originalChildEntity, currentChildEntity).forEach((patch) => {
                        patches.push(patch);
                    });
                }
            }
            // Deleted children
            let deletedChildIds: Array<string> = Enumerable.From(originalChildIds).Except(currentChildIds).ToArray();
            for (var i = 0; i < originalEntities.length; i++) {
                let originalChildEntity: rps.entities.BaseEntity = originalEntities[i];
                if (deletedChildIds.indexOf(originalChildEntity.getEntityID()) > -1)
                    patches.push(
                        {
                            Path: "{0}{1}[{2}]".format(currentPath, prop, originalChildEntity.getEntityID()),
                            Operation: rps.entities.PatchOperationType.Delete,
                            NewValue: null,
                            OldValue: null
                        });
            }
            // Added children
            let addedChildIds: Array<string> = Enumerable.From(currentChildIds).Except(originalChildIds).ToArray();
            for (var i = 0; i < currentEntities.length; i++) {
                let currentChildEntity: rps.entities.BaseEntity = currentEntities[i];
                if (addedChildIds.indexOf(currentChildEntity.getEntityID()) > -1)
                    patches.push(
                        {
                            Path: "{0}{1}[{2}]".format(currentPath, prop, currentChildEntity.getEntityID()),
                            Operation: rps.entities.PatchOperationType.Add,
                            NewValue: currentChildEntity,
                            OldValue: null
                        });
            }
        }

        return patches;
    }

    public setProperty(entity: rps.entities.IBaseEntity, propertyName: string): Promise<rps.entities.IPropertyPatch[]>;
    public setProperty(entity: rps.entities.IBaseEntity, propertyName: string, contractName: string): Promise<rps.entities.IPropertyPatch[]>;
    public setProperty(entity: rps.entities.IBaseEntity, propertyName: string, contractName?: string): Promise<rps.entities.IPropertyPatch[]> {
        return new Promise((resolve, reject) => {
            let url = this.appSettings.rpsAPIAddress + "api/" + entity.__entityUrl;
            if (contractName)
                url = url + "/customset/" + contractName + "/" + propertyName;
            else 
                url = url + "/set/" + propertyName;
            this._http.post(
                url,
                rps.services.API.serialize(entity),
                {
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    })
                }).map((res: Response, ix?: number) => {
                    return rps.services.API.deserialize(res.text());
                }).subscribe((data: rps.entities.IBaseEntity) => {
                    resolve(data);
                }, (res: Response) => {
                    rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                });
        });
    }

    public uploadImage(params: { url: string; file: File }): Promise<string> {
        var url: string = params.url;
        var httpMethod: string = 'PUT'

        return new Promise((resolve, reject) => {
            this._uploadService.makeFileRequest(url, [], [params.file], httpMethod)
                .subscribe((response: XMLHttpRequest) => {
                    resolve(response.getResponseHeader("Location"));
                }, (res: XMLHttpRequest) => {
                    rps.services.API.treatErrorStatusCode(res, resolve, reject);
                });
        });
    }

    public removeImage(params: { url: string }): Promise<string> {
        var url: string = params.url;
        var httpMethod: string = 'DELETE';
        return new Promise((resolve, reject) => {
            return this._http.delete(url)
                .map((res: Response) => {
                    return rps.services.API.deserialize(res.text());
                }) 
                .subscribe((location: string) => {
                    resolve(location);
            },
                (res: Response) => {
                    rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                });
        });
    }

    public uploadFile(params?: { allowedExtensions?: Array<string> }): Promise<{ ticket: string }> {
        var fileUploaderVM: FileUploaderVM = new FileUploaderVM(params);
        return rps.app.uiFactory.showWindow({
            template: `<rps-window [rpsOpen]='vm.isOpen'             [rpsActions]="vm.actions"             rpsTitle="{{$root.resources.directives.FILE_UPLOADER_WINDOW}}"             id="fileUploaderWindow"             rpsHorizontalSize="6">     <rps-rows-group>         <rps-row>             <rps-file-uploader rpsColumns="12"                                [rpsAdapter]="vm.fileUploadAdapter">             </rps-file-uploader>         </rps-row>         <rps-row>             <rps-button rpsColumns="3"                         rpsLabel="{{$root.resources.directives.ACCEPT}}"                         [rpsModel]="vm.accept">             </rps-button>             <rps-button rpsColumns="3"                         rpsLabel="{{$root.resources.directives.CANCEL}}"                         [rpsModel]="vm.cancel">             </rps-button>         </rps-row>     </rps-rows-group> </rps-window>`,
            viewModel: fileUploaderVM
        }).then(() => {
            fileUploaderVM.dispose();
            return { ticket: fileUploaderVM.ticket };
        });        
    }

    public getReport(service: string, reportName: string, params?: {}) : Promise<string>{
        var queryParams = new URLSearchParams()
        if (params) {
            for (var param in params) {
                queryParams.set(param, params[param]);
            }
        }
        return new Promise<string>((resolve, reject) => {
            this._http.get(
                this.appSettings.rpsAPIAddress + "api/" + service + "/query/" + reportName,
                {
                    search: queryParams,
                    body: '',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Company': this._rpsStateManager.getCurrentCompany()
                    })
                }).map((res: Response, ix?: number) => {
                    debugger;
                    return rps.services.API.deserialize(res.text());
                }).subscribe((data: any) => {
                    debugger;
                    resolve(data);
                }, (res: Response) => {
                    rps.services.API.treatErrorStatusCode(<any>res, resolve, reject);
                });
        });
    }

    public exportQuery(params: { queryReference: string, exportFormat: string, params?: {} }): Promise<any> {
        var exportParams = Object.assign({}, params.params);
        exportParams["$exportFormat"] = params.exportFormat;
        exportParams["$nopaging"] = "true";
        var queryNameParts = params.queryReference.split('/');
        let queryUrl = queryNameParts[0] + "/query/" + queryNameParts[1];
        return this.download({
            url: queryUrl,
            urlType: rps.services.UrlType.Relative,
            params: exportParams
        });
    }

    public download(params: { url: string, urlType: rps.services.UrlType, params?: {} }): Promise<any> {
        var resolvedUrl: string;
        if (params.urlType == rps.services.UrlType.Absolute)
            resolvedUrl = params.url;
        else
            resolvedUrl = this.appSettings.rpsAPIAddress + "api/" + params.url;
        var queryParams = new URLSearchParams();
        if (params.params) {
            for (var param in params.params) {
                //Si el parámetro no tiene valor, ni siquiera se envía
                if (rps.object.hasValue(params.params[param])) {
                    if (rps.object.isDate(params.params[param]))
                        queryParams.set(param, (<Date>params.params[param]).toISOString());
                    else if (rps.object.isArray(params.params[param]) && (<Array<any>>params.params[param]).length == 0) {
                        //Filtrar los arrays que no tienen elementos para no mandarlos
                    }
                    else
                        queryParams.set(param, params.params[param].toString());
                }
            }
        }

        var windowRef = resolvedUrl + "?" + queryParams;
        window.open(windowRef);
        return Promise.resolve(this);
    }
}

class FileUploaderAdapter extends rps.viewmodels.adapters.FileUploaderAdapterBase {
}

class FileUploaderVM implements rps.viewmodels.IWindowVM {
    private fileUploadedSubscription: rps.services.ISubscription;
    
    private _isOpen: boolean = false;
    public actions: [rpsWindowAction];
    public get isOpen(): boolean {
        return this._isOpen;
    }
    public set isOpen(newValue: boolean) {
        if (this._isOpen != newValue) {
            this._isOpen = newValue;
            this.isOpenChanged.emit(newValue);
        }
    }
    public isOpenChanged: rps.services.IEventEmitter<boolean>;
    public defaultCloseCommand = new rps.viewmodels.commands.CommandProperty({
        target: this,
        command: (commandParameters: any): Promise<any> => {
            return this.cancel.execute();
        }
    });
    public fileUploadAdapter: rps.viewmodels.adapters.FileUploaderAdapterBase;
    public ticket: string;

    accept: rps.viewmodels.commands.CommandProperty;
    cancel: rps.viewmodels.commands.CommandProperty;

    constructor(params?: { allowedExtensions?: Array<string> }) {
        this.isOpenChanged = rps.app.eventManager.createEmitter<boolean>();

        this.fileUploadAdapter = new FileUploaderAdapter();
        this.fileUploadAdapter.multiple = false;
        if (params && params.allowedExtensions)
            this.fileUploadAdapter.allowedExtensions = params.allowedExtensions;
        this.fileUploadedSubscription = this.fileUploadAdapter.fileUploaded.subscribe((e: { uploadTicket: string, fileName: string }) => {
            this.createAttachment(e);
        });

        this.accept = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<any> => {
                this.close();
                return Promise.resolve<any>(this);
            },
            canExecute: (): rps.viewmodels.commands.CanExecuteResult => {
                if (this.ticket)
                    return rps.viewmodels.commands.CanExecuteResult.allow();
                else
                    return rps.viewmodels.commands.CanExecuteResult.deny([]);
            }
        });

        this.cancel = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: (): Promise<any> => {
                this.ticket = null;
                this.close();
                return Promise.resolve<any>(this);
            }
        });
        this.actions = [{
            target: this,
            name: 'close',
            method: this.cancel.execute
        }];
    }

    createAttachment(data: { uploadTicket: string, fileName: string }) {
        this.ticket = data.uploadTicket;
    }

    close() {
        this.isOpen = false;
    }

    public dispose() {
        if (this.fileUploadedSubscription)
            this.fileUploadedSubscription.unsubscribe();
    }
}
