import {Component,
    ElementRef,
    forwardRef,
    OnChanges,
    SimpleChange,
    Optional,
    ComponentRef,
    ViewChild,
    ViewContainerRef,
    OnDestroy,
    ChangeDetectorRef } from '@angular/core';
import {rpsRowService} from '../layouts/row'
import { rpsEditor } from '../editors/editorBase'
import { rpsControl } from '../controlBase'
import { rpsSearchBox } from '../editors/searchBox'

@Component({
    template: `
        <div>
            <div class="rps-stack-panel-item" >
                <rps-button rpsTemplate="K-ICON" 
                            rpsIcon="plus" 
                            [rpsModel]="rpsViewSelector?.rpsAddNewCommand">
                </rps-button>
            </div>
        </div>
    `
})
export class rpsViewSelectorFooter {
    rpsViewSelector: rpsViewSelector;
    constructor() {

    }

    setRPSViewSelector(rpsViewSelector: rpsViewSelector) {
        this.rpsViewSelector = rpsViewSelector;
    }
}

@Component({
    selector: 'rps-view-selector',
    template: `
        <div class="rps-editor rps-view-selector" >
            <div class="kendoContainer">
                <select class="rps-editor-editor">
                </select>
            </div>
        </div>
        <div #dynamicContainer>
        </div>
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsViewSelector) }],
    inputs: rpsEditor.getComponentInputs(['rpsAddNewCommand'])
})
export class rpsViewSelector extends rpsEditor<string> implements OnDestroy {
    @ViewChild('dynamicContainer', { read: ViewContainerRef })
    private dynamicContainer: ViewContainerRef;

    private valueTemplate: string = `
        <h4>
            <span>#:data.description#</span>
        </h4>
    `;
    private footerTemplate: string = `
        <div id="[id]" class="rps-stack-panel">
        </div>
    `;
    private kendoControl: kendo.ui.DropDownList;
    private kendoSourceHTML: string;
    private viewSelectorFooterComponentRef: ComponentRef<rpsViewSelectorFooter>;

    //Propiedad que contiene el comboSource del vmProperty
    protected comboSource: rps.data.sources.ComboSource;

    public rpsAddNewCommand: rps.viewmodels.commands.CommandProperty;

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }

    ngOnDestroy() {
        if (this.kendoControl) {
            this.kendoControl.destroy();
            this.kendoControl = null;
            this.element.find("select").first().empty();
        }

        if (this.viewSelectorFooterComponentRef) {            
            this.viewSelectorFooterComponentRef.destroy();
            this.viewSelectorFooterComponentRef = null;
        }            
    }

    createTargetControl() {
        var footerID: string = "footer-" + this.myId;

        //Busca inputContainer (el div que contiene el input)
        var div = this.element.find(".kendoContainer").first();

        //Se queda con una copia del HTML del input, por si necesita crearlo de nuevo (en caso de que cambien las opciones, por ejemplo)
        if (!this.kendoSourceHTML)
            this.kendoSourceHTML = div.html();

        //Si el control estaba creado, destruirlo y vaciarlo
        if (this.kendoControl) {
            this.kendoControl.destroy();
            div.empty();
            //Volver a crear un input para que pueda llamar al método de Telerik para crear el editor concreto
            div.append(this.kendoSourceHTML);
        }
        
        //Crear el editor
        //intellisense
        var options: kendo.ui.DropDownListOptions = {
            dataValueField: "value",
            dataTextField: "description",
            valuePrimitive: true,
            dataSource: this.createDataSource(this.comboSource),
            change: (e: kendo.ui.DropDownListChangeEvent) => { this.change(e) },
            value: this.value,
            valueTemplate: this.valueTemplate
        };
        //Establecer la propiedad “footerTemplate”, que no está en las opciones ase del control
        options['footerTemplate'] = this.footerTemplate.replace("[id]", footerID);
        //Crear el widget customizado kendoViewSelectorDropDownList
        this.kendoControl = (<any>this.element.find("select").first()).kendoViewSelectorDropDownList(options).data("kendoViewSelectorDropDownList");

        //Crear el componente de manera dinámica y sustituir el elemento con el ID anteriormente creado para la ocasión
        rps.app.uiFactory.createComponent<rpsViewSelectorFooter>(rpsViewSelectorFooter, this.dynamicContainer)
            .then((result: ComponentRef<rpsViewSelectorFooter>) => {
                this.viewSelectorFooterComponentRef = result;
                result.instance.setRPSViewSelector(this);
                var footer = $(document).find("#" + footerID);
                if (footer.length > 0)
                    footer.append(result.location.nativeElement);
            });
    }

    change(e: kendo.ui.DropDownListChangeEvent) {
        var dropDownList: any = e.sender;
        var selectedItem = null;
        for (var i = 0; i < this.comboSource.length; i += 1) {
            if (this.comboSource[i]["value"] == dropDownList.value()) {
                selectedItem = this.comboSource[i];
                break;
            }
        }
        if (selectedItem != null)
            this.updateSourceValue(selectedItem["value"]);
        else
            this.updateSourceValue(null);
    }

    //Al cambiar el vmProperty, también hay que refrescar el origen de datos del combo, para esto se hace lo mismo que para el value en la base, con la diferencia de que el origen de datos no varía, por lo que no hay que tenerlo en cuenta en el método rpsModelPropertyChanged
    //Para esto se sobreescribe el onChangeModel y se crean el setComboSource y updateTargetDataSource
    onChangeModel() {
        if (this.rpsModel) {            
            this.setComboSource((<rps.viewmodels.properties.ComboProperty>this.rpsModel).comboSource);
        }
        else
            this.setComboSource(null);

        super.onChangeModel();
    }
    setComboSource(newValue: rps.data.sources.ComboSource) {
        if (this.comboSource != newValue) {
            this.comboSource = newValue;
            if (this.contentInitialized)
                this.updateTargetComboSource(newValue);
        }
    }
    updateTargetComboSource(newValue: rps.data.sources.ComboSource) {
        this.kendoControl.setDataSource(this.createDataSource(newValue));
    }

    updateTargetValue(newValue: string) {
        //HACK: Si está sin definir, se establece un null, porque si no, el widget, se queda el valor…
        if (rps.string.isNullOrEmpty(this.value))
            this.kendoControl.value(null);
        else
            this.kendoControl.value(this.value);
    }

    private createDataSource(newValue: rps.data.sources.ComboSource): kendo.data.DataSource {
        if (newValue && newValue.isGrouped)
            return new kendo.data.DataSource({
                data: newValue,
                group: [{ field: "group" }]
            });
        else
            return new kendo.data.DataSource({
                data: newValue
            });
    }
}

@Component({
    selector: 'rps-view-view',
    template: `
        <div>
            <rps-rows-group>
                <rps-row>
                    <rps-view-selector rpsColumns="3"
                                       [rpsModel]="selectedViewProperty"
                                       [rpsAddNewCommand]="addNewCommand">
                    </rps-view-selector>
                    <div rpsColumns="1" class="rps-view-selector-padding">
                        <rps-button *ngIf="viewSourceOptions?.editState"
                                    rpsTemplate="ICON" 
                                    rpsIcon="fa fa-pencil-square-o" 
                                    rpsLabel="{{resources.directives.EDIT_VIEW}}"
                                    [rpsModel]="editViewCommand">
                        </rps-button>
                    </div>
                    <div rpsColumns="5">
                    </div>
                    <div rpsColumns="3" class="rps-view-selector-padding">
                        <rps-search-box *ngIf="viewSourceOptions?.isPaginated && viewSourceOptions?.isSearchable" 
                                        [rpsModel]="viewSourceOptions.querySource?.filtersManager.search"
                                        #searchBox
                                        [rpsCommandModel]="viewSourceOptions.querySource?.loadItems">
                        </rps-search-box>
                    </div>
                </rps-row>
                <rps-filters *ngIf="viewSourceOptions?.isPaginated"                                    
                             [rpsFiltersManager]="viewSourceOptions.querySource?.filtersManager">
                </rps-filters>
            </rps-rows-group>
            <div *ngIf="viewSourceOptions?.isPaginated">
                <rps-query-source-toolbar [rpsSource]='viewSourceOptions.querySource'                                       
                                          [rpsViews]="allowedViewTypes"
                                          [rpsSelectedView]="selectedViewType"
                                          (rpsSelectedViewChanged)="selectedViewType = $event"
                                          [rpsSelectionMode]="rpsSelectionMode"
                                           [rpsExportFormats]="viewSourceOptions.supportedExportFormats"
                                          rpsAllowRemovePagination="true">
                    <rps-items-control-toolbar-item rpsItemValue="Card" *ngIf="selectedViewType == 'Card'">
                        <rps-descriptor-view [rpsSource]="viewSourceOptions.querySource" 
                                             [rpsLinkPropertyPath]="rpsLinkPropertyPath"
                                             [rpsSelectionMode]="rpsSelectionMode"
                                             rpsDescriptorPropertyPath="model.descriptor">
                        </rps-descriptor-view>
                    </rps-items-control-toolbar-item>
                    <rps-items-control-toolbar-item rpsItemValue="Grid" *ngIf="selectedViewType == 'Grid'">
                        <rps-grid [rpsSource]='viewSourceOptions.querySource'
                                    [rpsColumnsDefinition]='viewSourceOptions.columnsDefinition'
                                    [rpsLinkPropertyPath]="rpsLinkPropertyPath"
                                    [rpsSelectionMode]="rpsSelectionMode"
                                    rpsAutoFit="">
                        </rps-grid>
                    </rps-items-control-toolbar-item>
                </rps-query-source-toolbar>
            </div>
            <div *ngIf="viewSourceOptions?.isChronological">
                <rps-calendar-view [rpsSource]="viewSourceOptions.querySource"
                                    rpsLinkPropertyPath="{{rpsLinkPropertyPath}}"
                                    [rpsSelectionMode]="rpsSelectionMode">
                </rps-calendar-view>
            </div>          
        </div>
     
  `,
    providers: [{ provide: rpsControl, useExisting: forwardRef(() => rpsViewView) }],
    inputs: ['rpsSource', 'rpsSelectionMode', 'rpsLinkPropertyPath']
})
export class rpsViewView extends rpsControl implements OnChanges {

    @ViewChild('searchBox') searchBox:  rpsSearchBox;

    rpsSelectionMode: string = "None";
    rpsLinkPropertyPath: string;
    rpsSource: any;    

    private viewSource: rps.data.sources.ViewSource;
    private viewSourceOptions: rps.data.sources.ViewSource.IViewSourceOptions;
    private allowedViews: Array<rps.data.sources.ViewSource.ViewDefinitionVM>;

    public allowedViewTypes: string;
    private _selectedViewType: string;
    get selectedViewType(): string {
        return this._selectedViewType;
    };
    set selectedViewType(newValue:string) {
        if (this._selectedViewType != newValue) {
            this._selectedViewType = newValue;
            if (this.viewSource)
                this.viewSource.selectedViewType = newValue;
        }
    };
    public disabled: boolean;
    public selectedViewProperty: rps.viewmodels.properties.ComboProperty;        
    public _autoRefresh: boolean = false;
    get autoRefresh(): boolean {
        return this._autoRefresh;
    }
    set autoRefresh(newValue: boolean) {
        this._autoRefresh = newValue;
        if (this.viewSource) {                
            this.viewSource.autoRefresh = this.autoRefresh;
        }
    }

    public get resources() {
        return rps.app.resources;
    }

    //Cachear el canAddNewResult para no tener que hacerlo cada vez (dependerá de permisos de vista)
    private canAddNewResult: rps.viewmodels.commands.CanExecuteResult;
    public addNewCommand: rps.viewmodels.commands.CommandProperty;
    public editViewCommand: rps.viewmodels.commands.CommandProperty;    

    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super();

        this.addNewCommand = new rps.viewmodels.commands.CommandProperty({
            target:this,
            command: this.addNew,
            canExecute:this.canAddNew
        });
        this.editViewCommand = new rps.viewmodels.commands.CommandProperty({
            target: this,
            command: this.editView,
            canExecute: this.canEditView
        });
    }

    createCustomQueryLink() {
        var entityParts = this.viewSource.entityName.split('/');
        return new rps.data.IUILinkDefinition(
            {
                State: "general.customquery",
                Parameters: {},
                Arguments: {}
            }, {
                State: "detail",
                Parameters: {
                    IDCustomQuery: rps.viewmodels.EntityVM.NEW_ID_VALUE
                },
                Arguments: {
                    CodUserForNewQuery: rps.app.session.user,
                    ServiceForNewQuery: entityParts[0],
                    EntityForNewQuery: entityParts[1]
                }
            }
        );
    }

    addNew(): Promise<any> {
        return rps.app.stateManager.goTo(this.createCustomQueryLink());
    }

    canAddNew(): rps.viewmodels.commands.CanExecuteResult {
        if (!this.canAddNewResult) {
            if (rps.app.stateManager.isStateRegistered("general.customquery"))
                this.canAddNewResult = rps.viewmodels.commands.CanExecuteResult.allow();
            else
                this.canAddNewResult = rps.viewmodels.commands.CanExecuteResult.deny([rps.app.resources.errors.ERR_FORBIDDEN]);
        }
        return this.canAddNewResult;
    }

    editView(): Promise<any> {
        rps.app.stateManager.goTo(this.viewSourceOptions.editState);
        return Promise.resolve<any>(this);
    }

    canEditView(): rps.viewmodels.commands.CanExecuteResult {
        return rps.viewmodels.commands.CanExecuteResult.allow();
    }

    ngOnChanges(changes: { [key: string]: SimpleChange }): any {
        if (changes['rpsSource'])
            this.rpsSourceChanged(changes['rpsSource'].currentValue);
    }

    rpsSourceChanged(newValue) {
        if (this.viewSource != newValue) {

            this.viewSource = newValue;

            if (this.viewSource) {
                this.viewSource.autoRefresh = this.autoRefresh;
                this.viewSource.propertyChanged.subscribe((e: rps.viewmodels.properties.VMPropertyChange) => {
                    this.vmPropertyPropertyChanged(e);
                });
                this.allowedViewTypes = JSON.stringify(this.viewSource.allowedViewTypes);
                this.selectedViewTypeChanged();
                this.optionsChanged();
            }
            else {
                this.allowedViews = null;
                this.selectedViewType = null;
                this.allowedViewTypes = null;
                this.selectedViewProperty = null;
                this.setViewSourceOptions(null);
            }
        }
    }

    vmPropertyPropertyChanged(e: rps.viewmodels.properties.VMPropertyChange) {
        if (e.propertyName == "selectedViewType")
            this.selectedViewTypeChanged();
        else if (e.propertyName == "options") {
            this.optionsChanged();
        }
    }

    optionsChanged() {        
        if (this.viewSource) {
            //allowedViews
            if (this.allowedViews != this.viewSource.allowedViews) {
                this.allowedViews = this.viewSource.allowedViews;
                if (this.allowedViews) {
                    this.selectedViewProperty = new rps.viewmodels.properties.ComboProperty({
                        relatedItems: this.allowedViews,
                        target: this,
                        initialValue: this.viewSource.selectedView.Name,
                        valuePath: "Name",
                        displayPath: "Label",
                        groupPath:"ScopeDescription"
                    });
                    this.selectedViewProperty.propertyChanged.subscribe((e: rps.viewmodels.properties.VMPropertyChange) => { this.selectedViewPropertyPropertyChanged(e);});
                }
                else {
                    this.selectedViewProperty = null;
                }
            }
            //viewSourceOptions
            this.setViewSourceOptions(this.viewSource.options);                    
        }
        else {
            this.allowedViews = null;
            this.selectedViewProperty = null;
            this.setViewSourceOptions(null);
        }
    }

    selectedViewTypeChanged() {
        this.selectedViewType = this.viewSource.selectedViewType;
    }

    selectedViewPropertyPropertyChanged(e: rps.viewmodels.properties.VMPropertyChange) {
        if (e.propertyName == "value") {
            this.selectedViewPropertyValueChanged(e.newValue);
        }
    }

    selectedViewPropertyValueChanged(newValue: string) {
        if (this.viewSource) {
            this.viewSource.selectedView = Enumerable.From<rps.data.sources.ViewSource.ViewDefinitionVM>(this.viewSource.allowedViews)
                .FirstOrDefault(null, av => av.Name == newValue);
        }
    }

    setViewSourceOptions(newOptions: rps.data.sources.ViewSource.IViewSourceOptions) {
        this.viewSourceOptions = newOptions;        
    }

    focus() : boolean {
        if (this.searchBox)
            return this.searchBox.focus();

        return false;
    }
}     