import { Component, OnChanges, SimpleChange, Injectable } from '@angular/core';

@Injectable()
export class rpsItemsControlToolbarService {
    private items: Array<rpsItemsControlToolbarItem> = new Array<rpsItemsControlToolbarItem>();
    public itemTemplate: string;
    public itemTitle: string;

    public itemAdded$: rps.services.IEventEmitter<rpsItemsControlToolbarItem> = rps.app.eventManager.createEmitter<rpsItemsControlToolbarItem>();
    public selectedView: string;
    constructor() {
    }

    addItemsControlToolbarItem(newItem: rpsItemsControlToolbarItem) {
        this.items.push(newItem);
        this.itemAdded$.emit(newItem);
    }
}

@Component({
    selector: 'rps-items-control-toolbar-item',
    template: `
    <div *ngIf="isSelected">
        <ng-content></ng-content>
    </div>
`,
    inputs: ['rpsItemValue']
})
export class rpsItemsControlToolbarItem implements OnChanges {
    isSelected: boolean = false;
    rpsItemValue: string;

    constructor(private itemsControlToolbarService: rpsItemsControlToolbarService) {                
        itemsControlToolbarService.addItemsControlToolbarItem(this);
    }

    ngOnChanges(changes: {[key: string]: SimpleChange;}): any {
        if (changes["rpsItemValue"]) {
            if (!rps.string.isNullOrEmpty(this.rpsItemValue) && this.itemsControlToolbarService.selectedView == this.rpsItemValue)
                this.isSelected = true;
            else
                this.isSelected = false;
        }
    }
}

 