import { Component, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'rps-link',
    template: `
        <a [routerLink]="linkRouterParams" 
           [queryParams]="linkRouterQueryParams">
            <ng-content></ng-content>
        </a>
    `,
    inputs: ['rpsLink'],
})
export class rpsLink implements OnChanges {
    rpsLink: rps.viewmodels.properties.ILinkProperty;

    public linkRouterParams: Array<any>;
    public linkRouterQueryParams: rps.IParams;

    constructor() { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["rpsLink"]) {
            if (this.rpsLink) {
                var routerLink = this.rpsLink.getRouterLinkParameters();
                this.linkRouterParams = routerLink.routerLink;
                this.linkRouterQueryParams = routerLink.queryParams;
            }
            else {
                this.linkRouterParams = null;
                this.linkRouterQueryParams = null;
            }
        }
    }
}