import {Component, ElementRef, Optional, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {rpsReadOnlyEditor} from './readOnlyEditorBase'
import {rpsRowService} from '../layouts/row'
import {rpsSemanticState} from '../utils/semanticState'
import {NgStyle} from '@angular/common';

@Component({
    selector: 'rps-read-only-color-editor',
    template: `
        <div class="rps-editor" 
             [rpsSemanticState]="rpsModel?.semanticState"
             [class.rps-error-detail-partner]="rpsModel?.hasErrors">
            <label class="rps-editor-label">
                {{rpsLabel}}
            </label>
            <div class="rps-editor-editor rps-read-only-editor-viewer rps-read-only-semantic-state"
                 [style.background-color]="text">
            </div>
        </div>
        <error-detail-validator *ngIf="rpsModel?.hasErrors" [errorDetails]="rpsModel?.errors">
        </error-detail-validator>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: rpsReadOnlyEditor.getComponentInputs()
})
export class rpsReadOnlyColorEditor extends rpsReadOnlyEditor<number, rps.viewmodels.properties.VMProperty<number>>  {
    constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, @Optional() rpsRowService?: rpsRowService) {
        super(elementRef, changeDetectorRef, rpsRowService);
    }
}