import {Component, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {NgClass} from '@angular/common';
import { baseServerColumn } from './baseServerColumn';

@Component({    
    template: `
        <div class="rps-icon-container" align="center"
                [ngClass]="{'rps-semantic-state-icon-positive': value?.SemanticState==1,
                            'rps-semantic-state-icon-info': value?.SemanticState==2,
                            'rps-semantic-state-icon-warning': value?.SemanticState==3,
                            'rps-semantic-state-icon-negative': value?.SemanticState==4}">
                <i *ngIf="itemClass" 
                    [ngClass]="itemClass"                                      
                    aria-hidden="true">
                </i>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class rpsIconServerColumn extends baseServerColumn<rps.data.Icon>{
    value: rps.data.Icon;
    itemClass: string = "";
    kendoTooltip: kendo.ui.Tooltip;
    semanticState: rps.data.SemanticState;

    constructor(private elementRef: ElementRef, _cdr: ChangeDetectorRef) {       
        super(_cdr);
    }

    setValue(newValue: rps.data.Icon) {
        if (this.value != newValue) {
            this.value = newValue;
            this.itemClass = rps.object.hasValue(this.value) ? rps.icon.getIconClass(this.value.IconType) : "";
            //Si ya contenia un tooltip, se elimina
            if (this.kendoTooltip)
                this.kendoTooltip.destroy();
            //En el caso de que el icono tenga un texto asociado, se genera el tooltip
            if (this.value && !rps.string.isNullOrEmpty(this.value.IconLabel)) {
                this.kendoTooltip = $(this.elementRef.nativeElement).kendoTooltip({
                    filter: ".rps-icon-container",
                    position: "bottom",
                    showAfter: rps.utils.getTooltipDelay(),
                    content: this.value.IconLabel
                }).data("kendoTooltip");
            }
            this._cdr.markForCheck();
        }
        
    }
}