import {Http, Response, Headers, URLSearchParams} from '@angular/http';
import {Injectable, Inject} from '@angular/core';
import {Observable} from 'rxjs';
import 'rxjs/Rx';
import {rpsAppSettings} from './appSettings';
import {rpsStateManager} from './stateManager';

@Injectable()
export class rpsViewFactory implements rps.services.IViewFactory {

    constructor(private http: Http, private appSettings: rpsAppSettings, private _rpsStateManager:rpsStateManager) {
    }

    configure(): void {
        rps.app.viewFactory = this;
    }

    getStateView(params:{
        stateName: string,
        isModal?:boolean
    }): Promise<JQuery> {
        return this.getStateViewCode(params.stateName).then((code) => {
            var view: JQuery = jQuery(code);
            if (params.isModal || view.find("rps-dialog-view").length > 0)
                this._rpsStateManager.setAsModalState(params.stateName);
            return view;
        });      
    }

    getStateViewCode(stateName: string): Promise<string> {
        //El stateName se compone de servicename.stateName
        var firstDot: number = stateName.indexOf(".");
        return new Promise<string>((resolve) => {
            this.http.get(
                this.appSettings.rpsAPIAddress + "clientapi/" + stateName.substr(0, firstDot) + "/" + stateName.substr(firstDot + 1) + "/view")
                .map((res: Response, ix?: number) => {
                    return (res.text());
                }).subscribe((result: any) => {                    
                    resolve(result);
                });
        });
    }

    getModalStateView(stateName: string): Promise<JQuery> {
        return this.getStateView({ stateName: stateName, isModal: true }).then((view: JQuery) => {                
            return view;
        });
    }
}
