import {Component, OnChanges, SimpleChange, Optional, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {rpsRowService} from '../layouts/row';
import {NgClass} from '@angular/common';

@Component({
    selector: 'rps-icon',
    template: `
        <div class="rps-editor">
            <label class="rps-editor-label">
                {{rpsLabel}}<font *ngIf="isRequired" color="red"> *</font>
            </label>
            <div class="rps-icon-container" align="center"
                [ngClass]="{'rps-semantic-state-icon-positive': value?.SemanticState==1,
                            'rps-semantic-state-icon-info': value?.SemanticState==2,
                            'rps-semantic-state-icon-warning': value?.SemanticState==3,
                            'rps-semantic-state-icon-negative': value?.SemanticState==4}">
                <i *ngIf="itemClass" 
                    [ngClass]="itemClass"                                      
                    aria-hidden="true">
                </i>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['rpsLabel', 'rpsModel']
})
export class rpsIcon implements OnChanges {
    rpsLabel: string;
    rpsModel: rps.viewmodels.properties.IconProperty;
    value: rps.data.Icon;
    itemClass: string = "";
    private kendoTooltip: kendo.ui.Tooltip;
    protected myId: string = rps.guid.newGuid();

    constructor(private elementRef: ElementRef, private changeDetectorRef: ChangeDetectorRef, @Optional() private rpsRowService?: rpsRowService) {
    }

    ngOnChanges(changes: { [key: string]: SimpleChange; }) {
        if (changes['rpsModel']) {
            if (rps.object.hasValue(this.rpsModel)) {
                this.rpsModel.propertyChanged.subscribe((value: rps.viewmodels.properties.VMPropertyChange) => {
                    if (value.propertyName == "value")
                        this.valueChanged(this.rpsModel.value);
                    else if (value.propertyName == "semanticState")
                        this.semanticStateChanged(this.rpsModel.semanticState);
                });
                this.valueChanged(this.rpsModel.value);
            }
            else
                this.valueChanged(null);
        }
        if (changes["rpsLabel"] && this.rpsRowService)
            this.rpsRowService.labelChanged(this.myId, this.rpsLabel);
    }

    valueChanged(newValue: rps.data.Icon) {
        if (this.value != newValue) {
            this.value = newValue;

            if (this.value && this.value.IconType)
                this.itemClass = rps.icon.getIconClass(this.value.IconType);
            else
                this.itemClass = null;   
            //Si ya contenia un tooltip, se elimina
            if (this.kendoTooltip)
                this.kendoTooltip.destroy();
            //En el caso de que el icono tenga un texto asociado, se genera el tooltip
            if (this.value && !rps.string.isNullOrEmpty(this.value.IconLabel)) {
                this.kendoTooltip = $(this.elementRef.nativeElement).kendoTooltip({
                    filter: ".rps-icon-container",
                    position: "bottom",
                    showAfter: rps.utils.getTooltipDelay(),
                    content: this.value.IconLabel
                }).data("kendoTooltip");
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    semanticStateChanged(newValue: rps.data.SemanticState) {
        this.changeDetectorRef.markForCheck();    
    }
}
