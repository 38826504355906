import { Component, OnChanges, SimpleChanges } from '@angular/core';
import {ColumnsManagerService } from './columns';

@Component({
    selector: 'rps-box',
    template: `
        <div class="rps-layout-padding">
            <div>        
                <div *ngIf="rpsTitle">
                    <h6>
                        {{rpsTitle}}
                    </h6>
                </div>
                <div>
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    `,
    inputs: ['rpsTitle', 'rpsHorizontalSize'],
    providers: [ColumnsManagerService]
})
export class rpsBox implements OnChanges  {
    rpsTitle: string;
    rpsHorizontalSize: string;

    constructor(private columnsManagerService: ColumnsManagerService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["rpsHorizontalSize"]) {
            if (rps.object.isNumeric(this.rpsHorizontalSize)) {
                //enviamos al servicio el tamaño para que la columna lo recoja
                this.columnsManagerService.setColumnSize(this.rpsHorizontalSize);
            }
        }
    }
}