import { Directive, Component, ElementRef, Optional, OnChanges, SimpleChanges, Injectable,OnDestroy } from '@angular/core';

@Injectable()
export class ColumnsManagerService {
    public columnSize: string;
    public columnSizeChanged$: rps.services.IEventEmitter<string> = rps.app.eventManager.createEmitter<string>();
    setColumnSize(newValue: string) {
        this.columnSize = newValue;
        this.columnSizeChanged$.emit(this.columnSize);
    }
}

@Component({
    selector: 'rps-columns-manager',
    template: `
        <div>
            <ng-content></ng-content>
        </div>
    `,
    providers: [ColumnsManagerService],
    inputs: ['rpsHorizontalSize']
})
export class rpsColumnsManager implements OnChanges {
    rpsHorizontalSize: string;
    constructor(private columnsManagerService: ColumnsManagerService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["rpsHorizontalSize"]) {
            if (rps.object.isNumeric(this.rpsHorizontalSize)) {
                //enviamos al servicio el tamaño para que la columna lo recoja
                this.columnsManagerService.setColumnSize(this.rpsHorizontalSize);
            }
        }
    }
}

@Directive({
    selector: '[rpsColumns]',
    inputs: [
        'rpsColumns',
        'rpsColumnsSM',
        'rpsColumnsLG',
        'rpsColumnsInvariant'
    ]

})
export class rpsColumns implements OnChanges, OnDestroy {    
    rpsColumns: string = "0";
    rpsColumnsSM: string = "0";
    rpsColumnsLG: string = "0";

    //Indica que el ancho no varía en función del columnSize del ColumnsManagerService, 
    //ya que en las ventanas emergentes, en ocasiones no se trabaja contra 12 columnas y se quiere que los editores tengan el mismo tamaño que si trabajasen contra 12, 
    //pero hay algunos componentes que tienen los tamaños pensado para 12 columnas, como el wizard
    private columnsInvariant: boolean = false;
    public get rpsColumnsInvariant(): string {
        if (this.columnsInvariant)
            return "true";
        else
            return "false";
    }
    public set rpsColumnsInvariant(newValue: string) {
        if (newValue == "true") {
            this.columnsInvariant = true;
        }
        else
            this.columnsInvariant = false;
    }

    private classNames: Array<string> = new Array<string>();
    private resolvedColumns: string;    
    private columnSizeChangedSubscription: rps.services.ISubscription;

    constructor(private elementRef: ElementRef, @Optional() private columnsManagerService: ColumnsManagerService) {
        if (this.columnsManagerService) {
            this.resolvedColumns = this.columnsManagerService.columnSize;
            this.columnSizeChangedSubscription = columnsManagerService.columnSizeChanged$.subscribe(
                (newValue: string) => {
                    //Recibimos la cantidad de columnas que tiene la ventana padre
                    this.resolvedColumns = newValue;
                    //Si todavía no tiene establecidas el número de columnas, no se hace nada
                    if (this.rpsColumns != "0")
                        this.setColClass();
                });
        }     
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["rpsColumns"] || changes["rpsColumnsSM"] || changes["rpsColumnsLG"])
            this.setColClass();        
    }

    private setColClass() {        
        const maxColumns: number = 12;
        //Borramos las clases que se han podido crear anteriormente y estan guardadas en classNames
        this.classNames.forEach((value) => {
            $(this.elementRef.nativeElement).removeClass(value);
        });
        this.classNames = new Array<string>();       
        //Añadimos la clase y la guardamos en el array
        this.addClass("col-xs-12");

        //Si la ventana padre viene con columnas, calculamos las columnas respecto a la ventana
        if (this.resolvedColumns) {
            var colsMD = this.calculateFinalColumns(this.rpsColumns, maxColumns, this.resolvedColumns);
            this.addClass(rps.string.format("col-md-{0}", colsMD));
            if (this.rpsColumnsSM != "0") {
                var colsSM = this.calculateFinalColumns(this.rpsColumnsSM,maxColumns,this.resolvedColumns);
                this.addClass(rps.string.format("col-sm-{0}", colsSM));
            }
            else
                this.addClass(rps.string.format("col-sm-{0}", colsMD));
            if (this.rpsColumnsLG != "0") {
                var colsLG = this.calculateFinalColumns(this.rpsColumnsLG,maxColumns,this.resolvedColumns);
                this.addClass(rps.string.format("col-lg-{0}", colsLG));
            }
            else
                this.addClass(rps.string.format("col-lg-{0}", colsMD));
        } else {            
            this.addClass(rps.string.format("col-md-{0}", this.rpsColumns));
            if (this.rpsColumnsSM != "0")
                this.addClass(rps.string.format("col-sm-{0}", this.rpsColumnsSM));
            else
                this.addClass(rps.string.format("col-sm-{0}", this.rpsColumns));
            if (this.rpsColumnsLG != "0")
                this.addClass(rps.string.format("col-lg-{0}", this.rpsColumnsLG));
            else
                this.addClass(rps.string.format("col-lg-{0}", this.rpsColumns));
        }    
        
    }

    private calculateFinalColumns(columns: string, maxColumns: number, workingColumns: string):number {
        if (!this.columnsInvariant) {
            var result: number = Math.floor((+columns * maxColumns) / +workingColumns);
            if (result > maxColumns)
                return maxColumns;
            else
                return result;
        }
        else {
            //Si no se quiere modificar el número de columnas, 
            //solo se modifica si el numero de columnas que tiene es inferior al número de columnas sobre el que se está trabajando
            if (+columns > +workingColumns)
                return maxColumns;
            else
                return +columns;
        }
    }

    private addClass(className:string) {
        $(this.elementRef.nativeElement).addClass(className);
        this.classNames.push(className);
    }

    ngOnDestroy() {
        if (this.columnSizeChangedSubscription)
            this.columnSizeChangedSubscription.unsubscribe();
    }
}